/*
 *
 * ToDos actions
 *
 */
import {
	ASSIGN_CLINICIAN,
	ASSIGN_CLINICIAN_ERROR,
	ASSIGN_CLINICIAN_SUCCESS,
	DEFAULT_ACTION,
	FETCH_PHARMACIES,
	FETCH_PHARMACIES_ERROR,
	FETCH_PHARMACIES_SUCCESS,
	LOAD_NEW_INTAKES,
	LOAD_NEW_INTAKES_ERROR,
	LOAD_NEW_INTAKES_SUCCESS,
	LOAD_OTHER_REQ,
	LOAD_OTHER_REQ_ERROR,
	LOAD_OTHER_REQ_SUCCESS,
	LOAD_RX_REQUESTS,
	LOAD_RX_REQUESTS_ERROR,
	LOAD_RX_REQUESTS_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function fetchPharmacies(id) {
	return {
		type: FETCH_PHARMACIES,
		id,
	};
}

export function fetchPharmaciesSuccess(data) {
	return {
		type: FETCH_PHARMACIES_SUCCESS,
		data,
	};
}

export function fetchPharmaciesError(err) {
	return {
		type: FETCH_PHARMACIES_ERROR,
		err,
	};
}

export function loadOtherRequests() {
	return {
		type: LOAD_OTHER_REQ,
	};
}

export function loadOtherRequestsSuccess(otherRequests) {
	return {
		type: LOAD_OTHER_REQ_SUCCESS,
		otherRequests,
	};
}

export function loadOtherRequestsError(error) {
	return {
		type: LOAD_OTHER_REQ_ERROR,
		error,
	};
}

export function loadRxRequests() {
	return {
		type: LOAD_RX_REQUESTS,
	};
}

export function loadRxRequestsSuccess(rxRequests) {
	return {
		type: LOAD_RX_REQUESTS_SUCCESS,
		rxRequests,
	};
}

export function loadRxRequestsError(error) {
	return {
		type: LOAD_RX_REQUESTS_ERROR,
		error,
	};
}

export function assignClinician(data) {
	return {
		type: ASSIGN_CLINICIAN,
		data,
	};
}

export function assignClinicianSuccess(data) {
	return {
		type: ASSIGN_CLINICIAN_SUCCESS,
		data,
	};
}

export function assignClinicianError(err) {
	return {
		type: ASSIGN_CLINICIAN_ERROR,
		err,
	};
}

export function loadNewIntakes() {
	return {
		type: LOAD_NEW_INTAKES,
	};
}

export function loadNewIntakesSuccess(newIntakes) {
	return {
		type: LOAD_NEW_INTAKES_SUCCESS,
		newIntakes,
	};
}

export function loadNewIntakesError(error) {
	return {
		type: LOAD_NEW_INTAKES_ERROR,
		error,
	};
}
