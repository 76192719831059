import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * <SelectButtonGroup />, a functional component to display buttons with single select option.
 *
 */

const SelectButtonGroup = (props) => {
	const { id, value, buttons, buttonClass, handleSelect, className, disabled } =
		props;
	const modifiedClass = `${className} btn-group btn-group-toggle w-100`;

	return (
		<div className={modifiedClass} data-toggle="buttons">
			{buttons.map((button) => (
				<label
					className={
						value === button.id
							? `px-0 btn border radio toggle-options radio-active ${buttonClass} `
							: `px-0 btn border radio text-secondary toggle-options ${buttonClass} `
					}
					key={button.id}
					style={{ cursor: disabled ? 'default' : 'pointer' }}
				>
					<input
						type="radio"
						name={id}
						id={button.id}
						autoComplete="off"
						value={button.id}
						onChange={handleSelect}
						disabled={disabled}
					/>
					{button.name}
				</label>
			))}
		</div>
	);
};

SelectButtonGroup.propTypes = {
	id: PropTypes.string || PropTypes.number,
	value: PropTypes.string || PropTypes.number,
	/** Buttons to display */
	buttons: PropTypes.array.isRequired,
	/** function to get selected button id */
	handleSelect: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default SelectButtonGroup;
