import { call, put, takeLatest } from 'redux-saga/effects';

import {
	addNewClientAPI,
	fetchAllGroupsAPI,
	fetchPatientAttendanceAPI,
	fetchPatientDocs,
	fetchPatientProfile,
	fetchPatientPrograms,
	fetchSessionsAPI,
	updateEnrollmentApi,
	updatePatientAPI,
} from 'utils/api';

import {
	addClientToGroupError,
	addClientToGroupSuccess,
	fetchGroupsError,
	fetchGroupsSuccess,
	fetchPatientAttendanceError,
	fetchPatientAttendanceSuccess,
	fetchPatientDocsError,
	fetchPatientDocsSuccess,
	fetchPatientGroupsError,
	fetchPatientGroupsSuccess,
	fetchPatientProfileError,
	fetchPatientProfileSuccess,
	getSessionsError,
	getSessionsSuccess,
	updateEnrollmentError,
	updateEnrollmentSuccess,
	updatePatientInfoError,
	updatePatientInfoSuccess,
} from './actions';
import {
	ADD_CLIENT_TO_GROUP,
	FETCH_GROUPS,
	FETCH_PATIENT_ATTENDANCE,
	FETCH_PATIENT_DOCS,
	FETCH_PATIENT_GROUPS,
	FETCH_PATIENT_PROFILE,
	GET_SESSIONS,
	UPDATE_ENROLLMENT,
	UPDATE_PATIENT_INFO,
} from './constants';

export default function* patientProfileSaga() {
	yield takeLatest(FETCH_PATIENT_PROFILE, getPatientProfile);
	yield takeLatest(UPDATE_PATIENT_INFO, updatePatientInfo);
	yield takeLatest(FETCH_PATIENT_GROUPS, getPatientGroups);
	yield takeLatest(FETCH_GROUPS, getAllGroups);
	yield takeLatest(GET_SESSIONS, getSessions);
	yield takeLatest(UPDATE_ENROLLMENT, updateEnrollment);
	yield takeLatest(FETCH_PATIENT_ATTENDANCE, getPatientAttendance);
	yield takeLatest(FETCH_PATIENT_DOCS, getPatientDocs);
	yield takeLatest(ADD_CLIENT_TO_GROUP, addClientToGroup);
}

export function* getPatientProfile(actions) {
	const { id } = actions;
	try {
		const response = yield call(fetchPatientProfile, id);

		if (
			response.response &&
			response.response.status &&
			response.response.status !== 200
		) {
			yield put(fetchPatientProfileError(response));
		} else {
			yield put(fetchPatientProfileSuccess(response));
		}
	} catch (err) {
		yield put(fetchPatientProfileError(err));
	}
}

export function* updatePatientInfo(actions) {
	const { data, callback } = actions;
	try {
		const response = yield call(updatePatientAPI, data);

		if (
			response.response &&
			response.response.status &&
			response.response.status !== 200
		) {
			yield put(updatePatientInfoError(response));
		} else {
			yield put(updatePatientInfoSuccess(response));
			callback();
		}
	} catch (err) {
		yield put(updatePatientInfoError(err));
	}
}

export function* getPatientGroups(actions) {
	const { id } = actions;
	try {
		const response = yield call(fetchPatientPrograms, { id, enrolled: false });

		if (
			response.response &&
			response.response.status &&
			response.response.status !== 200
		) {
			yield put(fetchPatientGroupsError(response.response));
		} else {
			yield put(fetchPatientGroupsSuccess(response));
		}
	} catch (err) {
		yield put(fetchPatientGroupsError(err));
	}
}

export function* getAllGroups() {
	try {
		const response = yield call(fetchAllGroupsAPI);
		if (
			response.response &&
			response.response.status &&
			response.response.status !== 200
		) {
			yield put(fetchGroupsError(response.error));
		} else {
			yield put(fetchGroupsSuccess(response));
		}
	} catch (err) {
		yield put(fetchGroupsError(err));
	}
}

export function* updateEnrollment(actions) {
	const { data, callback } = actions;
	try {
		const response = yield call(updateEnrollmentApi, data);
		yield put(updateEnrollmentSuccess(response.data));

		if (callback) {
			callback();
		}
	} catch (err) {
		yield put(updateEnrollmentError(err));
	}
}

export function* getSessions(actions) {
	const { data } = actions;
	try {
		const response = yield call(fetchSessionsAPI, data);
		yield put(getSessionsSuccess(response));
	} catch (err) {
		yield put(getSessionsError(err));
	}
}

export function* getPatientAttendance(actions) {
	const { id } = actions;
	try {
		const response = yield call(fetchPatientAttendanceAPI, id);

		if (
			response.response &&
			response.response.status &&
			response.response.status !== 200
		) {
			yield put(fetchPatientAttendanceError(response.response));
		} else {
			yield put(fetchPatientAttendanceSuccess(response));
		}
	} catch (err) {
		yield put(fetchPatientAttendanceError(err));
	}
}

export function* getPatientDocs(actions) {
	const { id } = actions;
	try {
		const response = yield call(fetchPatientDocs, id);
		if (!response?.requests && !response?.userDocs) {
			yield put(fetchPatientDocsError(response));
		} else {
			yield put(fetchPatientDocsSuccess(response));
		}
	} catch (err) {
		yield put(fetchPatientDocsError(err));
	}
}

export function* addClientToGroup(actions) {
	const { data, cb } = actions;
	try {
		const response = yield call(addNewClientAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(addClientToGroupError(response.response.data));
		} else {
			yield put(addClientToGroupSuccess(response.data));
			if (cb) {
				cb();
			}
		}
	} catch (err) {
		yield put(addClientToGroupError(err));
	}
}
