import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * <Checkbox />, a functional component for checkbox
 */
const Checkbox = (props) => {
	const { item, handleCheck, className, checkboxGroup, parent } = props;

	const modifiedClass = `${className} custom-control custom-checkbox`;

	return (
		<div className={modifiedClass}>
			<div className="p-3">
				<input
					name={checkboxGroup}
					type="checkbox"
					className="custom-control-input"
					onClick={() => handleCheck(parent, checkboxGroup, item.id)}
					id={item.id}
					checked={item.isSelected}
				/>
				<label
					className="custom-control-label text-cust-grey"
					htmlFor={item.id}
				>
					{item.name}
				</label>
			</div>
		</div>
	);
};

Checkbox.propTypes = {
	handleCheck: PropTypes.func.isRequired,
	item: PropTypes.object,
	className: PropTypes.string,
	checkboxGroup: PropTypes.string,
	parent: PropTypes.string,
};

export default Checkbox;
