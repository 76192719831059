import { parseISO } from 'date-fns';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-validation/build/form';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import DiscontinueRequest from 'components/DiscontinueRequest';
import GeneralRequest from 'components/GeneralRequest';
import LOARequest from 'components/LOARequest';
import ROIRequest from 'components/ROIRequest';
import RXRequest from 'components/RXRequest';

/* eslint-disable indent */
import TelehealthRequest from 'components/TelehealthRequest';

import './index.css';

/**
 *
 * PatientRequestModal
 *
 */

/* eslint-disable react/prefer-stateless-function */
class PatientRequestModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			// reqDate: new Date(),
			readOnly: false,
			loa: {
				clientName: '',
				requestDate: new Date(),
				fromDate: new Date(),
				toDate: '',
				reasonForAbsence: '',
				loaChecked: false,
			},
			rx: {
				clientName: '',
				clientPhone: '',
				rxName: '',
				requiredOn: new Date(),
				notes: '',
				pharmacies: [],
			},
			roi: {
				clientName: '',
				clientEmail: '',
				clientPhone: '',
				clientAddress: '',
				clientCity: null,
				clientState: null,
				clientZipcode: '',
				dateOfBirth: null,
				clientAuthName: '',
				entityName: '',
				entityType: '',
				entityPhone: '',
				entityFax: '',
				entityAddress: '',
				entityZipcode: '',
				roiPurpose: '',
				authClientType: 'patient',
				sendChecked: true,
				receiveChecked: true,
				evalChecked: false,
				labResChecked: false,
				progRepChecked: false,
				medInfoChecked: false,
				testResChecked: false,
				diagnChecked: false,
				sumRepChecked: false,
				entRecChecked: false,
				planTreatChecked: false,
				contTreatChecked: false,
				caseRevChecked: false,
				updateFileChecked: false,
				roiChecked: false,
			},
			discontinuance: {
				clientName: '',
				programSelected: null,
				requestDate: new Date(),
				discontinueDate: new Date(),
				effectiveDate: moment().add(7, 'days').toDate(),
				confirmChecked: false,
				reasonForDenial: '',
				hasOutsideProgram: false,
				outsideProgram: '',
			},
			generalRequest: {
				notes: '',
			},
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientRegisterData &&
			Object.keys(nextProps.patientRegisterData).length > 0
		) {
			if (nextProps.requestType.includes('Release')) {
				this.setRoiData(nextProps.patientRegisterData);
			} else if (nextProps.requestType.includes('Rx')) {
				this.setRxData(nextProps.patientRegisterData);
			} else if (nextProps.requestType.includes('Discontinuance')) {
				this.setDiscontinuanceData(nextProps.patientRegisterData);
			} else if (nextProps.requestType.includes('Absence')) {
				this.setLoaData(nextProps.patientRegisterData);
			}
			// eslint-disable-next-line react/no-access-state-in-setstate
		}

		if (nextProps.pharmacy) {
			const { auth } = this.props;
			const { rx } = this.state;
			rx.clientName = auth.loggedInUser.name;
			rx.clientPhone = auth.loggedInUser.phone;
			rx.pharmacies = nextProps.pharmacy.map((pharm) => ({
				...pharm,
				city: { value: pharm?.address?.city, label: pharm?.address?.city },
				state: { value: pharm?.address?.state, label: pharm?.address?.state },
				street: pharm?.address?.street,
				zipcode: {
					value: pharm?.address?.zipcode,
					label: pharm?.address?.zipcode,
				},
			}));
			this.setState({ rx });
		}
	}

	setLoaData = () => {
		const { loa } = this.state;
		const { auth } = this.props;
		loa.clientName = auth.loggedInUser.name;
		this.setState({ loa });
	};

	setRoiData = (data) => {
		const { states } = this.props;
		const { patientDetails, address } = data;
		const roi = { ...this.state.roi };

		if (data) {
			roi.clientName = patientDetails.name;
			roi.clientEmail = patientDetails.email;
			roi.clientPhone = patientDetails.phone;
			roi.clientAddress = address.street;
			roi.dateOfBirth = patientDetails.dob;
			if (!this.state.roi.clientState) {
				roi.clientState = states.filter(
					(state) => state.value === address.state,
				)[0];
				roi.clientCity = {
					label: address.city,
					value: address.city,
				};
			}
			roi.clientZipcode = address.zipcode;
			roi.clientAuthName = patientDetails.name;
		}
		this.setState({ roi });
	};

	setRxData = (data) => {
		const { patientDetails, pharmacy } = data;
		const rx = { ...this.state.rx };
		rx.clientName = patientDetails.name;
		rx.clientPhone = patientDetails.phone;
		rx.pharmacies = pharmacy;
		this.setState({ rx });
	};

	setDiscontinuanceData() {
		const { auth } = this.props;
		const discontinuance = { ...this.state.discontinuance };
		discontinuance.clientName = auth.loggedInUser.name || '';
		this.setState({ discontinuance });
	}

	componentDidMount() {
		const { data, requestType, pharmacy, patientRegisterData, auth } =
			this.props;

		let readOnly = false;

		if (data) {
			readOnly = true;
			if (requestType.toLowerCase().includes('leave')) {
				const loa = data;
				this.setState({ loa, readOnly });
			}
			if (requestType.toLowerCase().includes('general')) {
				const generalRequest = data;
				this.setState({ generalRequest, readOnly });
			}
			if (requestType.toLowerCase().includes('rx')) {
				const {
					pharmacyCity,
					pharmacyName,
					pharmacyState,
					pharmacyZipcode,
					pharmacyAddress,
					rxNumber,
				} = data;
				const rx = data;
				rx.pharmacies = [
					{
						city: pharmacyCity,
						name: pharmacyName,
						state: pharmacyState,
						zipcode: pharmacyZipcode,
						street: pharmacyAddress,
						phone: rxNumber,
					},
				];
				this.setState({ rx, readOnly });
			}
			if (requestType.toLowerCase().includes('discontinuance')) {
				const discontinuance = data;
				this.setState({ discontinuance, readOnly });
			}
		}

		if (pharmacy) {
			const { rx } = this.state;
			rx.clientName = auth.loggedInUser.name;
			rx.clientPhone = auth.loggedInUser.phone;
			rx.pharmacies = [pharmacy];
			this.setState({ rx });
		}

		if (patientRegisterData && Object.keys(patientRegisterData).length > 0) {
			if (requestType.includes('Release')) {
				this.setRoiData(patientRegisterData);
			} else if (requestType.includes('Rx')) {
				this.setRxData(patientRegisterData);
			} else if (requestType.includes('Discontinuance')) {
				this.setDiscontinuanceData();
			} else if (requestType.includes('Leave')) {
				this.setLoaData();
			}
		}

		if (!readOnly) {
			if (requestType.includes('Discontinuance')) {
				this.setDiscontinuanceData();
			} else if (requestType.includes('Leave')) {
				this.setLoaData();
			}
		}
	}

	handleNumberChange = (request) => (id, values) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[request];
		componentState[id] = values.value;
		this.setState({ [request]: componentState });
	};

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	disableSubmit = (requestType) => {
		const { generalRequest, rx } = this.state;

		if (requestType === 'General Request') {
			if (!generalRequest.notes) {
				return true;
			}
		} else if (requestType === 'Request for Rx') {
			if (
				!rx.clientName ||
				!rx.clientPhone ||
				rx.requiredOn === 'Invalid date'
			) {
				return true;
			}
		}
		return false;
	};

	handleAcceptance = (requestType) => (key) => {
		const { readOnly } = this.state;
		if (!readOnly) {
			// eslint-disable-next-line react/no-access-state-in-setstate
			const value = this.state[requestType];
			value[key] = !value[key];

			this.setState({ [requestType]: value });
		}
	};

	handleDateChange = (component, id) => (date) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[component];
		let dateFormat;

		if (date) {
			dateFormat = parseISO(moment(date).format('YYYY-MM-DD'));
		}
		componentState[id] = dateFormat;

		this.setState({ [component]: componentState });
	};

	handleChange = (component) => (event) => {
		const { target } = event;
		// event.preventDefault();
		const { name, value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[component];
		componentState[name] = value;

		this.setState({ [component]: componentState });
	};

	handleSelect = (component) => (option, name) => {
		const componentState = this.state[component];
		componentState[name] = option;

		this.setState({ component: componentState });
	};

	handleCheckBox = (formType, e) => {
		const { target } = e;
		const { name } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const form = this.state[formType];

		form[name] = !form[name];

		this.setState({ form });
	};

	getTypeBasedContent = (requestType) => {
		let result;
		const { readOnly, loa, roi, rx, generalRequest, discontinuance } =
			this.state;
		const {
			states,
			cities,
			zipcodes,
			programs,
			auth,
			isMobile,
			fetchCities,
			fetchZipcodes,
		} = this.props;

		if (requestType.toLowerCase().includes('leave')) {
			result = (
				<div>
					<LOARequest
						data={loa}
						readOnly={readOnly}
						handleChange={this.handleChange}
						handleDateChange={this.handleDateChange}
						onSubmit={this.submitForm}
						closeModal={this.closeModal}
						isMobile={isMobile}
					/>
				</div>
			);
		}
		if (requestType.toLowerCase().includes('discontinuance')) {
			result = (
				<div>
					<DiscontinueRequest
						data={discontinuance}
						role={auth.loggedInUser.roleName}
						programs={programs}
						readOnly={readOnly}
						handleChange={this.handleChange}
						handleSelect={this.handleSelect}
						handleDateChange={this.handleDateChange}
						handleAcceptance={this.handleAcceptance}
						onSubmit={this.submitForm}
						closeModal={this.closeModal}
						isMobile={isMobile}
					/>
				</div>
			);
		}
		if (requestType.toLowerCase().includes('information')) {
			result = (
				<div>
					<ROIRequest
						roi={roi}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						submitForm={this.submitForm}
						isMobile={isMobile}
						fetchCities={fetchCities}
						fetchZipcodes={fetchZipcodes}
					/>
				</div>
			);
		}
		if (requestType.toLowerCase().includes('rx')) {
			result = (
				<RXRequest
					data={rx}
					submitForm={this.submitForm}
					isMobile={isMobile}
					readOnly={readOnly}
					states={states}
					cities={cities}
					zipcodes={zipcodes}
					fetchCities={fetchCities}
					fetchZipcodes={fetchZipcodes}
				/>
			);
		}
		if (requestType.toLowerCase().includes('general')) {
			result = (
				<GeneralRequest
					note={generalRequest.notes}
					submitForm={this.submitForm}
					isMobile={isMobile}
				/>
			);
		}
		if (requestType.toLowerCase().includes('telehealth')) {
			result = (
				<TelehealthRequest submitForm={this.submitForm} isMobile={isMobile} />
			);
		}
		return result;
	};

	submitForm = (status, data) => {
		const { submitRequest, requestType } = this.props;
		const { loa, discontinuance } = this.state;

		let requestObj;
		let type = '';
		const reqStatus = status || 'OPEN';
		if (requestType.includes('Leave')) {
			type = 'LEAVE';
			requestObj = loa;
			// requestObj.groupObject = groupObject;
		}
		if (requestType.includes('General')) {
			type = 'GENERAL';
			requestObj = data;
			// requestObj.groupObject = groupObject;
		}
		if (requestType.includes('Rx')) {
			type = 'Rx';
			requestObj = {
				...data,
				pharmacyState: data?.pharmacyState?.value,
				pharmacyCity: data?.pharmacyCity?.value,
				pharmacyZipcode: data?.pharmacyZipcode?.value,
				pharmacyStreet: data?.pharmacyAddress,
			};
		}
		if (requestType.includes('Release')) {
			type = 'ROI';
			requestObj = {
				...data,
				clientState: data?.clientState?.value,
				clientCity: data?.clientCity?.value,
				clientZipcode: data?.clientZipcode?.value,
			};
			this.props.handleLoader();
		}
		if (requestType.includes('Telehealth')) {
			type = 'TELEHEALTH';
		}
		if (requestType.includes('Discontinuance')) {
			type = 'DISCONTINUANCE';
			requestObj = discontinuance;
		}

		submitRequest(type, reqStatus, requestObj);
	};

	render() {
		const { modal } = this.state;
		const { requestType, isMobile } = this.props;

		return (
			<div>
				<Modal
					isOpen={modal}
					className="modal-dialog-centered custom-modal"
					backdrop="static"
				>
					<ModalHeader
						toggle={this.closeModal}
						className={`align-center text-center px-${
							isMobile ? '2' : '4'
						} border-0 container-fluid custom-bg-color text-nowrap`}
						cssModule={{
							'modal-title': `d-flex justify-content-center w-100 pt-2 font-${
								isMobile ? '20' : '35'
							} text-dark font-weight-bold`,
						}}
					>
						{requestType}
					</ModalHeader>
					<ModalBody
						className={`pb-3 custom-bg-color ${isMobile && 'px-0 pt-0'}`}
						style={{
							borderRadius: '0.3rem',
							maxHeight: isMobile ? 'calc(90vh - 2rem)' : 'auto',
						}}
					>
						<Form>
							<div className="container-fluid p-0">
								{this.getTypeBasedContent(requestType)}
							</div>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

PatientRequestModal.propTypes = {
	// groupObject: PropTypes.object.isRequired,
	hide: PropTypes.func.isRequired,
	requestType: PropTypes.object.isRequired,
	submitRequest: PropTypes.func,
	auth: PropTypes.object,
	// showSelectGroup: PropTypes.func,
	data: PropTypes.object,
	pharmacy: PropTypes.object,
	patientRegisterData: PropTypes.object,
	handleLoader: PropTypes.func,
	states: PropTypes.array,
	cities: PropTypes.array,
	handleGeoChange: PropTypes.func,
};

export default PatientRequestModal;
