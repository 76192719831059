/*
 *
 * BrowseGroups constants
 *
 */

export const DEFAULT_ACTION = 'app/BrowseGroupsPrivate/DEFAULT_ACTION';

export const LOAD_PROGRAMS_PRIVATE =
	'app/BrowseGroupsPrivate/LOAD_PROGRAMS_PRIVATE';
export const LOAD_PROGRAMS_PRIVATE_SUCCESS =
	'app/BrowseGroupsPrivate/LOAD_PROGRAMS_PRIVATE_SUCCESS';
export const LOAD_PROGRAMS_PRIVATE_ERROR =
	'app/BrowseGroupsPrivate/LOAD_PROGRAMS_PRIVATE_ERROR';
