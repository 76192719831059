// import { take, call, put, select } from 'redux-saga/effects';
// Individual exports for testing
import { call, put, takeLatest } from 'redux-saga/effects';

import {
	addNewClientAPI,
	changeClinicianAPI,
	deleteClinicianAPI,
	editClinicianAPI,
	fetchAllClientsAPI,
	fetchAllCliniciansAPI,
	fetchAllGroupsAPI,
	fetchMyClientsAPI,
	getClinicianAPI,
} from 'utils/api';

import {
	addClientError,
	addClientSuccess,
	changeClinicianError,
	changeClinicianSuccess,
	deleteClinicianError,
	deleteClinicianSuccess,
	editClinicianError,
	editClinicianSuccess,
	getClinicianError,
	getClinicianSuccess,
	loadAllClientsError,
	loadAllClientsSuccess,
	loadAllCliniciansError,
	loadAllCliniciansSuccess,
	loadClientsError,
	loadClientsSuccess,
	loadProgramsError,
	loadProgramsSuccess,
} from './actions';
import {
	ADD_CLIENT,
	ADD_CLIENT_SUCCESS,
	CHANGE_CLINCIAN,
	CHANGE_CLINCIAN_SUCCESS,
	DELETE_CLINCIAN,
	DELETE_CLINCIAN_SUCCESS,
	EDIT_CLINCIAN,
	EDIT_CLINCIAN_SUCCESS,
	GET_CLINCIAN,
	LOAD_ALL_CLIENTS,
	LOAD_ALL_CLINICIANS,
	LOAD_CLIENTS,
	LOAD_PROGRAMS,
} from './constants';

export default function* cliniciansAndClientsSaga() {
	yield takeLatest(GET_CLINCIAN, getClinician);
	yield takeLatest(CHANGE_CLINCIAN, changeClinician);
	yield takeLatest(CHANGE_CLINCIAN_SUCCESS, getAllClients);
	yield takeLatest(LOAD_ALL_CLIENTS, getAllClients);
	yield takeLatest(LOAD_ALL_CLINICIANS, getAllClinicians);
	yield takeLatest(LOAD_CLIENTS, loadMyClients);
	yield takeLatest(ADD_CLIENT_SUCCESS, loadMyClients);
	yield takeLatest(DELETE_CLINCIAN_SUCCESS, getAllClinicians);
	yield takeLatest(EDIT_CLINCIAN_SUCCESS, getAllClinicians);
	yield takeLatest(DELETE_CLINCIAN, deleteClinician);
	yield takeLatest(EDIT_CLINCIAN, editClinician);
	yield takeLatest(ADD_CLIENT, addNewClient);
	yield takeLatest(ADD_CLIENT_SUCCESS, getAllClients);
	yield takeLatest(LOAD_PROGRAMS, getPrograms);
}

export function* getAllClients() {
	try {
		const response = yield call(fetchAllClientsAPI);
		if (response.response && response.response.status >= 400) {
			yield put(loadAllClientsError());
		} else {
			yield put(loadAllClientsSuccess(response.data));
		}
	} catch (err) {
		yield put(loadAllClientsError(err));
	}
}

export function* loadMyClients() {
	try {
		const response = yield call(fetchMyClientsAPI);
		if (response.response && response.response.status >= 400) {
			yield put(loadClientsError());
		} else {
			yield put(loadClientsSuccess(response.data));
		}
	} catch (err) {
		yield put(loadClientsError(err));
	}
}

export function* addNewClient(actions) {
	const { data } = actions;
	try {
		const response = yield call(addNewClientAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(addClientError(response.response.data));
		} else {
			yield put(addClientSuccess(response.data));
		}
	} catch (err) {
		yield put(addClientError(err));
	}
}

export function* getAllClinicians() {
	try {
		const response = yield call(fetchAllCliniciansAPI);
		if (response.response && response.response.status >= 400) {
			yield put(loadAllCliniciansError());
		} else {
			yield put(loadAllCliniciansSuccess(response.data));
		}
	} catch (err) {
		yield put(loadAllCliniciansError(err));
	}
}

export function* deleteClinician(actions) {
	const { data } = actions;
	try {
		const response = yield call(deleteClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(deleteClinicianError());
		} else {
			yield put(deleteClinicianSuccess(response.data));
		}
	} catch (err) {
		yield put(deleteClinicianError(err));
	}
}

export function* changeClinician(actions) {
	const { data } = actions;
	try {
		const response = yield call(changeClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(changeClinicianError());
		} else {
			yield put(changeClinicianSuccess(response.data));
		}
	} catch (err) {
		yield put(changeClinicianError(err));
	}
}

export function* editClinician(actions) {
	const { data } = actions;
	try {
		const response = yield call(editClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(editClinicianError());
		} else {
			yield put(editClinicianSuccess(response.data));
		}
	} catch (err) {
		yield put(editClinicianError(err));
	}
}

export function* getClinician(actions) {
	const { data } = actions;
	try {
		const response = yield call(getClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(getClinicianError());
		} else {
			yield put(getClinicianSuccess(response.data));
		}
	} catch (err) {
		yield put(getClinicianError(err));
	}
}

export function* getPrograms() {
	try {
		const response = yield call(fetchAllGroupsAPI);
		yield put(loadProgramsSuccess(response));
	} catch (err) {
		yield put(loadProgramsError(err));
	}
}
