import PropTypes from 'prop-types';

import React from 'react';

import './index.css';

/**
 *
 * <AvatarTextOverlay />, a functional component to display circular image with text overlay.
 */
const AvatarTextOverlay = (props) => {
	const { src, width, text, fontSize } = props;
	const avatarStyle = {
		width,
		height: width,
	};
	const textbox = {
		height: width,
		width,
	};

	return (
		<div className="mtc-avt-container">
			{src ? (
				<img className="mtc-avatar" src={src} alt="" style={avatarStyle} />
			) : (
				<div className="mtc-avatar" style={avatarStyle} />
			)}
			<div className="mtc-textbox" style={textbox}>
				<span style={{ fontSize }}>{text}</span>
			</div>
		</div>
	);
};

AvatarTextOverlay.propTypes = {
	/** <AvatarTextOverlay /> image url */
	src: PropTypes.string,
	/** width of <AvatarTextOverlay />, optional prop */
	width: PropTypes.string,
	/** Custom style for <AvatarTextOverlay/> */
	text: PropTypes.string,
	/** Font size for overlay text */
	fontSize: PropTypes.string,
};

AvatarTextOverlay.defaultProps = {
	width: '32px',
};

export default AvatarTextOverlay;
