import { parseISO } from 'date-fns';

import { isNil } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledDropdown,
} from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '@material-ui/icons/Info';

import CustomCheckbox from 'atoms/CustomCheckbox';
import CustomDatePicker from 'atoms/CustomDatePicker';
import CustomTimePicker from 'atoms/CustomTimePicker';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import {
	getObjectDifference,
	getDayIndex,
	getDayFromIndex,
} from 'utils/helpers';

import './index.css';

/**
 *
 * UpdateGroup
 *
 */

/* eslint-disable react/prefer-stateless-function */

const repeatAction = ['Weekly']; //, 'Daily'];

class UpdateGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			price: '',
			location: 'Marin',
			activeTab: '1',
			startDate: new Date(),
			repeats: 'Weekly',
			groupType: '',
			startTime: moment().toDate(),
			endTime: moment().add(1, 'hour').toDate(),
			repeatsOn: [],
			clinicianId: '',
			groupSize: 'Group',
			weekDays: [
				{ name: 'Mon', isSelected: false, value: 'Monday' },
				{ name: 'Tue', isSelected: false, value: 'Tuesday' },
				{ name: 'Wed', isSelected: false, value: 'Wednesday' },
				{ name: 'Thu', isSelected: false, value: 'Thursday' },
				{ name: 'Fri', isSelected: false, value: 'Friday' },
				{ name: 'Sat', isSelected: false, value: 'Saturday' },
				{ name: 'Sun', isSelected: false, value: 'Sunday' },
			],
			inPerson: false,
			meetingLink: '',
		};
	}

	componentDidMount() {
		this.setState({
			repeats: repeatAction[0],
		});
		const { mode, groupData, role, id, clinicians } = this.props;

		let {
			groupName,
			location,
			clinicianId,
			groupSize,
			price,
			repeatsOn,
			repeatFrequency,
			startTime,
			endTime,
			startDate,
			inPerson,
			meetingLink,
		} = groupData;

		if (mode === 'edit') {
			startTime = moment
				.utc(startTime)
				.set({ month: 0, day: 1, year: 1999 })
				.local()
				.toDate();
			endTime = moment
				.utc(endTime)
				.set({ month: 1, day: 1, year: 1999 })
				.local()
				.toDate();

			this.setState({
				groupType: groupName,
				location,
				clinicianId,
				groupSize,
				price,
				repeatsOn: [...repeatsOn],
				repeats: repeatFrequency,
				startTime,
				endTime,
				startDate: moment(startDate).toDate(),
				inPerson,
				meetingLink,
			});

			if (repeatFrequency === 'Weekly') {
				const { weekDays } = this.state;
				for (let day of repeatsOn) {
					const weekIndex = weekDays.findIndex((week) => week.value === day);
					weekDays[weekIndex].isSelected = true;
				}

				this.setState({
					weekDays,
				});
			}
		}
		if (mode === 'create' && role === 'clinician') {
			this.setState({ clinicianId: id.toString() });
		}
		if (
			mode === 'create' &&
			role === 'admin' &&
			clinicians &&
			clinicians.length
		) {
			this.setState({ clinicianId: clinicians[0].id.toString() });
		}
	}

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	handleChange = (event) => {
		const {
			target: { name, value },
		} = event;

		this.setState({ [name]: value });
	};

	handleCheck = (name) => () => {
		this.setState({ [name]: !this.state[name] });
	};

	repeating = (e) => {
		this.setState({
			repeats: e.currentTarget.textContent,
		});
	};

	weekDaysSelect = (e, index) => {
		let { weekDays, repeatsOn } = this.state;
		weekDays[index].isSelected = !weekDays[index].isSelected;

		const day = weekDays[index].value;

		if (weekDays[index].isSelected) {
			repeatsOn.push(day);
		} else {
			repeatsOn.splice(repeatsOn.indexOf(day, 1));
		}

		this.setState({
			weekDays,
			repeatsOn,
		});
	};

	handleSelect = (e) => {
		this.setState({
			clinicianId: e.currentTarget.id,
		});
	};

	handleDateChange = (id) => (date) => {
		if (isNil(date)) {
			date = 'Invalid date';
		} else {
			date = parseISO(moment(date).format('YYYY-MM-DD'));
		}

		this.setState({ [id]: date });
	};

	handleCancel = () => {
		this.closeModal();
	};

	handleCreate = () => {
		const { createProgram } = this.props;
		const {
			price,
			startDate,
			repeatsOn,
			repeats,
			location,
			startTime,
			endTime,
			groupType,
			clinicianId,
			groupSize,
			inPerson,
			meetingLink,
		} = this.state;

		// fix the repeatsOn days if converting to utc from local causes a shift
		// get the date of the first selected repeating weekday in local and utc
		const checkLocal = moment().startOf('isoWeek').add(getDayIndex(repeatsOn[0]), 'day').set({
			'hour': startTime.getHours(),
			'minute': startTime.getMinutes(),
		});
		const checkUTC = moment.utc(checkLocal);
		// compare dates of local and utc times to offset the repeatsOn days if different
		const offset = checkUTC.day() - checkLocal.day();
		const newRepeats = repeatsOn.map(day => getDayFromIndex(getDayIndex(day) + offset));

		const programData = {
			groupName: groupType,
			location,
			price,
			repeatFrequency: repeats,
			clinicianId,
			groupSize,
			startDate: moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss'),
			endDate: moment
				.utc(startDate)
				.add(6, 'months')
				.format('YYYY-MM-DD HH:mm:ss'),
			startTime: moment.utc(startTime).format('HH:mm'),
			endTime: moment.utc(endTime).format('HH:mm'),
			repeatsOn: newRepeats,
			inPerson,
			meetingLink
		};

		createProgram(programData);
		this.closeModal();
	};

	handleEdit = () => {
		const {
			price,
			startDate,
			repeatsOn,
			repeats,
			location,
			startTime,
			endTime,
			groupType,
			clinicianId,
			groupSize,
			inPerson,
			meetingLink,
		} = this.state;

		const stateData = {
			price,
			startDate,
			repeatsOn: repeatsOn,
			repeatFrequency: repeats,
			location,
			startTime: moment.utc(startTime).format('HH:mm:ss'),
			endTime: moment.utc(endTime).format('HH:mm:ss'),
			groupName: groupType,
			clinicianId,
			groupSize,
			inPerson,
			meetingLink,
		};

		const propsData = {
			groupName: this.props.groupData.groupName,
			clinicianId: this.props.groupData.clinicianId,
			groupSize: this.props.groupData.groupSize,
			location: this.props.groupData.location,
			price: this.props.groupData.price,
			startDate: this.props.groupData.startDate,
			repeatsOn: this.props.groupData.repeatsOn,
			repeatFrequency: this.props.groupData.repeatFrequency,
			startTime: this.props.groupData.startTime,
			endTime: this.props.groupData.endTime,
			inPerson: this.props.groupData.inPerson,
			meetingLink: this.props.groupData.meetingLink,
		};

		const q = getObjectDifference(stateData, propsData);
		stateData.q = q;
		stateData.programId = this.props.groupData.programId;

		const { updateProgram } = this.props;
		updateProgram(stateData);
		this.closeModal();
	};

	handleTimeChange = (id) => (time) => {
		this.setState({ [id]: time });
	};

	checkWeekdaysIsSelected = () => {
		const { weekDays } = this.state;
		for (let i = 0; i < weekDays.length; i++) {
			if (weekDays[i].isSelected === true) {
				return true;
			}
		}
		return false;
	};

	allChecked = (startTime, endTime, startDate, repeat, groupType) => {
		const isTimeError =
			startTime && endTime && moment(startTime).isAfter(moment(endTime));

		if (repeat === 'Weekly') {
			if (!this.checkWeekdaysIsSelected()) return true;
		}

		if (
			!startTime ||
			!endTime ||
			startDate === 'Invalid date' ||
			isTimeError ||
			!groupType
		) {
			return true;
		}
		return false;
	};

	render() {
		const {
			modal,
			price,
			location,
			startDate,
			groupType,
			startTime,
			endTime,
			clinicianId,
			groupSize,
			weekDays,
			meetingLink,
		} = this.state;

		const { mode, clinicians, type } = this.props;
		let selectedClinician = clinicians.filter(
			(clinician) => clinician.id.toString() === clinicianId.toString(),
		);
		selectedClinician = selectedClinician.length
			? selectedClinician[0]
			: { name: '', therapistType: '' };

		const isTimeError = Boolean(
			!startTime || !endTime || moment(startTime).isAfter(moment(endTime)),
		);

		return (
			<Modal isOpen={modal} className="modal-dialog-centered" backdrop="static">
				<ModalHeader toggle={this.closeModal} className="px-4 border-0">
					<div className="">
						{' '}
						{mode === 'create' ? 'Create Therapy' : 'Update Therapy'}
					</div>
				</ModalHeader>
				<ModalBody
					className="p-0"
					style={{
						maxHeight: 'calc(100vh - 200px)',
						overflow: 'hidden',
					}}
				>
					<div
						className="form-body px-3 mb-4"
						style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}
					>
						<Form>
							<div className="form-group">
								<label
									htmlFor="exampleInputEmail1"
									className="text-uppercase text-muted font-10"
								>
									Clinician*
								</label>
								{type === 'clinician' ? (
									<div className="d-flex align-items-center border py-0">
										<div className="d-flex align-items-start pb-2 pt-2 flex-column justify-content-start col-11">
											<ImageDetailsCell
												heading={selectedClinician.name}
												primarySubHeading={selectedClinician.therapistType}
											/>
										</div>
									</div>
								) : (
									<UncontrolledDropdown
										nav
										className="d-flex align-items-center border"
									>
										<div className="d-flex align-items-start pb-2 pt-2 flex-column justify-content-start col-11">
											<ImageDetailsCell
												heading={selectedClinician.name}
												primarySubHeading={selectedClinician.therapistType}
											/>
										</div>
										<DropdownToggle
											nav
											caret
											className="px-2 link-color col-1"
										/>
										<DropdownMenu
											right
											className="w-100"
											flip={false}
											style={{
												maxHeight: 400,
												overflowY: 'auto',
											}}
										>
											{clinicians.map((item) => (
												<DropdownItem
													key={item.id}
													onClick={this.handleSelect}
													id={item.id}
												>
													{' '}
													<ImageDetailsCell
														heading={item.name}
														primarySubHeading={item.therapistType}
													/>
												</DropdownItem>
											))}
										</DropdownMenu>
									</UncontrolledDropdown>
								)}
							</div>
							<div className="form-group">
								<label
									htmlFor="exampleInputEmail1"
									className="text-uppercase text-muted font-10"
								>
									Therapy Name*
								</label>
								<div className="d-flex align-items-center border py-2">
									<input
										type="text"
										required="required"
										value={groupType}
										id="groupType"
										name="groupType"
										className="font-17 col-8 pl-2"
										onChange={this.handleChange}
									/>
								</div>
							</div>
							<div className="form-group">
								<label
									htmlFor="exampleInputEmail1"
									className="text-uppercase text-muted font-10"
								>
									Location*
								</label>
								<Select
									value={location}
									className="custom-select"
									id="location"
									name="location"
									onChange={this.handleChange}
									disabled={mode === 'edit'}
								>
									<option key="Marin" value="Marin">
										Marin
									</option>
									<option key="San Francisco" value="San Francisco">
										San Francisco
									</option>
								</Select>
							</div>
							<div className="form-group row">
								<div className="col-6">
									<label
										htmlFor="exampleInputEmail1"
										className="text-uppercase text-muted font-10"
									>
										Size*
									</label>
									<Select
										value={groupSize}
										className="custom-select"
										id="groupSize"
										name="groupSize"
										onChange={this.handleChange}
									>
										<option key="Group" value="Group">
											Group
										</option>
										<option key="Individual" value="Individual">
											Individual
										</option>
									</Select>
								</div>
								<div className="col-6">
									<label
										htmlFor="updateGroupPrice"
										className="text-uppercase text-muted font-10"
									>
										Price per month*
									</label>
									<div className="d-flex align-items-center border py-2">
										<div className="font-17 pl-3 pr-1">$</div>
										<input
											type="number"
											required="required"
											value={price}
											min={0}
											id="price"
											name="price"
											className="font-17 col-10"
											onChange={this.handleChange}
											style={{
												height: 18,
											}}
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="form-group mt-1">
									<CustomDatePicker
										label={<span className="form-group">START DATE*</span>}
										minDate={new Date()}
										id="startDate"
										selected={startDate}
										handleDateChange={this.handleDateChange('startDate')}
										disabled={moment(startDate).isBefore(moment(), 'D')}
									/>
									<div className="d-flex mb-3">
										<div className="col-12 p-0">
											{/* <div className="col-12 p-0">
                            <label
                              htmlFor="updateGroupPrice"
                              className="text-uppercase text-muted font-10"
                            >
                              Repeats*
                            </label>
                            <Select
                              value={repeats}
                              className="custom-select"
                              id="repeats"
                              name="repeats"
                              onChange={this.handleChange}
                            >
                              {repeatAction.map((val) => (
                                <option key={val} value={val}>
                                  {val}
                                </option>
                              ))}
                            </Select>
                          </div> */}
											{this.state.repeats === 'Weekly' && (
												<div className="d-flex col p-0 justify-content-center mt-2">
													{weekDays.map((value, key) => (
														<span
															className={
																value.isSelected
																	? 'col badge badge-pill text-bold badge-success border py-2 text-uppercase font-10 hand'
																	: 'col badge badge-pill bg-white border py-2 badge-color-cust text-uppercase font-10 link-color hand'
															}
															onClick={(e) => this.weekDaysSelect(e, key)}
															key={value.name}
														>
															{value.name}
														</span>
													))}
												</div>
											)}
										</div>
									</div>
									<div className="d-flex mb-3 justify-content-between">
										<div className="col-5 p-0">
											<label
												htmlFor="updateGroupPrice"
												className="text-uppercase text-muted font-10"
											>
												Start Time*
											</label>
											{/* <div className="d-flex align-items-center border py-2 px-3"> */}
											<CustomTimePicker
												id="startTime"
												selected={startTime}
												handleTimeInput={this.handleTimeChange('startTime')}
												className="font-17"
											/>
										</div>
										<div className="col-5 p-0">
											<label
												htmlFor="updateGroupPrice"
												className="text-uppercase text-muted font-10"
											>
												{'End Time*'}
											</label>

											<CustomTimePicker
												id="endTime"
												selected={endTime}
												minTime={startTime}
												maxTime={moment()
													.set('hours', 23)
													.set('minutes', 55)
													.toDate()}
												handleTimeInput={this.handleTimeChange('endTime')}
												className="font-17 w-150"
												// timeCaption="Time"
											/>
											{isTimeError && (
												<span className="form-error">Invalid Time Range</span>
											)}
										</div>
									</div>
									<div className="form-group">
										<label
											htmlFor="meetingLink"
											className="text-uppercase text-muted font-10"
										>
											Meeting Link 
											<OverlayTrigger
												placement="top"
												overlay=
													{<Tooltip id="tooltip">
														Link must start with https://
													</Tooltip>}
											>
												<InfoIcon color="grey" className="ml-1"/>
											</OverlayTrigger>
										</label>
										<div className="d-flex align-items-center border py-2">
											<input
												type="text"
												value={meetingLink}
												id="meetingLink"
												name="meetingLink"
												className="font-17 col-8 pl-2"
												onChange={this.handleChange}
												placeholder="https://meet.google.com/abc-xyz"
											/>
										</div>
									</div>	
									<div className="d-flex justify-content-end">
										<div
											className="mr-2"
											onClick={this.handleCheck('inPerson')}
										>
											In Person
										</div>
										<CustomCheckbox
											id="inPerson"
											checked={this.state.inPerson}
											onChange={this.handleCheck('inPerson')}
											classNames={{
												container: 'align-self-center',
											}}
											styles={{
												container: { marginBottom: 34 },
											}}
										/>
									</div>
								</div>
							</div>
						</Form>
					</div>
					<div className="d-flex justify-content-end align-items-center mb-3 px-3">
						<div className="d-flex justify-content-end align-items-center">
							<button
								type="button"
								className="btn btn-primary btn-border btn-color-cust mr-2 px-5 py-3 text-uppercase font-11 link-color"
								onClick={this.handleCancel}
							>
								Cancel
							</button>
							<button
								disabled={this.allChecked(
									this.state.startTime,
									this.state.endTime,
									this.state.startDate,
									this.state.repeats,
									this.state.groupType,
								)}
								type="button"
								className="btn btn-primary btn-border primary-btn-color px-5 py-3 text-uppercase font-11"
								onClick={mode === 'edit' ? this.handleEdit : this.handleCreate}
							>
								Save
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

UpdateGroup.propTypes = {
	hide: PropTypes.func.isRequired,
	groupData: PropTypes.object,
	mode: PropTypes.string,
	clinicians: PropTypes.array,
	createProgram: PropTypes.func,
	type: PropTypes.string,
	role: PropTypes.string,
	id: PropTypes.string,
	updateProgram: PropTypes.func,
};

export default UpdateGroup;
