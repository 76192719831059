import PropTypes from 'prop-types';

import React, { useState } from 'react';

const GeneralRequest = (props) => {
	const { readOnly, submitForm, isMobile } = props;
	const [notes, setNotes] = useState(props.note);

	const handleChange = (e) => {
		if (readOnly) {
			return;
		}

		const { target } = e;
		// event.preventDefault();
		const { value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		setNotes(value);
	};

	const isFormIncomplete = () => {
		if (readOnly) return true;

		return !notes;
	};

	let containerStyle = {};

	if (isMobile) {
		containerStyle = {
			width: '90%',
			maxHeight: '74vh',
			overflowY: 'auto',
			overflowX: 'hidden',
		};
	}

	const containerClass = `container-fluid bg-white py-3 ${
		isMobile ? 'px-2 mx-auto' : 'mx-4'
	}`;
	const inputClassName = 'form-group';

	return (
		<div className="row custom-bg-color mx-0">
			<div className={containerClass} style={containerStyle}>
				<form>
					<div className={inputClassName}>
						<label
							htmlFor="notesToClinician"
							className="font-10 cust-black-global text-dark text-uppercase"
						>
							Notes To Your Clinician*
						</label>
						<textarea
							readOnly={readOnly}
							value={notes}
							className="form-control font-17 custom-no-radius"
							id="note"
							name="note"
							rows="5"
							placeholder="Note"
							onChange={handleChange}
						/>
					</div>
				</form>
			</div>
			{!readOnly && (
				<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
					<button
						onClick={() => submitForm('OPEN', { notes })}
						disabled={isFormIncomplete()}
						type="button"
						className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
					>
						<span className="mr-0 pl-3 w-100">SUBMIT</span>
						<i className="fas fa-check align-self-center" />
					</button>
				</div>
			)}
		</div>
	);
};

GeneralRequest.propTypes = {
	data: PropTypes.object,
};

export default GeneralRequest;
