import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the userDropDown state domain
 */

const selectUserDropDownDomain = (state) =>
	state.get('userDropDown', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserDropDown
 */

const makeSelectUserDropDown = () =>
	createSelector(selectUserDropDownDomain, (substate) => returnJS(substate));

const makeSelectIsUpdating = () =>
	createSelector(selectUserDropDownDomain, (substate) =>
		returnJS(substate.get('isUpdating')),
	);

const makeSelectMultiError = () =>
	createSelector(selectUserDropDownDomain, (substate) =>
		returnJS(substate.get('multiError')),
	);

const makeSelectMultiResult = () =>
	createSelector(selectUserDropDownDomain, (substate) =>
		returnJS(substate.get('multiResult')),
	);

export default makeSelectUserDropDown;
export {
	selectUserDropDownDomain,
	makeSelectIsUpdating,
	makeSelectMultiError,
	makeSelectMultiResult,
};
