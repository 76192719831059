import { startCase } from 'lodash';

/**
 *
 * UpdatePassword
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import {
	Col,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';

import Pagination from 'atoms/Pagination';
import PasswordInput from 'atoms/PasswordInput';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import classnames from 'classnames';
import { compareWords } from 'utils/columnCompare';
import { getRoleNameFromId } from 'utils/fetchRole';
import { filterByLetter } from 'utils/helpers';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class UpdatePassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			activeTab: '1',
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			newPasswordOthers: '',
			confirmPasswordOthers: '',
			errorPassword: false,
			errorConfirmPassword: false,
			errorPasswordMatch: false,
			errorPasswordOthers: false,
			errorConfirmPasswordOthers: false,
			errorPasswordMatchOthers: false,
			patientSelected: false,
			patientId: '',
			patientName: '',
			page: 0,
			usersToShow: 5,
			usersToShowDropdown: false,
			userFilter: '',
		};
	}

	passwordValidation = () => {
		let errorPassword = false;
		let errorConfirmPassword = false;
		let errorPasswordMatch = false;
		const { newPassword, confirmPassword } = this.state;

		// eslint-disable-next-line no-unused-expressions
		!newPassword ? (errorPassword = true) : (errorPassword = false);
		// eslint-disable-next-line no-unused-expressions
		!confirmPassword
			? (errorConfirmPassword = true)
			: (errorConfirmPassword = false);

		if (newPassword && confirmPassword && newPassword !== confirmPassword) {
			errorPasswordMatch = true;
		}
		this.setState({
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
		});

		if (errorPassword || errorConfirmPassword || errorPasswordMatch) {
			return true;
		}
		return false;
	};

	passwordValidationOthers = () => {
		let errorPasswordOthers = false;
		let errorConfirmPasswordOthers = false;
		let errorPasswordMatchOthers = false;
		const { newPasswordOthers, confirmPasswordOthers } = this.state;

		// eslint-disable-next-line no-unused-expressions
		!newPasswordOthers
			? (errorPasswordOthers = true)
			: (errorPasswordOthers = false);
		// eslint-disable-next-line no-unused-expressions
		!confirmPasswordOthers
			? (errorConfirmPasswordOthers = true)
			: (errorConfirmPasswordOthers = false);

		if (
			newPasswordOthers &&
			confirmPasswordOthers &&
			newPasswordOthers !== confirmPasswordOthers
		) {
			errorPasswordMatchOthers = true;
		}
		this.setState({
			errorPasswordOthers,
			errorConfirmPasswordOthers,
			errorPasswordMatchOthers,
		});

		if (
			errorPasswordOthers ||
			errorConfirmPasswordOthers ||
			errorPasswordMatchOthers
		) {
			return true;
		}
		return false;
	};

	toggleTabs = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	toggle = () => {
		const { toggle, toggleSettings } = this.props;

		toggle();
		toggleSettings();
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleSubmit = () => {
		const isPasswordValidated = this.passwordValidation();
		const { currentPassword, newPassword } = this.state;
		const { user, updatePass } = this.props;

		if (isPasswordValidated) {
			return;
		}

		const data = {
			id: user.id,
			currentPassword,
			newPassword,
		};

		updatePass(data);
	};

	handleSubmitOthers = () => {
		const isPasswordValidated = this.passwordValidationOthers();
		const { patientId, newPasswordOthers } = this.state;
		const { user, updatePassOthers } = this.props;

		if (isPasswordValidated) {
			return;
		}

		const data = {
			adminId: user.id,
			patientId,
			newPassword: newPasswordOthers,
		};

		updatePassOthers(data);
	};

	patientSelected = (patient) => {
		this.setState({
			patientName: patient.name,
			patientId: patient.id,
			patientSelected: true,
		});
	};

	showPatientList = () => {
		this.setState({ patientSelected: false });
	};

	UNSAFE_componentWillMount() {
		const { listUsers, user } = this.props;

		const data = {
			id: user.id,
		};

		listUsers(data);
	}

	toggleNumPatientsDropdown = () => {
		this.setState({
			usersToShowDropdown: !this.state.usersToShowDropdown,
		});
	};

	handleDropdownClick = (e) => {
		this.setState({
			usersToShow: parseInt(e.currentTarget.textContent),
		});
	};

	createDropdown = () => {
		const dropdownOptions = [5, 10, 20];
		const { usersToShowDropdown, usersToShow } = this.state;

		return (
			<Dropdown
				isOpen={usersToShowDropdown}
				toggle={this.toggleNumPatientsDropdown}
			>
				<DropdownToggle nav caret>
					<span className="text-dark font-13">{usersToShow}</span>
				</DropdownToggle>
				<DropdownMenu flip={false}>
					{dropdownOptions.map((option) => (
						<DropdownItem onClick={this.handleDropdownClick} key={option}>
							{option}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	};

	nextPage = (otherPages) => {
		const { page } = this.state;

		if (page < otherPages.length - 1) {
			this.setState({ page: page + 1 });
		}
	};

	prevPage = () => {
		const { page } = this.state;

		if (page > 0) {
			this.setState({ page: page - 1 });
		}
	};

	render() {
		const {
			currentPassword,
			newPassword,
			confirmPassword,
			newPasswordOthers,
			confirmPasswordOthers,
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
			errorPasswordOthers,
			errorConfirmPasswordOthers,
			errorPasswordMatchOthers,
			patientSelected,
			patientName,
			userFilter,
			page,
			usersToShow,
		} = this.state;
		const { errorMsg, users } = this.props;
		const filteredUsers = filterByLetter(users, 'name', userFilter).sort(
			(a, b) => {
				return compareWords(a.name, b.name, 'asc');
			},
		);
		const start = page * usersToShow;
		const end = Math.min(start + usersToShow, filteredUsers.length);
		const usersOnScreen = filteredUsers.slice(start, end);

		return (
			<div>
				<Modal
					className="modal-dialog-centered"
					isOpen={this.state.modal}
					toggle={this.toggle}
				>
					<ModalHeader toggle={this.toggle} className="border-remove" />
					<ModalBody>
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<h3 className="m-0 heading-update-pass">Update Password</h3>
									<div className="d-flex justify-content-center error-font mb-2 mt-3">
										{errorMsg}
									</div>
									<Nav tabs className="form-send-actions row mt-2">
										<NavItem className="col-6 p-0 text-center hand">
											<NavLink
												className={classnames({
													active: this.state.activeTab === '1',
												})}
												onClick={() => {
													this.toggleTabs('1');
												}}
											>
												SELF
											</NavLink>
										</NavItem>
										<NavItem className="col-6 p-0 text-center hand">
											<NavLink
												className={classnames({
													active: this.state.activeTab === '2',
												})}
												onClick={() => {
													this.toggleTabs('2');
												}}
											>
												OTHERS
											</NavLink>
										</NavItem>
									</Nav>

									<TabContent activeTab={this.state.activeTab}>
										<TabPane tabId="1">
											<Row>
												<Col sm="12">
													<div className="form-group mt-2">
														<PasswordInput
															label={
																<label
																	className="form-font"
																	htmlFor="currentPassword"
																>
																	CURRENT PASSWORD
																</label>
															}
															value={currentPassword}
															onChange={this.handleChange}
															type="password"
															name="currentPassword"
															className="form-control"
															placeholder="Enter current password"
														/>
													</div>
													<div className="form-group mt-3">
														<PasswordInput
															label={
																<label
																	className="form-font"
																	htmlFor="NewPassword"
																>
																	NEW PASSWORD
																</label>
															}
															value={newPassword}
															onChange={this.handleChange}
															type="password"
															name="newPassword"
															className={`form-control ${
																errorPassword ? 'is-invalid' : null
															}`}
															id="NewPassword"
															ariaDescribedBy="passwordHelp"
															placeholder="Enter new password"
														/>
													</div>
													{errorPassword && (
														<div className="invalid-feedback">
															Please enter a password.
														</div>
													)}
													<div className="form-group mt-3">
														<PasswordInput
															label={
																<label
																	className="form-font"
																	htmlFor="ConfirmPassword"
																>
																	CONFIRM NEW PASSWORD
																</label>
															}
															value={confirmPassword}
															onChange={this.handleChange}
															type="password"
															name="confirmPassword"
															className={`form-control ${
																errorConfirmPassword || errorPasswordMatch
																	? 'is-invalid'
																	: null
															}`}
															id="ConfirmPassword"
															ariaDescribedBy="confirmPasswordHelp"
															placeholder="Confirm new password"
														/>
														{errorConfirmPassword && (
															<div className="invalid-feedback">
																Re-enter your password.
															</div>
														)}
														{errorPasswordMatch && (
															<div className="invalid-feedback">
																Password does not match
															</div>
														)}
													</div>
													<div className="mt-4 mb-2">
														<button
															onClick={this.handleSubmit}
															type="button"
															className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
														>
															UPDATE PASSWORD
														</button>
													</div>
												</Col>
											</Row>
										</TabPane>
										<TabPane tabId="2">
											<Row>
												<Col sm="12">
													<div className="mt-3">
														{patientSelected === false ? (
															<div>
																<div className="row px-3">
																	<span className="form-font pt-3 font-13">
																		Users to show
																	</span>
																	{this.createDropdown()}
																</div>
																<div style={{ position: 'relative' }}>
																	<input
																		onChange={this.handleChange}
																		name="userFilter"
																		className="form-control"
																		placeholder="Search here..."
																		value={userFilter}
																	/>
																	{userFilter.length > 0 && (
																		<span
																			onClick={() =>
																				this.setState({ userFilter: '' })
																			}
																			className="hand"
																			style={{
																				position: 'absolute',
																				top: '8px',
																				right: '8px',
																				color: 'red',
																			}}
																		>
																			X
																		</span>
																	)}
																</div>
																{usersOnScreen.map((patient, i) => (
																	<div
																		className="my-3 mx-0 hand"
																		onClick={() =>
																			this.patientSelected(patient)
																		}
																		key={`${patient.name}-${i}`}
																	>
																		<ImageDetailsCell
																			heading={patient.name}
																			primarySubHeading={startCase(
																				getRoleNameFromId(patient.role),
																			)}
																			secondarySubHeading={patient.email}
																			classes={{
																				primarySubHeading: 'font-italic',
																			}}
																		/>
																		<hr />
																	</div>
																))}
																<div>
																	<Pagination
																		setPage={(page) => this.setState({ page })}
																		page={page}
																		data={filteredUsers}
																		start={start}
																		end={end}
																		dataPerPage={usersToShow}
																	/>
																</div>
															</div>
														) : (
															<div className="d-flex flex-column">
																<span
																	className="font-11 link-color hand"
																	onClick={this.showPatientList}
																>
																	<strong>Back</strong>
																</span>
																<span className="font-14">{patientName}</span>
																<div className="form-group mt-3">
																	<PasswordInput
																		label={
																			<label
																				className="form-font"
																				htmlFor="NewPasswordOthers"
																			>
																				NEW PASSWORD
																			</label>
																		}
																		value={newPasswordOthers}
																		onChange={this.handleChange}
																		type="password"
																		name="newPasswordOthers"
																		className={`form-control ${
																			errorPasswordOthers ? 'is-invalid' : null
																		}`}
																		id="NewPasswordOthers"
																		ariaDescribedBy="passwordOthersHelp"
																		placeholder="Enter new password"
																	/>
																</div>
																{errorPasswordOthers && (
																	<div className="invalid-feedback">
																		Please enter a password.
																	</div>
																)}
																<div className="form-group mt-3">
																	<PasswordInput
																		label={
																			<label
																				className="form-font"
																				htmlFor="ConfirmPasswordOthers"
																			>
																				CONFIRM NEW PASSWORD
																			</label>
																		}
																		value={confirmPasswordOthers}
																		onChange={this.handleChange}
																		type="password"
																		name="confirmPasswordOthers"
																		className={`form-control ${
																			errorConfirmPasswordOthers ||
																			errorPasswordMatchOthers
																				? 'is-invalid'
																				: null
																		}`}
																		id="ConfirmPasswordOthers"
																		ariaDescribedBy="ConfirmPasswordOthersHelp"
																		placeholder="Confirm new password"
																	/>
																	{errorConfirmPasswordOthers && (
																		<div className="invalid-feedback">
																			Re-enter your password.
																		</div>
																	)}
																	{errorPasswordMatchOthers && (
																		<div className="invalid-feedback">
																			Password does not match
																		</div>
																	)}
																</div>
																<div className="mt-4 mb-2">
																	<button
																		onClick={this.handleSubmitOthers}
																		type="button"
																		className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
																	>
																		UPDATE PASSWORD
																	</button>
																</div>
															</div>
														)}
													</div>
												</Col>
											</Row>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

UpdatePassword.propTypes = {
	user: PropTypes.object.isRequired,
	updatePass: PropTypes.func.isRequired,
	updatePassOthers: PropTypes.func.isRequired,
	listUsers: PropTypes.func.isRequired,
	patients: PropTypes.array.isRequired,
	errorMsg: PropTypes.string,
	toggle: PropTypes.func.isRequired,
	toggleSettings: PropTypes.func.isRequired,
};

export default UpdatePassword;
