/**
 *
 * AuthorizeToSharePHI
 *
 */
import PropTypes from 'prop-types';

import Form from 'react-validation/build/form';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
const AuthorizeToSharePHI = (props) => {
	const {
		authorizeToSharePHIChecked,
		authorizeToSharePHINotSignedDescribe,
		prevProgress,
		nextProgress,
		handleAcceptance,
		handleChange,
		isMobile,
	} = props;

	return (
		<Form>
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h3 className="text-center font-weight-bold mb-5 mt-5">
					Patient Authorization To Share Health Information For Prior
					Authorization Of Medication
				</h3>
				<div className="card d-flex flex-column p-4 mb-4">
					<span className="pt-1 pb-5">
						I authorize Mark Schiller, M.D. and Mind Therapy Clinic to submit my
						patient information to CoverMyMeds for the purpose of assisting in
						managing the prior authorization or other coverage determination
						process for prescription drugs. This information may include but is
						not limited to my name, date of birth, address and contact
						information, my medical condition, my treatment history, including
						prescription medications, my health insurance information, and/or
						financial information. This information is considered Protected
						Health Information (“PHI”), and is subject to local, state and
						federal regulations, and the HIPAA Privacy Rule, located at 45 CFR
						Part 160 and Subparts A and E of Part 164. Once my PHI is submitted
						to CoverMyMeds, I understand that it will be used to submit coverage
						determinations to my health plan, and may be shared with related
						physician or pharmacy staff involved in my care. I understand that
						my information will be used only to the extent necessary to submit
						coverage determinations, and will not be published to those not
						involved in my care. However, I acknowledge that once my PHI is
						disclosed to third parties, it may no longer be subject to
						protection under the HIPAA Privacy Rule. I can review the full
						privacy policy at{' '}
						<a
							href="http://www.covermymeds.com/main/privacy_policy"
							target="_blank"
							rel="noopener noreferrer"
						>
							<u>here</u>
						</a>{' '}
						or by writing to:
						<br />
						<br /> Privacy Office, <br />
						CoverMyMeds LL, 2 Miranova PI.,
						<br />
						Floor 12, Columbus, Ohio 43215
						<br /> or emailing to privacy@covermymeds.com
					</span>
					<span className="pt-1 pb-3">
						This authorization will be effective until I notify my provider that
						I do not want my information to be disclosed to CoverMyMeds. I
						understand that I can revoke this authorization at any time, that I
						am not required to sign this form, and that my healthcare provider
						cannot condition treatment or eligibility for benefits on my
						execution of this authorization. I understand that I have a right to
						receive a copy of this form.{' '}
					</span>

					<IAccept
						handleAcceptance={handleAcceptance}
						name="authorizeToSharePHIChecked"
						value={authorizeToSharePHIChecked}
					/>
					{!authorizeToSharePHIChecked && (
						<div>
							If you do not accept the above policies, please provide a reason
							below:
							<textarea
								type="text"
								name="authorizeToSharePHINotSignedDescribe"
								id="authorizeToSharePHINotSignedDescribe"
								className="form-control text-muted mt-2"
								placeholder="Describe"
								onChange={handleChange}
								value={authorizeToSharePHINotSignedDescribe}
							/>
						</div>
					)}
					<span className="pt-4 pb-5 font-italic">
						This authorization form does not form a relationship between the
						patient and CoverMyMeds or its customers. This form may be modified
						for use by healthcare providers who wish to supplement their
						existing consent program to ask for specific patient approval to use
						the CoverMyMeds service in the course of patient care. Providers
						should ensure that their consent program meets all applicable local,
						state, and federal regulations.{' '}
					</span>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={
								!authorizeToSharePHIChecked &&
								!authorizeToSharePHINotSignedDescribe
							}
							onClick={nextProgress({
								checks: {
									authorizeToSharePHIChecked,
									authorizeToSharePHINotSignedDescribe,
								},
								q: 'checks',
							})}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		</Form>
	);
};

AuthorizeToSharePHI.propTypes = {
	authorizeToSharePHIChecked: PropTypes.bool.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default AuthorizeToSharePHI;
