/**
 *
 * ReportsTable
 *
 */
// import components
import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import TextCell from 'atoms/TableCell/TextCell';
import { headerStyle } from 'containers/App/constants';
import { compareWords } from 'utils/columnCompare';

import makeSelectReports from './selectors';

const reportsColumns = [
	{
		field: 'name',
		title: 'NAME',
		headerStyle,
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => (
			<ImageDetailsCell
				src={cell.profilePicture}
				heading={cell.userName}
				primarySubHeading={cell.state}
				secondarySubHeading={cell.phoneNo}
			/>
		),
	},
	{
		field: 'age',
		title: 'AGE',
		headerStyle: { ...headerStyle, width: '60px', margin: '0px 20px' },
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => <TextCell title={cell.age} />,
	},
	{
		field: 'gender',
		title: 'GENDER',
		headerStyle: { ...headerStyle, width: '100px' },
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => <TextCell title={cell.gender} />,
		sort: true,
		sortFunc: (a, b, order) => {
			return compareWords(a.gender, b.gender, order);
		},
	},
	{
		field: 'maritalStatus',
		title: 'MARITAL STATUS',
		headerStyle: { ...headerStyle, width: '120px' },
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => <TextCell title={cell.marital_status} />,
		sort: true,
		sortFunc: (a, b, order) => {
			return compareWords(a.maerital_status, b.maerital_status, order);
		},
	},
	{
		field: 'referrer',
		title: 'REFERRER',
		headerStyle,
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => (
			<ImageDetailsCell
				src={cell.profilePicture}
				heading={cell.userName}
				primarySubHeading={cell.specialization}
			/>
		),
		sort: true,
		sortFunc: (a, b, order) => {
			return compareWords(a.userName, b.userName, order);
		},
	},
	{
		field: 'clinician',
		title: 'ASSIGNED CLINICIAN',
		headerStyle,
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => (
			<ImageDetailsCell
				src={cell.profilePicture}
				heading={cell.userName}
				primarySubHeading={cell.specialization}
			/>
		),
	},
	{
		field: 'name',
		title: 'CLIENT TYPE',
		headerStyle,
		headerClasses: 'table-header font-10 text-uppercase',
		formatter: (cell) => <TextCell title={cell.client_type} />,
	},
];

const reportsData = [
	// {
	//   name: {
	//     userName: 'Sophie Turner',
	//     state: 'Texas',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//     age: '28',
	//     gender: 'Female',
	//     marital_status: 'Single',
	//     client_type: 'Outpatient',
	//     phoneNo: '(541) 763-4453',
	//   },
	//   referrer: {
	//     userName: 'John Manson',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	//   clinician: {
	//     userName: 'Nicole Poel',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	// },
	// {
	//   name: {
	//     userName: 'Innaki Williams',
	//     state: 'Southern California',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//     age: '41',
	//     gender: 'Male',
	//     marital_status: 'Married',
	//     client_type: 'Outpatient',
	//     phoneNo: '(541) 772-6753',
	//   },
	//   referrer: {
	//     userName: 'Richard Turner',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	//   clinician: {
	//     userName: 'Jeffrey Devido',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	// },
	// {
	//   name: {
	//     userName: 'Joe Cole',
	//     state: 'San Francisco',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//     age: '38',
	//     gender: 'Male',
	//     marital_status: 'Single',
	//     client_type: 'Outpatient',
	//     phoneNo: '(541) 743-5567',
	//   },
	//   referrer: {
	//     userName: 'Richrds Manson',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	//   clinician: {
	//     userName: 'Jeffrey Devido',
	//     specialization: 'M.D., Psychiatrist',
	//     profilePicture: 'https://www.bootdey.com/img/Content/avatar/avatar7.png',
	//   },
	// },
];

/* eslint-disable react/prefer-stateless-function */
export class ReportsTable extends React.Component {
	render() {
		return (
			<div>
				<Table
					keyField="reports"
					data={reportsData}
					columns={reportsColumns}
					placeholder="No reports to display"
				/>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	reports: makeSelectReports(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);
