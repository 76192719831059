export const CHECK_ONBOARDING = 'src/intake/CHECK_ONBOARDING';
export const DOC_CREATE = 'src/intake/DOC_CREATE';
export const DOC_CREATE_SUCCESS = 'src/intake/DOC_CREATE_SUCCESS';
export const DOC_SIGN = 'src/intake/DOC_SIGN';
export const DOC_SIGN_SUCCESS = 'src/intake/DOC_SIGN_SUCCESS';
export const ERROR = 'src/intake/ERROR';
export const FETCH_DATA = 'src/intake/FETCH_DATA';
export const PATIENT_INTAKE = 'src/intake/PATIENT_INTAKE';
export const SET_PREV_PATH = 'src/intake/SET_PREV_PATH';
export const SUCCESS = 'src/intake/SUCCESS';
export const SUCCESS_CHECK_ONBOARDING = 'src/intake/SUCCESS_CHECK_ONBOARDING';

/*eslint sort-keys: "error"*/
export const STEPS = {
	Acknowledgement: 'acknowledgement',
	AdultMedical: 'adultMedical',
	AdultMedicalContinued: 'adultMedicalContinued',
	AdultMedicalHistory: 'adultMedicalHistory',
	AdultOtherHistory: 'adultOtherHistory',
	AdultPsychiatricHistory: 'adultPsychiatricHistory',
	AdultSleepHabits: 'adultSleepHabits',
	Behavioral: 'behavioral',
	ChidlAppointmentReason: 'childAppointmentReason',
	ChildAdhdInfo: 'childAdhdInfo',
	ChildInfo: 'childInfo',
	ChildOtherIntake: 'childOtherIntake',
	ChildSleepHabits: 'childSleepHabits',
	Consent: 'consent',
	DrugAlcohol: 'drugAlcohol',
	Financial: 'financial',
	Group: 'group',
	Insurance: 'insurance',
	IopIntake: 'iopIntake',
	Payment: 'payment',
	Phi: 'phi',
	Primary: 'primary',
	Privacy: 'privacy',
	Questionnaire: 'questionnaire',
	Substance: 'substance',
	Telehealth: 'telehealth',
};

export const sharedSteps = [
	STEPS.Primary,
	STEPS.Insurance,
	STEPS.Acknowledgement,
	STEPS.Financial,
	STEPS.Payment,
	STEPS.Privacy,
	STEPS.Phi,
	STEPS.Telehealth,
];

export const questionnaireSteps = [
	STEPS.Questionnaire,
	STEPS.AdultMedical,
	STEPS.AdultMedicalContinued,
	STEPS.AdultSleepHabits,
	STEPS.Substance,
	STEPS.AdultPsychiatricHistory,
	STEPS.AdultMedicalHistory,
	STEPS.AdultOtherHistory,
];

export const childSteps = [
	...sharedSteps,
	STEPS.ChildInfo,
	STEPS.ChidlAppointmentReason,
	STEPS.ChildAdhdInfo,
	STEPS.ChildSleepHabits,
	STEPS.ChildOtherIntake,
];

export const adultSteps = [...sharedSteps, ...questionnaireSteps];

export const iopPhpSteps = [
	...sharedSteps,
	STEPS.Group,
	STEPS.Behavioral,
	STEPS.DrugAlcohol,
	STEPS.Consent,
	...questionnaireSteps,
];

iopPhpSteps[4] = STEPS.IopIntake;

export const stepNameToLabel = {
	[STEPS.Acknowledgement]: 'Acknowledgements',
	[STEPS.AdultMedical]: 'Medical Information',
	[STEPS.AdultMedicalContinued]: 'Medical Continued',
	[STEPS.AdultMedicalHistory]: 'Medical History',
	[STEPS.AdultOtherHistory]: 'Other History',
	[STEPS.AdultPsychiatricHistory]: 'Psychiatric History',
	[STEPS.AdultSleepHabits]: 'Sleep Habits',
	[STEPS.Behavioral]: 'Behavioral Guidelines',
	[STEPS.ChidlAppointmentReason]: 'Reason for Appointment',
	[STEPS.ChildAdhdInfo]: 'ADHD History',
	[STEPS.ChildInfo]: 'Intake Questionnaire',
	[STEPS.ChildOtherIntake]: 'Other History',
	[STEPS.ChildSleepHabits]: 'Sleep Habits',
	[STEPS.Consent]: 'Group Therapy Consent Form',
	[STEPS.DrugAlcohol]: 'Drug and Alcohol Policy',
	[STEPS.Financial]: 'Financial Agreement',
	[STEPS.Group]: 'Group Standards',
	[STEPS.Insurance]: 'Insurance Information',
	[STEPS.IopIntake]: 'IopPhp Agreement',
	[STEPS.Payment]: 'Payment Information',
	[STEPS.Phi]: 'Authorization to Share PHI',
	[STEPS.Primary]: 'Primary Physician and Doctors',
	[STEPS.Privacy]: 'Notice of Privacy Practices',
	[STEPS.Questionnaire]: 'Intake Questionnaire',
	[STEPS.Substance]: 'Substance Abuse',
	[STEPS.Telehealth]: 'Telehealth Agreement',
};

export const patientFinalStep = {
	adult: adultSteps.length - 1,
	child: childSteps.length - 1,
	iopPhp: iopPhpSteps.length - 1,
};

export const lastSigningStep = sharedSteps.length - 1;
