/*
 *
 * Admin constants
 *
 */

export const DEFAULT_ACTION = 'src/Admin/DEFAULT_ACTION';

export const LOAD_CLIENTS = 'src/Admin/LOAD_CLIENTS';
export const LOAD_CLIENTS_SUCCESS = 'src/Admin/LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_ERROR = 'src/Admin/LOAD_CLIENTS_ERROR';

export const LOAD_CLINICIANS = 'src/Admin/LOAD_CLINICIANS';
export const LOAD_CLINICIANS_SUCCESS = 'src/Admin/LOAD_CLINICIANS_SUCCESS';
export const LOAD_CLINICIANS_ERROR = 'src/Admin/LOAD_CLINICIANS_ERROR';

export const CHANGE_CLINICIAN = 'src/Admin/CHANGE_CLINICIANS';
export const CHANGE_CLINICIAN_SUCCESS = 'src/Admin/CHANGE_CLINICIANS_SUCCESS';
export const CHANGE_CLINICIAN_ERROR = 'src/Admin/CHANGE_CLINICIANS_ERROR';
