/*
 *
 * GroupDetail reducer
 *
 */
import { fromJS } from 'immutable';

import moment from 'moment';

import { returnJS } from 'utils/helpers';

import {
	ADD_CLIENT,
	ADD_CLIENT_ERROR,
	ADD_CLIENT_SUCCESS,
	ADD_SESSION,
	ADD_SESSION_ERROR,
	ADD_SESSION_SUCCESS,
	DEFAULT_ACTION,
	DELETE_SESSIONS,
	DELETE_SESSIONS_ERROR,
	DELETE_SESSIONS_SUCCESS,
	EXTEND_SESSIONS,
	EXTEND_SESSIONS_ERROR,
	EXTEND_SESSIONS_SUCCESS,
	FETCH_ALL_CLIENTS,
	FETCH_ALL_CLIENTS_ERROR,
	FETCH_ALL_CLIENTS_SUCCESS,
	GET_ATTENDANCE,
	GET_ATTENDANCE_ERROR,
	GET_ATTENDANCE_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_ERROR,
	GET_CLIENTS_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	LOAD_PROGRAM,
	LOAD_PROGRAM_ERROR,
	LOAD_PROGRAM_SUCCESS,
	SAVE_ATTENDANCE,
	SAVE_ATTENDANCE_ERROR,
	SAVE_ATTENDANCE_SUCCESS,
	UNENROLL,
	UNENROLL_ERROR,
	UNENROLL_SUCCESS,
	UPDATE_ENROLLMENT,
	UPDATE_ENROLLMENT_ERROR,
	UPDATE_ENROLLMENT_SUCCESS,
} from './constants';

export const initialState = fromJS({
	attendance: [],
	sessions: [],
	clients: [],
	allClients: [],
	program: {},
	error: '',
	updateRequestSent: false,
	updateError: false,
	updateSuccess: false,
	reloadAttendance: false,
});

function addNewSessions(state, { newSessions }) {
	const sessions = state.get('sessions').toJS();

	newSessions.forEach((newSession) => {
		const month = moment(newSession.startDate).format('YYYY-MM');
		let currentSession = sessions.find((s) => s.month === month);

		if (!currentSession) {
			currentSession = {
				month,
				programId: newSession.programId,
				sessionDetails: [],
			};
			sessions.push(currentSession);
		}

		currentSession.sessionDetails.push({
			endDate: newSession.endDate,
			sessionId: newSession.id,
			startDate: newSession.startDate,
		});
	});

	return sessions;
}

function deleteSession(state, action) {
	const { ids } = action;
	const sessions = state.get('sessions').toJS();

	sessions.forEach((session) => {
		let { sessionDetails } = session;

		sessionDetails = sessionDetails.filter((s) => !ids.includes(s.sessionId));

		session.sessionDetails = sessionDetails;
	});
	return sessions;
}

function addNewClientSuccess(state, data) {
	const clients = returnJS(state.get('clients'));
	const attendance = returnJS(state.get('attendance'));

	return state.merge({
		clients: [...clients, data],
		attendance: [
			...attendance,
			{
				attendance: [],
				city: data.address.city,
				name: data.client.name,
				patientId: data.patientId,
				startDate: data.client.startDate,
				endDate: data.client.endDate,
			},
		],
	});
}

function groupDetailReducer(state = initialState, action) {
	let data;
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case GET_ATTENDANCE:
			return state.set('reloadAttendance', false);
		case GET_ATTENDANCE_SUCCESS:
			data = action.data;
			if (!Array.isArray(data)) {
				data = [];
			}
			return state.set('attendance', fromJS(data));
		case GET_ATTENDANCE_ERROR:
			return state.set('error', action.error);
		case SAVE_ATTENDANCE:
			return state.set('reloadAttendance', false);
		case SAVE_ATTENDANCE_SUCCESS:
			return state;
		case SAVE_ATTENDANCE_ERROR:
			return state.set('error', action.error);
		case LOAD_PROGRAM:
			return state.set('program', {});
		case LOAD_PROGRAM_SUCCESS:
			return state.set('program', action.data);
		case LOAD_PROGRAM_ERROR:
			return state.set('error', action.error);
		case ADD_SESSION:
			return state;
		case ADD_SESSION_SUCCESS:
			const sessions = state.get('sessions').toJS();
			sessions.forEach((session) => {
				if (session.month === action.data.startDate.substring(0, 7)) {
					session.sessionDetails.push({
						endDate: action.data.endDate,
						sessionId: action.data.id,
						startDate: action.data.startDate,
					});

					session.sessionDetails.sort((a, b) => {
						return moment(a.startDate).date() - moment(b.startDate).date();
					});
				}
			});

			return state.set('sessions', fromJS(sessions));
		case ADD_SESSION_ERROR:
			return state.set('error', action.error);

		case EXTEND_SESSIONS:
			return state;
		case EXTEND_SESSIONS_SUCCESS:
			return state.set('sessions', fromJS(addNewSessions(state, action.data)));
		case EXTEND_SESSIONS_ERROR:
			return state;

		case DELETE_SESSIONS:
			return state;
		case DELETE_SESSIONS_SUCCESS:
			return state.set('sessions', fromJS(deleteSession(state, action)));
		case DELETE_SESSIONS_ERROR:
			return state.set('error', action.error);
		case GET_SESSIONS:
			return state;
		case GET_SESSIONS_SUCCESS:
			data = action.data;
			if (!Array.isArray(data)) {
				data = [];
			}
			return state.set('sessions', fromJS(data));
		case GET_SESSIONS_ERROR:
			return state.set('error', action.error);
		case GET_CLIENTS:
			return state;
		case GET_CLIENTS_SUCCESS:
			data = action.data;
			if (!Array.isArray(data)) {
				data = [];
			}
			return state.set('clients', fromJS(data));
		case GET_CLIENTS_ERROR:
			return state.set('error', action.error);
		case UNENROLL:
			return state;
		case UNENROLL_SUCCESS:
			if (action.data.error) {
				return state.merge({
					unenroll: false,
				});
			}
			return state.merge({
				unenroll: true,
				reloadAttendance: true,
			});

		case UNENROLL_ERROR:
			return state.set('unenroll', false);

		case UPDATE_ENROLLMENT:
			return state.merge({
				updateRequestSent: true,
				updateError: false,
				updateSuccess: false,
			});
		case UPDATE_ENROLLMENT_SUCCESS:
			const clients = state.get('clients').toJS();

			const newData = action.data.updated[0];
			clients.forEach((data) => {
				if (data.client.patientId === newData.patientId) {
					data.client.startDate = newData.startDate;
					data.client.endDate = newData.endDate;
				}
			});

			return state.merge({
				updateRequestSent: false,
				updateSuccess: true,
				reloadAttendance: true,
				clients,
			});
		case UPDATE_ENROLLMENT_ERROR:
			return state.merge({
				updateRequestSent: false,
				updateError: true,
			});

		case FETCH_ALL_CLIENTS:
			return state.merge({
				allClients: [],
				error: '',
			});
		case FETCH_ALL_CLIENTS_SUCCESS:
			return state.merge({
				allClients: action.data,
			});
		case FETCH_ALL_CLIENTS_ERROR:
			return state.merge({
				allClients: [],
				error: action.error,
			});

		case ADD_CLIENT:
			return state.merge({
				isUpdating: true,
				error: '',
			});
		case ADD_CLIENT_SUCCESS:
			return addNewClientSuccess(state, action.data);
		case ADD_CLIENT_ERROR:
			return state.merge({
				error: action.error,
			});
		default:
			return state;
	}
}

export default groupDetailReducer;
