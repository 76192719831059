/*
 *
 * UserDropDown actions
 *
 */
import {
	DEFAULT_ACTION,
	DO_MAGIC,
	DO_MAGIC_ERROR,
	DO_MAGIC_SUCCESS,
	FETCH_DOC_LOG,
	FETCH_DOC_LOG_ERROR,
	FETCH_DOC_LOG_SUCCESS,
	TEST_ENROLLMENT,
	TEST_ENROLLMENT_ERROR,
	TEST_ENROLLMENT_SUCCESS,
	TEST_INTAKE_NEW,
	TEST_INTAKE_NEW_ERROR,
	TEST_INTAKE_NEW_SUCCESS,
	TEST_QUESTIONNAIRE,
	TEST_QUESTIONNAIRE_ERROR,
	TEST_QUESTIONNAIRE_SUCCESS,
	TEST_ROI,
	TEST_ROI_ERROR,
	TEST_ROI_SUCCESS,
	TEST_TELEHEALTH,
	TEST_TELEHEALTH_ERROR,
	TEST_TELEHEALTH_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function testTelehealth(data) {
	return {
		type: TEST_TELEHEALTH,
		data,
	};
}
export function doMagicSuccess(url) {
	return {
		type: DO_MAGIC_SUCCESS,
		url,
	};
}
export function doMagicError(error) {
	return {
		type: DO_MAGIC_ERROR,
		error,
	};
}

export function doMagic(data) {
	return {
		type: DO_MAGIC,
		data,
	};
}
export function testTelehealthSuccess(url) {
	return {
		type: TEST_TELEHEALTH_SUCCESS,
		url,
	};
}
export function testTelehealthError(error) {
	return {
		type: TEST_TELEHEALTH_ERROR,
		error,
	};
}

export function testIntakeNew(data) {
	return {
		type: TEST_INTAKE_NEW,
		data,
	};
}
export function testIntakeNewSuccess(url) {
	return {
		type: TEST_INTAKE_NEW_SUCCESS,
		url,
	};
}
export function testIntakeNewError(error) {
	return {
		type: TEST_INTAKE_NEW_ERROR,
		error,
	};
}

export function testEnrollment(data) {
	return {
		type: TEST_ENROLLMENT,
		data,
	};
}
export function testEnrollmentSuccess(url) {
	return {
		type: TEST_ENROLLMENT_SUCCESS,
		url,
	};
}
export function testEnrollmentError(error) {
	return {
		type: TEST_ENROLLMENT_ERROR,
		error,
	};
}

export function testRoi(data) {
	return {
		type: TEST_ROI,
		data,
	};
}
export function testRoiSuccess(url) {
	return {
		type: TEST_ROI_SUCCESS,
		url,
	};
}
export function testRoiError(error) {
	return {
		type: TEST_ROI_ERROR,
		error,
	};
}

export function testQuestionnaire(data) {
	return {
		type: TEST_QUESTIONNAIRE,
		data,
	};
}
export function testQuestionnaireSuccess(url) {
	return {
		type: TEST_QUESTIONNAIRE_SUCCESS,
		url,
	};
}
export function testQuestionnaireError(error) {
	return {
		type: TEST_QUESTIONNAIRE_ERROR,
		error,
	};
}

export function fetchDocLog() {
	return {
		type: FETCH_DOC_LOG,
	};
}
export function fetchDocLogSuccess(data) {
	return {
		type: FETCH_DOC_LOG_SUCCESS,
		data,
	};
}
export function fetchDocLogError(error) {
	return {
		type: FETCH_DOC_LOG_ERROR,
		error,
	};
}
