import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { createStructuredSelector } from 'reselect';

import FeedbackView from 'components/FeedbackView';
import { makeSelectAuth } from 'containers/App/selectors';
import errorImg from 'images/fail.png';
import successImg from 'images/illu_success.png';

import { submitFeedback } from './actions';
import { makeSelectError, makeSelectSuccess } from './selectors';
import './style.css';

const FeedbackForm = (props) => {
	const { error, programs, isOpen, toggle, isMobile } = props;
	const transitionTimer = 800;

	const [height, setHeight] = useState(600);
	const [topic, setTopic] = useState('');
	const [feedbackType, setFeedbackType] = useState('');
	const [anonymous, setAnonymous] = useState('no');
	const [webFeedback, setWebFeedback] = useState('');
	const [message, setMessage] = useState('');
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [selectedClinician, setSelectedClinician] = useState(null);
	const [sentFeedback, setSentFeedback] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	const calcHeight = () => {
		const subtractHeight = window.innerWidth < 1200 ? 60 : 0;
		let height = hasExtraRow() ? 740 - subtractHeight : 660 - subtractHeight;

		setHeight(height);
	};

	useEffect(() => {
		if (sentFeedback && (props.success || props.error)) {
			setSentFeedback(false);

			if (props.success) {
				setShowSuccess(true);

				setTimeout(() => {
					setTopic('');
					setMessage('');
				}, 3000);
			}

			reset();
		}
	}, [props.success, props.error, sentFeedback]);

	useEffect(() => {
		window.addEventListener('resize', calcHeight);
		calcHeight();

		return () => window.removeEventListener('resize', calcHeight);
	}, [feedbackType, calcHeight]);

	const doToggle = () => {
		setTimeout(() => {
			setShowSuccess(false);
		}, transitionTimer);

		toggle();
	};

	const reset = () => {
		setFeedbackType('');
		setWebFeedback('');
		setTopic('');
		setMessage('');
		setSelectedProgram(null);
		setSelectedClinician(null);
		setAnonymous('no');
	};

	const hasExtraRow = () => {
		return (
			feedbackType === 'program' ||
			feedbackType === 'clinician' ||
			feedbackType === 'mtcApp'
		);
	};

	const handleInput = (e) => {
		if (e.target.name === 'topic') {
			setTopic(e.target.value);
		}

		if (e.target.name === 'message') {
			setMessage(e.target.value);
		}
	};

	const handleCheck = (type) => (e) => {
		if (type === 'feedbackType') {
			setFeedbackType(e.target.id);
		} else if (type === 'anonymous') {
			setAnonymous(e.target.id);
		} else if (type === 'webFeedback') {
			setWebFeedback(e.target.id);
		}
	};

	const handleSelect = (type) => (o) => {
		switch (type) {
			case 'program':
				return setSelectedProgram(o);
			case 'clinician':
				return setSelectedClinician(o);
			default:
				return;
		}
	};

	const submitForm = () => {
		if (sentFeedback) return;

		const { auth, submitFeedback } = props;

		const data = {
			senderUserId: auth.loggedInUser.id,
			type: feedbackType,
			topic,
			message,
			anonymous: anonymous === 'yes',
		};

		switch (feedbackType) {
			case 'program':
				const programId = selectedProgram.value;
				data.feedbackData = { programId };
				break;
			case 'clinician':
				const clinicianId = selectedClinician.value;
				data.feedbackData = { clinicianId };
				break;
			case 'mtcApp':
				data.feedbackData = { appTopic: webFeedback };
				break;
			default:
				data.feedbackData = {};
				break;
		}

		setSentFeedback(true);
		submitFeedback(data);
	};

	const containerStyle = {
		transition: '0.5s height ease-in-out',
		height: `${height}px`,
		right: isMobile ? '6px' : '12px',
		boxShadow: isOpen ? '0px 0px 15px 5px rgba(230, 230, 230, 1)' : 'none',
	};
	if (!isOpen) {
		containerStyle.height = 0;
	}

	const feedback = {
		type: feedbackType,
		topic,
		message,
		anonymous,
		program: selectedProgram,
		clinician: selectedClinician,
		webFeedback,
	};

	return (
		<div className="feedback-container" style={containerStyle}>
			<CSSTransition
				in={isOpen}
				timeout={transitionTimer}
				classNames={'feedback-form'}
				appear
				unmountOnExit
			>
				<div
					className="card border-0 p-2"
					style={{ width: '100%', height: '100%', zIndex: 2 }}
				>
					<h5 className="px-3 py-2 mb-1 font-weight-bold">
						{!(showSuccess || error) && 'Tell us what you think.'}
						<button
							style={{
								position: 'absolute',
								top: '0.25rem',
								right: '0.5rem',
								borderRadius: '30px',
								fontSize: '26px',
								color: 'var(--main-grey)',
							}}
							onClick={toggle}
						>
							×
						</button>
					</h5>
					<div className="px-2" style={{ width: '100%', height: '100%' }}>
						{showSuccess ? (
							<div className="px-3 justify-content-center text-center">
								<img src={successImg} className="my-3" alt="success" />
								<h4 className="mb-3">
									Feedback sent successfully! Thank you for your submission.
								</h4>
								<button
									className="btn btn-primary btn-color"
									onClick={doToggle}
								>
									Okay
								</button>
							</div>
						) : error ? (
							<div className="px-3 justify-content-center text-center">
								<img src={errorImg} className="my-3" alt="error" />
								<h4 className="mb-3">{error}</h4>
								<button
									className="btn btn-primary btn-color"
									onClick={doToggle}
								>
									Okay
								</button>
							</div>
						) : (
							<FeedbackView
								feedback={feedback}
								handleInput={handleInput}
								handleCheck={handleCheck}
								handleSelect={handleSelect}
								submit={submitForm}
								programs={programs}
								sentFeedback={sentFeedback}
								mode={'edit'}
							/>
						)}
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	success: makeSelectSuccess(),
	error: makeSelectError(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		submitFeedback: (data) => dispatch(submitFeedback(data)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
