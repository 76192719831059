/*
 *
 * Groups actions
 *
 */
import {
	CREATE_PROGRAM,
	CREATE_PROGRAM_ERROR,
	CREATE_PROGRAM_SUCCESS,
	DEACTIVATE_PROGRAM,
	DEACTIVATE_PROGRAM_ERROR,
	DEACTIVATE_PROGRAM_SUCCESS,
	DEFAULT_ACTION,
	DELETE_PROGRAM,
	DELETE_PROGRAM_ERROR,
	DELETE_PROGRAM_SUCCESS,
	DOWNLOAD_ATTENDANCE_REPORT,
	DOWNLOAD_ATTENDANCE_REPORT_ERROR,
	LOAD_GROUP_TYPES,
	LOAD_GROUP_TYPES_ERROR,
	LOAD_GROUP_TYPES_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
	UPDATE_PROGRAM,
	UPDATE_PROGRAM_ERROR,
	UPDATE_PROGRAM_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function loadGroupTypes() {
	return {
		type: LOAD_GROUP_TYPES,
	};
}

export function loadGroupTypesSuccess(groupTypes) {
	return {
		type: LOAD_GROUP_TYPES_SUCCESS,
		groupTypes,
	};
}

export function loadGroupTypesError(err) {
	return {
		type: LOAD_GROUP_TYPES_ERROR,
		err,
	};
}

export function createProgram(data) {
	return {
		type: CREATE_PROGRAM,
		data,
	};
}

export function createProgramSuccess(data) {
	return {
		type: CREATE_PROGRAM_SUCCESS,
		data,
	};
}

export function createProgramError(error) {
	return {
		type: CREATE_PROGRAM_ERROR,
		error,
	};
}

export function updateProgram(data) {
	return {
		type: UPDATE_PROGRAM,
		data,
	};
}

export function updateProgramSuccess(data) {
	return {
		type: UPDATE_PROGRAM_SUCCESS,
		data,
	};
}

export function updateProgramError(error) {
	return {
		type: UPDATE_PROGRAM_ERROR,
		error,
	};
}

export function loadPrograms() {
	return {
		type: LOAD_PROGRAMS,
	};
}

export function loadProgramsSuccess(programs) {
	return {
		type: LOAD_PROGRAMS_SUCCESS,
		programs,
	};
}

export function loadProgramsError(error) {
	return {
		type: LOAD_PROGRAMS_ERROR,
		error,
	};
}

export function deleteProgram(data) {
	return {
		type: DELETE_PROGRAM,
		data,
	};
}

export function deleteProgramSuccess(programs) {
	return {
		type: DELETE_PROGRAM_SUCCESS,
		programs,
	};
}

export function deleteProgramError(error) {
	return {
		type: DELETE_PROGRAM_ERROR,
		error,
	};
}

export function deactivateProgram(data) {
	return {
		type: DEACTIVATE_PROGRAM,
		data,
	};
}

export function deactivateProgramSuccess(programs) {
	return {
		type: DEACTIVATE_PROGRAM_SUCCESS,
		programs,
	};
}

export function deactivateProgramError(error) {
	return {
		type: DEACTIVATE_PROGRAM_ERROR,
		error,
	};
}

export function downloadAttendanceReport(data) {
	return {
		type: DOWNLOAD_ATTENDANCE_REPORT,
		data,
	};
}

export function downloadAttendanceReportError(err) {
	return {
		type: DOWNLOAD_ATTENDANCE_REPORT_ERROR,
		err,
	};
}
