/*
 *
 * Dashboard constants
 *
 */

export const DEFAULT_ACTION = 'src/UserDropDown/DEFAULT_ACTION';

export const ENABLE_SCROLL = 'src/UserDropDown/ENABLE_SCROLL';
export const DISABLE_SCROLL = 'src/UserDropDown/DISABLE_SCROLL';
export const TOGGLE_SCROLL = 'src/UserDropDown/TOGGLE_SCROLL';
