import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the geo state domain
 */

const selectGeoDomain = (state) => state.get('geo', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Geo
 */

const makeSelectGeo = () =>
	createSelector(selectGeoDomain, (substate) => returnJS(substate));

export default makeSelectGeo;
export { selectGeoDomain };
