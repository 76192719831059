import _ from 'lodash';

import { Input } from 'reactstrap';

import DeleteButton from 'atoms/DeleteButton';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import { mtcClinTypes } from 'containers/PatientProfile/constants';

import MTCClinician from './MTCClinician';
import './style.css';

const CareGroup = (props) => {
	const { data, clinicians, dispatch } = props;

	const {
		md = {},
		therapist = {},
		mtc = [],
		outside = [],
		mainClinician = {},
	} = data;

	const clinOptions = clinicians.map((c) => ({
		label: c.name,
		value: c.id,
	}));

	const clinician = { label: mainClinician.name, value: mainClinician.id };

	const handleChange = (type, name, value, index = -1) => {
		let newData = data[type];

		if (index >= 0) {
			newData[index] = {
				...newData[index],
				[name]: value,
			};
		} else {
			newData = {
				...newData,
				[name]: value,
			};
		}

		dispatch({ name: type, value: newData });
	};
	const handleSelect = (type) => (option, index) => {
		const target = {
			type,
			clinician: { name: option.label, id: option.value },
		};
		let actionType = 'SELECT_CLINICIAN';
		if (type === 'mainClinician') {
			actionType = 'SELECT_CLINICIAN';
		} else {
			target.type = 'mtc';
			if (index >= 0) {
				target.index = index;
				actionType = 'SELECT_CLINICIAN';
			} else {
				target.clinician.type = type;
				actionType = 'ADD_CLINICIAN';
			}
		}

		dispatch(target, actionType);
	};

	const handleAddClinician = (type) => {
		dispatch({ type }, 'ADD_CLINICIAN');
	};
	const handleDeleteClinician = (type, index, id) => {
		dispatch({ type, index, id }, 'REMOVE_CLINICIAN');
	};

	const hasError = (type, i, key) => {
		let checks;

		if (key === 'type') {
			checks = ['name'];
		} else if (key === 'name') {
			checks = ['type'];
		}

		if (type === 'outside') {
			checks.push('phone');
		}

		const clin = data[type][i];

		if (!clin || clin[key] !== '') {
			return false;
		}

		for (let c of checks) {
			if (clin[c] !== '') {
				return true;
			}
		}
	};

	return (
		<div>
			<div className="form-row mt-4">
				<div className="form-group col">
					<SelectAutoComplete
						label="LEAD CLINICIAN"
						handleChange={handleSelect('mainClinician')}
						options={clinOptions}
						value={clinician}
						placeholder="Select Lead Clinician"
					/>
				</div>
			</div>
			<div className="form-row">
				<div className="form-group col">
					<label htmlFor={'primaryMD'} className="form-font">
						PRIMARY MD
					</label>
					<Input
						name="name"
						value={_.startCase(md.name)}
						placeholder="Enter Primary MD"
						onChange={(e) => handleChange('md', 'name', e.target.value)}
					/>
				</div>
				<div className="form-group col">
					<PhoneNumberInput
						label="PHONE"
						id="mdPhone"
						value={md.phone}
						placeholder="Enter 10 Digit Phone Number"
						handleInput={(id, values) =>
							handleChange('md', 'phone', values.value)
						}
					/>
				</div>
			</div>
			<div className="form-row">
				<div className="form-group col">
					<label htmlFor={'primaryTherapist'} className="form-font">
						PRIMARY THERAPIST
					</label>
					<Input
						name="name"
						value={_.startCase(therapist.name)}
						placeholder="Enter Primary Therapist Name"
						onChange={(e) => handleChange('therapist', 'name', e.target.value)}
					/>
				</div>
				<div className="form-group col">
					<PhoneNumberInput
						label="PHONE"
						id="therapistPhone"
						value={therapist.phone}
						placeholder="Enter 10 Digit Phone Number"
						handleInput={(id, values) =>
							handleChange('therapist', 'phone', values.value)
						}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				{mtcClinTypes.map((type) => {
					const clin = mtc.find((c) => c.type === type) || {};
					const i = mtc.findIndex((c) => c.type === type);

					return (
						<div className="form-group col-6" key={clin.id}>
							<SelectAutoComplete
								customLabel={
									<div
										className="row justify-content-start"
										style={{ marginBottom: clin.name ? '-0.26rem' : 0 }}
									>
										<div
											className={`col-auto form-font pr-3 ${
												clin.name ? '' : 'mb-2'
											}`}
										>
											{type}
										</div>
										<div className="col-auto px-0">
											{clin.name && (
												<DeleteButton
													onClick={() =>
														handleDeleteClinician('mtc', i, clin.ptcId)
													}
													popupText={`Unassign ${clin.name}`}
													popupAdjust={2}
													size={20}
													styles={{
														container: {
															transform: 'translate(-2px, -13px)',
														},
													}}
													classNames={{
														container: 'unassign-clin',
													}}
												/>
											)}
										</div>
									</div>
								}
								handleChange={(option) => handleSelect(type)(option, i)}
								options={clinOptions}
								value={clin.name ? { label: clin.name, value: clin.id } : null}
								placeholder={`Select ${type}`}
							/>
						</div>
					);
				})}
			</div>
			{mtc.map((clinician, i) => {
				if (mtcClinTypes.includes(clinician.type)) return null;

				return (
					<MTCClinician
						key={clinician.id}
						handleDelete={handleDeleteClinician}
						index={i}
						clinician={clinician}
						handleChange={handleChange}
						handleSelect={handleSelect('mtc')}
						clinicians={clinOptions}
					/>
				);
			})}
			<div className="form-row w-100 justify-content-end pb-3">
				<div className="col">
					<button
						disabled={false}
						onClick={() => handleAddClinician('mtc')}
						className="btn btn-white link-color border btn-lg btn-block font-15 mt-3 font-weight-bold w-100"
					>
						+ ASSIGN MTC CLINICIAN
					</button>
				</div>
			</div>
			<hr />
			{outside.map((clinician, i) => {
				const nameError = hasError('outside', i, 'name');
				const typeError = hasError('outside', i, 'type');
				return (
					<div key={`outside-${i}`}>
						<div className="form-row">
							<div className="form-group col pt-2">
								<div
									className="d-flex justify-content-start"
									style={{ marginBottom: '-0.35rem' }}
								>
									<div className="form-font pr-3">CLINICIAN TYPE</div>
									<DeleteButton
										onClick={() => handleDeleteClinician('outside', i)}
										popupText={`Unassign ${clinician.name}`}
										size={20}
										styles={{
											container: {
												transform: 'translate(-2px, -13px)',
											},
										}}
										classNames={{
											container: 'unassign-clin',
										}}
									/>
								</div>
								<Input
									name="type"
									value={_.startCase(clinician.type)}
									placeholder="Enter Clinician Type"
									onChange={(e) =>
										handleChange('outside', 'type', e.target.value, i)
									}
									className={typeError && 'input-error'}
								/>
								<span className="form-error">
									{typeError && 'Field required'}
								</span>
							</div>
							<div className="form-group col">
								<label htmlFor={`clinicianType-${i}`} className="form-font">
									CLINICIAN NAME
								</label>
								<Input
									name="name"
									value={_.startCase(clinician.name)}
									placeholder="Enter Clinician Name"
									onChange={(e) =>
										handleChange('outside', 'name', e.target.value, i)
									}
									className={nameError && 'input-error'}
								/>
								<span className="form-error">
									{nameError && 'Field required'}
								</span>
							</div>
							<div className="form-group col">
								<PhoneNumberInput
									label="PHONE"
									id={`clinicianPhone-${i}`}
									value={clinician.phone}
									placeholder="Enter 10 Digit Phone Number"
									handleInput={(id, values) =>
										handleChange('outside', 'phone', values.value, i)
									}
									style={{
										height: window.innerWidth < 1200 ? 38 : 44.75,
									}}
								/>
							</div>
						</div>
					</div>
				);
			})}
			<div className="form-row w-100 justify-content-end pb-3">
				<div className="col">
					<button
						disabled={false}
						onClick={() => handleAddClinician('outside')}
						className="btn btn-white link-color border btn-lg btn-block font-15 mt-3 font-weight-bold w-100"
					>
						+ ADD OUTSIDE CLINICIAN
					</button>
				</div>
			</div>
			<hr />
		</div>
	);
};

export default CareGroup;
