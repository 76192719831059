import { call, put, select, takeLatest } from 'redux-saga/effects';

import { UPDATE_REQUEST_SUCCESS } from 'containers/App/constants';
import { assignClinicianAPI, findPharmacy, findToDosAPI } from 'utils/api';

import {
	assignClinicianError,
	assignClinicianSuccess,
	fetchPharmaciesError,
	fetchPharmaciesSuccess,
	loadNewIntakesError,
	loadNewIntakesSuccess,
	loadOtherRequestsError,
	loadOtherRequestsSuccess,
	loadRxRequestsError,
	loadRxRequestsSuccess,
} from './actions';
import {
	ASSIGN_CLINICIAN,
	ASSIGN_CLINICIAN_SUCCESS,
	CHANGE_PHARMACY_SUCCESS,
	FETCH_PHARMACIES,
	LOAD_NEW_INTAKES,
	LOAD_OTHER_REQ,
	LOAD_RX_REQUESTS,
} from './constants';
import { makeSelectUserId } from './selectors';

export default function* toDosSaga() {
	yield takeLatest(LOAD_OTHER_REQ, getOtherRequests);
	yield takeLatest(FETCH_PHARMACIES, getPharmacies);
	yield takeLatest(UPDATE_REQUEST_SUCCESS, getOtherRequests);
	yield takeLatest(UPDATE_REQUEST_SUCCESS, getRxRequests);
	yield takeLatest(ASSIGN_CLINICIAN_SUCCESS, getRxRequests);
	yield takeLatest(CHANGE_PHARMACY_SUCCESS, getRxRequests);
	yield takeLatest(LOAD_NEW_INTAKES, getNewIntakes);
	yield takeLatest(ASSIGN_CLINICIAN_SUCCESS, getNewIntakes);
	yield takeLatest(LOAD_RX_REQUESTS, getRxRequests);
	yield takeLatest(ASSIGN_CLINICIAN, assignClinicianToPatient);
}

export function* getPharmacies({ id }) {
	try {
		const response = yield call(findPharmacy, id);
		yield put(fetchPharmaciesSuccess(response));
	} catch (err) {
		yield put(fetchPharmaciesError(err));
	}
}

export function* getOtherRequests() {
	const id = yield select(makeSelectUserId());
	const data = {
		userId: id,
		parentRequest: 'OTHER_REQ',
	};
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(findToDosAPI, data);
		yield put(loadOtherRequestsSuccess(response));
	} catch (err) {
		yield put(loadOtherRequestsError(err));
	}
}

export function* getRxRequests() {
	const id = yield select(makeSelectUserId());
	const data = {
		userId: id,
		parentRequest: 'RX_REQUESTS',
	};
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(findToDosAPI, data);
		yield put(loadRxRequestsSuccess(response));
	} catch (err) {
		yield put(loadRxRequestsError(err));
	}
}
export function* assignClinicianToPatient(actions) {
	const { data } = actions;
	try {
		const response = yield call(assignClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(assignClinicianError());
		} else {
			yield put(assignClinicianSuccess(response.data));
		}
	} catch (err) {
		yield put(assignClinicianError(err));
	}
}

export function* getNewIntakes() {
	const id = yield select(makeSelectUserId());
	const data = {
		userId: id,
		parentRequest: 'NEW_INTAKES',
	};
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(findToDosAPI, data);
		yield put(loadNewIntakesSuccess(response));
	} catch (err) {
		yield put(loadNewIntakesError(err));
	}
}
