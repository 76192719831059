import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import CustomDatePicker from 'atoms/CustomDatePicker';
import PrevNextButtons from 'atoms/PrevNextButtons';
// import components
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * ChildBasicInfo
 *
 */

/* eslint-disable react/prefer-stateless-function */
const ChildBasicInfo = (props) => {
	const { prevProgress, nextProgress, data, handleChange, handleDateChange } =
		props;
	const {
		othersPresent,
		personCompletingQuestionnaire,
		maritalStatus,
		legallyAuthorizedDecisonMaker,
		currentLivingSituation,
		allergicToMedication,
		allergyDetails,
		currentMedications,
		currentMedicationDosage,
		pastMedications,
		reasonToDiscontinue,
		name,
		dob,
		age,
	} = data;

	return (
		<div className="container-fluid d-flex flex-column px-5">
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Intake Questionnaire
			</h2>

			<Form onSubmit={nextProgress({}, 'childBasicInfo')}>
				<div className="card d-flex flex-column p-4 mb-4">
					<h3>Child / Adolescent Intake Evaluation</h3>
					<div className="row">
						<TextInput
							key="name"
							className="col-4"
							label="NAME"
							id="name"
							value={name || ''}
							handleInput={handleChange('childBasicInfo')}
							validations={[]}
							type="text"
						/>

						<CustomDatePicker
							className="col-4"
							label={<span className="form-group">DATE OF BIRTH</span>}
							id="dob"
							selected={dob}
							handleDateChange={handleDateChange('childBasicInfo', 'dob')}
							maxDate={new Date()}
							placeholderText="Select Date"
						/>
						<TextInput
							key="age"
							className="col-4"
							label="AGE"
							id="age"
							value={age}
							handleInput={handleChange('childBasicInfo')}
							validations={[]}
							type="text"
							readOnly={false}
						/>
					</div>
					<div className="row">
						<TextInput
							className="col-6"
							label="OTHERS PRESENT:"
							id="othersPresent"
							value={othersPresent}
							handleInput={handleChange('childBasicInfo')}
						/>
						<TextInput
							className="col-6"
							label="PERSON COMPLETING THIS INTAKE QUESTIONNAIRE:"
							id="personCompletingQuestionnaire"
							value={personCompletingQuestionnaire}
							handleInput={handleChange('childBasicInfo')}
						/>
					</div>

					<div className="row mb-3">
						<div className="col-12">
							<label htmlFor="maritalStatus" className="form-font">
								MARITAL STATUS:
							</label>
							<SelectButtonGroup
								id="maritalStatus"
								value={maritalStatus}
								buttons={generateObjectArray([
									'Married',
									'Divorced',
									'Separated',
									'Single',
									'In Relationship',
								])}
								handleSelect={handleChange('childBasicInfo')}
							/>
						</div>
					</div>

					<TextInput
						className="mb-3"
						label="PLEASE NAME ALL PERSONS WHO HAVE LEGAL MEDICAL DECISION-MAKING AUTHORITY FOR THIS PATIENT?"
						id="legallyAuthorizedDecisonMaker"
						value={legallyAuthorizedDecisonMaker}
						handleInput={handleChange('childBasicInfo')}
					/>

					<TextInput
						className="mb-3"
						label="CURRENT LIVING SITUATION (RELATIONSHIP OF PERSON(S) WITH WHOM PATIENT RESIDES) ?"
						id="currentLivingSituation"
						value={currentLivingSituation}
						handleInput={handleChange('childBasicInfo')}
					/>

					<div className="row mb-5">
						<div className="col-4">
							<label htmlFor="allergicToMedication" className="form-font">
								ALLERGIC TO MEDICATION:
							</label>
							<SelectButtonGroup
								id="allergicToMedication"
								value={allergicToMedication}
								buttons={generateObjectArray(['Yes', 'No'])}
								handleSelect={handleChange('childBasicInfo')}
							/>
						</div>
						{allergicToMedication === 'yes' && (
							<TextInput
								className="col-8"
								label="IF YES, PLEASE DESCRIBE EACH ALLERGY AND ADVERSE REACTIONS:"
								id="allergyDetails"
								value={allergyDetails}
								handleInput={handleChange('childBasicInfo')}
							/>
						)}
					</div>

					<label htmlFor="currentMedications" className="form-font mb-3">
						CURRENT MEDICATIONS: (IMPORTANT: PLEASE LIST ANY PRESCRIPTION AND
						NON_PRECREPTION MEDICATIONS, VITAMINS, SUPPLIMENTS OR HERBS. INCLUDE
						NAME, DOSE, AND HOW OFTEN TAKEN):
					</label>

					<div className="row mb-3">
						<TextArea
							className="col-12"
							label="LIST ALL CURRRENT MEDICATION NAME(S)"
							id="currentMedications"
							value={currentMedications}
							handleInput={handleChange('childBasicInfo')}
						/>
						<TextArea
							className="col-12"
							label="LIST THE DOSAGE AND HOW OFTEN TAKEN FOR EACH MEDICATION LISTED ABOVE:"
							id="currentMedicationDosage"
							value={currentMedicationDosage}
							handleInput={handleChange('childBasicInfo')}
						/>
						<TextArea
							className="col-12"
							label="LIST ALL PAST MEDICATION(S)"
							id="pastMedications"
							value={pastMedications}
							handleInput={handleChange('childBasicInfo')}
						/>
						<TextArea
							className="col-12"
							label="WHY DID YOU DISCONTINUE TAKING EACH MEDICATION LISTED ABOVE:"
							id="reasonToDiscontinue"
							value={reasonToDiscontinue}
							handleInput={handleChange('childBasicInfo')}
						/>
					</div>
				</div>

				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

ChildBasicInfo.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleDateChange: PropTypes.func.isRequired,
};

export default ChildBasicInfo;
