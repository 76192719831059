const formFilters = (options) => {
	return [
		{
			type: 'Dropdown Input',
			name: 'client.name',
			selectOptions: options.clientName,
			selectLabel: 'CLIENT NAME',
			defaultSelect: 'All Clients',
		},
	];
};

export default formFilters;
