/*
 *
 * Attendance actions
 *
 */
import {
	DEFAULT_ACTION,
	DELETE_SESSIONS,
	DELETE_SESSIONS_ERROR,
	DELETE_SESSIONS_SUCCESS,
	EXTEND_SESSIONS,
	EXTEND_SESSIONS_ERROR,
	EXTEND_SESSIONS_SUCCESS,
	GET_ATTENDANCE,
	GET_ATTENDANCE_ERROR,
	GET_ATTENDANCE_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
	SAVE_ATTENDANCE,
	SAVE_ATTENDANCE_ERROR,
	SAVE_ATTENDANCE_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function getAttendance(data, cb) {
	return {
		type: GET_ATTENDANCE,
		data,
		cb,
	};
}

export function getAttendanceSuccess(data) {
	return {
		type: GET_ATTENDANCE_SUCCESS,
		data,
	};
}

export function getAttendanceError(error) {
	return {
		type: GET_ATTENDANCE_ERROR,
		error,
	};
}

export function saveAttendance(data, success, err) {
	return {
		type: SAVE_ATTENDANCE,
		data,
		success,
		err,
	};
}

export function saveAttendanceSuccess(data) {
	return {
		type: SAVE_ATTENDANCE_SUCCESS,
		data,
	};
}

export function saveAttendanceError(error) {
	return {
		type: SAVE_ATTENDANCE_ERROR,
		error,
	};
}

export function getSessions(data) {
	return {
		type: GET_SESSIONS,
		data,
	};
}
export function getSessionsSuccess(data) {
	return {
		type: GET_SESSIONS_SUCCESS,
		data,
	};
}
export function getSessionsError(error) {
	return {
		type: GET_SESSIONS_ERROR,
		error,
	};
}

export function loadPrograms() {
	return {
		type: LOAD_PROGRAMS,
	};
}

export function loadProgramsSuccess(programs) {
	return {
		type: LOAD_PROGRAMS_SUCCESS,
		programs,
	};
}

export function loadProgramsError(error) {
	return {
		type: LOAD_PROGRAMS_ERROR,
		error,
	};
}

export function extendSessions(data, cb) {
	return {
		type: EXTEND_SESSIONS,
		data,
		cb,
	};
}
export function extendSessionsSuccess(data) {
	return {
		type: EXTEND_SESSIONS_SUCCESS,
		data,
	};
}
export function extendSessionsError(error) {
	return {
		type: EXTEND_SESSIONS_ERROR,
		error,
	};
}

export function deleteSessions(data) {
	return {
		type: DELETE_SESSIONS,
		data,
	};
}
export function deleteSessionsSuccess(ids) {
	return {
		type: DELETE_SESSIONS_SUCCESS,
		ids,
	};
}
export function deleteSessionsError(error) {
	return {
		type: DELETE_SESSIONS_ERROR,
		error,
	};
}
