/* eslint-disable indent */
import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SelectAutoComplete from 'atoms/SelectAutoComplete';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import ExtendSessionsModal from 'components/ExtendSessionsModal';
import GroupAttendance from 'components/GroupAttendance';
import { generateToast } from 'containers/App/actions';
import { makeSelectAuth } from 'containers/App/selectors';

import {
	deleteSessions,
	extendSessions,
	getAttendance,
	getSessions,
	loadPrograms,
	saveAttendance,
} from './actions';
import './index.css';
import makeSelectAttendance, {
	makeSelectAllPrograms,
	makeSelectGetAttendance,
	makeSelectGetSessions,
	makeSelectLoadingPrograms,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class Attendance extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedProgram: {},
			isSaving: false,
			showExtendSessions: false,
		};
	}

	componentDidMount() {
		const { fetchProgram } = this.props;
		fetchProgram();
	}

	componentDidUpdate() {
		const { selectedProgram } = this.state;
		const { programs, fetchAttendance, fetchSessions } = this.props;

		if (!selectedProgram.value && programs.length > 0) {
			const [program] = programs;
			this.setState({
				selectedProgram: {
					value: program.groupName,
					label: program.groupName,
					program,
				},
			});
			fetchAttendance({
				programId: program.programId,
				month: moment().format('M'),
				year: moment().format('YYYY'),
			});
			fetchSessions({
				programId: program.programId,
			});
		}
	}

	getOptions = () => {
		let { programs } = this.props;

		if (!Array.isArray(programs)) {
			programs = [];
		}

		return programs.map((program) => ({
			value: program.groupName,
			label: program.groupName,
			program,
		}));
	};

	handleProgramChange = (program) => {
		const { fetchAttendance, fetchSessions } = this.props;
		this.setState({ selectedProgram: program });
		fetchAttendance({
			programId: program.program.programId,
			month: moment().format('M'),
			year: moment().format('YYYY'),
		});
		fetchSessions({
			programId: program.program.programId,
		});
	};

	saveAttendance = (data) => {
		const { doSaveAttendance, createToast } = this.props;
		this.setState({ isSaving: true });
		doSaveAttendance(
			data.attendance,
			() => {
				createToast({
					text: 'Attendance Marked Successfully',
					type: 'custom-success',
				});
				this.setState({ isSaving: false });
			},
			() => {
				createToast({
					text: 'Attendance Could Not Be Taken',
					position: 'bottom-left',
					type: 'custom-error',
				});
				this.setState({ isSaving: false });
			},
		);
	};

	extendSessions = (data) => {
		const { doExtendSessions, createToast } = this.props;
		const {
			selectedProgram: { program },
		} = this.state;
		const { programId, startTime, endTime } = program;

		doExtendSessions({ ...data, programId, startTime, endTime }, (sessions) => {
			if (sessions?.length > 0) {
				const endDate = moment(sessions[sessions.length - 1].endDate).format(
					'MM-DD-YYYY',
				);
				createToast({
					text: `Sessions extended successfully! New end date is ${endDate}.`,
					type: 'custom-success',
				});
			} else {
				createToast({
					type: 'custom-error',
					text: 'Could not extend sessions. Please contact an administrator.',
				});
			}
		});
		this.toggleShowExtendSessions();
		this.setState({ isWorking: true });
	};

	toggleShowExtendSessions = () => {
		this.setState((prevState) => ({
			showExtendSessions: !prevState.showExtendSessions,
		}));
	};

	getCurrentLastSession = () => {
		const sessions = this.props.sessions[this.props.sessions.length - 1];
		const date =
			sessions.sessionDetails[sessions.sessionDetails.length - 1].endDate;
		return date && moment(date).format('MM-DD-YYYY');
	};

	render() {
		const { selectedProgram, isSaving, showExtendSessions } = this.state;
		const {
			fetchAttendance,
			attendances,
			fetchSessions,
			sessions,
			type,
			doDeleteSessions,
			auth,
			loadingPrograms,
		} = this.props;
		const options = this.getOptions();
		return (
			<div className="container-fluid px-5 mt-5">
				<h6 className="text-dark cust-clinician-attendance-heading font-10 text-uppercase">
					<strong>Attendance</strong>
				</h6>
				<div className="col-12 p-3 mb-3 d-flex justify-content-between filter-nav bg-white">
					<div style={{ width: '20rem' }}>
						{loadingPrograms ? (
							<div className="border">
								<SmallLoadingIndicator
									color="var(--main-color)"
									fontSize="20px"
									style={{ height: '20px', margin: '0px 8px' }}
									className="my-0"
									text="Loading Therapies"
									inline
								/>
							</div>
						) : (
							<SelectAutoComplete
								handleChange={this.handleProgramChange}
								options={options}
								value={selectedProgram}
								placeholder="Select Therapy"
								indicatorClass="fas fa-sort dropdown-indicator text-primary"
								optionStyles={{ fontSize: '14px' }}
								singleValueStyles={{
									color: 'var(--main-color)',
									fontSize: '14px',
								}}
							/>
						)}
					</div>
				</div>
				{selectedProgram.program && (
					<GroupAttendance
						auth={auth}
						type={type}
						programDetails={selectedProgram.program}
						attendance={attendances}
						fetchAttendance={fetchAttendance}
						saveAttendance={this.saveAttendance}
						sessions={sessions}
						fetchSessions={fetchSessions}
						toggleShowAddSession={this.toggleShowAddSession}
						deleteSessions={doDeleteSessions}
						isSaving={isSaving}
						toggleShowExtendSessions={this.toggleShowExtendSessions}
					/>
				)}
				{showExtendSessions && (
					<ExtendSessionsModal
						hide={this.toggleShowExtendSessions}
						details={selectedProgram.program}
						onSubmit={this.extendSessions}
						lastSession={this.getCurrentLastSession()}
					/>
				)}
			</div>
		);
	}
}

Attendance.propTypes = {
	type: PropTypes.string.isRequired,
	fetchAttendance: PropTypes.func,
	doUpdateAttendance: PropTypes.func,
	fetchProgram: PropTypes.func,
	attendances: PropTypes.array,
	fetchSessions: PropTypes.func,
	sessions: PropTypes.array,
	programs: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	attendance: makeSelectAttendance(),
	attendances: makeSelectGetAttendance(),
	programs: makeSelectAllPrograms(),
	sessions: makeSelectGetSessions(),
	loadingPrograms: makeSelectLoadingPrograms(),
	auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchAttendance: (data, cb) => dispatch(getAttendance(data, cb)),
		doSaveAttendance: (data, success, err) =>
			dispatch(saveAttendance({ attendance: data }, success, err)),
		fetchSessions: (data) => dispatch(getSessions(data)),
		fetchProgram: () => dispatch(loadPrograms()),
		doDeleteSessions: (ids) => dispatch(deleteSessions(ids)),
		doExtendSessions: (data, cb) => dispatch(extendSessions(data, cb)),
		createToast: (data) => dispatch(generateToast(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
