/*
 *
 * UserDropDown actions
 *
 */
import {
	DEFAULT_ACTION,
	ERROR,
	LIST_USERS,
	LOGOUT,
	PASSWORD_ERROR,
	PASSWORD_VERIFIED,
	REMOVE_PASSWORD_ERROR,
	TOGGLE_EMAIL,
	TOGGLE_NOTIFICATIONS,
	TOGGLE_PASSWORD,
	TOGGLE_PHONE,
	TOGGLE_SETTINGS,
	UPDATE_AUTH,
	UPDATE_EMAIL,
	UPDATE_MULTI,
	UPDATE_MULTI_ERROR,
	UPDATE_MULTI_SUCCESS,
	UPDATE_NAME,
	UPDATE_PASSWORD,
	UPDATE_PASSWORD_OTHERS,
	UPDATE_PHONE,
	UPDATE_USERS,
	VERIFY_PASSWORD,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function toggleNotifications() {
	return {
		type: TOGGLE_NOTIFICATIONS,
	};
}

export function toggleSettings() {
	return {
		type: TOGGLE_SETTINGS,
	};
}

export function toggleEmail() {
	return {
		type: TOGGLE_EMAIL,
	};
}

export function togglePassword() {
	return {
		type: TOGGLE_PASSWORD,
	};
}

export function togglePhone() {
	return {
		type: TOGGLE_PHONE,
	};
}

export function verifyPassword(data) {
	return {
		type: VERIFY_PASSWORD,
		data,
	};
}

export function updateName(data) {
	return {
		type: UPDATE_NAME,
		data,
	};
}

export function updateEmail(data) {
	return {
		type: UPDATE_EMAIL,
		data,
	};
}

export function updatePhone(data) {
	return {
		type: UPDATE_PHONE,
		data,
	};
}

export function updatePassword(data) {
	return {
		type: UPDATE_PASSWORD,
		data,
	};
}

export function updatePasswordOther(data) {
	return {
		type: UPDATE_PASSWORD_OTHERS,
		data,
	};
}

export function listUsers(data) {
	return {
		type: LIST_USERS,
		data,
	};
}

export function updateUsers(users) {
	return {
		type: UPDATE_USERS,
		users,
	};
}

export function updateAuth(auth) {
	return {
		type: UPDATE_AUTH,
		auth,
	};
}

export function updateMulti(data) {
	return {
		type: UPDATE_MULTI,
		data,
	};
}
export function updateMultiSuccess(data) {
	return {
		type: UPDATE_MULTI_SUCCESS,
		data,
	};
}
export function updateMultiError(err) {
	return {
		type: UPDATE_MULTI_ERROR,
		err,
	};
}

export function passwordVerified() {
	return {
		type: PASSWORD_VERIFIED,
	};
}

export function showPasswordError(errorMsg) {
	return {
		type: PASSWORD_ERROR,
		errorMsg,
	};
}

export function removePasswordError(nullMsg) {
	return {
		type: REMOVE_PASSWORD_ERROR,
		nullMsg,
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export function error() {
	return {
		type: ERROR,
	};
}
