import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomButton from 'atoms/CustomButton';
import {
	MAX_HOURS_CHECK_IN,
	MIN_HOURS_CHECK_IN,
	isProduction,
} from 'utils/constants';

export default function NextSessionCell({
	upcomingSession,
	upcomingSessionId,
	status,
	type,
	checkIn,
	inPerson,
}) {
	const history = useHistory();
	const permissions = useSelector(
		(state) => state.get('app').get('permissions')?.toJS() || [],
	);
	const [timeRemaining, setTimeRemaining] = useState(
		moment.utc(upcomingSession).fromNow(),
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining(moment.utc(upcomingSession).fromNow());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const diff = moment.utc(upcomingSession).diff(moment(), 'hours', true);
	const inTimeRange =
		upcomingSession && diff <= MAX_HOURS_CHECK_IN && diff >= MIN_HOURS_CHECK_IN;
	const canCheckIn =
		(!isProduction && upcomingSession) ||
		(inPerson && type === 'patient' && inTimeRange);
	const checkedIn = status && status !== '';
	const hasPermission = permissions.find(
		(permission) => permission === 'check-in',
	);

	let content = '-';
	if (upcomingSession) {
		content =
			diff < 24
				? timeRemaining
				: moment.utc(upcomingSession).format('YYYY-MM-DD');
	}
	let checkInButton;
	if (type === 'patient') {
		let button;

		if (checkedIn) {
			button = (
				<div
					className="badge badge-pill text-bold border px-3 py-2 text-uppercase font-10"
					style={{
						backgroundColor: `var(--attendance-${status})`,
						color: 'white',
					}}
				>
					{status}
				</div>
			);
		} else if (canCheckIn) {
			button = (
				<CustomButton
					title="Check-In"
					type="action"
					onClick={() =>
						hasPermission
							? checkIn(upcomingSessionId)
							: history.push('/patient/dashboard/modal/covid')
					}
				/>
			);
		}

		checkInButton = <div className="col-auto mt-2 px-0">{button}</div>;
	}

	return (
		<div className="row font-13 align-items-center">
			<div className="col-auto mt-2">{content}</div>
			{checkInButton}
		</div>
	);
}
