import React, { useCallback, useEffect, useState } from 'react';

import PhoneNumberInput from 'atoms/PhoneNumberInput';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import GeoDropdown from 'components/GeoDropdown';
import useIsMobile from 'hooks/isMobile.ts';

const initPharmacy = {
	name: '',
	phone: '',
	street: '',
	state: '',
	city: '',
	zipcode: '',
};
const emptyPharm = {
	pharmacyName: '',
	rxNumber: '',
	pharmacyAddress: '',
	pharmacyState: '',
	pharmacyCity: '',
	pharmacyZipcode: '',
};

const dbNames = {
	name: 'pharmacyName',
	phone: 'rxNumber',
	street: 'pharmacyAddress',
	state: 'pharmacyState',
	city: 'pharmacyCity',
	zipcode: 'pharmacyZipcode',
};

function PharmacyInput({
	pharmacies = [],
	dispatch,
	readOnly = false,
	cities = [],
	states = [],
	zipcodes = [],
	selectedPharmacyId,
	errors = {},
}) {
	const [isAdding, setIsAdding] = useState(false);
	const [selectedPharmacy, setSelectedPharmacy] = useState(
		readOnly ? pharmacies[0] : initPharmacy,
	);

	useEffect(() => {
		if (pharmacies.length > 0 && selectedPharmacyId) {
			const pharmacy = pharmacies.find((p) => p.id === selectedPharmacyId);
			if (pharmacy) {
				setIsAdding(false);
				setSelectedPharmacy(pharmacy);
			}
		}
	}, [pharmacies, pharmacies.length, selectedPharmacyId]);

	const isMobile = useIsMobile();

	const inputGroupName = `${
		isMobile ? 'row justify-content-center' : 'd-flex'
	}`;
	const inputClassName = `${isMobile ? 'col-auto' : 'col-6'}`;
	const inputStyle = isMobile ? { width: '100%', maxWidth: '400px' } : {};

	const cancelAdding = () => {
		setIsAdding(false);
		if (pharmacies.length > 0) {
			if (selectedPharmacyId) {
				const pharmacy = pharmacies.find((p) => p.id === selectedPharmacyId);
				if (pharmacy) {
					setSelectedPharmacy(pharmacy);
				} else {
					setSelectedPharmacy(pharmacies[0]);
				}
			} else {
				setSelectedPharmacy(pharmacies[0]);
			}
		} else {
			setSelectedPharmacy(initPharmacy);
			dispatch({ type: 'CHANGE', name: 'pharmacyId', value: -1 });
		}
	};

	const handleSelect = (option) => {
		const { value } = option;
		if (value === -1) {
			setIsAdding(true);
			setSelectedPharmacy(initPharmacy);
		} else {
			const pharmacy = pharmacies.find((p) => p.id === value);
			setSelectedPharmacy(pharmacy);
		}

		dispatch({ type: 'CHANGE', name: 'pharmacyId', value });
	};

	const handleChange = (e) => {
		const { target } = e;
		const { name, value } = target;

		setSelectedPharmacy({
			...selectedPharmacy,
			[name]: value,
		});
		dispatch({
			type: 'CHANGE',
			name: dbNames[name],
			value,
		});
	};

	const handleNumberChange = (_, values) => {
		setSelectedPharmacy({
			...selectedPharmacy,
			phone: values.formattedValue,
		});

		dispatch({
			type: 'CHANGE',
			name: 'rxNumber',
			value: values.formattedValue,
		});
	};

	const handleGeoChange = (type) => (data) => {
		if (type === 'state') {
			setSelectedPharmacy({
				...selectedPharmacy,
				state: data,
				city: null,
				zipcode: null,
			});
			dispatch({
				type: 'CHANGE',
				name: 'pharmacyState',
				value: data,
			});
		} else if (type === 'city') {
			setSelectedPharmacy({
				...selectedPharmacy,
				city: data,
				zipcode: null,
			});
			dispatch({
				type: 'CHANGE',
				name: 'pharmacyCity',
				value: data,
			});
		} else if (type === 'zipcode') {
			setSelectedPharmacy({
				...selectedPharmacy,
				zipcode: data,
			});
			dispatch({
				type: 'CHANGE',
				name: 'pharmacyZipcode',
				value: data,
			});
		}
	};

	const pharmacyOptions = () => {
		if (!Array.isArray(pharmacies)) return [];
		return [
			...pharmacies.map((p) => ({ label: p.name, value: p.id })),
			{ label: 'Add new pharmacy', value: -1 },
		];
	};

	const toDBKeys = (data) => ({
		pharmacyId: data.id,
		pharmacyName: data.name,
		rxNumber: data.phone,
		pharmacyAddress: data.street,
		pharmacyCity: data.city,
		pharmacyState: data.state,
		pharmacyZipcode: data.zipcode,
	});

	useEffect(() => {
		if (selectedPharmacy.id && selectedPharmacy.id > 0) {
			dispatch({
				type: 'SELECT',
				value: toDBKeys(selectedPharmacy),
			});
		} else {
			dispatch({
				type: 'SELECT',
				value: emptyPharm,
			});
		}
	}, [selectedPharmacy.id]);

	useEffect(
		useCallback(() => {
			if (isAdding && pharmacies.length > 0) {
				cancelAdding();
			} else if (pharmacies.length === 0) {
				setIsAdding(true);
			}
		}, [isAdding, pharmacies]),
		[pharmacies.length],
	);

	return (
		<>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<label
						htmlFor="name"
						className="w-100 text-uppercase text-dark font-10 justify-content-between"
					>
						<span className="cust-black-global">
							{isAdding ? 'Pharmacy Name*' : 'Selected Pharmacy*'}
						</span>
						{!readOnly && isAdding && pharmacies.length > 0 && (
							<span
								className="float-right link-color hand"
								onClick={cancelAdding}
							>
								Cancel
							</span>
						)}
					</label>
					{isAdding || readOnly ? (
						<div className="form-group">
							<div
								className="d-flex align-items-center border p-2 mb-3"
								style={
									errors.pharmacyName
										? { border: '1px solid #e99083 !important' }
										: {}
								}
							>
								<input
									readOnly={readOnly}
									type="text"
									id="name"
									name="name"
									value={selectedPharmacy.name}
									aria-describedby="name"
									placeholder="Pharmacy Name"
									className="font-17"
									onChange={handleChange}
								/>
							</div>
							{errors.pharmacyName && (
								<div className="form-error">Name is required</div>
							)}
						</div>
					) : (
						<SelectAutoComplete
							handleChange={handleSelect}
							options={pharmacyOptions()}
							value={{
								label: selectedPharmacy.name,
								value: selectedPharmacy.id,
							}}
							controlStyles={{
								height: 48,
							}}
						/>
					)}
				</div>
				<div className={inputClassName} style={inputStyle}>
					<PhoneNumberInput
						id="pharamcyNumber"
						label="PHARMACY PHONE*"
						labelClass="text-uppercase cust-black-global text-dark font-10"
						value={selectedPharmacy.phone}
						placeholder="Enter Pharmacy Phone"
						handleInput={handleNumberChange}
						readOnly={readOnly}
						style={errors.rxNumber ? { borderColor: '#e99083 !important' } : {}}
					/>
					{errors.rxNumber && (
						<div className="form-error">Phone is required</div>
					)}
				</div>
			</div>
			<div className={inputGroupName}>
				<div className="form-group col-12">
					<label
						htmlFor="street"
						className="w-100 text-uppercase text-dark font-10 justify-content-between"
					>
						<span className="cust-black-global">Pharmacy Address*</span>
					</label>
					<div className="d-flex align-items-center border p-2">
						<input
							readOnly={readOnly}
							type="text"
							id="street"
							name="street"
							value={selectedPharmacy.street}
							aria-describedby="street"
							placeholder="Pharmacy Street"
							className="font-17"
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
			{readOnly ? (
				<div className={inputGroupName}>
					<div className="form-group col-4" style={inputStyle}>
						<label
							htmlFor="state"
							className="w-100 text-uppercase text-dark font-10 justify-content-between"
						>
							<span className="cust-black-global">Pharmacy State*</span>
						</label>
						<div className="d-flex align-items-center border p-2">
							<input
								readOnly={readOnly}
								type="text"
								id="state"
								name="state"
								value={selectedPharmacy.state.value || selectedPharmacy.state}
								aria-describedby="state"
								placeholder="Pharmacy State"
								className="font-17"
							/>
						</div>
					</div>
					<div className="form-group col-4" style={inputStyle}>
						<label
							htmlFor="city"
							className="w-100 text-uppercase text-dark font-10 justify-content-between"
						>
							<span className="cust-black-global">Pharmacy City*</span>
						</label>
						<div className="d-flex align-items-center border p-2">
							<input
								readOnly={readOnly}
								type="text"
								id="city"
								name="city"
								value={selectedPharmacy.city.value || selectedPharmacy.city}
								aria-describedby="city"
								placeholder="Pharmacy City"
								className="font-17"
							/>
						</div>
					</div>
					<div className="form-group col-4" style={inputStyle}>
						<label
							htmlFor="zipcode"
							className="w-100 text-uppercase text-dark font-10 justify-content-between"
						>
							<span className="cust-black-global">Pharmacy Zipcode*</span>
						</label>
						<div className="d-flex align-items-center border p-2">
							<input
								readOnly={readOnly}
								type="text"
								id="zipcode"
								name="zipcode"
								value={
									selectedPharmacy.zipcode.value || selectedPharmacy.zipcode
								}
								aria-describedby="zipcode"
								placeholder="Pharmacy Zipcode"
								className="font-17"
							/>
						</div>
					</div>
				</div>
			) : (
				<div className={inputGroupName}>
					<div className="form-group col-12">
						<GeoDropdown
							states={states}
							cities={
								selectedPharmacy.state
									? cities[selectedPharmacy.state.value]
									: []
							}
							zipcodes={
								selectedPharmacy.city
									? zipcodes[selectedPharmacy.city.value]
									: []
							}
							handleChange={handleGeoChange}
							state={selectedPharmacy.state}
							city={selectedPharmacy.city}
							zipcode={selectedPharmacy.zipcode}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default PharmacyInput;
