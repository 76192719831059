import PropTypes from 'prop-types';

import React from 'react';
import Button from 'react-validation/build/button';
import Form from 'react-validation/build/form';

/* eslint-disable prefer-const */
import Select from 'react-validation/build/select';

import PhoneNumberInput from 'atoms/PhoneNumberInput';
import TextInput from 'atoms/TextInput';
import { required } from 'utils/validations';

/**
 *
 * PatientOtherDetails
 *
 */

// const referredByOptions = generateObjectArray([
//   'DOCTOR',
//   'THERAPIST',
//   'INSURANCE',
//   'TREATMENT',
//   'FAMILY',
//   'FRIEND',
//   'INTERNET',
//   'OTHERS',
// ]);
/* eslint-disable react/prefer-stateless-function */
class PatientOtherDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			occupation: '',
			employer: '',
			employerPhone: null,
			referredBy: {
				type: '',
				name: '',
			},
			otherFamilyName: '',
		};
	}

	componentDidMount() {
		const { patientData } = this.props;
		if (
			patientData.otherDetails &&
			Object.getOwnPropertyNames(patientData.otherDetails).length > 0
		) {
			const arr = Object.getOwnPropertyNames(patientData.otherDetails);
			for (let i = 0; i < arr.length; i++) {
				const val = arr[i];
				this.setState({ [val]: patientData.otherDetails[val] });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientData.otherDetails &&
			Object.getOwnPropertyNames(nextProps.patientData.otherDetails).length > 0
		) {
			const arr = Object.getOwnPropertyNames(
				nextProps.patientData.otherDetails,
			);
			for (let i = 0; i < arr.length; i++) {
				const val = arr[i];
				this.setState({ [val]: nextProps.patientData.otherDetails[val] });
			}
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		if (name === 'referrerType') {
			this.setState({
				referredBy: {
					type: '',
					name: '',
				},
			});
			this.setState((prevState) => ({
				referredBy: {
					...prevState.referredBy,
					type: value,
				},
			}));
		}
		if (name === 'referrerName') {
			this.setState((prevState) => ({
				referredBy: {
					...prevState.referredBy,
					name: value,
				},
			}));
		} else {
			this.setState({ [name]: value });
		}
	};

	handleNumberChange = (id, values) => {
		this.setState({ [id]: values.value });
	};

	nextStep = () => {
		const { step, auth } = this.props;

		let { occupation, employer, employerPhone, referredBy, otherFamilyName } =
			this.state;
		employerPhone = !employerPhone ? '' : employerPhone;
		const data = {
			otherDetails: {
				occupation,
				employer,
				employerPhone,
				referredBy,
				otherFamilyName,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.id = auth.id;
		data.q = keys.join();
		step(data);
	};

	prevStep = () => {
		const { previousStep } = this.props;
		previousStep();
	};

	render() {
		const { occupation, employer, employerPhone, referredBy, otherFamilyName } =
			this.state;

		const referrerValidations =
			referredBy.type === 'doctor' || referredBy.type === 'therapist'
				? [required]
				: [];
		const referrerLabel =
			referredBy.type === 'doctor' || referredBy.type === 'therapist'
				? 'NAME OF THE REFERRER*'
				: 'NAME OF THE REFERRER';

		return (
			<div>
				<Form>
					<div className="form-group">
						<TextInput
							label="OCCUPATION"
							id="occupation"
							value={occupation}
							placeholder="Manager"
							handleInput={this.handleChange}
						/>
					</div>

					<div className="form-row">
						<div className="col-md-6 form-group">
							<TextInput
								label="EMPLOYER"
								id="employer"
								value={employer}
								placeholder="Walmart"
								handleInput={this.handleChange}
							/>
						</div>
						<div className="col-md-6 form-group">
							{/* <TextInput
                maxLength={10}
                label="EMPLOYER PHONE"
                id="employerPhone"
                value={employerPhone}
                placeholder="Enter 10 Digit Phone Number"
                handleInput={this.handleChange}
                validations={[isPhoneNumber]}
              /> */}
							<PhoneNumberInput
								id="employerPhone"
								label="EMPLOYER PHONE"
								value={employerPhone}
								placeholder="Enter 10 Digit Phone Number"
								handleInput={this.handleNumberChange}
							/>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-6 form-group">
							<label htmlFor="referrerType" className="form-font">
								REFERRED TO CLINIC BY*
							</label>
							<Select
								value={referredBy.type || ''}
								className="custom-select"
								id="referrerType"
								name="referrerType"
								onChange={this.handleChange}
								validations={[required]}
							>
								<option value="">Select Type of Referrer</option>
								<option value="doctor">DOCTOR</option>
								<option value="therapist">THERAPIST</option>
								<option value="insurance">INSURANCE</option>
								<option value="treatment facility">TREATMENT FACILITY</option>
								<option value="family">FAMILY</option>
								<option value="friend">FRIEND</option>
								<option value="internet">INTERNET</option>
								<option value="others">OTHERS</option>
							</Select>
						</div>

						<div className="col-md-6 form-group">
							{referredBy.type && (
								<TextInput
									label={referrerLabel}
									id="referrerName"
									value={referredBy.name || ''}
									placeholder="John Doe"
									handleInput={this.handleChange}
									validations={referrerValidations}
								/>
							)}
						</div>
					</div>

					<div className="form-group mb-0 pb-2">
						<TextInput
							label="OTHER FAMILY MEMBER SEEN AT MIND THERAPY CLINIC"
							id="otherFamilyName"
							value={otherFamilyName}
							placeholder="John Doe"
							handleInput={this.handleChange}
						/>
					</div>

					<div className="d-flex mt-4">
						<div className="col-6 pl-0">
							<button
								type="button"
								className="btn btn-white link-color border btn-block btn-lg font-weight-bold d-flex justify-content-start font-15"
								onClick={this.prevStep}
							>
								<div className="col-4 d-flex justify-content-start align-self-center">
									<img className="fas fa-arrow-left" alt="arrow-left" />
								</div>
								<div className="col-4 d-flex justify-content-center">
									<span>PREV</span>
								</div>
							</button>
						</div>
						<div className="col-6 p-0 d-flex">
							<Button
								type="button"
								className="btn btn-primary btn-color btn-block btn-lg font-weight-bold d-flex justify-content-end font-15"
								onClick={this.nextStep}
							>
								<div className="col-4 d-flex justify-content-center">
									<span>NEXT</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</Button>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

PatientOtherDetails.propTypes = {
	patientData: PropTypes.object.isRequired,
	step: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	previousStep: PropTypes.func.isRequired,
};

export default PatientOtherDetails;
