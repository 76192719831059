import PropTypes from 'prop-types';

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/**
 *
 * TherapeuticGroupAgreement
 *
 */

/* eslint-disable react/prefer-stateless-function */
class TherapeuticGroupAgreement extends React.Component {
	nextProgress = (event) => {
		const { nextProgress, therapyGroupChecked } = this.props;
		const data = {
			checks: {
				therapyGroupChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	formComplete = () => {
		return this.props.therapyGroupChecked;
	};

	render() {
		const {
			reqDate,
			prevProgress,
			handleAcceptance,
			therapyGroupChecked,
			name,
			data,
			isMobile,
		} = this.props;

		const { groupsData = [] } = data;
		const groups = groupsData.filter(group => group.groupSize == 'Group');

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mb-5 mt-5">
					Therapeutic Group Agreement
				</h2>
				<div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
					<div>
						<p className="pb-2">
							I am registering to participate in Mind Therapy Clinic therapy
							group(s) named below:
						</p>
						<div className={`${!isMobile && 'd-flex'}`}>
							<div
								className={`col-${isMobile ? '12 pr-0' : '6  pr-3'} py-0 pl-0`}
							>
								<label
									htmlFor="browseGroupClientName"
									className="text-uppercase cust-steps-therapy-label text-dark font-10"
								>
									Client Name
								</label>
								<div className="d-flex align-items-center border p-2">
									<input
										type="text"
										id="browseGroupClientName"
										aria-describedby="browseGroupClientName"
										placeholder="Enter Client Name"
										className="font-17"
										value={name}
									/>
								</div>
							</div>
							<div className={`form-group col-${isMobile ? '12' : '6'} p-0`}>
								<label
									htmlFor="browseGroupTodayDate"
									className="text-uppercase cust-steps-therapy-label text-dark font-10"
								>
									Today&apos;s Date
								</label>
								<div className="d-flex align-items-center border p-2">
									<DatePicker
										id="browseGroupTodayDate"
										selected={reqDate}
										onChange={this.handleChange}
										className="font-17 text-dark"
										dateFormat="MMM d, yyyy"
										readOnly
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="border-top w-100 pt-1" />
					<div className="d-flex flex-column px-4 pt-1 pb-4">
						{!isMobile ? (
							<div>
								<div className="d-flex">
									<div className="col border cust-steps-grid-label font-17 p-3">
										Groups
									</div>
									<div className="col border cust-steps-grid-label font-17 p-3">
										Start Date
									</div>
								</div>
								{groups.map((group, i) => (
									<div className="d-flex" key={`group-${i}`}>
										<div className="col border cust-steps-grid-label-val font-15 py-1 px-3 pb-3">
											{group.groupName || ''}
										</div>
										<div className="col border cust-steps-grid-label-val font-15 py-1 px-3 pb-3">
											{group.startDate || ''}
										</div>
									</div>
								))}
							</div>
						) : (
							<div>
								<h5 className="py-2 font-weight-bold">Groups to Enroll</h5>
								{groups.map((group, i) => (
									<div key={`group-${i}`}>
										<div className="cust-steps-grid-label-val font-18 py-1 px-3">
											<span className="font-weight-bold">Group:</span>{' '}
											{group.groupName || ''}
										</div>
										<div className="cust-steps-grid-label-val font-18 py-1 px-3">
											<span className="font-weight-bold">Start:</span>{' '}
											{group.startDate || ''}
										</div>
										<hr />
									</div>
								))}
							</div>
						)}
					</div>
					{/* <div className="pt-1 pb-4 px-3"> */}
					<div className="pt-1 px-3">
						<p>
							My signature below confirms my intention to enroll in the group(s)
							named above. I understand groups are billed and paid for on a
							monthly basis. In this way my spot in the group is held for me
							until such time I give notice of my intention to terminate.
							Termination notice must be provided one (1) week before the end of
							the calendar month in order to give the group time to process the
							change in group composition. I further understand my enrollment in
							the group is ongoing until I provide such notice.{' '}
						</p>
						<div className={isMobile ? 'py-3' : ''}>
							<IAccept
								handleAcceptance={handleAcceptance}
								name="therapyGroupChecked"
								value={therapyGroupChecked}
								isRequired
							/>
						</div>
					</div>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!this.formComplete()}
							onClick={this.nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		);
	}
}

TherapeuticGroupAgreement.propTypes = {
	step: PropTypes.func.isRequired,
	previousStep: PropTypes.func.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
	therapyGroupChecked: PropTypes.bool.isRequired,
	reqDate: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	patientDetail: PropTypes.string,
	data: PropTypes.object,
};

export default TherapeuticGroupAgreement;
