/*
 *
 * UserDropDown constants
 *
 */

export const DEFAULT_ACTION = 'src/FeedbackView/DEFAULT_ACTION';

export const FETCH_FEEDBACK = 'src/FeedbackView/FETCH_FEEDBACK';
export const FETCH_FEEDBACK_SUCCESS = 'src/FeedbackView/FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_ERROR = 'src/FeedbackView/FETCH_FEEDBACK_ERROR';

export const UPDATE_FEEDBACK = 'src/FeedbackView/UPDATE_FEEDBACK';
export const UPDATE_FEEDBACK_SUCCESS =
	'src/FeedbackView/UPDATE_FEEDBACK_SUCCESS';
export const UPDATE_FEEDBACK_ERROR = 'src/FeedbackView/UPDATE_FEEDBACK_ERROR';
