/*
 *
 * BrowseGroups actions
 *
 */
import {
	DEFAULT_ACTION,
	LOAD_PROGRAMS_PRIVATE,
	LOAD_PROGRAMS_PRIVATE_ERROR,
	LOAD_PROGRAMS_PRIVATE_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function loadProgramsPrivate() {
	return {
		type: LOAD_PROGRAMS_PRIVATE,
	};
}

export function loadProgramsPrivateSuccess(programs) {
	return {
		type: LOAD_PROGRAMS_PRIVATE_SUCCESS,
		programs,
	};
}

export function loadProgramsPrivateError(error) {
	return {
		type: LOAD_PROGRAMS_PRIVATE_ERROR,
		error,
	};
}
