/*
 *
 * ClinicianRegister reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CLINICIAN_REGISTRATION,
	ERROR,
	FETCH_DATA,
	FETCH_STATES,
	FETCH_STATES_ERROR,
	FETCH_STATES_SUCCESS,
	SUCCESS,
	UPLOAD_CREDENTIAL,
	UPLOAD_CREDENTIAL_ERROR,
	UPLOAD_CREDENTIAL_SUCCESS,
} from './constants';

export const initialState = fromJS({
	id: '',
	clinicianType: '',
	therapistType: '',
	licenseDetails: {},
	states: [],
	files: [],
});

function updateClinicianRegistration(action, initial) {
	let state = initial;
	if (action.type !== SUCCESS) {
		state = state.setIn(['id'], action.data.id);
	}
	state = state.setIn(['clinicianType'], action.data.clinicianType);
	state = state.setIn(['therapistType'], action.data.therapistType);
	state = state.setIn(['licenseDetails'], action.data.licenseDetails);
	return state;
}

function clinicianRegisterReducer(state = initialState, action) {
	switch (action.type) {
		case CLINICIAN_REGISTRATION:
			return updateClinicianRegistration(action, state);
		case FETCH_DATA:
			return updateClinicianRegistration(action, state);
		case SUCCESS:
			return updateClinicianRegistration(action, state);
		case ERROR:
			return state;
		case FETCH_STATES:
			return state.set('states', []);
		case FETCH_STATES_SUCCESS:
			return state.set('states', action.states.data);
		case FETCH_STATES_ERROR:
			return state.set('error', action.error);
		case UPLOAD_CREDENTIAL:
			return state.set('files', []);
		case UPLOAD_CREDENTIAL_SUCCESS:
			return state.set('files', action.files.data);
		case UPLOAD_CREDENTIAL_ERROR:
			return state.set('error', action.error);
		default:
			return state;
	}
}

export default clinicianRegisterReducer;
