import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomDatePicker from 'atoms/CustomDatePicker';
import CustomTimePicker from 'atoms/CustomTimePicker';
import IlluSuccessImg from 'images/illu_success.png';

import './index.css';

/**
 *
 * BrowseReqSentModal
 *
 */

/* eslint-disable react/prefer-stateless-function */
class AddSessionModal extends React.Component {
	state = {
		success: false,
		newDate: new Date(),
		startTime: new Date(),
		endTime: new Date(),
		isTimeError: false,
	};

	closeModal = (typeNo) => {
		const { hide } = this.props;
		hide(typeNo);
	};

	updateTimeWithDate = (time, date) => {
		date = moment(date);
		const newTime = moment(time)
			.set('date', date.date())
			.set('month', date.month())
			.set('year', date.year())
			.toDate();

		return newTime;
	};

	handleDateChange = (date) => {
		const startTime = this.updateTimeWithDate(this.state.startTime, date);
		const endTime = this.updateTimeWithDate(this.state.endTime, date);
		this.setState({
			newDate: date,
			startTime,
			endTime,
		});
	};

	handleTimeChange = (time, name) => {
		let isTimeError = false;
		if (name === 'startTime') {
			if (moment(time).isAfter(this.state.endTime)) {
				isTimeError = true;
			}
		}

		const newTime = this.updateTimeWithDate(time, this.state.newDate);

		this.setState({
			[name]: newTime,
			isTimeError,
		});
	};

	onSubmit = () => {
		const data = {
			startDate: moment(this.state.startTime).utc().format('YYYY-MM-DD HH:mm'),
			endDate: moment(this.state.endTime).utc().format('YYYY-MM-DD HH:mm'),
		};
		this.props.onSubmit(data);
	};

	render() {
		const { success, newDate, startTime, endTime, isTimeError } = this.state;
		const { details } = this.props;
		return (
			<div>
				<Modal
					isOpen="true"
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.closeModal} className="border-0">
						Add New Session {details && `for ${details.groupName}`}
					</ModalHeader>
					<ModalBody className="container-fluid pt-0">
						{success && (
							<div className="d-flex flex-column">
								<div className="p-2 align-self-center">
									<img src={IlluSuccessImg} alt="Successfully_Sent_Image" />
								</div>
								<div className="custom-req-sent-heading p-3 font-25 text-dark text-uppercase text-center">
									Your request successfully sent
								</div>
							</div>
						)}
						<div className="d-flex form-group align-middle font-16">
							<CustomDatePicker
								className="col-md-12"
								label={<span className="form-group">NEW DATE</span>}
								id="newDate"
								selected={newDate}
								handleDateChange={this.handleDateChange}
								placeholderText="Select new date"
							/>
						</div>
						<div className="d-flex mx-3 justify-content-between">
							<div className="col-5 p-0">
								<label
									htmlFor="updateGroupPrice"
									className="text-uppercase text-muted font-10"
								>
									Start Time*
								</label>
								{/* <div className="d-flex align-items-center border py-2 px-3"> */}
								<CustomTimePicker
									id="startTime"
									selected={startTime}
									handleTimeInput={(time) => {
										this.handleTimeChange(time, 'startTime');
									}}
									className="font-17"
								/>
							</div>
							<div className="col-5 p-0">
								<label
									htmlFor="updateGroupPrice"
									className="text-uppercase text-muted font-10"
								>
									{'End Time*'}
								</label>

								<CustomTimePicker
									id="endTime"
									selected={endTime}
									minTime={startTime}
									maxTime={moment(startTime, 'YYYY-MM-DD')
										.set('hours', 23)
										.set('minutes', 55)
										.toDate()}
									handleTimeInput={(time) => {
										this.handleTimeChange(time, 'endTime');
									}}
									className="font-17 w-150"
									// timeCaption="Time"
								/>
								{isTimeError && (
									<span className="form-error">Invalid Time Range</span>
								)}
							</div>
						</div>
						<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
							<button
								disabled={isTimeError}
								type="button"
								className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
								onClick={this.onSubmit}
							>
								<span className="mr-0 w-100">SUBMIT</span>
								<i className="fas fa-check align-self-center" />
							</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

AddSessionModal.propTypes = {
	hide: PropTypes.func.isRequired,
};

export default AddSessionModal;
