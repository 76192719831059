import history from 'utils/history';

import { capitalize } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { createRef } from 'react';

import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import ToDoCard from 'cards/ToDoCard';
import FormShare from 'components/FormShare';
import { headerStyle } from 'containers/App/constants';
import { filterByLetter } from 'utils/helpers';

class NewIntakes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			assignClinician: false,
			patientId: '',
			numToShow: 10,
			client: {},
			clinicianId: null,
		};

		this.scrollRef = createRef();
	}

	assignClinician = (clinician) => {
		const { assignClinician } = this.props;
		const { patientId } = this.state;
		const data = { patientId, clinician, requestType: 'NEW_INTAKES' };

		assignClinician(data);
		this.setState((prevState) => ({
			assignClinician: !prevState.assignClinician,
		}));
	};

	showClinicianList = (client, clinicianId) => {
		if (this.state.assignClinician) {
			this.setState(() => ({
				assignClinician: false,
				patientId: null,
				client: {},
				clinicianId: null,
			}));

			return;
		}

		this.setState((prevState) => ({
			assignClinician: !prevState.assignClinician,
			patientId: client.id,
			client,
			clinicianId,
		}));
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	getColumns = () => {
		const { downloadFile, role } = this.props;

		return [
			{
				field: 'name',
				title: 'CLIENT',
				headerStyle,
				render: (row) => (
					<div className="hand" onClick={() => this.goToProfile(row.userId)}>
						<ImageDetailsCell
							src={row.profilePicture}
							heading={row.name}
							primarySubHeading={capitalize(row.type)}
						/>
					</div>
				),
			},
			{
				field: 'clinicianName',
				title: 'LEAD CLINICIAN',
				headerStyle,
				render: (row) =>
					row.clinicianName ? (
						<div>
							<div className="mt-2">
								<ImageDetailsCell
									src={row.profilePicture}
									heading={row.clinicianName}
									primarySubHeading={row.therapistType}
								/>
							</div>
							<div
								className="link-color font-11"
								style={{ cursor: 'pointer' }}
								onClick={() =>
									this.showClinicianList(
										{ name: row.name, id: row.userId },
										row.mainClin,
									)
								}
							>
								Change
							</div>
						</div>
					) : role === 'admin' ? (
						<div
							className="link-color mt-2 mx-2 font-13"
							style={{ cursor: 'pointer' }}
							onClick={() =>
								this.showClinicianList(
									{ name: row.name, id: row.userId },
									row.mainClin,
								)
							}
						>
							Assign Clinican
						</div>
					) : (
						<div>-</div>
					),
				sorting: false,
			},
			{
				field: 'docs',
				title: 'INTAKE DOCUMENTS',
				headerStyle,
				render: (row) =>
					row.onBoardingComplete && row.docs?.length && row.docs[0].uuid ? (
						<div
							className="d-flex hand  link-color align-items-center mt-2 mx-0 font-12"
							onClick={() => {
								for (const doc of row.docs) {
									downloadFile(doc.uuid);
								}
							}}
						>
							DOWNLOAD {row.docs.length} DOC{row.docs.length > 1 && 'S'}
						</div>
					) : row.client && row.client.onBoardingComplete ? (
						<div className="d-flex text-muted align-items-center mt-2 mx-0 font-12">
							DOCUMENT UNAVAILABLE
						</div>
					) : (
						<div className="d-flex text-muted align-items-center mt-2 mx-0 font-12">
							INTAKE INCOMPLETE
						</div>
					),
			},
			{
				field: 'intakeProgress',
				title: 'INTAKE PROGRESS',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.onBoardingComplete
							? 'Completed'
							: row.registrationProgress <= 0
							? 'Not Started'
							: `Step ${row.intakeProgress + row.registrationProgress}`}
					</div>
				),
			},
			{
				field: 'createdAt',
				title: 'REGISTERED ON',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{moment(row.createdAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
			{
				field: 'updatedAt',
				title: 'UPDATED AT',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{moment(row.updatedAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
		];
	};

	showMore = () => {
		this.setState({ numToShow: this.state.numToShow + 10 });
	};

	render() {
		const {
			data,
			clinicians,
			loading,
			isMobile,
			downloadFile,
			userSearch,
			backToTop,
		} = this.props;
		const { assignClinician, numToShow, client, clinicianId } = this.state;
		const newIntakesColumns = this.getColumns();

		let filteredUsers = [];
		let loadMore = true;

		if (data) {
			filteredUsers = filterByLetter(data, 'name', userSearch);

			if (isMobile) {
				filteredUsers = filteredUsers.slice(0, numToShow);
			}
			if (numToShow > data.length) loadMore = false;
		}

		return (
			<div>
				{isMobile ? (
					<div>
						<div className="row mb-4">
							{filteredUsers.map((clientInfo) => (
								<ToDoCard
									key={clientInfo.name}
									data={clientInfo}
									assignClinician={this.showClinicianList}
									downloadFile={downloadFile}
								/>
							))}
						</div>
						{loadMore && (
							<div
								className="row mx-0 justify-content-center link-color font-20 mb-4 hand"
								onClick={this.showMore}
							>
								Show More...
							</div>
						)}
						<div
							className="row mx-0 justify-content-center link-color font-20 mb-4 hand"
							onClick={backToTop}
						>
							Back to Top
						</div>
					</div>
				) : (
					<Table
						keyField="newIntakes"
						data={filteredUsers}
						loading={loading}
						columns={newIntakesColumns}
						placeholder="No new intakes"
					/>
				)}
				{assignClinician && (
					<FormShare
						clinicians={clinicians}
						type="assignClinician"
						title={`Assigning ${client.name} to...`}
						hide={this.showClinicianList}
						assignClinician={this.assignClinician}
						currentClinicianId={clinicianId}
					/>
				)}
			</div>
		);
	}
}

NewIntakes.propTypes = {
	data: PropTypes.array.isRequired,
	downloadFile: PropTypes.func,
	assignClinician: PropTypes.func,
	clinicians: PropTypes.array,
};

export default NewIntakes;
