import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { createStructuredSelector } from 'reselect';

import GroupSnippets from 'components/GroupSnippets';
import { getFilterOptions } from 'utils/helpers';

import { loadProgramsPrivate } from './actions';
import groupFilters from './filters';
import './index.css';
import { makeSelectAllProgramsPrivate } from './selectors';

/**
 *
 * BrowseGroupsPrivate
 *
 */

/* eslint-disable react/prefer-stateless-function */
export class BrowseGroupsPrivate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: [],
			currentFilter: 'Any',
			carouselIndex: 0,
		};
	}

	componentDidMount() {
		const { fetchProgramsPrivate } = this.props;
		fetchProgramsPrivate();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.programs) {
			this.setState({
				groups: nextProps.programs,
			});
		}
	}
	generateFilters = () => {
		const { programs } = this.props;
		return {
			therapistType: getFilterOptions(programs, 'therapist_type'),
		};
	};

	showAll() {
		return this.state.currentFilter === 'Any';
	}

	handleChange = (e) => {
		if (this.state.currentFilter === e.target.value) {
			return;
		}

		this.setState({
			currentFilter: e.target.value,
			carouselIndex: 0,
		});
	};

	handleCarouselChange = (selectedIndex) => {
		this.setState({ carouselIndex: selectedIndex });
	};

	render() {
		const { groups } = this.state;
		const filters = groupFilters(this.generateFilters());
		const { selectOptions = [] } = filters[0];

		const slides = [];

		groups.forEach((groupDetails) => {
			if (
				this.showAll() ||
				groupDetails.therapist_type === this.state.currentFilter
			) {
				slides.push(
					<div
						className="cardcontainer-fluid bg-light p-4"
						key={groupDetails.group_name}
					>
						<GroupSnippets groupDetails={groupDetails}></GroupSnippets>
					</div>,
				);
			}
		});

		return (
			<div>
				{groups.length > 0 && (
					<div className="card browseGroupsCard bg-light mt-1 rounded-border pt-3">
						<div className="d-flex width-100 justify-content-center">
							I&apos;m Looking For A...
						</div>
						<div className="d-flex width-100 my-2 justify-content-center">
							<select
								className="mx-2 bg-white p-1"
								onChange={this.handleChange}
							>
								<option key={'any'}>Any</option>
								{selectOptions.map((option) => {
									return <option key={option}>{option}</option>;
								})}
							</select>
						</div>
						<Carousel
							showArrows={true}
							showThumbs={false}
							showIndicators={false}
							onChange={this.handleCarouselChange}
							selectedItem={this.state.carouselIndex}
						>
							{slides}
						</Carousel>
					</div>
				)}
			</div>
		);
	}
}

BrowseGroupsPrivate.propTypes = {
	programs: PropTypes.array,
	fetchProgramsPrivate: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	programs: makeSelectAllProgramsPrivate(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchProgramsPrivate: () => dispatch(loadProgramsPrivate()),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BrowseGroupsPrivate);
