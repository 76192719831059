import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <Pain />, a functional component for Adult Intake Evaluation
 */
const Pain = (props) => {
	const {
		painProblem,
		painDetails,
		painScale,
		painDuration,
		ongoingPainTreatment,
		ongoingPainTreatmentDetails,
		handleChange,
		isMobile,
	} = props;

	return (
		<div>
			<h6>Pain</h6>
			<div className="mb-2 row">
				<label
					htmlFor="painProblem"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					DO YOU HAVE PROBLEM WITH PAIN ?
				</label>
				<SelectButtonGroup
					id="painProblem"
					value={painProblem}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
				/>
			</div>
			{painProblem === 'yes' && (
				<div>
					<TextArea
						className="mb-4"
						label="IF YES, PLEASE DESCRIBE:"
						id="painDetails"
						value={painDetails}
						handleInput={handleChange}
					/>
					<TextInput
						className="mb-4"
						label="WHAT IS YOUR AVERAGE PAIN LEVEL, USING THE PAIN SCALE FROM 1 TO 10, 10 BEING EXCRUCIATING PAIN ?"
						id="painScale"
						value={painScale}
						handleInput={handleChange}
					/>
					<TextInput
						className="mb-4"
						label="HOW LONG HAVE YOU BEEN SUFFERING WITH THIS LEVEL OF PAIN ?"
						id="painDuration"
						value={painDuration}
						handleInput={handleChange}
					/>
					<div className="mb-2 row">
						<label htmlFor="ongoingPainTreatment" className="form-font col-8">
							ARE YOU BEING TREATED FOR THIS PROBLEM?
						</label>
						<SelectButtonGroup
							id="ongoingPainTreatment"
							value={ongoingPainTreatment}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange}
							className="mb-2 col-4"
						/>
					</div>
					{ongoingPainTreatment === 'yes' && (
						<TextInput
							className="mb-4"
							label="IF YES, BY WHOM?"
							id="ongoingPainTreatmentDetails"
							value={ongoingPainTreatmentDetails}
							handleInput={handleChange}
						/>
					)}
				</div>
			)}
		</div>
	);
};

Pain.propTypes = {
	painProblem: PropTypes.string.isRequired,
	painDetails: PropTypes.string.isRequired,
	painScale: PropTypes.string.isRequired,
	painDuration: PropTypes.string.isRequired,
	ongoingPainTreatment: PropTypes.string.isRequired,
	ongoingPainTreatmentDetails: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default Pain;
