import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchFeedbackApi, updateFeedbackApi } from 'utils/api';

import {
	fetchFeedbackError,
	fetchFeedbackSuccess,
	updateFeedbackError,
	updateFeedbackSuccess,
} from './actions';
import { FETCH_FEEDBACK, UPDATE_FEEDBACK } from './constants';

export default function* feedbackSaga() {
	yield takeLatest(FETCH_FEEDBACK, fetchFeedback);
	yield takeLatest(UPDATE_FEEDBACK, updateFeedback);
}

export function* fetchFeedback(data) {
	try {
		const response = yield call(fetchFeedbackApi, data.id);
		if (response.response && response.response.status >= 400) {
			yield put(fetchFeedbackError());
		} else {
			yield put(fetchFeedbackSuccess(response.data));
		}
	} catch (err) {
		yield put(fetchFeedbackError(err));
	}
}

export function* updateFeedback(data) {
	try {
		const response = yield call(updateFeedbackApi, data.id);
		if (response.response && response.response.status >= 400) {
			yield put(updateFeedbackError());
		} else {
			yield put(updateFeedbackSuccess(response.data.id));
		}
	} catch (err) {
		yield put(updateFeedbackError(err));
	}
}
