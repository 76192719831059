export const roi = {
	clientName: 'John Doe',
	clientEmail: 'notanemail@me.com',
	clientPhone: '3414423232',
	clientAddress: '123 Main Street',
	clientCity: 'Milwaukee',
	clientState: 'Wisconsin',
	clientZipcode: '53217',
	dateOfBirth: 'January 1 1970',
	clientAuthName: 'Bob Smith',
	entityName: 'My Pharmacy',
	entityType: 'Pharmacy',
	entityPhone: '7463923837',
	entityFax: '9282746456',
	entityAddress: '456 Fake Street',
	entityZipcode: '72634',
	roiPurpose: 'Need to release information',
	authClientType: 'patient',
	sendChecked: true,
	receiveChecked: true,
	evalChecked: true,
	labResChecked: true,
	progRepChecked: true,
	medInfoChecked: true,
	testResChecked: true,
	diagnChecked: true,
	sumRepChecked: true,
	entRecChecked: false,
	planTreatChecked: false,
	contTreatChecked: false,
	caseRevChecked: false,
	updateFileChecked: false,
	roiChecked: true,
};

export const telehealth = {};

export const enrollment = {
	name: 'John Doe',
	groupsData: [
		{ groupName: 'Test Group 1', startDate: '01-01-2020', groupSize: 'Group' },
		{ groupName: 'Test Group 2', startDate: '02-02-2020', groupSize: 'Group' },
	],
};

export const intake = (type) => ({
	intakeProgress: 17,
	registrationProgress: 4,
	patientDetails: {
		id: 14016,
		registrationDate: '2020-08-26 14:43:00',
		maritalStatus: 'single',
		homePhone: '4144182396',
		registrationType: type,
		phone: '4144182396',
		email: 'cardenas.danielr+mtctest@gmail.com',
		dob: '2020-07-26',
		sex: 'male',
		createdAt: '2020-08-26 14:43:00',
		updatedAt: '2020-08-26 14:43:00',
		isActive: true,
		name: 'DC TEST',
		addressId: 29,
		mailingAddressId: 29,
		referredById: 14,
		primaryMdId: 19,
		primaryTherapistId: 18,
		pharmacyId: 6,
		insurance: {
			patientHere: 'yes',
			patientCoveredByInsurance: 'yes',
			primarySubscriberRelationship: 'self',
			secondarySubscriberRelationship: 'self',
		},
		emergencyContact: [
			{
				emergencyContact: 'a',
				relationship: 'b',
				homePhone: '1234142412',
				workPhone: '1241241241',
			},
		],
		questionnaire: {
			adultIntakeMaritalStatus: 'married',
			patientRelationshipToSubscriber: 'no',
			adultPsychiatricHistory: {
				pastPsychotherapy: 'no',
				familySuicideHistory: 'no',
				familypsychiatricHistory: 'no',
				selfMultilation: 'no',
				diagnosesHistory: [
					{
						id: 'anxiety',
						name: 'Anxiety',
					},
					{
						id: 'adhd',
						name: 'ADHD',
					},
					{
						id: 'ocd',
						name: 'OCD',
					},
					{
						id: 'depression',
						name: 'Depression',
						isSelected: true,
					},
					{
						id: 'schizophrenia',
						name: 'Schizophrenia',
					},
					{
						id: 'eatingDisorder',
						name: 'Eating Disorder',
					},
					{
						id: 'bipolarDisorder',
						name: 'Bipolar Disorder',
					},
					{
						id: 'learningDisorders',
						name: 'Learning Disorders',
					},
					{
						id: 'personalityDisorders',
						name: 'Personality Disorders',
						isSelected: true,
					},
					{
						id: 'traumaRelatedDisorder',
						name: 'Trauma-related Disorder',
					},
					{
						id: 'somaticSymptomDisorder',
						name: 'Somatic Symptom Disorder',
						isSelected: true,
					},
					{
						id: 'austismSpectrumDisorder',
						name: 'Austism Spectrum Disorder',
					},
					{
						id: 'alcoholSubstanceAbuseDisorders',
						name: 'Alcohol / Substance Abuse Disorders',
					},
					{
						id: 'other',
						name: 'Other',
					},
				],
				suicideAttempt: 'no',
				psychiatricHospitalization: 'no',
			},
			adultMedicalContinued: {
				familyMedicalConditions: [
					{
						id: 'highLowBloodPressure',
						name: 'High/Low blood pressure',
					},
					{
						id: 'highCholesterol',
						name: 'High cholesterol',
					},
					{
						id: 'heartDisease',
						name: 'Heart disease',
					},
					{
						id: 'cancer',
						name: 'Cancer',
					},
					{
						id: 'diabetes',
						name: 'Diabetes',
					},
					{
						id: 'liverProblems',
						name: 'Liver problems',
						isSelected: true,
					},
					{
						id: 'kidneyProblems',
						name: 'Kidney problems',
						isSelected: true,
					},
					{
						id: 'respiratoryProblems',
						name: 'Respiratory problems',
					},
					{
						id: 'asthma',
						name: 'Asthma',
					},
					{
						id: 'nervousSystemDisorder',
						name: 'Nervous system disorder',
					},
					{
						id: 'seizures',
						name: 'Seizures',
						isSelected: true,
					},
					{
						id: 'gastrointestinalProblems',
						name: 'Gastrointestinal problems',
					},
					{
						id: 'bloodDisorder',
						name: 'Blood disorder',
					},
					{
						id: 'thyroidDisorder',
						name: 'Thyroid disorder',
					},
					{
						id: 'otherGlandularDisorder',
						name: 'Other glandular disorder',
					},
					{
						id: 'sleepDisorder',
						name: 'Sleep disorder',
					},
					{
						id: 'headachesMigraines',
						name: 'Headaches/Migraines',
					},
					{
						id: 'painDisorder',
						name: 'Pain disorder',
					},
				],
				sufferAnorexia: 'no',
				recentWeightChanges: 'no',
				funtioningSexually: 'no',
				medicationSideEffects: 'no',
			},
			allergiesToMedication: 'no',
			symptoms: [
				{
					name: 'Depressed mood',
				},
				{
					id: 1,
					name: 'Unable to enjoy activities',
				},
				{
					id: 2,
					name: 'Sleep pattern disturbance',
				},
				{
					id: 3,
					name: 'Loss of interest',
				},
				{
					id: 4,
					name: 'Concentration issues/forgetfulness',
				},
				{
					id: 5,
					name: 'Change in appetite',
				},
				{
					id: 6,
					name: 'Excessive guilt',
					isSelected: true,
				},
				{
					id: 7,
					name: 'Feeling worthless',
				},
				{
					id: 8,
					name: 'Fatigue',
				},
				{
					id: 9,
					name: 'Decreased libido',
				},
				{
					id: 10,
					name: 'Restlessness',
					isSelected: true,
				},
				{
					id: 11,
					name: 'Feeling Slowed down',
				},
				{
					id: 12,
					name: 'Racing thoughts',
				},
				{
					id: 13,
					name: 'Impulsivity',
				},
				{
					id: 14,
					name: 'Increase risky behaviour',
				},
				{
					id: 15,
					name: 'Increased libido',
				},
				{
					id: 16,
					name: 'Decreased need for sleep',
					isSelected: true,
				},
				{
					id: 17,
					name: 'Excessive energy',
				},
				{
					id: 18,
					name: 'Increased irritability',
				},
				{
					id: 19,
					name: 'Crying spells',
				},
				{
					id: 20,
					name: 'Excessive worry',
					isSelected: true,
				},
				{
					id: 21,
					name: 'Anxiety/Panic attack',
				},
				{
					id: 22,
					name: 'Avoidance',
				},
				{
					id: 23,
					name: 'Hallucinations',
				},
				{
					id: 24,
					name: 'Suspiciouness',
				},
				{
					id: 25,
					name: 'Feeling hopeless',
				},
				{
					id: 26,
					name: 'Other',
				},
			],
			currentTherapyHelpful: 'yes',
			childrenCount: '3',
			adultSubstanceAbuse: {
				drinkCaffeinatedDrinks: 'no',
				threatenDueToAlcohol: 'no',
				friendsFamilyVoiceConcern: 'no',
				useAlcohol: 'no',
				useRecreationalDrugs: 'no',
				geneticProblemOfAlcohol: 'no',
				appointmentDueToSubstances: 'no',
				useNicotine: 'no',
			},
			adultIntakeName: 'John Doe',
			marriedYear: '12',
			adultIntakeDob: '1988-07-05T04:00:00.000Z',
			adultMedical: {
				pastMedication: 'Other medications',
				relativesMedicationHelpful: 'no',
				currentMedicationList: 'Some medication',
				medicalConditions: [
					{
						id: 'highLowBloodPressure',
						name: 'High/Low blood pressure',
					},
					{
						id: 'highCholesterol',
						name: 'High cholesterol',
					},
					{
						id: 'heartDisease',
						name: 'Heart disease',
					},
					{
						id: 'cancer',
						name: 'Cancer',
					},
					{
						id: 'diabetes',
						name: 'Diabetes',
					},
					{
						id: 'liverProblems',
						name: 'Liver problems',
						isSelected: true,
					},
					{
						id: 'kidneyProblems',
						name: 'Kidney problems',
					},
					{
						id: 'respiratoryProblems',
						name: 'Respiratory problems',
					},
					{
						id: 'asthma',
						name: 'Asthma',
					},
					{
						id: 'nervousSystemDisorder',
						name: 'Nervous system disorder',
					},
					{
						id: 'seizures',
						name: 'Seizures',
						isSelected: true,
					},
					{
						id: 'gastrointestinalProblems',
						name: 'Gastrointestinal problems',
					},
					{
						id: 'bloodDisorder',
						name: 'Blood disorder',
						isSelected: true,
					},
					{
						id: 'thyroidDisorder',
						name: 'Thyroid disorder',
					},
					{
						id: 'otherGlandularDisorder',
						name: 'Other glandular disorder',
					},
					{
						id: 'sleepDisorder',
						name: 'Sleep disorder',
					},
					{
						id: 'headachesMigraines',
						name: 'Headaches/Migraines',
					},
					{
						id: 'painDisorder',
						name: 'Pain disorder',
					},
				],
				concernAboutHealth: 'no',
				bloodWorkDone: 'no',
				relativesOnMedication: 'no',
				medicineDiscontinueReason: 'I finished the perscription',
				dosageForMedicationList: 'Some amount',
			},
			symptomsFrequent: 'no',
			symptomsRelatedToLife: 'no',
			adultIntakeAge: '32',
			adultSleepHabits: {
				feelRestedInMorning: 'sometimes',
				troubleFallingAsleep: 'no',
				doYouSnore: 'no',
				stopBreathingAsleep: 'yes',
				fallbackToSleep: 'usually',
				wakeMiddleNight: 'dontKnow',
			},
			adultMedicalHistory: {
				pmsSymptoms: 'no',
				takingContraceptives: 'no',
				painProblem: 'no',
				headInjuries: 'no',
				regularPeriods: 'nA',
				menopausalSymptoms: 'no',
			},
			adultOtherHistory: {
				onProbation: 'no',
				legalProblem: 'no',
				servedInMilitary: 'no',
				supporters: [
					{
						id: 'family',
						name: 'Family',
					},
					{
						id: 'coworkers',
						name: 'Coworkers',
					},
					{
						id: 'friends',
						name: 'Friends',
					},
					{
						id: 'significantOtherS',
						name: 'Significant other(s)',
					},
					{
						id: 'others',
						name: 'Others',
					},
				],
				supportSystem: 'no',
			},
		},
		cardDetails: {
			cardNumber:
				'U2FsdGVkX19wY3+PWA6xpRdYe31XEYPI9LtFx9+f5jw0UOkE6tRuISG4bI9K5bd7',
			cardExpirationMonth: 'U2FsdGVkX1/DI0nrrBfwwDxNUOVfRFqBBlmZlQE6qOI=',
			cardExpirationYear: 'U2FsdGVkX1+TdzTxklkxGN9AV6FjaOBQLYAcs6GzLZ8=',
			billingZipcode: 'U2FsdGVkX189vBlkQmGmn0M40ing0ahFhmomHtQJJG8=',
			cardName: 'U2FsdGVkX1/Sub5Lzml2p5yR6GjHPCoJ3j2sC9fDsiw=',
			cardEmail: 'U2FsdGVkX18+hY7dnTuUdklHf8n5wek+j1NA0pmPBCY=',
			enrollmentDateIOP: '03-02-2020',
			programFee: 200,
			financialPartyName: 'Bob Smith',
		},
	},
	address: {
		id: 29,
		street: '123 Fake Street',
		city: 'MILWAUKEE',
		state: 'WI',
		zipcode: '53217',
	},
	mailingAddress: {
		id: 29,
		street: '123 Fake Street',
		city: 'MILWAUKEE',
		state: 'WI',
		zipcode: '53217',
	},
	referredBy: {
		id: 14,
		name: 'abc',
		type: 'friend',
	},
	doctor: {
		id: 19,
		name: 'Chris',
		clinicId: 8,
		clinic: {
			id: 8,
			name: "Chris's Clinic",
			phone: '6546546465',
			addressId: 32,
			address: {
				id: 32,
				street: '123 Fake Street',
				city: 'AKIACHAK',
				state: 'AK',
				zipcode: '99551',
				fax: '6546546516351',
			},
		},
	},
	therapist: {
		id: 18,
		name: 'Jane Doe',
		clinicId: 7,
		clinic: {
			id: 7,
			phone: '6654654654',
			addressId: 31,
			address: {
				id: 31,
				street: '123 Fake Street',
				city: 'ABERNANT',
				state: 'AL',
				zipcode: '35440',
			},
		},
	},
	pharmacy: {
		id: 6,
		name: 'A Real Pharmacy',
		phone: '1546541321',
		addressId: 30,
		address: {
			id: 30,
			street: '123 Fake Street',
			city: 'ADAK',
			state: 'AK',
			zipcode: '99546',
		},
	},
	checks: {
		primaryCareChecked: true,
		therapistChecked: true,
		insuranceInformationreChecked: true,
		cancellationPolicyChecked: true,
		returnedCheckChecked: true,
		delinquentAccountsChecked: true,
		limitaionsOfConfidentialityChecked: true,
		mobileMsgFaxEmailChecked: true,
		MessagesChecked: true,
		emergencyAccessChecked: true,
		consentForTreatmentChecked: true,
		electronicRecordChecked: true,
		billingChecked: true,
		infoRelatedTreatmentChecked: true,
		patientAgreementChecked: true,
		openPaymentChecked: true,
		goodFaithEstimateChecked: true,
		financialAgreementChecked: true,
		patientPaymentDetailsChecked: true,
		patientPaymentAgreementChecked: true,
		privacyPracticesChecked: true,
		authorizeToSharePHIChecked: true,
		telehealthChecked: true,
		secondarySubscriberRelationship: true,
	},
});
