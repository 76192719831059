import PropTypes from 'prop-types';

import React from 'react';

import CommentPopover from 'components/CommentPopover';

/**
 *
 * <CustomButton />, a class component to display Button with comments.
 */
// eslint-disable-next-line react/prefer-stateless-function
class CustomButton extends React.Component {
	state = {
		showAddCommentPopover: false,
		comment: '',
	};

	togglePopover = () => {
		this.setState((prevState) => ({
			showAddCommentPopover: !prevState.showAddCommentPopover,
		}));
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.comment) {
			this.setState({
				comment: nextProps.comment,
			});
		}
	}

	handleInput = (event) => {
		const { target } = event;
		event.preventDefault();
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	handleCommentSubmit = () => {
		const { onClick, id } = this.props;
		const { comment } = this.state;
		this.setState({ showAddCommentPopover: false });
		onClick(id, comment);
	};

	getButtonClass = (type) => {
		const { className } = this.props;
		const commonClasses = 'btn px-3 py-2 font-10';
		let classes = '';

		switch (type) {
			case 'action':
				classes = 'btn-action';
				break;
			case 'secondary':
				classes = 'btn-color-cust link-color';
				break;

			case 'primary':
			default:
				classes = 'btn-primary primary-btn-color';
				break;
		}

		return `${commonClasses} ${classes} ${className}`;
	};

	render() {
		const { onClick, id, type, commentTitle, mode, title, disabled } =
			this.props;
		const { showAddCommentPopover, comment } = this.state;
		const isCommentVisible = mode && id;
		const onClickFunc = !isCommentVisible ? onClick : this.togglePopover;
		const buttonClass = this.getButtonClass(type);

		return (
			<div className="d-flex align-items-center">
				<button
					onClick={onClickFunc}
					type="button"
					className={buttonClass}
					disabled={disabled}
					id={`customButton${id}`}
				>
					{title}
				</button>

				{isCommentVisible && (
					<CommentPopover
						toggle={this.togglePopover}
						target={`customButton${id}`}
						isOpen={showAddCommentPopover}
						title={commentTitle}
						comment={comment}
						handleInput={this.handleInput}
						handleSubmit={this.handleCommentSubmit}
						mode={mode}
					/>
				)}
			</div>
		);
	}
}

CustomButton.propTypes = {
	id: PropTypes.any,
	onClick: PropTypes.func,
	comment: PropTypes.string,
	type: PropTypes.string,
	commentTitle: PropTypes.string,
	title: PropTypes.string,
	mode: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

export default CustomButton;
