import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import cryptoRandomString from 'crypto-random-string';

import MaterialTable from 'material-table';

import PropTypes from 'prop-types';

import React, { forwardRef } from 'react';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';

import './index.css';

/* eslint-disable react/display-name */
const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Table = (props) => {
	let {
		data = [],
		columns,
		loading = false,
		placeholder,
		error = '',
		horizontalScroll = false,
		paging = true,
		classes,
		title = cryptoRandomString({ length: 10 }),
		pageSize = 5,
		rowStyle,
		showWhenEmpty = false,
	} = props;

	if (!Array.isArray(data)) {
		data = [];
	}

	let pageSizeOptions = [5, 10, 20];
	if (data.length <= 5) {
		pageSizeOptions = [5];
	} else if (data.length <= 10) {
		pageSizeOptions = [5, 10];
	}

	if (data.length < pageSizeOptions[0]) {
		paging = false;
	}

	return error ? (
		<div className="w-100 pb-4 bg-white">
			<div className="text-center w-100 font-30 pt-3 uppercase">{error}</div>
		</div>
	) : loading ? (
		<div className="w-100 pb-4 bg-white">
			<SmallLoadingIndicator text="Loading..." color="var(--main-color)" />
		</div>
	) : !showWhenEmpty && data.length === 0 ? (
		<div className="w-100 pb-4 bg-white">
			<div className="text-center w-100 font-30 pt-3 uppercase">
				{placeholder || 'No data to display'}
			</div>
		</div>
	) : (
		<div className={`w-100 ${horizontalScroll ? 'scrollable' : ''} ${classes}`}>
			<MaterialTable
				icons={tableIcons}
				columns={columns}
				data={data}
				options={{
					draggable: false,
					toolbar: false,
					emptyRowsWhenPaging: false,
					pageSizeOptions,
					paging,
					pageSize,
					rowStyle,
				}}
				title={title}
				placeholder={placeholder}
			/>
		</div>
	);
};

Table.propTypes = {
	keyField: PropTypes.string,
	data: PropTypes.array,
	columns: PropTypes.array,
	classes: PropTypes.string,
};

export default Table;
