// import components

/**
 *
 * AdultMedicalHistory
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import { STEPS } from 'pages/intake/constants';

import FemalePatients from './FemalePatients';
import HeadInjury from './HeadInjuries';
import Pain from './Pain';

/* eslint-disable react/prefer-stateless-function */
const AdultMedicalHistory = (props) => {
	const { prevProgress, nextProgress, data, handleChange, isMobile } = props;
	const {
		headInjuries,
		headInjuryArea,
		amnesiaAfterHeadInjury,
		moodChangeAfterHeadInjury,
		moodChangeAfterHeadInjuryDetails,
		hospitalizedAfterHeadInjury,
		hospitalizedAfterHeadInjuryDetails,
		scanPerformed,
		scanDetails,
		painProblem,
		painDetails,
		painScale,
		painDuration,
		ongoingPainTreatment,
		ongoingPainTreatmentDetails,
		regularPeriods,
		irregularPeriodsDetail,
		takingContraceptives,
		moodChangeAfterContraceptives,
		moodChangeAfterContraceptivesDetail,
		menopausalSymptoms,
		visitedDoctorForMenopausal,
		treatmentForMenopausal,
		pmsSymptoms,
		pmsSymptomsDetail,
	} = data;

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Intake Questionnaire
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultMedicalHistory)}>
				<div className="card d-flex flex-column p-4 mb-4">
					<h3 className="mb-5">Medical History</h3>
					<HeadInjury
						headInjuries={headInjuries}
						headInjuryArea={headInjuryArea}
						amnesiaAfterHeadInjury={amnesiaAfterHeadInjury}
						moodChangeAfterHeadInjury={moodChangeAfterHeadInjury}
						moodChangeAfterHeadInjuryDetails={moodChangeAfterHeadInjuryDetails}
						hospitalizedAfterHeadInjury={hospitalizedAfterHeadInjury}
						hospitalizedAfterHeadInjuryDetails={
							hospitalizedAfterHeadInjuryDetails
						}
						scanPerformed={scanPerformed}
						scanDetails={scanDetails}
						handleChange={handleChange(STEPS.AdultMedicalHistory)}
						isMobile={isMobile}
					/>

					<Pain
						painProblem={painProblem}
						painDetails={painDetails}
						painScale={painScale}
						painDuration={painDuration}
						ongoingPainTreatment={ongoingPainTreatment}
						ongoingPainTreatmentDetails={ongoingPainTreatmentDetails}
						handleChange={handleChange(STEPS.AdultMedicalHistory)}
						isMobile={isMobile}
					/>

					<FemalePatients
						regularPeriods={regularPeriods}
						irregularPeriodsDetail={irregularPeriodsDetail}
						takingContraceptives={takingContraceptives}
						moodChangeAfterContraceptives={moodChangeAfterContraceptives}
						moodChangeAfterContraceptivesDetail={
							moodChangeAfterContraceptivesDetail
						}
						menopausalSymptoms={menopausalSymptoms}
						visitedDoctorForMenopausal={visitedDoctorForMenopausal}
						treatmentForMenopausal={treatmentForMenopausal}
						pmsSymptoms={pmsSymptoms}
						pmsSymptomsDetail={pmsSymptomsDetail}
						handleChange={handleChange(STEPS.AdultMedicalHistory)}
						isMobile={isMobile}
					/>
				</div>

				<PrevNextButtons prevProgress={prevProgress} className="mt-4 mb-5" />
			</Form>
		</div>
	);
};

AdultMedicalHistory.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default AdultMedicalHistory;
