import React, { useState } from 'react';

import styled from 'styled-components';

import CommentPopover from 'components/CommentPopover';
import { getAttendanceColor, getAttendanceHoverColor } from 'utils/helpers';

const Container = styled.div`
	background: ${(props) => props.background || '#eeeeee'};
	position: relative;

	:hover {
		background: ${(props) => props.hover || '#eeeeee'};
	}
`;

const AttendanceBlock = ({ data = { attendance: {}, program: {} }, goto }) => {
	const { attendance = { status: '', notes: '' }, program } = data;
	const { status, notes } = attendance;
	const { program_id, startTime, groupName } = program;
	const [showPopup, setShowPopup] = useState(false);

	return (
		<Container
			className="hand font-9 font-weight-bold w-100 p-2 border-top border-bottom"
			background={getAttendanceColor(status)}
			hover={getAttendanceHoverColor(status)}
			onClick={() => goto(`groupDetails/${program_id}`)}
			onMouseEnter={() => setShowPopup(true)}
			onMouseLeave={() => setShowPopup(false)}
			id={`block-${program_id}`}
		>
			{notes && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: '50%',
						transform: 'translate(-50%, -2px)',
					}}
				>
					<CommentPopover
						toggle={() => setShowPopup(!showPopup)}
						target={`block-${program_id}`}
						isOpen={showPopup}
						title="Reason for Excuse"
						comment={notes}
						mode="view"
					/>
				</div>
			)}
			<div>
				{startTime} {groupName}
			</div>
		</Container>
	);
};

export default AttendanceBlock;
