import { isProduction } from 'utils/constants';

enum LogLevel {
	Log = 'log',
	Error = 'error',
	Debug = 'debug',
	Info = 'info',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LogMessage = any;

interface CustomLogger {
	log: (message: LogMessage) => void;
	error: (message: LogMessage) => void;
	debug: (message: LogMessage) => void;
	info: (message: LogMessage) => void;
}

const LOG_LEVEL_TO_COLOR = {
	[LogLevel.Log]: 'blue',
	[LogLevel.Error]: 'red',
	[LogLevel.Debug]: 'yellow',
	[LogLevel.Info]: 'green',
};

const startTime = Date.now();

const denyListedLogsForProduction = new Set([LogLevel.Debug, LogLevel.Info]);

function formatLog(level: LogLevel, label: string, message: string) {
	/** Do not expose console messages in prod. */
	if (isProduction && denyListedLogsForProduction.has(level)) {
		return;
	}

	const currentTime = Date.now();
	const delta = (currentTime - startTime) / 1000;
	const color = LOG_LEVEL_TO_COLOR[level];

	// eslint-disable-next-line no-console
	console.log(
		`(${delta})` + `%c ${level}:` + `%c [${label}] ${message}`,
		`color: ${color}`,
		'',
	);
}

export function getLogger(label: string): CustomLogger {
	return {
		log: (message: LogMessage) => void formatLog(LogLevel.Log, label, message),
		error: (message: LogMessage) =>
			void formatLog(LogLevel.Error, label, message),
		debug: (message: LogMessage) =>
			void formatLog(LogLevel.Debug, label, message),
		info: (message: LogMessage) =>
			void formatLog(LogLevel.Info, label, message),
	};
}

/**
 * Creates a safe logger that will only run 1000 times. Useful if trying to debug a potentially infinite loop.
 * @param label
 * @returns
 */
export function getSafeLogger(label: string, maxLogs = 1000): CustomLogger {
	let count = 0;

	function safeLog(level: LogLevel, message: LogMessage) {
		if (count < maxLogs) {
			formatLog(level, label, message);
			count++;
		}
	}

	return {
		log: (message: LogMessage) => void safeLog(LogLevel.Log, message),
		error: (message: LogMessage) => void safeLog(LogLevel.Error, message),
		debug: (message: LogMessage) => void safeLog(LogLevel.Debug, message),
		info: (message: LogMessage) => void safeLog(LogLevel.Info, message),
	};
}

export const TEST_ONLY = {
	LOG_LEVEL_TO_COLOR,
	LogLevel,
};
