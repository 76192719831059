const filters = (options, activeTab) => {
	if (activeTab === '1') {
		return [
			/* {
        type: 'Dropdown Input',
        name: 'name',
        key: 'clientName',
        label: 'client',
        selectOptions: options.name,
        selectLabel: 'CLIENT NAME',
        defaultSelect: 'All Clients',
      },
       */ {
				type: 'Single Select',
				name: 'clientType',
				label: 'type',
				selectOptions: options.clientType,
				selectLabel: 'CLIENT TYPE',
				defaultSelect: 'All Types',
			},
			{
				type: 'Single Select',
				name: 'state',
				label: 'state',
				selectOptions: options.state,
				selectLabel: 'STATE',
				defaultSelect: 'All States',
			},
			{
				type: 'Toggle',
				name: 'showInactiveClients',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactiveClients,
			},
		];
	}
	return [
		/* {
      type: 'Dropdown Input',
      name: 'name',
      key: 'clinicianName',
      label: 'clinician',
      selectOptions: options.name,
      selectLabel: 'CLINICIAN NAME',
      defaultSelect: 'All Clinicians',
    },
     */ {
			type: 'Single Select',
			name: 'therapistType',
			label: 'type',
			selectOptions: options.therapistType,
			selectLabel: 'THERAPIST TYPE',
			defaultSelect: 'All Types',
		},
		{
			type: 'Toggle',
			name: 'showInactiveClinicians',
			label: 'Show Inactive',
			selectLabel: 'SHOW INACTIVE',
			isSelected: options.showInactiveClinicians,
		},
	];
};

export default filters;
