import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'react-phone-number-input';
import history from 'utils/history';

import SearchBar from 'atoms/SearchBar';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

import loadIcon from 'images/load-icon.gif';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import AdministrationModal from './AdministrationModal';

import {
    getOQClient,
    createOQClient,
} from './actions';
import { useAllClients } from 'containers/CliniciansAndClients/selectors';
import { filterPatients } from 'utils/helpers';

const PatientActionsCard = (): JSX.Element => {
    const {
        isFetchingClient,
        clientHasOQAccount,
        isCreatingClient,
        historyURL,
        recentQuestionnaireDate,
    } = useSelector((state: RootState) => state.get('measures').toJS());

    const userRole = useSelector((state: RootState) =>
        state.get('app').get('auth').get('loggedInUser').get('roleName')
    );

    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState<ClientData | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const allClients = useAllClients();

    const handleSearch = (value: string): void => {
        const client = allClients.find((client: ClientData) => client.patientId.toString() == value);
        setSelectedClient(client);
        if (client?.patientId) {
            dispatch(
                getOQClient({
                    patientId: client.patientId?.toString(),
                }),
            );
        }
    };

    const createList = (): Array<{ label: string; value: string }> => {
        return allClients.map((client: ClientData) => {
            return {
                label: client.client?.name,
                value: client.patientId,
            };
        });
    };

    const handleCreatePatient = () => {
        if (selectedClient?.patientId) {
            dispatch(
                createOQClient({
                    patientId: selectedClient.patientId.toString(),
                }),
            );
        }
    };

    const goToProfile = () => {
        const pathname = `/${userRole}/dashboard/profile/${selectedClient?.patientId}`;
        history.push({
            pathname,
        });
    };

    const ClientCreationButton = (): JSX.Element => (
        <div>
            <button
                className="btn btn-primary primary-btn-color px-3 py-2 font-10 ml-2"
                onClick={handleCreatePatient}
                disabled={isCreatingClient}
            >
                {isCreatingClient ? (
                    <img src={loadIcon} alt="Loading..." height="23px" />
                ) : (
                    <span>Create Client</span>
                )}
            </button>
        </div>
    );

    const ClientActionsRow = (): JSX.Element => (
        <div className="d-flex">
            <button
                onClick={() => setShowModal(true)}
                className="btn btn-primary primary-btn-color mr-2 font-10"
            >
                Administer
            </button>
            <a
                className="btn btn-primary primary-btn-color px-3 mr-2 font-10 d-inline-flex align-items-center"
                href={historyURL}
                rel="noreferrer noopener"
                target="_blank"
            >
                History
            </a>
        </div>
    );

    const ClientProfile = (): JSX.Element => {
        if (!selectedClient?.client) {
            return (
                <div className="card bg-white w-100 mx-0 pb-4 mb-2 border-0">
                    <div className="text-center w-100 font-30 pt-3 uppercase">Search for a client</div>
                </div>
            );
        }

        return (
            <div className="card bg-white p-3 w-100 mx-0 mb-2">
                <div className="w-100 mx-0 justify-content-between d-flex align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <div className="hand mr-3" onClick={goToProfile}>
                            <ImageDetailsCell
                                heading={selectedClient.client.name}
                                primarySubHeading={formatPhoneNumber(selectedClient.client.phone)}
                            />
                        </div>
                        {showMoreInfo ?
                            <span
                                className="hand m-0 font-12"
                                onClick={() => setShowMoreInfo(false)}
                            >
                                Most recent completed: {recentQuestionnaireDate}
                            </span>
                            :
                            <MoreHorizIcon
                                className="hand"
                                color="primary"
                                onClick={() => setShowMoreInfo(true)}
                            />
                        }
                    </div>
                    {isFetchingClient ?
                        <div className="d-flex">
                            <SmallLoadingIndicator className="my-0" color="var(--main-color)" />
                        </div>
                        :
                        clientHasOQAccount ?
                            <ClientActionsRow />
                            :
                            <ClientCreationButton />
                    }
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="w-100 px-0 mb-2">
                <SearchBar
                    onSearch={handleSearch}
                    placeholder={'Search For Client'}
                    classNames={{ container: 'mx-0' }}
                    dropdownList={createList()}
                    filter={filterPatients}
                />
            </div>
            <ClientProfile />
            {selectedClient &&
                <AdministrationModal
                    isOpen={showModal}
                    toggle={() => setShowModal(prev => !prev)}
                    client={selectedClient}
                />
            }
        </>
    );
};

export default PatientActionsCard;
