import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
// import components
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <SchoolPerformance />, a functional component for Child Intake Evaluation
 */
const SchoolPerformance = (props) => {
	const { data, handleChange } = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			<h3 className="mb-2">School Performance</h3>
			<label htmlFor="schoolPerformance" className="form-font mb-4">
				(If patient is out of school, complete as to what did occur)
			</label>

			<div className="row mb-3">
				<label
					htmlFor="gradeAndSchool"
					className="form-font col-4"
					style={{ paddingTop: '11px' }}
				>
					CURRENT GRADE AND SCHOOL:
				</label>

				<TextInput
					className="col-4"
					label="GRADE"
					id="grade"
					value={data.grade}
					handleInput={handleChange}
				/>
				<TextInput
					className="col-4"
					label="SCHOOL"
					id="school"
					value={data.school}
					handleInput={handleChange}
				/>
			</div>

			<TextArea
				label="HOW DOES THE PATIENT DO IN SCHOOL? (IN GRADE SCHOOL AND HIGH SCHOOL - WHAT DO TEACHERS SAY; DOES YOUR CHILD LIVE UP TO HIS/HER ACADEMIC POTENTIAL? HOW ABOUT ABSENCES, TARDINESS, AND ALERTNESS IN CLASS, etc.)"
				id="schoolPerformance"
				value={data.schoolPerformance}
				handleInput={handleChange}
			/>

			<div className="row mt-3 mb-3">
				<label htmlFor="hasIEP" className="form-font col-8">
					DOES THE STUDENT HAVE AN IEP (INDIVIDUALIZED EDUCATION PROGRAM) OR
					504? IF YES, PLEASE BRING A COPY OF THE EVALUATION.
				</label>
				<SelectButtonGroup
					id="hasIEP"
					value={data.hasIEP}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className="col-4"
				/>
			</div>

			<div className="row mt-3 mb-3">
				<label htmlFor="hasIEPUnderstanding" className="form-font col-8">
					DO YOU UNDERSTAND WHY THE STUDENT HAS AN IEP OR 504?
				</label>
				<SelectButtonGroup
					id="hasIEPUnderstanding"
					value={data.hasIEPUnderstanding}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className="col-4"
				/>
			</div>
			{data.hasIEPUnderstanding === 'yes' && (
				<TextArea
					label="IF YES, PLEASE DESCRIBE YOUR UNDERSTANDING OF IT:"
					id="iepUnderstanding"
					value={data.iepUnderstanding}
					handleInput={handleChange}
				/>
			)}

			<TextInput
				label="DO YOU FEEL THAT THE SCHOOL HAS FOLLOWED THROUGH WITH THE SUGGESTED ACCOMMODATIONS DESCRIBED IN THE IEP OR 504?"
				id="iepFollowed"
				value={data.iepFollowed}
				handleInput={handleChange}
			/>
		</div>
	);
};

SchoolPerformance.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default SchoolPerformance;
