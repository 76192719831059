import moment from 'moment';

import { headerStyle } from 'containers/App/constants';

const typeToTextMap = {
	mtcApp: 'MTC App',
	clinician: 'Clinician',
	billing: 'Billing',
	program: 'Program',
	other: 'Other',
};

const columns = (viewFunc) => {
	return [
		{
			headerStyle: {
				...headerStyle,
				width: '100px',
				paddingRight: '1rem',
			},
			render: (row) => (
				<button
					className="btn btn-white bg-white link-color border font-13 font-weight-bold d-flex justify-content-start mx-auto"
					onClick={() => viewFunc(row)}
				>
					View
				</button>
			),
		},
		{
			field: 'type',
			title: 'TYPE',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2">{typeToTextMap[row.type]}</div>
			),
		},
		{
			field: 'topic',
			title: 'SUBJECT',
			headerStyle,
			render: (row) => <div className="font-13 my-2">{row.topic}</div>,
		},
		{
			field: 'createdAt',
			title: 'CREATED ON',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2">
					{moment(row.createdAt).format('MM-DD-YYYY')}
				</div>
			),
		},
		{
			field: 'updatedAt',
			title: 'UPDATED ON',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2">
					{moment(row.updatedAt).format('MM-DD-YYYY')}
				</div>
			),
		},
		{
			field: 'completed',
			title: 'STATUS',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2">
					{row.completed ? 'Completed' : 'Requires Action'}
				</div>
			),
		},
	];
};
export default columns;
