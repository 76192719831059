import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import { BULLET } from 'constants/main';
import { capitalizeWords } from 'utils/helpers';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class GroupSnippets extends React.Component {
	render() {
		const { groupDetails } = this.props;
		const mtcOutSideURL = 'https://www.mindtherapyclinic.com/therapies';
		return (
			<div className="card custom-snippet mb-2">
				<div className="snippet-body">
					<div className="snippet-title text-dark font-15 custom-snippet-heading">
						{`${groupDetails.group_type} ${BULLET} ${capitalizeWords(
							groupDetails.location,
						)}`}
					</div>
					<div className="text-cust-grey font-10 custom-snippet-label text-uppercase py-2">
						{groupDetails.group_size} Therapy
					</div>
					<div className="d-flex py-1 mb-2">
						<div className="col-6 p-0">
							<div className="text-cust-grey font-10 custom-snippet-label text-uppercase py-2">
								Next Session
							</div>
							<div className="text-dark mb-2 custom-snippet-label-value font-13">
								{moment(groupDetails.upcomingSession).format('MMM D[,] YYYY')}
							</div>
						</div>
						<div className="col-6">
							<div className="text-cust-grey font-10 custom-snippet-label text-uppercase py-2">
								Clinician
							</div>
							<div className="text-dark custom-snippet-label-value font-13">
								{groupDetails.clinician_name}
							</div>
							<div className="text-dark custom-snippet-label-value font-13">
								{groupDetails.therapist_type}
							</div>
						</div>
					</div>
					<div className="d-flex col justify-content-center align-items-center mt-2">
						<button
							type="button"
							className="btn btn-primary primary-btn-color px-4 py-2 font-10"
							onClick={() => window.open(mtcOutSideURL, '_blank')}
						>
							Learn More
						</button>
					</div>
				</div>
			</div>
		);
	}
}

GroupSnippets.propTypes = {
	groupDetails: PropTypes.object.isRequired,
};

export default GroupSnippets;
