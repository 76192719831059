import imgBrowse from 'images/ic_browse.svg';
import imgBrowseActive from 'images/ic_browse_active.svg';
import imgDocuments from 'images/ic_forms.svg';
import imgDocumentsActive from 'images/ic_forms_active.svg';
import imgMyGroups from 'images/ic_groups.svg';
import imgMyGroupsActive from 'images/ic_groups_active.svg';
import imgRequests from 'images/ic_requests.svg';
import imgRequestsActive from 'images/ic_requests_active.svg';

const patientFeatures = [
	/* {
    tabId: 'patientTodos',
    tabName: 'To-Dos',
    tabIconActive: <img src={imgTodosActive} alt="TodosActive" />,
    tabIconInactive: <img src={imgTodos} alt="TodosInactive" />,
    to: '/patient/dashboard/todos',
    childs: [
      {
        tabId: 'requests',
        tabName: 'Requests',
      },
    ],
  }, */
	{
		tabId: 'patientMyGroups',
		tabName: 'My Therapies',
		mobileTabName: 'My Therapies',
		tabIconActive: (
			<img className="tab-img" src={imgMyGroupsActive} alt="MyGroupsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgMyGroups} alt="MyGroupsInactive" />
		),
		to: '/patient/dashboard/mygroups',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'patientRequests',
		tabName: 'Requests',
		mobileTabName: 'Requests',
		tabIconActive: (
			<img className="tab-img" src={imgRequestsActive} alt="RequestsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgRequests} alt="RequestsInactive" />
		),
		to: '/patient/dashboard/requests',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'patientForms',
		tabName: 'Forms',
		mobileTabName: 'Forms',
		tabIconActive: (
			<img className="tab-img" src={imgDocumentsActive} alt="DocumentsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgDocuments} alt="DocumentsInactive" />
		),
		to: '/patient/dashboard/forms',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'patientBrowseGroups',
		tabName: 'Browse Therapies',
		mobileTabName: 'Browse',
		tabIconActive: (
			<img className="tab-img" src={imgBrowseActive} alt="BrowseGroupsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgBrowse} alt="BrowseGroupsInactive" />
		),
		to: '/patient/dashboard/browsegroups',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
];

export default patientFeatures;
