import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * <TextArea />, a functional component for TextArea
 */
const TextArea = (props) => {
	const {
		label,
		placeholder,
		handleInput,
		className,
		id,
		value,
		rows,
		readOnly,
		style,
	} = props;

	const modifiedClass = `${className} form-group`;

	return (
		<div className={modifiedClass}>
			<label htmlFor={id} className="form-font">
				{label}
			</label>
			<textarea
				readOnly={readOnly}
				value={value}
				type="text"
				name={id}
				id={id}
				className="form-control text-muted"
				style={style}
				placeholder={placeholder}
				onChange={handleInput}
				rows={rows}
			/>
		</div>
	);
};

TextArea.propTypes = {
	handleInput: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	rows: PropTypes.number,
};

TextArea.defaultProps = {
	rows: 2,
};

export default TextArea;
