import { useEffect } from 'react';

export default function useCollectJS(
	onFinish,
	onValidate,
	fields,
	dependencies,
) {
	useEffect(() => {
		window.CollectJS.configure({
			variant: 'inline',
			styleSniffer: true,
			callback: (resp) => {
				onFinish(resp);
			},
			fields,
			validationCallback: onValidate,
		});
	}, dependencies);
}
