import React from 'react';

const kebab = (
	<svg
		width="4px"
		height="16px"
		viewBox="0 0 4 16"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="To-Dos-(Admin)"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="01.1_Admin-Dashboard_ToDos"
				transform="translate(-949.000000, -390.000000)"
			>
				<g id="Group-15" transform="translate(42.000000, 119.000000)">
					<g id="ic_more" transform="translate(897.000000, 267.000000)">
						<polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
						<path
							d="M12,8 C13.1,8 14,7.1 14,6 C14,4.9 13.1,4 12,4 C10.9,4 10,4.9 10,6 C10,7.1 10.9,8 12,8 Z M12,10 C10.9,10 10,10.9 10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,10.9 13.1,10 12,10 Z M12,16 C10.9,16 10,16.9 10,18 C10,19.1 10.9,20 12,20 C13.1,20 14,19.1 14,18 C14,16.9 13.1,16 12,16 Z"
							id="Shape"
							fillRule="nonzero"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default kebab;
