import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the clinicianRegister state domain
 */

const selectClinicianRegisterDomain = (state) =>
	state.get('clinicianRegister', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ClinicianRegister
 */

const makeSelectClinicianRegister = () =>
	createSelector(selectClinicianRegisterDomain, (substate) =>
		returnJS(substate),
	);

export default makeSelectClinicianRegister;
export { selectClinicianRegisterDomain };
