import { call, put, select, takeLatest } from 'redux-saga/effects';

import makeSelectPatientRegister from 'containers/PatientRegister/selectors';
import {
	findUser,
	patientRegistrationAPI,
	patientRegistrationFetchDataAPI,
} from 'utils/api';

import {
	docCreateSuccess,
	docSignSuccess,
	error,
	success,
	successCheckOnboading,
} from './actions';
import { intakeApi, signingApi } from './api';
import {
	CHECK_ONBOARDING,
	DOC_CREATE,
	DOC_SIGN,
	FETCH_DATA,
	PATIENT_INTAKE,
} from './constants';
import makeSelectPatientIntake from './selectors';

export function* performPatientIntake(action) {
	const patientIntake = yield select(makeSelectPatientIntake());
	const { cb } = action;
	try {
		const response = yield call(patientRegistrationAPI, patientIntake);
		yield put(success(response));
		if (cb) {
			cb();
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performCreateDoc() {
	const patientIntake = yield select(makeSelectPatientIntake());
	const patientRegister = yield select(makeSelectPatientRegister());

	try {
		const resp = yield call(intakeApi, {
			...patientRegister,
			...patientIntake,
		});
		yield put(docCreateSuccess(resp));
	} catch (err) {
		yield put(error(err));
	}
}

export function* performSignDoc() {
	const patientIntake = yield select(makeSelectPatientIntake());
	const patientRegister = yield select(makeSelectPatientRegister());

	try {
		const resp = yield call(signingApi, {
			...patientRegister,
			...patientIntake,
		});
		yield put(docSignSuccess(resp));
	} catch (err) {
		yield put(error(err));
	}
}

export function* performFetchData() {
	const patientIntake = yield select(makeSelectPatientIntake());

	try {
		const response = yield call(patientRegistrationFetchDataAPI, patientIntake);
		yield put(success(response));
	} catch (err) {
		yield put(error());
	}
}
export function* checkOnboarding() {
	const patientIntake = yield select(makeSelectPatientIntake());

	try {
		const response = yield call(findUser, patientIntake);
		yield put(successCheckOnboading(response));
	} catch (err) {
		yield put(error());
	}
}

export default function* patientIntakeSaga() {
	yield takeLatest(PATIENT_INTAKE, performPatientIntake);
	yield takeLatest(DOC_CREATE, performCreateDoc);
	yield takeLatest(DOC_SIGN, performSignDoc);
	yield takeLatest(FETCH_DATA, performFetchData);
	yield takeLatest(CHECK_ONBOARDING, checkOnboarding);
}
