import { Formik } from 'formik';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import LoadingIndicator from 'components/LoadingIndicator';
import RequestSentModal from 'components/RequestSentModal';
import useIsMobile from 'hooks/isMobile.ts';
import { closeModal } from 'reducers/Modal';
import { getUserAddressApi, saveUserPaymentApi } from 'utils/api';
import PaymentForm from 'v2/routes/intake/PaymentForm';
import {
	PatientPaymentValidation,
	initialPaymentValues,
} from 'v2/routes/intake/constants';

export default function UpdatePaymentInfo() {
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [submitResponse, setSubmitResponse] = useState({});
	const [defaultAddress, setDefaultAddress] = useState({
		street: '123 Main St',
		state: 'WI',
		city: 'MILWAUKEE',
		zipcode: '53217',
	});
	const isMobile = useIsMobile();

	useEffect(() => {
		setIsFetching(true);
		getUserAddressApi()
			.then((resp) => {
				if (resp.success) {
					setDefaultAddress(resp.address);
				}
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, []);

	async function saveUserPaymentInfo(formValues) {
		const backendResp = await saveUserPaymentApi({
			...formValues,
		});
		setIsSubmitting(false);

		if (backendResp.success) {
			setSubmitResponse({
				success: true,
				message: 'Information Successfully Updated',
			});
		} else {
			setSubmitResponse({
				success: false,
				message:
					'Payment information could not be validated. Please contact an administrator',
			});
		}
	}

	function toggle() {
		dispatch(closeModal());
	}

	function onSubmit() {
		setIsSubmitting(true);
		window.CollectJS.startPaymentRequest();
	}

	return (
		<>
			<LoadingIndicator
				text="Verifying payment information..."
				isLoading={isSubmitting}
			/>
			<Modal
				isOpen={true}
				className="modal-dialog-centered custom-modal covid-modal"
				backdrop="static"
				toggle={toggle}
			>
				<ModalHeader
					toggle={toggle}
					className={`align-center text-center px-${
						isMobile ? '2' : '4'
					} border-0 container-fluid custom-bg-color`}
					cssModule={{
						'modal-title': `d-flex justify-content-center w-100 pt-2 font-${
							isMobile ? '20' : '35'
						} text-dark font-weight-bold`,
					}}
				>
					Update Payment Information
				</ModalHeader>
				<ModalBody
					className={`pb-3 custom-bg-color ${isMobile && 'px-0 pt-0'}`}
				>
					{isFetching ? (
						<SmallLoadingIndicator color="var(--main-color)" text="Loading" />
					) : (
						<Formik
							initialValues={initialPaymentValues}
							onSubmit={onSubmit}
							validationSchema={PatientPaymentValidation}
							validateOnMount
						>
							{({ handleSubmit, isValid }) => (
								<>
									<div className="p-4 bg-white border bb-0">
										Submitting new payment information will overwrite previously
										stored information.
									</div>
									<PaymentForm
										onSaveToken={saveUserPaymentInfo}
										defaultAddress={defaultAddress}
									/>
									<div
										className={
											isMobile
												? 'row w-100 mx-0 justify-content-center mt-4'
												: 'd-flex justify-content-center align-items-center w-100 p-4'
										}
									>
										<button
											type="button"
											className="btn btn-primary btn-lg btn-white bg-white border link-color mr-3 text-uppercase font-15 col-4 d-flex justify-content-center"
											onClick={toggle}
										>
											<span className="mr-0 pl-3 w-100">CANCEL</span>
										</button>
										<button
											disabled={!isValid}
											type="button"
											className="btn btn-primary btn-lg btn-color text-uppercase font-15 col-4 d-flex justify-content-center"
											onClick={handleSubmit}
										>
											<span className="mr-0 pl-3 w-100">SUBMIT</span>
											<i className="fas fa-check align-self-center" />
										</button>
									</div>
								</>
							)}
						</Formik>
					)}
				</ModalBody>
			</Modal>
			{submitResponse.message && (
				<RequestSentModal
					message={submitResponse.message}
					hide={toggle}
					successStatus={submitResponse.success}
				/>
			)}
		</>
	);
}
