import PropTypes from 'prop-types';

import React, { useState } from 'react';

import Popup from 'atoms/Popup';

const DeleteButton = (props) => {
	const {
		onClick,
		popupText,
		popupAdjust,
		styles = {},
		classNames = {},
		size,
	} = props;

	const [popupOpen, setPopupOpen] = useState(false);

	const onMouseEnter = () => {
		setPopupOpen(true);
	};

	const onMouseLeave = () => {
		setPopupOpen(false);
	};

	return (
		<div
			className={`${classNames.container} form-row justify-content-end`}
			style={{ ...styles.container, position: 'relative' }}
		>
			{popupText && (
				<Popup
					isOpen={popupOpen}
					text={popupText}
					style={styles.popup}
					adjust={popupAdjust}
				/>
			)}
			<button
				className="close"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				type="button"
				aria-label="Close"
				style={styles.button}
			>
				<span style={{ fontSize: size, ...styles.content }} aria-hidden="true">
					×
				</span>
			</button>
		</div>
	);
};

DeleteButton.propTypes = {
	onClick: PropTypes.func,
	popupText: PropTypes.string,
	popupAdjust: PropTypes.number,
	styles: PropTypes.object,
	classNames: PropTypes.string,
	size: PropTypes.number,
};

export default DeleteButton;
