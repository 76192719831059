import moment from 'moment';

import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomDatePicker from 'atoms/CustomDatePicker';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import { noop } from 'utils/common';

import './index.css';

const extendLengths = [
	{ label: 1, value: 1 },
	{ label: 2, value: 2 },
	{ label: 3, value: 3 },
	{ label: 4, value: 4 },
	{ label: 'Specific Date', value: 'specific' },
];
const timeOptions = [
	{ label: 'Week(s)', value: 'week' },
	{ label: 'Month(s)', value: 'month' },
	{ label: 'Year(s)', value: 'year' },
];

const ExtendSessionsModal = ({
	hide,
	details,
	lastSession = moment().utc().format('YYYY-MM-DD'),
	onSubmit,
}) => {
	const [extendLength, setExtendLength] = useState(extendLengths[0]);
	const [extendBy, setExtendBy] = useState();
	const [endDate, setEndDate] = useState(moment(lastSession).toString());
	const [isFormComplete, setIsFormComplete] = useState(false);

	const handleDateChange = (date) => {
		setExtendBy(moment(date).toString());
	};

	const handleSelect = (name) => (option) => {
		if (name === 'extendLength') {
			setExtendLength(option);
			if (option.value === 'specific') {
				setExtendBy(null);
				setIsFormComplete(false);
			}
		} else if (name === 'extendBy') {
			setExtendBy(option);
			setIsFormComplete(true);
		}
	};

	const handleSubmit = () => {
		onSubmit({
			lastSession: lastSession.toString(),
			extendLength: extendLength.value,
			extendBy: extendBy.value || extendBy,
		});
	};

	useEffect(() => {
		if (extendLength?.value && extendBy?.value) {
			let newDate;
			if (extendLength.value === 'specific') {
				newDate = moment(extendBy).format();
			} else {
				newDate = moment(lastSession)
					.add(extendLength.value, extendBy.value)
					.format();
			}

			setEndDate(newDate);
		}
	}, [extendLength, extendBy]);

	return (
		<div>
			<Modal isOpen="true" className="modal-dialog-centered" backdrop="static">
				<ModalHeader toggle={hide} className="border-0">
					Extend Sessions {details && `for ${details.groupName}`}
				</ModalHeader>
				<ModalBody className="container-fluid pt-0">
					<div className="form-group align-middle font-16">
						<CustomDatePicker
							className="col-md-12"
							label={<span className="form-group">CURRENT END DATE</span>}
							id="lastSession"
							selected={lastSession}
							handleDateChange={noop}
							disabled
						/>
					</div>
					<div className="row mx-0 form-group align-middle font-16">
						<div className="col-6">
							<SelectAutoComplete
								label="EXTEND LENGTH*"
								handleChange={handleSelect('extendLength')}
								options={extendLengths}
								value={extendLength}
								placeholder="Select Length"
								controlStyles={{ height: 49 }}
							/>
						</div>
						<div className="col-6">
							{extendLength.value === 'specific' ? (
								<CustomDatePicker
									className="modal-datepicker-y-offset"
									handleDateChange={handleDateChange}
									label={<span className="form-group">EXTEND TO*</span>}
									id="extendBy"
									selected={extendBy}
									minDate={lastSession}
								/>
							) : (
								<>
									<SelectAutoComplete
										label="EXTEND BY*"
										handleChange={handleSelect('extendBy')}
										options={timeOptions}
										value={extendBy}
										placeholder="Select Time Period"
										controlStyles={{ height: 49 }}
									/>
								</>
							)}
						</div>
					</div>
					{extendLength.value !== 'specific' && (
						<div className="form-group align-middle font-16">
							<CustomDatePicker
								className="col-md-12"
								label={<span className="form-group">NEW END DATE</span>}
								id="endDate"
								selected={endDate}
								disabled
							/>
						</div>
					)}
					<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
						<button
							type="button"
							className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
							onClick={handleSubmit}
							disabled={!isFormComplete}
						>
							<>
								<span className="mr-0 w-100">SUBMIT</span>
								<i className="fas fa-check align-self-center" />
							</>
						</button>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

ExtendSessionsModal.propTypes = {
	hide: PropTypes.func.isRequired,
};

export default ExtendSessionsModal;
