import { parseISO } from 'date-fns';

import moment from 'moment';

import Steps, { Step } from 'rc-steps';

import React, { useEffect, useReducer, useRef, useState } from 'react';

import TextInput from 'atoms/CustTextInput';
import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import GeoDropdown from 'components/GeoDropdown';
import IAccept from 'components/IAccept';
import { maxlen, numbersOnly, required } from 'utils/validations';

import ClientDetails from './ClientDetails';
import EntityDetails from './EntityDetails';
import Purpose from './Purpose';

const roiReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				[action.name]: action.value,
			};
		case 'CHECKBOX':
			return {
				...state,
				[action.name]: !state[action.name],
			};
		case 'DATE':
			return {
				...state,
				[action.id]: action.value,
			};
		case 'CITY':
			return {
				...state,
				clientCity: action.value,
			};
		case 'ZIPCODE':
			return {
				...state,
				clientZipcode: action.value,
			};
		case 'STATE':
			return {
				...state,
				clientState: action.value,
			};
		case 'NUMBER':
			return {
				...state,
				[action.id]: action.value,
			};
		case 'LOADED_STATE':
			return {
				...action.state,
			};
		default:
			return state;
	}
};

const ROIRequest = (props) => {
	const { states, cities, zipcodes, roi, submitForm, isMobile } = props;

	const [step, setStep] = useState(0);
	const [state, dispatch] = useReducer(roiReducer, roi);
	const scrollRef = useRef(null);

	const [hasRelease, setRelease] = useState(false);
	const [hasPurpose, setPurpose] = useState(false);

	useEffect(() => {
		setRelease(
			state.evalChecked ||
				state.labResChecked ||
				state.progRepChecked ||
				state.medInfoChecked ||
				state.testResChecked ||
				state.diagnChecked ||
				state.sumRepChecked ||
				state.entRecChecked,
		);

		setPurpose(
			state.planTreatChecked ||
				state.contTreatChecked ||
				state.caseRevChecked ||
				state.updateFileChecked,
		);
	}, [state]);

	useEffect(() => {
		if (props.roi && !state.clientName) {
			dispatch({ type: 'LOADED_STATE', state: props.roi });
		}
	}, [props.roi]);

	const numSteps = 3;

	const handleChange = (event) => {
		const { target } = event;
		event.preventDefault();
		const { name, value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		dispatch({ type: 'CHANGE', name, value });
	};

	const handleCheckBox = (event) => {
		const { target } = event;
		const { name } = target;

		dispatch({ type: 'CHECKBOX', name });
	};

	const handleDateChange = (id) => (date) => {
		let dateFormat;

		if (date) {
			dateFormat = parseISO(moment(date).format('YYYY-MM-DD'));
		}

		dispatch({ type: 'DATE', id, value: dateFormat });
	};

	const handleGeoChange = (type) => (data) => {
		if (type === 'state') {
			dispatch({ type: 'CITY', value: null });
			dispatch({ type: 'STATE', value: data });
		} else if (type === 'city') {
			dispatch({ type: 'CITY', value: data });
		} else if (type === 'zipcode') {
			dispatch({ type: 'ZIPCODE', value: data });
		}
	};

	useEffect(() => {
		const { fetchCities } = props;
		if (state.clientState) {
			fetchCities(state.clientState);
		}
	}, [state.clientState]);

	useEffect(() => {
		const { fetchZipcodes } = props;
		if (state.clientCity) {
			fetchZipcodes(state.clientCity);
		}
	}, [state.clientCity]);

	const handleNumberChange = (id, values) => {
		dispatch({ type: 'NUMBER', id, value: values.formattedValue });
	};

	const handleSelect = (option) => {
		dispatch({ type: 'CHANGE', name: 'authClientType', value: option.value });
	};

	const handleClick = (option) => {
		dispatch({ type: 'CHECKBOX', name: option });
	};

	const handleSubmit = () => {
		submitForm(null, state);
	};

	const getStep = () => {
		switch (step) {
			case 0:
				return (
					<ClientDetails
						roi={state}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						isMobile={isMobile}
						handleChange={handleChange}
						handleNumberChange={handleNumberChange}
						handleDateChange={handleDateChange}
						handleGeoChange={handleGeoChange}
					/>
				);
			case 1:
				return (
					<EntityDetails
						roi={state}
						isMobile={isMobile}
						handleChange={handleChange}
						handleNumberChange={handleNumberChange}
						handleCheckBox={handleCheckBox}
					/>
				);
			case 2:
				return (
					<Purpose
						roi={state}
						isMobile={isMobile}
						handleSelect={handleSelect}
						handleClick={handleClick}
					/>
				);
			default:
				return <div>No Step Provided</div>;
		}
	};

	const isFormComplete = () => {
		for (let i = 0; i < 3; i++) {
			if (isStepIncomplete(i)) {
				return false;
			}
		}
		return true;
	};

	const nextStep = (e) => {
		e.preventDefault();

		if (step < numSteps - 1) {
			setStep(step + 1);
			scrollRef.current.scrollTop = 0;
		} else {
			handleSubmit();
		}
	};

	const prevStep = (e) => {
		e.preventDefault();

		if (step > 0) {
			setStep(step - 1);
			scrollRef.current.scrollTop = 0;
		}
	};

	const isStepIncomplete = (val) => {
		switch (val || step) {
			case 0:
				return !(
					state.clientName &&
					state.clientPhone &&
					state.clientAddress &&
					state.clientState &&
					state.clientCity &&
					state.clientAuthName
				);
			case 1:
				return !(
					(state.sendChecked || state.receiveChecked) &&
					state.entityName &&
					state.entityType &&
					state.entityPhone &&
					state.entityAddress
				);
			case 2:
				return !(hasRelease && hasPurpose && state.roiChecked);
			default:
				return false;
		}
	};

	let containerStyle = {};

	if (isMobile) {
		containerStyle = {
			width: '90%',
		};
	}

	const containerClass = `container-fluid bg-white py-3 ${
		isMobile ? 'px-2 mx-auto' : 'mx-4'
	}`;

	const inputGroupName = 'row';
	const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
	const inputStyle = isMobile
		? {
				width: '100%',
				maxWidth: 350,
		  }
		: {};

	if (isMobile) {
		return (
			<div className="container h-100">
				<Steps className="mb-3" labelPlacement="vertical" current={step}>
					<Step title="Client Details" />
					<Step title="Entity Details" />
					<Step title="Purpose" />
				</Steps>
				<div
					className="card d-flex flex-column p-4 mb-4"
					style={{
						maxHeight: '60vh',
						overflowY: 'auto',
					}}
					ref={scrollRef}
				>
					{getStep()}
				</div>
				<div className="row justify-content-between px-5">
					<button
						disabled={step === 0}
						className="btn btn-primary btn-lg col-5 btn-color font-15"
						onClick={prevStep}
					>
						<div className="row">
							<div className="col-4 d-flex justify-content-start align-self-center">
								<img className="fas fa-arrow-left" alt="arrow-left" />
							</div>
							<div className="col-4 d-flex justify-content-center">
								<span>Prev</span>
							</div>
						</div>
					</button>
					<button
						disabled={isStepIncomplete()}
						className="btn btn-primary btn-lg col-5 btn-color font-15"
						onClick={nextStep}
					>
						{step < numSteps - 1 ? (
							<div className="row justify-content-end">
								<div className="col-4 d-flex justify-content-center">
									<span>Next</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</div>
						) : (
							<div className="row justify-content-end">
								<div className="col-4 d-flex justify-content-center">
									<span>Submit</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</div>
						)}
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="row custom-bg-color">
			<div className={containerClass} style={containerStyle}>
				<form>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="NAME*"
								id="clientName"
								value={state.clientName}
								placeholder="Name"
								handleInput={handleChange}
								validations={[required]}
								style={{
									height: 49,
								}}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<CustomDatePicker
								label={<span className="form-group">DATE OF BIRTH</span>}
								id="dateOfBirth"
								selected={state.dateOfBirth}
								handleDateChange={handleDateChange('dateOfBirth')}
								maxDate={new Date()}
								placeholderText="Select Date of Birth"
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<PhoneNumberInput
								id="clientPhone"
								label="PHONE NUMBER*"
								value={state.clientPhone}
								placeholder="Enter 10 Digit Mobile Number"
								handleInput={handleNumberChange}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="STREET ADDRESS*"
								id="clientAddress"
								value={state.clientAddress}
								placeholder="Address"
								handleInput={handleChange}
								validations={[required]}
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={'form-group col-12'}>
							<GeoDropdown
								states={states}
								cities={
									state.clientState ? cities[state.clientState.value] : []
								}
								zipcodes={
									state.clientCity ? zipcodes[state.clientCity.value] : []
								}
								handleChange={handleGeoChange}
								state={state.clientState}
								city={state.clientCity}
								zipcode={state.clientZipcode}
								isRequired
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}></div>
					</div>
					<div className="d-flex p-3 pt-1 flex-column form-group mb-0">
						<div>
							<p className="mx-auto">
								I,
								<input
									id="clientAuthName"
									name="clientAuthName"
									className="border"
									value={state.clientAuthName}
									onChange={handleChange}
									placeholder="Enter Authorizer Name"
								/>
								, authorize Mind Therapy Clinic, provider of my psychiatric
								care, to
							</p>
						</div>
						<div
							className={`${
								!isMobile && 'd-flex'
							} align-items-center justify-content-center`}
						>
							<div
								className={`form-group ${
									isMobile ? 'row mx-0' : 'col-4'
								} d-flex align-items-center`}
							>
								<div className="form-group col-6 border border-right-0 p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkSend"
											onChange={handleCheckBox}
											name="sendChecked"
											checked={state.sendChecked}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="checkSend"
										>
											Send
										</label>
									</div>
								</div>
								<div className="form-group col-6 border p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkRecieve"
											onChange={handleCheckBox}
											name="receiveChecked"
											checked={state.receiveChecked}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="checkRecieve"
										>
											Receive
										</label>
									</div>
								</div>
							</div>
							<p className={`font-16 ${isMobile && 'row mx-0'}`}> my record</p>
							<div
								className={`form-group ${
									isMobile ? 'row mx-0' : 'col-4'
								} d-flex align-items-center`}
							>
								<div className="form-group col-6 border border-right-0 p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkTo"
											onChange={handleCheckBox}
											name="sendChecked"
											checked={state.sendChecked}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="checkTo"
										>
											To
										</label>
									</div>
								</div>
								<div className="form-group col-6 border p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkFrom"
											onChange={handleCheckBox}
											name="receiveChecked"
											checked={state.receiveChecked}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="checkFrom"
										>
											From
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="NAME*"
								id="entityName"
								value={state.entityName}
								placeholder="Name"
								handleInput={handleChange}
								validations={[required]}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="ENTITY*"
								id="entityType"
								value={state.entityType}
								placeholder="Entity"
								handleInput={handleChange}
								validations={[required]}
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<PhoneNumberInput
								id="entityPhone"
								label="PHONE NO*"
								value={state.entityPhone}
								placeholder="Enter 10 Digit Phone Number"
								handleInput={handleNumberChange}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<PhoneNumberInput
								label="FAX"
								id="entityFax"
								value={state.entityFax}
								placeholder="Fax"
								handleInput={handleNumberChange}
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="STREET ADDRESS*"
								id="entityAddress"
								value={state.entityAddress}
								placeholder="Street Address"
								handleInput={handleChange}
								validations={[required]}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="ZIP CODE"
								id="entityZipcode"
								value={state.entityZipcode}
								placeholder="Zip Code"
								handleInput={handleChange}
								validations={[numbersOnly, maxlen(5)]}
								maxLength={5}
							/>
						</div>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<TextInput
								label="EMAIL"
								id="entityEmail"
								value={state.entityEmail}
								placeholder="Entity Email"
								handleInput={handleChange}
								validations={[required]}
							/>
						</div>
					</div>
				</form>
				<div className="px-3 pt-3">
					<p className="custom-relofInfo-text text-dark font-15">
						Please release my records to Mind Therapy Clinic at 240 Tamal Vista
						Blvd., Suite 160, Corte Madera, CA 94925.
					</p>
				</div>
			</div>
			<div className={`${containerClass} border-top`} style={containerStyle}>
				<div className="d-flex px-3">
					<h6 className="font-23 text-dark font-weight-bold d-flex">
						Information To Be Released
						<p className="align-top">*</p>
					</h6>
				</div>
				<div className="px-3 py-2">
					<div className="form-group d-flex align-items-center mb-0">
						<div className="form-group col-4 border border-right-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkEvaluation"
									onChange={handleCheckBox}
									name="evalChecked"
									checked={state.evalChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkEvaluation"
								>
									Evaluation
								</label>
							</div>
						</div>
						<div className="form-group col-4 border border-right-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkLabResults"
									onChange={handleCheckBox}
									name="labResChecked"
									checked={state.labResChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkLabResults"
								>
									Lab Results
								</label>
							</div>
						</div>
						<div className="form-group col-4 border p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkProgress"
									onChange={handleCheckBox}
									name="progRepChecked"
									checked={state.progRepChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkProgress"
								>
									Progress Reports
								</label>
							</div>
						</div>
					</div>
					<div className="form-group d-flex align-items-center mb-0">
						<div className="form-group col-4 border border-right-0 border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkMedication"
									onChange={handleCheckBox}
									name="medInfoChecked"
									checked={state.medInfoChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkMedication"
								>
									Medication Info
								</label>
							</div>
						</div>
						<div className="form-group col-4 border border-right-0 border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkTestResults"
									onChange={handleCheckBox}
									name="testResChecked"
									checked={state.testResChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkTestResults"
								>
									Testing Results
								</label>
							</div>
						</div>
						<div className="form-group col-4 border border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkDiagonsis"
									onChange={handleCheckBox}
									name="diagnChecked"
									checked={state.diagnChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkDiagonsis"
								>
									Diagnosis
								</label>
							</div>
						</div>
					</div>
					<div className="form-group d-flex align-items-center mb-0">
						<div className="form-group col-4 border border-right-0 border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkSummary"
									onChange={handleCheckBox}
									name="sumRepChecked"
									checked={state.sumRepChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkSummary"
								>
									Summary Reports
								</label>
							</div>
						</div>
						<div className="form-group col-4 border border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkEntire"
									onChange={handleCheckBox}
									name="entRecChecked"
									checked={state.entRecChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkEntire"
								>
									Entire Record
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex px-3 pt-2">
					<h6 className="font-16 text-dark font-weight-bold custom-relofInfo-text">
						The above information will be used for the following purposes only*:
					</h6>
				</div>
				<div className="px-3 py-2">
					<div className="form-group d-flex align-items-center mb-0">
						<div className="form-group col-4 border border-right-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkPlanning"
									onChange={handleCheckBox}
									name="planTreatChecked"
									checked={state.planTreatChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkPlanning"
								>
									Planning Treatment
								</label>
							</div>
						</div>
						<div className="form-group col-4 border border-right-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkContinuing"
									onChange={handleCheckBox}
									name="contTreatChecked"
									checked={state.contTreatChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkContinuing"
								>
									Continuing Treatment
								</label>
							</div>
						</div>
						<div className="form-group col-4 border p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkCaseReview"
									onChange={handleCheckBox}
									name="caseRevChecked"
									checked={state.caseRevChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkCaseReview"
								>
									Case Review
								</label>
							</div>
						</div>
					</div>
					<div className="form-group d-flex align-items-center mb-0">
						<div className="form-group col-4 border border-top-0 p-3 mb-0">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkFiles"
									onChange={handleCheckBox}
									name="updateFileChecked"
									checked={state.updateFileChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkFiles"
								>
									Updating Files
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex px-3 py-4">
					<p className="font-16 text-dark custom-relofInfo-text">
						I understand that this information may be protected by Title 42
						(CFR, Parts 160 and 164) and Title 45 (Federal Rules of
						Confidentiality of Alcohol and Drug Abuse Patient Records, Chapter
						1, Part 2), plus applicable state laws. I further understand the
						information disclosed to the recipient may not be protected under
						these guidelines if they are not a health care provider covered by
						the state or federal rules. I understand that this authorization is
						voluntary, and I may revoke this consent at any time by providing
						written notice. This release will expire
						<strong> one year from the date of signature</strong>. I have been
						informed what information will be given, its purpose, and who will
						receive the information.{' '}
						<strong>
							I understand that I have a right to receive a copy of this
							authorization
						</strong>
						. I understand that I have a right to refuse to sign this
						authorization.
					</p>
				</div>
				<div className={inputGroupName}>
					<div className="row mx-0">
						<div className="col-3 d-flex align-items-start">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkClientAccept"
									onChange={handleChange}
									name="authClientType"
									value="patient"
									checked={state.authClientType === 'patient'}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkClientAccept"
								>
									Patient
								</label>
							</div>
						</div>
						<div className="col-3 d-flex align-items-start">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkGuardianAccept"
									onChange={handleChange}
									name="authClientType"
									value="guardian"
									checked={state.authClientType === 'guardian'}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkGuardianAccept"
								>
									Parent or Legal Guardian
								</label>
							</div>
						</div>
						<div className="col-3 d-flex align-items-start">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkSpouseAccept"
									onChange={handleChange}
									name="authClientType"
									value="spouse"
									checked={state.authClientType === 'spouse'}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkSpouseAccept"
								>
									Spouse or Person Financially Responsible for Patient
								</label>
							</div>
						</div>
						<div className="col-3 d-flex align-items-start">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkBeneficiaryAccept"
									onChange={handleChange}
									name="authClientType"
									value="beneficiary"
									checked={state.authClientType === 'beneficiary'}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkBeneficiaryAccept"
								>
									Beneficiary or Person Representing Deceased Patient
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className={'col-12 d-flex justify-content-end'}>
					<IAccept
						isRequired
						handleAcceptance={handleClick}
						name="roiChecked"
						value={state.roiChecked}
					/>
				</div>
			</div>
			<div className={containerClass} style={containerStyle}>
				<form>
					<div className="col-12 flex-column form-group">
						<label
							htmlFor="ifNotInfo"
							className="font-16 text-dark custom-relofInfo-text font-weight-bold"
						>
							*If your information to be released and/or purposes for the
							information to be released are not listed as one of the selection
							choices on this form, please add it here.
						</label>
						<textarea
							className="form-control font-17 custom-no-radius"
							id="ifNotInfo"
							rows="1"
							placeholder="Information To Be Released"
							name="roiPurpose"
							value={state.roiPurpose}
							onChange={handleChange}
						/>
					</div>
				</form>
			</div>
			<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
				<button
					onClick={() => handleSubmit()}
					disabled={!isFormComplete()}
					className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
				>
					<span className="mr-0 pl-3 w-100">SUBMIT</span>
					<i className="fas fa-check align-self-center" />
				</button>
			</div>
		</div>
	);
};

export default ROIRequest;
