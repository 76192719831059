/*
 *
 * ClinicianRegister constants
 *
 */

export const DEFAULT_ACTION = 'src/ClinicianRegister/DEFAULT_ACTION';
export const CLINICIAN_REGISTRATION =
	'src/ClinicianRegister/CLINICIAN_REGISTRATION';
export const SUCCESS = 'src/ClinicianRegister/SUCCESS';
export const ERROR = 'src/ClinicianRegister/ERROR';
export const CLINICIAN_LICENSED = 'CLINICIAN_LICENSED';
export const CLINICIAN_PRELICENSED = 'CLINICIAN_PRELICENSED';
export const CLINICIAN_NOLICENSED = 'CLINICIAN_NOLICENSED';
export const SUCCESS_CHECK_ONBOARDING_CLINICIAN =
	'src/ClinicianRegister/SUCCESS_CHECK_ONBOARDING_CLINICIAN';
export const FETCH_DATA = 'src/ClinicianRegister/FETCH_DATA';
export const FETCH_STATES = 'src/ClinicianRegister/FETCH_STATES';
export const FETCH_STATES_SUCCESS =
	'src/ClinicianRegister/FETCH_STATES_SUCCESS';
export const FETCH_STATES_ERROR = 'src/ClinicianRegister/FETCH_STATES_ERROR';
export const UPLOAD_CREDENTIAL = 'src/ClinicianRegister/UPLOAD_CREDENTIAL';
export const UPLOAD_CREDENTIAL_SUCCESS =
	'src/ClinicianRegister/UPLOAD_CREDENTIAL_SUCCESS';
export const UPLOAD_CREDENTIAL_ERROR =
	'src/ClinicianRegister/UPLOAD_CREDENTIAL_ERROR';
