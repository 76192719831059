import { call, put, takeLatest } from 'redux-saga/effects';

import { forgotUpdatePasswordMsg } from './actions';
import { forgotUpdatePasswordApi } from './api';
import { FORGOT_UPDATE_PASSWORD } from './constants';

export function* performForgotUpdatePassword(action) {
	try {
		const result = yield call(forgotUpdatePasswordApi, action.data);
		yield put(forgotUpdatePasswordMsg(result));
	} catch (err) {
		yield put(forgotUpdatePasswordMsg({ msg: 'Something Went Wrong' }));
	}
}

export default function* userForgotPasswordSaga() {
	yield takeLatest(FORGOT_UPDATE_PASSWORD, performForgotUpdatePassword);
}
