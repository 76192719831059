import history from 'utils/history';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { generateToast } from 'containers/App/actions';
import { makeSelectLocation } from 'containers/App/selectors';
import { getRole } from 'utils/fetchRole';

import {
	doSignUp,
	error,
	phoneValidation,
	removeError,
	sendOtp,
	showError,
	successVerified,
	toggleVerification,
} from './actions';
import {
	checkUserExistAPI,
	sendOtpAPI,
	signUpAPI,
	validateOtpAPI,
} from './api';
import {
	CHECK_USER_EXIST,
	DO_SIGN_UP,
	SEND_OTP,
	SHOW_ERROR,
	VALIDATE_OTP,
} from './constants';
import makeSelectSignUp from './selectors';

// Individual exports for testing
export function* performCheckUserExist() {
	const signUp = yield select(makeSelectSignUp());
	try {
		const response = yield call(checkUserExistAPI, {
			email: signUp.email,
			phone: signUp.phone,
		});
		if (!response.data.user_exists) {
			if (signUp.toggleOtp) {
				yield put(sendOtp());
			} else {
				yield put(doSignUp());
			}
		} else {
			yield put(showError(response.data.message));
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performSendOtp() {
	const signUp = yield select(makeSelectSignUp());

	try {
		const response = yield call(sendOtpAPI, {
			number: signUp.phone,
		});

		if (response.status === 200) {
			if (!signUp.showVerificationModal) {
				yield put(toggleVerification());
			}
			yield put(phoneValidation(true));
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performValidateOtp() {
	const signUp = yield select(makeSelectSignUp());
	try {
		const response = yield call(validateOtpAPI, {
			number: signUp.phone,
			code: signUp.code,
		});
		yield put(successVerified());

		if (response.status === 200) {
			yield put(doSignUp());
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performSignUp() {
	// Select inputs
	const signUp = yield select(makeSelectSignUp());
	const location = yield select(makeSelectLocation());

	const role = location.pathname.split('/');
	const roleId = getRole(role[1]);

	try {
		const response = yield call(signUpAPI, {
			name: signUp.name,
			email: signUp.email,
			password: signUp.password,
			role: roleId,
			phone: signUp.phone,
		});

		if (response.status === 200) {
			yield put(generateToast('Registered Successfully'));
			history.push(`/${role[1]}/signin`);
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performShowError() {
	try {
		yield put(removeError(null));
	} catch (err) {
		yield put(error(err));
	}
}

export default function* signUpSaga() {
	yield takeLatest(CHECK_USER_EXIST, performCheckUserExist);
	yield takeLatest(SEND_OTP, performSendOtp);
	yield takeLatest(VALIDATE_OTP, performValidateOtp);
	yield takeLatest(DO_SIGN_UP, performSignUp);
	yield takeLatest(SHOW_ERROR, performShowError);
}
