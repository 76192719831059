import PropTypes from 'prop-types';

import React, { useState } from 'react';

/**
 *
 * <CustTextInput />, a functional component for CustTextInput
 */
const CustTextInput = (props) => {
	const {
		label,
		placeholder,
		handleInput,
		className,
		id,
		value,
		validations,
		type = 'text',
		readOnly,
		maxLength,
		minLength,
		style,
	} = props;

	const [error, setError] = useState(null);

	const modifiedClass = `${className} form-group`;

	const handleChange = (event) => {
		if (readOnly) return;
		const { target } = event;
		const { value } = target;

		let err = '';
		validations.forEach((validation) => {
			err = validation(value);
		});

		setError(err);
		handleInput(event);
	};

	const inputStyle = style || {};
	if (error) inputStyle.border = '1px solid red';

	return (
		<div className={modifiedClass}>
			<label htmlFor={id} className="form-font">
				{label}
			</label>
			<input
				max={maxLength}
				min={minLength}
				value={value}
				type={type}
				name={id}
				id={id}
				className="form-control text-muted"
				placeholder={placeholder}
				onChange={handleChange}
				readOnly={readOnly}
				style={inputStyle}
			/>
			{error}
		</div>
	);
};

CustTextInput.propTypes = {
	handleInput: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	validations: PropTypes.array,
	type: PropTypes.string,
	readOnly: PropTypes.bool,
	maxLength: PropTypes.any,
	minLength: PropTypes.any,
};

CustTextInput.defaultProps = {
	type: 'text',
};

export default CustTextInput;
