import history from 'utils/history';

import { capitalize, startCase } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import ActionButton from 'atoms/ActionButton';
import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import TextCell from 'atoms/TableCell/TextCell';
import ClientCard from 'cards/ClientCard';
import ClinicianCard from 'cards/ClinicianCard';
import FormShare from 'components/FormShare';
import StatusModal from 'components/RequestSentModal';
import EditModal from 'components/UpdateClinician';
import { headerStyle } from 'containers/App/constants';
import deleteImage from 'svgs/delete';
import editImage from 'svgs/edit';

/**
 *
 * ClientAndClinicianTableAdmin
 *
 */

/* eslint-disable react/prefer-stateless-function */
export class ClientAndClinicianTableAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showChangeClinician: false,
			patientId: '',
			toBeDeletedClinician: '',
			showDeleteModal: false,
			showEditModal: false,
			toBeEditedClinician: '',
			numToShow: 10,
			client: {},
			clinicianId: null,
		};
	}

	handleDelete = (id) => {
		if (this.state.showDeleteModal) {
			const { deleteClinician } = this.props;
			const data = { clinicianId: id };
			deleteClinician(data);
		}
	};

	changeClinician = (clinician) => {
		const { changeClinician } = this.props;
		const { patientId } = this.state;
		const data = { patientId, clinicianId: clinician.id };

		changeClinician(data);

		this.setState((prevState) => ({
			showChangeClinician: !prevState.showChangeClinician,
		}));
	};

	showClinicianList = (client, clinicianId) => {
		this.setState((prevState) => ({
			showChangeClinician: !prevState.showChangeClinician,
			patientId: client.id,
			client,
			clinicianId,
		}));
	};

	handleEdit = (data) => {
		if (this.state.showEditModal) {
			const { editClinician } = this.props;
			editClinician(data);
		}
	};

	showDeleteModal = (id) => {
		if (!this.state.toBeDeletedClinician) {
			this.setState((prevState) => ({
				showDeleteModal: !prevState.showDeleteModal,
				toBeDeletedClinician: id,
			}));
		} else {
			this.setState((prevState) => ({
				showDeleteModal: !prevState.showDeleteModal,
				toBeDeletedClinician: '',
			}));
		}
	};

	getClinicianInfo = (id) => {
		const { getClinician } = this.props;
		getClinician(id);
	};

	showEditModal = (id) => {
		if (!this.state.toBeEditedClinician) {
			this.setState({
				showEditModal: true,
				toBeEditedClinician: id,
			});
			this.getClinicianInfo(id);
		} else {
			this.setState({
				showEditModal: false,
				toBeEditedClinician: '',
			});
		}
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	// eslint-disable-next-line consistent-return
	getColumns = () => {
		const { tableType, downloadFile, type } = this.props;

		let columns;
		if (tableType === 'client') {
			columns = [
				{
					field: 'client',
					title: 'NAME',
					headerStyle,
					render: (row) => {
						return (
							row.client && (
								<div
									className="hand"
									onClick={() => this.goToProfile(row.client.patientId)}
								>
									<ImageDetailsCell
										src={row.client.profilePicture || ''}
										heading={row.client.name}
										primarySubHeading={startCase(row.client.type)}
										secondarySubHeading={formatPhoneNumber(row.client.phone)}
									/>
								</div>
							)
						);
					},
				},
				{
					field: 'age',
					title: 'AGE',
					headerStyle,
					render: (row) => (
						<div className="mt-2 font-13" cursor="pointer">
							{row.age}
						</div>
					),
				},
				{
					field: 'gender',
					title: 'GENDER',
					headerStyle,
					render: (row) => (
						<div className="mt-2 font-13" cursor="pointer">
							{capitalize(row.gender)}
						</div>
					),
				},
				{
					field: 'maritalStatus',
					title: 'MARITAL STATUS',
					headerStyle,
					render: (row) => (
						<div className="mt-2 font-13" cursor="pointer">
							{startCase(row.maritalStatus)}
						</div>
					),
				},
				{
					field: 'referrer',
					title: 'REFERRER',
					headerStyle,
					render: (row) =>
						row.referrer?.referrerName ? (
							<div className="mt-2">
								<TextCell
									title={row.referrer.referrerName}
									primaryHeading={capitalize(row.referrer.referrerType)}
								/>
							</div>
						) : (
							<div>-</div>
						),
				},
				{
					field: 'docs',
					title: 'INTAKE DOCUMENT',
					headerStyle,
					render: (row) =>
						row && row.docs.length && row.docs[0].uuid ? (
							<div
								className="d-flex hand  link-color align-items-center mt-2 mx-0 font-12"
								onClick={() => {
									for (const doc of row.docs) {
										downloadFile(doc.uuid);
									}
								}}
							>
								DOWNLOAD {row.docs.length} DOC{row.docs.length > 1 && 'S'}
							</div>
						) : row.docs.client && row.client.onBoardingComplete ? (
							<div className="d-flex text-muted align-items-center mt-2 mx-0 font-12">
								DOCUMENT UNAVAILABLE
							</div>
						) : (
							<div className="d-flex text-muted align-items-center mt-2 mx-0 font-12">
								INTAKE INCOMPLETE
							</div>
						),
				},
				{
					field: 'clinicianDetails',
					title: 'LEAD CLINICIAN',
					headerStyle: { ...headerStyle, marginRight: '20px' },
					render: (row) => (
						<div>
							{row.clinicianDetails && row.clinicianDetails.name && (
								<div>
									<ImageDetailsCell
										src={row.clinicianDetails.profilePicture}
										heading={row.clinicianDetails.name}
										primarySubHeading={row.clinicianDetails.therapistType}
									/>
								</div>
							)}
							<div
								className="font-10 mt-2 link-color hand text-uppercase"
								onClick={() =>
									this.showClinicianList(
										{ name: row.client.name, id: row.patientId },
										row.clinicianId,
									)
								}
							>
								{type === 'admin' &&
									(row.clinicianDetails.name ? 'CHANGE' : 'ASSIGN')}
							</div>
						</div>
					),
					sorting: false,
				},
				{
					field: 'programs',
					title: 'GROUPS',
					headerStyle,
					render: (row) => (
						<div className="mt-2 font-13" cursor="pointer">
							{row.programs}
						</div>
					),
				},
			];
		} else if (tableType === 'clinician') {
			// We are VIEWING clinicians
			columns = [
				{
					field: 'userInfo',
					title: 'CLINICIAN',
					headerStyle,
					render: (row) => {
						return (
							row.userInfo && (
								<div>
									<ImageDetailsCell
										src={row.userInfo.profileUrl || ''}
										heading={row.userInfo.name}
										primarySubHeading={row.therapistType}
									/>
								</div>
							)
						);
					},
				},
				{
					field: 'licenseDetails',
					title: 'LICENSE EXPIRATION',
					headerStyle,
					render: (row) => {
						return row.licenseDetails ? (
							<div className="mt-2 font-13" cursor="pointer">
								{row.licenseDetails.licenseExpirationMonth} -{' '}
								{row.licenseDetails.licenseExpirationYear}
							</div>
						) : (
							<div className="mt-2 font-13" cursor="pointer">
								-
							</div>
						);
					},
				},
				{
					field: 'licenseDetails',
					title: 'SUPERVISOR',
					headerStyle,
					render: (row) => {
						return row.licenseDetails ? (
							<div className="mt-2 font-13" cursor="pointer">
								{row.licenseDetails.supervisorName}
							</div>
						) : (
							<div className="mt-2 font-13" cursor="pointer">
								-
							</div>
						);
					},
				},
				{
					field: 'actions',
					title: 'ACTIONS',
					headerStyle: {
						...headerStyle,
						textAlign: 'right',
					},
					render: (row) => (
						<div className="row justify-content-end mr-2">
							<div className="col-auto px-0">
								<ActionButton
									image={editImage}
									onClick={() => this.showEditModal(row.id)}
									alt="Edit"
								/>
							</div>
							<div className="col-auto px-0">
								<ActionButton
									image={deleteImage}
									hoverColor={'var(--main-alert)'}
									onClick={() => this.showDeleteModal(row.id)}
									alt="Delete"
								/>
							</div>
						</div>
					),
				},
			];
		}

		return columns;
	};

	showMore = () => {
		this.setState({
			numToShow: this.state.numToShow + 10,
		});
	};

	render() {
		const {
			data,
			states,
			clinicianInfo,
			clinicians,
			loading,
			isMobile,
			downloadFile,
			backToTop,
			tableType,
		} = this.props;
		const { showChangeClinician, numToShow, client, clinicianId } = this.state;
		const columns = this.getColumns();

		const mobileData = data.slice(0, numToShow);
		let loadMore = false;
		if (numToShow < data.length) loadMore = true;

		const rowStyle = (rowData) => {
			if (!rowData.isActive) {
				return {
					backgroundColor: 'rgb(255 231 231)',
				};
			}
		};

		return (
			<div style={{ overflowX: 'hidden' }}>
				{isMobile ? (
					<div>
						<div ref={this.scrollRef}></div>
						{mobileData.map((c) =>
							tableType === 'client' ? (
								<ClientCard
									key={c.id}
									data={c}
									changeClinician={this.showClinicianList}
									downloadFile={downloadFile}
									loading={loading}
								/>
							) : (
								<ClinicianCard
									key={c.id}
									data={c}
									changeClinician={this.showClinicianList}
									downloadFile={downloadFile}
									loading={loading}
								/>
							),
						)}
						{loadMore && (
							<div
								className="row mx-0 justify-content-center link-color font-20 mb-4 hand"
								onClick={this.showMore}
							>
								Show More...
							</div>
						)}
						{mobileData.length > 5 && (
							<div
								className="row mx-0 justify-content-center link-color font-20 mb-4 hand"
								onClick={backToTop}
							>
								Back to Top
							</div>
						)}
					</div>
				) : (
					<Table
						keyField="myClients"
						data={data}
						columns={columns}
						placeholder="No clients to display"
						loading={loading}
						rowStyle={rowStyle}
					/>
				)}
				{this.state.showDeleteModal && (
					<StatusModal
						mode="confirmation"
						successStatus={false}
						hide={this.showDeleteModal}
						handleDelete={this.handleDelete}
						message="Are you sure?"
						id={this.state.toBeDeletedClinician}
					/>
				)}
				{this.state.showEditModal && (
					<EditModal
						states={states}
						hide={this.showEditModal}
						handleEdit={this.handleEdit}
						clinicianId={this.state.toBeEditedClinician}
						clinicianInfo={clinicianInfo}
					/>
				)}

				{showChangeClinician && (
					<FormShare
						clinicians={clinicians}
						type="assignClinician"
						title={`Assign ${client.name} to...`}
						hide={this.showClinicianList}
						assignClinician={this.changeClinician}
						currentClinicianId={clinicianId}
					/>
				)}
			</div>
		);
	}
}

ClientAndClinicianTableAdmin.propTypes = {
	tableType: PropTypes.string.isRequired,
	data: PropTypes.array,
	deleteClinician: PropTypes.func,
	editClinician: PropTypes.func,
	getClinician: PropTypes.func,
	downloadFile: PropTypes.func,
	states: PropTypes.array,
	clinicianInfo: PropTypes.object,
	clinicians: PropTypes.array,
	changeClinician: PropTypes.func,
};

export default ClientAndClinicianTableAdmin;
