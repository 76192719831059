import PropTypes from 'prop-types';

import React from 'react';

/* eslint-disable indent */

/**
 *
 * FeedbackModal
 *
 */
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import FeedbackView from 'components/FeedbackView';

// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
const FeedbackModal = (props) => {
	const { hide, isOpen, feedback, submit } = props;

	const closeModal = () => {
		hide();
	};

	feedback.anonymous = feedback.sender.id ? 'no' : 'yes';

	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal-dialog-centered"
				backdrop="static"
			>
				<ModalHeader toggle={closeModal} className="border-0">
					Client Feedback {feedback.sender.name && ` - ${feedback.sender.name}`}
				</ModalHeader>
				<ModalBody toggle={closeModal} className="container-fluid pt-0">
					<FeedbackView
						feedback={feedback}
						mode={'view'}
						hide={hide}
						submit={submit}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
};

FeedbackModal.propTypes = {
	hide: PropTypes.func,
	feedback: PropTypes.object,
	update: PropTypes.func,
	isOpen: PropTypes.bool,
};

export default FeedbackModal;
