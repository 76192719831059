import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import CustomButton from 'atoms/CustomButton';
import DeleteButton from 'atoms/DeleteButton';
import useIsMobile from 'hooks/isMobile.ts';
import { filterByLetter } from 'utils/helpers';

import './index.css';

const IconContainer = styled.div`
	path {
		fill: ${(props) => (props.isActive ? 'var(--main-color)' : '#c8c8c8')};
	}
`;

const DropdownItem = styled.div`
	padding: 8px 12px;
	background: ${(props) => (props.isActive ? 'var(--main-color)' : 'white')};
	color: ${(props) => (props.isActive ? 'white' : 'black')};

	&:hover {
		background: var(--main-color-hover);
		color: white;
		cursor: pointer;
	}
`;

const defaultClasses = {
	container: '',
	searchBox: '',
	icon: '',
};

const defaultStyle = {
	container: {},
	searchBox: {},
	icon: {},
};

const SearchBar = (props) => {
	const {
		onSearch,
		classNames = defaultClasses,
		style = defaultStyle,
		dropdownList,
		value = '',
		placeholder = 'Search for Client',
		keyboardControls = true,
		autoSearch,
		filter = filterByLetter,
	} = props;
	const { container, searchBox, icon } = style;

	// Search is ambiguous - we want to be searching on the label
	// Search should always be the same type as the label (a string)
	// We want to return the entire object that has been "selected or clicked" and set the search appropriately

	const [keyboardIndex, setKeyboardIndex] = useState(-1);
	const [search, setSearch] = useState(value);
	const [isSearching, setIsSearching] = useState(false);

	const dropdownRef = useRef();
	const inputRef = useRef();

	const isMobile = useIsMobile();

	let filteredDropdownList = [];

	if (dropdownList) {
		filteredDropdownList = filter(dropdownList, 'label', search);

		if (keyboardIndex >= filteredDropdownList.length) {
			setKeyboardIndex(filteredDropdownList.length - 1);
		}
	}

	const handleChange = (e) => {
		setSearch(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (!isSearching) return;

		if (e.key === 'Enter') {
			if (keyboardControls && keyboardIndex > -1) {
				handleClick(filteredDropdownList[keyboardIndex].value);
			} else {
				handleSearch();
			}
		}

		if (keyboardControls) {
			if (e.key === 'ArrowDown') {
				if (
					dropdownList &&
					keyboardIndex < Math.min(10, filteredDropdownList.length)
				) {
					setKeyboardIndex(keyboardIndex + 1);
				}
			} else if (e.key === 'ArrowUp') {
				if (dropdownList && keyboardIndex > -1) {
					setKeyboardIndex(keyboardIndex - 1);
				}
			}
		}
	};

	const clearSearch = () => {
		setSearch('');
		onSearch('');
	};

	const handleSearch = () => {
		onSearch(search);
		setIsSearching(false);
		setKeyboardIndex(-1);
	};

	const handleClick = (item) => {
		setSearch(item.label);
		onSearch(item.value);
		setIsSearching(false);
		setKeyboardIndex(-1);
	};

	const doSearch = useCallback(() => {
		if (isMobile || autoSearch) {
			handleSearch();
		}
	}, [isMobile, autoSearch, handleSearch]);

	useEffect(doSearch, [search]);

	useEffect(() => {
		const handleMouseDown = (e) => {
			if (
				!dropdownRef.current?.contains(e.target) &&
				!inputRef.current?.contains(e.target)
			) {
				setIsSearching(false);
			}
		};

		window.addEventListener('mousedown', handleMouseDown);

		return () => window.removeEventListener('mousedown', handleMouseDown);
	}, []);

	const maxWidth = 400;

	return (
		<div
			className={`${
				isMobile ? 'container-fluid' : 'row'
			} bg-white px-4 border-top ${classNames.container}`}
			style={{
				position: 'relative',
				boxShadow: '0 5px 5px 0 rgb(0 0 0 / 5%)',
				...container,
			}}
			ref={inputRef}
		>
			<div
				className="row w-100 custom-search border-0"
				style={{ height: 52, ...searchBox }}
			>
				<IconContainer
					className="col-auto d-flex justify-content-end ali hand"
					onClick={handleSearch}
					isActive={isSearching || search}
				>
					<i
						className="fas fa-search custom-search-text font-18"
						style={{
							position: 'absolute',
							top: '50%',
							left: 10,
							transform: 'translate(0%, -50%)',
							...icon,
						}}
					/>
				</IconContainer>
				<input
					placeholder={placeholder}
					className="col font-18"
					style={{
						maxWidth,
					}}
					type="text"
					value={search}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onFocus={() => setIsSearching(true)}
				/>
				{!isSearching && search && (
					<DeleteButton
						onClick={clearSearch}
						popupText="Clear Search"
						styles={{
							popup: {
								container: {
									background: 'var(--main-light-grey)',
									color: 'white',
									width: 140,
								},
								innerTri: {
									borderTop: '5px solid var(--main-light-grey)',
								},
							},
						}}
					/>
				)}
			</div>
			{isSearching && !isMobile && dropdownList && (
				<div
					className="bg-white search-dropdown"
					style={{
						position: 'absolute',
						top: '100%',
						left: 44,
						zIndex: 100,
						width: maxWidth + 40,
					}}
					ref={dropdownRef}
				>
					{filteredDropdownList.slice(0, 10).map((item, i) => (
						<DropdownItem
							key={item.value}
							onClick={() => handleClick(item)}
							isActive={keyboardIndex === i}
						>
							{item.label}
						</DropdownItem>
					))}
					{filteredDropdownList.length === 0 ? (
						<div className="font-20 font-italic text-center pb-2 text-muted">
							No Results
						</div>
					) : (
						dropdownList.length > 10 && (
							<div className="font-12 w-100 text-center pb-2 justify-content-center align-items-center">
								<div className="d-flex justify-content-center mb-3">
									<CustomButton
										type="secondary"
										title="Search"
										onClick={handleSearch}
										className="py-3 px-4 font-14"
									/>
								</div>
								Showing {Math.min(filteredDropdownList.length, 10)} of{' '}
								{filteredDropdownList.length}
							</div>
						)
					)}
				</div>
			)}
		</div>
	);
};

SearchBar.propTypes = {
	onSearch: PropTypes.func,
	classNames: PropTypes.object,
	style: PropTypes.object,
	dropdownList: PropTypes.array,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	autoSearch: PropTypes.bool,
	filter: PropTypes.func,
};

export default SearchBar;
