import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * DownloadReportModal
 *
 */
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import SelectAutoComplete from 'atoms/SelectAutoComplete';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class DownloadReportModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			selectedMonth: null,
			selectedYear: null,
		};
	}

	closeModal = () => {
		const { hide, handleDownloadReport } = this.props;
		handleDownloadReport();
		hide();
	};

	handleMonthChange = (selectedMonth) => {
		const { handleMonthChange } = this.props;
		this.setState({ selectedMonth });
		handleMonthChange(selectedMonth);
	};

	handleYearChange = (selectedYear) => {
		const { handleYearChange } = this.props;
		this.setState({ selectedYear });
		handleYearChange(selectedYear);
	};

	render() {
		const { modal, selectedMonth, selectedYear } = this.state;
		const currYear = parseInt(moment().utc().format('YYYY'), 10);
		return (
			<div>
				<Modal
					isOpen={modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.closeModal} className="border-0" />
					<ModalBody toggle={this.closeModal} className="container pt-0">
						<div className="row">
							<div className="col-6">
								<SelectAutoComplete
									handleChange={this.handleMonthChange}
									options={[
										{ value: 1, label: 'Jan' },
										{ value: 2, label: 'Feb' },
										{ value: 3, label: 'Mar' },
										{ value: 4, label: 'Apr' },
										{ value: 5, label: 'May' },
										{ value: 6, label: 'Jun' },
										{ value: 7, label: 'Jul' },
										{ value: 8, label: 'Aug' },
										{ value: 9, label: 'Sep' },
										{ value: 10, label: 'Oct' },
										{ value: 11, label: 'Nov' },
										{ value: 12, label: 'Dec' },
									]}
									placeholder="Select Month"
									indicatorClass="fas fa-sort dropdown-indicator text-primary"
									optionStyles={{ fontSize: '14px' }}
									singleValueStyles={{
										color: 'var(--main-color)',
										fontSize: '14px',
									}}
								/>
							</div>
							<div className="col-6">
								<SelectAutoComplete
									handleChange={this.handleYearChange}
									options={[
										{ value: currYear, label: currYear },
										{ value: currYear - 1, label: currYear - 1 },
										{ value: currYear - 2, label: currYear - 2 },
									]}
									placeholder="Select Year"
									indicatorClass="fas fa-sort dropdown-indicator text-primary"
									optionStyles={{ fontSize: '14px' }}
									singleValueStyles={{
										color: 'var(--main-color)',
										fontSize: '14px',
									}}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-around align-items-center pt-4 px-4 pb-2">
							{selectedMonth && selectedYear ? (
								<button
									onClick={this.closeModal}
									type="button"
									className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-5"
								>
									Okay
								</button>
							) : (
								<button
									disabled
									type="button"
									className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-5"
								>
									Okay
								</button>
							)}
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

DownloadReportModal.propTypes = {
	hide: PropTypes.func,
	handleYearChange: PropTypes.func,
	handleMonthChange: PropTypes.func,
	handleDownloadReport: PropTypes.func,
};

export default DownloadReportModal;
