import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';


interface Props {
    prevProgress: () => void,
    nextProgress: () => () => void,
    isMobile: boolean,
}

export default function KetaminePsychotherapy(props: Props): JSX.Element {
    const { prevProgress, nextProgress, isMobile } = props;

    const handleNext = () => {
        nextProgress()();
    };

    return (
        <div
            className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
        >
            <div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
                <div className="pt-1 pb-4">
                    <h4>Ketamine-Assisted Psychotherapy:</h4>
                    <span>
                        Combining ketamine with psychotherapy can yield a greater benefit
                        than biological ketamine treatments alone. The non-ordinary
                        consciousness states of the ketamine experience provide more
                        material to work with in therapy, especially in the context of a
                        long-term psychotherapeutic relationship. In this implementation
                        model, ketamine is dosed in an in-office setting, using either
                        oral lozenges or intramuscular injection. This location and
                        approach maximize the comfort of the patient, rather than the
                        placement of an IV line. By enhancing physical comfort. creating a
                        soothing environment, the therapeutic effect of treatment is
                        maximized. This treatment model is provided after the evaluation
                        of patient response to medication, psychotherapy, and the
                        appropriateness of others treatment modalities - ie. for
                        depression; ECT, TMS. Patient has a right to refuse this treatment
                        at any point during the course. The expected outcome from
                        treatment may vary from nonresponse to a robust sustained
                        response. There are possible adverse effects potentially
                        associated with the treatment. Ongoing benefit from treatment may
                        require ongoing dosing. A single or subsequent dosing of ketamine
                        does not automatically imply ongoing provision of this treatment
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Medical clearance for treatment:</h4>
                    <span>
                        Prior to administration of ketamine, a patient will need an
                        evaluation of overall health, in particular respiratory and
                        cardiovascular status. Medication optimization of blood pressure
                        is needed based on the sympathetic activation effects of ketamine.
                        An EKG may be required in instances where there has been
                        arrhythmia or a history of cardiovascular issues. Patients with a
                        history of cystitis or other bladder issues may need to be cleared
                        by urological consultation, noting the rare but potentially
                        significant adverse effect of cystitis. Blood pressure monitoring
                        at baseline and on an ongoing basis will be required as part of
                        treatment.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Adverse and side effect associated with treatment:</h4>
                    <span>
                        Ketamine increases sympathetic tone in the vasculature and can
                        raise blood pressure, which has associated risks with adverse
                        outcomes linked to stroke and arrhythmias, resulting in loss of
                        function and possibly death. Ketamine has limited suppression of
                        respiratory drive, however it is rarely reported to cause
                        laryngospasm, particularly in pediatric populations. Ketamine has
                        been associated with cystitis, a painful and potentially
                        irreversible bladder condition. Cystitis has been generally
                        reported in higher doses and more frequent uses, particularly in
                        substance abusing populations. Ketamine has a risk of abuse and
                        tolerance; escalated use with adverse outcomes. It generally has
                        low reinforcement properties (ie. stimulants) and no physiological
                        withdrawal syndrome (ie. opiates, benzodiazepines,). Therefore, it
                        is atypical for patients to crave use and demonstrate behaviors to
                        obtain it. Some patients exhibit tolerance (needing higher doses
                        for the same effect). At anesthetic doses 1-3 mg/kg dosed by IM or
                        IV, the following side effects are commonly reported and may occur
                        in lower dose delivery - ie. oral dosing of 100-200mg, but are
                        less likely.
                    </span>
                    <p className="pt-4">
                        Transient - up to 4 hours:
                    </p>
                    <ul>
                        <li>Dizziness</li>
                        <li>Blurred vision</li>
                        <li>Headache</li>
                        <li>Nausea, vomiting</li>
                        <li>Dry mouth</li>
                        <li>Restlessness</li>
                        <li>Impaired coordination</li>
                        <li>Impaired concentration</li>
                    </ul>
                    <br />
                    <span>
                        An emergence phenomena, in approximately 10-20% of cases, has been
                        reported in which a patient may experience subjective distress
                        with psychological or physical restlessness. In these instances,
                        treatment with low dose anxiolytic medication has been beneficial.
                        There are also rare psychological and psychiatric risks associated
                        with treatment, notable switching into mania for bipolar patients,
                        who may not yet be diagnosed as such. While rarely described, it
                        is possible that sustained perceptual disturbances, alternations
                        in cognition, reality testing or subjective distress stemming from
                        treatment may persist beyond that acute treatment.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Management of Adverse Effects:</h4>
                    <span>
                        Treating providers are trained in management of cardiovascular
                        events and airway access as defined by the ACLS treatment
                        protocol. Intervention may include provision of anti-hypertensive
                        medication, performing CPR, using an AED (defibrillator) and
                        interventions to manage the airway; administration of tongue
                        blade, bag-masking and oral airway devices. In the event of
                        psychological distress, treating provider may deliver of
                        anxiolytic medication (ie. Ativan / Lorazepam) or antipsychotic
                        medication (ie. Haldol / Haloperidol or Zyprexa / Olanzapine) in
                        oral or intramuscular formulation. The treating provider reserves
                        the right to activate emergency response systems, ie. call 911, if
                        it is determined by clinical judgment that patient safety requires
                        a higher level of care than can be provided in the office.
                    </span>
                </div>
            </div>

            <div className="d-flex mt-4 mb-5 justify-content-center">
                <div className="col-6">
                    <PrevButton onClick={prevProgress} isMobile={isMobile} />
                </div>
                <div className="col-6">
                    <NextButton onClick={handleNext} isMobile={isMobile} />
                </div>
            </div>
        </div>
    );
}
