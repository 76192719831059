import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the signIn state domain
 */

const selectSignInDomain = (state) => state.get('signIn', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by SignIn
 */

const makeSelectSignIn = () =>
	createSelector(selectSignInDomain, (signInState) =>
		returnJS(signInState.get('loginData')),
	);

const makeSelectForgotPassword = () =>
	createSelector(selectSignInDomain, (signInState) =>
		returnJS(signInState.get('forgotPasswordMessage')),
	);

const makeSelectError = () =>
	createSelector(selectSignInDomain, (signInState) =>
		returnJS(signInState.get('error')),
	);

const makeSelectForgotPassToggle = () =>
	createSelector(selectSignInDomain, (signInState) =>
		returnJS(signInState.get('showForgotPassModal')),
	);

export default selectSignInDomain;
export {
	makeSelectSignIn,
	makeSelectError,
	makeSelectForgotPassToggle,
	makeSelectForgotPassword,
};
