import PropTypes from 'prop-types';

import React from 'react';
import Select from 'react-select';

import './index.css';

/**
 *
 * <SelectAutoComplete />, a functional component for SelectAutoComplete
 */
const SelectAutoComplete = (props) => {
	const {
		handleChange,
		options,
		value,
		placeholder,
		indicatorClass,
		controlStyles,
		optionStyles,
		singleValueStyles,
		label,
		customLabel,
		multiple,
		disabled,
		...rest
	} = props;

	const DropdownIndicator = ({ innerProps }) => (
		<div {...innerProps}>
			<i className={indicatorClass} />
		</div>
	);
	const IndicatorSeparator = () => null;

	const generateOptionStyles = (base, props) => {
		const { isFocused, isSelected, data } = props;
		const { disabled } = data;

		return {
			...base,
			...optionStyles,
			background: isSelected
				? 'var(--main-color)'
				: isFocused
				? 'var(--main-color-light)'
				: 'white',
			cursor: isFocused && !disabled ? 'pointer' : 'default',
			color: isSelected ? 'white' : disabled ? 'grey' : 'black',
		};
	};

	return (
		<div>
			{label && <div className="form-font mb-2">{label}</div>}
			{customLabel}
			<Select
				isDisabled={disabled}
				value={value}
				onChange={handleChange}
				options={options}
				isMulti={multiple}
				placeholder={placeholder}
				styles={{
					control: (base) => ({ ...base, ...controlStyles, borderRadius: 0 }),
					option: (base, values) => generateOptionStyles(base, values),
					singleValue: (base) => ({ ...base, ...singleValueStyles }),
					menu: (base) => ({ ...base, zIndex: 500 }),
				}}
				components={{ DropdownIndicator, IndicatorSeparator }}
				{...rest}
			/>
		</div>
	);
};

SelectAutoComplete.propTypes = {
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	indicatorClass: PropTypes.string,
	controlStyles: PropTypes.object,
	optionStyles: PropTypes.object,
	singleValueStyles: PropTypes.object,
	multiple: PropTypes.bool,
};

SelectAutoComplete.defaultProps = {
	indicatorClass: 'fas fa-sort fa-xs dropdown-indicator',
};

export default SelectAutoComplete;
