import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the userDropDown state domain
 */

const selectSignNowDomain = (state) => state.get('signnow', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserDropDown
 */

const makeSelectUrl = () =>
	createSelector(selectSignNowDomain, (substate) =>
		returnJS(substate.get('url')),
	);

const makeSelectError = () =>
	createSelector(selectSignNowDomain, (substate) =>
		returnJS(substate.get('error')),
	);

const makeSelectDocs = () =>
	createSelector(selectSignNowDomain, (substate) =>
		returnJS(substate.get('docs')),
	);

const makeSelectFetchingDocs = () =>
	createSelector(selectSignNowDomain, (substate) =>
		returnJS(substate.get('fetchingDocs')),
	);

const makeSelectDocsError = () =>
	createSelector(selectSignNowDomain, (substate) =>
		returnJS(substate.get('docsError')),
	);

export default selectSignNowDomain;
export {
	makeSelectUrl,
	makeSelectError,
	makeSelectDocs,
	makeSelectFetchingDocs,
	makeSelectDocsError,
};
