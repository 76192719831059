import { chunk } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import Checkbox from 'atoms/Checkbox';
import CustomDatePicker from 'atoms/CustomDatePicker';
import Pill from 'atoms/Pill';
import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { STEPS } from 'pages/intake/constants';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * AdultMedical
 *
 */

const medicalConditionChunks = 3;

/* eslint-disable react/prefer-stateless-function */
const AdultMedical = (props) => {
	const {
		data,
		nextProgress,
		prevProgress,
		handleChange,
		handleCheck,
		handleDateChange,
		isMobile,
	} = props;

	const {
		currentMedicationList,
		dosageForMedicationList,
		pastMedication,
		medicineDiscontinueReason,
		prescriberNameLocation,
		relativesOnMedication,
		relativeMedicationList,
		relativesMedicationHelpful,
		concernAboutHealth,
		describeHealthConcerns,
		bloodWorkDone,
		anythingAbnormal,
		medicalConditions,
		recentPhysicalDate,
	} = data;
	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">Medical</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultMedical)}>
				<div className="card d-flex flex-column p-4 bb-0">
					<h4 className="font-weight-bold">Medications:</h4>
					<h6 className="mb-3">
						<strong>IMPORTANT:</strong> Please list any prescription &
						non-prescription medications, vitamins, supplements or herbs;
						include name, dose & how often taken and see appendix for list of
						medications.
					</h6>
					<TextArea
						className="mb-3"
						label="LIST ALL CURRENT MEDICATION NAME(S):"
						id="currentMedicationList"
						value={currentMedicationList}
						placeholder=" e.g. Alprazolam"
						handleInput={handleChange(STEPS.AdultMedical)}
					/>
					<TextArea
						className="mb-3"
						label="LIST THE DOSAGE AND HOW OFTEN TAKEN FOR EACH MEDICATION LISTED
                ABOVE:"
						id="dosageForMedicationList"
						value={dosageForMedicationList}
						placeholder=" e.g. 1.5 mg/day , 15-20 mg/day"
						handleInput={handleChange(STEPS.AdultMedical)}
					/>
					<TextArea
						className="mb-3"
						label="LIST ALL PAST MEDICATION(S)"
						id="pastMedication"
						value={pastMedication}
						placeholder="e.g. Alprazolam"
						handleInput={handleChange(STEPS.AdultMedical)}
					/>
					<TextArea
						className="mb-3"
						label="WHY DID YOU DISCONTINUE TAKING EACH MEDICATION LISTED ABOVE:"
						id="medicineDiscontinueReason"
						value={medicineDiscontinueReason}
						placeholder="Describe"
						handleInput={handleChange(STEPS.AdultMedical)}
					/>
					<TextArea
						className="mb-3"
						label="WHO HAS BEEN PRESCRIBING YOUR MEDICATIONS (PLEASE INCLUDE THE
                  NAME AND LOCATION OF THE PRESCRIBER)?"
						id="prescriberNameLocation"
						value={prescriberNameLocation}
						placeholder="Name and Location"
						handleInput={handleChange(STEPS.AdultMedical)}
					/>

					<div className="row mt-3 mb-3">
						<label
							htmlFor="relativesOnMedication"
							className={`form-font col-${isMobile ? 12 : 8}`}
						>
							ARE ANY OF YOUR RELATIVES ON MEDICATIONS ?
						</label>
						<SelectButtonGroup
							id="relativesOnMedication"
							value={relativesOnMedication}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedical)}
							className={isMobile ? 'row mx-3' : 'col-4'}
						/>
					</div>

					{relativesOnMedication === 'yes' && (
						<TextInput
							className="mb-3"
							label="IF YES. PLEASE LIST THE MEDICATIONS."
							id="relativeMedicationList"
							value={relativeMedicationList}
							placeholder="Acetaminophen, Adderall, Alprazolam"
							handleInput={handleChange(STEPS.AdultMedical)}
						/>
					)}

					{relativesOnMedication === 'yes' && (
						<div className="row mt-3 mb-3">
							<label
								htmlFor="relativesMedicationHelpful"
								className={`form-font col-${isMobile ? 12 : 8}`}
							>
								WERE THEY HELPFUL ?
							</label>
							<SelectButtonGroup
								id="relativesMedicationHelpful"
								value={relativesMedicationHelpful}
								buttons={generateObjectArray(['Yes', 'No'])}
								handleSelect={handleChange(STEPS.AdultMedical)}
								className={isMobile ? 'row mx-3' : 'col-4'}
							/>
						</div>
					)}

					<label htmlFor="medicalConditions">
						Do you have any of the following medical conditions? (check all that
						apply)
					</label>
					{isMobile ? (
						<div className="mt-2 mb-5">
							{medicalConditions.map((condition) => (
								<Pill
									selected={condition.isSelected}
									key={condition.id}
									text={condition.name}
									onClick={() =>
										handleCheck(
											STEPS.AdultMedical,
											'medicalConditions',
											condition.id,
										)
									}
								/>
							))}
						</div>
					) : (
						<div className="d-flex flex-column border-top border-left mt-2 mb-5">
							{chunk(medicalConditions, medicalConditionChunks).map((row) => (
								<div className="d-flex p-0" key={`medicalConditions-${row}`}>
									{row.map((medicalCondition) => (
										<Checkbox
											key={medicalCondition.id}
											className="col-4 border-right border-bottom"
											item={medicalCondition}
											checkboxGroup="medicalConditions"
											parent="adultMedical"
											handleCheck={handleCheck}
										/>
									))}
								</div>
							))}
						</div>
					)}

					<div className="form-row mb-3">
						<CustomDatePicker
							className="col-md-6 "
							label="WHEN WAS YOUR MOST RECENT PHYSICAL?"
							id="recentPhysicalDate"
							selected={recentPhysicalDate}
							handleDateChange={handleDateChange(
								STEPS.AdultMedical,
								'recentPhysicalDate',
							)}
							placeholderText="Select Date"
							maxDate={new Date()}
						/>
						<div className="col-md-6">
							<label htmlFor="concernAboutHealth" className="form-font">
								DID YOU OR YOUR DOCTOR HAVE ANY CONCERNS ABOUT YOUR HEALTH ?
							</label>
							<SelectButtonGroup
								id="concernAboutHealth"
								value={concernAboutHealth}
								buttons={generateObjectArray(['Yes', 'No'])}
								handleSelect={handleChange(STEPS.AdultMedical)}
							/>
						</div>
					</div>
					{concernAboutHealth === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES PLEASE DESCRIBE"
							id="describeHealthConcerns"
							value={describeHealthConcerns}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultMedical)}
						/>
					)}

					<div className="row mb-3">
						<label htmlFor="bloodWorkDone" className="form-font col-md-8">
							DID YOU HAVE ANY BLOOD WORK DONE (i.e. THYROID TESTING) ?
						</label>
						<SelectButtonGroup
							id="bloodWorkDone"
							value={bloodWorkDone}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedical)}
							className="col-md-4"
						/>
					</div>
					{bloodWorkDone === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES, WAS ANYTHING ABNORMAL?"
							id="anythingAbnormal"
							value={anythingAbnormal}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultMedical)}
						/>
					)}
				</div>
				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

AdultMedical.propTypes = {
	data: PropTypes.object.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
	handleDateChange: PropTypes.func.isRequired,
};

export default AdultMedical;
