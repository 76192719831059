import { call, put, takeLatest } from 'redux-saga/effects';

import { enrollmentRequestAPI, signDocAPI } from 'utils/api';

import {
	signDocumentError,
	signDocumentSuccess,
	submitEnrollmentRequestError,
	submitEnrollmentRequestSuccess,
} from './actions';
import { SIGN_DOCUMENT, SUBMIT_ENROLLMENT_REQUESTS } from './constants';

// Individual exports for testing
export default function* enrollmentRequestsSaga() {
	yield takeLatest(SUBMIT_ENROLLMENT_REQUESTS, submitEnrollmentRequests);
	yield takeLatest(SIGN_DOCUMENT, signDocument);
}

export function* submitEnrollmentRequests(action) {
	try {
		const response = yield call(enrollmentRequestAPI, action.data);
		yield put(submitEnrollmentRequestSuccess(response));
	} catch (err) {
		yield put(submitEnrollmentRequestError(err));
	}
}

export function* signDocument(action) {
	try {
		const response = yield call(signDocAPI, action.id);
		yield put(signDocumentSuccess(response));
	} catch (err) {
		yield put(signDocumentError(err));
	}
}
