import { startCase } from 'lodash';

import moment from 'moment';

import React, { useEffect, useState } from 'react';
import Form from 'react-validation/build/form';

import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import TextInput from 'atoms/TextInput';
import GeoDropdown from 'components/GeoDropdown';
import { required } from 'utils/validations';

import { generateAllOptions, generateSelectOption } from './selectOptions';

const PersonalInfo = (props) => {
	const {
		data,
		states,
		cities,
		zipcodes,
		fetchCities,
		fetchZipcodes,
		dispatch,
	} = props;
	const {
		name,
		dob,
		startDate,
		dischargeDate,
		gender,
		maritalStatus,
		email,
		phone,
		address = { city: '', state: '', street: '', zipcode: '' },
		clientType = '',
		insurance = {},
		referrer = {},
		isActive,
	} = data;
	const { primaryInsurance } = insurance || {};
	const [addressError, setAddressError] = useState({});

	useEffect(() => {
		if (address.state) {
			fetchCities(address.state);
		}
	}, []);

	useEffect(() => {
		const errorText = 'Field is required.';
		setAddressError({
			state: address.state ? '' : errorText,
			city: address.city ? '' : errorText,
		});
	}, [address.state, address.city]);

	const handleChange = (e) => {
		let { target } = e;

		if (target.name === 'street') {
			target = {
				name: 'address',
				value: {
					...address,
					street: target.value,
				},
			};
		} else if (target.name.includes('referrer')) {
			let name = target.name.includes('Name') ? 'name' : 'type';
			target = {
				name: 'referrer',
				value: {
					...referrer,
					[name]: target.value,
				},
			};
		} else if (target.name === 'primaryInsurance') {
			target = {
				name: 'insurance',
				value: {
					...insurance,
					primaryInsurance: target.value,
				},
			};
		}

		dispatch(target);
	};

	const handleDateChange = (id) => (date) => {
		dispatch({ name: id, value: moment(date).format('YYYY-MM-DD') });
	};

	const handleNumberChange = (id, values) => {
		dispatch({ name: id, value: values.value });
	};

	const handleSelect = (id) => (option) => {
		if (id === 'isActive') {
			option.value = option.value === 'active' ? true : false;
		}
		dispatch({ name: id, value: option.value });
	};

	const handleGeoChange = (type) => (input) => {
		if (!input?.value) return;

		const newAddress = address;

		if (type === 'state') {
			newAddress.state = input.value;
			newAddress.city = null;
			newAddress.zipcode = null;
			fetchCities(input.value);
		} else if (type === 'city') {
			newAddress.city = input.value;
			newAddress.zipcode = null;
			fetchZipcodes(address.city);
		} else {
			newAddress.zipcode = input.value;
		}

		dispatch({ name: 'address', value: newAddress });
	};

	const inputHeight = window.innerWidth < 1200 ? 46 : 49;

	return (
		<Form>
			<div className="row">
				<div className="col-8">
					<TextInput
						label="CLIENT NAME*"
						id="name"
						value={name}
						placeholder="John Lucan Doe"
						handleInput={handleChange}
						validations={[required]}
						style={{
							height: inputHeight,
						}}
					/>
				</div>
				<div className="col-4">
					<CustomDatePicker
						label={<span className="form-group">DATE OF BIRTH</span>}
						id="dob"
						maxDate={new Date()}
						selected={dob}
						handleDateChange={handleDateChange('dob')}
						placeholderText="Select Date of Birth"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-8">
					<TextInput
						label="CLIENT EMAIL*"
						name="email"
						value={email}
						onChange={handleChange}
						style={{
							height: inputHeight,
						}}
						validations={[required]}
					/>
				</div>
				<div className="col-4">
					<PhoneNumberInput
						label="PHONE NUMBER*"
						id="phone"
						value={phone}
						placeholder="Enter 10 Digit Phone Number"
						handleInput={handleNumberChange}
						style={{
							height: inputHeight,
						}}
						validations={[required]}
					/>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-6">
					<label className="form-font" htmlFor="gender">
						GENDER*
					</label>
					<SelectAutoComplete
						value={generateSelectOption('gender', gender)}
						name="gender"
						handleChange={handleSelect('gender')}
						options={generateAllOptions('gender')}
					/>
				</div>
				<div className="col-6">
					<label className="form-font" htmlFor="maritalStatus">
						MARITAL STATUS
					</label>
					<SelectAutoComplete
						value={generateSelectOption('maritalStatus', maritalStatus)}
						name="maritalStatus"
						handleChange={handleSelect('maritalStatus')}
						options={generateAllOptions('maritalStatus')}
					/>
				</div>
			</div>
			<div>
				<div>
					<TextInput
						label="STREET ADDRESS*"
						id="street"
						value={address.street || ''}
						placeholder="Enter Street Address"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div>
					<GeoDropdown
						states={states}
						cities={address.state ? cities[address.state] : []}
						zipcodes={address.city ? zipcodes[address.city] : []}
						handleChange={handleGeoChange}
						state={states.find((s) => s.value === address.state)}
						city={{ label: address.city, value: address.city }}
						zipcode={{ label: address.zipcode, value: address.zipcode }}
						error={addressError}
						isRequired={['city', 'state']}
					/>
				</div>
			</div>
			<div className="form-row mt-3">
				<div className="form-group col">
					<label className="form-font" htmlFor="clientType">
						CLIENT TYPE*
					</label>
					<SelectAutoComplete
						value={generateSelectOption('clientType', clientType)}
						name="clientType"
						handleChange={handleSelect('clientType')}
						options={generateAllOptions('clientType')}
						controlStyles={{ height: inputHeight }}
						disabled
					/>
				</div>
				<div className="form-group col">
					<label className="form-font" htmlFor="clientType">
						STATUS*
					</label>
					<SelectAutoComplete
						value={generateSelectOption(
							'isActive',
							isActive ? 'active' : 'inactive',
						)}
						name="isActive"
						handleChange={handleSelect('isActive')}
						options={generateAllOptions('isActive')}
						controlStyles={{ height: inputHeight }}
					/>
				</div>
			</div>
			{clientType.includes('IOP') && (
				<div className="form-row">
					<div className="form-group col">
						<CustomDatePicker
							disabled={!clientType.includes('IOP')}
							label={<span className="form-group">START DATE</span>}
							id="startDate"
							selected={startDate}
							handleDateChange={handleDateChange('startDate')}
							placeholderText="Select Date of Birth"
						/>
					</div>
					<div className="form-group col">
						<CustomDatePicker
							disabled={!clientType.includes('IOP')}
							label={<span className="form-group">DISCHARGE DATE</span>}
							id="dischargeDate"
							minDate={
								startDate
									? moment(startDate).add(1, 'day').format('YYYY-MM-DD')
									: null
							}
							selected={dischargeDate}
							handleDateChange={handleDateChange('dischargeDate')}
							placeholderText="Select Date of Birth"
						/>
					</div>
				</div>
			)}
			<div className="form-row">
				<div className="form-group col">
					<TextInput
						label="PRIMARY INSURANCE"
						id="primaryInsurance"
						value={startCase(primaryInsurance)}
						placeholder="Enter Primary Insurance"
						handleInput={handleChange}
					/>
				</div>
			</div>
			<div className="form-row pb-0">
				<div className="form-group mb-0 col-md-6">
					<TextInput
						label="REFERRER NAME"
						id="referrerName"
						value={startCase(referrer.name)}
						placeholder="Enter Referrer Name"
						handleInput={handleChange}
					/>
				</div>
				<div className="form-group mb-0 col-md-6">
					<TextInput
						label="REFERRER TYPE"
						id="referrerType"
						value={startCase(referrer.type)}
						placeholder="Enter Referrer Type"
						handleInput={handleChange}
					/>
				</div>
			</div>
		</Form>
	);
};

export default PersonalInfo;
