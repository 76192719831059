import { useField } from 'formik';

import React from 'react';

import classnames from 'classnames';

import './Input.css';
import Label from './Label';

const Input = ({ label, required, ...rest }) => {
	const [field, meta] = useField(rest);
	const hasError = meta.touched && meta.error;

	return (
		<div className="form-group custom-input">
			<Label
				label={label}
				required={required}
				id={rest.id}
				hasError={hasError}
			/>
			<input
				className={classnames('form-control', { invalid: hasError })}
				{...field}
				{...rest}
			/>
			{hasError && <div className="form-error">{meta.error}</div>}
		</div>
	);
};

export default Input;
