import { parseISO } from 'date-fns';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import CustomDatePicker from 'atoms/CustomDatePicker';
import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import Pill from 'atoms/Pill';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import { STEPS } from 'pages/intake/constants';
import { checkNested, generateObjectArray } from 'utils/helpers';

/**
 *
 * Questionnaire
 *
 */

const symptoms = [
	{
		id: 0,
		name: 'Depressed mood',
		isSelected: false,
	},
	{
		id: 1,
		name: 'Unable to enjoy activities',
		isSelected: false,
	},
	{
		id: 2,
		name: 'Sleep pattern disturbance',
		isSelected: false,
	},
	{
		id: 3,
		name: 'Loss of interest',
		isSelected: false,
	},
	{
		id: 4,
		name: 'Concentration issues/forgetfulness',
		isSelected: false,
	},
	{
		id: 5,
		name: 'Change in appetite',
		isSelected: false,
	},
	{
		id: 6,
		name: 'Excessive guilt',
		isSelected: false,
	},
	{
		id: 7,
		name: 'Feeling worthless',
		isSelected: false,
	},
	{
		id: 8,
		name: 'Fatigue',
		isSelected: false,
	},
	{
		id: 9,
		name: 'Decreased libido',
		isSelected: false,
	},
	{
		id: 10,
		name: 'Restlessness',
		isSelected: false,
	},
	{
		id: 11,
		name: 'Feeling Slowed down',
		isSelected: false,
	},
	{
		id: 12,
		name: 'Racing thoughts',
		isSelected: false,
	},
	{
		id: 13,
		name: 'Impulsivity',
		isSelected: false,
	},
	{
		id: 14,
		name: 'Increased risky behaviour',
		isSelected: false,
	},
	{
		id: 15,
		name: 'Increased libido',
		isSelected: false,
	},
	{
		id: 16,
		name: 'Decreased need for sleep',
		isSelected: false,
	},
	{
		id: 17,
		name: 'Excessive energy',
		isSelected: false,
	},
	{
		id: 18,
		name: 'Increased irritability',
		isSelected: false,
	},
	{
		id: 19,
		name: 'Crying spells',
		isSelected: false,
	},
	{
		id: 20,
		name: 'Excessive worry',
		isSelected: false,
	},
	{
		id: 21,
		name: 'Anxiety/Panic attack',
		isSelected: false,
	},
	{
		id: 22,
		name: 'Avoidance',
		isSelected: false,
	},
	{
		id: 23,
		name: 'Hallucinations',
		isSelected: false,
	},
	{
		id: 24,
		name: 'Suspiciouness',
		isSelected: false,
	},
	{
		id: 25,
		name: 'Feeling hopeless',
		isSelected: false,
	},
	{
		id: 26,
		name: 'Other',
		isSelected: false,
	},
];

/* eslint-disable react/prefer-stateless-function */
class Questionnaire extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			adultIntakeName: '',
			adultIntakeDob: '',
			adultIntakeAge: '',
			adultIntakeOtherPresent: '',
			adultIntakeMaritalStatus: 'single',
			marriedYear: '',
			patientRelationshipToSubscriber: 'no',
			childrenCount: '',
			childrenAge1: '',
			childrenAge2: '',
			childrenAge3: '',
			childrenAge4: '',
			childrenAge5: '',
			childrenAge6: '',
			childrenAge7: '',
			childrenAge8: '',
			livingSituation: '',
			allergiesToMedication: 'no',
			describeAllergies: '',
			appointmentReason: '',
			symptomsDuration: '',
			symptomsRelatedToLife: 'no',
			explainSymptoms: '',
			symptomsFrequent: 'no',
			symptomsFrequentDescribe: '',
			currentTherapyHelpful: 'no',
			currentTherapyHelpfulDescribe: '',
			symptoms,
			errorChildren: false,
		};
	}

	handleDateChange = (date) => {
		if (date) {
			this.setState({
				adultIntakeDob: parseISO(moment(date).format('YYYY-MM-DD')),
				adultIntakeAge: moment().diff(date, 'years'),
			});
		} else {
			this.setState({
				adultIntakeAge: '',
				adultIntakeDob: '',
			});
		}

		// if (this.state.adultIntakeDob === 'Invalid date') {
		//   this.setState({ adultIntakeAge: 0 });
		// } else
		//   this.setState(prevState => ({
		//     adultIntakeAge: moment().diff(prevState.adultIntakeDob, 'years'),
		//   }));
	};

	changeState(currentProps) {
		if (currentProps.patientIntakeFields.questionnaire) {
			if (currentProps.patientIntakeFields.questionnaire.authorizeOtherMds) {
				this.setState({
					authorizeOtherMds:
						currentProps.patientIntakeFields.questionnaire.authorizeOtherMds,
				});
			}
			if (
				currentProps.patientIntakeFields.questionnaire.adultIntakeMaritalStatus
			) {
				this.setState({
					adultIntakeMaritalStatus:
						currentProps.patientIntakeFields.questionnaire
							.adultIntakeMaritalStatus,
				});
			}
			if (currentProps.patientIntakeFields.questionnaire.adultIntakeDob) {
				this.setState({
					adultIntakeDob:
						currentProps.patientIntakeFields.questionnaire.adultIntakeDob,
				});
			}
			if (currentProps.patientIntakeFields.questionnaire.adultIntakeAge) {
				this.setState({
					adultIntakeAge:
						currentProps.patientIntakeFields.questionnaire.adultIntakeAge,
				});
			}
			if (
				currentProps.patientIntakeFields.questionnaire
					.patientRelationshipToSubscriber
			) {
				this.setState({
					patientRelationshipToSubscriber:
						currentProps.patientIntakeFields.questionnaire
							.patientRelationshipToSubscriber,
				});
			}
			if (
				currentProps.patientIntakeFields.questionnaire.allergiesToMedication
			) {
				this.setState({
					allergiesToMedication:
						currentProps.patientIntakeFields.questionnaire
							.allergiesToMedication,
				});
			}
			if (
				currentProps.patientIntakeFields.questionnaire.symptomsRelatedToLife
			) {
				this.setState({
					symptomsRelatedToLife:
						currentProps.patientIntakeFields.questionnaire
							.symptomsRelatedToLife,
				});
			}
			if (currentProps.patientIntakeFields.questionnaire.symptomsFrequent) {
				this.setState({
					symptomsFrequent:
						currentProps.patientIntakeFields.questionnaire.symptomsFrequent,
				});
			}
			if (
				currentProps.patientIntakeFields.questionnaire.currentTherapyHelpful
			) {
				this.setState({
					currentTherapyHelpful:
						currentProps.patientIntakeFields.questionnaire
							.currentTherapyHelpful,
				});
			}
			if (currentProps.patientIntakeFields.questionnaire.symptoms) {
				this.setState({
					symptoms: currentProps.patientIntakeFields.questionnaire.symptoms,
				});
			}
			if (currentProps.patientIntakeFields.questionnaire.childrenCount) {
				this.setState({
					childrenCount:
						currentProps.patientIntakeFields.questionnaire.childrenCount,
				});
			}
		}

		if (currentProps.patientIntakeFields.url) {
			this.setState((prevState) => ({
				modal: !prevState.modal,
				siteUrl: currentProps.patientIntakeFields.url,
			}));
		}
	}

	componentDidMount() {
		this.changeState(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.changeState(nextProps);
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	toggleSymptoms = (e, index) => {
		symptoms[index].isSelected = !symptoms[index].isSelected;
		this.setState({ symptoms });
	};

	prevProgress = () => {
		const { prevProgress } = this.props;

		prevProgress();
	};

	nextProgress = (event) => {
		const { nextProgress, auth, patientIntakeFields } = this.props;
		const data = {};

		data.id = auth.loggedInUser.id;

		let questionnaire = {};
		if (patientIntakeFields.questionnaire) {
			questionnaire = Object.assign({}, patientIntakeFields.questionnaire);
		}
		data.questionnaire = {
			authorizeOtherMds:
				this.state.authorizeOtherMds || questionnaire.authorizeOtherMds,
			enrollmentDateIOP:
				this.state.enrollmentDateIOP || questionnaire.enrollmentDateIOP,
			adultIntakeName:
				this.state.adultIntakeName || questionnaire.adultIntakeName,
			adultIntakeDob: this.state.adultIntakeDob || questionnaire.adultIntakeDob,
			adultIntakeAge: this.state.adultIntakeAge || questionnaire.adultIntakeAge,
			adultIntakeOtherPresent:
				this.state.adultIntakeOtherPresent ||
				questionnaire.adultIntakeOtherPresent,
			adultIntakeMaritalStatus:
				this.state.adultIntakeMaritalStatus ||
				questionnaire.adultIntakeMaritalStatus,
			marriedYear: this.state.marriedYear || questionnaire.marriedYear,
			patientRelationshipToSubscriber:
				this.state.patientRelationshipToSubscriber ||
				questionnaire.patientRelationshipToSubscriber,
			childrenCount: this.state.childrenCount || questionnaire.childrenCount,
			childrenAge1: this.state.childrenAge1 || questionnaire.childrenAge1,
			childrenAge2: this.state.childrenAge2 || questionnaire.childrenAge2,
			childrenAge3: this.state.childrenAge3 || questionnaire.childrenAge3,
			childrenAge4: this.state.childrenAge4 || questionnaire.childrenAge4,
			childrenAge5: this.state.childrenAge5 || questionnaire.childrenAge5,
			childrenAge6: this.state.childrenAge6 || questionnaire.childrenAge6,
			childrenAge7: this.state.childrenAge7 || questionnaire.childrenAge7,
			childrenAge8: this.state.childrenAge8 || questionnaire.childrenAge8,
			livingSituation:
				this.state.livingSituation || questionnaire.livingSituation,
			allergiesToMedication:
				this.state.allergiesToMedication || questionnaire.allergiesToMedication,
			describeAllergies:
				this.state.describeAllergies || questionnaire.describeAllergies,
			appointmentReason:
				this.state.appointmentReason || questionnaire.appointmentReason,
			symptomsDuration:
				this.state.symptomsDuration || questionnaire.symptomsDuration,
			symptomsRelatedToLife:
				this.state.symptomsRelatedToLife || questionnaire.symptomsRelatedToLife,
			explainSymptoms:
				this.state.explainSymptoms || questionnaire.explainSymptoms,
			symptomsFrequent:
				this.state.symptomsFrequent || questionnaire.symptomsFrequent,
			symptomsFrequentDescribe:
				this.state.symptomsFrequentDescribe ||
				questionnaire.symptomsFrequentDescribe,
			currentTherapyHelpful:
				this.state.currentTherapyHelpful || questionnaire.currentTherapyHelpful,
			currentTherapyHelpfulDescribe:
				this.state.currentTherapyHelpfulDescribe ||
				questionnaire.currentTherapyHelpfulDescribe,
			symptoms: this.state.symptoms || questionnaire.symptoms,
		};

		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();

		nextProgress(data)(event);
	};

	render() {
		const { patientIntakeFields, isMobile } = this.props;

		const symptomRows = this.state.symptoms.length / 3;
		const symptomExtra = this.state.symptoms.length % 3;
		const updatedSymptoms = [];

		let i = 0;
		let row = 0;

		for (row; row < symptomRows; row += 1) {
			updatedSymptoms[row] = this.state.symptoms.slice(i, i + 3);
			i += 3;
		}

		if (symptomExtra > 0) {
			updatedSymptoms[row] = this.state.symptoms.slice(i, i + symptomExtra);
		}

		const children = [];
		let index = 1;

		for (index; index <= this.state.childrenCount; index += 1) {
			if (this.state.childrenCount <= 8) {
				children.push(
					<div className="col-md-3 form-group">
						<div className="d-flex">
							<input
								defaultValue={
									this.state[`childrenAge${index}`] ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										`childrenAge${index}`,
									) ||
									''
								}
								type="number"
								name={`childrenAge${index}`}
								id={`childrenAge${index}`}
								placeholder={`For Child ${index}`}
								className="form-control text-muted"
								onChange={this.handleChange}
							/>
						</div>
					</div>,
				);
			}
		}

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mb-5 mt-5">
					Intake Questionnaire
				</h2>
				<div className="card d-flex flex-column p-4 bb-0">
					<h3>Adult Intake Evaluation</h3>
					<div className="form-row mt-3">
						<div className="col-md-3 form-group">
							<label htmlFor="adultIntakeName" className="form-font">
								NAME
							</label>
							<input
								defaultValue={
									this.state.adultIntakeName ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'adultIntakeName',
									) ||
									''
								}
								type="text"
								name="adultIntakeName"
								id="adultIntakeName"
								className="form-control text-muted"
								placeholder="Name"
								onChange={this.handleChange}
							/>
						</div>
						<div className="col-md-3 form-group">
							<CustomDatePicker
								className={`${!isMobile && 'col-md-12'}`}
								label={<span className="form-group">DATE OF BIRTH</span>}
								id="adultIntakeDob"
								maxDate={new Date()}
								selected={this.state.adultIntakeDob}
								handleDateChange={this.handleDateChange}
								placeholderText="Select Date"
							/>
						</div>
						<div className="col-md-3 form-group">
							<label htmlFor="adultIntakeAge" className="form-font">
								AGE
							</label>
							<input
								value={
									this.state.adultIntakeAge === null
										? ''
										: this.state.adultIntakeAge
								}
								type="text"
								name="adultIntakeAge"
								id="adultIntakeAge"
								className="form-control text-muted"
								placeholder="Age"
								onChange={this.handleChange}
							/>
						</div>
						<div className="col-md-3 form-group">
							<label htmlFor="adultIntakeOtherPresent" className="form-font">
								OTHERS PRESENT
							</label>
							<input
								defaultValue={
									this.state.adultIntakeOtherPresent ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'adultIntakeOtherPresent',
									) ||
									''
								}
								type="text"
								name="adultIntakeOtherPresent"
								id="adultIntakeOtherPresent"
								className="form-control text-muted"
								placeholder="Others Present"
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<label htmlFor="adultIntakeMaritalStatus" className="form-font">
								MARITAL STATUS
							</label>
							<SelectButtonGroup
								id="adultIntakeMaritalStatus"
								value={this.state.adultIntakeMaritalStatus}
								buttons={generateObjectArray([
									'Single',
									'Married',
									'Divorced',
									'Separated',
									'Widow',
									'Partner',
								])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
					</div>
					<div className="form-row mt-3 mb-3">
						<div className="col-md-6">
							<label htmlFor="marriedYear" className="form-font">
								HOW LONG HAVE YOU BEEN WITH YOUR PARTNER/MARRIED ?
							</label>
							<input
								defaultValue={
									this.state.marriedYear ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'marriedYear',
									) ||
									''
								}
								type="text"
								name="marriedYear"
								id="marriedYear"
								className="form-control text-muted"
								placeholder="Years"
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<label
								htmlFor="patientRelationshipToSubscriber"
								className="form-font"
							>
								DO YOU HAVE CHILDREN ?
							</label>
							<SelectButtonGroup
								id="patientRelationshipToSubscriber"
								value={this.state.patientRelationshipToSubscriber}
								buttons={generateObjectArray(['Yes', 'No'])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
						{this.state.patientRelationshipToSubscriber === 'yes' && (
							<div className="col-md-6 form-group">
								<label htmlFor="childrenCount" className="form-font">
									HOW MANY?
								</label>
								<input
									defaultValue={
										this.state.childrenCount ||
										checkNested(
											patientIntakeFields,
											STEPS.Questionnaire,
											'childrenCount',
										) ||
										''
									}
									type="text"
									name="childrenCount"
									id="childrenCount"
									className={`form-control text-muted ${
										this.state.errorChildren ? 'is-invalid' : null
									}`}
									placeholder="Number of Children"
									onChange={this.handleChange}
								/>
								{this.state.errorChildren && (
									<div className="invalid-feedback">
										Please enter maximum of 8.
									</div>
								)}
							</div>
						)}
					</div>
					{!this.state.errorChildren && this.state.childrenCount !== '' && (
						<div className="mt-1 ml-2">
							<label htmlFor="childrenAges" className="form-font">
								THEIR AGES
							</label>
						</div>
					)}
					<div className="form-row d-flex">
						{this.state.patientRelationshipToSubscriber === 'yes' && children}
					</div>
					<div className="form-group mt-4">
						<label htmlFor="livingSituation" className="form-font">
							CURRENT LIVING SITUATION (RELATIONSHIP OF PERSON(S) WITH WHOM
							PATIENT RESIDES)?
						</label>
						<input
							defaultValue={
								this.state.livingSituation ||
								checkNested(
									patientIntakeFields,
									STEPS.Questionnaire,
									'livingSituation',
								) ||
								''
							}
							type="text"
							name="livingSituation"
							id="livingSituation"
							className="form-control text-muted"
							placeholder="Describe"
							onChange={this.handleChange}
						/>
					</div>
					<div className="form-row">
						<div className="col-md-4">
							<label htmlFor="allergiesToMedication" className="form-font">
								ALLERGIES TO MEDICATION
							</label>
							<SelectButtonGroup
								id="allergiesToMedication"
								value={this.state.allergiesToMedication}
								buttons={generateObjectArray(['Yes', 'No'])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
						{this.state.allergiesToMedication === 'yes' && (
							<div className="col-md-8 form-group">
								<label htmlFor="describeAllergies" className="form-font">
									DESCRIBE YOUR ALLERGIES
								</label>
								<input
									defaultValue={
										this.state.describeAllergies ||
										checkNested(
											patientIntakeFields,
											STEPS.Questionnaire,
											'describeAllergies',
										) ||
										''
									}
									type="text"
									name="describeAllergies"
									id="describeAllergies"
									className="form-control text-muted"
									placeholder="Describe"
									onChange={this.handleChange}
								/>
							</div>
						)}
					</div>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Reason for Appointment</h3>
					<div className="mt-3 form-group">
						<label htmlFor="appointmentReason" className="form-font">
							WHAT ARE THE REASONS YOU SCHEDULED AN APPOINTMENT?
						</label>
						<input
							defaultValue={
								this.state.appointmentReason ||
								checkNested(
									patientIntakeFields,
									STEPS.Questionnaire,
									'appointmentReason',
								) ||
								''
							}
							type="text"
							name="appointmentReason"
							id="appointmentReason"
							className="form-control text-muted"
							placeholder="Describe"
							onChange={this.handleChange}
						/>
					</div>
					<div className="form-row">
						<div className="col-md-6 form-group">
							<label htmlFor="symptomsDuration" className="form-font">
								HOW LONG HAVE YOU HAD THESE SYMPTOMS
							</label>
							<input
								defaultValue={
									this.state.symptomsDuration ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'symptomsDuration',
									) ||
									''
								}
								type="text"
								name="symptomsDuration"
								id="symptomsDuration"
								className="form-control text-muted"
								placeholder="Years"
								onChange={this.handleChange}
							/>
						</div>
						<div className="col-md-6">
							<label htmlFor="symptomsRelatedToLife" className="form-font">
								ARE THESE SYMPTOMS RELATED TO LIFE SITUATION ?
							</label>

							<SelectButtonGroup
								id="symptomsRelatedToLife"
								value={this.state.symptomsRelatedToLife}
								buttons={generateObjectArray(['Yes', 'No'])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
					</div>
					{this.state.symptomsRelatedToLife === 'yes' && (
						<div className="form-group">
							<label htmlFor="explainSymptoms" className="form-font">
								IF YES, PLEASE EXPLAIN
							</label>
							<input
								defaultValue={
									this.state.explainSymptoms ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'explainSymptoms',
									) ||
									''
								}
								type="text"
								name="explainSymptoms"
								id="explainSymptoms"
								className="form-control text-muted"
								placeholder="Describe"
								onChange={this.handleChange}
							/>
						</div>
					)}
					<div className="form-row form-group">
						<div className="col-md-8 mt-2">
							<label htmlFor="symptomsFrequent" className="form-font">
								DO THESE SYMPTOMS SEEM TO COME AND GO REGULARLY, AS IN A CYCLE ?
							</label>
							<SelectButtonGroup
								id="symptomsFrequent"
								value={this.state.symptomsFrequent}
								buttons={generateObjectArray(['Yes', 'No'])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
					</div>
					{this.state.symptomsFrequent === 'yes' && (
						<div className="form-srow form-group">
							<label htmlFor="symptomsFrequentDescribe" className="form-font">
								IF YES, PLEASE DESCRIBE
							</label>
							<textarea
								defaultValue={
									this.state.symptomsFrequentDescribe ||
									checkNested(
										patientIntakeFields,
										STEPS.Questionnaire,
										'symptomsFrequentDescribe',
									) ||
									''
								}
								type="text"
								name="symptomsFrequentDescribe"
								id="symptomsFrequentDescribe"
								className="form-control text-muted"
								placeholder="Describe"
								onChange={this.handleChange}
							/>
						</div>
					)}

					<div className="form-row">
						<div className="col-md-8 form-group">
							<label htmlFor="currentTherapyHelpful" className="form-font">
								DO YOU FEEL THAT YOUR CURRENT OR PAST PSYCHIATRIC CARE/
								PSYCHOTHERAPY HAS BEEN HELPFUL TO YOU ?
							</label>
							<SelectButtonGroup
								id="currentTherapyHelpful"
								value={this.state.currentTherapyHelpful}
								buttons={generateObjectArray(['Yes', 'No'])}
								className={`${isMobile && 'row mx-0'}`}
								handleSelect={this.handleChange}
							/>
						</div>
					</div>
					<div className="form-row form-group">
						<label
							htmlFor="currentTherapyHelpfulDescribe"
							className="form-font"
						>
							WHY OR WHY NOT?
						</label>
						<textarea
							defaultValue={
								this.state.currentTherapyHelpfulDescribe ||
								checkNested(
									patientIntakeFields,
									STEPS.Questionnaire,
									'currentTherapyHelpfulDescribe',
								) ||
								''
							}
							type="text"
							name="currentTherapyHelpfulDescribe"
							id="currentTherapyHelpfulDescribe"
							className="form-control text-muted"
							placeholder="Describe"
							onChange={this.handleChange}
						/>
					</div>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 mb-4 bt-0">
					<h3>Symptoms</h3>
					<span>
						Do you currently, or have you in the past, experienced: (check all
						that apply)
					</span>
					{isMobile ? (
						<div className="mt-4 mb-4">
							{updatedSymptoms.map((value) =>
								value.map((innerValue) => (
									<Pill
										selected={innerValue.isSelected}
										onClick={(e) =>
											this.toggleSymptoms.bind(this, e, innerValue.id)()
										}
										text={innerValue.name}
										key={innerValue.name}
									/>
								)),
							)}
						</div>
					) : (
						<div className="d-flex flex-column border-top border-left mt-4 mb-4">
							{updatedSymptoms.map((value, i) => (
								<div
									className="d-flex p-0 border-bottom"
									key={`symptom-row-${i}`}
								>
									{value.map((innerValue) => (
										<div
											className="col-4 custom-control custom-checkbox border-right"
											key={`${i}-innerValue-${innerValue.id}`}
										>
											<div className="p-3">
												<input
													type="checkbox"
													className="custom-control-input"
													onClick={(e) =>
														this.toggleSymptoms.bind(this, e, innerValue.id)()
													}
													id={innerValue.id}
													checked={innerValue.isSelected}
												/>
												<label
													className="custom-control-label text-cust-grey"
													htmlFor={innerValue.id}
												>
													{innerValue.name}
												</label>
											</div>
										</div>
									))}
								</div>
							))}
						</div>
					)}
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={this.prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton onClick={this.nextProgress} isMobile={isMobile} />
					</div>
				</div>
			</div>
		);
	}
}

Questionnaire.propTypes = {
	patientIntakeFields: PropTypes.object.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

export default Questionnaire;
