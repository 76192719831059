/*
 *
 * Dashboard reducer
 *
 */
import { fromJS } from 'immutable';

import {
	DEFAULT_ACTION,
	DISABLE_SCROLL,
	ENABLE_SCROLL,
	TOGGLE_SCROLL,
} from './constants';

export const initialState = fromJS({
	scrollable: true,
});

function dashboardReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case ENABLE_SCROLL:
			return state.set('scrollable', true);
		case DISABLE_SCROLL:
			return state.set('scrollable', false);
		case TOGGLE_SCROLL:
			return state.set('scrollable', !state.get('scrollable'));
		default:
			return state;
	}
}

export default dashboardReducer;
