// import components

/**
 *
 * ChildOtherIntake
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import { STEPS } from 'pages/intake/constants';

import ChildhoodHistory from './ChildhoodHistory';
import LegalHistory from './LegalHistory';
import SchoolPerformance from './SchoolPerformance';

/* eslint-disable react/prefer-stateless-function */
const ChildOtherIntake = (props) => {
	const { prevProgress, nextProgress, data, handleChange, isUploading } = props;
	const {
		childFamilyLife,
		peopleLivingWithChild,
		grade,
		school,
		schoolPerformance,
		hasIEP,
		hasIEPUnderstanding,
		iepUnderstanding,
		iepFollowed,
		hasLegalHistory,
		legalHistoryDetails,
		presentlyOnProbation,
		probationRequirements,
	} = data;

	return (
		<div className="container-fluid d-flex flex-column px-5">
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Childhood/Cultural History
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.ChildOtherIntake)}>
				<ChildhoodHistory
					data={{ childFamilyLife, peopleLivingWithChild }}
					handleChange={handleChange(STEPS.ChildOtherIntake)}
				/>

				<SchoolPerformance
					data={{
						grade,
						school,
						schoolPerformance,
						hasIEP,
						hasIEPUnderstanding,
						iepUnderstanding,
						iepFollowed,
					}}
					handleChange={handleChange(STEPS.ChildOtherIntake)}
				/>

				<LegalHistory
					data={{
						hasLegalHistory,
						legalHistoryDetails,
						presentlyOnProbation,
						probationRequirements,
					}}
					handleChange={handleChange(STEPS.ChildOtherIntake)}
				/>

				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isBusy={isUploading}
					isLastPage
				/>
			</Form>
		</div>
	);
};

ChildOtherIntake.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	modal: PropTypes.bool.isRequired,
	siteUrl: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default ChildOtherIntake;
