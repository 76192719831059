import { useField } from 'formik';

import PropTypes from 'prop-types';

import React from 'react';
import _Select from 'react-select';

import { noop } from 'utils/common';

import Label from './Label';
import './Select.css';

export default function Select({
	label,
	required,
	options,
	placeholder,
	...rest
}) {
	const [field, meta, helpers] = useField(rest);
	const { setValue } = helpers;
	const hasError = meta.touched && meta.error;

	return (
		<div className="form-group penta-select">
			<Label
				label={label}
				required={required}
				hasError={hasError}
				id={rest.id}
			/>
			<_Select
				{...rest}
				{...field}
				onChange={(option) => {
					const { value } = option;
					setValue(value);
					rest.onChange?.(option);
				}}
				value={options.find(({ value }) => value === field.value)}
				options={options}
				name={field.name}
				placeholder={placeholder}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: 'var(--main-color)',
						primary50: 'var(--main-color-hover)',
					},
				})}
			/>
			{hasError && <div className="form-error">{meta.error}</div>}
		</div>
	);
}

Select.propTypes = {
	label: PropTypes.node,
	required: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		}),
	),
	placeholder: PropTypes.string,
	selected: PropTypes.any,
	handleSelect: PropTypes.func,
};

Select.defaultProps = {
	label: '',
	required: false,
	options: [],
	placeholder: 'Select an option...',
	handleSelect: noop,
};
