/*
 *
 * SignUp actions
 *
 */
import {
	CHECK_USER_EXIST,
	DO_SIGN_UP,
	ERROR,
	PHONE_VALIDATION,
	REMOVE_ERROR,
	SEND_OTP,
	SHOW_ERROR,
	STORE_DATA,
	SUCCESS,
	SUCCESS_VERIFIED,
	TOGGLE_VERIFICATION,
	USER_DOES_NOT_EXIST,
	USER_EXIST,
	VALIDATE_OTP,
} from './constants';

export function toggleVerification() {
	return {
		type: TOGGLE_VERIFICATION,
	};
}

export function storeData(data) {
	return {
		type: STORE_DATA,
		data,
	};
}

export function checkUserExist() {
	return {
		type: CHECK_USER_EXIST,
	};
}
export function userExist(response) {
	return {
		type: USER_EXIST,
		response,
	};
}
export function userNotExist(err) {
	return {
		type: USER_DOES_NOT_EXIST,
		err,
	};
}

export function sendOtp() {
	return {
		type: SEND_OTP,
	};
}

export function phoneValidation(isValidNumber) {
	return {
		type: PHONE_VALIDATION,
		isValidNumber,
	};
}

export function validateOtp(code) {
	return {
		type: VALIDATE_OTP,
		code,
	};
}

export function doSignUp() {
	return {
		type: DO_SIGN_UP,
	};
}

export function success() {
	return {
		type: SUCCESS,
	};
}

export function successVerified() {
	return {
		type: SUCCESS_VERIFIED,
	};
}

export function showError(errorMsg) {
	return {
		type: SHOW_ERROR,
		errorMsg,
	};
}

export function removeError(nullMsg) {
	return {
		type: REMOVE_ERROR,
		nullMsg,
	};
}

export function error() {
	return {
		type: ERROR,
	};
}
