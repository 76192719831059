/*
 *
 * UserDropDown constants
 *
 */

export const DEFAULT_ACTION = 'src/SignIn/DEFAULT_ACTION';
export const TOGGLE_NOTIFICATIONS = 'src/Dashboard/TOGGLE_NOTIFICATIONS';
export const TOGGLE_SETTINGS = 'src/Dashboard/TOGGLE_SETTINGS';
export const TOGGLE_EMAIL = 'src/Dashboard/TOGGLE_EMAIL';
export const TOGGLE_PHONE = 'src/Dashboard/TOGGLE_PHONE';
export const TOGGLE_PASSWORD = 'src/Dashboard/TOGGLE_PASSWORD';
export const VERIFY_PASSWORD = 'src/Dashboard/VERIFY_PASSWORD';
export const UPDATE_NAME = 'src/Dashboard/UPDATE_NAME';
export const UPDATE_EMAIL = 'src/Dashboard/UPDATE_EMAIL';
export const UPDATE_PHONE = 'src/Dashboard/UPDATE_PHONE';
export const UPDATE_PASSWORD = 'src/Dashboard/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_OTHERS = 'src/Dashboard/UPDATE_PASSWORD_OTHERS';
export const LIST_USERS = 'src/Dashboard/LIST_USERS';
export const UPDATE_USERS = 'src/Dashboard/UPDATE_USERS';
export const UPDATE_AUTH = 'src/Dashboard/UPDATE_AUTH';
export const PASSWORD_VERIFIED = 'src/Dashboard/PASSWORD_VERIFIED';
export const LOGOUT = 'src/Dashboard/LOGOUT';
export const PASSWORD_ERROR = 'src/Dashboard/PASSWORD_ERROR';
export const REMOVE_PASSWORD_ERROR = 'src/Dashboard/REMOVE_PASSWORD_ERROR';
export const ERROR = 'src/Dashboard/ERROR';
export const UPDATE_MULTI = 'src/Dashboard/UPDATE_MULTI';
export const UPDATE_MULTI_SUCCESS = 'src/Dashboard/UPDATE_MULTI_SUCCESS';
export const UPDATE_MULTI_ERROR = 'src/Dashboard/UPDATE_MULTI_ERROR';
