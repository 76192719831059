import { startCase } from 'lodash';

import React, { useState } from 'react';
import { Input } from 'reactstrap';

import DeleteButton from 'atoms/DeleteButton';
import SelectAutoComplete from 'atoms/SelectAutoComplete';

const MTCClinician = (props) => {
	const {
		handleDelete,
		index,
		clinician,
		handleChange,
		handleSelect,
		clinicians,
		inputDisabled,
	} = props;
	const [typeError, setTypeError] = useState(false);

	const onChange = (e) => {
		if (e.target.value === '') {
			setTypeError(true);
		} else {
			setTypeError(false);

			handleChange('mtc', 'type', e.target.value, index);
		}
	};

	return (
		<div key={`mtc-${index}`}>
			<div className="form-row">
				<div className="form-group col">
					<div
						className="d-flex justify-content-start"
						style={{ marginBottom: '-0.35rem' }}
					>
						<div className="form-font pr-3">CLINICIAN TYPE</div>
						<DeleteButton
							onClick={() => handleDelete('mtc', index, clinician.id)}
							popupText={`Unassign ${clinician.name}`}
							size={20}
							styles={{
								container: {
									transform: 'translate(-2px, -13px)',
								},
							}}
							classNames={{
								container: 'unassign-clin',
							}}
						/>
					</div>
					<Input
						name="type"
						label="CLINICIAN TYPE"
						value={startCase(clinician.type)}
						placeholder="Enter Clinician Type"
						className={typeError && 'input-error'}
						onChange={onChange}
						disabled={inputDisabled}
						style={{
							height: 46,
						}}
					/>
					<span className="form-error">{typeError && 'Field required'}</span>
				</div>
				<div className="form-group col">
					<SelectAutoComplete
						label="CLINICIAN NAME"
						handleChange={(option) => handleSelect(option, index)}
						options={clinicians}
						value={{ label: clinician.name, value: clinician.id }}
						placeholder="Select Clinician"
						controlStyles={{
							marginTop: '0.5rem',
							padding: '.08rem .75rem !important',
							borderColor: '#ced4da',
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default MTCClinician;
