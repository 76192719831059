import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the patient state domain
 */

const selectPatientDomain = (state) => state.get('patient', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Patient
 */

const makeSelectPatient = () =>
	createSelector(selectPatientDomain, (substate) => returnJS(substate));

export default makeSelectPatient;
export { selectPatientDomain };
