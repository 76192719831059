import PropTypes from 'prop-types';

import Avatar from 'atoms/Avatar/index.tsx';
import DefaultAvatar from 'atoms/DefaultAvatar';

/**
 *
 * <ImageDetailsCell />, a functional component to display image with heading and subheading
 */
const ImageDetailsCell = (props) => {
	const {
		src,
		heading,
		primarySubHeading,
		secondarySubHeading,
		className,
		showDetails,
		style,
		classes = {},
		width,
	} = props;

	const modifiedClass = `${className} d-flex justify-content-start p-0`;

	return (
		<div className={modifiedClass}>
			<div className="pr-1 align-self-center">
				{src ? (
					<Avatar src={src} />
				) : (
					<DefaultAvatar name={heading} style={style} width={width} />
				)}
			</div>
			{showDetails && (
				<div className="ml-1 d-flex flex-column justify-content-center text-left">
					<h6 className={`text-dark m-0 font-13 ${classes.heading} `}>
						{heading}
					</h6>
					<p
						className={`text-cust-grey m-0 font-11 ${classes.primarySubHeading} `}
					>
						{primarySubHeading}
					</p>
					<p
						className={`text-cust-grey m-0 font-11 ${classes.secondarySubHeading} `}
					>
						{secondarySubHeading}
					</p>
				</div>
			)}
		</div>
	);
};

ImageDetailsCell.propTypes = {
	// image url
	src: PropTypes.string,
	// heading text
	heading: PropTypes.string,
	// subheading text
	primarySubHeading: PropTypes.string,
	secondarySubHeading: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.number,
	showDetails: PropTypes.bool,
};

ImageDetailsCell.defaultProps = {
	width: 40,
	showDetails: true,
};

export default ImageDetailsCell;
