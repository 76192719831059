import { call, put, takeLatest } from 'redux-saga/effects';

import {
	createSigningDocAPI,
	declineFormAPI,
	deleteFormAPI,
	downloadFormAPI,
	fetchAllClientsAPI,
	fetchAllGroupsAPI,
	fetchFormsAPI,
	fetchMyClientsAPI,
	signDocAPI,
	submitFormAPI,
	updateFormAPI,
	uploadFormsAPI,
} from 'utils/api';

import {
	createSigningDocError,
	createSigningDocSuccess,
	declineFormError,
	declineFormSuccess,
	deleteFormError,
	deleteFormSuccess,
	fetchClientsError,
	fetchClientsSuccess,
	fetchFormError,
	fetchFormSuccess,
	fetchFormsError,
	fetchFormsSuccess,
	fetchGroupsError,
	fetchGroupsSuccess,
	shareFormError,
	shareFormSuccess,
	signDocError,
	signDocSuccess,
	submitJotformError,
	submitJotformSuccess,
	updateFormError,
	updateFormSuccess,
	uploadFormsError,
	uploadFormsResponse,
} from './actions';
import {
	CREATE_SIGNING_DOC,
	DECLINE_FORM,
	DELETE_FORM,
	FETCH_CLIENTS,
	FETCH_FORM,
	FETCH_FORMS,
	FETCH_GROUPS,
	SHARE_FORM,
	SIGN_DOC,
	SUBMIT_JOTFORM,
	UPDATE_FORM,
	UPLOAD_FORMS,
} from './constants';

export default function* formsSaga() {
	yield takeLatest(UPLOAD_FORMS, uploadForms);
	yield takeLatest(FETCH_FORMS, fetchForms);
	yield takeLatest(FETCH_FORM, fetchForm);
	yield takeLatest(DECLINE_FORM, declineForm);
	yield takeLatest(FETCH_CLIENTS, fetchClients);
	yield takeLatest(FETCH_GROUPS, fetchGroups);
	yield takeLatest(UPDATE_FORM, updateForm);
	yield takeLatest(SHARE_FORM, shareForm);
	yield takeLatest(DELETE_FORM, deleteForm);
	yield takeLatest(CREATE_SIGNING_DOC, createSigningDoc);
	yield takeLatest(SIGN_DOC, signDoc);
	yield takeLatest(SUBMIT_JOTFORM, submitJotform);
}

export function* uploadForms(data) {
	const { files } = data;
	try {
		const response = yield call(uploadFormsAPI, { files });
		if (response.data) {
			yield put(uploadFormsResponse(response.data));
		} else {
			yield put(uploadFormsError('Could not upload files'));
		}
	} catch (err) {
		yield put(uploadFormsError(err));
	}
}

export function* fetchForms() {
	try {
		const response = yield call(fetchFormsAPI);

		if (response.data) {
			yield put(fetchFormsSuccess(response.data));
		} else {
			yield put(fetchFormsError());
		}
	} catch (err) {
		yield put(fetchFormsError());
	}
}

export function* fetchForm(action) {
	const { id } = action;

	try {
		const response = yield call(fetchFormsAPI, { id });

		if (response.data?.form) {
			yield put(fetchFormSuccess(response.data.form));
		} else {
			yield put(fetchFormError());
		}
	} catch (err) {
		yield put(fetchFormError());
	}
}

export function* declineForm(action) {
	const { id, reason } = action;

	try {
		const response = yield call(declineFormAPI, { id, reason });

		if (response.data?.form) {
			yield put(declineFormSuccess(response.data.form));
		} else {
			yield put(declineFormError());
		}
	} catch (err) {
		yield put(declineFormError());
	}
}

export function* fetchClients(action) {
	const { role } = action;

	try {
		let response;
		if (role === 'admin') {
			response = yield call(fetchAllClientsAPI);
		} else if (role === 'clinician') {
			response = yield call(fetchMyClientsAPI);
		}

		if (response.data) {
			yield put(fetchClientsSuccess(response.data));
		} else {
			yield put(fetchClientsError());
		}
	} catch (err) {
		yield put(fetchClientsError());
	}
}

export function* fetchGroups(action) {
	const { id } = action;
	try {
		const response = yield call(fetchAllGroupsAPI, { id });

		if (response) {
			yield put(fetchGroupsSuccess(response));
		} else {
			yield put(fetchGroupsError());
		}
	} catch (err) {
		yield put(fetchGroupsError());
	}
}

export function* updateForm(action) {
	const { data, uuid, cb } = action;

	try {
		const response = yield call(updateFormAPI, { ...data, uuid });

		if (response.data) {
			yield put(updateFormSuccess(response.data.document));
			if (cb) {
				cb(response.data.document);
			}
		} else {
			yield put(updateFormError('Could not update form'));
		}
	} catch (err) {
		yield put(updateFormError('Could not update form'));
	}
}

export function* shareForm(action) {
	const { uuid, cb } = action;
	const data = { uuid };
	try {
		const response = yield call(downloadFormAPI, data);
		if (response.data) {
			yield put(shareFormSuccess(response.data));
			if (cb) {
				cb();
			}
		} else {
			yield put(shareFormError('Could not update form'));
		}
	} catch (err) {
		yield put(shareFormError('Could not update form'));
	}
}

export function* deleteForm(action) {
	const { uuid } = action;
	const data = { uuid };
	try {
		const response = yield call(deleteFormAPI, data);

		if (response.data) {
			yield put(deleteFormSuccess(response.data.document));
		} else {
			yield put(deleteFormError('Could not delete form'));
		}
	} catch (err) {
		yield put(deleteFormError('Could not delete form'));
	}
}

export function* createSigningDoc(action) {
	const { uuid, filename, receivers, cb } = action;
	const data = { uuid, filename, receivers };
	try {
		const response = yield call(createSigningDocAPI, data);

		if (response.data) {
			yield put(createSigningDocSuccess(response.data));
			if (cb) {
				cb();
			}
		} else {
			yield put(createSigningDocError('Could not create document'));
		}
	} catch (err) {
		yield put(createSigningDocError('Could not create document'));
	}
}

export function* signDoc(action) {
	try {
		const response = yield call(signDocAPI, action.id);
		if (response.url) {
			yield put(signDocSuccess(response.url));
		} else {
			yield put(signDocError());
		}
	} catch (err) {
		yield put(signDocError(err));
	}
}

export function* submitJotform(action) {
	try {
		const response = yield call(submitFormAPI, action.data);
		if (response.response?.status < 200 || response.response?.status > 299) {
			yield put(submitJotformError(response.response.data.error));
		}
		yield put(submitJotformSuccess(response.data?.permissions || []));
	} catch (err) {
		yield put(submitJotformError(err));
	}
}
