import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useState } from 'react';

import SearchBar from 'atoms/SearchBar';
import Table from 'atoms/Table';
import FormCard from 'cards/FormCard';
import FilterNav from 'components/FilterNav';
import formFilters from 'containers/Forms/filters';
import useIsMobile from 'hooks/isMobile.ts';
import { compareWords } from 'utils/columnCompare';
import { filterByLetter, filterData, getFilterOptions } from 'utils/helpers';

const FormTabContent = (props) => {
	const {
		buttons = [],
		data,
		placeholder = 'No data to display',
		searchText = 'Search for form',
		loading = false,
		columns,
		activeTab,
		type,
		actions,
		routeProps = { location: {} },
	} = props;

	const [search, setSearch] = useState('');
	const [filteredData, setFilteredData] = useState(data);
	const [filters, setFilters] = useState([]);
	const isMobile = useIsMobile();

	const { location = { state: { activeTab: '', searchText: '' } } } =
		routeProps;
	const state = location.state || { searchText: '', activeTab: '' };

	const addFilter = (filter) => {
		setFilters([...filters, filter]);
	};
	const removeFilter = (name) => {
		setFilters(filters.filter((x) => x.name !== name));
	};
	const filterDropdown = (data, name, value) => {
		const newData = filterData(data, name, value);

		return newData;
	};

	const handleSearch = useCallback(() => {
		if (search) {
			const filterOn = activeTab === 'templates' ? 'templateName' : 'formName';
			setFilteredData(filterByLetter(data, filterOn, search));
		} else {
			setFilteredData(data);
		}
	}, [filteredData, setFilteredData, search, data]);

	useEffect(handleSearch, [search]);
	useEffect(() => {
		if (state?.searchText) {
			setSearch(state.searchText);
			const filterOn = activeTab === 'templates' ? 'templateName' : 'formName';
			setFilteredData(filterByLetter(data, filterOn, state.searchText));
		} else {
			setFilteredData(data);
		}
	}, [data]);
	useEffect(() => {
		setSearch(state.searchText);
		const filterOn = activeTab === 'templates' ? 'templateName' : 'formName';
		setFilteredData(filterByLetter(data, filterOn, state.searchText));
	}, [state.searchText]);

	const generateFilters = () => {
		let filteroptions = [];
		const options = {};

		options.clientName = getFilterOptions(data, 'client.name').sort((a, b) =>
			compareWords(a, b, 'asc'),
		);

		filteroptions = formFilters(options, type);

		return filteroptions;
	};

	const searchBar = () => (
		<SearchBar
			value={search}
			onSearch={setSearch}
			placeholder={searchText}
			classNames={
				activeTab === 'templates' || type === 'patient'
					? { container: 'mx-0' }
					: {}
			}
			autoSearch
		/>
	);

	let finalData = filteredData;
	filters.forEach((filter) => {
		finalData = filter.callback(finalData, filter.name, filter.option);
	});

	return (
		<>
			<div className="px-0 mb-2">
				{activeTab !== 'templates' && type !== 'patient' ? (
					<FilterNav
						filtersList={generateFilters()}
						filterDropdown={filterDropdown}
						filterByLetter={filterByLetter}
						addFilter={addFilter}
						removeFilter={removeFilter}
						currentFilters={filters}
						isMobile={isMobile}
						search={searchBar()}
					/>
				) : (
					searchBar()
				)}
			</div>
			{buttons.length > 0 && (
				<div className="bg-white p-3 w-100 mx-0 justify-content-end d-flex mb-2">
					{buttons.map((button) => (
						<button
							key={button.name}
							className="btn btn-primary primary-btn-color px-3 py-2 font-10 ml-2"
							onClick={button.onClick}
						>
							{button.name}
						</button>
					))}
				</div>
			)}
			{isMobile ? (
				finalData.map((data, i) => (
					<FormCard
						key={`${data.createdAt}-${data.formName || data.filename}-${i}`}
						heading={data.formName || data.filename}
						form={data}
						actions={actions}
						isSender={!(type === 'patient')}
						type={activeTab}
					/>
				))
			) : (
				<div className="mb-1">
					<Table
						columns={columns}
						data={finalData}
						placeholder={placeholder}
						loading={loading}
					/>
				</div>
			)}
		</>
	);
};

FormTabContent.propTypes = {
	buttons: PropTypes.array,
	actions: PropTypes.array,
	data: PropTypes.array,
	loading: PropTypes.bool,
};

export default FormTabContent;
