import React from 'react';

const Footer = () => {
	return (
		<div className="footer-wrapper">
			<div className="footer py-2 pr-2 row justify-content-end mx-0">
				<div className="col-auto">
					<a
						href="https://www.mindtherapyclinic.com/privacy-practices"
						target="_blank"
						rel="noopener noreferrer"
						className="external-link"
					>
						Policies
					</a>
				</div>
				<div className="col-auto">
					<a
						href="https://www.mindtherapyclinic.com/privacy-practices"
						target="_blank"
						rel="noopener noreferrer"
						className="external-link"
					>
						Privacy Practices
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
