import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';


interface Props {
    prevProgress: () => void,
    nextProgress: () => () => void,
    ketamineChecked: boolean,
    handleAcceptance: () => void,
    isMobile: boolean,
}

export default function KetamineFinal(props: Props): JSX.Element {
    const { prevProgress, nextProgress, handleAcceptance, ketamineChecked, isMobile } = props;

    const handleNext = () => {
        nextProgress()();
    };


    return (
        <div
            className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
        >
            <div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
                <div className="pt-1 pb-4">
                    <h4>Ongoing and concurrent treatment:</h4>
                    <span>
                        The patient will be engaged in an ongoing and concurrent
                        treatment with a psychiatrist and/or psychologist, as deemed
                        appropriate by the physician providing ketamine treatment. The
                        termination or interruption of this collaborative treatment may
                        result in termination of ketamine associated therapy.
                    </span>
                </div>

                <div className="pt-1 pb-4">
                    <h4>Final declaration:</h4>
                    <p>
                        In signing this agreement, I recognize that I have established a
                        treatment relationship with Mind Therapy Clinic. I understand that
                        I have alternative treatment options beside ketamine for my
                        condition. I understand that I may not respond or have a favorable
                        response to ketamine treatment and there are risks associated with
                        the treatment, some of which may be permanent.
                    </p>
                    <p>
                        Treatment may be terminated by Mind Therapy Clinic based on
                        clinical factors, including and not limited to concerns for poor
                        fit with treatment, likelihood of response, lack of compliance
                        with care, concern for diversion, patient behaviors and risk
                        factors exceeding tolerance of his practice, and lack of
                        sufficient collaborative support by the treatment team. Provision
                        of treatment does not automatically imply future treatment.
                    </p>

                    <IAccept
                        handleAcceptance={handleAcceptance}
                        name="ketamineChecked"
                        value={ketamineChecked}
                        isRequired
                    />
                </div>
            </div>

            <div className="d-flex mt-4 mb-5 justify-content-center">
                <div className="col-6">
                    <PrevButton onClick={prevProgress} isMobile={isMobile} />
                </div>
                <div className="col-6">
                    <NextButton
                        disabled={!ketamineChecked}
                        onClick={handleNext}
                        isMobile={isMobile}
                    />
                </div>
            </div>
        </div>
    );
}
