import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import NavTab from 'atoms/NavTab';
import PdfViewer from 'atoms/PdfViewer';
import ShareFormModal from 'components/ShareFormModal';
import SigningModal from 'components/SigningModal';
import TagFormModal from 'components/TagFormModal';
import UploadFormModal from 'components/UploadFormModal';
import ValidateModal from 'components/ValidateSentModal';
import { downloadFile, generateToast, viewPdf } from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectFile,
	makeSelectIsLoadingFile,
} from 'containers/App/selectors';
import useIsMobile from 'hooks/isMobile.ts';
import { getLogger } from 'utils/logger';

import {
	clearUploadResult,
	createSigningDoc,
	declineForm,
	deleteForm,
	fetchClients,
	fetchForm,
	fetchForms,
	fetchGroups,
	shareForm,
	signDoc,
	updateForm,
	uploadForms,
} from './actions';
import { sharedFormsColumns, templatesColumns } from './columns';
import DeclineModal from './components/DeclineModal';
import FormTabContent from './components/FormTabContent';
import {
	makeSelectClients,
	makeSelectErrors,
	makeSelectFileToEdit,
	makeSelectFormsList,
	makeSelectGroups,
	makeSelectIsFetching,
	makeSelectIsSending,
	makeSelectIsUpdating,
	makeSelectIsUploading,
	makeSelectUploadResult,
	makeSelectUrl,
} from './selectors';

import MeasureCard from 'v2/measures/MeasureCard';

const commonTabs = [
	{ name: 'Pending', id: 'pending' },
	{ name: 'Signed', id: 'signed' },
	{ name: 'Declined', id: 'declined' },
	{ name: 'Shared', id: 'shared' },
];

const logger = getLogger('containers.Forms');

const adminTabs = [{ name: 'Templates', id: 'templates' }];
const Forms = (props) => {
	const {
		doUpload,
		isUploading,
		uploadResult,
		sawResult,
		loadForms,
		forms,
		isFetching,
		download,
		view,
		updateForm,
		isUpdating,
		deleteForm,
		createSigningDoc,
		signingUrl,
		clients,
		loadClients,
		type,
		loadGroups,
		groups,
		isSending,
		doShareForm,
		fileToEdit,
		auth,
		doSignDoc,
		url,
		doDeclineForm,
		routeProps,
		file,
		isLoadingFile,
	} = props;
	const { location: { state } = { state: { activeTab: '' } } } = routeProps;
	const dispatch = useDispatch();

	const tabs = type === 'patient' ? commonTabs : [...adminTabs, ...commonTabs];

	const [activeTab, setActiveTab] = useState(tabs[0].id);

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const [showTagFormModal, setShowTagFormModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSigningModal, setShowSigningModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [shouldLoadForms, setShouldLoadForms] = useState(true);
	const [, setReason] = useState('');
	const [mode, setMode] = useState(type === 'patient' ? 'edit' : 'view');
	const [activeFile, setActiveFile] = useState(null);
	const [showPdfViewer, setShowPdfViewer] = useState(false);

	const [statusText, setStatusText] = useState('');

	const isMobile = useIsMobile();

	useEffect(() => {
		if (state?.activeTab) {
			setActiveTab(state.activeTab);
		}
	}, []);

	useEffect(() => {
		if (type !== 'patient' || activeTab === 'declined') {
			setMode('view');
		} else {
			setMode('edit');
		}
	}, [activeTab]);

	useEffect(() => {
		if (type !== 'patient') {
			loadClients(type);
		}

		if (type === 'clinician') {
			const { loggedInUser } = auth;
			const { id } = loggedInUser;
			loadGroups(id);
		} else if (type === 'admin') {
			loadGroups();
		}
	}, [type]);

	useEffect(() => {
		if (shouldLoadForms) {
			loadForms();
			setShouldLoadForms(false);
		}
	}, [shouldLoadForms, loadForms, setShouldLoadForms]);

	useEffect(() => {
		loadForms();
	}, [activeTab]);

	useEffect(() => {
		if (!showSigningModal && signingUrl) {
			setShowSigningModal(true);
		}
	}, [showSigningModal, signingUrl, setShowSigningModal]);

	useEffect(() => {
		const { docsSaved, errors } = uploadResult;

		if (docsSaved.length > 0) {
			docsSaved.forEach((text) => {
				dispatch(generateToast({ type: 'custom-success', text }));
			});
			dispatch(clearUploadResult());
		}

		if (Array.isArray(errors) && errors.length > 0) {
			setStatusText('');
			errors.forEach((text) =>
				dispatch(generateToast({ type: 'custom-error', text })),
			);
		}

		if (docsSaved.length > 0 || errors.length > 0) {
			sawResult();
		}
	}, [uploadResult, dispatch, generateToast]);

	useEffect(
		useCallback(() => {
			if (!isSending && statusText === 'Sending Form...') {
				setStatusText('');
			}
		}, [statusText, isSending, setStatusText]),
		[isSending],
	);

	const toggleUploadModal = () => {
		setShowUploadModal(!showUploadModal);
	};

	const toggleTagFormModal = () => {
		setShowTagFormModal(!showTagFormModal);
	};

	const toggleDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	const toggleShareModal = (form) => {
		setShowShareModal(!showShareModal);

		if (form) {
			setSelectedForm(form);
		}
	};

	const updateTag = (type) => {
		updateForm(
			{
				documentType: type,
			},
			selectedForm.uuid,
		);
	};

	const doSaveForm = (data, cb) => {
		updateForm(data, selectedForm.uuid, (document) => {
			setSelectedForm(document);

			if (cb) {
				cb(document);
			}
		});
	};

	const doDeleteForm = () => {
		deleteForm(selectedForm.uuid);
	};

	const setDeleteForm = (form) => {
		setSelectedForm(form);
		toggleDeleteModal();
	};

	const doUploadForms = (data) => {
		doUpload(data);
	};

	const shareCurrentForm = (clients, filename) => {
		toggleShareModal();
		setStatusText('Sending Form...');
		createSigningDoc({
			clients,
			uuid: selectedForm.uuid,
			filename,
		});
		dispatch(
			generateToast({ type: 'custom-success', text: 'Sharing form...' }),
		);
	};

	const handleShareSelection = (form) => {
		setSelectedForm(form);
		doShareForm(form.uuid, toggleShareModal);
	};

	const handleViewReason = (declineReason, form) => {
		setReason(declineReason);
		setSelectedForm(form);
		setShowDeclineModal(true);
	};

	const handleSign = (form) => {
		setSelectedForm(form);
		doSignDoc(form.request.id);
		toggleSigningModal();
	};

	const toggleDeclineModal = () => {
		setShowDeclineModal(!showDeclineModal);

		if (showDeclineModal && mode === 'edit') {
			setShouldLoadForms(true);
		}
	};

	const openDeclineModal = (form) => {
		setSelectedForm(form);
		setShowDeclineModal(true);
	};

	const handleDeclineSubmit = (reason) => {
		if (mode === 'edit') {
			doDeclineForm(selectedForm.request.id, reason);
			setActiveTab('declined');
		}

		toggleDeclineModal();
	};

	const toggleSigningModal = () => {
		setShowSigningModal(!showSigningModal);

		if (showSigningModal) {
			setShouldLoadForms(true);
			setActiveTab('signed');
		}
	};

	const handleViewForm = (data, key) => {
		let { uuid } = data;

		if (key) {
			if (key === 'template') {
				uuid = data.templateUUID;
			}
		}

		if (!uuid) {
			uuid = data.uuid || data.formUUID || data.templateUUID;
		}

		if (!uuid) return;

		data.uuid = uuid;

		view(uuid);
		setActiveFile(data);
		togglePdfViewer();
	};

	const togglePdfViewer = () => {
		setShowPdfViewer(!showPdfViewer);
	};

	let { templates = [], sharedForms = [] } = forms;

	const renderTabContent = () => {
		const actions = {
			edit: (id) => logger.info('edit ' + id),
			download,
			view: handleViewForm,
			delete: setDeleteForm,
			share: handleShareSelection,
			viewReason: handleViewReason,
			sign: handleSign,
			decline: openDeclineModal,
		};
		let columns =
			activeTab === 'templates'
				? templatesColumns(actions)
				: sharedFormsColumns(actions, activeTab, type);

		const tabToContent = {
			templates: {
				buttons: [
					{
						onClick: toggleUploadModal,
						name: 'Upload Templates',
					},
					/* {
            onClick: () => history.push('forms/editor'),
            name: 'Create New Form',
          }, */
				],
				data: templates,
				placeholder: 'No templates to display',
				searchText: 'Search for Template',
				loading: isFetching.templates,
			},
			shared: {
				data: sharedForms.filter((f) => f.state?.includes('OPEN')),
				placeholder: 'No shared forms to display',
				searchText: 'Search for Shared Form',
				loading: isFetching.sharedForms,
			},
			pending: {
				data: sharedForms.filter((f) => f.state?.includes('AWAITING')),
				placeholder: 'No pending forms to display',
				searchText: 'Search for Pending Form',
				loading: isFetching.sharedForms,
			},
			signed: {
				data: sharedForms.filter((f) => f.state?.includes('COMPLETED')),
				placeholder: 'No signed forms to display',
				searchText: 'Search for Signed Form',
				loading: isFetching.sharedForms,
			},
			declined: {
				data: sharedForms.filter((f) => f.state?.includes('DECLINED')),
				placeholder: 'No declined forms to display',
				searchText: 'Search for Declined Form',
				loading: isFetching.sharedForms,
			},
		};

		const content = {
			...tabToContent[activeTab],
		};

		return (
			<FormTabContent
				activeTab={activeTab}
				columns={columns}
				buttons={content.buttons}
				data={content.data}
				placeholder={content.placeholder}
				searchText={content.searchText}
				loading={content.loading}
				type={type}
				routeProps={routeProps}
				actions={actions}
			/>
		);
	};

	const getDropdownLabel = () => {
		let label = tabs.find((label) => label.id.toString() === activeTab);

		return label ? label.name : 'No Options';
	};

	const switchTab = (tab) => {
		setActiveTab(tab);
		if (type !== 'patient' || tab === 'declined') {
			setMode('view');
		} else {
			setMode('edit');
		}
	};

	return (
		<div className={`container-fluid pt-3 ${isMobile ? 'px-2' : 'px-5'} pb-5`}>
			{type !== 'patient' &&
				<>
					<MeasureCard />
					<div className="row mt-4 mb-5"></div>
						<hr className="w-100" />
					<div className="row mt-4 mb-5"></div>
				</>
			}
			

			<NavTab
				type={type}
				getLabel={getDropdownLabel}
				activeTab={activeTab}
				isMobile={isMobile}
				labels={{
					admin: tabs,
					clinician: tabs,
					patient: tabs,
				}}
				switchTab={switchTab}
			/>
			{renderTabContent()}
			{showUploadModal && (
				<UploadFormModal
					isOpen={showUploadModal}
					toggle={toggleUploadModal}
					uploadFiles={doUploadForms}
					isUploading={isUploading}
				/>
			)}
			{showTagFormModal && (
				<TagFormModal
					isOpen={showTagFormModal}
					toggle={toggleTagFormModal}
					submit={updateTag}
					form={selectedForm}
					isUpdating={isUpdating}
				/>
			)}
			{showDeleteModal && (
				<ValidateModal
					successStatus={false}
					hide={toggleDeleteModal}
					handleDelete={doDeleteForm}
					message="Once deleted, a form and ALL of its data will be deleted forever! This action CANNOT Be undone."
					toBeDeleted={selectedForm}
				/>
			)}
			{showShareModal && (
				<ShareFormModal
					isOpen={showShareModal}
					toggle={toggleShareModal}
					clients={clients}
					groups={groups}
					form={selectedForm}
					handleSubmit={shareCurrentForm}
					file={fileToEdit}
					saveForm={doSaveForm}
					isUpdating={isUpdating}
				/>
			)}
			{showDeclineModal && (
				<DeclineModal
					isOpen={showDeclineModal}
					toggle={toggleDeclineModal}
					submit={handleDeclineSubmit}
					form={selectedForm}
					mode={mode}
				/>
			)}
			{url && (
				<SigningModal
					isOpen={showSigningModal}
					siteUrl={url}
					toggle={toggleSigningModal}
				/>
			)}

			{showPdfViewer && (
				<PdfViewer
					isOpen={showPdfViewer}
					toggle={togglePdfViewer}
					file={file}
					documentData={activeFile}
					isLoading={isLoadingFile}
					downloadFile={() => download(activeFile.uuid)}
				/>
			)}
		</div>
	);
};

Forms.propTypes = {
	dispatch: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
	isUploading: makeSelectIsUploading(),
	uploadResult: makeSelectUploadResult(),
	forms: makeSelectFormsList(),
	errors: makeSelectErrors(),
	isFetching: makeSelectIsFetching(),
	isUpdating: makeSelectIsUpdating(),
	isSending: makeSelectIsSending(),
	clients: makeSelectClients(),
	groups: makeSelectGroups(),
	fileToEdit: makeSelectFileToEdit(),
	auth: makeSelectAuth(),
	url: makeSelectUrl(),
	file: makeSelectFile(),
	isLoadingFile: makeSelectIsLoadingFile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		loadClients: (type) => dispatch(fetchClients(type)),
		loadGroups: (id) => dispatch(fetchGroups(id)),
		loadForms: () => dispatch(fetchForms()),
		doUpload: (data) => dispatch(uploadForms(data)),
		sawResult: () => dispatch(clearUploadResult()),
		download: (id) => dispatch(downloadFile(id, 'forms')),
		view: (id, cb) => dispatch(viewPdf(id, cb)),
		updateForm: (data, uuid, cb) => dispatch(updateForm(data, uuid, cb)),
		deleteForm: (uuid) => dispatch(deleteForm(uuid)),
		createSigningDoc: (data, cb) => dispatch(createSigningDoc(data, cb)),
		doShareForm: (uuid, cb) => dispatch(shareForm(uuid, cb)),
		doSignDoc: (id) => dispatch(signDoc(id)),
		doGetForm: (id) => dispatch(fetchForm(id)),
		doDeclineForm: (id, reason) => dispatch(declineForm(id, reason)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
