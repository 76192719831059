/*
 *
 * SignIn reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CLEAR_ERROR,
	DEFAULT_ACTION,
	FORGOT_PASS,
	FORGOT_PASS_MSG,
	LOGIN_USER_FAILURE,
	LOGIN_USER_FETCH,
	TOGGLE_FORGOT_PASS,
} from './constants';

export const initialState = fromJS({
	loginData: {
		email: '',
		password: '',
	},
	error: '',
	showForgotPassModal: false,
	forgotPasswordMessage: '',
});

function signInReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case LOGIN_USER_FETCH:
			return state.set(
				'loginData',
				state.get('loginData').mergeDeep(action.loginData),
			);
		case LOGIN_USER_FAILURE:
			return state.set('error', action.failureData);
		case CLEAR_ERROR:
			return state.set('error', null);
		case TOGGLE_FORGOT_PASS:
			return state.set(
				'showForgotPassModal',
				!state.get('showForgotPassModal'),
			);
		case FORGOT_PASS_MSG:
			return state.set('forgotPasswordMessage', action.data.data.msg);
		case FORGOT_PASS:
			return state.set('forgotPasswordMessage', '');
		default:
			return state;
	}
}

export default signInReducer;
