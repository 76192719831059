const NextButton = (props) => {
	const { disabled, onClick, isMobile, ...rest } = props;

	return (
		<div>
			<button
				disabled={disabled}
				type="button"
				className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-end"
				onClick={onClick}
				{...rest}
			>
				<div className="col-4 d-flex justify-content-center">
					<span>NEXT</span>
				</div>
				<div
					className={`col-4 d-flex justify-content-end align-self-center ${
						isMobile && 'ml-2'
					}`}
				>
					<img className="fas fa-arrow-right" alt="custom-arrow-right" />
				</div>
			</button>
		</div>
	);
};

export default NextButton;
