import PropTypes from 'prop-types';

import React from 'react';

const ButtonToolBar = (props) => {
	const { title, buttons } = props;
	return (
		<div className="card p-0 mt-2 mb-2">
			<div className="d-flex align-items-center justify-content-between p-3">
				<span className="font-weight-bold font-13 text-dark">{title}</span>
				<div className="d-flex justify-content-end">
					{buttons.map((button) => (
						<button
							type="button"
							className={button.className}
							onClick={button.handleClick}
							key={button.label}
						>
							{button.label}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

ButtonToolBar.propTypes = {
	title: PropTypes.string,
	buttons: PropTypes.array,
};

export default ButtonToolBar;
