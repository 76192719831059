import { chunk } from 'lodash';

import PropTypes from 'prop-types';

import Checkbox from 'atoms/Checkbox';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
// import components
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <SymptomInformation />, a functional component for Child Intake Evaluation
 */
const SymptomInformation = (props) => {
	const { data, handleChange, handleCheck } = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			<div className="row mt-3 mb-3">
				<label htmlFor="previouslyADHDEvaluated" className="form-font col-8">
					HAS YOUR CHILD PREVIOUSLY BEEN EVALUATED FOR ADD/ADHD?
				</label>
				<SelectButtonGroup
					id="previouslyADHDEvaluated"
					value={data.previouslyADHDEvaluated}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className="col-4"
				/>
			</div>

			{data.previouslyADHDEvaluated === 'yes' && (
				<TextInput
					className="mb-4 mt-n2"
					label="IF YES, PLEASE TELL US WHO PERFORMED THE EVALUATION, TESTS PERFORMED AND RESULTS:"
					id="evaluationDetails"
					value={data.evaluationDetails}
					handleInput={handleChange}
				/>
			)}

			<label htmlFor="adhdSymptoms" className="form-font">
				WHAT ARE THE PRIMARY SYMPTOMS RELATED TO YOUR CHILD&apos;S ADD/ADHD:
			</label>
			<div className="d-flex flex-column border-top border-left mt-2 mb-5">
				{chunk(data.adhdSymptoms, 3).map((row) => (
					<div className="d-flex p-0" key={`adhd-${row}`}>
						{row.map((symptom) => (
							<Checkbox
								key={symptom.id}
								className="col-4 border-right border-bottom"
								item={symptom}
								checkboxGroup="adhdSymptoms"
								parent="childAdhdInfo"
								handleCheck={handleCheck}
							/>
						))}
					</div>
				))}
			</div>

			<label htmlFor="symptomsDetails" className="form-font mb-3">
				PLEASE DESCRIBE THESE SYMPTOMS IN MORE DETAIL AND HOW THEY ARE AFFECTING
				YOUR CHILD&apos;S LIFE IN THE FOLLOWING AREAS:
			</label>
			<div className="row">
				<TextArea
					className="col-4 mb-3"
					label="HOME"
					id="symptomsAtHome"
					value={data.symptomsAtHome}
					handleInput={handleChange}
				/>
				<TextArea
					className="col-4 mb-3"
					label="SCHOOL WORK"
					id="symptomsAtSchool"
					value={data.symptomsAtSchool}
					handleInput={handleChange}
				/>
				<TextArea
					className="col-4 mb-3"
					label="RELATIONSHIPS"
					id="symptomsAtRelation"
					value={data.symptomsAtRelation}
					handleInput={handleChange}
				/>
			</div>
			<div className="row">
				<TextArea
					className="col-12 mb-3"
					label="HOW LONG THESE SYMPTOMS HAVE BEEN A PROBLEM FOR YOUR CHILD?"
					id="symptomsDuration"
					value={data.symptomsDuration}
					handleInput={handleChange}
				/>
			</div>

			<div className="row mb-3">
				<div className="col-6">
					<label htmlFor="symptomsPresence" className="form-font">
						ARE THESE SYMPTOMS:
					</label>
					<SelectButtonGroup
						id="symptomsPresence"
						value={data.symptomsPresence}
						buttons={generateObjectArray(['Always present', 'Intermittent'])}
						handleSelect={handleChange}
						className=""
					/>
				</div>
				{data.symptomsPresence?.length > 0 && (
					<TextArea
						className="col-6"
						label="PLEASE DESCRIBE YOUR SELECTION:"
						id="symptomsPresenceDetails"
						value={data.symptomsPresenceDetails}
						handleInput={handleChange}
						rows={1}
					/>
				)}
			</div>
		</div>
	);
};

SymptomInformation.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
};

export default SymptomInformation;
