/*
 *
 * UserDropDown reducer
 *
 */
import { fromJS } from 'immutable';

import {
	FETCH_DOC_LOG,
	FETCH_DOC_LOG_ERROR,
	FETCH_DOC_LOG_SUCCESS,
	TEST_ENROLLMENT,
	TEST_ENROLLMENT_ERROR,
	TEST_ENROLLMENT_SUCCESS,
	TEST_INTAKE_NEW,
	TEST_INTAKE_NEW_ERROR,
	TEST_INTAKE_NEW_SUCCESS,
	TEST_QUESTIONNAIRE,
	TEST_QUESTIONNAIRE_ERROR,
	TEST_QUESTIONNAIRE_SUCCESS,
	TEST_ROI,
	TEST_ROI_ERROR,
	TEST_ROI_SUCCESS,
	TEST_TELEHEALTH,
	TEST_TELEHEALTH_ERROR,
	TEST_TELEHEALTH_SUCCESS,
} from './constants';

export const initialState = fromJS({
	url: '',
	data: {},
	error: '',
	fetchingDocs: false,
	docs: [],
	docsError: '',
});

function adminConsoleReducer(state = initialState, action) {
	switch (action.type) {
		case TEST_TELEHEALTH:
		case TEST_INTAKE_NEW:
		case TEST_ROI:
		case TEST_ENROLLMENT:
		case TEST_QUESTIONNAIRE:
			return state.merge({
				data: action.data,
				error: '',
				url: '',
			});

		case TEST_TELEHEALTH_SUCCESS:
		case TEST_INTAKE_NEW_SUCCESS:
		case TEST_ROI_SUCCESS:
		case TEST_ENROLLMENT_SUCCESS:
		case TEST_QUESTIONNAIRE_SUCCESS:
			return state.merge({
				error: '',
				url: action.url,
			});

		case TEST_TELEHEALTH_ERROR:
		case TEST_INTAKE_NEW_ERROR:
		case TEST_ROI_ERROR:
		case TEST_ENROLLMENT_ERROR:
		case TEST_QUESTIONNAIRE_ERROR:
			return state.merge({
				error: action.error,
				url: '',
			});

		case FETCH_DOC_LOG:
			return state.merge({
				fetchingDocs: true,
				docsError: '',
			});
		case FETCH_DOC_LOG_SUCCESS:
			return state.merge({
				docs: action.data,
				fetchingDocs: false,
			});
		case FETCH_DOC_LOG_ERROR:
			return state.merge({
				docsError: action.error,
				fetchingDocs: false,
			});

		default:
			return state;
	}
}

export default adminConsoleReducer;
