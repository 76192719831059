import PropTypes from 'prop-types';

const TelehealthRequest = (props) => {
	const { isMobile, submitForm } = props;

	let containerStyle = {};

	if (isMobile) {
		containerStyle = {
			width: '90%',
			maxHeight: '74vh',
			overflowY: 'auto',
			overflowX: 'hidden',
		};
	}

	const containerClass = `container-fluid bg-white py-3 ${
		isMobile ? 'px-2 mx-auto' : 'mx-4'
	}`;

	return (
		<div className="row custom-bg-color mx-0">
			<div className={containerClass} style={containerStyle}>
				<div className="d-flex col-12 flex-column form-group">
					As a therapeutic outpatient program that requires a healthy
					environment, Mind Therapy Clinic and its staff have established the
					following virtual group standards to provide the conditions under
					which clients and staff can do the best work possible. Clients are
					expected to review and agree to uphold these, or, as importantly, to
					ask for help when unable to do so. To optimize experience for staff
					and clients in IOP groups the following standards are adopted:
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>CONFIDENTIALITY</h3>
					The names, faces, conversations you encounter in group must be
					confidential. &quot;Who you see here, what you hear here, stays
					here.&quot; You may share about yourself only. When attending a
					virtual group, please ensure that you are in a private place where
					confidentiality is maintained.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>LATE POLICY</h3>
					There is a grace period of 10 minutes in case of an emergency.
					However, being considerate of others’ time by being on time is how you
					can be a community member and support yourself and others. In order to
					uphold and maintain group standards, clients will not be admitted to a
					virtual group after the 10 minute grace period has passed. NOTE: If
					you are having technical difficulties that are preventing you from
					joining group on-time, please inform the group facilitator as soon as
					possible and we will do our best to accommodate.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>FOOD</h3>
					There is NO eating during group. Beverages are ok as long as clients
					are on time.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>PHONE</h3>
					There is NO cellphone use during group. To maintain group standards
					and format in a virtual setting, please refrain from multi-tasking or
					accessing other programs/games/texts while in group as to ensure your
					presence with us.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>SMOKING</h3>
					There is NO Smoking or Vaping or Chewing Tobacco allowed during
					virtual groups.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>SOBRIETY</h3>
					Supporting the abstinence needs of other clients is essential.
					Glorifying or romanticizing drug or alcohol use interferes with
					recovery and group health—please refrain. Attending any virtual group
					intoxicated will result in being asked to leave the group.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>VIDEO/AUDIO</h3>
					Please use headphones whenever possible for best audio quality. Please
					try to use your computer or laptop whenever possible, making sure your
					face fills the screen and you are not back-lit.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					<h3>VIRTUAL ETIQUETTE</h3>
					Please sit upright, at a desk or sitting in a chair at a table
					whenever possible. If you need to be in your bedroom for privacy, and
					need to sit on your bed, please sit up and refrain from laying down or
					under covers during group.
				</div>
				<div className="d-flex col-12 flex-column form-group">
					These group standards in addition to the behavioral guidelines are
					here to help us create a respectful, open and safe space to do our
					work and learn. If you feel like you are unable to adhere to these
					standards, please ask for help.
					<br />I have read and understand the information provided above.
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
				<button
					onClick={submitForm}
					type="button"
					className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
				>
					<span className="mr-0 pl-3 w-100">SUBMIT</span>
					<i className="fas fa-check align-self-center" />
				</button>
			</div>
		</div>
	);
};

TelehealthRequest.propTypes = {
	data: PropTypes.object,
};

export default TelehealthRequest;
