import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the dashboard state domain
 */

const selectDashboardDomain = (state) => state.get('dashboard', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Dashboard
 */

const makeSelectScrollable = () =>
	createSelector(selectDashboardDomain, (substate) =>
		returnJS(substate.get('scrollable')),
	);

const makeSelectDashboard = () =>
	createSelector(selectDashboardDomain, (substate) => returnJS(substate));

export default makeSelectDashboard;
export { selectDashboardDomain, makeSelectScrollable };
