import PropTypes from 'prop-types';

import React from 'react';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';
import { isProduction } from 'utils/constants';

/* eslint-disable react/prefer-stateless-function */
class Acknowledgements extends React.Component {
	nextProgress = (event) => {
		const { nextProgress } = this.props;
		const {
			cancellationPolicyChecked,
			returnedCheckChecked,
			delinquentAccountsChecked,
			limitaionsOfConfidentialityChecked,
			mobileMsgFaxEmailChecked,
			MessagesChecked,
			emergencyAccessChecked,
			consentForTreatmentChecked,
			electronicRecordChecked,
			billingChecked,
			infoRelatedTreatmentChecked,
			patientAgreementChecked,
			openPaymentChecked,
			goodFaithEstimateChecked,
		} = this.props;

		const data = {
			checks: {
				cancellationPolicyChecked,
				returnedCheckChecked,
				delinquentAccountsChecked,
				limitaionsOfConfidentialityChecked,
				mobileMsgFaxEmailChecked,
				MessagesChecked,
				emergencyAccessChecked,
				consentForTreatmentChecked,
				electronicRecordChecked,
				billingChecked,
				infoRelatedTreatmentChecked,
				patientAgreementChecked,
				openPaymentChecked,
				goodFaithEstimateChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	allChecked = () => {
		const {
			cancellationPolicyChecked,
			returnedCheckChecked,
			delinquentAccountsChecked,
			limitaionsOfConfidentialityChecked,
			mobileMsgFaxEmailChecked,
			MessagesChecked,
			emergencyAccessChecked,
			consentForTreatmentChecked,
			electronicRecordChecked,
			billingChecked,
			infoRelatedTreatmentChecked,
			patientAgreementChecked,
			openPaymentChecked,
			goodFaithEstimateChecked,
		} = this.props;

		if (!isProduction) return true;

		return (
			cancellationPolicyChecked &&
			returnedCheckChecked &&
			delinquentAccountsChecked &&
			limitaionsOfConfidentialityChecked &&
			mobileMsgFaxEmailChecked &&
			MessagesChecked &&
			emergencyAccessChecked &&
			consentForTreatmentChecked &&
			electronicRecordChecked &&
			billingChecked &&
			infoRelatedTreatmentChecked &&
			patientAgreementChecked &&
			openPaymentChecked &&
			goodFaithEstimateChecked
		);
	};

	render() {
		const {
			cancellationPolicyChecked,
			returnedCheckChecked,
			delinquentAccountsChecked,
			limitaionsOfConfidentialityChecked,
			mobileMsgFaxEmailChecked,
			MessagesChecked,
			emergencyAccessChecked,
			consentForTreatmentChecked,
			electronicRecordChecked,
			billingChecked,
			infoRelatedTreatmentChecked,
			patientAgreementChecked,
			openPaymentChecked,
			goodFaithEstimateChecked,
			handleAcceptance,
			prevProgress,
			isMobile,
		} = this.props;

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center text-center font-weight-bold mb-5 mt-5">
					Acknowledgements
				</h2>
				<div className="card d-flex flex-column p-4 bb-0">
					<h3>Cancellation Policy</h3>
					<span className="pt-1 pb-5">
						For OUTPATIENTS: Scheduled appointment times are reserved for you.
						All appointments are subject to full charge, whether missed,
						unattended or cancelled. This charge can be avoided by giving two
						(2) business day’s notice. In order to avoid being charged the full
						amount of the appointment you must call by 5 p.m. at least two (2)
						business days prior to the appointment day (i.e., if your
						appointment is anytime on Monday you must call to cancel by 5 p.m.
						on the prior Thursday). Medication management visits require one (1)
						business day’s notice. Group therapy is charged on a monthly basis,
						and the payment for the first month is due at the time of
						enrollment. Cancellation request during trial period for group
						therapy requires one (1) business day notice. One (1) week advance
						notice is required to cancel enrollment in any group. Any requests
						for leave of absence must be submitted in writing, signed and dated
						one (1) week prior to the first date of absence. Unexcused missed
						group sessions are not refunded. You acknowledge that insurance
						companies do not pay for cancellation fees and, and these charges
						are your responsibility. Repeated “no show” appointments could
						result in treatment ending for non-compliance.
						<br />
						<br />
						<br />
						For IOP/PHP: Once registered, IOP/PHP enrollment is continuous until
						discharged by your clinician from the program or you terminate
						services. The monthly program fee will automatically be charged at
						the beginning of each calendar month during which services will be
						provided, and we require one (1) week of notice for any departure
						from the program or cancellation of enrollment.{' '}
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="cancellationPolicyChecked"
						value={cancellationPolicyChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Returned Check</h3>
					<span className="pt-1 pb-5">
						There will be a $35.00 service charge applied to your account for
						all returned checks.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="returnedCheckChecked"
						value={returnedCheckChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Delinquent Accounts</h3>
					<span className="pt-1 pb-5">
						Should your account become 60 days delinquent, finance charges of
						one and one half percent (1.5%) per month may be added to your bill,
						services may be discontinued, your bill may be turned over to a
						collection agency and you will be responsible for payment of all
						legal and all other collection costs.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="delinquentAccountsChecked"
						value={delinquentAccountsChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Limitations of Confidentiality</h3>
					<span className="pt-1 pb-5">
						Information obtained by Mind Therapy Clinic relating to the client
						is strictly confidential. Mind Therapy Clinic clinicians share
						confidential mental health information only with other healthcare
						providers involved in the client’s treatment in order to provide
						integrated, comprehensive care. There are legal exceptions to when
						such information may be shared with third parties, such as (1) in
						medical emergencies; (2) if the client presents as a physical danger
						to self or others; (3) if child or elder abuse and/or neglect is
						suspected; or (4) if the client authorizes the release of the
						information with a signature. For more information on how health
						information may be used or disclosed by Mind Therapy Clinic, please
						refer to our Notice of Privacy Practices.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="limitaionsOfConfidentialityChecked"
						value={limitaionsOfConfidentialityChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Mobile Phones, Instant Messages, Faxes, Email, Video Chats</h3>
					<span className="pt-1 pb-5">
						Mobile phone, instant messages, video chats, and email communication
						may not be secure. Faxes can also be easily sent to erroneous
						numbers. If you are using a mobile phone while communicating with
						our office, you must be aware that we cannot ensure the
						confidentiality of the call. If you use email to communicate with
						Mind Therapy Clinic then we will assume that you have made an
						informed decision that you are taking the risk of such communication
						being intercepted. Please do not communicate an emergency by email
						or fax.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="mobileMsgFaxEmailChecked"
						value={mobileMsgFaxEmailChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Messages</h3>
					<span className="pt-1 pb-5">
						It may be necessary at times for our office to leave you a message
						at the phone number(s) you provide us. By supplying us with specific
						phone numbers, you authorize us to leave messages for you at those
						numbers.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="MessagesChecked"
						value={MessagesChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Emergency Access</h3>
					<span className="pt-1 pb-5">
						We try to service our clients whenever possible; however we are not
						a 24-hour facility. In case of an emergency, call 911 or go to the
						nearest emergency room.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="emergencyAccessChecked"
						value={emergencyAccessChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Consent For Treatment</h3>
					<span className="pt-1 pb-5">
						I authorize and request my practitioner to carry out psychological
						exams, treatment and/or diagnostic procedures, which now, or during
						the course of my treatment, become advisable. I understand the
						purpose of these procedures will be explained to me upon my request
						and that I am responsible for the costs of such exams and/or
						diagnostic procedures. I also understand that while the course of my
						treatment is designed to be helpful, my practitioner can make no
						guarantees about the outcome of my treatment. Further, the
						psychotherapeutic process can bring up uncomfortable feelings and
						reactions such as anxiety, sadness and anger. I understand that this
						is a normal response to working through unresolved life experiences
						and that these reactions will be worked on between my practitioner
						and me. I understand and acknowledge that from time to time I may
						have a phone or video session. I authorize these sessions.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="consentForTreatmentChecked"
						value={consentForTreatmentChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Electronic Record Keeping System</h3>
					<span className="pt-1 pb-5">
						Mind Therapy Clinic uses Electronic Medical Records (EMR) to
						securely maintain your health care information. Electronic Medical
						Record means an electronic record of health-related information
						that: includes my demographic and clinical health information, such
						as medical history and problem lists; and has the capacity to
						provide clinical decision support; to support physician entry and
						billing invoices; to capture and query information relevant to
						healthcare quality; and to communicate with me through patient
						portal about my care. I understand that my healthcare information is
						maintained in this way.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="electronicRecordChecked"
						value={electronicRecordChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Billing</h3>
					<span className="pt-1 pb-4">
						Itemized billing statements are available through a secure patient
						portal website, and my unique access is available when I activate
						the online account assigned to me. If I do not create an account, it
						is my responsibility to request the billing statement to be mailed
						to me. An invoice will be placed in my secure portal for any unpaid
						balance. If the invoice remains unpaid for 120 days, my account will
						be in default and auto referred to a collection agency. The balance
						of any account not paid within 60 days will begin to accrue interest
						at the rate of 1.5% per month or the maximum allowed by applicable
						law, whichever is lower.
					</span>
					<span className="pt-1 pb-5">
						If I do not agree with the billing provided in my billing statement,
						I understand that I have thirty (30) days from the date of the
						statement to report errors or objections. If there are problems with
						my account, it is my responsibility to contact Mind Therapy Clinic
						to address the problem or to discuss a workable solution. If a
						workable solution cannot be reached, I agree to the arbitration
						process administered by the American Health Lawyers Association
						(AHLA) Dispute Resolution Service and conducted pursuant to the AHLA
						Rules of Procedure for Arbitration. Judgment on the award may be
						entered and enforced in any court having jurisdiction. In the event
						of a legal action to enforce the terms of this Agreement, including
						collection of amounts payable or Mind Therapy becomes party to any
						legal proceedings relating to my treatment at Mind Therapy Clinic,
						Mind Therapy Clinic shall be entitled to payment from me or my
						Guarantor of its reasonable attorney’s fees and costs, including any
						collection costs.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="billingChecked"
						value={billingChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Use of Information Related to My Treatment</h3>
					<span className="pt-1 pb-5">
						I give permission to Mind Therapy Clinic and its business associates
						to use my information if it relates to my treatment.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="infoRelatedTreatmentChecked"
						value={infoRelatedTreatmentChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Patient Agreement</h3>
					<span className="pt-1 pb-5">
						I have been notified by Mind Therapy Clinic of my responsibility for
						cancellation policy/fees. I authorize release of information to my
						Primary Care Physician, psychotherapist, psychiatrist, other
						healthcare providers, institutions, and referral sources for the
						purpose of diagnosis, treatment, consultation and professional
						communication. I authorize the release of necessary information to a
						collection agency should that become necessary.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="patientAgreementChecked"
						value={patientAgreementChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 bt-0 bb-0">
					<h3>Open Payment Acknowledgement</h3>
					<span className="pt-1 pb-5">
						The Open Payments database is a federal tool used to search payments
						made by drug and device companies to physicians and teaching
						hospitals. It can be found at https://openpaymentsdata.cms.gov. I
						acknowledge that I have been informed that my healthcare provider
						may receive payments or other transfers of value from a medical
						device, pharmaceutical, or other healthcare industry company. I
						understand that this information will be publicly available on the
						Open Payments database.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="openPaymentChecked"
						value={openPaymentChecked}
						isRequired
					/>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 mb-4 bt-0">
					<h3>Good Faith Estimate</h3>
					<span className="pt-1 pb-5">
						I acknowledge that I have been provided with a good-faith estimate
						of the cost of healthcare services that I may receive. I understand
						that this estimate is not a guarantee of the final cost and that the
						actual cost may be different based on various factors. By signing
						this acknowledgment, I confirm that I understand the right to
						receive a “Good Faith Estimate” for mental health care. More
						information can be found at www.cms.gov/nosurprises.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="goodFaithEstimateChecked"
						value={goodFaithEstimateChecked}
						isRequired
					/>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!this.allChecked()}
							onClick={this.nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		);
	}
}

Acknowledgements.propTypes = {
	cancellationPolicyChecked: PropTypes.bool.isRequired,
	returnedCheckChecked: PropTypes.bool.isRequired,
	delinquentAccountsChecked: PropTypes.bool.isRequired,
	limitaionsOfConfidentialityChecked: PropTypes.bool.isRequired,
	mobileMsgFaxEmailChecked: PropTypes.bool.isRequired,
	MessagesChecked: PropTypes.bool.isRequired,
	emergencyAccessChecked: PropTypes.bool.isRequired,
	consentForTreatmentChecked: PropTypes.bool.isRequired,
	electronicRecordChecked: PropTypes.bool.isRequired,
	billingChecked: PropTypes.bool.isRequired,
	infoRelatedTreatmentChecked: PropTypes.bool.isRequired,
	patientAgreementChecked: PropTypes.bool.isRequired,
	openPaymentChecked: PropTypes.bool.isRequired,
	goodFaithEstimateChecked: PropTypes.bool.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default Acknowledgements;
