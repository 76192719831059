/**
 *
 * IAccept
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import CheckBox from 'react-animated-checkbox';

// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
class IAccept extends React.Component {
	render() {
		const { name, value, handleAcceptance, isRequired } = this.props;
		return (
			<div className="d-flex justify-content-end custom-control custom-checkbox">
				<CheckBox
					checked={value}
					checkBoxStyle={{
						checkedColor: '#00DB06',
						size: 30,
						unCheckedColor: '#b8b8b8',
					}}
					duration={400}
					onClick={() => {
						handleAcceptance(name);
					}}
				/>
				<h5 className="text-center ml-2 font-weight-bold">
					{isRequired ? 'I Accept*' : 'I Accept'}
				</h5>
			</div>
		);
	}
}

IAccept.propTypes = {
	handleAcceptance: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	isRequired: PropTypes.bool,
};

export default IAccept;
