import PropTypes from 'prop-types';

import React from 'react';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
class FinancialAgreement extends React.Component {
	nextProgress = (event) => {
		const { nextProgress, financialAgreementChecked } = this.props;
		const data = { checks: { financialAgreementChecked } };
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	render() {
		const {
			financialAgreementChecked,
			handleAcceptance,
			prevProgress,
			isMobile,
		} = this.props;

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mb-5 mt-5">
					Financial Agreement
				</h2>
				<div className="card d-flex flex-column p-4 mb-4">
					<span className="pt-5 pb-4">
						This Financial Agreement (the “Agreement”) is made by and between
						the undersigned Client, and if applicable, Guarantor (individually
						or collectively the “Responsible Party”) and Mark J. Schiller PC,
						DBA Mind Therapy Clinic (“Mind Therapy Clinic”). Responsible Party
						agrees that in consideration for Client’s receiving certain
						treatment services as described herein, Responsible Party assumes
						full financial responsibility for the charges relating to such
						services provided to the Client.
					</span>
					<span className="pt-1 pb-4">
						Responsible Party shall be responsible for any amounts due for any
						amenities or services Client receives from Mind Therapy Clinic.
						These charges are due at the time of service. Mind Therapy Clinic
						will bill Responsible Party directly for any balances due,
						including, but not limited to, charges unpaid or missed
						appointments. If Responsible Party fails to make payment on any
						balances due or make satisfactory payment arrangements with Mind
						Therapy Clinic for any balance due, Mind Therapy Clinic may turn
						Client’s account over to a collection agency or take legal actions
						pursuant to any breach of this Agreement. Any such collection
						efforts may necessitate that Mind Therapy Clinic, or its designee,
						request financial and credit information from various sources
						including, without limitation, credit reporting bureaus.
					</span>
					<span className="pt-1 pb-4">
						If you are enrolling in IOP/PHP, monthly fees are paid at the
						beginning of each month. For those using health insurance for
						payment, Responsible Party agrees that Mind Therapy Clinic will bill
						Client’s insurance provider for reimbursement for the IOP/PHP
						services described herein, and authorizes and requests that Client’s
						insurance company pay directly to Mind Therapy Clinic the amounts
						due for treatment or services provided to Client. It is Responsible
						Party’s responsibility to verify health insurance coverage prior to
						any services being provided to the Client. Responsible Party agrees
						that eligibility for coverage will be subject to the terms and
						conditions of the relevant insurance policy and carrier application
						of the terms thereof, and that neither Mind Therapy Clinic nor any
						of its staff or agents can make any assurances on the extent of such
						coverage, which shall be at the risk and responsibility of
						Responsible Party. Responsible Party agrees that correct and
						complete insurance and other information must be provided prior to
						or upon admission in order for Mind Therapy Clinic to facilitate any
						pre-authorization that may be required by the applicable health
						insurance company. If Mind Therapy Clinic is unable to verify
						eligibility of benefits or correct and complete insurance
						information is not provided by Responsible Party, Client will be
						treated as &quot;self-pay&quot;.
					</span>
					<span className="pt-1 pb-4">
						Any checks returned for non-payment will automatically be charged to
						Client’s account including a $35 service charge.
					</span>
					<span className="pt-1 pb-4">
						Other than any collection action, which may be pursued by Mind
						Therapy Clinic before any court with jurisdiction over Client and/or
						Guarantor, any dispute arising out of or relating to this Agreement,
						Client’s treatment by Mind Therapy Clinic or the subject matter
						hereof, including any dispute regarding the scope of this clause,
						will be resolved exclusively through arbitration administered by the
						American Health Lawyers Association Dispute Resolution Service and
						conducted pursuant to the AHLA Rules of Procedure for Arbitration.
						Judgment on the award may be entered and enforced in any court
						having jurisdiction. In the event Client, Guarantor or Mind Therapy
						Clinic initiates any legal action to enforce the terms of this
						Agreement, including collection of amounts payable hereunder, or
						Mind Therapy Clinic becomes party to any legal proceeding relating
						to Client’s treatment by Mind Therapy Clinic, Mind Therapy Clinic
						shall be entitled to payment from Responsible Party of its
						reasonable attorney’s fees and costs, including any collection
						costs.
					</span>
					<span className="pt-1 pb-4">
						To the greatest extent permitted by applicable law Responsible Party
						(a) agrees to indemnify, defend and hold harmless Mind Therapy
						Clinic and its agents, officers, shareholders contractors and
						employees (“Released Parties”) from and against any action arising
						out of or related to Client’s treatment by Mind Therapy Clinic and
						(b) waives, releases and discharges any of the Released Parties from
						any liabilities associated with Client’s treatment by Mind Therapy
						Clinic except to the extent of the sole gross negligence of any
						Released Party.
					</span>
					<span className="pt-1 pb-4">
						Responsible Party shall be liable for any loss, damage, destruction
						or theft to real or personal property caused by negligence or
						willful acts of Client. Property damage may include harm to
						vehicles, furnishings, fixtures or any other possession of Mind
						Therapy Clinic. The amount of recovery for property damage may be
						established by evidence of replacement value, cost of repairs, or
						loss of use until repaired or replaced.
					</span>
					<span className="pt-1 pb-4">
						The terms and conditions of this Agreement shall be jointly and
						severally binding upon Guarantor, Client, and their respective
						assigns, successors in interest, heirs, devisees and personal
						representatives. This Agreement shall inure to the benefit of the
						parties and their respective successors and assigns. If any term or
						provision of this Agreement is determined to be invalid, illegal or
						unenforceable, the performance of the offending term or provision
						shall be excused as if it had never been incorporated into this
						Agreement, and the remaining part of this Agreement shall not be
						affected thereby and shall continue in full force and effect, and
						this Agreement shall be reformed to implement as nearly as
						practicable the intent hereof.
					</span>
					<span className="pt-1 pb-4">
						GUARANTOR, CLIENT AND MIND THERAPY CLINIC EACH EXPRESSLY ACKNOWLEDGE
						AND AGREE THAT THIS AGREEMENT, INCLUDING ALL EXHIBITS ATTACHED
						HERETO, IS A COMPLETE STATEMENT OF THE AGREEMENT OF THE PARTIES WITH
						RESPECT TO THE SUBJECT MATTER HEREOF AND SUPERSEDES ANY PRIOR OR
						CONTEMPORANEOUS REPRESENTATIONS. Any agreement hereafter made shall
						be ineffective to modify, supplement or discharge the terms of this
						Agreement, in whole or in part, unless such agreement is in writing
						and signed by the party against whom enforcement of the modification
						is sought.
					</span>
					<span className="pt-1 pb-5">
						By signing, this Financial Responsibility Agreement, the persons
						whose signatures are affixed hereto acknowledge that they, and each
						of them, have read and fully understand the terms and conditions set
						forth herein. Each person signing this Agreement does so intending
						to create a legally binding and enforceable contract with financial
						and other binding terms and obligations, and in the case of
						Guarantor and Client, are jointly and severally binding on each of
						them.{' '}
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="financialAgreementChecked"
						value={financialAgreementChecked}
						isRequired
					/>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!financialAgreementChecked}
							onClick={this.nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		);
	}
}

FinancialAgreement.propTypes = {
	financialAgreementChecked: PropTypes.bool.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default FinancialAgreement;
