import axios from 'axios';

const headers = {
	'Content-Type': 'application/json',
};

const api = axios.create({
	headers,
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 30000,
});

export const forgotUpdatePasswordApi = (data) =>
	api.post('/user/newPassword', data, {
		headers: {
			'x-access-token': data.token,
		},
	});
