import React, { useEffect, useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './index.css';

const TagFormModal = (props) => {
	const { isOpen, toggle, form, submit, isUpdating } = props;
	const [type, setType] = useState(form ? form.documentType : 'OTHER');
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [sentUpdate, setSentUpdate] = useState(false);

	useEffect(() => {
		if (sentUpdate && !isUpdating) {
			setSentUpdate(false);
			toggle();
		}
	}, [isUpdating]);

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleChange = (val) => {
		setType(val);
	};

	const handleSubmit = () => {
		setSentUpdate(true);
		submit(type);
	};

	const formTypeOptions = [
		{ label: 'Intake', value: 'CLIENT_INTAKE' },
		{ label: 'Outpatient', value: 'OUTPATIENT_AGREEMENT' },
		{ label: 'Other', value: 'OTHER' },
	];

	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal-dialog-centered"
				backdrop="static"
				style={{
					maxWidth: 500,
				}}
			>
				<ModalHeader toggle={toggle} className="px-4 border-0 container-fluid">
					<div className="">Tag Form - {form?.filename}</div>
				</ModalHeader>
				<ModalBody className="px-4 pt-1">
					<div className="pb-3 px-2">
						<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle
								className="tag-select w-100 border p-2 text-left"
								caret
							>
								{formTypeOptions.find((x) => x.value === type).label}
							</DropdownToggle>
							<DropdownMenu
								className="w-100"
								style={{
									boxShadow: 'none',
									border: '1px solid grey',
									borderTop: 'none',
								}}
							>
								{formTypeOptions.map(
									(o) =>
										o.value !== type && (
											<DropdownItem
												key={o.value}
												onClick={() => handleChange(o.value)}
											>
												{o.label}
											</DropdownItem>
										),
								)}
							</DropdownMenu>
						</Dropdown>
					</div>
					<div className="w-100 justify-content-center text-center">
						<button
							className="btn btn-primary bg-white link-color border font-14 mx-3"
							style={{ height: 45, minWidth: 140 }}
							onClick={toggle}
						>
							CANCEL
						</button>
						<button
							disabled={isUpdating}
							className="btn btn-primary btn-color font-14 mx-3"
							style={{ height: 45, minWidth: 140 }}
							onClick={handleSubmit}
						>
							SAVE
						</button>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default TagFormModal;
