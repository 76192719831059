import { useField } from 'formik';

import React from 'react';
import _PhoneInput from 'react-phone-number-input/input';

import classnames from 'classnames';

import './Input.css';
import Label from './Label';

const MAX_LENGTH_WITH_FORMATTING = 14;

export default function PhoneInput({ label, required, ...rest }) {
	const [field, meta] = useField(rest);
	const hasError = meta.touched && meta.error;

	function handleChange(value) {
		field.onChange({ target: { name: field.name, value } });
	}

	return (
		<div className="form-group custom-input">
			<Label
				label={label}
				hasError={hasError}
				id={rest.id}
				required={required}
			/>
			<_PhoneInput
				className={classnames('form-control', { invalid: hasError })}
				country="US"
				maxLength={MAX_LENGTH_WITH_FORMATTING}
				{...rest}
				{...field}
				onChange={handleChange}
			/>
			{hasError && <div className="form-error">{meta.error}</div>}
		</div>
	);
}
