import history from 'utils/history';

import { isEqual } from 'lodash';

import moment from 'moment';

/**
 *
 * PatientRegister
 *
 */
import PropTypes from 'prop-types';

import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/iconfont.css';
import 'rc-steps/assets/index.css';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import CommonHeader from 'components/CommonHeader';
import Footer from 'components/Footer';
import PatientAddress from 'components/PatientAddress';
import PatientContact from 'components/PatientContact';
import PatientDetails from 'components/PatientDetails';
import PatientEmergencyContacts from 'components/PatientEmergencyContacts';
import PatientOtherDetails from 'components/PatientOtherDetails';
import PatientRegistrationType from 'components/PatientRegistrationType';
import {
	fetchCities,
	fetchStates,
	fetchZipcodes,
} from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectCities,
	makeSelectIsMobile,
	makeSelectStates,
	makeSelectZipcodes,
} from 'containers/App/selectors';
import makeSelectGeo from 'containers/Geo/selectors';
import { setPrevPath } from 'pages/intake/actions';
import makeSelectPatientIntake from 'pages/intake/selectors';

import { fetchData, patientRegistration } from './actions';
import './index.css';
import makeSelectPatientRegister from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class PatientRegister extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
			maxStep: 5,
			patientDetails: {
				registrationType: 'adult',
				name: '',
				preferredName: '',
				childName: '',
				maritalStatus: 'single',
				phone: '',
				homePhone: '',
				email: '',
				dob: '',
				sex: 'male',
				genderIdentity: '',
				sexualOrientation: '',
				pronouns: '',
			},
			firstLoad: true,
			fetchingData: false,
		};
	}

	componentDidMount() {
		const { patientRegisterFetchData, auth } = this.props;
		const { id, name, phone, email } = auth.loggedInUser;

		this.setState({ name, phone, email, fetchingData: true });

		patientRegisterFetchData({ id });
		this.props.doFetchStates();
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { patientIntakeFields, doSetPrevPath, fetchingData } = this.props;

		doSetPrevPath('');
		if (patientIntakeFields.path === 'Intake') {
			this.setState({ currentStep: 3 });
		}

		if (fetchingData && nextProps.fetchSuccess) {
			this.setState({ fetchingData: false });
		}
	};

	shouldComponentUpdate = (nextProps) => {
		const { firstLoad } = this.state;
		const { patientRegister, auth, patientIntakeFields } = nextProps;
		if (
			patientRegister &&
			patientRegister.details &&
			Object.keys(patientRegister.details).length &&
			!isEqual(this.state.patientDetails, patientRegister.details)
		) {
			this.setState({
				patientDetails: nextProps.patientRegister.details,
			});
		} else if (
			auth.loggedInUser &&
			auth.loggedInUser.id &&
			!this.state.patientDetails.name &&
			!this.state.patientDetails.phone &&
			!this.state.patientDetails.email
		) {
			const { patientDetails } = this.state;
			patientDetails.name = auth.loggedInUser.name;
			patientDetails.phone = auth.loggedInUser.phone;
			patientDetails.email = auth.loggedInUser.email;
			this.setState({
				patientDetails,
			});
		}

		if (
			firstLoad &&
			(patientRegister.registrationProgress > 0 ||
				patientIntakeFields.registrationProgress > 0)
		) {
			let registrationProgress, intakeProgress;

			if (patientRegister.registrationProgress > 0) {
				registrationProgress = patientRegister.registrationProgress;
				intakeProgress = patientRegister.intakeProgress;

				if (patientIntakeFields.intakeProgress < 0) {
					intakeProgress = patientIntakeFields.intakeProgress;
				}
			} else {
				registrationProgress = patientIntakeFields.registrationProgress;
				intakeProgress = patientIntakeFields.intakeProgress;
			}

			const { maxStep } = this.state;

			if (intakeProgress > 0 && registrationProgress >= maxStep) {
				history.push('/patient/intake');
			} else {
				if (registrationProgress > maxStep) registrationProgress = maxStep; // Minus two because of telehealth

				this.setState({ firstLoad: false, currentStep: registrationProgress });
				window.scrollTo(0, 0);
			}
		}
		return true;
	};

	nextStep = (dataExe) => {
		const {
			currentStep,
			maxStep,
			patientDetails: { registrationType },
		} = this.state;
		const {
			patientRegisterForm,
			auth: {
				loggedInUser: { id },
			},
		} = this.props;
		const newCurrentStep = currentStep + 1;
		if (currentStep === 1) {
			const detailsObj = {
				details: {
					...this.state.patientDetails,
				},
				id,
				q: 'details',
				registrationProgress: newCurrentStep,
			};
			if (Object.keys(this.props.patientRegister.details).length) {
				detailsObj.details.id = id;
			}
			patientRegisterForm(detailsObj);
		} else if (currentStep > 1) {
			dataExe.registrationProgress = newCurrentStep;
			patientRegisterForm(dataExe);
		}
		if (newCurrentStep > maxStep) {
			history.push(`/patient/intake?type=${registrationType}`);
		} else {
			this.setState({ currentStep: newCurrentStep });
		}
	};

	prevStep = () => {
		const { currentStep } = this.state;
		this.setState({ currentStep: currentStep - 1 });
	};

	handleGeoChange = (type, selectedItem) => {
		const { doFetchCities, doFetchZipcodes } = this.props;
		switch (type) {
			case 'state':
				doFetchCities(selectedItem.value, selectedItem.label);
				break;

			case 'city':
				doFetchZipcodes(selectedItem.value);
				break;

			default:
				break;
		}
	};

	handleChange = (component) => (event) => {
		const { target } = event;

		if (event.preventDefault) event.preventDefault();

		const { name, value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate

		const componentState = this.state[component];
		componentState[name] = value;

		this.setState({ [component]: componentState });
	};

	handleDateChange = (component, id) => (date) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[component];

		let dateFormat;
		if (date) {
			dateFormat = moment(date).format('YYYY-MM-DD');
		}
		componentState[id] = dateFormat;

		this.setState({ [component]: componentState });
	};

	handleNumberChange = (id, values) => {
		const { patientDetails } = this.state;

		patientDetails[id] = values.value;
		this.setState({ patientDetails });
	};

	render() {
		const { currentStep } = this.state;
		const { loggedInUser } = this.props.auth;
		const { patientRegister, geo, states, cities, zipcodes, isMobile } =
			this.props;
		const formSteps = [];

		const headerHeight = isMobile ? 60 : 100;

		formSteps.push(
			<PatientRegistrationType
				nextStep={this.nextStep}
				data={this.state.patientDetails}
				handleChange={this.handleChange}
				isMobile={isMobile}
			/>,
		);

		formSteps.push(
			<PatientDetails
				prevStep={this.prevStep}
				nextStep={this.nextStep}
				data={this.state.patientDetails}
				handleChange={this.handleChange}
				handleDateChange={this.handleDateChange}
				handleNumberChange={this.handleNumberChange}
				isMobile={isMobile}
			/>,
		);

		formSteps.push(
			<PatientContact
				auth={loggedInUser}
				previousStep={this.prevStep}
				step={this.nextStep}
				data={this.state.patientDetails}
				handleChange={this.handleChange}
				handleNumberChange={this.handleNumberChange}
				isMobile={isMobile}
			/>,
		);

		formSteps.push(
			<PatientAddress
				patientData={patientRegister}
				step={this.nextStep}
				auth={loggedInUser}
				geo={geo}
				states={states}
				cities={cities}
				zipcodes={zipcodes}
				previousStep={this.prevStep}
				handleGeoChange={this.handleGeoChange}
				validations
				handleNumberChange={this.handleNumberChange}
				data={this.state.patientDetails}
				isMobile={isMobile}
			/>,
		);

		formSteps.push(
			<PatientOtherDetails
				patientData={patientRegister}
				step={this.nextStep}
				auth={loggedInUser}
				previousStep={this.prevStep}
				isMobile={isMobile}
			/>,
		);

		formSteps.push(
			<PatientEmergencyContacts
				patientData={patientRegister}
				step={this.nextStep}
				auth={loggedInUser}
				previousStep={this.prevStep}
				isMobile={isMobile}
			/>,
		);

		return (
			<div>
				<CommonHeader
					routeProps={this.props}
					user={loggedInUser}
					name="Patient Registration"
					height={headerHeight}
					isMobile={isMobile}
				/>
				<div
					className={`container container-register ${
						isMobile && 'mobile'
					} d-flex flex-column h-100`}
					style={{ minHeight: window.innerHeight - headerHeight - 60 }}
				>
					<h2 className="align-self-center font-weight-bold mb-5 mt-5">
						Patient Registration
					</h2>
					<Steps
						className="mb-3"
						labelPlacement="vertical"
						current={currentStep}
					>
						<Step title="Registration Type" />
						<Step title="Patient Details" />
						<Step title="Patient Contact" />
						<Step title="Patient Address" />
						<Step title="Other Details" />
						<Step title="Emergency Contacts" />
					</Steps>
					<div className="card d-flex flex-column p-4 mb-4">
						{formSteps[currentStep]}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

PatientRegister.propTypes = {
	patientRegisterForm: PropTypes.func.isRequired,
	patientRegister: PropTypes.object.isRequired,
	patientIntakeFields: PropTypes.object.isRequired,
	patientRegisterFetchData: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	geo: PropTypes.object.isRequired,
	doSetPrevPath: PropTypes.func.isRequired,
	doFetchStates: PropTypes.func.isRequired,
	doFetchCities: PropTypes.func.isRequired,
	doFetchZipcodes: PropTypes.func.isRequired,
	states: PropTypes.array,
	cities: PropTypes.object,
	zipcodes: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	patientRegister: makeSelectPatientRegister(),
	patientIntakeFields: makeSelectPatientIntake(),
	auth: makeSelectAuth(),
	geo: makeSelectGeo(),
	states: makeSelectStates(),
	cities: makeSelectCities(),
	zipcodes: makeSelectZipcodes(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		patientRegisterForm: (data) => dispatch(patientRegistration(data)),
		patientRegisterFetchData: (data) => dispatch(fetchData(data)),
		doSetPrevPath: (data) => dispatch(setPrevPath(data)),
		doFetchStates: () => dispatch(fetchStates()),
		doFetchCities: (stateCode, stateName) =>
			dispatch(fetchCities(stateCode, stateName)),
		doFetchZipcodes: (cityName) => dispatch(fetchZipcodes(cityName)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientRegister);
