import PropTypes from 'prop-types';

import { getAttendanceColor } from 'utils/helpers';

const Circle = (props) => {
	const { handleClick, letter, type, id, style } = props;
	return (
		<div
			id={id}
			style={{
				borderRadius: '50%',
				backgroundColor: getAttendanceColor(letter),
				color: 'white',
				height: type === 'main' ? '40px' : '20px',
				width: type === 'main' ? '40px' : '20px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				opacity: type === 'main' ? 1 : 0.8,
				...style,
			}}
			onClick={() => handleClick(letter)}
		>
			<span style={{ fontSize: type === 'main' ? '20px' : '10px' }}>
				{letter}
			</span>
		</div>
	);
};

Circle.propTypes = {
	handleClick: PropTypes.func,
	letter: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
};

export default Circle;
