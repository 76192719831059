import PropTypes from 'prop-types';

import React from 'react';
import {
	Col,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';

import ActionButton from 'atoms/ActionButton';
import Pagination from 'atoms/Pagination';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import deleteImage from 'svgs/delete';
import sendImage from 'svgs/send';
import { filterByLetter } from 'utils/helpers';

import './index.css';

class FormShare extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			activeTab: '1',
			receiver: '',
			searchFilter: '',
			page: 0,
			cliniciansToShow: 5,
		};
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { receiver } = this.state;

		this.setState({ receiver });
	};

	render() {
		const { hide, title, clinicians, assignClinician, currentClinicianId } =
			this.props;
		const { searchFilter, page, cliniciansToShow } = this.state;

		let filteredClinicians = filterByLetter(clinicians, 'name', searchFilter);
		let currentClinician;
		if (currentClinicianId) {
			filteredClinicians = filteredClinicians.filter(
				(x) => x.id !== currentClinicianId,
			);
			currentClinician = clinicians.find((x) => x.id === currentClinicianId);
		}

		const start = page * cliniciansToShow;
		const end = Math.min(start + cliniciansToShow, filteredClinicians.length);

		filteredClinicians = filteredClinicians.slice(start, end);

		return (
			<div>
				<Modal isOpen={this.state.modal} toggle={hide}>
					<ModalHeader toggle={hide}>{title}</ModalHeader>
					<ModalBody className="p-0">
						<div className="container-fluid">
							<div>
								<TabContent activeTab={this.state.activeTab}>
									<TabPane tabId="1">
										<Row>
											<Col sm="12">
												<form
													onSubmit={this.handleSubmit}
													className="search col-12 p-0"
												></form>
												<div>
													<div className="border py-1 mb-3 row mx-0 mt-2">
														<div className="col-1 d-flex justify-content-end ali mt-1 pr-0">
															<i className="fas fa-search custom-search-text font-18" />
														</div>
														<input
															className="col"
															name="searchFilter"
															placeholder="Search for a Clinician..."
															value={searchFilter}
															onChange={(e) =>
																this.setState({
																	[e.target.name]: e.target.value,
																})
															}
														/>
													</div>
													{filteredClinicians.map((value) => (
														<div key={value.name} className="mb-3">
															<div className="container d-flex justify-content-between">
																<ImageDetailsCell
																	src={value.profilePicture}
																	heading={value.name}
																	primarySubHeading={value.therapistType}
																/>
																<div className="p-0 align-self-center">
																	<ActionButton
																		onClick={() => assignClinician(value)}
																		image={sendImage}
																		alt="Assign Clinician"
																		fillColor="var(--main-color)"
																	/>
																</div>
															</div>
															<hr className="mb-0" />
														</div>
													))}
													{clinicians.length > cliniciansToShow && (
														<div className="mb-2">
															<Pagination
																setPage={(page) => this.setState({ page })}
																page={page}
																data={clinicians}
																start={start}
																end={end}
																dataPerPage={cliniciansToShow}
															/>
														</div>
													)}
													{currentClinician && (
														<div className="mb-3">
															<p className="font-14">Current Clinician</p>
															<div className="container d-flex justify-content-between">
																<ImageDetailsCell
																	src={currentClinician.profilePicture}
																	heading={currentClinician.name}
																	primarySubHeading={
																		currentClinician.therapistType
																	}
																/>
																<div className="p-0 align-self-center">
																	<ActionButton
																		onClick={() => assignClinician({ id: 0 })}
																		image={deleteImage}
																		alt="Remove Clinician"
																		hoverColor="var(--main-alert)"
																	/>
																</div>
															</div>
															<hr className="mb-0" />
														</div>
													)}
												</div>
											</Col>
										</Row>
									</TabPane>
								</TabContent>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

FormShare.propTypes = {
	hide: PropTypes.func,
	type: PropTypes.string,
	title: PropTypes.string,
	clinicians: PropTypes.array,
	assignClinician: PropTypes.func,
};

export default FormShare;
