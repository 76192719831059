/*
 *
 * Requests reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CLEAR_URL,
	DEFAULT_ACTION,
	FETCH_PHARMACY,
	FETCH_PHARMACY_ERROR,
	FETCH_PHARMACY_SUCCESS,
	FETCH_PROGRAMS,
	FETCH_PROGRAMS_ERROR,
	FETCH_PROGRAMS_SUCCESS,
	FETCH_REQUEST,
	FETCH_REQUEST_ERROR,
	FETCH_REQUEST_SUCCESS,
	LOAD_PATIENT_GROUP_REQUESTS,
	LOAD_PATIENT_GROUP_REQUESTS_ERROR,
	LOAD_PATIENT_GROUP_REQUESTS_SUCCESS,
	LOAD_PATIENT_ROI_DATA,
	LOAD_PATIENT_ROI_DATA_ERROR,
	LOAD_PATIENT_ROI_DATA_SUCCESS,
	LOAD_PATIENT_RX_REQUESTS,
	LOAD_PATIENT_RX_REQUESTS_ERROR,
	LOAD_PATIENT_RX_REQUESTS_SUCCESS,
	REQUEST_TELEHEALTH,
	REQUEST_TELEHEALTH_ERROR,
	REQUEST_TELEHEALTH_SUCCESS,
	SIGN_DOC,
	SIGN_DOC_ERROR,
	SIGN_DOC_SUCCESS,
	SUBMIT_PATIENT_ROI_DATA,
	SUBMIT_PATIENT_ROI_DATA_ERROR,
	SUBMIT_PATIENT_ROI_DATA_SUCCESS,
	UPDATE_PATIENT_RX_REQUEST,
} from './constants';

export const initialState = fromJS({
	programs: [],
	pharmacy: [],
	groupRequests: [],
	rxRequests: [],
	roiData: {},
	isFetchingPatientGroupReq: false,
	isFetchingPatientRXReq: false,
	url: '',
	isFetching: false,
});

const updateLocalRequest = (state, request) => {
	state.set('isUpdatingRequest', false);

	let requests, newRequests;
	requests = state.get('rxRequests').toJS();

	newRequests = requests.map((r) => {
		if (r.requestId === request.requestId) {
			return {
				...r,
				...request,
				requestState: request.state,
				reasonForDenial: request.payload?.reasonForDenial,
			};
		} else {
			return r;
		}
	});

	return state.set('rxRequests', fromJS(newRequests));
};

function requestsReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case FETCH_PROGRAMS:
			return state.set('isFetching', true);
		case FETCH_PROGRAMS_SUCCESS:
			return state.set('programs', action.data);
		case FETCH_PROGRAMS_ERROR:
			return state.set('error', action.err);

		case FETCH_REQUEST:
			return state.set('programs', []);
		case FETCH_REQUEST_SUCCESS:
			if (action.data.req.type === 'ROI' || action.data.req.type === 'RX') {
				return state;
			}
			/* eslint-disable no-case-declarations */
			const newRequests = state
				.get('groupRequests')
				.toJS()
				.map((r) => {
					if (r.requestId === action.data.req.id) {
						return {
							...r,
							requestState: action.data.req.state,
						};
					} else {
						return r;
					}
				});

			return state.set('groupRequests', fromJS(newRequests));
		case FETCH_REQUEST_ERROR:
			return state.set('error', action.err);

		case UPDATE_PATIENT_RX_REQUEST:
			return updateLocalRequest(state, action.data);

		case LOAD_PATIENT_RX_REQUESTS:
			return state.merge({
				rxRequests: [],
				isFetchingPatientRXReq: true,
			});
		case LOAD_PATIENT_RX_REQUESTS_SUCCESS:
			return state.merge({
				rxRequests: action.data,
				isFetchingPatientRXReq: false,
			});
		case LOAD_PATIENT_RX_REQUESTS_ERROR:
			return state.merge({
				error: action.err,
				isFetchingPatientRXReq: false,
			});

		case LOAD_PATIENT_GROUP_REQUESTS:
			return state.merge({
				groupRequests: [],
				isFetchingPatientGroupReq: true,
			});
		case LOAD_PATIENT_GROUP_REQUESTS_SUCCESS:
			return state.merge({
				groupRequests: action.data,
				isFetchingPatientGroupReq: false,
			});
		case LOAD_PATIENT_GROUP_REQUESTS_ERROR:
			return state.merge({
				error: action.err,
				isFetchingPatientGroupReq: false,
			});

		case FETCH_PHARMACY:
			return state.set('pharmacy', []);
		case FETCH_PHARMACY_SUCCESS:
			return state.set('pharmacy', action.data);
		case FETCH_PHARMACY_ERROR:
			return state.set('error', action.err);

		case LOAD_PATIENT_ROI_DATA:
			return state.set('roiData', {});
		case LOAD_PATIENT_ROI_DATA_SUCCESS:
			return state.set('roiData', action.data);
		case LOAD_PATIENT_ROI_DATA_ERROR:
			return state.set('error', action.err);

		case SUBMIT_PATIENT_ROI_DATA:
			return state.set('url', '');
		case SUBMIT_PATIENT_ROI_DATA_SUCCESS:
			return state.set('url', action.data.url);
		case SUBMIT_PATIENT_ROI_DATA_ERROR:
			return state.set('error', action.err);

		case REQUEST_TELEHEALTH:
			return state.set('url', '');
		case REQUEST_TELEHEALTH_SUCCESS:
			return state.set('url', action.data.url);
		case REQUEST_TELEHEALTH_ERROR:
			return state.set('error', action.err);

		case SIGN_DOC:
			return state.set('url', '');
		case SIGN_DOC_SUCCESS:
			return state.set('url', action.data.url);
		case SIGN_DOC_ERROR:
			return state.set('error', action.err);

		case CLEAR_URL:
			return state.set('url', '');
		default:
			return state;
	}
}

export default requestsReducer;
