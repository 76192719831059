import { default as styled } from 'styled-components';

const ClientContainer = styled.div`
	:hover {
		background: #f7f7f7;
	}
`;

const ProgramContainer = styled.div`
	:hover {
		background: #f7f7f7;
	}
`;

export { ProgramContainer, ClientContainer };
