import PropTypes from 'prop-types';

/**
 *
 * <TextCell />, a functional component to display text with styling.
 */
const TextCell = (props) => (
	<div>
		<div className=" text-dark font-13" style={props.style}>
			{props.title}
		</div>
		<div className=" text-cust-grey font-11">{props.primaryHeading}</div>
		<div className="text-cust-grey font-11">{props.secondaryHeading}</div>
	</div>
);

TextCell.propTypes = {
	// title to display
	title: PropTypes.string,
	primaryHeading: PropTypes.string,
	secondaryHeading: PropTypes.string,
	// Custom style for <TextCell/>
	style: PropTypes.objectOf(PropTypes.string),
};

export default TextCell;
