/*
 *
 * PatientIntake actions
 *
 */
import {
	CHECK_ONBOARDING,
	DOC_CREATE,
	DOC_CREATE_SUCCESS,
	DOC_SIGN,
	DOC_SIGN_SUCCESS,
	ERROR,
	FETCH_DATA,
	PATIENT_INTAKE,
	SET_PREV_PATH,
	SUCCESS,
	SUCCESS_CHECK_ONBOARDING,
} from './constants';

export function patientIntake(data, cb) {
	return {
		type: PATIENT_INTAKE,
		data,
		cb,
	};
}
export function success(response) {
	return {
		type: SUCCESS,
		data: response,
	};
}
export function error(err) {
	return {
		type: ERROR,
		data: err,
	};
}
export function fetchData(data) {
	return {
		type: FETCH_DATA,
		data,
	};
}
export function docSign() {
	return {
		type: DOC_SIGN,
	};
}
export function docSignSuccess(data) {
	return {
		type: DOC_SIGN_SUCCESS,
		data,
	};
}
export function docCreate() {
	return {
		type: DOC_CREATE,
	};
}
export function docCreateSuccess(data) {
	return {
		type: DOC_CREATE_SUCCESS,
		data,
	};
}
export function checkOnboading() {
	return {
		type: CHECK_ONBOARDING,
	};
}
export function successCheckOnboading(data) {
	return {
		type: SUCCESS_CHECK_ONBOARDING,
		data,
	};
}
export function setPrevPath(data) {
	return {
		type: SET_PREV_PATH,
		data,
	};
}
