/*
 *
 * Attendance constants
 *
 */

export const DEFAULT_ACTION = 'src/Attendance/DEFAULT_ACTION';

export const GET_ATTENDANCE_SUCCESS = 'src/Attendance/GET_ATTENDANCE_SUCCESS';
export const GET_ATTENDANCE_ERROR = 'src/Attendance/GET_ATTENDANCE_ERROR';
export const GET_ATTENDANCE = 'src/Attendance/GET_ATTENDANCE';

export const SAVE_ATTENDANCE = 'src/Attendance/SAVE_ATTENDANCE';
export const SAVE_ATTENDANCE_SUCCESS = 'src/Attendance/SAVE_ATTENDANCE_SUCCESS';
export const SAVE_ATTENDANCE_ERROR = 'src/Attendance/SAVE_ATTENDANCE_ERROR';

export const GET_SESSIONS = 'src/Attendance/GET_SESSIONS';
export const GET_SESSIONS_SUCCESS = 'src/Attendance/GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_ERROR = 'src/Attendance/GET_SESSIONS_ERROR';

export const DELETE_SESSIONS = 'src/Attendance/DELETE_SESSIONS';
export const DELETE_SESSIONS_SUCCESS = 'src/Attendance/DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_ERROR = 'src/Attendance/DELETE_SESSIONS_ERROR';

export const EXTEND_SESSIONS = 'src/Attendance/EXTEND_SESSIONS';
export const EXTEND_SESSIONS_SUCCESS = 'src/Attendance/EXTEND_SESSIONS_SUCCESS';
export const EXTEND_SESSIONS_ERROR = 'src/Attendance/EXTEND_SESSIONS_ERROR';

export const LOAD_PROGRAMS = 'src/Attendance/LOAD_PROGRAMS';
export const LOAD_PROGRAMS_SUCCESS = 'src/Attendance/LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_ERROR = 'src/Attendance/LOAD_PROGRAMS_ERROR';
