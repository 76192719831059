import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import CustomDatePicker from 'atoms/CustomDatePicker';
import useIsMobile from 'hooks/isMobile.ts';

import './index.css';

/**
 *
 * <FilterDatePicker />, a functional component for DatePicker
 */

const FilterDatePicker = (props) => {
	const {
		name,
		label,
		className,
		from,
		to,
		minDate,
		maxDate,
		handleDateChange,
	} = props;

	const handleChange = (type) => (value) => {
		handleDateChange(type, name, value);
	};

	const width = 130;
	const isMobile = useIsMobile();

	if (isMobile) {
		return (
			<div className="pb-2">
				<div className="text-muted m-0 font-13 pb-2">{label}</div>
				<div className="row mt-2">
					<div className="col-auto pr-0" style={{ width }}>
						<CustomDatePicker
							customClass="border w-100 font-14"
							selected={from}
							minDate={moment(minDate).toDate()}
							maxDate={moment(maxDate).toDate()}
							handleDateChange={handleChange('from')}
							dateFormat="MM-dd-yyyy"
							placeholder="From"
							popperPlacement="bottom-start"
							isClearable
						/>
					</div>
					<div className="col-auto">-</div>
					<div className="col-auto pl-0" style={{ width }}>
						<CustomDatePicker
							customClass="border w-100 font-14"
							selected={to}
							minDate={moment(minDate).toDate()}
							maxDate={moment(maxDate).toDate()}
							handleDateChange={handleChange('to')}
							dateFormat="MM-dd-yyyy"
							placeholder="To"
							popperPlacement="bottom-end"
							isClearable
						/>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={className}>
				<div className="text-muted m-0 font-9">{label}</div>
				<div className="row mt-2">
					<div className="col-auto pr-0" style={{ width }}>
						<CustomDatePicker
							customClass="border w-100 font-14"
							selected={from}
							minDate={moment(minDate).toDate()}
							maxDate={moment(maxDate).toDate()}
							handleDateChange={handleChange('from')}
							dateFormat="MM-dd-yyyy"
							placeholder="From"
							popperPlacement="bottom-start"
							isClearable
						/>
					</div>
					<div className="col-auto">-</div>
					<div className="col-auto pl-0" style={{ width }}>
						<CustomDatePicker
							customClass="border w-100 font-14"
							selected={to}
							minDate={moment(minDate).toDate()}
							maxDate={moment(maxDate).toDate()}
							handleDateChange={handleChange('to')}
							dateFormat="MM-dd-yyyy"
							placeholder="To"
							popperPlacement="bottom-end"
							isClearable
						/>
					</div>
				</div>
			</div>
		);
	}
};

FilterDatePicker.propTypes = {
	handleDateChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
};

export default FilterDatePicker;
