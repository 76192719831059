/*
 *
 * Geo actions
 *
 */
import {
	ERROR,
	FETCH_CITIES,
	FETCH_STATES,
	FETCH_ZIPCODES,
	SET_ZIPCODE,
	SUCCESS,
	UPDATE_CITIES,
	UPDATE_STATES,
	UPDATE_ZIPCODES,
} from './constants';

export function fetchStates() {
	return {
		type: FETCH_STATES,
	};
}

export function fetchCities(stateCode, stateName, keyName) {
	return {
		type: FETCH_CITIES,
		stateCode,
		stateName,
		keyName,
	};
}

export function fetchZipcodes(cityName, keyName) {
	return {
		type: FETCH_ZIPCODES,
		cityName,
		keyName,
	};
}

export function updateStates(states) {
	return {
		type: UPDATE_STATES,
		states,
	};
}

export function updateCities(cities, stateName, keyName) {
	return {
		type: UPDATE_CITIES,
		cities,
		stateName,
		keyName,
	};
}

export function updateZipcodes(zipcodes, keyName) {
	return {
		type: UPDATE_ZIPCODES,
		zipcodes,
		keyName,
	};
}

export function setZipcode(zipcode, keyName) {
	return {
		type: SET_ZIPCODE,
		zipcode,
		keyName,
	};
}

export function success(response) {
	return {
		type: SUCCESS,
		data: response,
	};
}

export function error(err) {
	return {
		type: ERROR,
		data: err,
	};
}
