import history from 'utils/history';

import { capitalize } from 'lodash';

import moment from 'moment';

/**
 *
 * GroupDetail
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import CustomButton from 'atoms/CustomButton';
import Table from 'atoms/Table';
import AttachmentCell from 'atoms/TableCell/AttachmentCell';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import classnames from 'classnames';
import AddClientToGroup from 'components/AddClientToGroup';
import AddSessionModal from 'components/AddSessionModal';
import ExtendSessionsModal from 'components/ExtendSessionsModal';
import GroupAttendance from 'components/GroupAttendance';
import StatusModal from 'components/RequestSentModal';
import UpdateEnrollmentModal from 'components/UpdateEnrollmentModal';
import { BULLET } from 'constants/main';
import { downloadFile, generateToast } from 'containers/App/actions';
import { headerStyle } from 'containers/App/constants';
import { makeSelectAuth, makeSelectIsMobile } from 'containers/App/selectors';
import { compareWords } from 'utils/columnCompare';
import { toastOptions } from 'utils/toast';

import {
	addClient,
	addSession,
	deleteSessions,
	extendSessions,
	fetchAllClients,
	getAttendance,
	getAttendanceSuccess,
	getClients,
	getSessions,
	loadProgram,
	saveAttendance,
	updateEnrollment,
} from './actions';
import './index.css';
import makeSelectGroupDetail, {
	makeSelectAllClients,
	makeSelectGetAttendance,
	makeSelectGetClients,
	makeSelectGetProgram,
	makeSelectGetSessions,
	makeSelectReloadAttendance,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */

const patientLabels = [
	{
		name: 'My Attendance',
		id: 1,
	},
];
const adminLabels = [
	{
		name: 'Attendance',
		id: 1,
	},
	{
		name: 'Clients Enrolled',
		id: 4,
	},
	{
		name: 'Former Clients',
		id: 5,
	},
];
export class GroupDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: '1',
			dropdownOpen: false,
			showEditGroup: false,
			programId: '',
			program: null,
			showAddSession: false,
			showExtendSessions: false,
			showDeleteSessions: false,
			showUpdateEnroll: false,
			showAddClient: false,
			updateRequestSent: false,
			tabDropdownOpen: false,
			isSaving: false,
		};
	}

	closeRequestSentModal = () => {
		this.setState({ updateRequestSent: false });
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { program } = nextProps;

		if (program) {
			this.setState({
				program,
			});
		}
	}

	downloadClientFile = (docs) => {
		const { downloadClientFile } = this.props;
		docs.forEach((id) => {
			if (id) {
				downloadClientFile(id);
			}
		});
	};

	toggleAddClientModal = () => {
		const { fetchAllClients, type } = this.props;
		const { showAddClient } = this.state;

		if (!showAddClient) {
			fetchAllClients(type);
		}

		this.setState((prevState) => ({
			showAddClient: !prevState.showAddClient,
		}));
	};

	toggleShowAddSession = () => {
		this.setState({
			showAddSession: !this.state.showAddSession,
		});
	};

	toggleShowExtendSessions = () => {
		this.setState({
			showExtendSessions: !this.state.showExtendSessions,
		});
	};

	toggleShowDeleteSessions = () => {
		this.setState({
			showDeleteSessions: !this.state.showDeleteSessions,
		});
	};

	addNewSession = ({ startDate, endDate }) => {
		const { addNewSession } = this.props;
		const { program } = this.state;

		this.toggleShowAddSession();

		const data = {
			startDate: moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss'),
			endDate: moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss'),
			programId: program.programId,
			clinicianId: program.clinician.clinicianId,
		};

		addNewSession(data);
	};

	extendSessions = (data) => {
		const { extendSessions, createToast } = this.props;
		const { program } = this.state;
		const { programId, startTime, endTime } = program;

		const utcStart = moment.utc(startTime).format('HH:mm');
		const utcEnd = moment.utc(endTime).format('HH:mm');

		extendSessions(
			{
				...data,
				programId,
				startTime: utcStart,
				endTime: utcEnd,
			},
			(sessions) => {
				if (sessions?.length > 0) {
					const endDate = moment(sessions[sessions.length - 1].endDate).format(
						'MM-DD-YYYY',
					);
					createToast({
						text: `Sessions extended successfully! New end date is ${endDate}.`,
						type: 'custom-success',
					});
				} else {
					createToast({
						type: 'custom-error',
						text: 'Could not extend sessions. Please contact an administrator.',
					});
				}
			},
		);
		this.toggleShowExtendSessions();
		this.setState({ isWorking: true });
	};

	deleteSessions = (ids) => {
		const { deleteSessions } = this.props;
		const { program } = this.state;

		this.toggleShowAddSession();
		const data = {
			programId: program.programId,
			clinicianId: program.clinician.clinicianId,
			ids,
		};

		deleteSessions(data);
	};

	getId = () => {
		const { pathname } = this.props.routeProps.location;
		const pathArray = pathname.split('/');

		return pathArray[pathArray.length - 1];
	};

	goToProfile = (id) => {
		const { auth } = this.props;

		history.push({
			pathname: `/${auth.loggedInUser.roleName}/dashboard/profile/${id}`,
		});
	};

	clientEnrolledColumns = () => {
		return [
			{
				field: 'client',
				title: 'CLIENT',
				headerStyle,
				render: (row) => (
					<div className="d-flex justify-content-start mx-2 p-0 mt-2 hand">
						<ImageDetailsCell
							src={row.client.profilePicture || ''}
							heading={row.client.name}
							primarySubHeading={capitalize(row.client.type)}
						/>
					</div>
				),
			},
			{
				field: 'docs',
				title: 'SIGNED DOCUMENTS',
				headerStyle,
				render: (row) => (
					<div
						className="d-flex justify-content-start mx-2 p-0 mt-2"
						onClick={() => this.downloadClientFile(row.docs)}
					>
						<AttachmentCell title={`${row.docs.length} documents`} />
					</div>
				),
			},
			{
				field: 'client',
				title: 'START DATE',
				headerStyle,
				render: (row) => (
					<div className="d-flex justify-content-start font-14 mx-2 p-0 mt-2">
						{moment(row.client.startDate).format('MM-DD-YYYY')}
					</div>
				),
			},
			{
				field: 'client',
				title: 'END DATE',
				headerStyle,
				render: (row) => (
					<div className="d-flex justify-content-start font-14 mx-2 p-0 mt-2">
						{row.client.endDate
							? moment(row.client.endDate).format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'updateEnroll',
				headerStyle,
				render: (row) => (
					<div className="d-flex justify-content-end align-items-center mt-2">
						<button
							type="button"
							className="btn btn-primary primary-btn-color px-3 py-2 font-10"
							onClick={() => this.updateEnrollment(row.client)}
						>
							Update Enrollment
						</button>
					</div>
				),
			},
		];
	};

	componentDidMount() {
		const {
			fetchClients,
			routeProps: {
				location: { pathname },
			},
			type,
			dispatch,
		} = this.props;
		const index = pathname.lastIndexOf('/');
		const programId = pathname.substring(index + 1, pathname.length);
		const data1 = {
			id: parseInt(programId, 10),
		};
		this.setState({ programId: parseInt(programId, 10) });
		if (programId) {
			dispatch(loadProgram(data1));
			if (type !== 'patient') fetchClients({ programId });
		}
	}

	handleShowEditGroup = () => {
		this.setState((prevState) => ({
			showEditGroup: !prevState.showEditGroup,
		}));
	};

	doUpdateEnrollment = (startDates, endDates) => {
		const { updateEnrollment } = this.props;
		const { updateEnrollmentInfo, programId } = this.state;

		updateEnrollment({
			patientId: updateEnrollmentInfo.patientId,
			programIds: [programId],
			startDates,
			endDates,
		});

		this.setState({ showUpdateEnroll: false, updateRequestSent: true });
	};

	updateEnrollment = (data) => {
		this.setState({ updateEnrollmentInfo: data, showUpdateEnroll: true });
	};

	toggleUnenroll = () => {
		this.setState({ showUpdateEnroll: !this.state.showUpdateEnroll });
	};

	toggle = (tab) => {
		const { type, fetchClients } = this.props;
		const { programId } = this.state;
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
			if (programId) {
				if (type !== 'patient') {
					fetchClients({ programId });
				}
			}
		}
	};

	toggleDropdown = () => {
		const { dropdownOpen } = this.state;
		this.setState({
			dropdownOpen: !dropdownOpen,
		});
	};

	addClient = (data) => {
		const { dispatch } = this.props;
		data.enrolledGroups.push({ value: this.props.program.programId });
		this.props.addClient(data, () => {
			dispatch(
				generateToast({
					text: 'Client Added Successfully',
					type: 'custom-success',
				}),
			);
		});
	};

	getNavLink = (type) => {
		let headingLabel;
		if (type === 'patient') {
			headingLabel = patientLabels.map((value) => (
				<NavItem key={value.id}>
					<NavLink
						className={classnames(
							{
								active: this.state.activeTab === value.id.toString(),
							},
							'font-13',
						)}
						onClick={() => {
							this.toggle(value.id.toString());
						}}
					>
						{value.name}
					</NavLink>
				</NavItem>
			));
		}
		if (type === 'admin' || type === 'clinician') {
			headingLabel = adminLabels.map(
				(value) =>
					!(value.id === 6 && type === 'clinician') && (
						<NavItem key={value.id}>
							<NavLink
								className={classnames(
									{
										active: this.state.activeTab === value.id.toString(),
									},
									'font-13 hand',
								)}
								onClick={() => {
									this.toggle(value.id.toString());
								}}
							>
								{value.name}
							</NavLink>
						</NavItem>
					),
			);
		}
		return headingLabel;
	};

	updateAttendance = (data) => {
		const { doUpdateAttendance, createToast } = this.props;

		doUpdateAttendance(
			data,
			() =>
				createToast({
					text: 'Attendance Marked Successfully',
					position: 'bottom-left',
					options: toastOptions,
				}),
			() =>
				createToast({
					type: 'error',
					text: 'Attendance Could Not Be Taken',
					position: 'bottom-left',
					options: {
						...toastOptions,
						bodyClassName: 'attendance-toast-body-error',
					},
				}),
		);
	};

	saveAttendance = (data, newAttendance) => {
		const { doSaveAttendance, createToast, dispatch } = this.props;
		this.setState({ isSaving: true });

		doSaveAttendance(
			data,
			() => {
				createToast({
					text: 'Attendance Marked Successfully',
					position: 'bottom-left',
					options: toastOptions,
				});
				dispatch(getAttendanceSuccess(newAttendance));
				this.setState({ isSaving: false });
			},
			() => {
				createToast({
					type: 'error',
					text: 'Attendance Could Not Be Taken',
					position: 'bottom-left',
					options: {
						...toastOptions,
						bodyClassName: 'attendance-toast-body-error',
					},
				});
				this.setState({ isSaving: false });
			},
		);
	};

	toggleTabDropdown = () => {
		this.setState({ tabDropdownOpen: !this.state.tabDropdownOpen });
	};

	getDropdownLabel = (activeTab, type) => {
		let label;
		if (type === 'admin') {
			label = adminLabels.find((label) => label.id.toString() === activeTab);
		} else {
			label = patientLabels.find((label) => label.id.toString() === activeTab);
		}

		return label ? label.name : 'No Options';
	};

	getNav = () => {
		const { type, isMobile } = this.props;
		const { activeTab, tabDropdownOpen } = this.state;

		if (type === 'patient') {
			return <div></div>;
		}

		return (
			<div
				className={`${
					isMobile ? 'mb-1' : 'mb-2'
				} d-flex justify-content-between filter-nav`}
			>
				{isMobile ? (
					<ButtonDropdown
						direction="down"
						isOpen={tabDropdownOpen}
						toggle={this.toggleTabDropdown}
						className="w-100"
					>
						<DropdownToggle
							className="btn btn-white bg-white link-color btn-lg btn-block font-15 font-weight-bold d-flex custom-caret"
							nav
							caret
						>
							{this.getDropdownLabel(activeTab, type)}
						</DropdownToggle>
						{tabDropdownOpen && (
							<DropdownMenu className="w-100">
								{this.getNavLink(type).map((nav) => (
									<DropdownItem key={nav}>{nav}</DropdownItem>
								))}
							</DropdownMenu>
						)}
					</ButtonDropdown>
				) : (
					<div>
						<Nav
							tabs
							className="actions m-0 link-color w-100 border-bottom-0 shadow-none"
						>
							{this.getNavLink(type)}
						</Nav>
						<div className="col-1 p-0 d-flex justify-content-end bg-white">
							<div
								className="col-6 fa-filter border-bottom-0 shadow-none"
								onClick={this.handleChange}
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	getTabContent = (activeTab, type) => {
		let tabcontent;
		const {
			fetchAttendance,
			doDeleteSessions,
			attendance,
			fetchSessions,
			routeProps: {
				location: { data },
			},
			sessions,
			reloadAttendance,
			clients = [],
			auth,
		} = this.props;

		const { program, isSaving } = this.state;

		if (type === 'patient') {
			tabcontent = (
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<GroupAttendance
							auth={auth}
							fetchAttendance={fetchAttendance}
							attendance={attendance}
							programDetails={program || data}
							fetchSessions={fetchSessions}
							sessions={sessions}
							type={type}
							programId={this.state.programId}
							isSaving={isSaving}
						/>
					</TabPane>
				</TabContent>
			);
		}
		if (type === 'admin' || type === 'clinician') {
			tabcontent = (
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<GroupAttendance
							auth={auth}
							fetchAttendance={fetchAttendance}
							attendance={attendance}
							saveAttendance={this.saveAttendance}
							programDetails={program || data}
							fetchSessions={fetchSessions}
							sessions={sessions}
							type={type}
							programId={this.state.programId}
							toggleShowAddSession={this.toggleShowAddSession}
							toggleShowExtendSessions={this.toggleShowExtendSessions}
							deleteSessions={doDeleteSessions}
							reload={reloadAttendance}
							isSaving={isSaving}
						/>
					</TabPane>
					<TabPane tabId="4">
						<div className="row">
							<div className="col-sm-12">
								<Table
									keyField="patientId"
									data={clients
										.filter(
											(c) =>
												!c.client.endDate ||
												moment(c.client.endDate).isAfter(moment()),
										)
										.sort((a, b) =>
											compareWords(a.client.name, b.client.name, 'asc'),
										)}
									columns={this.clientEnrolledColumns()}
									placeholder="No clients enrolled"
								/>
							</div>
						</div>
					</TabPane>
					<TabPane tabId="5">
						<div className="row">
							<div className="col-sm-12">
								<Table
									keyField="patientId"
									data={clients
										.filter(
											(c) =>
												c.client.endDate &&
												moment(c.client.endDate).isBefore(moment()),
										)
										.sort((a, b) =>
											compareWords(a.client.name, b.client.name, 'asc'),
										)}
									columns={this.clientEnrolledColumns()}
									placeholder="No former clients"
								/>
							</div>
						</div>
					</TabPane>
				</TabContent>
			);
		}
		return tabcontent;
	};

	getCurrentLastSession = () => {
		const sessions = this.props.sessions[this.props.sessions.length - 1];
		const date =
			sessions.sessionDetails[sessions.sessionDetails.length - 1].endDate;
		return date && moment(date).format('MM-DD-YYYY');
	};

	isValidLink = () => {
		const { program } = this.props;
		const meetingLink = program.meetingLink;
		if (!meetingLink) return false;
		return meetingLink.indexOf('http://') === 0 || meetingLink.indexOf('https://') === 0;
	};

	getProgramHeader = () => {
		const { program, type, isMobile, clients } = this.props;

		return (
			<div className={`container-fluid bg-white ${!isMobile && 'px-5'}`}>
				{isMobile ? (
					<div className="container">
						<div className="mx-auto">
							<div className="row pt-3 bg-white p-0">
								<div className="m-auto font-25 text-center">
									{program.groupName}
								</div>
							</div>
							<div className="row py-0 bg-white py-2 justify-content-center">
								<div className="m-0 font-14 font-weight-bold">
									{moment.utc(program.startTime).local().format('h:mm a')} -{' '}
									{moment.utc(program.endTime).local().format('h:mm a')}
								</div>
								<div className="m-0 font-14 font-weight-bold">
									{program.inPerson && `In-Person ${BULLET}`}
									{program.location}
								</div>
							</div>
							{(type === 'patient' || type === 'admin') && (
								<div className="row justify-content-center ">
									<div className="p-2">
										<ImageDetailsCell
											heading={program.clinician.clinicianName}
											primarySubHeading={program.clinician.therapistType}
										/>
									</div>
								</div>
							)}
						</div>
						<div className="row p-2 justify-content-center">
							<h6 className="text-dark m-0 font-13">
								<a href={program.meetingLink} target="_blank" rel="noopener noreferrer">
									Meeting Link
								</a>
							</h6>
						</div>
						<div className="row p-2 justify-content-around text-center">
							<div className="my-2 col-auto d-flex flex-column">
								<h6 className="detailed-nav">Last Session</h6>
								<div className="detailed-nav-value">
									{program.lastSession
										? moment(program.lastSession).format('MMM D, YYYY')
										: '-'}
								</div>
							</div>
							<div className="my-2 col-auto d-flex flex-column">
								<h6 className="detailed-nav">Upcoming Session</h6>
								<div className="detailed-nav-value">
									{program.upcomingSession
										? moment(program.upcomingSession).format('MMM D, YYYY')
										: '-'}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						{type === 'patient' || type === 'admin' ? (
							<div className="row py-3 bg-white p-0">
								<div className="col-6 d-flex justify-content-end border-right">
									<div className="d-flex flex-column justify-content-center align-items-end">
										<div className="m-0 font-25">{program.groupName}</div>
										<div className="m-0 font-14 font-weight-bold">
											{moment.utc(program.startTime).local().format('h:mm a')} -{' '}
											{moment.utc(program.endTime).local().format('h:mm a')}
										</div>
										<div className="m-0 font-14 font-weight-bold">
											{program.inPerson ? 'In Person ' : 'Remote '}
											{`${BULLET} `}
											{program.location}
										</div>
									</div>
								</div>
								<div className="col-6 row pr-0">
									<div className="col-8 row justify-content-start align-items-center p-0 ml-2">
										<ImageDetailsCell
											heading={program.clinician.clinicianName}
											primarySubHeading={program.clinician.therapistType}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="col-12 d-flex justify-content-center p-0 pt-3">
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div className="m-0 font-25">{program.groupName}</div>
									<div className="m-0 font-14 font-weight-bold">
										{program.location}
									</div>
								</div>
							</div>
						)}
						{this.isValidLink() && 
							<div className="d-flex justify-content-center">
								<h6 className="text-dark m-0 font-13">
									<a href={program.meetingLink} target="_blank" rel="noopener noreferrer">
										Meeting Link
									</a>
								</h6>
							</div>
						}
						<div className="pb-2 bg-white d-flex justify-content-center">
							<div className="d-flex session-metadata justify-content-center mt-3">
								<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
									<h6 className="detailed-nav">Last Session</h6>
									<div className="detailed-nav-value">
										{program.lastSession
											? moment(program.lastSession).format('MMM D, YYYY')
											: '-'}
									</div>
								</div>
								<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
									<h6 className="detailed-nav">Upcoming Session</h6>
									<div className="detailed-nav-value">
										{program.upcomingSession
											? moment(program.upcomingSession).format('MMM D, YYYY')
											: '-'}
									</div>
								</div>
								<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
									<h6 className="detailed-nav">Price</h6>
									<div className="detailed-nav-value">
										${program.price} / month
									</div>
								</div>
								{type === 'patient' ? (
									<>
										<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
											<h6 className="detailed-nav">Join Date</h6>
											<div className="detailed-nav-value">
												{program.client?.startDate
													? moment(program.client.startDate).format(
															'MMM DD, YYYY',
													  )
													: '-'}
											</div>
										</div>
										<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
											<h6 className="detailed-nav">Discontinue Date</h6>
											<div className="detailed-nav-value">
												{program.client?.endDate
													? moment(program.client.endDate).format(
															'MMM DD, YYYY',
													  )
													: '-'}
											</div>
										</div>
									</>
								) : (
									<div className="my-2 col-3 d-flex justify-content-center align-items-center flex-column">
										<h6 className="detailed-nav">Enrollees</h6>
										<div className="detailed-nav-value">
											{
												clients.filter(
													(c) =>
														!c.client.endDate ||
														moment(c.client.endDate).isAfter(moment()),
												).length
											}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};

	render() {
		const {
			type,
			updateError,
			updateSuccess,
			isMobile,
			allClients,
			clients,
			sessions,
		} = this.props;

		let endDate;
		const endDetails = sessions[sessions.length - 1]?.sessionDetails;
		if (endDetails) {
			endDate = endDetails[endDetails.length - 1].endDate;
		}

		const {
			activeTab,
			program,
			showAddSession,
			showUpdateEnroll,
			updateEnrollmentInfo,
			updateRequestSent,
			showAddClient,
			showExtendSessions,
		} = this.state;

		return (
			<div className="mt-1">
				{updateRequestSent && (updateError || updateSuccess) && (
					<StatusModal
						successStatus={!updateError}
						hide={this.closeRequestSentModal}
						message={
							updateError
								? 'Something went wrong. Please try again later'
								: 'Client updated successfully'
						}
					/>
				)}
				{program && program.programId && this.getProgramHeader()}
				<div
					className={`container-fluid ${isMobile ? 'my-2 px-2' : 'mt-4 px-5'}`}
				>
					{this.getNav()}
					{type !== 'patient' && (
						<div className={'row mx-0 bg-white mb-2 p-3 justify-content-end'}>
							<CustomButton
								type="primary"
								title="+ ADD CLIENT"
								onClick={this.toggleAddClientModal}
							/>
						</div>
					)}
					{this.getTabContent(activeTab, type)}
				</div>
				{showAddSession && (
					<AddSessionModal
						hide={this.toggleShowAddSession}
						details={program}
						onSubmit={this.addNewSession}
					/>
				)}
				{showExtendSessions && (
					<ExtendSessionsModal
						hide={this.toggleShowExtendSessions}
						details={program}
						onSubmit={this.extendSessions}
						lastSession={endDate}
					/>
				)}
				{showUpdateEnroll && (
					<UpdateEnrollmentModal
						isOpen={showUpdateEnroll}
						toggle={this.toggleUnenroll}
						programs={[this.getId()]}
						client={updateEnrollmentInfo}
						onSubmit={this.doUpdateEnrollment}
					/>
				)}
				{showAddClient && (
					<AddClientToGroup
						program={program}
						addNewClient={this.addClient}
						patientList={allClients.filter((client) => {
							return (
								client.isActive &&
								!clients.find((c) => c.patientId === client.patientId)
							);
						})}
						hide={this.toggleAddClientModal}
						mode="single"
					/>
				)}
			</div>
		);
	}
}

GroupDetail.propTypes = {
	type: PropTypes.string.isRequired,
	fetchAttendance: PropTypes.func,
	attendance: PropTypes.array,
	doUpdateAttendance: PropTypes.func,
	fetchSessions: PropTypes.func,
	sessions: PropTypes.array,
	routeProps: PropTypes.object,
	fetchClients: PropTypes.func,
	clients: PropTypes.array,
	downloadClientFile: PropTypes.func,
	updateEnrollment: PropTypes.func,
	clearClientUnenroll: PropTypes.func,
	program: PropTypes.object,
	dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	groupDetail: makeSelectGroupDetail(),
	attendance: makeSelectGetAttendance(),
	sessions: makeSelectGetSessions(),
	clients: makeSelectGetClients(),
	program: makeSelectGetProgram(),
	reloadAttendance: makeSelectReloadAttendance(),
	isMobile: makeSelectIsMobile(),
	allClients: makeSelectAllClients(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchAttendance: (data, cb) => dispatch(getAttendance(data, cb)),
		doSaveAttendance: (data, res, err) =>
			dispatch(saveAttendance(data, res, err)),
		fetchSessions: (data) => dispatch(getSessions(data)),
		fetchClients: (data) => dispatch(getClients(data)),
		addNewSession: (data) => dispatch(addSession(data)),
		doDeleteSessions: (ids) => dispatch(deleteSessions(ids)),
		downloadClientFile: (docId) => dispatch(downloadFile(docId)),
		updateEnrollment: (data) => dispatch(updateEnrollment(data)),
		createToast: (data) => dispatch(generateToast(data)),
		fetchAllClients: (type) => dispatch(fetchAllClients(type)),
		addClient: (data, cb) => dispatch(addClient(data, cb)),
		extendSessions: (data, cb) => dispatch(extendSessions(data, cb)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);
