import { createSelector } from 'reselect';

import { compareDates } from 'utils/columnCompare';
import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the forms state domain
 */

const selectNotificationsDomain = (state) =>
	state.get('notifications', initialState);

const makeSelectNotifications = () =>
	createSelector(selectNotificationsDomain, (substate) =>
		returnJS(substate.get('data')).filter((n) => n.state !== 'MARKED_ABSENT'),
	);

const makeSelectAlerts = () =>
	createSelector(selectNotificationsDomain, (substate) =>
		returnJS(substate.get('data'))
			.filter((n) => n.state === 'MARKED_ABSENT')
			.sort((a, b) =>
				compareDates(a.attendance.date, b.attendance.date, 'desc'),
			),
	);

const makeSelectNotificationsSelectors = () =>
	createSelector(selectNotificationsDomain, (substate) => returnJS(substate));

export default makeSelectNotificationsSelectors;

export { makeSelectNotifications, makeSelectAlerts };
