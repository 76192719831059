const PrevButton = (props) => {
	const { disabled, onClick, isMobile } = props;

	return (
		<div>
			<button
				disabled={disabled}
				type="button"
				className="btn btn-white bg-white link-color border btn-lg btn-block font-15 font-weight-bold d-flex justify-content-start"
				onClick={onClick}
			>
				<div
					className={`col-4 d-flex justify-content-start align-self-center ${
						isMobile && 'mr-2'
					}`}
				>
					<img className="fas fa-arrow-left" alt="custom-arrow-left" />
				</div>
				<div className="col-4 d-flex justify-content-center">
					<span>PREV</span>
				</div>
			</button>
		</div>
	);
};

export default PrevButton;
