import parse from 'html-react-parser';

import { uniqBy } from 'lodash';

import PropTypes from 'prop-types';

import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Col, Row, TabContent, TabPane } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import NavTab from 'atoms/NavTab';
import SearchBar from 'atoms/SearchBar';
import KebabMenuCell from 'atoms/TableCell/KebabMenuCell';
import AddClientToGroup from 'components/AddClientToGroup';
import AddNewClient from 'components/AddNewClient';
import ClientAndClinicianTableAdmin from 'components/ClientAndClinicianTableAdmin';
import FilterNav from 'components/FilterNav';
import RequestSentModal from 'components/RequestSentModal';
import { downloadFile, fetchCities, fetchStates } from 'containers/App/actions';
import {
	makeSelectCities,
	makeSelectClinicians,
	makeSelectIsMobile,
	makeSelectStates,
} from 'containers/App/selectors';
import { compareWords } from 'utils/columnCompare';
import { filterByLetter, filterData, getFilterOptions } from 'utils/helpers';

import {
	addClient,
	changeClinician,
	clearNewClient,
	deleteClinician,
	editClinician,
	getClinician,
	loadAllClients,
	loadAllClinicians,
	loadClients,
	loadPrograms,
} from './actions';
import cliniciansAndClientsFilter from './filters';
import {
	makeSelectAllPrograms,
	makeSelectCliniciansAndClients,
	makeSelectIsFetchingClients,
	makeSelectIsFetchingClinicians,
	makeSelectMyClients,
	makeSelectNewClient,
} from './selectors';

const clinicianLabels = [
	{
		name: 'My Clients',
		id: 1,
	},
];

const adminLabels = [
	{
		name: 'My Clients',
		id: 1,
	},
	{
		name: 'My Clinicians',
		id: 2,
	},
];

/* eslint-disable react/prefer-stateless-function */
export class CliniciansAndClients extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			filterClicked: true,
			allClients: [],
			allClinicians: [],
			showModal: false,
			showAddToGroupModal: false,
			screenstate: false,
			loadRequestSentModal: false,
			clients: [],
			filters: [],
			clientSearch: '',
			clinicianSearch: '',
			showInactiveClients: false,
			showInactiveClinicians: false,
		};

		this.scrollRef = createRef();
	}

	toggle = (tab) => {
		const { dispatch, type, loadMyClients } = this.props;
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});

			if (type === 'admin') {
				dispatch(loadAllClients());
				dispatch(loadAllClinicians());
			} else if (type === 'clinician') {
				loadMyClients();
			}

			this.setState({ filters: [] });
		}
	};

	addNewClient = () => {
		this.setState({
			showModal: true,
		});
	};

	addClientToGroup = () => {
		this.setState({
			showAddToGroupModal: true,
		});
	};

	hideClientModal = () => {
		this.setState({
			showModal: false,
		});
	};

	hideAddClientToGroupModal = () => {
		this.setState({
			showAddToGroupModal: false,
		});
	};

	closeRequestSentModal = () => {
		const { dispatch } = this.props;
		dispatch(clearNewClient());
		this.setState({
			loadRequestSentModal: false,
			showModal: false,
			showAddToGroupModal: false,
		});
	};

	changeClinician = (data) => {
		const { dispatch } = this.props;
		dispatch(changeClinician(data));
	};

	handleDelete = (clinicianId) => {
		const { dispatch } = this.props;
		const data = {
			...clinicianId,
		};
		dispatch(deleteClinician(data));
	};

	editClinician = (data) => {
		const { dispatch } = this.props;
		dispatch(editClinician(data));
	};

	getClinician = (data) => {
		const { dispatch } = this.props;
		dispatch(getClinician(data));
	};

	componentDidMount = () => {
		const {
			dispatch,
			type,
			loadMyClients,
			cliniciansAndClients,
			fetchPrograms,
		} = this.props;

		if (cliniciansAndClients) {
			this.setState({
				allClients: cliniciansAndClients.allClients,
				allClinicians: cliniciansAndClients.allClinicians,
			});

			dispatch(loadAllClients());
			dispatch(loadAllClinicians());
		}
		if (type === 'clinician') {
			loadMyClients();
		}

		dispatch(fetchStates());
		fetchPrograms();
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.cliniciansAndClients) {
			this.setState({
				allClients: nextProps.cliniciansAndClients.allClients,
				allClinicians: nextProps.cliniciansAndClients.allClinicians,
				loadingClients: false,
				loadingClinicians: false,
			});
		}

		if (nextProps.clients.clients) {
			this.setState({
				clients: nextProps.clients.clients,
				loadingClients: false,
			});
		}
	}

	downloadFile = (docId) => {
		if (!docId) return;

		const { dispatch } = this.props;
		dispatch(downloadFile(docId));
	};

	backToTop = () => {
		this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
	};

	applyFilters = () => {
		const { type } = this.props;
		let {
			allClinicians,
			allClients,
			filters,
			clientSearch,
			clinicianSearch,
			showInactiveClients,
			showInactiveClinicians,
			activeTab,
		} = this.state;

		let clients = [];

		if (type === 'admin') {
			clients = allClients;
		} else if (type === 'clinician') {
			clients = this.state.clients;
		}

		if (activeTab === '1') {
			if (!Array.isArray(clients)) clients = [];
			filters.forEach((filter) => {
				clients = filter.callback(filter.name, filter.option, clients);
			});

			if (clientSearch) {
				clients = filterByLetter(clients, 'client.name', clientSearch);
			}

			if (!showInactiveClients) {
				clients = clients.filter((c) => c.isActive);
			}
		} else if (activeTab === '2') {
			if (!Array.isArray(allClinicians)) allClinicians = [];

			filters.forEach((filter) => {
				allClinicians = filter.callback(
					filter.name,
					filter.option,
					allClinicians,
				);
			});

			if (clinicianSearch) {
				allClinicians = filterByLetter(allClinicians, 'name', clinicianSearch);
			}

			if (!showInactiveClinicians) {
				allClinicians = allClinicians.filter((c) => c.isActive);
			}

			allClinicians.sort((a, b) => compareWords(a.name, b.name, 'asc'));
		}

		return {
			clients,
			clinicians: allClinicians,
		};
	};

	getTabContent = (activeTab) => {
		const {
			states,
			cliniciansAndClients: { getClinician },
			type,
			isFetchingClients,
			isFetchingClinicians,
			isMobile,
		} = this.props;

		const { clients, clinicians } = this.applyFilters();

		const tabcontent = (
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<Row>
						<Col sm="12">
							<ClientAndClinicianTableAdmin
								data={clients}
								tableType="client"
								changeClinician={this.changeClinician}
								downloadFile={this.downloadFile}
								clinicians={clinicians}
								type={type}
								loading={isFetchingClients}
								isMobile={isMobile}
								backToTop={this.backToTop}
							/>
						</Col>
					</Row>
				</TabPane>
				{type === 'admin' && (
					<TabPane tabId="2">
						<div className="row">
							<div className="col-sm-12">
								<ClientAndClinicianTableAdmin
									data={clinicians}
									tableType="clinician"
									deleteClinician={this.handleDelete}
									editClinician={this.editClinician}
									getClinician={this.getClinician}
									clinicianInfo={getClinician}
									states={states}
									loading={isFetchingClinicians}
									isMobile={isMobile}
									backToTop={this.backToTop}
								/>
							</div>
						</div>
					</TabPane>
				)}
			</TabContent>
		);

		return tabcontent;
	};

	handleChange = () => {
		this.setState((prevState) => ({ filterClicked: !prevState.filterClicked }));
	};

	handleCheck = (name) => {
		this.setState((prevState) => ({
			[name]: !prevState[name],
		}));
	};

	addFilter = (filter) => {
		let { filters } = this.state;

		filters = filters.filter((x) => x.name !== filter.name);

		filters.push(filter);
		this.setState({ filters });
	};

	removeFilter = (name) => {
		let { filters } = this.state;
		filters = filters.filter((x) => x.name !== name);
		this.setState({ filters });
	};

	generateFilters = () => {
		const {
			cliniciansAndClients: { allClients, allClinicians, clients },
			type,
		} = this.props;
		const { activeTab } = this.state;
		let filteroptions = [];
		const options = {};
		if (activeTab === '1') {
			const clientTypes = getFilterOptions(
				type === 'admin' ? allClients : clients,
				'clientType',
			).sort((a, b) => compareWords(a, b, 'asc'));

			options.clientType = ['All IOP/ PHP', ...clientTypes];
			options.state = getFilterOptions(
				type === 'admin' ? allClients : clients,
				'client.state',
			).sort((a, b) => compareWords(a, b, 'asc'));
			filteroptions = cliniciansAndClientsFilter(options, activeTab);
		} else {
			options.therapistType = getFilterOptions(
				allClinicians,
				'therapistType',
			).sort((a, b) => compareWords(a, b, 'asc'));
			filteroptions = cliniciansAndClientsFilter(options, activeTab);
		}
		return filteroptions;
	};

	filterDropdown = (name, value, list) => {
		const { activeTab } = this.state;
		if (activeTab === '1') {
			let path = '';
			/* if (name === 'name') {
        path = 'client.name';
      } else */
			if (name === 'state') {
				path = 'client.state';
			} else {
				path = name;
			}

			if (value === 'All IOP/ PHP') {
				return list.filter(
					(item) =>
						item.clientType.toLowerCase().includes('iop') &&
						item.clientType.toLowerCase() !== 'iopphp',
				);
			} else {
				return filterData(list, path, value);
			}
		} else {
			/* let path = '';
      if (name === 'name') {
        path = 'userInfo.name';
      } else { }*/
			let path = name;

			return filterData(list, path, value);
		}
	};

	handleGeoChange = (type, selectedItem) => {
		const { doFetchCities } = this.props;
		switch (type) {
			case 'state':
				doFetchCities(selectedItem.value, selectedItem.label);
				break;

			default:
				break;
		}
	};

	addClient = (data) => {
		const { dispatch } = this.props;
		dispatch(addClient(data));
		this.setState({ loadRequestSentModal: true });
	};

	getDropdownLabel = (activeTab, type) => {
		let label;
		if (type === 'admin') {
			label = adminLabels.find((label) => label.id.toString() === activeTab);
		} else {
			label = clinicianLabels.find(
				(label) => label.id.toString() === activeTab,
			);
		}

		return label ? label.name : 'No Options';
	};

	searchForClient = () => {
		const { type } = this.props;
		const { clientSearch, clinicianSearch, activeTab } = this.state;

		let dropdownList = [];

		const { clients, clinicians } = this.applyFilters();
		if (type === 'admin') {
			if (activeTab === '1') {
				dropdownList = clients.map((x) => ({
					label: x.client?.name,
					value: x.client?.name,
				}));
			} else if (activeTab === '2') {
				dropdownList = clinicians.map((x) => ({
					label: x.name,
					value: x.name,
				}));
			}
		} else if (type === 'clinician') {
			dropdownList = clients.map((x) => ({
				label: x.client?.name,
				value: x.client?.name,
			}));
		}

		dropdownList = dropdownList.filter((x) => x.label);
		dropdownList = uniqBy(dropdownList, (x) => x.label);
		dropdownList = dropdownList.sort((a, b) =>
			compareWords(a.label, b.label, 'asc'),
		);

		return (
			<SearchBar
				value={activeTab === '1' ? clientSearch : clinicianSearch}
				onSearch={(value) => {
					if (activeTab === '1') {
						this.setState({
							clientSearch: value,
						});
					} else {
						this.setState({
							clinicianSearch: value,
						});
					}
				}}
				style={{
					searchBox: { height: 52 },
				}}
				dropdownList={dropdownList}
				placeholder={
					activeTab === '1' ? 'Search for Client' : 'Search for Clinician'
				}
			/>
		);
	};

	filterKebab = () => {
		const { activeTab } = this.state;

		if (activeTab !== '1') return null;

		return (
			<KebabMenuCell
				dropdownList={[
					{
						id: 1,
						name: '+ Add New Client',
						callback: this.addNewClient,
						enabled: true,
					},
					{
						id: 2,
						name: '+ Add Client to Therapy',
						callback: this.addClientToGroup,
						enabled: true,
					},
				]}
				noMargin
			/>
		);
	};

	render() {
		const {
			activeTab,
			filterClicked,
			showModal,
			filters,
			allClients,
			loadRequestSentModal,
			showAddToGroupModal,
		} = this.state;
		const { programs, states, cities, newClient, isMobile, dispatch, type } =
			this.props;
		const requestStatus = !newClient.error;
		const message = (
			<div>
				<span className="d-block font-15 text-dark text-uppercase mb-1 text-left">
					{newClient.error
						? parse(newClient.error || '')
						: parse(newClient.msg || '')}
				</span>
			</div>
		);

		return (
			<div
				className={`container-fluid ${isMobile ? 'px-2 mt-3' : 'px-5 mt-5'}`}
			>
				<div ref={this.scrollRef} />
				{type === 'admin' && (
					<NavTab
						type={type}
						activeTab={activeTab}
						handleChange={this.handleChange}
						isMobile={isMobile}
						getLabel={this.getDropdownLabel}
						labels={{ admin: adminLabels, clinician: clinicianLabels }}
						switchTab={this.toggle}
					/>
				)}
				{filterClicked && (
					<div>
						<FilterNav
							filtersList={this.generateFilters()}
							filterDropdown={this.filterDropdown}
							addFilter={this.addFilter}
							removeFilter={this.removeFilter}
							handleCheck={this.handleCheck}
							currentFilters={filters}
							isMobile={isMobile}
							dispatch={dispatch}
							search={this.searchForClient()}
							kebab={this.filterKebab()}
						/>
					</div>
				)}
				{activeTab === '1' && !isMobile && (
					<div className={'card p-0 mt-4 mb-2'}>
						<div className="d-flex align-items-center justify-content-between p-3">
							<span className="font-weight-bold font-13 text-dark">
								List Of Clients
							</span>
							<div className="d-flex justify-content-end">
								<button
									type="button"
									className="btn btn-primary btn-color font-10 mr-3"
									onClick={this.addNewClient}
								>
									+ ADD NEW CLIENT
								</button>
								{
									<button
										type="button"
										className="btn btn-primary btn-color font-10 mr-3"
										onClick={this.addClientToGroup}
									>
										+ ADD CLIENT TO THERAPY
									</button>
								}
							</div>
						</div>
					</div>
				)}
				<div className="pb-4">{this.getTabContent(activeTab)}</div>
				{showAddToGroupModal && (
					<AddClientToGroup
						programs={programs}
						addNewClient={this.addClient}
						patientList={allClients}
						hide={this.hideAddClientToGroupModal}
					/>
				)}
				{showModal && !newClient.error && !newClient.msg && (
					<AddNewClient
						hide={this.hideClientModal}
						addNewClient={this.addClient}
						programs={programs}
						states={states}
						cities={cities}
						handleGeoChange={this.handleGeoChange}
					/>
				)}
				{loadRequestSentModal && (newClient.error || newClient.msg) && (
					<RequestSentModal
						hide={this.closeRequestSentModal}
						successStatus={requestStatus}
						message={message}
					/>
				)}
			</div>
		);
	}
}

CliniciansAndClients.propTypes = {
	dispatch: PropTypes.func.isRequired,
	states: PropTypes.array,
	cities: PropTypes.array,
	doFetchCities: PropTypes.func,
	cliniciansAndClients: PropTypes.array,
	clinicians: PropTypes.array,
	loadMyClients: PropTypes.func.isRequired,
	clients: PropTypes.object.isRequired,
	programs: PropTypes.array,
	fetchPrograms: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	cliniciansAndClients: makeSelectCliniciansAndClients(),
	clients: makeSelectMyClients(),
	clinicians: makeSelectClinicians(),
	programs: makeSelectAllPrograms(),
	states: makeSelectStates(),
	newClient: makeSelectNewClient(),
	cities: makeSelectCities(),
	isFetchingClients: makeSelectIsFetchingClients(),
	isFetchingClinicians: makeSelectIsFetchingClinicians(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchPrograms: () => dispatch(loadPrograms()),
		loadMyClients: () => dispatch(loadClients()),
		doFetchCities: (stateCode, stateName) =>
			dispatch(fetchCities(stateCode, stateName)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CliniciansAndClients);
