import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	createRequestAPI,
	deleteRequestAPI,
	downloadFileAPI,
	downloadFormAPI,
	fetchCitiesAPI,
	fetchClinicians,
	fetchDocumentListAPI,
	fetchStatesAPI,
	fetchZipcodesAPI,
	findPharmacy,
	findToDosAPI,
	getPermissionsAPI,
	pingServerApi,
	updateRequestAPI,
} from 'utils/api';

import {
	createRequestError,
	createRequestSuccess,
	deleteRequestError,
	deleteRequestSuccess,
	downloadFileError,
	downloadFileSuccess,
	fetchCitiesError,
	fetchCitiesSuccess,
	fetchDocumentListError,
	fetchDocumentListSuccess,
	fetchEnrollRequestsError,
	fetchEnrollRequestsSuccess,
	fetchMemberRequestsError,
	fetchMemberRequestsSuccess,
	fetchPharmaciesError,
	fetchPharmaciesSuccess,
	fetchStatesError,
	fetchStatesSuccess,
	fetchZipcodesError,
	fetchZipcodesSuccess,
	getPermissionsError,
	getPermissionsSuccess,
	loadCliniciansError,
	loadCliniciansSuccess,
	pingServerError,
	pingServerSuccess,
	removeToastMessage,
	toastError,
	updateRequestError,
	updateRequestSuccess,
	viewPdfError,
	viewPdfSuccess,
} from './actions';
import {
	CREATE_REQUEST,
	DELETE_REQUEST,
	DOWNLOAD_FILE,
	FETCH_CITIES,
	FETCH_DOCUMENT_LIST,
	FETCH_ENROLL_REQ,
	FETCH_MEMBER_REQ,
	FETCH_PHARMACIES,
	FETCH_STATES,
	FETCH_ZIPCODES,
	GET_PERMISSIONS,
	LOAD_CLINICIANS,
	PING_SERVER,
	REMOVE_TOAST_MESSAGE,
	UPDATE_REQUEST,
	VIEW_PDF,
} from './constants';
import { makeSelectUserId } from './selectors';

export function* performRemoveToast() {
	try {
		yield put(removeToastMessage(null));
	} catch (err) {
		yield put(toastError(err));
	}
}

export function* pingServer() {
	try {
		const response = yield call(pingServerApi);

		if (response.success) {
			yield put(pingServerSuccess(response));
		} else {
			yield put(pingServerError());
		}
	} catch (err) {
		yield put(pingServerError());
	}
}

export function* fetchStates() {
	try {
		const response = yield call(fetchStatesAPI);
		yield put(fetchStatesSuccess(response));
	} catch (err) {
		yield put(fetchStatesError(err));
	}
}

export function* fetchEnrollReq() {
	const id = yield select(makeSelectUserId());

	try {
		const data = {
			userId: id,
			parentRequest: 'Enrollment Request',
		};
		const response = yield call(findToDosAPI, data);
		yield put(fetchEnrollRequestsSuccess(response));
	} catch (err) {
		yield put(fetchEnrollRequestsError(err));
	}
}

export function* fetchMemberReq() {
	const id = yield select(makeSelectUserId());

	try {
		const data = {
			userId: id,
			parentRequest: 'Member Request',
		};
		const response = yield call(findToDosAPI, data);
		yield put(fetchMemberRequestsSuccess(response));
	} catch (err) {
		yield put(fetchMemberRequestsError(err));
	}
}

export function* updateRequest(actions) {
	const { data } = actions;
	try {
		const response = yield call(updateRequestAPI, data);
		yield put(updateRequestSuccess(response));
	} catch (err) {
		yield put(updateRequestError(err));
	}
}

export function* deleteRequest(actions) {
	const { data } = actions;
	try {
		const response = yield call(deleteRequestAPI, data);
		if (!response.request) {
			throw Error(response.error);
		}
		yield put(deleteRequestSuccess(response.request));
	} catch (err) {
		yield put(deleteRequestError(err));
	}
}

export function* createRequest(actions) {
	const { data } = actions;
	try {
		const response = yield call(createRequestAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(createRequestError());
		} else {
			yield put(createRequestSuccess(response.data));
		}
	} catch (err) {
		yield put(createRequestError(err));
	}
}

export function* viewPdf(action) {
	const { uuid, cb, onErr } = action;
	const data = { uuid };
	try {
		const response = yield call(downloadFormAPI, data);
		if (response.data) {
			yield put(viewPdfSuccess(response.data));
			if (cb) {
				cb();
			}
		} else {
			yield put(viewPdfError('Could not download'));
			if (onErr) {
				onErr();
			}
		}
	} catch (err) {
		yield put(viewPdfError('Could not download'));
	}
}

export function* fetchDocumentList() {
	try {
		const response = yield call(fetchDocumentListAPI);
		if (response.response && response.response.status >= 400) {
			yield put(fetchDocumentListError());
		} else {
			yield put(fetchDocumentListSuccess(response.data));
		}
	} catch (err) {
		yield put(fetchDocumentListError(err));
	}
}

export function* fetchCities(actions) {
	const { stateCode } = actions;
	try {
		const response = yield call(fetchCitiesAPI, stateCode);
		yield put(fetchCitiesSuccess(stateCode, response));
	} catch (err) {
		yield put(fetchCitiesError(err));
	}
}

export function* fetchZipcodes(actions) {
	const { cityName } = actions;
	try {
		const response = yield call(fetchZipcodesAPI, cityName);
		yield put(fetchZipcodesSuccess(cityName, response));
	} catch (err) {
		yield put(fetchZipcodesError(err));
	}
}

export function* downloadFile(actions) {
	const { docId, container } = actions;
	let data = { docId, container };
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(downloadFileAPI, data);

		if (response?.response?.status >= 500) {
			yield put(downloadFileError(response.message));
		} else {
			yield put(downloadFileSuccess(response));
		}
	} catch (err) {
		yield put(downloadFileError(err));
	}
}

export function* getClinicians() {
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(fetchClinicians);
		yield put(loadCliniciansSuccess(response));
	} catch (err) {
		yield put(loadCliniciansError(err));
	}
}

export function* getPharmacies({ id }) {
	try {
		const response = yield call(findPharmacy, id);
		yield put(fetchPharmaciesSuccess(response));
	} catch (err) {
		yield put(fetchPharmaciesError(err));
	}
}

export function* getPermissions() {
	try {
		const response = yield call(getPermissionsAPI);
		yield put(getPermissionsSuccess(response.data));
	} catch (err) {
		yield put(getPermissionsError(err));
	}
}

export default function* appSaga() {
	yield takeLatest(REMOVE_TOAST_MESSAGE, performRemoveToast);
	yield takeLatest(UPDATE_REQUEST, updateRequest);
	yield takeLatest(FETCH_STATES, fetchStates);
	yield takeEvery(FETCH_CITIES, fetchCities);
	yield takeEvery(FETCH_ZIPCODES, fetchZipcodes);
	yield takeLatest(FETCH_ENROLL_REQ, fetchEnrollReq);
	yield takeLatest(DOWNLOAD_FILE, downloadFile);
	yield takeLatest(FETCH_MEMBER_REQ, fetchMemberReq);
	yield takeLatest(CREATE_REQUEST, createRequest);
	yield takeLatest(DELETE_REQUEST, deleteRequest);
	yield takeLatest(LOAD_CLINICIANS, getClinicians);
	yield takeLatest(FETCH_DOCUMENT_LIST, fetchDocumentList);
	yield takeLatest(PING_SERVER, pingServer);
	yield takeLatest(VIEW_PDF, viewPdf);
	yield takeLatest(FETCH_PHARMACIES, getPharmacies);
	yield takeLatest(GET_PERMISSIONS, getPermissions);
}
