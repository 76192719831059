import {
    CREATE_OQ_CLIENT,
    CREATE_OQ_CLIENT_SUCCESS,
    CREATE_OQ_CLIENT_ERROR,
    CREATE_OQ_EMPLOYEE,
    CREATE_OQ_EMPLOYEE_SUCCESS,
    CREATE_OQ_EMPLOYEE_ERROR,
    GET_OQ_CLIENT,
    GET_OQ_CLIENT_SUCCESS,
    GET_OQ_CLIENT_ERROR,
    GET_OQ_PROFILE,
    GET_OQ_PROFILE_SUCCESS,
    GET_OQ_PROFILE_ERROR,
    GET_OQ_ADMINISTRATION_URL,
    GET_OQ_ADMINISTRATION_URL_SUCCESS,
    GET_OQ_ADMINISTRATION_URL_ERROR,
    SEND_OQ_ADMINISTRATION_SMS,
    SEND_OQ_ADMINISTRATION_SMS_SUCCESS,
    SEND_OQ_ADMINISTRATION_SMS_ERROR,
} from './constants';
import {
    CreateOQClientData,
    CreateOQClientAction,
    CreateOQClientSuccessAction,
    CreateOQClientErrorAction,
    CreateOQEmployeeData,
    CreateOQEmployeeAction,
    CreateOQEmployeeSuccessAction,
    CreateOQEmployeeErrorAction,
    GetOQProfileData,
    GetOQProfileAction,
    GetOQProfileSuccessAction,
    GetOQProfileErrorAction,
    GetOQClientData,
    GetOQClientAction,
    GetOQClientSuccessAction,
    GetOQClientErrorAction,
    GetOQAdministrationURLData,
    GetOQAdministrationURLAction,
    GetOQAdministrationURLSuccessAction,
    GetOQAdministrationURLErrorAction,
    SendOQAdministrationSMSData,
    SendOQAdministrationSMSAction,
    SendOQAdministrationSMSSuccessAction,
    SendOQAdministrationSMSErrorAction,
} from './types';

export function createOQClient(client: CreateOQClientData): CreateOQClientAction {
    return {
        type: CREATE_OQ_CLIENT,
        data: client,
    };
};

export function createOQClientSuccess(client: unknown): CreateOQClientSuccessAction {
    return {
        type: CREATE_OQ_CLIENT_SUCCESS,
        data: client,
    };
};

export function createOQClientError(data: unknown): CreateOQClientErrorAction {
    return {
        type: CREATE_OQ_CLIENT_ERROR,
        data,
    };
};

export function createOQEmployee(employee: CreateOQEmployeeData): CreateOQEmployeeAction {
    return {
        type: CREATE_OQ_EMPLOYEE,
        data: employee,
    };
};

export function createOQEmployeeSuccess(employee: unknown): CreateOQEmployeeSuccessAction {
    return {
        type: CREATE_OQ_EMPLOYEE_SUCCESS,
        data: employee,
    };
};

export function createOQEmployeeError(data: unknown): CreateOQEmployeeErrorAction {
    return {
        type: CREATE_OQ_EMPLOYEE_ERROR,
        data,
    };
};


export function getOQProfile(user: GetOQProfileData): GetOQProfileAction {
    return {
        type: GET_OQ_PROFILE,
        data: user,
    };
};

export function getOQProfileSuccess(data: unknown): GetOQProfileSuccessAction {
    return {
        type: GET_OQ_PROFILE_SUCCESS,
        data,
    };
};

export function getOQProfileError(data: unknown): GetOQProfileErrorAction {
    return {
        type: GET_OQ_PROFILE_ERROR,
        data,
    };
};

export function getOQClient(client: GetOQClientData): GetOQClientAction {
    return {
        type: GET_OQ_CLIENT,
        data: client,
    };
};

export function getOQClientSuccess(client: unknown): GetOQClientSuccessAction {
    return {
        type: GET_OQ_CLIENT_SUCCESS,
        data: client,
    };
};

export function getOQClientError(data: unknown): GetOQClientErrorAction {
    return {
        type: GET_OQ_CLIENT_ERROR,
        data,
    };
};

export function getOQAdministrationURL(client: GetOQAdministrationURLData): GetOQAdministrationURLAction {
    return {
        type: GET_OQ_ADMINISTRATION_URL,
        data: client,
    };
};

// define the data somewhere based on OQ docs
export function getOQAdministrationURLSuccess(url: unknown): GetOQAdministrationURLSuccessAction {
    return {
        type: GET_OQ_ADMINISTRATION_URL_SUCCESS,
        data: url,
    };
};

export function getOQAdministrationURLError(data: unknown): GetOQAdministrationURLErrorAction {
    return {
        type: GET_OQ_ADMINISTRATION_URL_ERROR,
        data,
    };
};

export function sendOQAdministrationSMS(client: SendOQAdministrationSMSData): SendOQAdministrationSMSAction {
    return {
        type: SEND_OQ_ADMINISTRATION_SMS,
        data: client,
    };
};

export function sendOQAdministrationSMSSuccess(data: unknown): SendOQAdministrationSMSSuccessAction {
    return {
        type: SEND_OQ_ADMINISTRATION_SMS_SUCCESS,
        data,
    };
};

export function sendOQAdministrationSMSError(data: unknown): SendOQAdministrationSMSErrorAction {
    return {
        type: SEND_OQ_ADMINISTRATION_SMS_ERROR,
        data,
    };
};

