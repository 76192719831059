import CryptoJS from 'crypto-js';

import { camelCase, capitalize, filter, map, uniq } from 'lodash';

import moment from 'moment';

const dayNumberMap = {
	0: 'Sunday',
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
	6: 'Saturday',
};

const dayMap = {
	Sunday: 0,
	Monday: 1,
	Tuesday: 2,
	Wednesday: 3,
	Thursday: 4,
	Friday: 5,
	Saturday: 6,
};

const weekNumberMap = {
	1: 'first',
	2: 'second',
	3: 'third',
	4: 'fourth',
};

/* eslint prefer-rest-params: 0 */
/* eslint no-param-reassign: 0 */
export function checkNested(obj /* , level1, level2, ... levelN */) {
	const args = Array.prototype.slice.call(arguments, 1);

	for (let i = 0; i < args.length; i++) {
		if (!obj || !Object.hasOwnProperty.call(obj, args[i])) {
			return false;
		}
		if (i === args.length - 1) {
			return obj[args[i]];
		}
		obj = obj[args[i]];
	}
	return false;
}

export function generateObjectArray(stringArray, props) {
	return stringArray.map((item) => ({
		id: camelCase(item),
		name: item,
		...props,
	}));
}

export function capitalizeWords(text) {
	if (text) {
		return text
			.split(' ')
			.map((word) => capitalize(word))
			.join(' ');
	}

	return '';
}

export function returnJS(state) {
	if (state && state.toJS) state = state.toJS();
	return state;
}

export function getRepeatDaysFromFrequency(repeatFrequency, repeatsOn) {
	let repeatDays = 'every';
	const days = [];
	switch (repeatFrequency) {
		case 'Monthly':
			repeatsOn.forEach((row, weekNumber) => {
				row.forEach((day, dayNumber) => {
					if (day) {
						const number = `${weekNumberMap[weekNumber + 1]}`;
						const dayOfWeek = `${dayNumberMap[dayNumber]}`;
						if (days.indexOf(dayOfWeek) === -1) {
							days.push(dayOfWeek);
						}
						repeatDays += ` ${number} ${dayOfWeek},`;
					}
				});
			});
			repeatDays += ' of the month';
			break;

		case 'Weekly':
			if (days.indexOf(repeatsOn[0]) === -1) {
				days.push(repeatsOn[0]);
			}
			repeatDays += ` ${repeatsOn[0]}`;
			break;

		default:
			break;
	}
	return { repeatDays, days };
}

export function getFilterOptions(data, filterOn) {
	let filters = [];

	filters = uniq(map(data, filterOn));
	filters = filters.filter((s) => s !== null);

	return filters;
}

export function maxValue(data, filterOn) {
	let maximumValue = 0;
	data.forEach((row) => {
		if (row[filterOn] > maximumValue) {
			maximumValue = row[filterOn];
		}
	});
	return maximumValue;
}

export function filterData(data, filterOn, value) {
	return filter(data, [filterOn, value]);

	// if (path) {
	//   return data.filter(
	//     row => capitalizeWords(row[path][filterOn]) === capitalizeWords(value),
	//   );
	// }
	// return data.filter(
	//   row => capitalizeWords(row[filterOn]) === capitalizeWords(value),
	// );
}

export function filterByLetter(data, filterOn, letters = '') {
	// Check if we have incomplete data and bail early
	if (!data || typeof letters !== 'string') return data;

	letters = letters.split('');

	return data.filter((row) => {
		let word = row;

		// Access the properties of row object using filterOn
		// filterOn represents property names separated by dots
		// currently doesn't work with more than one filter
		if (filterOn.length > 0) {
			word = filterOn.split('.').reduce((o, i) => o[i], row);
		}

		if (!word) return false;

		return matchesLetters(word, letters);	
	});
}

export function getAttendanceColor(status = '') {
	status = status.toLowerCase();

	switch (status) {
		case 'present':
		case 'p':
		case 'virtual':
		case 'v':
			return 'rgb(155, 192, 118)';

		case 'absent':
		case 'a':
			return 'rgb(227, 121, 134)';

		case 'excused':
		case 'e':
			return 'rgb(238, 182, 89)';

		case 'clear':
		case 'c':
			return 'rgb(66, 66, 66)';

		default:
			return 'rgb(238, 238, 238)';
	}
}

export function getAttendanceHoverColor(status = '') {
	status = status.toLowerCase();

	switch (status) {
		case 'present':
		case 'p':
			return 'rgb(103, 153, 52)';

		case 'absent':
		case 'a':
			return 'rgb(224, 52, 73)';

		case 'excused':
		case 'e':
			return 'rgb(242, 158, 19)';

		case 'clear':
		case 'c':
			return 'rgb(66, 66, 66)';

		default:
			return 'rgb(184, 184, 184)';
	}
}

export function compareTwoDays(day1, day2) {
	return moment(day1).format('D') === moment(day2).format('D');
}

export function compareDayWithToday(day) {
	return moment(day).format('D') === moment().format('D');
}

export function getDayIndex(day) {
	return dayMap[day];
}

export function getDayFromIndex(dayIndex) {
	return dayNumberMap[dayIndex];
}

export function getObjectDifference(obj1, obj2) {
	return Object.keys(obj1).filter(
		(k) => JSON.stringify(obj1[k]) !== JSON.stringify(obj2[k]),
	);
}

export function decryptObject(object, secret) {
	const newObject = Object.assign(object);

	Object.keys(newObject).forEach((key) => {
		const value = object[key];
		if (typeof value === 'object') {
			newObject[key] = decryptObject(value, secret);
		} else {
			const encrypted = CryptoJS.AES.decrypt(value, secret);
			newObject[key] = encrypted.toString(CryptoJS.enc.Utf8);
		}
	});

	return newObject;
}

export function formatAsCreditCard(string) {
	const stringCopy = string.substring(0);
	return stringCopy
		.replace(/[^\dA-Z]/g, '')
		.replace(/(.{4})/g, '$1 ')
		.trim();
}

// Takes a name and returns an array of acronyms
// using the first three letters of the last then first name
// If there are more than two words in the name, returns two acronyms:
// One using the last word as the last name 
// And one using the second word as the last name
// This is a temporary solution until we modify the database
// to store last and first names separately 
export function convertNameToAcronyms(fullName) {
	const acronyms = [];
    const words = fullName.split(' ');
	// create acronym with first and last words
    const firstName = words.at(0)?.slice(0,3) ?? '';
    let lastName = words.length > 1 ? words.at(-1).slice(0, 3) : '';
    acronyms.push(`${lastName}${firstName}`.toUpperCase());
	// create acronym with second word as last name if more than two words
	if(words.length > 2) {
		lastName = words[1].slice(0, 3);
		acronyms.push(`${lastName}${firstName}`.toUpperCase());
	}
	return acronyms;
}

/**
 * Matches letters in a word.
 *
 * @param {string} word - the word to search within
 * @param {string[]} letters - an array of letters to match in the word
 * @return {boolean} whether all letters were found in order in the word
 */
function matchesLetters(word, letters) {
	word = word.toLowerCase();

	let lastIndex = -1;
	return letters.every((char) => {
		char = char.toLowerCase();

		const part = word.substring(lastIndex + 1);
		if (part.includes(char)) {
			lastIndex += part.indexOf(char) + 1;
			return true;
		}

		return false;
	});
}


/**
 * Filters a patient array based on the search string
 * Search string can be patient's name or acronym
 * @param {Array} data - The data to be filtered
 * @param {string} search - The search criteria
 * @return {Array} - The filtered data
 */
export function filterPatients(data, filterOn = 'label', search){
	if (!data || !search) return data;

	return data.filter((row) => {
		const acronyms = convertNameToAcronyms(row[filterOn]);
		const acronymMatch = acronyms.some((acronym) => acronym.includes(search.toUpperCase()));
		const letterMatch = matchesLetters(row.label, search.split(''));
		return acronymMatch || letterMatch;
	});
};
