/**
 *
 * Reports
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import AddNewClient from 'components/AddNewClient';
import ButtonToolBar from 'components/ButtonToolBar';
import FilterNav from 'components/FilterNav';

import { ReportsTable } from './ReportsTable';
import reportFilters from './filters';
import makeSelectReports from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class Reports extends React.Component {
	state = {
		showAddClient: false,
	};

	handleCreate = () => {
		this.setState((prevState) => ({ showAddClient: !prevState.showAddClient }));
	};

	render() {
		const { showAddClient } = this.state;
		const { type } = this.props;

		const buttons = [
			{
				label: 'DOWNLOAD REPORT',
				className: 'btn btn-primary btn-color font-10 mr-2',
			},
		];

		if (type === 'clinician')
			buttons.unshift({
				label: '+ ADD NEW CLIENT',
				className: 'btn btn-primary btn-color font-10 mr-2',
				handleClick: this.handleCreate,
			});

		return (
			<div className="px-5 my-5">
				<FilterNav filtersList={reportFilters} />

				<ButtonToolBar title="List of Clients" buttons={buttons} />

				<div className="justify-content-end mt-3">{<ReportsTable />}</div>
				{showAddClient && <AddNewClient hide={this.handleCreate} />}
			</div>
		);
	}
}

Reports.propTypes = {
	type: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	reports: makeSelectReports(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
