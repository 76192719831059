/*
 *
 * BrowseGroups actions
 *
 */
import {
	ADD_CLIENT_TO_GROUP,
	ADD_CLIENT_TO_GROUP_ERROR,
	ADD_CLIENT_TO_GROUP_SUCCESS,
	CLEAR_PROFILE,
	FETCH_GROUPS,
	FETCH_GROUPS_ERROR,
	FETCH_GROUPS_SUCCESS,
	FETCH_PATIENT_ATTENDANCE,
	FETCH_PATIENT_ATTENDANCE_ERROR,
	FETCH_PATIENT_ATTENDANCE_SUCCESS,
	FETCH_PATIENT_DOCS,
	FETCH_PATIENT_DOCS_ERROR,
	FETCH_PATIENT_DOCS_SUCCESS,
	FETCH_PATIENT_GROUPS,
	FETCH_PATIENT_GROUPS_ERROR,
	FETCH_PATIENT_GROUPS_SUCCESS,
	FETCH_PATIENT_PROFILE,
	FETCH_PATIENT_PROFILE_ERROR,
	FETCH_PATIENT_PROFILE_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	UNENROLL,
	UNENROLL_ERROR,
	UNENROLL_SUCCESS,
	UPDATE_ENROLLMENT,
	UPDATE_ENROLLMENT_ERROR,
	UPDATE_ENROLLMENT_SUCCESS,
	UPDATE_PATIENT_INFO,
	UPDATE_PATIENT_INFO_ERROR,
	UPDATE_PATIENT_INFO_SUCCESS,
} from './constants';

export function fetchPatientProfile(id) {
	return {
		type: FETCH_PATIENT_PROFILE,
		id,
	};
}
export function fetchPatientProfileSuccess(payload) {
	return {
		type: FETCH_PATIENT_PROFILE_SUCCESS,
		payload,
	};
}
export function fetchPatientProfileError(error) {
	return {
		type: FETCH_PATIENT_PROFILE_ERROR,
		error,
	};
}

export function updatePatientInfo(data, callback) {
	return {
		type: UPDATE_PATIENT_INFO,
		data,
		callback,
	};
}
export function updatePatientInfoSuccess(payload) {
	return {
		type: UPDATE_PATIENT_INFO_SUCCESS,
		payload,
	};
}
export function updatePatientInfoError(error) {
	return {
		type: UPDATE_PATIENT_INFO_ERROR,
		error,
	};
}

export function fetchPatientGroups(id) {
	return {
		type: FETCH_PATIENT_GROUPS,
		id,
	};
}
export function fetchPatientGroupsSuccess(payload) {
	return {
		type: FETCH_PATIENT_GROUPS_SUCCESS,
		payload,
	};
}
export function fetchPatientGroupsError(error) {
	return {
		type: FETCH_PATIENT_GROUPS_ERROR,
		error,
	};
}

export function fetchGroups() {
	return {
		type: FETCH_GROUPS,
	};
}
export function fetchGroupsSuccess(data) {
	return {
		type: FETCH_GROUPS_SUCCESS,
		data,
	};
}
export function fetchGroupsError(error) {
	return {
		type: FETCH_GROUPS_ERROR,
		error,
	};
}

export function addClientToGroup(data, cb) {
	return {
		type: ADD_CLIENT_TO_GROUP,
		data,
		cb,
	};
}
export function addClientToGroupSuccess(data) {
	return {
		type: ADD_CLIENT_TO_GROUP_SUCCESS,
		data,
	};
}
export function addClientToGroupError(error) {
	return {
		type: ADD_CLIENT_TO_GROUP_ERROR,
		error,
	};
}

export function getSessions(data) {
	return {
		type: GET_SESSIONS,
		data,
	};
}
export function getSessionsSuccess(data) {
	return {
		type: GET_SESSIONS_SUCCESS,
		data,
	};
}
export function getSessionsError(error) {
	return {
		type: GET_SESSIONS_ERROR,
		error,
	};
}

export function unenroll(data, callback) {
	return {
		type: UNENROLL,
		data,
		callback,
	};
}
export function unenrollSuccess(payload) {
	return {
		type: UNENROLL_SUCCESS,
		payload,
	};
}
export function unenrollError(error) {
	return {
		type: UNENROLL_ERROR,
		error,
	};
}

export function updateEnrollment(data, callback) {
	return {
		type: UPDATE_ENROLLMENT,
		data,
		callback,
	};
}
export function updateEnrollmentSuccess(payload) {
	return {
		type: UPDATE_ENROLLMENT_SUCCESS,
		payload,
	};
}
export function updateEnrollmentError(error) {
	return {
		type: UPDATE_ENROLLMENT_ERROR,
		error,
	};
}

export function fetchPatientAttendance(id) {
	return {
		type: FETCH_PATIENT_ATTENDANCE,
		id,
	};
}
export function fetchPatientAttendanceSuccess(payload) {
	return {
		type: FETCH_PATIENT_ATTENDANCE_SUCCESS,
		payload,
	};
}
export function fetchPatientAttendanceError(error) {
	return {
		type: FETCH_PATIENT_ATTENDANCE_ERROR,
		error,
	};
}

export function fetchPatientDocs(id) {
	return {
		type: FETCH_PATIENT_DOCS,
		id,
	};
}
export function fetchPatientDocsSuccess({ userDocs, requests }) {
	return {
		type: FETCH_PATIENT_DOCS_SUCCESS,
		userDocs,
		requests,
	};
}
export function fetchPatientDocsError(error) {
	return {
		type: FETCH_PATIENT_DOCS_ERROR,
		error,
	};
}

export function clearProfile() {
	return {
		type: CLEAR_PROFILE,
	};
}
