import PropTypes from 'prop-types';

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';

/**
 *
 * <CustomTimePicker />, a functional component for TimePicker
 */
const CustomTimePicker = (props) => {
	const { label, handleTimeInput, className, selected, id, minTime, maxTime } =
		props;

	const modifiedClass = `${className}`;

	return (
		<div className={modifiedClass}>
			<label htmlFor={id} className="form-font">
				{label}
			</label>
			<DatePicker
				className="date-border w-100"
				selected={selected}
				onChange={handleTimeInput}
				minTime={minTime}
				maxTime={maxTime}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={5}
				dateFormat="h:mm aa"
				// timeCaption="Time"
			/>
		</div>
	);
};

CustomTimePicker.propTypes = {
	handleTimeInput: PropTypes.func.isRequired,
	label: PropTypes.string,
	selected: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	minTime: PropTypes.object,
	maxTime: PropTypes.object,
};

export default CustomTimePicker;
