/*
 *
 * UserDropDown constants
 *
 */

export const DEFAULT_ACTION = 'src/SignIn/DEFAULT_ACTION';

export const TEST_TELEHEALTH = 'src/Dashboard/TEST_TELEHEALTH';
export const TEST_TELEHEALTH_SUCCESS = 'src/Dashboard/TEST_TELEHEALTH_SUCCESS';
export const TEST_TELEHEALTH_ERROR = 'src/Dashboard/TEST_TELEHEALTH_ERROR';

export const TEST_ROI = 'src/Dashboard/TEST_ROI';
export const TEST_ROI_SUCCESS = 'src/Dashboard/TEST_ROI_SUCCESS';
export const TEST_ROI_ERROR = 'src/Dashboard/TEST_ROI_ERROR';

export const TEST_QUESTIONNAIRE = 'src/Dashboard/TEST_QUESTIONNAIRE';
export const TEST_QUESTIONNAIRE_SUCCESS =
	'src/Dashboard/TEST_QUESTIONNAIRE_SUCCESS';
export const TEST_QUESTIONNAIRE_ERROR =
	'src/Dashboard/TEST_QUESTIONNAIRE_ERROR';

export const TEST_INTAKE_NEW = 'src/Dashboard/TEST_INTAKE_NEW';
export const TEST_INTAKE_NEW_SUCCESS = 'src/Dashboard/TEST_INTAKE_NEW_SUCCESS';
export const TEST_INTAKE_NEW_ERROR = 'src/Dashboard/TEST_INTAKE_NEW_ERROR';

export const TEST_ENROLLMENT = 'src/Dashboard/TEST_ENROLLMENT';
export const TEST_ENROLLMENT_SUCCESS = 'src/Dashboard/TEST_ENROLLMENT_SUCCESS';
export const TEST_ENROLLMENT_ERROR = 'src/Dashboard/TEST_ENROLLMENT_ERROR';

export const FETCH_DOC_LOG = 'src/Dashboard/FETCH_DOC_LOG';
export const FETCH_DOC_LOG_SUCCESS = 'src/Dashboard/FETCH_DOC_LOG_SUCCESS';
export const FETCH_DOC_LOG_ERROR = 'src/Dashboard/FETCH_DOC_LOG_ERROR';

export const DO_MAGIC = 'src/Dashboard/DO_MAGIC';
export const DO_MAGIC_SUCCESS = 'src/Dashboard/DO_MAGIC_SUCCESS';
export const DO_MAGIC_ERROR = 'src/Dashboard/DO_MAGIC_ERROR';
