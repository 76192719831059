import PropTypes from 'prop-types';

import qs from 'query-string';

import React from 'react';

/**
 *
 * UserForgotPassword
 *
 */
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import PasswordInput from 'atoms/PasswordInput';
import ForgotPassResult from 'components/ForgotPassResult';
import imgLogo from 'images/logo_header.svg';

import { forgotUpdatePassword } from './actions';
import makeSelectUserForgotPassword from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class UserForgotPassword extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			newPassword: '',
			confirmPassword: '',
			errorPassword: false,
			errorConfirmPassword: false,
			errorPasswordMatch: false,
			forgotUpdatePasswordMsg: '',
			showSuccessModal: false,
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	passwordError = () => {
		let errorPassword = false;
		let errorConfirmPassword = false;
		let errorPasswordMatch = false;
		const { newPassword, confirmPassword } = this.state;

		// eslint-disable-next-line no-unused-expressions
		!newPassword ? (errorPassword = true) : (errorPassword = false);
		// eslint-disable-next-line no-unused-expressions
		!confirmPassword
			? (errorConfirmPassword = true)
			: (errorConfirmPassword = false);

		if (newPassword && confirmPassword && newPassword !== confirmPassword) {
			errorPasswordMatch = true;
		}
		this.setState({
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
		});

		if (errorPassword || errorConfirmPassword || errorPasswordMatch) {
			return true;
		}
		return false;
	};

	handleSubmit = () => {
		const isPasswordError = this.passwordError();
		const { newPassword } = this.state;
		if (!isPasswordError) {
			const value = qs.parse(this.props.location.search);
			const { token } = value;
			this.props.forgotUpdatePassword({ token, newPassword });
			this.setState({
				showSuccessModal: true,
			});
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.userForgotPassword.forgotUpdatePasswordMsg) {
			this.setState({
				forgotUpdatePasswordMsg:
					nextProps.userForgotPassword.forgotUpdatePasswordMsg,
			});
		}
	}

	render() {
		const {
			newPassword,
			confirmPassword,
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
			forgotUpdatePasswordMsg,
		} = this.state;

		return (
			<div className="container border col-4 mt-5 p-3 bg-white">
				<div className="d-flex justify-content-center mt-3">
					<img src={imgLogo} alt="Mind Therapy" />
				</div>
				<div className="form-group mt-4">
					<PasswordInput
						label={
							<label className="form-font" htmlFor="NewPassword">
								NEW PASSWORD
							</label>
						}
						value={newPassword}
						onChange={this.handleChange}
						name="newPassword"
						className={`form-control ${errorPassword ? 'is-invalid' : null}`}
						id="newPassword"
						aria-describedby="passwordHelp"
						placeholder="Enter new password"
					/>
				</div>
				{errorPassword && (
					<div className="invalid-feedback">Please enter a password.</div>
				)}
				<div className="form-group mt-3">
					<PasswordInput
						label={
							<label className="form-font" htmlFor="ConfirmPassword">
								CONFIRM NEW PASSWORD
							</label>
						}
						value={confirmPassword}
						onChange={this.handleChange}
						name="confirmPassword"
						className={`form-control ${
							errorConfirmPassword || errorPasswordMatch ? 'is-invalid' : null
						}`}
						id="confirmPassword"
						aria-describedby="ConfirmPasswordHelp"
						placeholder="Confirm new password"
					/>
					{errorConfirmPassword && (
						<div className="invalid-feedback">Re-enter your password.</div>
					)}
					{errorPasswordMatch && (
						<div className="invalid-feedback">Password does not match</div>
					)}
				</div>
				<div className="mt-4 mb-2">
					<button
						onClick={this.handleSubmit}
						type="button"
						className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
					>
						RESET PASSWORD
					</button>
				</div>
				{this.state.showSuccessModal && (
					<ForgotPassResult
						message={forgotUpdatePasswordMsg}
					></ForgotPassResult>
				)}
			</div>
		);
	}
}

UserForgotPassword.propTypes = {
	forgotUpdatePassword: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	userForgotPassword: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
	userForgotPassword: makeSelectUserForgotPassword(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		forgotUpdatePassword: (data) => dispatch(forgotUpdatePassword(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForgotPassword);
