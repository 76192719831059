import history from 'utils/history';

import React, { useEffect } from 'react';

import Table from 'atoms/Table';

import columns from './columns';

const Absences = (props) => {
	const { data, role, markRead } = props;

	const goToProfile = (id) => {
		history.push({
			pathname: `/${role}/dashboard/profile/${id}`,
		});
	};

	useEffect(() => {
		const unread = [];
		for (let notif of data) {
			if (!notif.hasSeen) {
				unread.push(notif.id);
			}
		}

		if (unread.length > 0) {
			markRead(unread);
		}
	});

	return (
		<div>
			<Table
				keyField="absences"
				data={data}
				columns={columns({
					goToProfile,
				})}
				placeholder="No absences to display"
			/>
		</div>
	);
};

export default Absences;
