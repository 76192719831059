/*
 *
 * Dashboard actions
 *
 */
import {
	DEFAULT_ACTION,
	DISABLE_SCROLL,
	ENABLE_SCROLL,
	TOGGLE_SCROLL,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function enableScroll() {
	return {
		type: ENABLE_SCROLL,
	};
}

export function disableScroll() {
	return {
		type: DISABLE_SCROLL,
	};
}

export function toggleScroll() {
	return {
		type: TOGGLE_SCROLL,
	};
}
