import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import TextInput from 'atoms/TextInput';
import tick from 'images/tick.gif';
import { email as emailValidation } from 'utils/validations';

/**
 *
 * ForgotPass
 *
 */

/* eslint-disable react/prefer-stateless-function */
class ForgotPass extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			email: '',
			forgotPasswordMessage: '',
		};
	}

	toggle = () => {
		const { toggle } = this.props;
		toggle();
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.forgotPasswordMessage) {
			this.setState({
				forgotPasswordMessage: nextProps.forgotPasswordMessage,
			});
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
		this.setState({ forgotPasswordMessage: '' });
	};

	handleEmailSubmit = () => {
		const data = {};
		data.email = this.state.email.toLowerCase();
		this.props.forgotPassword(data);
	};

	render() {
		const { email, forgotPasswordMessage } = this.state;

		return (
			<Modal
				className="modal-dialog-centered"
				isOpen={this.state.modal}
				toggle={this.toggle}
			>
				<ModalHeader toggle={this.toggle} className="border-remove" />
				<ModalBody>
					<div className="container-fluid">
						<div className="row">
							{forgotPasswordMessage.includes('sent') ? (
								<div className="col-12 d-flex justify-content-center flex-column">
									<img
										src={tick}
										className="align-self-center"
										height="150"
										width="150"
										alt="tick"
									/>
									<h3 className="pt-5 text-center">{forgotPasswordMessage}</h3>
								</div>
							) : (
								<div className="col-12">
									<h3 className="m-0 heading-update-email">
										Enter your email address
									</h3>
									<div className="d-flex justify-content-center error-font mt-3">
										{forgotPasswordMessage}
									</div>
									<Form className="form-group mt-1">
										<TextInput
											label="EMAIL"
											id="email"
											value={email}
											placeholder="Enter your email"
											handleInput={this.handleChange}
											validations={[emailValidation]}
										/>
									</Form>
									<div className="mt-4">
										<button
											onClick={this.handleEmailSubmit}
											type="button"
											className="btn btn-primary btn-color btn-lg btn-block font-weight-bold d-flex justify-content-center font-15 text-uppercase font-13"
										>
											GET LINK TO RESET PASSWORD
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

ForgotPass.propTypes = {
	toggle: PropTypes.func.isRequired,
	forgotPassword: PropTypes.func.isRequired,
	forgotPasswordMessage: PropTypes.string,
};

export default ForgotPass;
