import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the patientIntake state domain
 */

const selectPatientIntakeDomain = (state) =>
	state.get('patientIntake', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PatientIntake
 */

const makeSelectPatientIntake = () =>
	createSelector(selectPatientIntakeDomain, (substate) => returnJS(substate));

const makeSelectCreateDocSuccess = () =>
	createSelector(selectPatientIntakeDomain, (substate) =>
		returnJS(substate.get('createDocSuccess')),
	);

const makeSelectCreatingDoc = () =>
	createSelector(selectPatientIntakeDomain, (substate) =>
		returnJS(substate.get('creatingDoc')),
	);

export default makeSelectPatientIntake;
export {
	selectPatientIntakeDomain,
	makeSelectCreateDocSuccess,
	makeSelectCreatingDoc,
};
