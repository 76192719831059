/*
 *
 * UserDropDown actions
 *
 */
import {
	DEFAULT_ACTION,
	SUBMIT_FEEDBACK,
	SUBMIT_FEEDBACK_ERROR,
	SUBMIT_FEEDBACK_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function submitFeedback(payload) {
	return {
		type: SUBMIT_FEEDBACK,
		payload,
	};
}
export function submitFeedbackSuccess() {
	return {
		type: SUBMIT_FEEDBACK_SUCCESS,
	};
}
export function submitFeedbackError(error) {
	return {
		type: SUBMIT_FEEDBACK_ERROR,
		error,
	};
}
