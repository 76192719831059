import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the clinician state domain
 */

const selectClinicianDomain = (state) => state.get('clinician', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Clinician
 */

const makeSelectClinician = () =>
	createSelector(selectClinicianDomain, (substate) => returnJS(substate));

export default makeSelectClinician;
export { selectClinicianDomain };
