/*
 *
 * Forms actions
 *
 */
import {
	CLEAR_UPLOAD_RESULT,
	CREATE_SIGNING_DOC,
	CREATE_SIGNING_DOC_ERROR,
	CREATE_SIGNING_DOC_SUCCESS,
	DECLINE_FORM,
	DECLINE_FORM_ERROR,
	DECLINE_FORM_SUCCESS,
	DELETE_FORM,
	DELETE_FORM_ERROR,
	DELETE_FORM_SUCCESS,
	FETCH_CLIENTS,
	FETCH_CLIENTS_ERROR,
	FETCH_CLIENTS_SUCCESS,
	FETCH_FORM,
	FETCH_FORMS,
	FETCH_FORMS_ERROR,
	FETCH_FORMS_SUCCESS,
	FETCH_FORM_ERROR,
	FETCH_FORM_SUCCESS,
	FETCH_GROUPS,
	FETCH_GROUPS_ERROR,
	FETCH_GROUPS_SUCCESS,
	SHARE_FORM,
	SHARE_FORM_ERROR,
	SHARE_FORM_SUCCESS,
	SHOW_FORM_SHARE,
	SIGN_DOC,
	SIGN_DOC_ERROR,
	SIGN_DOC_SUCCESS,
	SUBMIT_JOTFORM,
	SUBMIT_JOTFORM_ERROR,
	SUBMIT_JOTFORM_SUCCESS,
	UPDATE_FORM,
	UPDATE_FORM_ERROR,
	UPDATE_FORM_SUCCESS,
	UPLOAD_FORMS,
	UPLOAD_FORMS_ERROR,
	UPLOAD_FORMS_RESPONSE,
} from './constants';

export function signDoc(id) {
	return {
		type: SIGN_DOC,
		id,
	};
}

export function signDocSuccess(url) {
	return {
		type: SIGN_DOC_SUCCESS,
		url,
	};
}

export function signDocError(err) {
	return {
		type: SIGN_DOC_ERROR,
		err,
	};
}

export function submitJotform(data) {
	return {
		type: SUBMIT_JOTFORM,
		data,
	};
}

export function submitJotformSuccess(permissions) {
	return {
		type: SUBMIT_JOTFORM_SUCCESS,
		permissions,
	};
}

export function submitJotformError(err) {
	return {
		type: SUBMIT_JOTFORM_ERROR,
		err,
	};
}

export function showFormShare(formShareVisible) {
	return {
		type: SHOW_FORM_SHARE,
		formShareVisible: !formShareVisible,
	};
}

export function clearUploadResult() {
	return {
		type: CLEAR_UPLOAD_RESULT,
	};
}

export function uploadForms(files) {
	return {
		type: UPLOAD_FORMS,
		files,
	};
}
export function uploadFormsResponse(res) {
	return {
		type: UPLOAD_FORMS_RESPONSE,
		res,
	};
}
export function uploadFormsError(errors) {
	return {
		type: UPLOAD_FORMS_ERROR,
		errors,
	};
}

export function deleteForm(uuid) {
	return {
		type: DELETE_FORM,
		uuid,
	};
}
export function deleteFormSuccess(document) {
	return {
		type: DELETE_FORM_SUCCESS,
		document,
	};
}
export function deleteFormError(error) {
	return {
		type: DELETE_FORM_ERROR,
		error,
	};
}

export function createSigningDoc({ clients, uuid, filename }, cb) {
	return {
		type: CREATE_SIGNING_DOC,
		uuid,
		filename,
		receivers: clients,
		cb,
	};
}
export function createSigningDocSuccess(result) {
	return {
		type: CREATE_SIGNING_DOC_SUCCESS,
		result,
	};
}
export function createSigningDocError(error) {
	return {
		type: CREATE_SIGNING_DOC_ERROR,
		error,
	};
}

export function fetchForms(files) {
	return {
		type: FETCH_FORMS,
		files,
	};
}
export function fetchFormsSuccess(forms) {
	return {
		type: FETCH_FORMS_SUCCESS,
		forms,
	};
}
export function fetchFormsError(errors) {
	return {
		type: FETCH_FORMS_ERROR,
		errors,
	};
}

export function fetchForm(id) {
	return {
		type: FETCH_FORM,
		id,
	};
}
export function fetchFormSuccess(form) {
	return {
		type: FETCH_FORM_SUCCESS,
		form,
	};
}
export function fetchFormError(errors) {
	return {
		type: FETCH_FORM_ERROR,
		errors,
	};
}

export function declineForm(id, reason) {
	return {
		type: DECLINE_FORM,
		id,
		reason,
	};
}
export function declineFormSuccess(form) {
	return {
		type: DECLINE_FORM_SUCCESS,
		form,
	};
}
export function declineFormError(errors) {
	return {
		type: DECLINE_FORM_ERROR,
		errors,
	};
}

export function fetchClients(role) {
	return {
		type: FETCH_CLIENTS,
		role,
	};
}
export function fetchClientsSuccess(clients) {
	return {
		type: FETCH_CLIENTS_SUCCESS,
		clients,
	};
}
export function fetchClientsError(errors) {
	return {
		type: FETCH_CLIENTS_ERROR,
		errors,
	};
}

export function fetchGroups(id) {
	return {
		type: FETCH_GROUPS,
		id,
	};
}
export function fetchGroupsSuccess(groups) {
	return {
		type: FETCH_GROUPS_SUCCESS,
		groups,
	};
}
export function fetchGroupsError(errors) {
	return {
		type: FETCH_GROUPS_ERROR,
		errors,
	};
}

export function updateForm(data, uuid, cb) {
	return {
		type: UPDATE_FORM,
		data,
		uuid,
		cb,
	};
}
export function updateFormSuccess(document) {
	return {
		type: UPDATE_FORM_SUCCESS,
		document,
	};
}
export function updateFormError(error) {
	return {
		type: UPDATE_FORM_ERROR,
		error,
	};
}

export function shareForm(uuid, cb) {
	return {
		type: SHARE_FORM,
		uuid,
		cb,
	};
}
export function shareFormSuccess(file) {
	return {
		type: SHARE_FORM_SUCCESS,
		file,
	};
}
export function shareFormError(error) {
	return {
		type: SHARE_FORM_ERROR,
		error,
	};
}
