import { parseISO } from 'date-fns';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'react-validation/build/button';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextInput from 'atoms/TextInput';
import GeoDropdown from 'components/GeoDropdown';
import loadIcon from 'images/load-icon.gif';
import { generateObjectArray } from 'utils/helpers';
import { email as emailValidation, required } from 'utils/validations';

import './index.css';

/**
 *
 * AddNewClient
 *
 */

/* eslint-disable react/prefer-stateless-function */
class AddNewClient extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			clientName: '',
			email: '',
			gender: null,
			birthDate: '',
			maritalStatus: '',
			address: {
				street: '',
				state: null,
				city: null,
			},
			guardian: '',
			relationshipToMinor: '',
			phoneNumber: '',
			referrer: '',
			clientAlreadyEnrolled: 'no',
			enrolledGroups: [],
			clientType: null,
			showLoading: false,
		};
	}

	toggle = () => {
		const { hide } = this.props;
		hide();
	};

	handleMultiSelect = (event) => {
		let { enrolledGroups } = this.state;
		enrolledGroups = event;
		this.setState({ enrolledGroups });
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		if (name === 'street') {
			const { address } = this.state;

			address[name] = value;

			this.setState({
				address,
			});
		} else {
			this.setState({ [name]: value });
		}
	};

	handleNumberChange = (id, values) => {
		this.setState({ [id]: values.value });
	};

	handleDateChange = (id) => (date) => {
		let dateFormat;

		if (date) {
			dateFormat = parseISO(moment(date).format('YYYY-MM-DD'));
		}

		this.setState({ [id]: dateFormat });
	};

	handleSubmit = () => {
		const { addNewClient } = this.props;
		const data = this.state;
		data.address.state = data.address.state.value;
		data.address.city = data.address.city.value;
		addNewClient(data);
		this.setState({
			showLoading: true,
		});
		// hide();
	};

	hide = () => {
		const { hide } = this.props;
		hide();
	};

	handleGeoChange = () => (type) => (data) => {
		const { handleGeoChange } = this.props;
		const { address } = this.state;
		if (type === 'state') {
			address.city = null;
			// doFetchCities(data.value, data.label);
		}
		address[type] = data;
		this.setState({
			address,
		});
		handleGeoChange(type, data);
	};

	render() {
		const {
			clientName,
			email,

			gender,
			birthDate,
			maritalStatus,
			address,
			guardian,
			relationshipToMinor,
			phoneNumber,
			referrer,
			clientType,
			clientAlreadyEnrolled,
			enrolledGroups,
			showLoading,
		} = this.state;

		let { programs, states, cities } = this.props;
		programs = programs.map((item) => ({
			label: item.groupName,
			value: item.id,
		}));
		return (
			<div>
				<Form>
					<Modal
						className="modal-dialog-centered"
						isOpen="true"
						toggle={this.toggle}
					>
						<ModalHeader toggle={this.toggle} className="border-remove" />
						<ModalBody>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12">
										<h3 className="m-0 heading-add-new-client">
											Add New Client
										</h3>
										<div className="mt-3">
											<TextInput
												label="CLIENT NAME*"
												id="clientName"
												value={clientName}
												placeholder="John Lucan Doe"
												handleInput={this.handleChange}
												validations={[required]}
											/>
										</div>
										<div className="mt-3">
											<TextInput
												label="EMAIL*"
												id="email"
												value={email}
												placeholder="Enter Email Address"
												handleInput={this.handleChange}
												validations={[required, emailValidation]}
											/>
										</div>
										<div className="form-row">
											<div className="col-md-6 mb-0">
												<PhoneNumberInput
													label="PHONE NUMBER*"
													id="phoneNumber"
													value={phoneNumber}
													placeholder="Enter 10 Digit Phone Number"
													handleInput={this.handleNumberChange}
													customClass="py-2"
												/>
											</div>
											<div className="col-md-6 mb-0">
												<label className="form-font" htmlFor="gender">
													GENDER*
												</label>
												<Select
													value={gender}
													className="custom-select"
													id="gender"
													name="gender"
													onChange={this.handleChange}
													style={{ height: 38, fontSize: 16 }}
												>
													<option value="">Select Gender</option>
													<option value="male">Male</option>
													<option value="female">Female</option>
													<option value="other">Other</option>
												</Select>
											</div>
										</div>
										<div className="form-row" />
										<div className="form-row mb-3">
											<div className="col-md-6">
												<CustomDatePicker
													label={<span>DATE OF BIRTH*</span>}
													id="birthDate"
													maxDate={new Date()}
													selected={birthDate}
													handleDateChange={this.handleDateChange('birthDate')}
													placeholderText="Select Date of Birth"
													customClass="p-2 border w-100 font-14"
												/>
											</div>
											<div className="col-md-6">
												<label className="form-font" htmlFor="maritalStatus">
													MARITAL STATUS
												</label>
												<Select
													value={maritalStatus}
													className="custom-select"
													id="maritalStatus"
													name="maritalStatus"
													onChange={this.handleChange}
													style={{ height: 45, fontSize: 16 }}
												>
													<option value="">Select Marital Status</option>
													<option value="single">Single</option>
													<option value="married">Married</option>
													<option value="divorced">Divorced</option>
													<option value="separated">Separated</option>
													<option value="widow">Widow</option>
													<option value="partner">Partner</option>
												</Select>
											</div>
										</div>
										<div>
											<TextInput
												label="STREET ADDRESS*"
												id="street"
												value={address.street}
												placeholder="Enter Street Address"
												handleInput={this.handleChange}
												validations={[required]}
											/>
										</div>
										<GeoDropdown
											states={states}
											cities={address.state ? cities[address.state.value] : []}
											zipcodes={[]}
											handleChange={this.handleGeoChange('asas')}
											state={address.state}
											city={address.city}
											zipcode={address.zipcode}
											isRequired
											zipCodeNotRequired
										/>
										<div className="form-row">
											<div className="col-md-6">
												<TextInput
													label="REFERRER*"
													id="referrer"
													value={referrer}
													placeholder="Enter Referrer"
													handleInput={this.handleChange}
												/>
											</div>
											<div className="col-md-6">
												<label className="form-font" htmlFor="clientType">
													CLIENT TYPE*
												</label>
												<Select
													value={clientType}
													className="custom-select"
													id="clientType"
													name="clientType"
													onChange={this.handleChange}
												>
													<option value="">Select Client Type</option>
													<option value="Adult">Outpatient - Adult</option>
													<option value="Child">Outpatient - Child</option>
													<option value="IOP 3 day">IOP 3 day</option>
													<option value="IOP 4 day">IOP 4 day</option>
													<option value="IOP 5 day">IOP 5 day</option>
													<option value="PHP 4 day">PHP 4 day</option>
													<option value="PHP 5 day">PHP 5 day</option>
												</Select>
											</div>
										</div>
										{clientType === 'Child' && (
											<div className="form-row">
												<div className="col-md-12">
													<TextInput
														label="PARENT/GUARDIAN"
														id="guardian"
														value={guardian}
														placeholder="Enter Legal Guardian for Minors"
														handleInput={this.handleChange}
													/>
												</div>
												<div className="col-md-12">
													<TextInput
														label="RELATIONSHIP TO MINOR"
														id="relationshipToMinor"
														value={relationshipToMinor}
														placeholder="Relationship to Minor"
														handleInput={this.handleChange}
													/>
												</div>
											</div>
										)}
										<div>
											<label className="form-font" htmlFor="groupToEnroll">
												IS THIS AN ESTABLISHED CLIENT?
											</label>
											<SelectButtonGroup
												id="clientAlreadyEnrolled"
												value={clientAlreadyEnrolled}
												buttons={generateObjectArray(['Yes', 'No'])}
												handleSelect={this.handleChange}
												className="pl-0 m-1 col-8"
											/>
										</div>
										{clientAlreadyEnrolled === 'yes' && (
											<div>
												<label className="form-font" htmlFor="groupToEnroll">
													GROUP TO ENROLL*
												</label>
												<SelectAutoComplete
													multiple
													handleChange={this.handleMultiSelect}
													options={programs}
													value={enrolledGroups}
													placeholder="Select Therapy"
												/>
											</div>
										)}

										<div className="mt-5 mb-4 mr-3 d-flex justify-content-end">
											<div>
												<button
													onClick={this.hide}
													type="button"
													className="btn btn-white btn-lg btn-block link-color border font-15"
												>
													CANCEL
												</button>
											</div>
											{!address.state ||
											!address.city ||
											(phoneNumber && phoneNumber.length < 10) ||
											!phoneNumber ||
											!gender ||
											!clientType ||
											(clientAlreadyEnrolled === 'yes' &&
												enrolledGroups.length === 0) ? (
												<div>
													<button
														disabled
														onClick={this.hide}
														type="button"
														className="btn btn-primary btn-lg btn-block btn-color font-15 ml-3"
													>
														SAVE
													</button>
												</div>
											) : (
												<div>
													<Button
														onClick={this.handleSubmit}
														type="button"
														className="btn btn-primary btn-lg btn-block btn-color font-15 ml-3"
													>
														{showLoading ? (
															<img
																src={loadIcon}
																alt="Loading..."
																height="23px"
															/>
														) : (
															<span>SAVE</span>
														)}
													</Button>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				</Form>
			</div>
		);
	}
}

AddNewClient.propTypes = {
	hide: PropTypes.func.isRequired,
	programs: PropTypes.array,
	states: PropTypes.array,
	cities: PropTypes.array,
	handleGeoChange: PropTypes.func,
	addNewClient: PropTypes.func,
};

export default AddNewClient;
