import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the rxRequests state domain
 */

const selectRxRequestsDomain = (state) => state.get('rxRequests', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by RxRequests
 */

const makeSelectRxRequests = () =>
	createSelector(selectRxRequestsDomain, (substate) => returnJS(substate));

export default makeSelectRxRequests;
export { selectRxRequestsDomain };
