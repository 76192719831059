import ActionButton from 'atoms/ActionButton';
import ActionButtonWithComment from 'atoms/ActionButtonWithComment';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import approveImage from 'svgs/approve';
import deleteImage from 'svgs/delete';
import denyImage from 'svgs/deny';
import hideImage from 'svgs/hide';
import showImage from 'svgs/show';

const generateButtons = (row, actions) => {
	const { requestState, requestId, parentTypeId, isActive } = row;

	const loading = (
		<SmallLoadingIndicator
			color="var(--main-color)"
			style={{
				height: 30,
				width: 30,
			}}
			className="my-2"
		/>
	);

	const approveRequest = (
		<ActionButton
			image={approveImage}
			hoverColor={'var(--main-color)'}
			alt="Approve Request"
			onClick={() => actions.approve(requestId)}
		/>
	);

	const denyRequest = (
		<ActionButtonWithComment
			image={denyImage}
			hoverColor={'var(--main-alert)'}
			alt="Deny Request"
			handleSubmit={(comment) => actions.deny(requestId, comment)}
			title={'Reason for Denial'}
			id={`${requestId}-denial`}
		/>
	);

	const deactivateRequest = (
		<ActionButton
			image={hideImage}
			hoverColor={'var(--main-alert)'}
			alt="Deactivate Request"
			onClick={() => actions.deactivate(requestId)}
		/>
	);

	const activateRequest = (
		<ActionButton
			image={showImage}
			hoverColor={'var(--main-color)'}
			alt="Activate Request"
			onClick={() => actions.activate(requestId)}
		/>
	);

	const deleteRequest = (
		<ActionButton
			image={deleteImage}
			hoverColor={'var(--main-alert)'}
			alt="Delete Request"
			onClick={() => actions.delete(requestId)}
		/>
	);

	if (!isActive) {
		return [activateRequest, deleteRequest];
	}

	const stateToButtons = {
		UPDATING: [loading],
		OPEN: [deactivateRequest, deleteRequest],
		ENROLLMENT_AGREEMENT_SENT: [deactivateRequest],
		PENDING_APPROVAL: [
			approveRequest,
			denyRequest,
			deactivateRequest,
			deleteRequest,
		],
		'AWAITING SIGNATURE': [deactivateRequest, deleteRequest],
		ENROLLEMNT_AGREEMENT_SIGNED: [
			approveRequest,
			denyRequest,
			deactivateRequest,
		],
		DENIED: [approveRequest],
		APPROVED: [denyRequest],
	};

	if (parentTypeId !== 1) {
		stateToButtons.OPEN = [approveRequest, denyRequest, ...stateToButtons.OPEN];
	}

	return stateToButtons[requestState] || [];
};

export { generateButtons };

/* generateActions = (row) => {
    const {
      auth: {
        loggedInUser: { roleName },
      },
    } = this.props;
    const { requestState, requestType } = row;

    let result = <div>-</div>;

    if (roleName === 'patient') {
      if (requestState === 'AWAITING SIGNATURE') {
        result = (
          <CustomButton
            type="primary"
            title="Sign Now"
            onClick={() => this.props.signDoc(row.requestId)}
          />
        );
      }
    } else {
      if (requestState === 'OPEN') {
        if (requestType === 'DISCONTINUANCE') {
          result = (
            <div
              className="link-color mt-2 mx-2 font-13 hand"
              cursor="pointer"
              onClick={() => this.showRequest(row)}
            >
              VIEW REQUEST
            </div>
          );
        } else {
          result = (
            <ApproveDenyCell
              approve={this.approve}
              deny={this.deny}
              requestId={row.requestId}
            />
          );
        }
      } else if (requestState === 'UPDATING') {
        result = (
          <SmallLoadingIndicator
            color="var(--main-color)"
            style={{
              height: 30,
              width: 30,
            }}
            className="my-2"
          />
        );
      }
    }

    return result;
  }; */
