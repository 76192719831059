import PropTypes from 'prop-types';

import React from 'react';
import Button from 'react-validation/build/button';
import Form from 'react-validation/build/form';

import PhoneNumberInput from 'atoms/PhoneNumberInput';

/* eslint-disable indent */
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextInput from 'atoms/TextInput';
import GeoDropdown from 'components/GeoDropdown';
import { generateObjectArray } from 'utils/helpers';
import { required } from 'utils/validations';

/**
 *
 * PatientAddress
 *
 */

class PatientAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			primaryAddress: {
				state: null,
				city: null,
				zipcode: null,
			},
			mailingAddress: {
				state: null,
				city: null,
				zipcode: null,
			},
			sendStatement: 'yes',
			livingAtPrimaryAddress: 'yes',
			livingAddress: {
				state: null,
				city: null,
				zipcode: null,
			},
		};
	}

	changeState(props) {
		const { patientData } = props;
		if (
			patientData.address &&
			Object.getOwnPropertyNames(patientData.address).length > 0
		) {
			const { primaryAddress } = this.state;
			// eslint-disable-next-line prefer-destructuring
			primaryAddress.state = props.states.filter(
				(state) => state.value === patientData.address.state,
			)[0];
			primaryAddress.city = {
				label: patientData.address.city,
				value: patientData.address.city,
			};
			primaryAddress.zipcode = {
				label: patientData.address.zipcode,
				value: patientData.address.zipcode,
			};
			this.setState({
				street: patientData.address.street,
				addressId: patientData.address.id,
				sendStatement:
					patientData.address.mailingAddress &&
					Object.getOwnPropertyNames(patientData.address.mailingAddress)
						.length > 0 &&
					patientData.address.id !== patientData.address.mailingAddress.id
						? 'no'
						: 'yes',
				primaryAddress,
			});
		}
		if (
			patientData.address.mailingAddress &&
			Object.getOwnPropertyNames(patientData.address.mailingAddress).length > 0
		) {
			const { mailingAddress } = this.state;
			// eslint-disable-next-line prefer-destructuring
			mailingAddress.state = props.states.filter(
				(state) => state.value === patientData.address.mailingAddress.state,
			)[0];

			mailingAddress.city = {
				label: patientData.address.mailingAddress.city,
				value: patientData.address.mailingAddress.city,
			};
			mailingAddress.zipcode = {
				label: patientData.address.mailingAddress.zipcode,
				value: patientData.address.mailingAddress.zipcode,
			};
			this.setState({
				mailingAddressStreet: patientData.address.mailingAddress.street,
				mailingAddressId: patientData.address.mailingAddress.id,
				mailingAddress,
			});
		}
	}

	componentDidMount() {
		this.changeState(this.props);
		// Set state (to load city list) and city (to load zip code list)
		// This needs to be reworked
		// if (this.state.primaryAddress) {
		//   this.props.handleGeoChange('state', this.state.primaryAddress.state);
		//   this.props.handleGeoChange('city', this.state.primaryAddress.city);
		// }
		// No reason for this now since sendStatement defaults to 'yes'
		// if (this.state.mailingAddress) {
		//   this.props.handleGeoChange('state', this.state.mailingAddress.state);
		//   this.props.handleGeoChange('city', this.state.mailingAddress.city);
		// }
	}

	handleGeoChange = (group) => (type) => (data) => {
		const { handleGeoChange } = this.props;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const groupGeo = this.state[group];
		if (type === 'state') {
			groupGeo.city = null;
			groupGeo.zipcode = null;
		} else if (type === 'city') {
			groupGeo.zipcode = null;
		}
		groupGeo[type] = data;
		this.setState({
			[group]: groupGeo,
		});
		handleGeoChange(type, data);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientData.address &&
			Object.getOwnPropertyNames(nextProps.patientData.address).length > 0 &&
			(!this.state.primaryAddress.state || !this.state.mailingAddress.state)
		) {
			this.handleChange(nextProps);
		}
	}

	handleChange = (event) => {
		const { target } = event;

		if (!target) return;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleNumberChange = (id, values) => {
		this.setState({ [id]: values.value });
	};

	nextStep = () => {
		const { step, auth } = this.props;

		const {
			street,
			sendStatement,
			addressId,
			mailingAddressId,
			mailingAddressStreet,
			primaryAddress,
			mailingAddress,
			livingAtPrimaryAddress,
			livingAddress,
			livingAddressStreet
		} = this.state;
		const data = {
			address: {
				id: addressId,
				street,
				sendStatement,
				city: !primaryAddress.city ? '' : primaryAddress.city.value,
				state: !primaryAddress.state ? '' : primaryAddress.state.value,
				zipcode: !primaryAddress.zipcode ? '' : primaryAddress.zipcode.value,
				mailingAddress: {
					street: mailingAddressStreet,
					id: mailingAddressId,
					state: !mailingAddress.state ? '' : mailingAddress.state.value,
					city: !mailingAddress.city ? '' : mailingAddress.city.value,
					zipcode: !mailingAddress.zipcode ? '' : mailingAddress.zipcode.value,
				},
				livingAtPrimaryAddress,
				livingAddress: {
					street: livingAddressStreet,
					state: !livingAddress.state ? '' : livingAddress.state.value,
					city: !livingAddress.city ? '' : livingAddress.city.value,
					zipcode: !livingAddress.zipcode ? '' : livingAddress.zipcode.value,
				}
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.id = auth.id;
		data.q = keys.join();

		step(data);
	};

	prevStep = () => {
		const { previousStep } = this.props;
		previousStep();
	};

	render() {
		// const {} = this.props;
		const { primaryAddress, mailingAddress, livingAddress } = this.state;
		const { states, cities, zipcodes } = this.props;
		return (
			<Form>
				<div className="form-group">
					<TextInput
						label="STREET ADDRESS*"
						id="street"
						value={this.state.street}
						placeholder="110, Old Hill Road"
						handleInput={this.handleChange}
						validations={[required]}
					/>
				</div>
				<GeoDropdown
					states={states}
					cities={
						primaryAddress.state ? cities[primaryAddress.state.value] : []
					}
					zipcodes={
						primaryAddress.city ? zipcodes[primaryAddress.city.value] : []
					}
					handleChange={this.handleGeoChange('primaryAddress')}
					state={primaryAddress.state}
					city={primaryAddress.city}
					zipcode={primaryAddress.zipcode}
					isRequired
				/>
				{/* <Geo
          data={this.props.patientData}
          keyName="address"
          // validations={validations}
        /> */}

				<div className="form-row">
					<div className="col-md-6 form-group">
						<label htmlFor="sendStatement" className="form-font">
							SEND STATEMENT TO THE SAME ADDRESS?
						</label>
						<SelectButtonGroup
							id="sendStatement"
							value={this.state.sendStatement}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={this.handleChange}
						/>
					</div>
				</div>

				{this.state.sendStatement === 'yes' ? (
					<div />
				) : (
					<div>
						<div className="form-group">
							<label htmlFor="mailingAddressStreet" className="form-font mt-4">
								SEND STATEMENT TO: MAILING ADDRESS
							</label>
							<input
								value={this.state.mailingAddressStreet}
								placeholder="119, Old Hyde Road"
								type="text"
								name="mailingAddressStreet"
								className="form-control"
								id="mailingAddressStreet"
								onChange={this.handleChange}
							/>
						</div>
						<GeoDropdown
							states={states}
							cities={
								mailingAddress.state ? cities[mailingAddress.state.value] : []
							}
							zipcodes={
								mailingAddress.city ? zipcodes[mailingAddress.city.value] : []
							}
							handleChange={this.handleGeoChange('mailingAddress')}
							state={mailingAddress.state}
							city={mailingAddress.city}
							zipcode={mailingAddress.zipcode}
						/>
						{/* <Geo
              data={this.props.patientData.address}
              keyName="mailingAddress"
            /> */}
					</div>
				)}

				<div className="form-row">
					<div className="col-md-6 form-group">
						<label htmlFor="livingAtPrimaryAddress" className="form-font">
							WILL YOU BE RESIDING AT THIS ADDRESS DURING YOUR TREATMENT?
						</label>
						<SelectButtonGroup
							id="livingAtPrimaryAddress"
							value={this.state.livingAtPrimaryAddress}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={this.handleChange}
						/>
					</div>
				</div>

				{this.state.livingAtPrimaryAddress === 'yes' ? (
					<div />
				) : (
					<div>
						<div className="form-group">
							<label htmlFor="livingAddressStreet" className="form-font mt-4">
								RESIDENCE DURING TREATMENT AT MIND THERAPY CLINIC
							</label>
							<input
								value={this.state.livingAddressStreet}
								placeholder="119, Old Hyde Road"
								type="text"
								name="livingAddressStreet"
								className="form-control"
								id="livingAddressStreet"
								onChange={this.handleChange}
							/>
						</div>
						<GeoDropdown
							states={states}
							cities={
								livingAddress.state ? cities[livingAddress.state.value] : []
							}
							zipcodes={
								livingAddress.city ? zipcodes[livingAddress.city.value] : []
							}
							handleChange={this.handleGeoChange('livingAddress')}
							state={livingAddress.state}
							city={livingAddress.city}
							zipcode={livingAddress.zipcode}
						/>
						{/* <Geo
              data={this.props.patientData.address}
              keyName="mailingAddress"
            /> */}
					</div>
				)}

				<div className="d-flex mt-3">
					<div className="col-6 pl-0">
						<button
							type="button"
							className="btn btn-white link-color border btn-lg btn-block font-weight-bold d-flex justify-content-start font-15"
							onClick={this.prevStep}
						>
							<div className="col-4 d-flex justify-content-start align-self-center">
								<img className="fas fa-arrow-left" alt="arrow-left" />
							</div>
							<div className="col-4 d-flex justify-content-center">
								<span>PREV</span>
							</div>
						</button>
					</div>
					<div className="col-6 p-0 d-flex">
						{!primaryAddress.state ||
						!primaryAddress.city ||
						!primaryAddress.zipcode ? (
							<button
								disabled
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-end"
								onClick={this.nextProgress}
							>
								<div className="col-4 d-flex justify-content-center">
									<span>NEXT</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</button>
						) : (
							<Button
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-weight-bold d-flex justify-content-end font-15"
								onClick={this.nextStep}
							>
								<div className="col-4 d-flex justify-content-center">
									<span>NEXT</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</Button>
						)}
					</div>
				</div>
			</Form>
		);
	}
}

PatientAddress.propTypes = {
	patientData: PropTypes.object.isRequired,
	step: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	previousStep: PropTypes.func.isRequired,
	states: PropTypes.array,
	cities: PropTypes.object,
	zipcodes: PropTypes.object,
	handleGeoChange: PropTypes.func.isRequired,
};

export default PatientAddress;
