import { fromJS } from 'immutable';

import {
	FETCH_NOTIFICATIONS,
	FETCH_NOTIFICATIONS_ERROR,
	FETCH_NOTIFICATIONS_SUCCESS,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_ERROR,
	UPDATE_NOTIFICATIONS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	isFetching: false,
	data: [],
	error: '',
});

function updateNotifications(state, newData) {
	const records = state.get('data');
	newData.forEach((n) => {
		const record = records.find((r) => r.id === n.id);
		if (record) {
			record.hasSeen = n.hasSeen;
		}
	});
	return state.set('data', records);
}

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_NOTIFICATIONS:
		case UPDATE_NOTIFICATIONS:
			return state.set('isFetching', true);
		case FETCH_NOTIFICATIONS_SUCCESS:
			return state.set('data', action.data);
		case UPDATE_NOTIFICATIONS_SUCCESS:
			return updateNotifications(state, action.data);
		case FETCH_NOTIFICATIONS_ERROR:
		case UPDATE_NOTIFICATIONS_ERROR:
			return state.set('error', action.error);
		default:
			return state;
	}
}
