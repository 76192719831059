/*
 *
 * SignUp reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CHECK_USER_EXIST,
	DO_SIGN_UP,
	ERROR,
	PHONE_VALIDATION,
	REMOVE_ERROR,
	SEND_OTP,
	SHOW_ERROR,
	STORE_DATA,
	SUCCESS,
	SUCCESS_VERIFIED,
	TOGGLE_VERIFICATION,
	USER_DOES_NOT_EXIST,
	USER_EXIST,
	VALIDATE_OTP,
} from './constants';

export const initialState = fromJS({
	name: '',
	email: '',
	phone: '',
	password: '',
	confirmPassword: '',
	code: '',
	errorMessage: null,
	toggleOtp: true,
	showVerificationModal: false,
	phoneValidated: false,
});

function signUpReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_VERIFICATION:
			return state.set(
				'showVerificationModal',
				!state.get('showVerificationModal'),
			);
		case STORE_DATA:
			return state
				.set('name', action.data.name)
				.set('email', action.data.email)
				.set('phone', action.data.phone)
				.set('password', action.data.password)
				.set('confirmPassword', action.data.confirmPassword)
				.set('toggleOtp', action.data.toggleOtp);
		case CHECK_USER_EXIST:
			return state;
		case USER_EXIST:
			return state;
		case USER_DOES_NOT_EXIST:
			return state;
		case SEND_OTP:
			return state;
		case PHONE_VALIDATION:
			return state.set('phoneValidated', action.isValidNumber);
		case VALIDATE_OTP:
			return state.set('code', action.code);
		case DO_SIGN_UP:
			return state;
		case SUCCESS_VERIFIED:
			return state.set(
				'showVerificationModal',
				!state.get('showVerificationModal'),
			);
		case SHOW_ERROR:
			return state.set('errorMessage', action.errorMsg);
		case REMOVE_ERROR:
			return state.set('errorMessage', action.nullMsg);
		case SUCCESS:
			return state;
		case ERROR:
			return state;
		default:
			return state;
	}
}

export default signUpReducer;
