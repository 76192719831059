import history from 'utils/history';

import React, { useState } from 'react';

import DropdownToggle from 'atoms/DropdownToggle';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

const ClientCard = (props) => {
	const { data, changeClinician, downloadFile } = props;
	const {
		client = {},
		docs = [],
		clinicianDetails = {},
		clientType,
		patientId,
	} = data;

	const [showInfo, setShowInfo] = useState(false);

	const onClick = () => {
		downloadFile(docs[0].uuid);
	};

	const toggleInfo = () => {
		setShowInfo(!showInfo);
	};

	const hasDocument = () => {
		return docs.length > 0 && docs[0].uuid;
	};

	const finishedIntake = () => {
		return hasDocument() && client.onBoardingComplete;
	};

	const goToProfile = () => {
		history.push(`/profile/${patientId}`);
	};

	return (
		<div className="card-container request-card bg-white border-bottom p-3 pt-4">
			<div className="mx-2 my-2 hand" style={{ position: 'relative' }}>
				<div onClick={goToProfile}>
					<ImageDetailsCell
						src={client.profileUrl}
						heading={client.name}
						primarySubHeading={client.email}
						secondarySubHeading={client.phone}
						width="50"
						classes={{
							heading: 'font-16',
							primarySubHeading: 'font-14 ellipsis',
						}}
					/>
				</div>
				<DropdownToggle show={showInfo} toggle={toggleInfo} />
			</div>
			<div style={styles.info(showInfo)}>
				<div className="row mx-0 mt-3">
					<div className="col-6">
						<div className="mb-2">
							<div className="text-cust-grey font-10 custom-card-label text-uppercase">
								Client Type
							</div>
							<div className="d-flex text-muted justify-content-start align-items-center font-12">
								{clientType}
							</div>
						</div>
					</div>
					<div>
						<div>
							<div className="text-cust-grey font-10 custom-card-label text-uppercase">
								View Intake Document
							</div>
							{hasDocument() ? (
								<div
									className="d-flex hand  link-color justify-content-start align-items-center font-13"
									onClick={onClick}
								>
									DOWNLOAD
								</div>
							) : finishedIntake() ? (
								<div className="d-flex text-muted justify-content-start align-items-center font-12">
									DOCUMENT UNAVAILABLE
								</div>
							) : (
								<div className="d-flex text-muted justify-content-start align-items-center font-12">
									INTAKE INCOMPLETE
								</div>
							)}
						</div>
					</div>
				</div>
				<hr />
				<div className="row justify-content-center">
					{clinicianDetails.name ? (
						<div>
							<ImageDetailsCell
								src={clinicianDetails.profilePicture}
								heading={clinicianDetails.name}
								primarySubHeading={clinicianDetails.therapistType}
								classes={{
									heading: 'font-16',
									primarySubHeading: 'font-14',
								}}
							/>
							<div
								className="link-color font-10 hand"
								onClick={() => changeClinician(patientId)}
							>
								CHANGE
							</div>
						</div>
					) : (
						<div
							className="link-color font-14 hand mx-0"
							onClick={() => changeClinician(patientId)}
						>
							ASSIGN CLINICIAN
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const styles = {
	info: (showInfo) => ({
		height: showInfo ? 150 : 0,
		overflow: 'hidden',
		transition: '0.3s all ease-in-out',
	}),
};

export default ClientCard;
