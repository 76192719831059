import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

// import { createSelector } from 'reselect';
// import { initialState } from './reducer';
/**
 * Direct selector to the cliniciansAndClients state domain
 */

const selectCliniciansAndClientsDomain = (state) =>
	state.get('cliniciansAndClients', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by CliniciansAndClients
 */

const makeSelectMyClients = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate),
	);

const makeSelectNewClient = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('newClient')),
	);

const makeSelectAllPrograms = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('programs')),
	);

const makeSelectCliniciansAndClients = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate),
	);

const makeSelectAllClinicians = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('allClinicians')),
	);

const makeSelectAllClients = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('allClients')),
	);

const makeSelectIsFetchingClients = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('isFetchingClients')),
	);

const makeSelectIsFetchingClinicians = () =>
	createSelector(selectCliniciansAndClientsDomain, (substate) =>
		returnJS(substate.get('isFetchingClinicians')),
	);

const useAllClients = () =>
	useSelector((state) =>
		state.get('cliniciansAndClients').get('allClients')?.toJS(),
	);
//   );

export {
	makeSelectCliniciansAndClients,
	makeSelectAllClinicians,
	makeSelectAllClients,
	makeSelectMyClients,
	makeSelectAllPrograms,
	makeSelectNewClient,
	makeSelectIsFetchingClients,
	makeSelectIsFetchingClinicians,
	useAllClients,
};
