/**
 *
 * BehavioralGuidelines
 *
 */
import PropTypes from 'prop-types';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
const BehavioralGuidelines = (props) => {
	const nextProgress = (event) => {
		const { nextProgress } = props;
		const { behavioralGuideLinesChecked } = props;

		const data = {
			checks: {
				behavioralGuideLinesChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	const formComplete = () => {
		return props.behavioralGuideLinesChecked;
	};

	const {
		handleAcceptance,
		behavioralGuideLinesChecked,
		prevProgress,
		isMobile,
	} = props;

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Behavioral Guidelines
			</h2>
			<div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
				<p className="pb-4">
					In order to support a healthy environment for clients and staff, Mind
					Therapy Clinic and its staff have established the following behavioral
					guidelines, to provide the conditions under which clients and staff
					can do their best work possible. Clients are expected to review and
					agree to uphold these, or, as importantly, to ask for help when unable
					to do so. While discharging clients for misconduct is rarely helpful
					and not our preference, it can become necessary when a client cannot
					meet the expectations or cooperate with interventions to change. When
					this becomes the case, staff will refer that individual to a higher
					level of care where additional help would be more available.
				</p>
				<p className="pt-1 pb-4">
					<h4>Safety:</h4>
					<span>
						Rude, abusive, derogatory, threatening, destructive, or violent
						behavior will not be tolerated. Threats of violence (including
						implied threats) or acts of violence to patients or staff are not
						acceptable. I will ask for help from staff when I’m feeling at risk
						of harming myself or anyone else. I will accept interventions to
						reduce the risk I will cause harm to myself or others. I will accept
						feedback from clients and staff about ways I might conduct myself
						that threaten the safety of others either through my verbal or
						nonverbal behavior. I will refrain from bullying, threatening,
						intimidating, name-calling, or similar and ask for help if I am
						experiencing or engaging in any of the above.
					</span>
				</p>

				<p className="pt-1 pb-4">
					<h4>Physical Closeness and Sexual Behavior:</h4>
					<span>
						Sexual behavior between clients interferes with all members doing
						their best work. This includes flirting, touching, pairing up (with
						or without physical contact), having sex in any form, sexting, being
						sexually provocative (including through dress or speech), or
						pressuring another. Sexual harassment will not be tolerated at Mind
						Therapy Clinic. Sexual harassment includes uninvited physical or
						verbal actions, such as touching, hugging, suggestive comments, or
						harassing phone calls and text messages. I will refrain from
						engaging in any of the named (or related) activities while here.
					</span>
				</p>

				<p className="pt-1 pb-4">
					<h4 className="cust-steps-content-head">
						Alcohol and Other Drug Use:
					</h4>
					<span>
						The vast majority of clients here have experienced losses and
						difficulties as a result of substance use or addiction; many clients
						are in active recovery. All members are expected to support the
						sobriety and abstinence needs of others and to assume that alcohol
						and other drug use is inherently destructive to treatment outcomes.
						Therefore, I understand I may not engage in alcohol or other drug
						use with other clients or promote or glamorize their use in groups
						or individual communications. I will not bring any intoxicating
						substance onto the premises (including parking lots) or come to
						sessions under the influence in any way. I will discuss my use of
						alcohol and other drugs with my primary therapist. I will give urine
						samples when asked to do so to verify my abstinence and/or measure
						my current usage. Minors may not legally use any mood altering
						substances.
					</span>
				</p>
				<p className="pt-1 pb-4">
					<h4>Group Participation/ Attendance:</h4>
					<span>
						Group work is an essential and fundamental component of the work at
						Mind Therapy Clinic. All members are expected to participate in
						groups in a respectful way that supports the best work possible for
						clients and staff alike. This includes: showing up on time, making
						space for all to speak and participate, open-mindedly listening and
						asking questions, taking directions from group facilitators,
						refraining from disrupting the group, and asking for help when
						unable to do so. Unnecessary absences interrupt group cohesion and
						effectiveness: I understand my presence and participation are needed
						for the groups to operate reliably and for me to obtain the best
						possible treatment outcomes.
					</span>
				</p>
				<p className="pt-1 pb-4">
					As a client requesting enrollment in the IOP, I understand I am
					expected to conform to these guidelines and to participate in healthy
					ways in this outpatient community. I understand my continued
					enrollment here depends on my willingness to uphold these guidelines
					and ask for help when I can’t.{' '}
				</p>
				<IAccept
					handleAcceptance={handleAcceptance}
					name="behavioralGuideLinesChecked"
					value={behavioralGuideLinesChecked}
					isRequired
				/>
			</div>
			<div className="d-flex mt-4 mb-5 justify-content-center">
				<div className="col-6">
					<PrevButton onClick={prevProgress} isMobile={isMobile} />
				</div>
				<div className="col-6">
					<NextButton
						disabled={!formComplete()}
						onClick={nextProgress}
						isMobile={isMobile}
					/>
				</div>
			</div>
		</div>
	);
};

BehavioralGuidelines.propTypes = {
	step: PropTypes.func.isRequired,
	previousStep: PropTypes.func.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
	behavioralGuideLinesChecked: PropTypes.bool.isRequired,
};

export default BehavioralGuidelines;
