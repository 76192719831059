import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the userDropDown state domain
 */

const selectFeedbackDomain = (state) => state.get('feedbackForm', initialState);

const makeSelectError = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('error')),
	);

const makeSelectSuccess = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('success')),
	);

export default selectFeedbackDomain;
export { makeSelectError, makeSelectSuccess };
