import PropTypes from 'prop-types';

import React from 'react';

import classnames from 'classnames';

export default function Label({
	label,
	children,
	required,
	id,
	hasError,
	...rest
}) {
	if (!label && !children) return null;

	return (
		<label
			className={classnames('text-grey form-font', {
				invalid: hasError,
			})}
			htmlFor={id}
			{...rest}
		>
			{label || children}
			{required && '*'}
		</label>
	);
}

Label.propTypes = {
	label: PropTypes.node,
	required: PropTypes.bool,
	id: PropTypes.string,
	hasError: PropTypes.bool,
};
