import React from 'react';

const Pagination = ({ page, setPage, dataPerPage, data, start, end }) => {
	const otherPages = new Array(Math.ceil(data.length / dataPerPage));
	for (let i = 0; i < otherPages.length; i++) {
		otherPages[i] = i;
	}

	let buttonIndex = 0;

	return (
		<>
			<div className="row w-100 text-center my-2 justify-content-center">
				<div
					onClick={() => setPage(0)}
					className="col-auto px-0 double-arrow mr-2 mt-1"
				>
					<span
						className="custom-arrow left hand"
						style={{ color: 'var(--main-color)' }}
					/>
					<span
						className="custom-arrow left hand"
						style={{ color: 'var(--main-color)' }}
					/>
				</div>
				<div className="col-auto px-0 mt-1">
					<span
						className="custom-arrow left hand"
						style={{ color: 'var(--main-color)' }}
						onClick={() => page > 0 && setPage(page - 1)}
					/>
				</div>
				<div className="col-auto px-2" style={{ marginTop: 1 }}>
					{otherPages.map((index, i) => {
						if (
							(page <= 2 && index > 4) ||
							(page > 2 &&
								page <= otherPages.length - 4 &&
								Math.abs(page - index) > 2) ||
							(page > otherPages.length - 4 && otherPages.length - index > 5)
						) {
							return;
						}

						const isActive = index === page;
						const style = {
							color: isActive ? 'white' : 'var(--main-color)',
							background: isActive ? 'var(--main-color)' : 'white',
							border: '1px solid var(--main-color)',
							transition: '0.3s background ease-in-out, 0.3s color ease-in-out',
							borderRadius: 2,
							margin: 0,
							marginRight: buttonIndex < 4 ? 4 : 0,
							width: 38,
							height: 38,
						};

						buttonIndex++;

						return (
							<button
								style={style}
								className="font-13"
								onClick={() => !isActive && setPage(i)}
								key={`pages-${i}`}
							>
								{i + 1}
							</button>
						);
					})}
				</div>
				<div className="col-auto px-0 mt-1">
					<span
						className="custom-arrow right hand"
						style={{ color: 'var(--main-color)' }}
						onClick={() => page < otherPages.length - 1 && setPage(page + 1)}
					/>
				</div>
				<div
					onClick={() => setPage(otherPages.length - 1)}
					className="col-auto px-0 double-arrow ml-2 mt-1"
				>
					<span
						className="custom-arrow right hand"
						style={{ color: 'var(--main-color)' }}
					/>
					<span
						className="custom-arrow right hand"
						style={{ color: 'var(--main-color)' }}
					/>
				</div>
			</div>
			<div className="row text-center justify-content-center w-100 font-italic font-10">
				Showing {start + 1} - {end} of {data.length}
			</div>
		</>
	);
};

export default Pagination;
