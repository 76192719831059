/*
 *
 * ToDos constants
 *
 */

export const DEFAULT_ACTION = 'src/ToDos/DEFAULT_ACTION';
export const LOAD_OTHER_REQ = 'src/ToDos/LOAD_OTHER_REQ';
export const LOAD_OTHER_REQ_SUCCESS = 'src/ToDos/LOAD_OTHER_REQ_SUCCESS';
export const LOAD_OTHER_REQ_ERROR = 'src/ToDos/LOAD_OTHER_REQ_ERROR';

export const LOAD_NEW_INTAKES = 'src/ToDos/LOAD_NEW_INTAKES';
export const LOAD_NEW_INTAKES_SUCCESS = 'src/ToDos/LOAD_NEW_INTAKES_SUCCESS';
export const LOAD_NEW_INTAKES_ERROR = 'src/ToDos/LOAD_NEW_INTAKES_ERROR';

export const LOAD_RX_REQUESTS = 'src/ToDos/LOAD_RX_REQUESTS';
export const LOAD_RX_REQUESTS_SUCCESS = 'src/ToDos/LOAD_RX_REQUESTS_SUCCESS';
export const LOAD_RX_REQUESTS_ERROR = 'src/ToDos/LOAD_RX_REQUESTS_ERROR';

export const FETCH_PHARMACIES = 'src/ToDos/FETCH_PHARMACIES';
export const FETCH_PHARMACIES_SUCCESS = 'src/ToDos/FETCH_PHARMACIES_SUCCESS';
export const FETCH_PHARMACIES_ERROR = 'src/ToDos/FETCH_PHARMACIES_ERROR';

export const CHANGE_PHARMACY = 'src/ToDos/CHANGE_PHARMACY';
export const CHANGE_PHARMACY_SUCCESS = 'src/ToDos/CHANGE_PHARMACY_SUCCESS';
export const CHANGE_PHARMACY_ERROR = 'src/ToDos/CHANGE_PHARMACY_ERROR';

export const ASSIGN_CLINICIAN = 'src/RxRequests/ASSIGN_CLINICIAN';
export const ASSIGN_CLINICIAN_SUCCESS =
	'src/RxRequests/ASSIGN_CLINICIAN_SUCCESS';
export const ASSIGN_CLINICIAN_ERROR = 'src/RxRequests/ASSIGN_CLINICIAN_ERROR';

export const tabLabels = {
	ENROLLMENT_REQUESTS: '1',
	MEMBER_REQUESTS: '2',
	NEW_INTAKES: '3',
	RX_REQUESTS: '4',
	OTHER_REQUESTS: '5',
	CLIENT_FEEDBACK: '6',
	ABSENCES: '7',
};
