/*
 *
 * Groups constants
 *
 */

export const DEFAULT_ACTION = 'src/Therapies/DEFAULT_ACTION';
export const LOAD_GROUP_TYPES = 'src/Therapies/LOAD_GROUP_TYPES';
export const LOAD_GROUP_TYPES_SUCCESS =
	'src/Therapies/LOAD_GROUP_TYPES_SUCCESS';
export const LOAD_GROUP_TYPES_ERROR = 'src/Therapies/LOAD_GROUP_TYPES_ERROR';

export const CREATE_PROGRAM = 'src/Therapies/CREATE_PROGRAM';
export const CREATE_PROGRAM_SUCCESS = 'src/Therapies/CREATE_PROGRAM_SUCCESS';
export const CREATE_PROGRAM_ERROR = 'src/Therapies/CREATE_PROGRAM_ERROR';

export const UPDATE_PROGRAM = 'src/Therapies/UPDATE_PROGRAM';
export const UPDATE_PROGRAM_SUCCESS = 'src/Therapies/UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_ERROR = 'src/Therapies/UPDATE_PROGRAM_ERROR';

export const LOAD_PROGRAMS = 'src/Therapies/LOAD_PROGRAMS';
export const LOAD_PROGRAMS_SUCCESS = 'src/Therapies/LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_ERROR = 'src/Therapies/LOAD_PROGRAMS_ERROR';

export const DELETE_PROGRAM = 'src/Therapies/DELETE_PROGRAM';
export const DELETE_PROGRAM_SUCCESS = 'src/Therapies/DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_ERROR = 'src/Therapies/DELETE_PROGRAM_ERROR';

export const DEACTIVATE_PROGRAM = 'src/Therapies/DEACTIVATE_PROGRAM';
export const DEACTIVATE_PROGRAM_SUCCESS =
	'src/Therapies/DEACTIVATE_PROGRAM_SUCCESS';
export const DEACTIVATE_PROGRAM_ERROR =
	'src/Therapies/DEACTIVATE_PROGRAM_ERROR';

export const DOWNLOAD_ATTENDANCE_REPORT =
	'src/Therapies/DOWNLOAD_ATTENDANCE_REPORT';
export const DOWNLOAD_ATTENDANCE_REPORT_ERROR =
	'src/Therapies/DOWNLOAD_ATTENDANCE_REPORT_ERROR';
