import moment from 'moment';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { headerStyle } from 'containers/App/constants';
import failImg from 'images/error-alert.png';

const logColumns = () => {
	return [
		{
			headerStyle: {
				...headerStyle,
				width: '50px',
			},
			render: () => (
				<div className="pt-2">
					<img src={failImg} alt="error-icon" width="30px" height="30px" />
				</div>
			),
			sorting: false,
		},
		{
			field: 'client',
			title: 'USER',
			headerStyle: {
				...headerStyle,
				width: '150px',
			},
			headerClasses: 'table-header font-10 mt-2 text-uppercase',
			render: (row) => (
				<div className="font-13 my-1">
					<ImageDetailsCell
						heading={row.client.name}
						classes={{
							primarySubHeading: 'ellipsis',
						}}
						width="100px"
					/>
				</div>
			),
		},
		{
			field: 'fileName',
			title: 'FILE NAME',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2 ellipsis">{row.fileName}</div>
			),
		},
		{
			field: 'error',
			title: 'Error',
			headerStyle,
			render: (row) => <div className="font-13 my-2">{row.error}</div>,
		},
		{
			field: 'updatedAt',
			title: 'Updated At',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2">
					{moment(row.updatedAt).format('MM-DD-YYYY')}
				</div>
			),
		},
	];
};

export { logColumns };
