/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */
import history from 'utils/history';

import React from 'react';

import logo from 'images/logo_login.svg';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
export default class NotFound extends React.PureComponent {
	goHome = () => {
		history.push('/');
	};

	render() {
		return (
			<div>
				<div className="container-fluid">
					<div className="row vh-80 align-items-center">
						<div className="col-12 text-center">
							<img src={logo} className="logo-width" alt="Mind Therapy" />
							<h1 className="my-4 title">404</h1>
							<h3 className="sub-title">
								Sorry <i className="far fa-frown" />, the page you are looking
								for does not exist.
							</h3>
							<button
								type="button"
								className="btn btn-primary btn-color btn-lg w-25 mt-3"
								onClick={this.goHome}
							>
								GO HOME
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
