import { startCase } from 'lodash';

import React, { Component } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { mtcClinTypes } from 'containers/PatientProfile/constants';

class PatientCareGroup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emergencyContactIndex: 0,
		};

		this.keys = ['md', 'therapist'];
	}

	handleContactChange = (e) => {
		this.setState({ emergencyContactIndex: e.target.value });
	};

	renderClinicanInfo = (clinician) => {
		return (
			<div key={clinician.name}>
				<div className="row w-100">
					<div
						className={`col p-2 font-13 ${clinician.name ? 'mt-2' : ''}`}
						style={{ width: '45%' }}
					>
						{startCase(clinician.type)}
					</div>
					<div className="col p-2 font-13">
						{clinician && clinician.name ? (
							<ImageDetailsCell
								src={clinician.profilePicture || ''}
								heading={clinician.name}
								showDetails={true}
							/>
						) : (
							<div>-</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const {
			data = { md: {}, therapist: {}, mtc: [], outside: [] },
			mainClinician,
		} = this.props;
		const { md, therapist, mtc, outside } = data;

		const mtcClins = mtc.filter((c) => mtcClinTypes.includes(c.type));
		const mtcOtherClins = mtc.filter((c) => !mtcClinTypes.includes(c.type));

		return (
			<div className="d-flex bg-white p-4 w-100">
				<div className="w-100">
					<div className="row w-100">
						<div className="col p-2 align-top font-13" style={{ width: '45%' }}>
							Lead Clinician
						</div>
						<div className="col p-2 font-13">
							{mainClinician && mainClinician.name ? (
								<ImageDetailsCell
									src={mainClinician.profilePicture || ''}
									heading={mainClinician.name}
									showDetails={true}
								/>
							) : (
								<div>-</div>
							)}
						</div>
					</div>
					<hr />
					{mtcClinTypes.map((type) => {
						const clin = mtcClins.find((x) => x.type === type);

						if (clin) {
							return this.renderClinicanInfo(clin);
						} else {
							return this.renderClinicanInfo({ type });
						}
					})}
					<hr />
					{mtcOtherClins.map((clinician) => {
						return (
							<div key={clinician.name}>
								<div className="row w-100">
									<div
										className="col p-2 pt-3 align-top font-13"
										style={{ width: '45%' }}
									>
										{startCase(clinician.type)}
									</div>
									<div className="col p-2 font-13">
										{clinician && clinician.name ? (
											<ImageDetailsCell
												src={clinician.profilePicture || ''}
												heading={clinician.name}
												showDetails={true}
											/>
										) : (
											<div>-</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
					<div className="row w-100">
						<div className="col p-2 align-top font-13" style={{ width: '45%' }}>
							Primary MD Name
						</div>
						<div className="col p-2 font-13">{md.name || '-'}</div>
					</div>
					<div className="row w-100">
						<div className="col p-2 align-top font-13" style={{ width: '45%' }}>
							Primary MD Phone
						</div>
						<div className="col p-2 font-13">
							{formatPhoneNumber(`+1${md.phone}`) || '-'}
						</div>
					</div>
					<div className="row w-100">
						<div className="col p-2 align-top font-13" style={{ width: '45%' }}>
							Primary Therapist Name
						</div>
						<div className="col p-2 font-13">{therapist.name || '-'}</div>
					</div>
					<div className="row w-100">
						<div className="col p-2 align-top font-13" style={{ width: '45%' }}>
							Primary Therapist Phone
						</div>
						<div className="col p-2 font-13">
							{formatPhoneNumber(`+1${therapist.phone}`) || '-'}
						</div>
					</div>
					{outside.length > 0 && <hr />}
					{outside.map((clinician) => {
						return (
							<div key={clinician.name}>
								<div className="row w-100">
									<div
										className="col p-2 align-top font-13"
										style={{ width: '45%' }}
									>
										{startCase(clinician.type)}
									</div>
									<div className="col p-2 font-13">{clinician.name || '-'}</div>
								</div>
								<div className="row w-100">
									<div
										className="col p-2 align-top font-13"
										style={{ width: '45%' }}
									>
										{startCase(clinician.type)} Phone
									</div>
									<div className="col p-2 font-13">
										{formatPhoneNumber(`+1${clinician.phone}`) || '-'}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default PatientCareGroup;
