import React, { useEffect, useState } from 'react';

import Pill from 'atoms/Pill';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import IAccept from 'components/IAccept';

import { purposeChecks, releaseChecks } from './checks';

const Purpose = (props) => {
	const { roi, isMobile, handleSelect, handleClick } = props;

	const [selectedAuthClient, setSelectedAuthClient] = useState({
		label: 'Patient',
		value: 'Patient',
	});

	const authClientOptions = [
		{ label: 'Patient', value: 'patient' },
		{ label: 'Parent or Legal Guardian', value: 'guardian' },
		{
			label: 'Spouse or Person Financially Responsible for Patient',
			value: 'spouse',
		},
		{
			label: 'Beneficiary or Person Representing Deceased Patient',
			value: 'beneficiary',
		},
	];

	useEffect(() => {
		setSelectedAuthClient(
			authClientOptions.find((o) => o.value === props.roi.authClientType),
		);
	}, [props.roi.authClientType]);

	/* const getPillClassName = (option) => {
		let className =
      'badge badge-pill text-bold border m-1 px-3 py-2 text-uppercase font-10 hand';

		return roi[option]
			? `${className} badge-success`
			: `${className} bg-white badge-color-cust link-color`;
	}; */

	const inputGroupName = `${
		isMobile ? 'row' : 'd-flex pb-3'
	} justify-content-center`;

	return (
		<div>
			<div className="d-flex px-3">
				<h6 className="font-16 text-dark font-weight-bold d-flex">
					Information To Be Released:
					<p className="align-top">*</p>
				</h6>
			</div>
			<div className="row mx-0 ml-2 justify-content-start">
				{releaseChecks.map((c) => (
					<Pill
						selected={roi[c.value]}
						onClick={() => handleClick(c.value)}
						text={c.label}
						key={c.label}
					/>
				))}
			</div>
			<div className="d-flex px-3 pt-4">
				<h6 className="font-16 text-dark font-weight-bold custom-relofInfo-text">
					The above information will be used for the following purposes only*:
				</h6>
			</div>
			<div className="row mx-0 ml-2 justify-content-start">
				{purposeChecks.map((c) => (
					<Pill
						selected={roi[c.value]}
						onClick={() => handleClick(c.value)}
						text={c.label}
						key={c.label}
					/>
				))}
			</div>
			<div className="d-flex px-3 py-4">
				<p className="font-16 text-dark custom-relofInfo-text">
					I understand that this information may be protected by Title 42 (CFR,
					Parts 160 and 164) and Title 45 (Federal Rules of Confidentiality of
					Alcohol and Drug Abuse Patient Records, Chapter 1, Part 2), plus
					applicable state laws. I further understand the information disclosed
					to the recipient may not be protected under these guidelines if they
					are not a health care provider covered by the state or federal rules.
					I understand that this authorization is voluntary, and I may revoke
					this consent at any time by providing written notice. This release
					will expire
					<strong> one year from the date of signature</strong>. I have been
					informed what information will be given, its purpose, and who will
					receive the information.{' '}
					<strong>
						I understand that I have a right to receive a copy of this
						authorization
					</strong>
					. I understand that I have a right to refuse to sign this
					authorization.
				</p>
			</div>
			<div className={`${inputGroupName} mb-4`}>
				<div className="col mb-2" style={{ minWidth: '300px' }}>
					<SelectAutoComplete
						handleChange={handleSelect}
						options={authClientOptions}
						value={selectedAuthClient}
						label={'Relationship to Client'}
						placeholder="Select Client Type"
						indicatorClass="fas fa-sort dropdown-indicator text-primary"
						optionStyles={{ fontSize: '14px' }}
						singleValueStyles={{ color: 'var(--main-color)', fontSize: '14px' }}
					/>
				</div>
				<div className={'col-auto d-flex justify-content-end pt-4'}>
					<IAccept
						isRequired
						handleAcceptance={handleClick}
						name="roiChecked"
						value={roi.roiChecked}
					/>
				</div>
			</div>
		</div>
	);
};

export default Purpose;
