import PropTypes from 'prop-types';

import TextArea from 'atoms/TextArea';

/**
 *
 * <AcademicHistory />, a functional component for Adult Intake Evaluation
 */
const AcademicHistory = (props) => {
	const { handleChange, vocationalHistory } = props;

	return (
		<div className="card d-flex flex-column p-4">
			<h3 className="mb-5">Academic / Vocational History</h3>

			<TextArea
				className="mb-4"
				label="PLEASE DESCRIBE YOUR EDUCATIONAL AND VOCATIONAL HISTORY"
				id="vocationalHistory"
				value={vocationalHistory}
				handleInput={handleChange}
				rows={4}
			/>
		</div>
	);
};

AcademicHistory.propTypes = {
	vocationalHistory: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default AcademicHistory;
