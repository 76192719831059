import React from 'react';

import Covid from 'pages/covid/Covid';
import CovidReturn from 'pages/covid/CovidReturn';

const MODALS = {
	COVID: 'covid',
	COVID_RETURN: 'covid-return',
};

const ModalMap = {
	[MODALS.COVID]: Covid,
	[MODALS.COVID_RETURN]: CovidReturn,
};

export default function ModalManager({ match }) {
	const { params } = match;
	const { modalName = '' } = params;

	const Content = ModalMap[modalName];

	/* if (!content) {
    history.push('/patient/dashboard');
  } */

	return <Content />;
}
