import React, { useState } from 'react';

import DropdownToggle from 'atoms/DropdownToggle';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

const ClinicianCard = (props) => {
	const { data } = props;
	const {
		userInfo = {},
		licenseDetails = {},
		supervisor = {},
		therapistType,
	} = data;

	const [showInfo, setShowInfo] = useState(false);

	const toggleInfo = () => {
		setShowInfo(!showInfo);
	};

	const changeSupervisor = () => {
		throw new Error('changeSupervisor not implemented');
	};

	return (
		<div className="card-container request-card bg-white border-bottom p-3 pt-4">
			<div className="row mx-0 mb-4" style={{ position: 'relative' }}>
				<div
					className="col-6 mt-2 hand"
					style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
				>
					<ImageDetailsCell
						src={userInfo.profileUrl}
						heading={userInfo.name}
						primarySubHeading={therapistType}
						width="50"
						classes={{
							heading: 'font-16',
							primarySubHeading: 'font-14',
						}}
					/>
				</div>
				<div className="col-6 mt-4">
					<div className="mb-2">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase">
							License Expiration
						</div>
						<div className="d-flex text-muted justify-content-start align-items-center font-12">
							{licenseDetails.licenseExpirationMonth} -{' '}
							{licenseDetails.licenseExpirationYear}
						</div>
					</div>
					<div></div>
				</div>
				<DropdownToggle show={showInfo} toggle={toggleInfo} />
			</div>
			<div style={styles.info(showInfo, supervisor.name)}>
				{supervisor.name ? (
					<>
						<hr />
						<div className="row justify-content-center">
							<div>
								<ImageDetailsCell
									src={supervisor.profilePicture}
									heading={supervisor.name}
									primarySubHeading={supervisor.therapistType}
								/>
								<div
									className="link-color font-10 hand"
									onClick={() => changeSupervisor()}
								>
									CHANGE
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<button
							disabled
							className="link-color font-14 hand disabled"
							onClick={() => changeSupervisor(userInfo.id)}
						>
							ASSIGN SUPERVISOR
						</button>
					</>
				)}
			</div>
		</div>
	);
};

const styles = {
	info: (showInfo, hasSupervisor) => ({
		height: !showInfo ? 0 : hasSupervisor ? 100 : 40,
		overflow: 'hidden',
		transition: '0.3s all ease-in-out',
		textAlign: 'center',
	}),
};

export default ClinicianCard;
