import React, { useState } from 'react';

import ActionButton from 'atoms/ActionButton';
import CustomCheckbox from 'atoms/CustomCheckbox';
import DeleteButton from 'atoms/DeleteButton';
import SearchBar from 'atoms/SearchBar';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import addImage from 'svgs/add';
import { compareWords } from 'utils/columnCompare';
import { filterByLetter } from 'utils/helpers';

import { ClientContainer, ProgramContainer } from './ChooseRecipients.style';

const ChooseRecipients = (props) => {
	const { clients, groups, nextStep, prevStep, isSending } = props;

	const [unusedClients, setUnusedClients] = useState(clients);
	const [clientSearch, setClientSearch] = useState('');
	const [recipientSearch, setRecipientSearch] = useState('');
	const [groupSearch, setGroupSearch] = useState('');
	const [selectedClients, setSelectedClients] = useState([]);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [showClients, setShowClients] = useState(true);
	const [showGroups, setShowGroups] = useState(true);

	const headerText = 'text-uppercase font-13 font-weight-bold mb-2';

	const handleSearch = (type) => (search) => {
		if (type === 'client') {
			setClientSearch(search);
		} else if (type === 'recipient') {
			setRecipientSearch(search);
		} else if (type === 'group') {
			setGroupSearch(search);
		}
	};

	const toggleShow = (type) => () => {
		if (type === 'client') {
			setShowClients(!showClients);
		} else if (type === 'group') {
			setShowGroups(!showGroups);
		}
	};

	const selectClient = (id) => {
		const newClient = unusedClients.find((x) => x.id === id);
		if (newClient) {
			setSelectedClients([...selectedClients, newClient]);
			setUnusedClients(
				[...unusedClients.filter((x) => x.id !== id)].sort((a, b) =>
					compareWords(a.name, b.name, 'asc'),
				),
			);
		}
	};

	const deselectClient = (id) => {
		const newClient = selectedClients.find((x) => x.id === id);
		if (newClient) {
			setUnusedClients(
				[...unusedClients, newClient].sort((a, b) =>
					compareWords(a.name, b.name, 'asc'),
				),
			);
			setSelectedClients([...selectedClients.filter((x) => x.id !== id)]);
		}
	};

	const toggleGroup = (id) => {
		let isSelected = selectedGroups.includes(id);

		if (isSelected) {
			setSelectedGroups([...selectedGroups.filter((g) => g !== id)]);

			let toRemove = selectedClients.filter((c) => c.groups.includes(id));
			setSelectedClients([
				...selectedClients.filter((c) => !c.groups.includes(id)),
			]);
			setUnusedClients(
				[...unusedClients, ...toRemove].sort((a, b) =>
					compareWords(a.name, b.name, 'asc'),
				),
			);
		} else {
			setSelectedGroups([...selectedGroups, id]);

			let toAdd = unusedClients.filter((c) => c.groups.includes(id));
			setUnusedClients(
				[...unusedClients.filter((c) => !c.groups.includes(id))].sort((a, b) =>
					compareWords(a.name, b.name, 'asc'),
				),
			);
			setSelectedClients([...selectedClients, ...toAdd]);
		}
	};

	const filteredClients = filterByLetter(unusedClients, 'name', clientSearch);
	const filteredRecipients = filterByLetter(
		selectedClients,
		'name',
		recipientSearch,
	);
	const filteredGroups = filterByLetter(groups, 'name', groupSearch);

	const searchBarStyle = {
		container: { paddingTop: 2, paddingBottom: 2 },
		searchBox: { width: 250 },
	};

	return (
		<div>
			<div className="row font-14 mb-4" style={{ height: 530 }}>
				<div className="col-6 border-right pr-3 h-100">
					<div className="mb-2">
						<div className={`${headerText} hand`} onClick={toggleShow('group')}>
							Select by Group
							<span
								className={`custom-arrow ${showGroups ? 'up' : 'down'} mt-2`}
								style={{ float: 'right' }}
							/>
						</div>
						<div>
							{showGroups && (
								<SearchBar
									classNames={{ container: 'w-100 mx-0' }}
									style={searchBarStyle}
									onSearch={handleSearch('group')}
									value={groupSearch}
									placeholder="Search for Group"
									autoSearch
								/>
							)}
							<div
								className="border px-2"
								style={{
									height: showGroups ? (showClients ? 140 : 400) : 0,
									overflow: 'hidden auto',
									transition: '0.25s all ease-in-out',
								}}
							>
								{filteredGroups.length === 0 ? (
									<div className="text-muted font-italic mt-2">No Results</div>
								) : (
									filteredGroups.map((g) => (
										<ProgramContainer
											className="row justify-content-between pb-2"
											key={`groups-${g.id}`}
										>
											<div className="col-10 mt-2">{g.name}</div>
											<div className="col-1 mt-2 mr-3">
												<CustomCheckbox
													checked={selectedGroups.includes(g.id)}
													onChange={() => toggleGroup(g.id)}
													scale={0.5}
												/>
											</div>
										</ProgramContainer>
									))
								)}
							</div>
						</div>
					</div>
					<div>
						<div
							className={`${headerText} hand`}
							onClick={toggleShow('client')}
						>
							Select by Client
							<span
								className={`custom-arrow ${showClients ? 'up' : 'down'} mt-2`}
								style={{ float: 'right' }}
							/>
						</div>
						<div>
							{showClients && (
								<SearchBar
									classNames={{ container: 'w-100 mx-0' }}
									style={searchBarStyle}
									onSearch={handleSearch('client')}
									value={clientSearch}
									autoSearch
								/>
							)}
							<div
								className="border px-2"
								style={{
									height: showClients ? (showGroups ? 200 : 400) : 0,
									overflow: 'hidden auto',
									transition: '0.25s all ease-in-out',
								}}
							>
								{unusedClients.length === 0 ? (
									<div className="text-muted font-italic mt-2">
										All Clients Selected
									</div>
								) : filteredClients.length === 0 ? (
									<div className="text-muted font-italic mt-2">No Results</div>
								) : (
									filteredClients.map((c) => (
										<ClientContainer
											className="row justify-content-between"
											key={`unused-${c.id}`}
										>
											<div className="col-10 mt-2">{c.name}</div>
											<div className="col-1 px-0 mr-3">
												<ActionButton
													image={addImage}
													onClick={() => selectClient(c.id)}
												/>
											</div>
										</ClientContainer>
									))
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className={headerText}>Recipients</div>
					<SearchBar
						classNames={{ container: 'w-100 mx-0' }}
						style={searchBarStyle}
						onSearch={handleSearch('recipients')}
						value={recipientSearch}
						placeholder="Search for Recipient"
						autoSearch
					/>
					<div
						className="border"
						style={{ height: 440, overflow: 'hidden auto' }}
					>
						{selectedClients.length === 0 ? (
							<div className="text-muted font-italic py-2 px-3">
								No Clients Selected
							</div>
						) : filteredRecipients.length === 0 ? (
							<div className="text-muted font-italic py-2 px-3">No Results</div>
						) : (
							filteredRecipients.map((c) => (
								<ClientContainer
									className="row justify-content-between py-2 px-2"
									key={`selected-${c.id}`}
								>
									<div className="col-auto mt-1">{c.name}</div>
									<div className="col-auto mr-1">
										<DeleteButton onClick={() => deselectClient(c.id)} />
									</div>
								</ClientContainer>
							))
						)}
					</div>
				</div>
			</div>
			<div className="w-100 mt-4 justify-content-center text-center">
				<button
					className="btn btn-primary bg-white link-color border font-14 mx-3"
					style={{ height: 45, minWidth: 140 }}
					onClick={prevStep}
				>
					PREV
				</button>
				<button
					disabled={selectedClients.length === 0 || isSending}
					className="btn btn-primary btn-color font-14 mx-3"
					style={{ height: 45, minWidth: 140 }}
					onClick={() => nextStep(selectedClients)}
				>
					{isSending ? (
						<SmallLoadingIndicator
							style={{ width: '20px', height: '20px', margin: '0 auto' }}
							className=""
						/>
					) : (
						'SUBMIT'
					)}
				</button>
			</div>
		</div>
	);
};

export default ChooseRecipients;
