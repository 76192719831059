import React from 'react';

import styled from 'styled-components';

const Button = styled.button`
	border: ${(props) =>
		props.selected
			? '2px solid var(--main-color)'
			: '1px solid var(--main-grey)'};
	color: ${(props) =>
		props.selected ? 'var(--main-color)' : 'var(--main-grey)'};
	padding: 0.375rem 0.75rem;
	padding-top: ${(props) =>
		props.selected ? '0.375rem' : 'calc(0.375rem + 1px)'};
	padding-bottom: ${(props) =>
		props.selected ? '0.375rem' : 'calc(0.375rem + 1px)'};

	&:hover {
		border: ${(props) =>
			props.selected
				? '2px solid var(--main-color)'
				: '2px solid var(--main-grey)'};
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}
`;

const PatientRegistrationType = (props) => {
	const {
		data: { registrationType },
		handleChange,
		nextStep,
	} = props;

	return (
		<div className="py-2">
			<h4 className="text-center mb-4">
				Please select your registration type:
			</h4>
			<div className="row w-100 mx-0 py-4">
				<div className="col-6">
					<Button
						className="btn-block text-uppercase"
						name="registrationType"
						onClick={handleChange('patientDetails')}
						value="adult"
						selected={registrationType === 'adult'}
					>
						Adult Outpatient
					</Button>
				</div>
				<div className="col-6">
					<Button
						className="btn-block text-uppercase"
						name="registrationType"
						onClick={handleChange('patientDetails')}
						value="child"
						selected={registrationType === 'child'}
					>
						Child Outpatient
					</Button>
				</div>
			</div>
			<div className="row w-100 mx-0 pb-4">
				<div className="col-12">
					<Button
						className="btn-block text-uppercase"
						name="registrationType"
						onClick={handleChange('patientDetails')}
						value="iopPhp"
						selected={registrationType === 'iopPhp'}
					>
						Intensive Programs (IOP/PHP)
					</Button>
				</div>
			</div>
			<div className="px-2">
				<button
					disabled={!registrationType}
					type="button"
					className="btn btn-primary btn-color btn-lg btn-block mt-3 font-weight-bold font-15"
					onClick={() => nextStep({ registrationType })}
				>
					NEXT
				</button>
			</div>
		</div>
	);
};

export default PatientRegistrationType;
