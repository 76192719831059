import { tabLabels } from './constants';

const requestFilters = (options, activeTab) => {
	if (activeTab === tabLabels.ENROLLMENT_REQUESTS) {
		return [
			{
				type: 'Dropdown Input',
				name: 'clinicianName',
				label: 'clinician',
				selectOptions: options.clinicianName,
				selectLabel: 'CLINICIAN',
				defaultSelect: 'All Clinicians',
			},
			{
				type: 'Dropdown Input',
				name: 'groupName',
				label: 'therapy',
				selectOptions: options.groupName,
				selectLabel: 'THERAPY NAME',
				defaultSelect: 'All Therapies',
			},
			{
				type: 'Dropdown Input',
				name: 'location',
				label: 'location',
				selectOptions: options.location,
				selectLabel: 'LOCATION',
				defaultSelect: 'All Locations',
			},
			{
				type: 'Date Picker',
				name: 'dateRange-enroll',
				minDate: options.minDate,
				maxDate: options.maxDate,
				label: 'CUSTOM RANGE',
			},
			options.type !== 'patient' && {
				type: 'Toggle',
				name: 'showInactiveEnroll',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactiveEnroll,
			},
		];
	}
	if (activeTab === tabLabels.GROUP_REQUESTS) {
		return [
			{
				type: 'Dropdown Input',
				name: 'name',
				label: 'clinician',
				selectOptions: options.name,
				selectLabel: 'CLINICIAN',
				defaultSelect: 'All Clinicians',
			},
			{
				type: 'Dropdown Input',
				name: 'requestType',
				label: 'type',
				selectOptions: options.requestType,
				selectLabel: 'TYPE',
				defaultSelect: 'All Requests',
			},
			{
				type: 'Date Picker',
				name: 'dateRange-group',
				minDate: options.minDate,
				maxDate: options.maxDate,
				label: 'CUSTOM RANGE',
			},
			options.type !== 'patient' && {
				type: 'Toggle',
				name: 'showInactiveGroup',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactiveGroup,
			},
		];
	}
	if (activeTab === tabLabels.RX_REQUESTS) {
		return [
			{
				type: 'Dropdown Input',
				name: 'clinicianName',
				label: 'clinician',
				selectOptions: options.clinicianName,
				selectLabel: 'CLINICIAN',
				defaultSelect: 'All Clinicians',
			},
			{
				type: 'Date Picker',
				name: 'dateRange-rx',
				minDate: options.minDate,
				maxDate: options.maxDate,
				label: 'CUSTOM RANGE',
			},
			options.type !== 'patient' && {
				type: 'Toggle',
				name: 'showInactiveRx',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactiveRx,
			},
		];
	}
	if (activeTab === tabLabels.MEMBER_REQUESTS) {
		return [
			{
				type: 'Dropdown Input',
				name: 'name',
				label: 'clinician',
				selectOptions: options.name,
				selectLabel: 'CLINICIAN',
				defaultSelect: 'All Clinicians',
			},
			{
				type: 'Dropdown Input',
				name: 'requestType',
				label: 'type',
				selectOptions: options.requestType,
				selectLabel: 'REQUEST TYPE',
				defaultSelect: 'All Types',
			},
			{
				type: 'Date Picker',
				name: 'dateRange-member',
				minDate: options.minDate,
				maxDate: options.maxDate,
				label: 'CUSTOM RANGE',
			},
			options.type !== 'patient' && {
				type: 'Toggle',
				name: 'showInactiveMember',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactiveMember,
			},
		];
	}
	return [];
};

export default requestFilters;
