import PropTypes from 'prop-types';

import React from 'react';

import Circle from './Circle';
import Wrapper from './Wrapper';
import './index.css';

export default function LoadingIndicator(props) {
	const { text, isLoading } = props;

	if (!isLoading) return null;

	return (
		<div className="loading-parent d-flex">
			<div className="d-flex flex-column justify-content-center w-100 ">
				<Wrapper className="my-4">
					<Circle />
					<Circle rotate={30} delay={-1.1} />
					<Circle rotate={60} delay={-1} />
					<Circle rotate={90} delay={-0.9} />
					<Circle rotate={120} delay={-0.8} />
					<Circle rotate={150} delay={-0.7} />
					<Circle rotate={180} delay={-0.6} />
					<Circle rotate={210} delay={-0.5} />
					<Circle rotate={240} delay={-0.4} />
					<Circle rotate={270} delay={-0.3} />
					<Circle rotate={300} delay={-0.2} />
					<Circle rotate={330} delay={-0.1} />
				</Wrapper>
				<h4 className="text-white d-flex justify-content-center">{text}</h4>
			</div>
		</div>
	);
}

LoadingIndicator.propTypes = {
	text: PropTypes.string,
	isLoading: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
	text: 'Please wait for SignNow to load',
	isLoading: true,
};
