/*
 *
 * App constants
 *
 */
export const DEFAULT_ACTION = 'src/App/DEFAULT_ACTION';
export const TOAST_GENERATED = 'src/App/TOAST_GENERATED';
export const TOAST_ERROR = 'src/App/TOAST_ERROR';
export const REMOVE_TOAST_MESSAGE = 'src/App/REMOVE_TOAST_MESSAGE';

export const SET_MOBILE = 'src/App/SET_MOBILE';

export const FETCH_STATES = 'src/App/FETCH_STATES';
export const FETCH_STATES_SUCCESS = 'src/App/FETCH_STATES_SUCCESS';
export const FETCH_STATES_ERROR = 'src/App/FETCH_STATES_ERROR';

export const FETCH_CITIES = 'src/App/FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'src/App/FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_ERROR = 'src/App/FETCH_CITIES_ERROR';

export const FETCH_ZIPCODES = 'src/App/FETCH_ZIPCODES';
export const FETCH_ZIPCODES_SUCCESS = 'src/App/FETCH_ZIPCODES_SUCCESS';
export const FETCH_ZIPCODES_ERROR = 'src/App/FETCH_ZIPCODES_ERROR';

export const FETCH_ENROLL_REQ = 'src/App/FETCH_ENROLL_REQ';
export const FETCH_ENROLL_REQ_SUCCESS = 'src/App/FETCH_ENROLL_REQ_SUCCESS';
export const FETCH_ENROLL_REQ_ERROR = 'src/App/FETCH_ENROLL_REQ_ERROR';

export const FETCH_MEMBER_REQ = 'src/App/FETCH_MEMBER_REQ';
export const FETCH_MEMBER_REQ_SUCCESS = 'src/App/FETCH_MEMBER_REQ_SUCCESS';
export const FETCH_MEMBER_REQ_ERROR = 'src/App/FETCH_MEMBER_REQ_ERROR';

export const FETCH_DOCUMENT_LIST = 'src/App/FETCH_DOCUMENT_LIST';
export const FETCH_DOCUMENT_LIST_SUCCESS =
	'src/App/FETCH_DOCUMENT_LIST_SUCCESS';
export const FETCH_DOCUMENT_LIST_ERROR = 'src/App/FETCH_DOCUMENT_LIST_ERROR';

export const DOWNLOAD_FILE = 'src/App/DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'src/App/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = 'src/App/DOWNLOAD_FILE_ERROR';

export const UPDATE_REQUEST = 'src/App/UPDATE_REQUEST';
export const UPDATE_REQUEST_SUCCESS = 'src/App/UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_ERROR = 'src/App/UPDATE_REQUEST_ERROR';

export const DELETE_REQUEST = 'src/App/DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'src/App/DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_ERROR = 'src/App/DELETE_REQUEST_ERROR';

export const CREATE_REQUEST = 'src/App/CREATE_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'src/App/CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_ERROR = 'src/App/CREATE_REQUEST_ERROR';

export const FETCH_PHARMACIES = 'src/App/FETCH_PHARMACIES';
export const FETCH_PHARMACIES_SUCCESS = 'src/App/FETCH_PHARMACIES_SUCCESS';
export const FETCH_PHARMACIES_ERROR = 'src/App/FETCH_PHARMACIES_ERROR';

export const LOAD_CLINICIANS = 'src/App/LOAD_CLINICIANS';
export const LOAD_CLINICIANS_SUCCESS = 'src/App/LOAD_CLINICIANS_SUCCESS';
export const LOAD_CLINICIANS_ERROR = 'src/App/LOAD_CLINICIANS_ERROR';

export const PING_SERVER = 'src/App/PING_SERVER';
export const PING_SERVER_SUCCESS = 'src/App/PING_SERVER_SUCCESS';
export const PING_SERVER_ERROR = 'src/App/PING_SERVER_ERROR';

export const VIEW_PDF = 'src/Requests/VIEW_PDF';
export const VIEW_PDF_SUCCESS = 'src/Requests/VIEW_PDF_SUCCESS';
export const VIEW_PDF_ERROR = 'src/Requests/VIEW_PDF_ERROR';

export const SET_VIEW_MODE = 'src/App/SET_VIEW_MODE';

export const CLEAR_FILE = 'src/App/CLEAR_FILE';

export const CLEAR_DOWNLOAD_ERROR = 'src/App/CLEAR_DOWNLOAD_ERROR';

export const GET_PERMISSIONS = 'src/App/GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'src/App/GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'src/App/GET_PERMISSIONS_ERROR';

export const headerStyle = {
	color: '#999999',
	fontSize: '0.625rem',
	letterSpacing: 1,
	fontWeight: 'bold',
};

export const formsTypesShouldHavePdf = [
	'form',
	'roi',
	'enrollment-request',
	'patient-intake',
	'intake-questionnaire',
	'telehealth',
	'form-share',
];
