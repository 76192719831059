import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the requests state domain
 */

const selectRequestsDomain = (state) => state.get('requests', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Requests
 */

const makeSelectRequests = () =>
	createSelector(selectRequestsDomain, (substate) => returnJS(substate));

const makeSelectPrograms = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('programs')),
	);

const makeSelectPharmacy = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('pharmacy')),
	);
const makeSelectRxRequests = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('rxRequests')),
	);

const makeSelectRoiData = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('roiData')),
	);

const makeSelectSigningUrl = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('url')),
	);

const makeSelectPatientGroupRequests = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('groupRequests')),
	);

const makeSelectIsFetchingPatientGroupReq = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('isFetchingPatientGroupReq')),
	);

const makeSelectIsFetchingPatientRXReq = () =>
	createSelector(selectRequestsDomain, (substate) =>
		returnJS(substate.get('isFetchingPatientRXReq')),
	);

export default makeSelectRequests;
export {
	selectRequestsDomain,
	makeSelectPrograms,
	makeSelectPharmacy,
	makeSelectPatientGroupRequests,
	makeSelectRxRequests,
	makeSelectRoiData,
	makeSelectSigningUrl,
	makeSelectIsFetchingPatientGroupReq,
	makeSelectIsFetchingPatientRXReq,
};
