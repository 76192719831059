import history from 'utils/history';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

/**
 *
 * UserDropDown
 *
 */
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import Avatar from 'atoms/Avatar/index.tsx';
import DefaultAvatar from 'atoms/DefaultAvatar';
import Settings from 'components/Settings';
import UpdateEmail from 'components/UpdateEmail';
import UpdatePassword from 'components/UpdatePassword';
import UpdatePhone from 'components/UpdatePhone';
import { pingServer } from 'containers/App/actions';
import { makeSelectServerVersion } from 'containers/App/selectors';
import { openModal } from 'reducers/Modal';
import { MODALS } from 'v2/modals/list';

import {
	listUsers,
	logout,
	toggleEmail,
	toggleNotifications,
	togglePassword,
	togglePhone,
	toggleSettings,
	updateEmail,
	updateName,
	updatePassword,
	updatePasswordOther,
	updatePhone,
	verifyPassword,
} from './actions';
import './index.css';
import makeSelectUserDropDown from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class UserDropDown extends React.Component {
	logout = () => {
		const { logoutFunc } = this.props;

		logoutFunc();
	};

	componentDidMount = () => {
		const { doPing, serverVersion } = this.props;

		if (!serverVersion) {
			doPing();
		}
	};

	openPaymentInfo = () => {
		const { dispatch } = this.props;
		dispatch(openModal({ name: MODALS.UPDATE_PAYMENT_INFO }));
	};

	render() {
		const {
			user,
			userDropDown,
			doToggleSettings,
			doToggleEmail,
			doTogglePassword,
			doTogglePhone,
			doVerifyPassword,
			doUpdateName,
			doUpdateEmail,
			doUpdatePhone,
			doUpdatePassword,
			doUpdatePasswordOthers,
			doListUsers,
			src,
			serverVersion,
			isMobile,
		} = this.props;

		return (
			<div>
				<UncontrolledDropdown nav inNavbar>
					<DropdownToggle
						className="text-dark d-flex align-items-center"
						nav
						caret
					>
						<div className="d-flex justify-content-center ">
							{!isMobile && (
								<div className="d-flex align-self-center mr-2">{user.name}</div>
							)}
							{src ? (
								<Avatar
									src="http://keenthemes.com/preview/metronic/theme/assets/pages/media/profile/profile_user.jpg"
									style={{ margin: '0 0.25rem' }}
								/>
							) : (
								<DefaultAvatar name={user.name} />
							)}
						</div>
					</DropdownToggle>
					<DropdownMenu
						right
						className={`user-drop-down ${isMobile && 'mobile'}`}
						flip={false}
					>
						<DropdownItem>
							<div className="d-flex justify-content-start mr-4 col-12 p-0 mt-2">
								{src ? (
									<Avatar
										src="http://keenthemes.com/preview/metronic/theme/assets/pages/media/profile/profile_user.jpg"
										style={{ margin: '0 0.5rem' }}
									/>
								) : (
									<DefaultAvatar name={user.name} />
								)}
								<div className="mr-4 col-11">
									<h6 className="m-0">{user.name}</h6>
									<p
										className="text-muted m-0 w-100"
										style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}
									>
										{user.email}
									</p>
									<span className="badge badge-dark">
										{user.roleName && user.roleName.toUpperCase()}
									</span>
								</div>
							</div>
						</DropdownItem>
						<DropdownItem divider />
						{user.roleName === 'admin' && (
							<DropdownItem
								onClick={() => history.push('/admin/dashboard/console')}
							>
								Admin Console
							</DropdownItem>
						)}
						<DropdownItem onClick={doToggleSettings}>Settings</DropdownItem>
						{user.roleName === 'patient' && (
							<DropdownItem onClick={this.openPaymentInfo}>
								Manage Payment Information
							</DropdownItem>
						)}
						<DropdownItem onClick={this.logout}>Log Out</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
				{userDropDown.showSettings && (
					<Settings
						user={user}
						toggle={doToggleSettings}
						toggleEmail={doToggleEmail}
						togglePassword={doTogglePassword}
						togglePhone={doTogglePhone}
						updateName={doUpdateName}
						serverVersion={serverVersion}
					/>
				)}
				{userDropDown.showEmailUpdate && (
					<UpdateEmail
						toggle={doToggleEmail}
						toggleSettings={doToggleSettings}
						verifyPassword={doVerifyPassword}
						updateEmail={doUpdateEmail}
						user={user}
						errorMsg={userDropDown.passwordError}
						passwordVerified={userDropDown.passwordVerified}
					/>
				)}
				{userDropDown.showPhoneUpdate && (
					<UpdatePhone
						toggle={doTogglePhone}
						toggleSettings={doToggleSettings}
						verifyPassword={doVerifyPassword}
						updatePhone={doUpdatePhone}
						user={user}
						errorMsg={userDropDown.passwordError}
						passwordVerified={userDropDown.passwordVerified}
					/>
				)}
				{userDropDown.showPassUpdate && (
					<UpdatePassword
						toggle={doTogglePassword}
						toggleSettings={doToggleSettings}
						updatePass={doUpdatePassword}
						user={user}
						updatePassOthers={doUpdatePasswordOthers}
						listUsers={doListUsers}
						users={userDropDown.users}
						errorMsg={userDropDown.passwordError}
					/>
				)}
			</div>
		);
	}
}

UserDropDown.propTypes = {
	user: PropTypes.object,
	userDropDown: PropTypes.object,
	logoutFunc: PropTypes.func.isRequired,
	doToggleNotifications: PropTypes.func.isRequired,
	doToggleSettings: PropTypes.func.isRequired,
	doToggleEmail: PropTypes.func.isRequired,
	doTogglePhone: PropTypes.func.isRequired,
	doTogglePassword: PropTypes.func.isRequired,
	doVerifyPassword: PropTypes.func.isRequired,
	doUpdateName: PropTypes.func.isRequired,
	doUpdateEmail: PropTypes.func.isRequired,
	doUpdatePassword: PropTypes.func.isRequired,
	doUpdatePasswordOthers: PropTypes.func.isRequired,
	doListUsers: PropTypes.func.isRequired,
	src: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	userDropDown: makeSelectUserDropDown(),
	serverVersion: makeSelectServerVersion(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		logoutFunc: () => dispatch(logout()),
		doToggleSettings: () => dispatch(toggleSettings()),
		doToggleNotifications: () => dispatch(toggleNotifications()),
		doToggleEmail: () => dispatch(toggleEmail()),
		doTogglePassword: () => dispatch(togglePassword()),
		doTogglePhone: () => dispatch(togglePhone()),
		doVerifyPassword: (data) => dispatch(verifyPassword(data)),
		doUpdateName: (data) => dispatch(updateName(data)),
		doUpdateEmail: (data) => dispatch(updateEmail(data)),
		doUpdatePhone: (data) => dispatch(updatePhone(data)),
		doUpdatePassword: (data) => dispatch(updatePassword(data)),
		doUpdatePasswordOthers: (data) => dispatch(updatePasswordOther(data)),
		doListUsers: (data) => dispatch(listUsers(data)),
		doPing: () => dispatch(pingServer()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropDown);
