import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the admin state domain
 */

const selectAdminDomain = (state) => state.get('admin', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Admin
 */

const makeSelectAdmin = () =>
	createSelector(selectAdminDomain, (substate) => substate);

const makeSelectClinicians = () =>
	createSelector(selectAdminDomain, (substate) =>
		returnJS(substate.get('clinicians')),
	);

const makeSelectClients = () =>
	createSelector(selectAdminDomain, (substate) =>
		returnJS(substate.get('clients')),
	);

const makeSelectChangedClinician = () =>
	createSelector(selectAdminDomain, (substate) =>
		returnJS(substate.get('changedClinician')),
	);

export default makeSelectAdmin;
export {
	selectAdminDomain,
	makeSelectClinicians,
	makeSelectClients,
	makeSelectChangedClinician,
};
