/*
 *
 * CliniciansAndClients actions
 *
 */
import {
	ADD_CLIENT,
	ADD_CLIENT_ERROR,
	ADD_CLIENT_SUCCESS,
	CHANGE_CLINCIAN,
	CHANGE_CLINCIAN_ERROR,
	CHANGE_CLINCIAN_SUCCESS,
	CLEAR_NEW_CLIENT,
	DEFAULT_ACTION,
	DELETE_CLINCIAN,
	DELETE_CLINCIAN_ERROR,
	DELETE_CLINCIAN_SUCCESS,
	EDIT_CLINCIAN,
	EDIT_CLINCIAN_ERROR,
	EDIT_CLINCIAN_SUCCESS,
	GET_CLINCIAN,
	GET_CLINCIAN_ERROR,
	GET_CLINCIAN_SUCCESS,
	LOAD_ALL_CLIENTS,
	LOAD_ALL_CLIENTS_ERROR,
	LOAD_ALL_CLIENTS_SUCCESS,
	LOAD_ALL_CLINICIANS,
	LOAD_ALL_CLINICIANS_ERROR,
	LOAD_ALL_CLINICIANS_SUCCESS,
	LOAD_CLIENTS,
	LOAD_CLIENTS_ERROR,
	LOAD_CLIENTS_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}
export function loadAllClients() {
	return {
		type: LOAD_ALL_CLIENTS,
	};
}
export function loadAllClientsSuccess(data) {
	return {
		type: LOAD_ALL_CLIENTS_SUCCESS,
		data,
	};
}
export function loadAllClientsError(err) {
	return {
		type: LOAD_ALL_CLIENTS_ERROR,
		error: err,
	};
}

export function loadClients() {
	return {
		type: LOAD_CLIENTS,
	};
}

export function loadClientsSuccess(clients) {
	return {
		type: LOAD_CLIENTS_SUCCESS,
		data: clients,
	};
}

export function loadClientsError(error) {
	return {
		type: LOAD_CLIENTS_ERROR,
		error,
	};
}

export function loadAllClinicians() {
	return {
		type: LOAD_ALL_CLINICIANS,
	};
}
export function loadAllCliniciansSuccess(data) {
	return {
		type: LOAD_ALL_CLINICIANS_SUCCESS,
		data,
	};
}
export function loadAllCliniciansError(err) {
	return {
		type: LOAD_ALL_CLINICIANS_ERROR,
		error: err,
	};
}

export function deleteClinician(data) {
	return {
		type: DELETE_CLINCIAN,
		data,
	};
}
export function deleteClinicianSuccess(data) {
	return {
		type: DELETE_CLINCIAN_SUCCESS,
		data,
	};
}
export function deleteClinicianError(err) {
	return {
		type: DELETE_CLINCIAN_ERROR,
		error: err,
	};
}

export function editClinician(data) {
	return {
		type: EDIT_CLINCIAN,
		data,
	};
}
export function editClinicianSuccess(data) {
	return {
		type: EDIT_CLINCIAN_SUCCESS,
		data,
	};
}
export function editClinicianError(err) {
	return {
		type: EDIT_CLINCIAN_ERROR,
		error: err,
	};
}

export function changeClinician(data) {
	return {
		type: CHANGE_CLINCIAN,
		data,
	};
}
export function changeClinicianSuccess(data) {
	return {
		type: CHANGE_CLINCIAN_SUCCESS,
		data,
	};
}
export function changeClinicianError(err) {
	return {
		type: CHANGE_CLINCIAN_ERROR,
		error: err,
	};
}

export function getClinician(data) {
	return {
		type: GET_CLINCIAN,
		data,
	};
}
export function getClinicianSuccess(data) {
	return {
		type: GET_CLINCIAN_SUCCESS,
		data,
	};
}
export function getClinicianError(err) {
	return {
		type: GET_CLINCIAN_ERROR,
		error: err,
	};
}

export function addClient(data) {
	return {
		type: ADD_CLIENT,
		data,
	};
}

export function addClientSuccess(data) {
	return {
		type: ADD_CLIENT_SUCCESS,
		data,
	};
}

export function addClientError(error) {
	return {
		type: ADD_CLIENT_ERROR,
		error,
	};
}

export function clearNewClient() {
	return {
		type: CLEAR_NEW_CLIENT,
	};
}

export function loadPrograms() {
	return {
		type: LOAD_PROGRAMS,
	};
}

export function loadProgramsSuccess(programs) {
	return {
		type: LOAD_PROGRAMS_SUCCESS,
		programs,
	};
}

export function loadProgramsError(error) {
	return {
		type: LOAD_PROGRAMS_ERROR,
		error,
	};
}
