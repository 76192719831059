/*
 *
 * UserForgotPassword actions
 *
 */
import {
	DEFAULT_ACTION,
	FORGOT_UPDATE_PASSWORD,
	FORGOT_UPDATE_PASSWORD_MSG,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}
export function forgotUpdatePassword(data) {
	return {
		type: FORGOT_UPDATE_PASSWORD,
		data,
	};
}
export function forgotUpdatePasswordMsg(data) {
	return {
		type: FORGOT_UPDATE_PASSWORD_MSG,
		data,
	};
}
