/**
 *
 * CommentPopover
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import TextArea from 'atoms/TextArea';

const CommentPopover = (props) => {
	const {
		toggle,
		target,
		handleInput,
		isOpen,
		title,
		comment,
		handleSubmit,
		mode,
	} = props;

	return (
		<div>
			<Popover
				placement="bottom"
				isOpen={isOpen}
				target={target}
				toggle={toggle}
			>
				<PopoverHeader>
					{title}{' '}
					<button className="float-right" onClick={toggle}>
						X
					</button>
				</PopoverHeader>
				<PopoverBody>
					{mode === 'edit' && (
						<div className="d-flex flex-column ">
							<TextArea
								className="mb-2"
								id="comment"
								handleInput={handleInput}
								value={comment}
							/>
							<button
								onClick={handleSubmit}
								type="button"
								className="btn btn-primary primary-btn-color px-4 py-2 font-10"
							>
								Sumbit
							</button>
						</div>
					)}
					{mode === 'view' && (
						<div>
							<p>{comment}</p>
						</div>
					)}
				</PopoverBody>
			</Popover>
		</div>
	);
};

CommentPopover.propTypes = {
	toggle: PropTypes.func,
	target: PropTypes.string,
	handleInput: PropTypes.func,
	isOpen: PropTypes.bool,
	title: PropTypes.string,
	comment: PropTypes.string,
	handleSubmit: PropTypes.func,
	mode: PropTypes.string,
};

export default CommentPopover;
