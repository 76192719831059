// import components
import { chunk } from 'lodash';

/**
 *
 * dultPsychiatricHistory
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import Checkbox from 'atoms/Checkbox';
import Pill from 'atoms/Pill';
import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { STEPS } from 'pages/intake/constants';
import { generateObjectArray } from 'utils/helpers';

const chunkSize = 3;

/* eslint-disable react/prefer-stateless-function */
const AdultPsychiatricHistory = (props) => {
	const {
		prevProgress,
		nextProgress,
		data,
		handleChange,
		handleCheck,
		isMobile,
	} = props;
	const {
		diagnosesHistory,
		pastPsychotherapy,
		pastPsychotherapists,
		psychiatricHospitalization,
		psychiatricHospitalizationDetails,
		psychiatricHospitalizationReason,
		familypsychiatricHistory,
		familypsychiatricHistoryDetails,
		suicideAttempt,
		suicideAttemptDetails,
		familySuicideHistory,
		familySuicideHistoryDetails,
		selfMultilation,
		selfMultilationDetails,
	} = data;
	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Intake Questionnaire
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultPsychiatricHistory)}>
				<div className="card d-flex flex-column p-4 mb-4">
					<h3 className="mb-5">Psychiatric History</h3>
					<div className="mb-2 row">
						<label htmlFor="pastPsychotherapy" className="form-font col-8">
							HAVE YOU HAD ANY PAST PSYCHIATRIC TREATMENT OR PSYCHOTHERAPY ?
						</label>
						<SelectButtonGroup
							id="pastPsychotherapy"
							value={pastPsychotherapy}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{pastPsychotherapy === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE LIST THE NAMES OF THE PSYCHIATRISTS OR THERAPISTS YOU HAVE SEEN, WHY YOU WERE YOU SEEN, WHEN AND FOR HOW LONG YOU WERE TREATED:"
							id="pastPsychotherapists"
							value={pastPsychotherapists}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}

					<span>CURRENT AND PAST DIAGNOSES:</span>
					{isMobile ? (
						<div className="mt-2 mb-4">
							{diagnosesHistory.map((diagnosis) => (
								<Pill
									key={diagnosis.id}
									text={diagnosis.name}
									selected={diagnosis.isSelected}
									onClick={() =>
										handleCheck(
											STEPS.AdultPsychiatricHistory,
											'diagnosesHistory',
											diagnosis.id,
										)
									}
								/>
							))}
						</div>
					) : (
						<div className="d-flex flex-column border-top border-left mt-2 mb-4">
							{chunk(diagnosesHistory, chunkSize).map((row) => (
								<div className="d-flex p-0" key={`diagnoses-${row}`}>
									{row.map((diagonsis) => (
										<Checkbox
											key={diagonsis.id}
											className="col-4 border-right border-bottom"
											item={diagonsis}
											checkboxGroup="diagnosesHistory"
											parent="adultPsychiatricHistory"
											handleCheck={handleCheck}
										/>
									))}
								</div>
							))}
						</div>
					)}

					<div className="mb-2 row">
						<label
							htmlFor="psychiatricHospitalization"
							className="form-font col-8"
						>
							HAVE YOU HAD ANY HOSPITALIZATIONS FOR A PSYCHIATRIC CONDITION ?
						</label>
						<SelectButtonGroup
							id="psychiatricHospitalization"
							value={psychiatricHospitalization}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{psychiatricHospitalization === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE EXPLAIN (REASON, LENGTH, etc.):"
							id="psychiatricHospitalizationReason"
							value={psychiatricHospitalizationReason}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}
					{psychiatricHospitalization === 'yes' && (
						<TextArea
							className="mb-4"
							label="WHERE AND WHEN WERE YOU HOSPITALIZED?"
							id="psychiatricHospitalizationDetails"
							value={psychiatricHospitalizationDetails}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}

					<div className="mb-2 row">
						<label
							htmlFor="familypsychiatricHistory"
							className="form-font col-8"
						>
							IS THERE ANY PSYCHIATRIC HISTORY IN YOUR FAMILY ?
						</label>
						<SelectButtonGroup
							id="familypsychiatricHistory"
							value={familypsychiatricHistory}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{familypsychiatricHistory === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE EXPLAIN:"
							id="familypsychiatricHistoryDetails"
							value={familypsychiatricHistoryDetails}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}

					<div className="mb-2 row">
						<label htmlFor="suicideAttempt" className="form-font col-8">
							HAVE YOU EVER ATTEMPTED SUICIDE ?
						</label>
						<SelectButtonGroup
							id="suicideAttempt"
							value={suicideAttempt}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{suicideAttempt === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE EXPLAIN (CIRCUMSTANCE, LETHALITY, MEANS, PLAN, MEDICAL ATTENTION NEEDED):"
							id="suicideAttemptDetails"
							value={suicideAttemptDetails}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}

					<div className="mb-2 row">
						<label htmlFor="familySuicideHistory" className="form-font col-8">
							IS THERE A FAMILY HISTORY OF SUICIDE ATTEMPTS AND OR COMPLETED
							SUICIDE ?
						</label>
						<SelectButtonGroup
							id="familySuicideHistory"
							value={familySuicideHistory}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{familySuicideHistory === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE EXPLAIN:"
							id="familySuicideHistoryDetails"
							value={familySuicideHistoryDetails}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}

					<div className="mb-2 row">
						<label htmlFor="selfMultilation" className="form-font col-8">
							HAVE YOU EVER ENGAGED IN CUTTING, BURNING OR OTHER SELF
							MULTILATION ?
						</label>
						<SelectButtonGroup
							id="selfMultilation"
							value={selfMultilation}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultPsychiatricHistory)}
							className="mb-2 col-4"
						/>
					</div>
					{selfMultilation === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE EXPLAIN:"
							id="selfMultilationDetails"
							value={selfMultilationDetails}
							handleInput={handleChange(STEPS.AdultPsychiatricHistory)}
						/>
					)}
				</div>

				<PrevNextButtons prevProgress={prevProgress} className="mt-4 mb-5" />
			</Form>
		</div>
	);
};

AdultPsychiatricHistory.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
};

export default AdultPsychiatricHistory;
