/*
 *
 * EnrollmentRequests actions
 *
 */
import {
	CLEAR_URL,
	DEFAULT_ACTION,
	SIGN_DOCUMENT,
	SIGN_DOCUMENT_ERROR,
	SIGN_DOCUMENT_SUCCESS,
	SUBMIT_ENROLLMENT_REQUESTS,
	SUBMIT_ENROLLMENT_REQUESTS_ERROR,
	SUBMIT_ENROLLMENT_REQUESTS_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function submitEnrollmentRequest(data) {
	return {
		type: SUBMIT_ENROLLMENT_REQUESTS,
		data,
	};
}
export function submitEnrollmentRequestSuccess(data) {
	return {
		type: SUBMIT_ENROLLMENT_REQUESTS_SUCCESS,
		data,
	};
}
export function submitEnrollmentRequestError(err) {
	return {
		type: SUBMIT_ENROLLMENT_REQUESTS_ERROR,
		err,
	};
}

export function signDocument(id) {
	return {
		type: SIGN_DOCUMENT,
		id,
	};
}
export function signDocumentSuccess(data) {
	return {
		type: SIGN_DOCUMENT_SUCCESS,
		data,
	};
}
export function signDocumentError(err) {
	return {
		type: SIGN_DOCUMENT_ERROR,
		err,
	};
}

export function clearUrl() {
	return {
		type: CLEAR_URL,
	};
}
