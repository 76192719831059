/*
 *
 * Patient profile reducer
 *
 */
import { fromJS } from 'immutable';

import {
	ADD_CLIENT_TO_GROUP,
	ADD_CLIENT_TO_GROUP_ERROR,
	ADD_CLIENT_TO_GROUP_SUCCESS,
	CLEAR_PROFILE,
	FETCH_GROUPS,
	FETCH_GROUPS_ERROR,
	FETCH_GROUPS_SUCCESS,
	FETCH_PATIENT_ATTENDANCE,
	FETCH_PATIENT_ATTENDANCE_ERROR,
	FETCH_PATIENT_ATTENDANCE_SUCCESS,
	FETCH_PATIENT_DOCS,
	FETCH_PATIENT_DOCS_ERROR,
	FETCH_PATIENT_DOCS_SUCCESS,
	FETCH_PATIENT_GROUPS,
	FETCH_PATIENT_GROUPS_ERROR,
	FETCH_PATIENT_GROUPS_SUCCESS,
	FETCH_PATIENT_PROFILE,
	FETCH_PATIENT_PROFILE_ERROR,
	FETCH_PATIENT_PROFILE_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	UPDATE_ENROLLMENT,
	UPDATE_ENROLLMENT_ERROR,
	UPDATE_ENROLLMENT_SUCCESS,
	UPDATE_PATIENT_INFO,
	UPDATE_PATIENT_INFO_ERROR,
	UPDATE_PATIENT_INFO_SUCCESS,
} from './constants';

export const initialState = fromJS({
	userInfo: {},
	isUpdating: false,
	loadingInfo: true,
	userError: false,
	groups: [],
	sessions: [],
	allGroups: [],
	sessionsError: '',
	loadingGroups: false,
	attendance: [],
	loadingAttendance: false,
	unenrollSuccess: false,
	unenrollError: false,
	updateSuccess: false,
	updateError: false,
	error: '',
	docs: [],
	requests: [],
	isAdding: false,
	addingError: '',
});

function patientProfileReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_PATIENT_PROFILE:
			return state.merge({
				userError: false,
				loadingInfo: true,
			});
		case FETCH_PATIENT_PROFILE_ERROR:
			return state.merge({
				userError: true,
				loadingInfo: fromJS(false),
			});
		case FETCH_PATIENT_PROFILE_SUCCESS:
			return state.merge({
				userError: false,
				userInfo: fromJS(action.payload),
				loadingInfo: fromJS(false),
			});

		case GET_SESSIONS:
			return state.set('sessionsError', '');
		case GET_SESSIONS_SUCCESS:
			return state.set('sessions', fromJS(action.data));
		case GET_SESSIONS_ERROR:
			return state.set('sessionsError', action.error);

		case FETCH_GROUPS:
			return state.merge({
				isFetchingGroups: true,
				allGroups: [],
				fetchingGroupsError: '',
			});
		case FETCH_GROUPS_SUCCESS:
			return state.merge({
				isFetchingGroups: false,
				allGroups: action.data,
				fetchingGroupsError: '',
			});
		case FETCH_GROUPS_ERROR:
			return state.merge({
				isFetchingGroups: false,
				allGroups: [],
				fetchingGroupsError: action.error,
			});

		case ADD_CLIENT_TO_GROUP:
			return state.merge({
				isAdding: true,
				addingError: '',
			});
		case ADD_CLIENT_TO_GROUP_SUCCESS:
			return state.merge({
				isAdding: false,
				addingError: '',
			});
		case ADD_CLIENT_TO_GROUP_ERROR:
			return state.merge({
				isAdding: false,
				addingError: action.error,
			});

		case UPDATE_PATIENT_INFO:
			return state.merge({
				updateError: false,
				updateSuccess: false,
				isUpdating: true,
				loadingInfo: true,
			});
		case UPDATE_PATIENT_INFO_ERROR:
			return state.merge({
				updateError: true,
				isUpdating: false,
				loadingInfo: false,
			});
		case UPDATE_PATIENT_INFO_SUCCESS:
			return state.merge({
				updateSuccess: true,
				isUpdating: false,
				loadingInfo: false,
			});

		case FETCH_PATIENT_GROUPS:
			return state.set('loadingGroups', true);
		case FETCH_PATIENT_GROUPS_ERROR:
			return state.merge({
				error: fromJS(action.error),
				loadingGroups: fromJS(false),
			});
		case FETCH_PATIENT_GROUPS_SUCCESS:
			return state.merge({
				groups: fromJS(action.payload),
				loadingGroups: false,
			});

		case UPDATE_ENROLLMENT:
			return state.merge({
				updateError: false,
				updateSuccess: false,
			});
		case UPDATE_ENROLLMENT_ERROR:
			return state.merge({
				updateError: true,
				updateSuccess: false,
			});
		case UPDATE_ENROLLMENT_SUCCESS:
			/* eslint-disable no-case-declarations */
			const groups = state.get('groups').toJS();
			const newData = action.payload.updated;
			newData.forEach((data) => {
				const group = groups.find((g) => g.program_id === data.programId);
				group.startDate = data.startDate;
				group.endDate = data.endDate;
			});

			return state.merge({
				updateError: false,
				updateSuccess: true,
				groups,
			});

		case FETCH_PATIENT_ATTENDANCE:
			return state.set('loadingAttendance', true);
		case FETCH_PATIENT_ATTENDANCE_ERROR:
			return state.merge({
				error: fromJS(action.error),
				loadingAttendance: fromJS(false),
			});
		case FETCH_PATIENT_ATTENDANCE_SUCCESS:
			return state.merge({
				attendance: fromJS(
					action.payload[0] ? action.payload[0].attendance : [],
				),
				loadingAttendance: fromJS(false),
			});

		case FETCH_PATIENT_DOCS:
			return state.set('loadingDocs', true);
		case FETCH_PATIENT_DOCS_ERROR:
			return state.merge({
				error: fromJS(action.error),
				loadingDocs: fromJS(false),
			});
		case FETCH_PATIENT_DOCS_SUCCESS:
			return state.merge({
				docs: fromJS(action.userDocs),
				requests: fromJS(action.requests),
				loadingDocs: fromJS(false),
			});

		case CLEAR_PROFILE:
			return state.merge({
				userInfo: {},
				docs: [],
				sessions: [],
				attendance: [],
				groups: [],
			});
		default:
			return state;
	}
}

export default patientProfileReducer;
