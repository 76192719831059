import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the reports state domain
 */

const selectReportsDomain = (state) => state.get('reports', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Reports
 */

const makeSelectReports = () =>
	createSelector(selectReportsDomain, (substate) => returnJS(substate));

export default makeSelectReports;
export { selectReportsDomain };
