import { call, put, takeLatest } from 'redux-saga/effects';

import {
	deleteSessionsAPI,
	extendSessionsAPI,
	fetchAttendanceAPI,
	fetchMyGroupsAPI,
	fetchSessionsAPI,
	saveAttendanceAPI,
} from 'utils/api';
import { noop } from 'utils/common';

import {
	deleteSessionsError,
	deleteSessionsSuccess,
	extendSessionsError,
	extendSessionsSuccess,
	getAttendanceError,
	getAttendanceSuccess,
	getSessionsError,
	getSessionsSuccess,
	loadProgramsError,
	loadProgramsSuccess,
	saveAttendanceError,
	saveAttendanceSuccess,
} from './actions';
import {
	DELETE_SESSIONS,
	EXTEND_SESSIONS,
	GET_ATTENDANCE,
	GET_SESSIONS,
	LOAD_PROGRAMS,
	SAVE_ATTENDANCE,
} from './constants';

export default function* attendanceSaga() {
	yield takeLatest(GET_ATTENDANCE, getAttendance);
	yield takeLatest(SAVE_ATTENDANCE, saveAttendance);
	yield takeLatest(GET_SESSIONS, getSessions);
	yield takeLatest(LOAD_PROGRAMS, getPrograms);
	yield takeLatest(DELETE_SESSIONS, deleteSessions);
	yield takeLatest(EXTEND_SESSIONS, extendSessions);
	// See example in containers/HomePage/saga.js
}

export function* getAttendance(actions) {
	const { data, cb = noop } = actions;
	try {
		const response = yield call(fetchAttendanceAPI, data);
		cb(response);
		yield put(getAttendanceSuccess(response));
	} catch (err) {
		yield put(getAttendanceError(err));
	}
}

export function* saveAttendance(actions) {
	const { data, success } = actions;
	try {
		const response = yield call(saveAttendanceAPI, data);
		yield put(saveAttendanceSuccess(response.data));
		if (success) success();
	} catch (err) {
		yield put(saveAttendanceError(err));
		if (err) err();
	}
}

export function* getSessions(actions) {
	const { data } = actions;
	try {
		const response = yield call(fetchSessionsAPI, data);
		yield put(getSessionsSuccess(response));
	} catch (err) {
		yield put(getSessionsError(err));
	}
}

export function* getPrograms() {
	try {
		const response = yield call(fetchMyGroupsAPI);
		yield put(loadProgramsSuccess(response));
	} catch (err) {
		yield put(loadProgramsError(err));
	}
}

export function* extendSessions(action) {
	const { data, cb } = action;
	try {
		const response = yield call(extendSessionsAPI, data);
		yield put(extendSessionsSuccess({ newSessions: response.newSessions }));
		cb(response.newSessions);
	} catch (err) {
		yield put(extendSessionsError(err));
		cb();
	}
}

export function* deleteSessions(actions) {
	const { data } = actions;
	try {
		const response = yield call(deleteSessionsAPI, data);
		yield put(deleteSessionsSuccess(response));
	} catch (err) {
		yield put(deleteSessionsError(err));
	}
}
