import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectRoleFromLocation } from 'containers/App/selectors';

import {
	clearError,
	forgotPasswordMessage,
	loginUserFailure,
	loginUserSuccess,
} from './actions';
import { forgotPasswordApi, loginUser } from './api';
import { FORGOT_PASS, LOGIN_USER_FAILURE, LOGIN_USER_FETCH } from './constants';
import { makeSelectSignIn } from './selectors';

export function* performLoginUser() {
	const { email, password } = yield select(makeSelectSignIn());

	const roleName = yield select(makeSelectRoleFromLocation());
	try {
		const result = yield call(loginUser, {
			email,
			password,
			roleName,
		});

		yield put(loginUserSuccess(result.data));
	} catch (err) {
		let msg = 'Incorrect email or password';
		if (err.response === undefined || err.response === null) {
			msg = 'Error communicating with server';
		} else if (err.response.status >= 400 && err.response.status < 500) {
			if (err.response && err.response.data && err.response.data.type) {
				msg = err.response.data.error;
			}
		}
		yield put(loginUserFailure(msg));
	}
}

export function* performLoginUserFailure() {
	yield put(clearError());
}

export function* performForgotPassword(action) {
	try {
		const result = yield call(forgotPasswordApi, action.data);
		yield put(forgotPasswordMessage(result));
	} catch (err) {
		yield put(forgotPasswordMessage({ msg: 'Something Went Wrong' }));
	}
}

// Individual exports for testing
export default function* signInSaga() {
	yield takeLatest(LOGIN_USER_FETCH, performLoginUser);
	yield takeLatest(LOGIN_USER_FAILURE, performLoginUserFailure);
	yield takeLatest(FORGOT_PASS, performForgotPassword);
	// See example in containers/HomePage/saga.js
}
