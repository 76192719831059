import TextInput from 'atoms/CustTextInput';
import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import GeoDropdown from 'components/GeoDropdown';
import { required } from 'utils/validations';

const ClientDetails = (props) => {
	const {
		roi,
		states,
		cities,
		zipcodes,
		isMobile,
		handleChange,
		handleNumberChange,
		handleDateChange,
		handleGeoChange,
	} = props;

	const inputGroupName = `${
		isMobile ? 'row' : 'd-flex pb-3'
	} justify-content-center`;
	const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
	const inputStyle = { width: isMobile && '100%', maxWidth: '350px' };

	return (
		<div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="NAME*"
						id="clientName"
						value={roi.clientName}
						placeholder="Name"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="EMAIL*"
						id="clientEmail"
						value={roi.clientEmail}
						placeholder="Email"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<CustomDatePicker
						label={<span className="form-group">DATE OF BIRTH</span>}
						id="dateOfBirth"
						selected={roi.dateOfBirth}
						handleDateChange={handleDateChange('dateOfBirth')}
						maxDate={new Date()}
						placeholderText="Select Date of Birth"
					/>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<PhoneNumberInput
						id="clientPhone"
						label="PHONE NUMBER*"
						value={roi.clientPhone}
						placeholder="Enter 10 Digit Mobile Number"
						handleInput={handleNumberChange}
					/>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="ADDRESS*"
						id="clientAddress"
						value={roi.clientAddress}
						placeholder="Address"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<GeoDropdown
						states={states}
						cities={roi.clientState ? cities[roi.clientState.value] : []}
						zipcodes={roi.clientCity ? zipcodes[roi.clientCity.value] : []}
						handleChange={handleGeoChange}
						state={roi.clientState}
						city={roi.clientCity}
						zipcode={roi.clientZipcode}
						isRequired
					/>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="CLIENT NAME*"
						id="clientAuthName"
						name="clientAuthName"
						value={roi.clientAuthName}
						handleInput={handleChange}
						validations={[required]}
						placeholder="Enter Client Name"
					/>
				</div>
			</div>
		</div>
	);
};

export default ClientDetails;
