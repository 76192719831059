import BehavioralGuidelines from 'components/BehavioralGuidelines';
import DrugAlcoholPolicy from 'components/DrugAlcoholPolicy';
import GroupStandards from 'components/GroupStandards';
import GroupTherapyConsentFrom from 'components/GroupTherapyConsentFrom';
import TherapeuticGroupAgreement from 'components/TherapeuticGroupAgreement';

import KetamineOverview from './KetamineOverview';
import KetamineAdministration from './KetamineAdministration';
import KetaminePsychotherapy from './KetaminePsychotherapy';
import KetamineTreatment from './KetamineTreatment';
import KetamineFinal from './KetamineFinal';

export const groupTabs = [
    {
        component: GroupStandards,
        checkName: 'groupStandardsChecked',
    },
    {
        component: BehavioralGuidelines,
        checkName: 'behavioralGuideLinesChecked',
    },
    {
        component: DrugAlcoholPolicy,
        checkName: 'drugAlcoholPolicyChecked',
    },
    {
		component: TherapeuticGroupAgreement,
		checkName: 'therapyGroupChecked',
	},
	{
		component: GroupTherapyConsentFrom,
		checkName: 'groupTherapyConsentFormChecked',
	},
];

export const ketamineTabs = [
    {
        component: KetamineOverview,
    },
    {
        component: KetamineAdministration,
    },
    {
        component: KetaminePsychotherapy,
    },
    {
        component: KetamineTreatment,
    },
    {
        component: KetamineFinal,
        checkName: 'ketamineChecked',
    }
];
