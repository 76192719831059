/**
 *
 * UpdateEmail
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import PasswordInput from 'atoms/PasswordInput';
import PhoneNumberInput from 'atoms/PhoneNumberInput';

import './index.css';

class UpdatePhone extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			password: '',
			phone: '',
			errorPhone: false,
		};
	}

	toggle = () => {
		const { toggle, toggleSettings } = this.props;

		toggleSettings();
		toggle();
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleInput = (id, values) => {
		this.setState({ [id]: values.value });
	};

	handlePasswordSubmit = () => {
		const { password } = this.state;
		const { user, verifyPassword } = this.props;

		const data = {
			id: user.id,
			password,
		};
		verifyPassword(data);
	};

	phoneValidation = () => {
		const { phone } = this.state;
		return phone.length === 10;
	};

	handlePhoneSubmit = () => {
		const isPhoneValidated = this.phoneValidation();
		const { phone } = this.state;
		const { user, updatePhone } = this.props;

		if (!isPhoneValidated) {
			return;
		}

		const data = {
			id: user.id,
			phone,
		};

		updatePhone(data);
	};

	render() {
		const { password, phone, errorPhone } = this.state;
		const { passwordVerified, errorMsg } = this.props;

		return (
			<div>
				{!passwordVerified ? (
					<Modal
						className="modal-dialog-centered"
						isOpen={this.state.modal}
						toggle={this.toggle}
					>
						<ModalHeader toggle={this.toggle} className="border-remove" />
						<ModalBody>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12">
										<h3 className="m-0 heading-update-email">
											Please verify your password
										</h3>
										<div className="d-flex justify-content-center error-font mb-2 mt-3">
											{errorMsg}
										</div>
										<div className="form-group mt-3">
											<PasswordInput
												label={
													<label className="form-font" htmlFor="password">
														PASSWORD
													</label>
												}
												value={password}
												onChange={this.handleChange}
												name="password"
												className="form-control"
												placeholder="Enter password"
											/>
										</div>
										<div className="mt-4">
											<button
												onClick={this.handlePasswordSubmit}
												type="button"
												className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
											>
												CONTINUE
											</button>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				) : (
					<Modal
						className="modal-dialog-centered"
						isOpen={this.state.modal}
						toggle={this.toggle}
					>
						<ModalHeader toggle={this.toggle} className="border-remove" />
						<ModalBody>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12">
										<h3 className="m-0 heading-update-email">
											Enter your new phone number
										</h3>
										<PhoneNumberInput
											label="NEW PHONE NUMBER"
											placeholder="Enter phone number"
											handleInput={this.handleInput}
											className="mt-3"
											id="phone"
											value={phone}
											isRequired={true}
											readOnly={false}
										/>
										{errorPhone && (
											<div className="invalid-feedback">
												Invalid phone number
											</div>
										)}
										<div className="mt-4">
											<button
												onClick={this.handlePhoneSubmit}
												type="button"
												className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
											>
												UPDATE PHONE NUMBER
											</button>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				)}
			</div>
		);
	}
}

UpdatePhone.propTypes = {
	toggle: PropTypes.func.isRequired,
	toggleSettings: PropTypes.func.isRequired,
	verifyPassword: PropTypes.func.isRequired,
	passwordVerified: PropTypes.bool.isRequired,
	updatePhone: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	errorMsg: PropTypes.string,
};

export default UpdatePhone;
