import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomDatePicker from 'atoms/CustomDatePicker';
import SelectAutoComplete from 'atoms/SelectAutoComplete';

/* eslint-disable react/prefer-stateless-function */
class AddClientToGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			selectedClient: '',
			programsAlreadyEnrolled: [],
			groupsToEnrollIn: [],
			enrollDate: new Date().toUTCString(),
		};
	}

	componentDidMount = () => {
		const { client } = this.props;

		if (client) {
			this.setState({ selectedClient: { value: client.id } });
		}
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleSubmit = () => {
		const { selectedClient, groupsToEnrollIn, enrollDate } = this.state;
		const { addNewClient, hide } = this.props;
		const data = {
			patientId: selectedClient.value,
			enrolledGroups: groupsToEnrollIn,
			enrollDate,
		};
		addNewClient(data);
		hide();
	};

	toggle = () => {
		const { hide } = this.props;
		hide();
	};

	handleMultiSelect = (event) => {
		let { groupsToEnrollIn } = this.state;
		groupsToEnrollIn = event;
		this.setState({ groupsToEnrollIn });
	};

	handleClientSelect = (event) => {
		const { patientList } = this.props;
		let { selectedClient, programsAlreadyEnrolled } = this.state;
		selectedClient = event;
		const patient = patientList.find(
			(item) => item.client.name === selectedClient.label,
		);
		programsAlreadyEnrolled = patient.programIds;
		this.setState({ selectedClient, programsAlreadyEnrolled });
	};

	render() {
		let {
			programs = [],
			program,
			patientList,
			mode = 'multi',
			client,
		} = this.props;
		const { programsAlreadyEnrolled } = this.state;

		programs = programs.map((item) => ({
			label: item.groupName,
			value: item.id,
		}));
		programs = programs.filter(
			(item) => !programsAlreadyEnrolled.includes(item.value),
		);

		if (!client) {
			patientList = patientList.map((item) => ({
				label: item.client.name,
				value: item.client.patientId,
			}));
		}

		return (
			<div>
				<Modal
					isOpen={this.state.modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.toggle} className="border-0">
						Add {client?.name || 'Client'} to{' '}
						{mode === 'multi' ? 'Therapy' : program.groupName}
					</ModalHeader>
					<ModalBody className="edit-profile container pt-0">
						<div className="form-group col-md-12">
							<div className="form-group">
								{!client && (
									<>
										<label className="form-font" htmlFor="groupToEnroll">
											CLIENT*
										</label>
										<SelectAutoComplete
											handleChange={this.handleClientSelect}
											options={patientList}
											value={this.state.selectedClient}
											placeholder="Select Client"
										/>
									</>
								)}
							</div>
							{mode === 'multi' && (
								<div className="form-group">
									<label className="form-font" htmlFor="groupToEnroll">
										GROUPS TO ENROLL*
									</label>
									<SelectAutoComplete
										multiple
										handleChange={this.handleMultiSelect}
										options={programs}
										value={this.state.groupsToEnrollIn}
										placeholder="Select Therapy"
										disabled={!this.state.selectedClient}
									/>
								</div>
							)}
							<div>
								<label className="form-font" htmlFor="enrollDate">
									ENROLL DATE
								</label>
								<CustomDatePicker
									selected={this.state.enrollDate}
									handleDateChange={(value) =>
										this.setState({ enrollDate: value })
									}
									id="enrollDate"
								/>
							</div>
							<div className="mt-5 mb-4 mr-3 d-flex justify-content-end">
								<div>
									<button
										onClick={this.toggle}
										type="button"
										className="btn btn-white btn-lg btn-block link-color border font-15"
									>
										CANCEL
									</button>
								</div>
								<div>
									<button
										onClick={this.handleSubmit}
										type="button"
										className="btn btn-primary btn-lg btn-block btn-color font-15 ml-3"
									>
										SUBMIT
									</button>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

AddClientToGroup.propTypes = {
	programs: PropTypes.array,
	hide: PropTypes.func,
	patientList: PropTypes.array,
	addNewClient: PropTypes.func,
};

export default AddClientToGroup;
