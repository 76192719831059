import { call, put, takeLatest } from 'redux-saga/effects';

import {
	changeClinicianAPI,
	fetchAllClientsAPI,
	fetchClinicians,
} from 'utils/api';

import {
	changeClinicianError,
	changeClinicianSuccess,
	loadClientsError,
	loadClientsSuccess,
	loadCliniciansError,
	loadCliniciansSuccess,
} from './actions';
import { CHANGE_CLINICIAN, LOAD_CLIENTS, LOAD_CLINICIANS } from './constants';

export default function* adminSaga() {
	yield takeLatest(LOAD_CLINICIANS, getClinicians);
	yield takeLatest(LOAD_CLIENTS, getClients);
	yield takeLatest(CHANGE_CLINICIAN, changeClinician);
}

export function* getClinicians() {
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(fetchClinicians);
		yield put(loadCliniciansSuccess(response));
	} catch (err) {
		yield put(loadCliniciansError(err));
	}
}

export function* getClients() {
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(fetchAllClientsAPI);
		yield put(loadClientsSuccess(response.data));
	} catch (err) {
		yield put(loadClientsError(err));
	}
}

export function* changeClinician(actions) {
	const { data } = actions;
	try {
		const response = yield call(changeClinicianAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(changeClinicianError());
		} else {
			yield put(changeClinicianSuccess(response.data));
			if (data.callback) {
				data.callback();
			}
		}
	} catch (err) {
		yield put(changeClinicianError(err));
	}
}
