import { createSelector } from 'reselect';

import { initialState } from './reducer';

/**
 * Direct selector to the browseGroupsPrivate state domain
 */

const selectBrowseGroupsPrivateDomain = (state) =>
	state.get('browseGroupsPrivate', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by BrowseGroupsPrivate
 */

const makeSelectAllProgramsPrivate = () =>
	createSelector(selectBrowseGroupsPrivateDomain, (substate) =>
		substate.get('programs'),
	);

export { selectBrowseGroupsPrivateDomain, makeSelectAllProgramsPrivate };
