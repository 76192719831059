import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import TextInput from 'atoms/TextInput';
import GeoDropdown from 'components/GeoDropdown';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-destructuring */
class PrimaryCare extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			primaryMdName: '',
			primaryOtherMdName: '',
			primaryOtherMdContactInfo: null,
			clinicName: '',
			clinicStreet: '',
			clinicPhone: null,
			clinicFax: '',
			primaryMdGeo: {
				state: null,
				city: null,
				zipcode: null,
			},
			name: '',
			authorizeOtherMds: 'yes',
			therapistName: '',
			therapistStreet: '',
			therapistPhone: null,
			therapistFax: '',
			therapistGeo: {
				state: null,
				city: null,
				zipcode: null,
			},
			otherMdName: '',
			otherMdContactInfo: null,
			pharmacyName: '',
			pharmacyStreet: '',
			pharmacyPhone: null,
			pharmacyFax: '',
			pharmacyGeo: {
				state: null,
				city: null,
				zipcode: null,
			},
			careTakerName: '',
			careTakerPhone: null,
		};
	}

	changeState(currentProps) {
		const { auth, patientIntakeFields } = currentProps;
		const { doctor } = patientIntakeFields;
		const { therapist } = patientIntakeFields;
		const { pharmacy } = patientIntakeFields;

		this.setState({ name: auth.loggedInUser.name });
		if (
			currentProps.patientIntakeFields.doctor &&
			currentProps.patientIntakeFields.doctor.id
		) {
			const { primaryMdGeo = {} } = this.state;
			// eslint-disable-next-line prefer-destructuring

			if (!primaryMdGeo.state) {
				primaryMdGeo.state = this.props.states.filter(
					(state) => state.value === doctor.clinic.address.state,
				)[0];
			}
			if (!primaryMdGeo.city) {
				primaryMdGeo.city = {
					label: doctor.clinic.address.city,
					value: doctor.clinic.address.city,
				};
			}
			if (!primaryMdGeo.zipcode) {
				primaryMdGeo.zipcode = {
					label: doctor.clinic.address.zipcode,
					value: doctor.clinic.address.zipcode,
				};
			}
			this.setState({
				doctorId: currentProps.patientIntakeFields.doctor.id,
				doctorClinicId: currentProps.patientIntakeFields.doctor.clinic.id,
				doctorClinicAddressId:
					currentProps.patientIntakeFields.doctor.clinic.addressId,
				primaryMdName: this.state.primaryMdName || doctor.name,
				clinicName: this.state.clinicName || doctor.clinic.name,
				clinicStreet: this.state.clinicStreet || doctor.clinic.address.street,
				clinicPhone: this.state.clinicPhone || doctor.clinic.phone,
				clinicFax: this.state.clinicFax || doctor.clinic.address.fax,
				primaryOtherMdName: this.state.primaryOtherMdName || doctor.otherMdName,
				primaryOtherMdContactInfo:
					this.state.primaryOtherMdContactInfo || doctor.otherMdContactInfo,
				careTakerName: this.state.careTakerName || doctor.careTakerName,
				careTakerPhone: this.state.careTakerPhone || doctor.careTakerPhone,
				primaryMdGeo,
			});
		}
		if (
			currentProps.patientIntakeFields.therapist &&
			currentProps.patientIntakeFields.therapist.id
		) {
			const { therapistGeo } = this.state;
			// eslint-disable-next-line prefer-destructuring
			if (!therapistGeo.state) {
				therapistGeo.state = this.props.states.filter(
					(state) => state.value === therapist.clinic.address.state,
				)[0];
			}
			if (!therapistGeo.city) {
				therapistGeo.city = {
					label: therapist.clinic.address.city,
					value: therapist.clinic.address.city,
				};
			}
			if (!therapistGeo.zipcode) {
				therapistGeo.zipcode = {
					label: therapist.clinic.address.zipcode,
					value: therapist.clinic.address.zipcode,
				};
			}
			this.setState({
				therapistId: currentProps.patientIntakeFields.therapist.id,
				therapistClinicId: currentProps.patientIntakeFields.therapist.clinic.id,
				therapistClinicAddressId:
					currentProps.patientIntakeFields.therapist.clinic.addressId,
				therapistName: this.state.therapistName || therapist.name,
				otherMdName: this.state.otherMdName || therapist.otherMdName,
				otherMdContactInfo:
					this.state.otherMdContactInfo || therapist.otherMdContactInfo,
				therapistStreet:
					this.state.therapistStreet || therapist.clinic.address.street,
				therapistPhone: this.state.therapistPhone || therapist.clinic.phone,
				therapistFax: this.state.therapistFax || therapist.clinic.address.fax,
				therapistGeo,
			});
		}
		if (
			currentProps.patientIntakeFields.pharmacy &&
			currentProps.patientIntakeFields.pharmacy.id
		) {
			const { pharmacyGeo = {} } = this.state;
			// eslint-disable-next-line prefer-destructuring
			if (!pharmacyGeo.state) {
				pharmacyGeo.state = this.props.states.filter(
					(state) => state.value === pharmacy.address.state,
				)[0];
			}
			if (!pharmacyGeo.city) {
				pharmacyGeo.city = {
					label: pharmacy.address.city,
					value: pharmacy.address.city,
				};
			}
			if (!pharmacyGeo.zipcode) {
				pharmacyGeo.zipcode = {
					label: pharmacy.address.zipcode,
					value: pharmacy.address.zipcode,
				};
			}
			this.setState({
				pharmacyId: currentProps.patientIntakeFields.pharmacy.id,
				pharmacyAddressId: currentProps.patientIntakeFields.pharmacy.addressId,
				pharmacyName: this.state.pharmacyName || pharmacy.name,
				pharmacyStreet: this.state.pharmacyStreet || pharmacy.address.street,
				pharmacyPhone: this.state.pharmacyPhone || pharmacy.phone,
				pharmacyFax: this.state.pharmacyFax || pharmacy.address.fax,
			});
		}
	}

	componentDidMount() {
		this.changeState(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientIntakeFields.doctor &&
			Object.getOwnPropertyNames(nextProps.patientIntakeFields.doctor).length >
				0 &&
			(!this.state.primaryMdGeo.state ||
				!this.state.therapistGeo.state ||
				!this.state.pharmacyGeo.state)
		)
			this.changeState(nextProps);
	}

	handleNumberChange = (id, values) => {
		this.setState({ [id]: values.value });
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	prevProgress = () => {
		const { prevProgress } = this.props;

		prevProgress();
	};

	nextProgress = (event) => {
		const { nextProgress, auth, primaryCareChecked, therapistChecked } =
			this.props;
		const data = {};
		const clinicPhone = !this.state.clinicPhone ? '' : this.state.clinicPhone;
		const primaryOtherMdContactInfo = !this.state.primaryOtherMdContactInfo
			? ''
			: this.state.primaryOtherMdContactInfo;
		const therapistPhone = !this.state.therapistPhone
			? ''
			: this.state.therapistPhone;
		const otherMdContactInfo = !this.state.otherMdContactInfo
			? ''
			: this.state.otherMdContactInfo;
		const pharmacyPhone = !this.state.pharmacyPhone
			? ''
			: this.state.pharmacyPhone;
		const careTakerPhone = !this.state.careTakerPhone
			? ''
			: this.state.careTakerPhone;

		data.id = auth.loggedInUser.id;
		data.doctor = {
			id: this.state.doctorId,
			name: this.state.primaryMdName,
			clinic: {
				id: this.state.doctorClinicId,
				addressId: this.state.doctorClinicAddressId,
				street: this.state.clinicStreet,
				name: this.state.clinicName,
				phone: clinicPhone,
				fax: this.state.clinicFax,
				city: !this.state.primaryMdGeo.city
					? ''
					: this.state.primaryMdGeo.city.value,
				state: !this.state.primaryMdGeo.state
					? ''
					: this.state.primaryMdGeo.state.value,
				zipcode: !this.state.primaryMdGeo.zipcode
					? ''
					: this.state.primaryMdGeo.zipcode.value,
			},
			otherMdName: this.state.primaryOtherMdName,
			otherMdContactInfo: primaryOtherMdContactInfo,
			careTakerName: this.state.careTakerName,
			careTakerPhone,
		};
		data.therapist = {
			id: this.state.therapistId,
			name: this.state.therapistName,
			otherMdName: this.state.otherMdName,
			otherMdContactInfo,
			clinic: {
				id: this.state.therapistClinicId,
				addressId: this.state.therapistClinicAddressId,
				street: this.state.therapistStreet,
				phone: therapistPhone,
				fax: this.state.therapistFax,
				city: !this.state.therapistGeo.city
					? ''
					: this.state.therapistGeo.city.value,
				state: !this.state.therapistGeo.state
					? ''
					: this.state.therapistGeo.state.value,
				zipcode: !this.state.therapistGeo.zipcode
					? ''
					: this.state.therapistGeo.zipcode.value,
			},
		};
		data.pharmacy = {
			id: this.state.pharmacyId,
			addressId: this.state.pharmacyAddressId,
			name: this.state.pharmacyName,
			street: this.state.pharmacyStreet,
			phone: pharmacyPhone,
			fax: this.state.pharmacyFax,
			city: !this.state.pharmacyGeo.city
				? ''
				: this.state.pharmacyGeo.city.value,
			state: !this.state.pharmacyGeo.state
				? ''
				: this.state.pharmacyGeo.state.value,
			zipcode: !this.state.pharmacyGeo.zipcode
				? ''
				: this.state.pharmacyGeo.zipcode.value,
		};
		data.checks = {
			primaryCareChecked,
			therapistChecked,
		};

		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();

		nextProgress(data)(event);
	};

	handleGeoChange = (group) => (type) => (data) => {
		const { handleGeoChange } = this.props;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const groupGeo = this.state[group];
		if (type === 'state') {
			groupGeo.city = null;
			groupGeo.zipcode = null;
		} else if (type === 'city') {
			groupGeo.zipcode = null;
		}
		groupGeo[type] = data;
		this.setState({
			[group]: groupGeo,
		});
		handleGeoChange(type, data);
	};

	render() {
		const {
			handleAcceptance,
			primaryCareChecked,
			therapistChecked,
			states,
			cities,
			zipcodes,
			isMobile,
		} = this.props;
		const { primaryMdGeo, pharmacyGeo, therapistGeo } = this.state;
		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center text-center font-weight-bold mb-5 mt-5">
					Your Primary Care Physician and Other Doctors
				</h2>
				<Form>
					<div className="card d-flex flex-column p-4 bb-0">
						<h4 className="font-weight-bold">Your Primary Physician</h4>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="PRIMARY MD NAME"
									id="primaryMdName"
									value={this.state.primaryMdName}
									placeholder="Primary MD Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="CLINIC NAME"
									id="clinicName"
									value={this.state.clinicName}
									placeholder="Clinic Name"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-12 form-group">
								<TextInput
									label="STREET ADDRESS"
									value={this.state.clinicStreet}
									id="clinicStreet"
									placeholder="Street Address"
									handleInput={this.handleChange}
								/>
							</div>
						</div>

						{/** <Geo data={patientIntakeFields} keyName="doctor" /> */}
						<GeoDropdown
							states={states}
							cities={
								primaryMdGeo.state ? cities[primaryMdGeo.state.value] : []
							}
							zipcodes={
								primaryMdGeo.city ? zipcodes[primaryMdGeo.city.value] : []
							}
							handleChange={this.handleGeoChange('primaryMdGeo')}
							state={primaryMdGeo.state}
							city={primaryMdGeo.city}
							zipcode={primaryMdGeo.zipcode}
						/>

						<div className="form-row">
							<div className="col-md-6 form-group">
								<PhoneNumberInput
									label="OFFICE PHONE"
									id="clinicPhone"
									value={this.state.clinicPhone}
									placeholder="Enter 10 Digit Office Phone"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="OFFICE PHONE"
                  value={this.state.clinicPhone}
                  id="clinicPhone"
                  placeholder="Office Phone"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength={10}
                /> */}
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="OFFICE FAX"
									value={this.state.clinicFax}
									id="clinicFax"
									placeholder="Office Fax"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="OTHER MD NAME (IF APPLICABLE)"
									value={this.state.primaryOtherMdName}
									id="primaryOtherMdName"
									placeholder="Other MD Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6 form-group">
								<PhoneNumberInput
									label="OTHER MD PHONE NUMBER"
									id="primaryOtherMdContactInfo"
									value={this.state.primaryOtherMdContactInfo}
									placeholder="Enter 10 Digit Office Phone"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="OTHER MD PHONE NUMBER"
                  value={this.state.primaryOtherMdContactInfo}
                  id="primaryOtherMdContactInfo"
                  placeholder="Enter 10 Digit Phone Number"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength={10}
                /> */}
							</div>
						</div>

						<div className="mt-3">
							Would you like to authorize release of your information to
							additional caretakers or facilities?
						</div>
						<div
							className="btn-group btn-group-toggle form-row"
							data-toggle="buttons"
						>
							<label
								className={
									this.state.authorizeOtherMds === 'yes'
										? 'btn border toggle-options radio-active col-md-2 form-group'
										: 'btn border text-secondary toggle-options col-md-2 form-group'
								}
							>
								<input
									type="radio"
									name="authorizeOtherMds"
									id="yes"
									value="yes"
									autoComplete="off"
									onChange={this.handleChange}
								/>
								Yes
							</label>
							<label
								className={
									this.state.authorizeOtherMds === 'no'
										? 'btn border toggle-options radio-active col-md-2 form-group'
										: 'btn border text-secondary toggle-options col-md-2 form-group'
								}
							>
								<input
									type="radio"
									name="authorizeOtherMds"
									id="no"
									value="no"
									autoComplete="off"
									onChange={this.handleChange}
								/>
								No
							</label>
						</div>
						{this.state.authorizeOtherMds === 'yes' && (
							<div>
								<div className="form-row mt-3">
									<div className="col-md-6 form-group">
										<TextInput
											label="CARTAKER OR FACILITY NAME (IF APPLICABLE)"
											value={this.state.careTakerName}
											id="careTakerName"
											placeholder="Caretaker or Facility Name"
											handleInput={this.handleChange}
										/>
									</div>
									<div className="col-md-6 form-group">
										<PhoneNumberInput
											label="CARETAKER OR FACILITY PHONE NUMBER"
											id="careTakerPhone"
											value={this.state.careTakerPhone}
											placeholder="Enter 10 Digit Office Phone"
											handleInput={this.handleNumberChange}
										/>
										{/* <TextInput
                      label="CARETAKER OR FACILITY PHONE NUMBER"
                      value={this.state.careTakerPhone}
                      id="careTakerPhone"
                      placeholder="Enter 10 Digit Phone Number"
                      handleInput={this.handleChange}
                      validations={[isPhoneNumber]}
                      maxLength={10}
                    /> */}
									</div>
								</div>
								<div className="form-group mt-2">
									<span className="d-inline align-self-center mr-2">I</span>
									<input
										value={this.state.name}
										type="text"
										name="name"
										className="form-control text-muted accept-input d-inline align-self-center"
										placeholder="Your Name"
										onChange={this.handleChange}
									/>
									<span className="d-inline align-self-center ml-2">
										authorize Mind Therapy Clinic to communicate with my Primary
										Physician (if applicable) and caretakers or facilities (if
										applicable) regarding my care.{' '}
									</span>
								</div>
							</div>
						)}
						<p className="pt-5 pb-0">
							Click the box before &quot;I Accept&quot; to indicate your
							authorization.
						</p>
						<IAccept
							handleAcceptance={handleAcceptance}
							name="primaryCareChecked"
							value={primaryCareChecked}
						/>
					</div>
					<hr className="m-0" />
					<div className="card d-flex flex-column p-4 bt-0 bb-0">
						<h4 className="font-weight-bold">Your Therapist</h4>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="THERAPIST NAME (IF APPLICABLE)"
									value={this.state.therapistName}
									id="therapistName"
									placeholder="Therapist Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="STREET ADDRESS"
									value={this.state.therapistStreet}
									id="therapistStreet"
									placeholder="Street Address"
									handleInput={this.handleChange}
								/>
							</div>
						</div>

						{/* <Geo data={this.props.patientIntakeFields} keyName="therapist" /> */}
						<GeoDropdown
							states={states}
							cities={
								therapistGeo.state ? cities[therapistGeo.state.value] : []
							}
							zipcodes={
								therapistGeo.city ? zipcodes[therapistGeo.city.value] : []
							}
							handleChange={this.handleGeoChange('therapistGeo')}
							state={therapistGeo.state}
							city={therapistGeo.city}
							zipcode={therapistGeo.zipcode}
						/>

						<div className="form-row">
							<div className="col-md-6 form-group">
								<PhoneNumberInput
									label="THERAPIST PHONE"
									id="therapistPhone"
									value={this.state.therapistPhone}
									placeholder="Enter 10 Digit Office Phone"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="THERAPIST PHONE"
                  value={this.state.therapistPhone}
                  id="therapistPhone"
                  placeholder="Enter 10 Digit Phone Number"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength={10}
                /> */}
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="THERAPIST FAX"
									value={this.state.therapistFax}
									id="therapistFax"
									placeholder="Therapist Fax"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="OTHER THERAPIST NAME (IF APPLICABLE)"
									value={this.state.otherMdName}
									id="otherMdName"
									placeholder="Other Therapist Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6 form-group">
								<PhoneNumberInput
									label="OTHER THERAPIST PHONE NUMBER"
									id="otherMdContactInfo"
									value={this.state.otherMdContactInfo}
									placeholder="Enter 10 Digit Office Phone"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="OTHER THERAPIST PHONE NUMBER"
                  value={this.state.otherMdContactInfo}
                  id="otherMdContactInfo"
                  placeholder="Enter 10 Digit Phone Number"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength={10}
                /> */}
							</div>
						</div>
						<div className="form-group mt-4">
							<span className="d-inline align-self-center mr-2">I</span>
							<input
								value={this.state.name}
								type="text"
								name="name"
								className="form-control text-muted accept-input d-inline align-self-center"
								placeholder="Your Name"
								onChange={this.handleChange}
							/>
							<span className="d-inline align-self-center ml-2">
								authorize Mind Therapy Clinic to communicate with my Therapist
								(if applicable) regarding my care.
							</span>
						</div>
						<IAccept
							handleAcceptance={handleAcceptance}
							name="therapistChecked"
							value={therapistChecked}
						/>
					</div>
					<hr className="m-0" />
					<div className="card d-flex flex-column p-4 mb-4 bt-0">
						<h4 className="font-weight-bold">Your Pharmacy</h4>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="PHARMACY NAME"
									id="pharmacyName"
									value={this.state.pharmacyName}
									placeholder="XYZ Pharmacy"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="STREET ADDRESS"
									id="pharmacyStreet"
									value={this.state.pharmacyStreet}
									placeholder="110, Old Hill Road"
									handleInput={this.handleChange}
								/>
							</div>
						</div>

						{/* <Geo data={this.props.patientIntakeFields} keyName="pharmacy" /> */}

						<GeoDropdown
							states={states}
							cities={pharmacyGeo.state ? cities[pharmacyGeo.state.value] : []}
							zipcodes={
								pharmacyGeo.city ? zipcodes[pharmacyGeo.city.value] : []
							}
							handleChange={this.handleGeoChange('pharmacyGeo')}
							state={pharmacyGeo.state}
							city={pharmacyGeo.city}
							zipcode={pharmacyGeo.zipcode}
						/>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<PhoneNumberInput
									label="PHARMACY PHONE"
									id="pharmacyPhone"
									value={this.state.pharmacyPhone}
									placeholder="Enter 10 Digit Office Phone"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="PHARMACY PHONE"
                  value={this.state.pharmacyPhone}
                  id="pharmacyPhone"
                  placeholder="Enter 10 Digit Phone Number"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength={10}
                /> */}
							</div>
							<div className="col-md-6 form-group">
								<TextInput
									label="PHARMACY FAX"
									value={this.state.pharmacyFax}
									id="pharmacyFax"
									placeholder="Pharmacy Fax"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex mt-4 mb-5 justify-content-center">
						<div className="col-6">
							<PrevButton onClick={this.prevProgress} isMobile={isMobile} />
						</div>
						<div className="col-6">
							<NextButton onClick={this.nextProgress} isMobile={isMobile} />
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

PrimaryCare.propTypes = {
	handleAcceptance: PropTypes.func.isRequired,
	primaryCareChecked: PropTypes.bool.isRequired,
	therapistChecked: PropTypes.bool.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleGeoChange: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	states: PropTypes.array,
	cities: PropTypes.object,
	zipcodes: PropTypes.object,
	patientIntakeFields: PropTypes.object,
};

export default PrimaryCare;
