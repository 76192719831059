import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PhoneNumberInput from 'atoms/PhoneNumberInput';
import TextInput from 'atoms/TextInput';
import { required } from 'utils/validations';

/**
 *
 * PatientEmergencyContacts
 *
 */

/* eslint-disable react/prefer-stateless-function */
class PatientEmergencyContacts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emergencyContact: {
				emergencyContact: '',
				relationship: '',
				homePhone: null,
				workPhone: null,
			},
			secondaryEmergencyContact: {
				emergencyContact: '',
				relationship: '',
				homePhone: null,
				workPhone: null,
			},
			addOtherEmergencyContact: false,
		};
	}

	componentDidMount() {
		const { patientData } = this.props;
		if (
			patientData.emergencyContact &&
			patientData.emergencyContact.length > 0
		) {
			this.setState({
				emergencyContact: patientData.emergencyContact[0],
			});
		}
		if (
			patientData.emergencyContact &&
			patientData.emergencyContact.length > 1
		) {
			this.setState({
				addOtherEmergencyContact: true,
				secondaryEmergencyContact: patientData.emergencyContact[1],
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientData.emergencyContact &&
			nextProps.patientData.emergencyContact.length > 0
		) {
			this.setState({
				emergencyContact: nextProps.patientData.emergencyContact[0],
			});
		}
		if (
			nextProps.patientData.emergencyContact &&
			nextProps.patientData.emergencyContact.length > 1
		) {
			this.setState({
				addOtherEmergencyContact: true,
				secondaryEmergencyContact: nextProps.patientData.emergencyContact[1],
			});
		}
	}

	addAnotherEmergencyContact = () => {
		if (this.state.addOtherEmergencyContact === false) {
			this.setState({ addOtherEmergencyContact: true });
		} else {
			this.setState({
				addOtherEmergencyContact: false,
				secondaryEmergencyContact: {
					emergencyContact: '',
					relationship: '',
					homePhone: null,
					workPhone: null,
				},
			});
		}
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		if (name === 'emergencyContactName_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					emergencyContact: value,
				},
			}));
		}
		if (name === 'emergencyContactName_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					emergencyContact: value,
				},
			}));
		}
		if (name === 'emergencyContactRelationshipToPatient_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					relationship: value,
				},
			}));
		}
		if (name === 'emergencyContactRelationshipToPatient_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					relationship: value,
				},
			}));
		}
		// if (name === 'emergencyContactHomePhone_1') {
		//   this.setState(prevState => ({
		//     emergencyContact: {
		//       ...prevState.emergencyContact,
		//       homePhone: value,
		//     },
		//   }));
		// }
		// if (name === 'emergencyContactHomePhone_2') {
		//   this.setState(prevState => ({
		//     secondaryEmergencyContact: {
		//       ...prevState.secondaryEmergencyContact,
		//       homePhone: value,
		//     },
		//   }));
		// }
		// if (name === 'emergencyContactWorkphone_1') {
		//   this.setState(prevState => ({
		//     emergencyContact: {
		//       ...prevState.emergencyContact,
		//       workPhone: value,
		//     },
		//   }));
		// }
		// if (name === 'emergencyContactWorkphone_2') {
		//   this.setState(prevState => ({
		//     secondaryEmergencyContact: {
		//       ...prevState.secondaryEmergencyContact,
		//       workPhone: value,
		//     },
		//   }));
		// }
		// else {
		//   this.setState({ [name]: value });
		// }
	};

	handleNumberChange = (id, values) => {
		if (id === 'emergencyContactHomePhone_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					homePhone: values.value,
				},
			}));
		}
		if (id === 'emergencyContactHomePhone_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					homePhone: values.value,
				},
			}));
		}
		if (id === 'emergencyContactWorkphone_1') {
			this.setState((prevState) => ({
				emergencyContact: {
					...prevState.emergencyContact,
					workPhone: values.value,
				},
			}));
		}
		if (id === 'emergencyContactWorkphone_2') {
			this.setState((prevState) => ({
				secondaryEmergencyContact: {
					...prevState.secondaryEmergencyContact,
					workPhone: values.value,
				},
			}));
		}
	};

	nextStep = () => {
		const { step, auth } = this.props;

		const {
			emergencyContact,
			addOtherEmergencyContact,
			secondaryEmergencyContact,
		} = this.state;
		const data = {};
		data.emergencyContact = [];
		data.emergencyContact.push(emergencyContact);
		if (addOtherEmergencyContact) {
			data.addOtherEmergencyContact = true;
			data.emergencyContact.push(secondaryEmergencyContact);
		}
		const keys = Object.getOwnPropertyNames(data);
		data.id = auth.id;
		data.q = keys.join();
		step(data);
	};

	prevStep = () => {
		const { previousStep } = this.props;
		previousStep();
	};

	getEmergencyContact = (index) => {
		const emergencyContactName = `emergencyContactName_${index}`;
		const emergencyContactRelationshipToPatient = `emergencyContactRelationshipToPatient_${index}`;
		const emergencyContactWorkphone = `emergencyContactWorkphone_${index}`;
		const emergencyContactHomePhone = `emergencyContactHomePhone_${index}`;
		const emergencyContactLabel =
			index === 1
				? `EMERGENCY CONTACT (${index})*`
				: `EMERGENCY CONTACT (${index})`;
		const emergencyContactRelation =
			index === 1 ? 'RELATIONSHIP TO PATIENT*' : 'RELATIONSHIP TO PATIENT';
		const emergencyContactHome =
			index === 1
				? "EMERGENCY CONTACT'S MAIN PHONE*"
				: "EMERGENCY CONTACT'S MAIN PHONE";

		return (
			<div>
				<div className="form-row">
					<div className="col-md-6 form-group">
						<TextInput
							label={emergencyContactLabel}
							id={emergencyContactName}
							value={
								index === 1
									? this.state.emergencyContact.emergencyContact
									: this.state.secondaryEmergencyContact.emergencyContact
							}
							placeholder="Jessica Doe"
							handleInput={this.handleChange}
							validations={index === 1 ? [required] : []}
						/>
					</div>
					<div className="col-md-6 form-group">
						<TextInput
							label={emergencyContactRelation}
							id={emergencyContactRelationshipToPatient}
							value={
								index === 1
									? this.state.emergencyContact.relationship
									: this.state.secondaryEmergencyContact.relationship
							}
							placeholder="Mother"
							handleInput={this.handleChange}
							validations={index === 1 ? [required] : []}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="col-md-6 form-group">
						{/* <TextInput
              maxLength={10}
              label={emergencyContactHome}
              id={emergencyContactHomePhone}
              value={
                index === 1
                  ? this.state.emergencyContact.homePhone
                  : this.state.secondaryEmergencyContact.homePhone
              }
              placeholder="Enter 10 Digit Main Phone"
              handleInput={this.handleChange}
              validations={
                index === 1 ? [required, isPhoneNumber] : [isPhoneNumber]
              }
            /> */}
						<PhoneNumberInput
							label={emergencyContactHome}
							id={emergencyContactHomePhone}
							value={
								index === 1
									? this.state.emergencyContact.homePhone
									: this.state.secondaryEmergencyContact.homePhone
							}
							placeholder="Enter 10 Digit Main Phone"
							handleInput={this.handleNumberChange}
							isRequired
						/>
					</div>
					<div className="col-md-6 form-group">
						{/* <TextInput
              maxLength={10}
              label="EMERGENCY CONTACT'S WORK PHONE"
              id={emergencyContactWorkphone}
              value={
                index === 1
                  ? this.state.emergencyContact.workPhone
                  : this.state.secondaryEmergencyContact.workPhone
              }
              placeholder="Enter 10 Digit Work Phone"
              handleInput={this.handleChange}
              validations={[isPhoneNumber]}
            /> */}
						<PhoneNumberInput
							label="EMERGENCY CONTACT'S WORK PHONE"
							id={emergencyContactWorkphone}
							value={
								index === 1
									? this.state.emergencyContact.workPhone
									: this.state.secondaryEmergencyContact.workPhone
							}
							placeholder="Enter 10 Digit Work Phone"
							handleInput={this.handleNumberChange}
						/>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const {
			addOtherEmergencyContact,
			emergencyContact: { homePhone },
		} = this.state;
		return (
			<div>
				<Form>
					{this.getEmergencyContact(1)}

					{addOtherEmergencyContact && this.getEmergencyContact(2)}
					{addOtherEmergencyContact && (
						<button
							type="button"
							className="btn btn-white remove-button border btn-lg btn-block font-15 mt-3 font-weight-bold"
							onClick={this.addAnotherEmergencyContact}
						>
							REMOVE ADDITIONAL EMERGENCY CONTACT
						</button>
					)}
					{!addOtherEmergencyContact && (
						<button
							type="button"
							className="btn btn-white link-color border btn-lg btn-block font-15 mt-3 font-weight-bold"
							onClick={this.addAnotherEmergencyContact}
						>
							+ ADD ANOTHER EMERGENCY CONTACT
						</button>
					)}

					<div className="d-flex mt-4">
						<div className="col-6 pl-0">
							<button
								type="button"
								className="btn btn-white link-color border btn-lg btn-block font-15 font-weight-bold d-flex justify-content-start"
								onClick={this.prevStep}
							>
								<div className="col-4 d-flex justify-content-start align-self-center">
									<img className="fas fa-arrow-left" alt="arrow-left" />
								</div>
								<div className="col-4 d-flex justify-content-center">
									<span>PREV</span>
								</div>
							</button>
						</div>
						<div className="col-6 p-0 d-flex">
							<button
								disabled={!homePhone || homePhone.length < 10}
								type="button"
								className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-end"
								onClick={this.nextStep}
							>
								<div className="col-4 d-flex justify-content-center">
									<span>NEXT</span>
								</div>
								<div className="col-4 d-flex justify-content-end align-self-center">
									<img className="fas fa-arrow-right" alt="arrow-right" />
								</div>
							</button>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

PatientEmergencyContacts.propTypes = {
	patientData: PropTypes.object.isRequired,
	step: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	previousStep: PropTypes.func.isRequired,
};

export default PatientEmergencyContacts;
