import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <LegalHistory />, a functional component for Adult Intake Evaluation
 */
const LegalHistory = (props) => {
	const {
		handleChange,
		legalProblem,
		legalProblemDetails,
		onProbation,
		probationRequirements,
		isMobile,
	} = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			<h3 className="mb-5">Legal History</h3>

			<div className="mb-2 row">
				<label
					htmlFor="legalProblem"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					HAVE YOU EVER HAD ANY LEGAL PROBLEMS INCLUDING JAIL, PRISON, LAWSUITS,
					BANKRUPTCY, DUI, etc. ?
				</label>
				<SelectButtonGroup
					id="legalProblem"
					value={legalProblem}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-2' : 'col-4'}`}
				/>
			</div>
			{legalProblem === 'yes' && (
				<TextInput
					className="mb-4"
					label="IF YES, PLEASE EXPLAIN:"
					id="legalProblemDetails"
					value={legalProblemDetails}
					handleInput={handleChange}
				/>
			)}

			<div className="mb-2 row">
				<label
					htmlFor="onProbation"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					ARE YOU PRESENTLY ON DIVERSION OR PROBATION?
				</label>
				<SelectButtonGroup
					id="onProbation"
					value={onProbation}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-2' : 'col-4'}`}
				/>
			</div>
			{onProbation === 'yes' && (
				<TextArea
					className="mb-4"
					label="IF YES, WHAT ARE THE REQUIREMENTS OF YOUR DIVERSION OR PROBATION?"
					id="probationRequirements"
					value={probationRequirements}
					handleInput={handleChange}
				/>
			)}
		</div>
	);
};

LegalHistory.propTypes = {
	legalProblem: PropTypes.string.isRequired,
	legalProblemDetails: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	onProbation: PropTypes.func.isRequired,
	probationRequirements: PropTypes.func.isRequired,
};

export default LegalHistory;
