/*
 *
 * UserForgotPassword constants
 *
 */

export const DEFAULT_ACTION = 'src/UserForgotPassword/DEFAULT_ACTION';
export const FORGOT_UPDATE_PASSWORD =
	'src/UserForgotPassword/FORGOT_UPDATE_PASSWORD';
export const FORGOT_UPDATE_PASSWORD_MSG =
	'src/UserForgotPassword/FORGOT_UPDATE_PASSWORD_MSG';
