import history from 'utils/history';

import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import IlluSuccessImg from 'images/illu_success.png';

/**
 *
 * ForgotPassResult
 *
 */

/* eslint-disable react/prefer-stateless-function */
class ForgotPassResult extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
		};
	}

	toggle = () => {
		history.push('/');
	};

	render() {
		return (
			<Modal
				className="modal-dialog-centered"
				isOpen={this.state.modal}
				toggle={this.toggle}
			>
				<ModalHeader toggle={this.toggle} className="border-remove" />
				<ModalBody>
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="p-2 align-self-center">
								<img
									src={IlluSuccessImg}
									height="200"
									width="200"
									alt="success"
								/>
							</div>
						</div>
						<div className="row justify-content-center">
							<h3 className="m-0 heading-update-email">Success!</h3>
						</div>
						<div className="d-flex justify-content-center mt-3">
							Please log in with your new password.
						</div>
						<div className="row justify-content-center">
							<div className="mt-4">
								<button
									onClick={this.toggle}
									className="btn btn-primary btn-color btn-lg btn-block font-weight-bold d-flex justify-content-center font-15 text-uppercase font-13"
								>
									Return to Sign In
								</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

ForgotPassResult.propTypes = {
	message: PropTypes.string,
};

export default ForgotPassResult;
