/*
 *
 * UserDropDown reducer
 *
 */
import { fromJS } from 'immutable';

import {
	SUBMIT_FEEDBACK,
	SUBMIT_FEEDBACK_ERROR,
	SUBMIT_FEEDBACK_SUCCESS,
} from './constants';

export const initialState = fromJS({
	error: '',
	success: false,
});

function feedbackFormReducer(state = initialState, action) {
	switch (action.type) {
		case SUBMIT_FEEDBACK:
			return state.merge({
				error: '',
				success: false,
			});
		case SUBMIT_FEEDBACK_SUCCESS:
			return state.merge({
				error: false,
				success: true,
			});
		case SUBMIT_FEEDBACK_ERROR:
			return state.merge({
				error: action.error,
				success: false,
			});
		default:
			return state;
	}
}

export default feedbackFormReducer;
