import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import failImg from 'images/fail.png';
import IlluSuccessImg from 'images/illu_success.png';

import './index.css';

/**
 *
 * RequestSentModal
 *
 */

// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
class RequestSentModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
		};
	}

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	handleDelete = () => {
		const { handleDelete, id, hide } = this.props;
		handleDelete(id);
		hide();
	};

	render() {
		const { modal } = this.state;

		const { groupObject, requestType, successStatus, message, mode } =
			this.props;

		return (
			<Modal isOpen={modal} className="modal-dialog-centered" backdrop="static">
				<ModalHeader toggle={this.closeModal} className="border-0" />
				<ModalBody toggle={this.closeModal} className="container-fluid pt-0">
					<div className="d-flex flex-column">
						<div className="p-2 align-self-center">
							<img
								src={successStatus ? IlluSuccessImg : failImg}
								height="200"
								width="200"
								alt="TodosInactive"
							/>
						</div>
						<div className="align-self-center w-100">
							<div className="custom-req-sent-heading p-3 font-25 text-dark text-center">
								{message}
							</div>
						</div>
					</div>
					{requestType &&
						groupObject &&
						Object.keys(groupObject).length > 0 && (
							<div className="row border">
								<div className="d-flex flex-column w-100 p-4">
									<div className=" m-1 px-2 custom-req-sent-request-to font-10 text-cust-grey text-uppercase">
										Request To :
									</div>
									<div className="d-flex flex-column m-1">
										<div className="custom-req-sent-group-name font-weight-bold font-15 text-dark p-1 m-1">
											{groupObject.name}
										</div>
										<div className="d-flex justify-content-start p-1 m-1">
											<div className="pr-1">
												<i className="fas fa-user-circle fa-2x" />
											</div>
											<div className="ml-1">
												<h6 className="text-dark m-0 font-15">
													{groupObject.clinician.name}
												</h6>
												<p className="text-muted m-0 font-11">
													{groupObject.clinician.title}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					<div>
						{mode === 'confirmation' ? (
							<div className="d-flex justify-content-around align-items-center pt-4 px-4 pb-2">
								<button
									onClick={this.closeModal}
									type="button"
									className="btn btn-primary btn-lg bg-white link-color border text-uppercase font-13 col-5"
								>
									Cancel
								</button>
								<button
									onClick={this.handleDelete}
									type="button"
									className={`btn btn-primary btn-lg btn-block ${
										successStatus ? 'btn-color' : 'btn-warn'
									} text-uppercase font-13 col-5`}
								>
									Okay
								</button>
							</div>
						) : (
							<div className="text-center">
								<button
									onClick={this.closeModal}
									type="button"
									className="btn btn-primary btn-lg btn-color text-uppercase font-13 col-5"
								>
									Okay
								</button>
							</div>
						)}
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

RequestSentModal.propTypes = {
	hide: PropTypes.func,
	groupObject: PropTypes.object,
	requestType: PropTypes.object,
	successStatus: PropTypes.bool,
	message: PropTypes.string,
	mode: PropTypes.string,
	handleDelete: PropTypes.func,
	id: PropTypes.any,
};

export default RequestSentModal;
