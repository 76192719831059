/*
 *
 * RxRequests actions
 *
 */
import {
	CHANGE_PHARMACY,
	CHANGE_PHARMACY_ERROR,
	CHANGE_PHARMACY_SUCCESS,
	DEFAULT_ACTION,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function changePharmacy(data, cb) {
	return {
		type: CHANGE_PHARMACY,
		data,
		cb,
	};
}

export function changePharmacySuccess(data) {
	return {
		type: CHANGE_PHARMACY_SUCCESS,
		data,
	};
}

export function changePharmacyError(error) {
	return {
		type: CHANGE_PHARMACY_ERROR,
		error,
	};
}
