import history from 'utils/history';

/**
 *
 * SignIn
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { createStructuredSelector } from 'reselect';

import PasswordInput from 'atoms/PasswordInput';
import ForgotPass from 'components/ForgotPass';
import {
	makeSelectAuth,
	makeSelectIsMobile,
	makeSelectLocation,
} from 'containers/App/selectors';
import BrowseGroupsPrivate from 'containers/BrowseGroupsPrivate';
import loadIcon from 'images/load-icon.gif';
import logoLogin from 'images/logo_login.svg';
import { getRoleNameFromId } from 'utils/fetchRole';

import { forgotPassword, loginUser, toggleForgotPass } from './actions';
import './index.css';
import {
	makeSelectError,
	makeSelectForgotPassToggle,
	makeSelectForgotPassword,
	makeSelectSignIn,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class SignIn extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			showLoading: false,
			password: '',
			email: '',
		};
	}

	redirectToDashboardIfAlreadySignedIn = () => {
		if (this.props.auth && this.props.auth.isLoggedIn) {
			const roleName = getRoleNameFromId(this.props.auth.user.role);
			history.push(`/${roleName}`);
		}
	};

	componentDidMount() {
		this.redirectToDashboardIfAlreadySignedIn();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { error } = nextProps;

		if (this.state.error === '' || this.state.error === null) {
			this.setState({
				error,
				showLoading: false,
			});
		}
	}

	signIn = (e) => {
		e.preventDefault();
		this.setState({
			showLoading: true,
		});
		this.props.loginUser({
			email: this.state.email,
			password: this.state.password,
		});
	};

	moveToSignUp = () => {
		const { location } = this.props;

		const path = location.pathname.split('/');

		if (path[1] === 'signin') {
			history.push('/patient/signup');
		} else {
			history.push(`/${path[1]}/signup`);
		}
	};

	handleChange(type, event) {
		this.setState({
			[type]: event.target.value,
		});

		this.setState({ error: '' });
	}

	render() {
		const { showLoading } = this.state;
		const { isMobile } = this.props;

		return (
			<div className="container-fluid p-0 d-flex login bg-white">
				{!isMobile && (
					<div className="p-0 image-login-container container-fluid">
						<div className="row spacer"></div>
						<div className="row">
							<div className="col py-lg-5 d-flex justify-content-center">
								<BrowseGroupsPrivate />
							</div>
						</div>
					</div>
				)}
				<div
					className={`${isMobile ? 'col' : 'col-6'} align-self-center px-xl-5`}
				>
					<div className="d-flex justify-content-center">
						<img className="mb-4" src={logoLogin} alt="logoLogin" />
					</div>
					{this.state.error && (
						<div>
							<div className="d-flex justify-content-center error-font my-3">
								{this.state.error}
							</div>
						</div>
					)}
					<form className="w-100" onSubmit={this.signIn}>
						<div className="px-5 form-group">
							<label
								className="text-grey form-font font-10"
								htmlFor="InputEmail"
							>
								EMAIL
							</label>
							<input
								onChange={this.handleChange.bind(this, 'email')}
								type="email"
								name="email"
								className="form-control"
								id="InputEmail"
								aria-describedby="emailHelp"
								placeholder="Enter Email"
							/>
						</div>
						<div className="mt-3 px-5 form-group mb-2">
							<PasswordInput
								label={
									<label
										className="text-grey form-font font-10"
										htmlFor="InputPassword"
									>
										PASSWORD
									</label>
								}
								onChange={this.handleChange.bind(this, 'password')}
								name="password"
								className="form-control"
								placeholder="Enter Password"
								value={this.state.password}
							/>
						</div>
						<div
							onClick={this.props.doToggleForgotPass}
							className="link-color d-flex justify-content-end mt-2 px-5 font-13 hand"
						>
							Forgot Password?
						</div>
						<div className="d-flex justify-content-between mt-4 px-5">
							<button
								type="submit"
								className="btn btn-primary btn-color btn-lg btn-block font-15"
							>
								{showLoading ? (
									<img src={loadIcon} alt="Loading..." height="23px" />
								) : (
									<span>SIGN IN</span>
								)}
							</button>
						</div>
					</form>
					<div className="mt-4 px-5 d-flex justify-content-center font-13">
						Don&apos;t have an account yet?
						<span
							className="link-color ml-2 font-weight-bold hand"
							onClick={this.moveToSignUp}
						>
							Sign Up
						</span>
					</div>
				</div>

				{this.props.showForgotPassModal && (
					<ForgotPass
						toggle={this.props.doToggleForgotPass}
						forgotPassword={this.props.forgotPassword}
						forgotPasswordMessage={this.props.forgotPasswordMessage}
					/>
				)}
			</div>
		);
	}
}

SignIn.propTypes = {
	location: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	error: PropTypes.string,
	loginUser: PropTypes.func.isRequired,
	showForgotPassModal: PropTypes.bool.isRequired,
	doToggleForgotPass: PropTypes.func.isRequired,
	forgotPassword: PropTypes.func.isRequired,
	forgotPasswordMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	signIn: makeSelectSignIn(),
	forgotPasswordMessage: makeSelectForgotPassword(),
	location: makeSelectLocation(),
	error: makeSelectError(),
	auth: makeSelectAuth(),
	showForgotPassModal: makeSelectForgotPassToggle(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		loginUser: (data) => dispatch(loginUser(data)),
		doToggleForgotPass: () => dispatch(toggleForgotPass()),
		forgotPassword: (data) => dispatch(forgotPassword(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
