/*
 *
 * BrowseGroups constants
 *
 */

export const DEFAULT_ACTION = 'src/BrowseGroups/DEFAULT_ACTION';

export const LOAD_PROGRAMS = 'src/BrowseGroups/LOAD_PROGRAMS';
export const LOAD_PROGRAMS_SUCCESS = 'src/BrowseGroups/LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_ERROR = 'src/BrowseGroups/LOAD_PROGRAMS_ERROR';

export const CREATE_REQUEST = 'src/BrowseGroups/CREATE_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'src/BrowseGroups/CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_ERROR = 'src/BrowseGroups/CREATE_REQUEST_ERROR';

export const CREATE_REQUEST_INDIVIDUAL =
	'src/BrowseGroups/CREATE_REQUEST_INDIVIDUAL';
export const CREATE_REQUEST_INDIVIDUAL_SUCCESS =
	'src/BrowseGroups/CREATE_REQUEST_INDIVIDUAL_SUCCESS';
export const CREATE_REQUEST_INDIVIDUAL_ERROR =
	'src/BrowseGroups/CREATE_REQUEST_INDIVIDUAL_ERROR';

export const CLEAR_URL = 'src/BrowseGroups/CLEAR_URL';
