import { chunk } from 'lodash';

import PropTypes from 'prop-types';

import Checkbox from 'atoms/Checkbox';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <ChildhoodHistory />, a functional component for Adult Intake Evaluation
 */
const ChildhoodHistory = (props) => {
	const {
		handleChange,
		handleSupporterCheck,
		childhoodHistory,
		spiritualFactors,
		supportSystem,
		supporters,
		isMobile,
	} = props;

	return (
		<div className={`card d-flex flex-column p-4 ${isMobile && 'px-0'}`}>
			<h3 className="mb-5">Childhood / Cultural History</h3>

			<TextArea
				className="mb-4"
				label="PLEASE DECRIBE YOUR CHILDHOOD, INCLUDING YOUR PARENTS RELATIONSHIP, LIFESTYLE, SIBLINGS AND THEIR AGES, RELATIONSHIPS, FRIENDSHIPS, ENVIRONMENT,ANY TRAUMA, PHYSICAL, EMOTIONAL OR SEXUAL ABUSE, AND BIRTH HISTORY."
				id="childhoodHistory"
				value={childhoodHistory}
				handleInput={handleChange}
				rows={4}
			/>

			<TextArea
				className="mb-4"
				label="ARE THERE ANY CULTURAL OR SPIRITUAL FACTORS THAT YOU WOULD LIKE TO TELL US ABOUT?"
				id="spiritualFactors"
				value={spiritualFactors}
				handleInput={handleChange}
				rows={2}
			/>

			<h6>Support System</h6>
			<div className="mb-2 row">
				<label
					htmlFor="supportSystem"
					className={`form-font ${isMobile ? 'col-12' : 'col-8'}`}
				>
					DO YOU FEEL THAT YOU HAVE A SUPPORT SYSTEM?
				</label>
				<SelectButtonGroup
					id="supportSystem"
					value={supportSystem}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-2' : 'col-4'}`}
				/>
			</div>

			{supportSystem === 'yes' && (
				<div>
					<h6>CHECK ALL THAT APPLY:</h6>
					<div className="d-flex flex-column border-top border-left mt-2 mb-4">
						{chunk(supporters, 3).map((row) => (
							<div className="d-flex p-0" key={`supporters-${row}`}>
								{row.map((supporter) => (
									<Checkbox
										key={supporter.id}
										className="col-4 border-right border-bottom"
										item={supporter}
										checkboxGroup="supporters"
										parent="adultOtherHistory"
										handleCheck={handleSupporterCheck}
									/>
								))}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

ChildhoodHistory.propTypes = {
	childhoodHistory: PropTypes.string.isRequired,
	spiritualFactors: PropTypes.string.isRequired,
	supportSystem: PropTypes.string.isRequired,
	supporters: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSupporterCheck: PropTypes.func.isRequired,
};

export default ChildhoodHistory;
