/*
 *
 * Admin reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CHANGE_CLINICIAN,
	CHANGE_CLINICIAN_ERROR,
	CHANGE_CLINICIAN_SUCCESS,
	DEFAULT_ACTION,
	LOAD_CLIENTS,
	LOAD_CLIENTS_ERROR,
	LOAD_CLIENTS_SUCCESS,
	LOAD_CLINICIANS,
	LOAD_CLINICIANS_ERROR,
	LOAD_CLINICIANS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	clients: [],
	clinicians: [],
	error: '',
});

function adminReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case LOAD_CLIENTS:
			return state.set('clients', []);
		case LOAD_CLIENTS_SUCCESS:
			return state.set('clients', action.clients);
		case LOAD_CLIENTS_ERROR:
			return state.set('error', action.error);

		case LOAD_CLINICIANS:
			return state.set('clinicians', []);
		case LOAD_CLINICIANS_SUCCESS:
			return state.set('clinicians', action.clinicians);
		case LOAD_CLINICIANS_ERROR:
			return state.set('error', action.error);

		case CHANGE_CLINICIAN:
			return state;
		case CHANGE_CLINICIAN_SUCCESS:
			return state;
		case CHANGE_CLINICIAN_ERROR:
			return state.set('error', action.error);

		default:
			return state;
	}
}

export default adminReducer;
