import history from 'utils/history';

import _, { capitalize } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import RequestCard from 'cards/RequestCard';
import FormShare from 'components/FormShare';
import PatientRequestModal from 'components/PatientRequestModal';
import { headerStyle } from 'containers/App/constants';

/**
 *
 * MemberRequests
 *
 */
import {
	makeSelectIsMobile,
	makeSelectIsUpdatingRequest,
} from 'containers/App/selectors';
import { generateButtons } from 'containers/Requests/helpers';
import { typeToFullName } from 'utils/requests';

/* eslint-disable react/prefer-stateless-function */
export class MemberRequests extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			requestModal: false,
			groupObject: {},
			requestType: '',
			data: {},
			requestSelectedId: null,
			showChangeClinician: false,
			patientId: null,
			updatingRequestId: null,
			client: {},
			clinicianId: null,
		};
	}

	componentDidUpdate = (nextProps) => {
		if (this.state.updatingRequestId && !nextProps.isUpdating) {
			this.setState({ updatingRequestId: null });
		}
	};

	showClinicianList = (client, clinicianId) => {
		this.setState((prevState) => ({
			showChangeClinician: !prevState.showChangeClinician,
			patientId: client.id,
			client,
			clinicianId,
		}));
	};

	fullRequestName = (type) => {
		return typeToFullName(type);
	};

	showRequest = (requestData) => {
		let { payload, requestType } = requestData;
		const { groupObject } = payload;
		requestType = this.fullRequestName(requestType);

		const data = _.omit(payload, ['groupObject']);
		data.state = requestData.requestState;
		this.setState({
			requestModal: true,
			requestType,
			requestId: requestData.requestId,
			groupObject,
			data,
		});
	};

	handleRequestSentModal = (type, state, payload) => {
		const { updateRequest, auth } = this.props;
		const { requestId } = this.state;

		if (!requestId) {
			return;
		}

		const data = {
			type,
			senderUserId: auth.loggedInUser.id,
			state,
			payload,
		};

		if (type === 'DISCONTINUANCE') {
			updateRequest(requestId, data.state, data.payload.reasonForDenial);
			this.setState({
				requestId: null,
				requestModal: false,
			});
		}
	};

	closeRequestModal = () => {
		this.setState({
			requestModal: false,
		});
	};

	approve = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'APPROVED');
		this.setState({ updatingRequestId: requestId });
	};

	deny = (requestId, comment) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'DENIED', comment);
		this.setState({ updatingRequestId: requestId });
	};

	activate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, true);
		this.setState({ updatingRequestId: requestId });
	};

	deactivate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, false);
		this.setState({ updatingRequestId: requestId });
	};

	delete = (requestId) => {
		const { deleteRequest } = this.props;
		deleteRequest(requestId);
		this.setState({ updatingRequestId: requestId });
	};

	downloadFile = (docId) => {
		if (!docId) return;
		const { downloadFile } = this.props;
		downloadFile(docId);
	};

	handleViewRequest = (data) => {
		const { role, viewPdf } = this.props;
		const { requestType, requestState = '', uuid, requestId } = data;
		const downloadForms = ['form', 'roi'];
		const canDownload = downloadForms.includes(requestType.toLowerCase());

		switch (requestState) {
			default:
				if (canDownload) {
					if (uuid && uuid.length > 0) {
						return (
							<div
								className="link-color mt-2 mx-2 font-13 hand"
								cursor="pointer"
								onClick={() => viewPdf(data)}
							>
								VIEW {requestType} DOCUMENT
							</div>
						);
					} else {
						return (
							<div className="text-muted mt-2 mx-2 font-13" cursor="pointer">
								{requestType} DOC UNAVAILABLE
							</div>
						);
					}
				} else {
					return (
						<div
							className="link-color mt-2 mx-2 font-13 hand"
							cursor="pointer"
							onClick={() => this.showRequest(data)}
						>
							VIEW {requestType} REQUEST
						</div>
					);
				}
			case 'AWAITING SIGNATURE':
				return role === 'patient' ? (
					<div
						className="link-color mt-2 mx-2 font-13 hand"
						cursor="pointer"
						onClick={() => this.props.signDoc(requestId)}
					>
						SIGN {requestType}
					</div>
				) : (
					<div className="mt-2 mx-2 font-13" cursor="pointer">
						{requestType}
					</div>
				);
		}
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	// eslint-disable-next-line consistent-return
	getColumns = () => {
		const { role } = this.props;
		let userCols = {
			admin: [
				'client',
				'clinicianAdminView',
				'type',
				'createdAt',
				'status',
				'action',
			],
			clinician: ['client', 'type', 'createdAt', 'status', 'action'],
			patient: ['clinician', 'type', 'createdAt', 'status'],
		};
		const columns = {
			clinician: {
				field: 'payload',
				title: 'CLINICIAN',
				headerStyle,
				render: (row) =>
					row.requestType !== 'Rx' &&
					row.payload &&
					row.payload.assignedClinician ? (
						<ImageDetailsCell
							src={row.payload.assignedClinician.profilePicture}
							heading={row.payload.assignedClinician.name}
							primarySubHeading={row.payload.assignedClinician.therapistType}
						/>
					) : (
						row.payload &&
						row.payload.clinician && (
							<ImageDetailsCell
								src={
									row.payload.clinician && row.payload.clinician.profilePicture
								}
								heading={row.payload.clinician && row.payload.clinician.name}
								primarySubHeading={
									row.payload.clinician && row.payload.clinician.therapistType
								}
							/>
						)
					),
			},
			type: {
				field: 'requestType',
				title: 'TYPE OF REQUEST',
				headerStyle,
				render: (row) => this.handleViewRequest(row),
			},
			createdAt: {
				field: 'createdAt',
				title: 'SUBMITTED ON',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{moment(row.createdAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
			status: {
				field: 'requestState',
				title: 'STATUS',
				headerStyle,
				render: (row) => (
					<div
						className="mt-2 mx-2 font-13"
						cursor="pointer"
						onClick={() => this.props.fetchRequest(row.requestId)}
					>
						{row.requestState}
					</div>
				),
			},
			action: {
				field: 'requestState',
				title: 'ACTION',
				headerStyle,
				render: (row) => (
					<div className="d-flex">
						{generateButtons(row, this, role).map((button) => button)}
					</div>
				),
			},
			client: {
				field: 'requestorName',
				title: 'CLIENT',
				headerStyle,
				render: (row) => (
					<div
						className="hand"
						onClick={() => this.goToProfile(row.requestorId)}
					>
						<ImageDetailsCell
							src={row.profilePicture}
							heading={row.requestorName}
							primarySubHeading={capitalize(row.patientType)}
						/>
					</div>
				),
			},
			clinicianAdminView: {
				field: 'payload',
				title: 'CLINICIAN',
				headerStyle,
				render: (row) => (
					<div className="mt-2">
						{row.payload && row.payload.assignedClinician && (
							<ImageDetailsCell
								src={row.payload.assignedClinician.profilePicture}
								heading={row.payload.assignedClinician.name}
								primarySubHeading={row.payload.assignedClinician.therapistType}
							/>
						)}
						<div
							className="font-10 mt-2 link-color hand text-uppercase"
							onClick={() =>
								role === 'admin' &&
								this.showClinicianList(
									{ name: row.requestorName, id: row.requestorId },
									row.payload?.assignedClinician?.id,
								)
							}
						>
							{role === 'admin' &&
								(row.payload?.assignedClinician?.name ? 'CHANGE' : 'ASSIGN')}
						</div>
					</div>
				),
			},
		};

		return userCols[role].map((c) => columns[c]);
	};

	changeClinician = (clinician) => {
		const { changeClinician } = this.props;
		const { patientId } = this.state;
		const data = { patientId, clinicianId: clinician.id };
		changeClinician(data);
		this.setState({
			showChangeClinician: false,
		});
	};

	handleRequestClick = (request) => {
		if (request.uuid && request.uuid.length > 1) {
			this.downloadFile(request.uuid);
		} else if (request.requestType !== 'ROI') {
			this.showRequest(request);
		}
	};

	render() {
		let { data, role, auth, clinicians = [], loading, isMobile } = this.props;
		const { showChangeClinician, updatingRequestId, client, clinicianId } =
			this.state;
		const memberReqColumns = this.getColumns();

		if (!Array.isArray(data)) {
			data = [];
		}

		data.forEach(
			(r) =>
				(r.requestState =
					r.requestId === updatingRequestId ? 'UPDATING' : r.requestState),
		);

		return (
			<div>
				{isMobile ? (
					<div className="row mx-1">
						{loading ? (
							<SmallLoadingIndicator
								text={'Loading...'}
								color="var(--main-color)"
							/>
						) : (
							data.map((r) => {
								const clinician = {};

								if (r.payload.assignClinician) {
									clinician.clinicianName = r.payload.assignedClinician.name;
									clinician.therapistType =
										r.payload.assignedClinician.therapistType;
								}

								return (
									<RequestCard
										heading={this.fullRequestName(r.requestType)}
										request={r}
										clinician={clinician}
										requestor={{
											profile: r.profileUrl,
											heading: r.requestorName,
											subheading: r.requestorState,
											id: r.userId,
										}}
										onClick={() => this.handleRequestClick(r)}
										key={r.requestId}
									/>
								);
							})
						)}
					</div>
				) : (
					<Table
						keyField="memberReq"
						data={data}
						columns={memberReqColumns}
						classes={role === 'admin' ? 'table-responsive-lg' : null}
						loading={loading}
					/>
				)}
				{this.state.requestModal && (
					<PatientRequestModal
						hide={this.closeRequestModal}
						requestType={this.state.requestType}
						submitRequest={this.handleRequestSentModal}
						data={this.state.data}
						auth={auth}
						isMobile={isMobile}
					/>
				)}

				{showChangeClinician && (
					<FormShare
						clinicians={clinicians}
						type="assignClinician"
						title={`Assign ${client.name} to...`}
						hide={this.showClinicianList}
						assignClinician={this.changeClinician}
						currentClinicianId={clinicianId}
					/>
				)}
			</div>
		);
	}
}

MemberRequests.propTypes = {
	// dispatch: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
	updateRequest: PropTypes.func,
	role: PropTypes.string.isRequired,
	downloadFile: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	isMobile: makeSelectIsMobile(),
	isUpdating: makeSelectIsUpdatingRequest(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberRequests);
