import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { clinicianTypes } from 'constants/userTypes.ts';

/* eslint-disable react/prefer-stateless-function */
class UpdateClinician extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			therapistType: '',
			licenseNumber: '',
			licenseState: '',
			licenseExpirationMonth: '',
			licenseExpirationYear: '',
			supervisorName: '',
			licenseNumberSupervisor: '',
			licenseExpirationMonthSupervisor: '',
			licenseExpirationYearSupervisor: '',
			licenseStateSupervisor: '',
		};
	}

	componentDidMount() {
		const { clinicianInfo } = this.props;
		this.setState({
			...clinicianInfo.licenseDetails,
			therapistType: clinicianInfo.therapistType,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { clinicianInfo } = nextProps;
		this.setState({
			...clinicianInfo.licenseDetails,
			therapistType: clinicianInfo.therapistType,
		});
	}

	yearList() {
		const currentYear = parseInt(moment().format('YYYY'), 10);
		return [...Array(50).keys()].map((a) => (a + currentYear).toString());
	}

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	handleEdit = () => {
		const { handleEdit, clinicianId, hide } = this.props;
		const data = {
			clinicianId,
			therapistType: this.state.therapistType,
			licenseDetails: {
				licenseNumber: this.state.licenseNumber,
				licenseState: this.state.licenseState,
				licenseExpirationMonth: this.state.licenseExpirationMonth,
				licenseExpirationYear: this.state.licenseExpirationYear,
				supervisorName: this.state.supervisorName,
				licenseNumberSupervisor: this.state.licenseNumberSupervisor,
				licenseExpirationMonthSupervisor:
					this.state.licenseExpirationMonthSupervisor,
				licenseExpirationYearSupervisor:
					this.state.licenseExpirationYearSupervisor,
				licenseStateSupervisor: this.state.licenseStateSupervisor,
			},
		};
		handleEdit(data);
		hide();
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	render() {
		const { states } = this.props;
		return (
			<div>
				{' '}
				<Modal
					isOpen={this.state.modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.closeModal} className="border-0 pb-0">
						EDIT CLINICIAN INFO
					</ModalHeader>
					<ModalBody toggle={this.closeModal} className="container pt-0">
						<div className="row">
							<div className=" d-flex flex-column p-4">
								<form>
									<div className="form-group">
										<label htmlFor="therapistType" className="form-font">
											THERAPIST TYPE
										</label>
										<select
											value={this.state.therapistType}
											name="therapistType"
											className="custom-select"
											id="therapistType"
											onChange={this.handleChange}
											required
										>
											<option value="">Select Therapist Type</option>
											{clinicianTypes.map((e) => (
												<option value={e} key={e}>
													{e}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licenseNumber" className="form-font">
											SELF LICENSE NUMBER
										</label>
										<input
											defaultValue={this.state.licenseNumber}
											placeholder="License Number"
											type="text"
											name="licenseNumber"
											className="form-control"
											id="licenseNumber"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="licenseState" className="form-font">
											SELF LICENSE STATE
										</label>
										<select
											value={this.state.licenseState}
											className="custom-select"
											placeholder="Select State"
											id="licenseState"
											name="licenseState"
											onChange={this.handleChange}
											required
										>
											<option value="">Select State</option>
											{states.map((e) => (
												<option value={e.value} key={e.value}>
													{e.label}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licenseExpiration" className="form-font">
											SELF LICENSE EXPIRATION
										</label>
										<div className="d-flex">
											<select
												value={this.state.licenseExpirationMonth}
												className="custom-select"
												placeholder="Select month"
												id="licenseExpirationMonth"
												name="licenseExpirationMonth"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Month</option>
												{moment.monthsShort().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
											<select
												value={this.state.licenseExpirationYear}
												className="custom-select"
												placeholder="Select State"
												id="licenseExpirationYear"
												name="licenseExpirationYear"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Year</option>
												{this.yearList().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="licenseNumber" className="form-font">
											SUPERVISOR NAME
										</label>
										<input
											defaultValue={this.state.supervisorName}
											placeholder="Supervisor Name"
											type="text"
											name="supervisorName"
											className="form-control"
											id="supervisorName"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="licenseNumber" className="form-font">
											SUPERVISOR LICENSE NUMBER
										</label>
										<input
											defaultValue={this.state.licenseNumberSupervisor}
											placeholder="Supervisor License Number"
											type="text"
											name="licenseNumberSupervisor"
											className="form-control"
											id="licenseNumberSupervisor"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="licenseState" className="form-font">
											SUPERVISOR LICENSE STATE
										</label>
										<select
											value={this.state.licenseStateSupervisor}
											className="custom-select"
											placeholder="Select State"
											id="licenseStateSupervisor"
											name="licenseStateSupervisor"
											onChange={this.handleChange}
											required
										>
											<option value="">Select State</option>
											{states.map((e) => (
												<option value={e.value} key={e.value}>
													{e.label}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licenseExpiration" className="form-font">
											SUPERVISOR LICENSE EXPIRATION
										</label>
										<div className="d-flex">
											<select
												value={this.state.licenseExpirationMonthSupervisor}
												className="custom-select"
												placeholder="Select month"
												id="licenseExpirationMonthSupervisor"
												name="licenseExpirationMonthSupervisor"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Month</option>
												{moment.monthsShort().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
											<select
												value={this.state.licenseExpirationYearSupervisor}
												className="custom-select"
												placeholder="Select State"
												id="licenseExpirationYearSupervisor"
												name="licenseExpirationYearSupervisor"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Year</option>
												{this.yearList().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="mt-5">
										<button
											type="button"
											className="btn btn-primary btn-color w-100 font-weight-bold"
											onClick={this.handleEdit}
										>
											SAVE
										</button>
									</div>
								</form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

UpdateClinician.propTypes = {
	states: PropTypes.array,
	hide: PropTypes.func,
	handleEdit: PropTypes.func,
	clinicianId: PropTypes.any,
	clinicianInfo: PropTypes.object,
};

export default UpdateClinician;
