/*
 *
 * RxRequests constants
 *
 */

export const DEFAULT_ACTION = 'src/RxRequests/DEFAULT_ACTION';

export const CHANGE_PHARMACY = 'src/RxRequests/CHANGE_PHARMACY';
export const CHANGE_PHARMACY_SUCCESS = 'src/RxRequests/CHANGE_PHARMACY_SUCCESS';
export const CHANGE_PHARMACY_ERROR = 'src/RxRequests/CHANGE_PHARMACY_ERROR';
