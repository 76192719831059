import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { BULLET } from 'constants/main';
import { capitalizeWords } from 'utils/helpers';

import './GroupCard.css';

/* eslint-disable react/prefer-stateless-function */
class GroupCard extends React.Component {
	render() {
		const { cardGroupDetails, openDateModal, isMobile } = this.props;
		return (
			<div
				className={`${
					isMobile ? 'bg-white request-card w-100' : 'card custom-card'
				} m-2`}
			>
				<div className={`${isMobile ? 'px-4 pt-4' : 'card-body'}`}>
					<div className="card-title text-dark font-15 custom-card-heading">
						{`${cardGroupDetails.group_type} ${BULLET} ${capitalizeWords(
							cardGroupDetails.location,
						)}`}
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							{cardGroupDetails.group_size}
						</div>
					</div>
					<div className="d-flex py-1 mb-2">
						<div className="col-6 p-0">
							<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
								Upcoming
							</div>
							<div className="text-dark mb-2 custom-card-label-value font-13">
								{moment(cardGroupDetails.upcomingSession).format(
									'MMM D[,] YYYY',
								)}
							</div>
						</div>
						<div className="col-6">
							<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
								Price
							</div>
							<div className="text-dark custom-card-label-value font-13">
								${cardGroupDetails.price}
								/m
							</div>
						</div>
					</div>
					<div className="row py-1 border-top">
						<div className={`d-flex px-3 py-2 mt-1 ${isMobile && 'pb-3'}`}>
							<ImageDetailsCell
								src={cardGroupDetails.profilePicture}
								heading={cardGroupDetails.clinician_name}
								primarySubHeading={cardGroupDetails.therapist_type}
							/>
						</div>
					</div>
					{!isMobile && (
						<div className="d-flex justify-content-end align-items-center mt-2">
							<button
								type="button"
								className={
									'btn btn-primary primary-btn-color px-4 py-2 font-10'
								}
								onClick={() =>
									openDateModal(cardGroupDetails.program_id, cardGroupDetails)
								}
							>
								Select
							</button>
						</div>
					)}
				</div>
				{isMobile && (
					<div className="d-flex justify-content-end align-items-center">
						<button
							type="button"
							className="btn btn-primary primary-btn-color py-3 btn-block btn-lg"
							onClick={() =>
								openDateModal(cardGroupDetails.program_id, cardGroupDetails)
							}
						>
							Select
						</button>
					</div>
				)}
			</div>
		);
	}
}

GroupCard.propTypes = {
	cardGroupDetails: PropTypes.object.isRequired,
	openDateModal: PropTypes.func.isRequired,
};

export default GroupCard;
