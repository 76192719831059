import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the clinicianToDos state domain
 */

const selectClinicianToDosDomain = (state) =>
	state.get('clinicianToDos', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ClinicianToDos
 */

const makeSelectClinicianToDos = () =>
	createSelector(selectClinicianToDosDomain, (substate) => returnJS(substate));

export default makeSelectClinicianToDos;
export { selectClinicianToDosDomain };
