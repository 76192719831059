import { chunk } from 'lodash';

/**
 *
 * AdultMedicalContinued
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import Checkbox from 'atoms/Checkbox';
import Pill from 'atoms/Pill';
import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { STEPS } from 'pages/intake/constants';
import { generateObjectArray } from 'utils/helpers';

const familyMedicalConditionsChunks = 3;

/* eslint-disable react/prefer-stateless-function */
const AdultMedicalContinued = (props) => {
	const {
		handleCheck,
		handleChange,
		prevProgress,
		nextProgress,
		data,
		isMobile,
	} = props;
	const {
		appetite,
		recentWeightChanges,
		recentWeightChangesDescribe,
		sufferAnorexia,
		sufferAnorexiaDescribe,
		funtioningSexually,
		medicationSideEffects,
		medicalSideEffectDescribe,
		otherMedicalSymtoms,
		familyMedicalConditions,
	} = data;
	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Medical (Contd..)
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultMedicalContinued)}>
				<div className="card d-flex flex-column p-4 bb-0">
					<label htmlFor="familyMedicalConditions">
						CHECK ANY SIGNIFICANT FAMILY MEDICAL ILLNESS OR HISTORY :
					</label>
					{isMobile ? (
						<div>
							{familyMedicalConditions.map((condition) => (
								<Pill
									key={condition.id}
									text={condition.name}
									selected={condition.isSelected}
									onClick={() =>
										handleCheck(
											STEPS.AdultMedicalContinued,
											'familyMedicalConditions',
											condition.id,
										)
									}
								/>
							))}
						</div>
					) : (
						<div className="d-flex flex-column border-top border-left mt-2 mb-5">
							{chunk(
								familyMedicalConditions,
								familyMedicalConditionsChunks,
							).map((row) => (
								<div className="d-flex p-0" key={`familyMedical-${row}`}>
									{row.map((medicalCondition) => (
										<Checkbox
											key={medicalCondition.id}
											className="col-4 border-right border-bottom"
											item={medicalCondition}
											checkboxGroup="familyMedicalConditions"
											parent="adultMedicalContinued"
											handleCheck={handleCheck}
										/>
									))}
								</div>
							))}
						</div>
					)}
					<TextArea
						className="mb-3"
						label="HOW IS YOUR APPETITE?"
						id="appetite"
						value={appetite}
						placeholder="Describe"
						handleInput={handleChange(STEPS.AdultMedicalContinued)}
					/>

					<div className="row mb-3">
						<label htmlFor="recentWeightChanges" className="form-font col-md-8">
							HAVE YOU HAD ANY RECENT CHANGES IN WEIGHT ?
						</label>
						<SelectButtonGroup
							id="recentWeightChanges"
							value={recentWeightChanges}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedicalContinued)}
							className="col-md-4"
						/>
					</div>
					{recentWeightChanges === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES PLEASE DESCRIBE"
							id="recentWeightChangesDescribe"
							value={recentWeightChangesDescribe}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultMedicalContinued)}
						/>
					)}

					<div className="row mb-3">
						<label htmlFor="sufferAnorexia" className="form-font col-md-8">
							DO YOU SUFFER FROM ANOREXIA, BULIMIA OR ANY OTHER EATING DISORDER
							?
						</label>
						<SelectButtonGroup
							id="sufferAnorexia"
							value={sufferAnorexia}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedicalContinued)}
							className="col-md-4"
						/>
					</div>
					{sufferAnorexia === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES PLEASE DESCRIBE"
							id="sufferAnorexiaDescribe"
							value={sufferAnorexiaDescribe}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultMedicalContinued)}
						/>
					)}

					<div className="row mb-3">
						<label htmlFor="funtioningSexually" className="form-font col-md-8">
							SEXUALLY, ARE YOU FUNCTIONING SATISFACTORILY ?
						</label>
						<SelectButtonGroup
							id="funtioningSexually"
							value={funtioningSexually}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedicalContinued)}
							className="col-md-4"
						/>
					</div>

					<div className="row mb-3">
						<label
							htmlFor="medicationSideEffects"
							className="form-font col-md-8"
						>
							ARE ANY OF YOUR MEDICATIONS CAUSING SEXUAL SIDE EFFECTS ?
						</label>
						<SelectButtonGroup
							id="medicationSideEffects"
							value={medicationSideEffects}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultMedicalContinued)}
							className="col-md-4"
						/>
					</div>
					{medicationSideEffects === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES PLEASE DESCRIBE"
							id="medicalSideEffectDescribe"
							value={medicalSideEffectDescribe}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultMedicalContinued)}
						/>
					)}

					<TextArea
						className="mb-4 mt-n2"
						label="ARE THERE OTHER MEDICAL SYMPTOMS WE SHOULD KNOW ABOUT (i.e.
                    FORGETFULNESS, WEIGHT CHANGES, DRY OR COARSE SKIN/HAIR, CHANGE
                    IN BOWEL HABITS, etc.) ?"
						id="otherMedicalSymtoms"
						value={otherMedicalSymtoms}
						placeholder="Describe"
						handleInput={handleChange(STEPS.AdultMedicalContinued)}
					/>
				</div>

				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

AdultMedicalContinued.propTypes = {
	handleCheck: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default AdultMedicalContinued;
