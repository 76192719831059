import { useFormikContext } from 'formik';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCities, fetchZipcodes } from 'containers/App/actions';
import useCollectJS from 'hooks/useCollectJS';
import AddressInput from 'v2/forms/AddressInput';
import Form from 'v2/forms/Form';
import Input from 'v2/forms/Input';
import Label from 'v2/forms/Label';
import PhoneInput from 'v2/forms/PhoneInput';
import Select from 'v2/forms/Select';

import { paymentOptions } from './constants';
import { ACHForm, CreditCardForm } from './forms';
import { getFields } from './utils';

export default function PaymentForm(props) {
	const { onSaveToken, defaultAddress } = props;
	const dispatch = useDispatch();

	const [sameAsMainAddress, setSameAsMainAddress] = useState(false);

	const formik = useFormikContext();
	const { values, setFieldValue, setFieldTouched } = formik;
	const { isCCForm } = values;

	const [paymentToken, setPaymentToken] = useState('');
	const [address, setAddress] = useState('');

	const [errors, setErrors] = useState();

	const onValidate = useCallback(
		(name, isValid, reason) => {
			setErrors((oldErrors) => ({
				...oldErrors,
				[name]: { isValid, reason },
			}));
		},
		[errors],
	);

	useCollectJS(
		({ token }) => setPaymentToken(token),
		onValidate,
		getFields(isCCForm),
		[isCCForm],
	);

	const handleSave = useCallback(() => {
		onSaveToken({
			...values,
			paymentToken,
		});
	}, [paymentToken, values]);

	useEffect(() => {
		if (!paymentToken) return;

		handleSave();
	}, [paymentToken]);

	function toggleAddress() {
		let value;
		if (sameAsMainAddress) {
			value = {
				street: '',
				city: '',
				state: '',
				zipcode: '',
			};
			setSameAsMainAddress(false);
		} else {
			setSameAsMainAddress(true);
			dispatch(fetchCities(defaultAddress.state));
			dispatch(fetchZipcodes(defaultAddress.city));
			value = {
				street: defaultAddress.street,
				city: defaultAddress.city,
				state: defaultAddress.state,
				zipcode: defaultAddress.zipcode,
			};
		}

		setFieldTouched(true);
		setFieldValue('address', value);
	}

	function handleChange(event) {
		const {
			target: { name, value },
		} = event;

		if (name === 'street') {
			setSameAsMainAddress(false);
			setAddress({
				...address,
				street: value,
			});

			return;
		}
	}

	return (
		<Form>
			<div className="card d-flex flex-column p-4 bb-0">
				<div className="row">
					<div className="col-auto pr-0">
						<Label className="font-19 mt-2">Payment Method</Label>
					</div>
					<div className="col-auto" style={{ width: 240 }}>
						<Select
							options={paymentOptions}
							id="isCCForm"
							name="isCCForm"
							onChange={({ value }) => {
								if (value !== isCCForm) {
									setErrors({});
								}
							}}
						/>
					</div>
				</div>
				{isCCForm ? (
					<CreditCardForm errors={errors} />
				) : (
					<ACHForm errors={errors} />
				)}
				<div className="form-row">
					<div className="col-md-6">
						<PhoneInput
							label="payer phone number"
							id="userInfo.phone"
							name="userInfo.phone"
							onChange={(value) =>
								handleChange({
									target: { name: 'phone', value },
								})
							}
							placeholder="###-###-####"
							required
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="payer email"
							id="userInfo.email"
							name="userInfo.email"
							placeholder="johndoe@gmail.com"
							required
						/>
					</div>
				</div>
				<div className="mt-4">
					<h4>Billing Address </h4>
					<div className="font-12 mb-1">
						Same as main address{' '}
						<input
							type="checkbox"
							checked={sameAsMainAddress}
							onChange={toggleAddress}
							className="ml-1"
						/>
					</div>
					<div className="mb-3">
						<AddressInput
							id="address"
							name="address"
							handleChange={() => setSameAsMainAddress(false)}
						/>
					</div>
				</div>
			</div>
			<hr className="m-0" />
		</Form>
	);
}
