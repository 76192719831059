import PropTypes from 'prop-types';

import React from 'react';

import CustomDatePicker from 'atoms/CustomDatePicker';
import { required } from 'utils/validations';

class LOARequest extends React.Component {
	constructor(props) {
		super(props);
		this.type = 'loa';
	}

	handleChange = (e) => {
		const { readOnly, handleChange } = this.props;
		if (readOnly) {
			return;
		}

		handleChange(this.type)(e);
	};

	handleDateChange = (component) => (date) => {
		const { readOnly, handleDateChange } = this.props;
		if (readOnly) {
			return;
		}

		handleDateChange(this.type, component)(date);
	};

	onSubmit = () => {
		const { readOnly, closeModal, onSubmit } = this.props;
		if (readOnly) {
			closeModal();
			return;
		}

		onSubmit();
	};

	isFormIncomplete = () => {
		const { data } = this.props;

		return !(
			data.clientName &&
			data.requestDate &&
			data.fromDate &&
			data.toDate &&
			data.reasonForAbsence
		);
	};

	render() {
		const { data, readOnly, isMobile } = this.props;

		let containerStyle = {};

		if (isMobile) {
			containerStyle = {
				width: '90%',
				maxHeight: '74vh',
				overflowY: 'auto',
				overflowX: 'hidden',
			};
		}

		const inputGroupName = `${
			isMobile ? 'row justify-content-center' : 'd-flex'
		} pb-3`;
		const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
		const inputStyle = { width: isMobile && '100%', maxWidth: '400px' };

		return (
			<div className="row custom-bg-color mx-0">
				<div
					className={`container-fluid bg-white ${
						isMobile ? 'py-0 px-2 mx-auto' : 'mx-4 mb-4 py-3'
					}`}
					style={containerStyle}
				>
					<div
						className={`py-2 custom-loa-text text-dark ${
							isMobile ? 'font-14 px-1' : 'font-16 px-3'
						}`}
					>
						<p>
							Please note, Mind Therapy Clinic requires at least one (1) week
							notice to process this request. Requests submitted in less than
							two weeks prior to the date of absence will not be accepted. Mind
							Therapy Clinic does not excuse absences of less than one(1) week
							of treatment. Patients are financially responsible for all
							unexcused absences
						</p>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<div className={isMobile ? '' : 'col-md-12'}>
								<label
									htmlFor="clientName"
									className="text-uppercase cust-black-global text-dark font-10"
								>
									Client Name*
								</label>
								<div className="d-flex align-items-center border p-2">
									<input
										readOnly={readOnly}
										type="text"
										id="clientName"
										name="clientName"
										value={data.clientName}
										onChange={this.handleChange}
										aria-describedby="clientNameHelp"
										placeholder="Enter Client Name"
										className="font-17"
									/>
								</div>
							</div>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<CustomDatePicker
								disabled={true}
								className={isMobile ? '' : 'col-md-12'}
								label={
									<span className="form-group">DATE REQUEST SUBMITTED*</span>
								}
								id="toDate"
								selected={data.requestDate}
								placeholderText="Select from Date"
							/>
						</div>
					</div>
					<div className="px-3">
						<h6 className="custom-tag font-16 text-dark text-left">
							Leave of Absence Dates Requested:
						</h6>
					</div>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<CustomDatePicker
								disabled={readOnly}
								className={isMobile ? '' : 'col-md-12'}
								label={<span className="form-group">FROM *</span>}
								id="fromDate"
								selected={data.fromDate}
								handleDateChange={this.handleDateChange('fromDate')}
								minDate={data.requestDate}
								placeholderText="Select from Date"
								validations={[required]}
							/>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<CustomDatePicker
								disabled={readOnly}
								className={isMobile ? '' : 'col-md-12'}
								label={<span className="form-group">TO *</span>}
								id="toDate"
								selected={data.toDate}
								handleDateChange={this.handleDateChange('toDate')}
								minDate={data.fromDate}
								placeholderText="Select from Date"
								validations={[required]}
							/>
						</div>
					</div>
					<div className="px-3 form-group">
						<label
							htmlFor="reasonForAbsence"
							className="font-10 cust-black-global text-dark cust-loa-reason-for-absence-text text-uppercase"
						>
							Reason For Absence*
						</label>
						<textarea
							readOnly={readOnly}
							name="reasonForAbsence"
							value={data.reasonForAbsence}
							onChange={this.handleChange}
							className="form-control custom-no-radius"
							id="reasonForAbsence"
							rows="3"
						/>
					</div>
				</div>
				<div
					className={
						isMobile
							? 'row w-100 mx-0 justify-content-center mt-4'
							: 'd-flex justify-content-center align-items-center w-100 p-4 mb-3'
					}
				>
					<button
						disabled={this.isFormIncomplete()}
						type="button"
						className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
						onClick={this.onSubmit}
					>
						<span className="mr-0 pl-3 w-100">
							{readOnly ? 'OKAY' : 'SUBMIT'}
						</span>
						<i className="fas fa-check align-self-center" />
					</button>
				</div>
			</div>
		);
	}
}

LOARequest.propTypes = {
	data: PropTypes.object,
};

export default LOARequest;
