import { call, put, takeLatest } from 'redux-saga/effects';

import {
	attendanceReport,
	createProgramAndSession,
	deactivateProgramAPI,
	deleteProgramAPI,
	fetchGroupTypes,
	fetchMyGroupsAPI,
	updateProgramAndSession,
} from 'utils/api';

import {
	createProgramError,
	createProgramSuccess,
	deactivateProgramError,
	deleteProgramError,
	downloadAttendanceReportError,
	loadGroupTypesError,
	loadGroupTypesSuccess,
	loadPrograms,
	loadProgramsError,
	loadProgramsSuccess,
	updateProgramError,
	updateProgramSuccess,
} from './actions';
import {
	CREATE_PROGRAM,
	CREATE_PROGRAM_SUCCESS,
	DEACTIVATE_PROGRAM,
	DEACTIVATE_PROGRAM_SUCCESS,
	DELETE_PROGRAM,
	DELETE_PROGRAM_SUCCESS,
	DOWNLOAD_ATTENDANCE_REPORT,
	LOAD_GROUP_TYPES,
	LOAD_PROGRAMS,
	UPDATE_PROGRAM,
} from './constants';

export default function* therapiesSaga() {
	yield takeLatest(LOAD_GROUP_TYPES, getGroupTypes);
	yield takeLatest(LOAD_PROGRAMS, getPrograms);
	yield takeLatest(CREATE_PROGRAM, createProgram);
	yield takeLatest(UPDATE_PROGRAM, updateProgram);
	yield takeLatest(CREATE_PROGRAM_SUCCESS, getPrograms);
	yield takeLatest(DELETE_PROGRAM, deleteProgram);
	yield takeLatest(DELETE_PROGRAM_SUCCESS, deleteProgram);
	yield takeLatest(CREATE_PROGRAM_SUCCESS, getPrograms);
	yield takeLatest(DEACTIVATE_PROGRAM, deactivateProgram);
	yield takeLatest(DEACTIVATE_PROGRAM_SUCCESS, deactivateProgram);
	yield takeLatest(DOWNLOAD_ATTENDANCE_REPORT, downloadAttendanceReport);
	// See example in containers/HomePage/saga.js
}

export function* getGroupTypes() {
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(fetchGroupTypes);
		yield put(loadGroupTypesSuccess(response));
	} catch (err) {
		yield put(loadGroupTypesError(err));
	}
}

export function* createProgram(actions) {
	try {
		const { data } = actions;
		const response = yield call(createProgramAndSession, data);
		if (response.response && response.response.status >= 400) {
			yield put(createProgramError());
		} else {
			yield put(createProgramSuccess(response.data));
		}
	} catch (err) {
		yield put(createProgramError(err));
	}
}

export function* updateProgram(actions) {
	try {
		const { data } = actions;
		const response = yield call(updateProgramAndSession, data);
		if (response.response && response.response.status >= 400) {
			yield put(updateProgramError());
		} else {
			yield put(updateProgramSuccess(data));
		}
	} catch (err) {
		yield put(updateProgramError(err));
	}
}

export function* deleteProgram(actions) {
	try {
		const { data } = actions;
		const response = yield call(deleteProgramAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(deleteProgramError());
		} else {
			yield put(loadPrograms());
		}
	} catch (err) {
		yield put(deleteProgramError(err));
	}
}

export function* deactivateProgram(actions) {
	try {
		const { data } = actions;
		const response = yield call(deactivateProgramAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(deactivateProgramError());
		} else {
			yield put(loadPrograms());
		}
	} catch (err) {
		yield put(deactivateProgramError(err));
	}
}

export function* getPrograms() {
	try {
		const response = yield call(fetchMyGroupsAPI);
		yield put(loadProgramsSuccess(response));
	} catch (err) {
		yield put(loadProgramsError(err));
	}
}

export function* downloadAttendanceReport(actions) {
	try {
		const { data } = actions;
		yield call(attendanceReport, data);
		// yield put(loadProgramsSuccess(response));
	} catch (err) {
		yield put(downloadAttendanceReportError(err));
	}
}
