import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the enrollmentRequests state domain
 */

const selectEnrollmentRequestsDomain = (state) =>
	state.get('enrollmentRequests', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by EnrollmentRequests
 */

const makeSelectEnrollmentRequests = () =>
	createSelector(selectEnrollmentRequestsDomain, (substate) =>
		returnJS(substate),
	);

const makeSelectSigningUrl = () =>
	createSelector(selectEnrollmentRequestsDomain, (substate) =>
		returnJS(substate.get('url')),
	);

const makeSelectIsUpdating = () =>
	createSelector(selectEnrollmentRequestsDomain, (substate) =>
		returnJS(substate.get('isUpdating')),
	);

export default makeSelectEnrollmentRequests;
export {
	selectEnrollmentRequestsDomain,
	makeSelectSigningUrl,
	makeSelectIsUpdating,
};
