import history from 'utils/history';

import { startCase } from 'lodash';

import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { BULLET } from 'constants/main';
import { saveAttendance } from 'containers/Attendance/actions';
import {
	MAX_HOURS_CHECK_IN,
	MIN_HOURS_CHECK_IN,
	isProduction,
} from 'utils/constants';

const TherapyCard = (props) => {
	const { group, type } = props;
	const { upcomingSession, upcomingSessionId, status, inPerson } = group;

	const dispatch = useDispatch();
	const { permissions, loggedInUser } = useSelector((state) => ({
		permissions: state.get('app').get('permissions').toJS() || [],
		loggedInUser: state.get('app').get('auth').get('loggedInUser').toJS() || {},
	}));

	const [timeRemaining, setTimeRemaining] = useState(
		moment.utc(upcomingSession).fromNow(),
	);
	const diff = moment.utc(upcomingSession).diff(moment(), 'hours', true);

	const inTimeRange =
		upcomingSession && diff <= MAX_HOURS_CHECK_IN && diff >= MIN_HOURS_CHECK_IN;
	const canCheckIn =
		(!isProduction && upcomingSession) ||
		(inPerson && type === 'patient' && inTimeRange);
	const checkedIn = status && status !== '';
	const hasPermission = permissions.find(
		(permission) => permission === 'check-in',
	);

	function checkIn() {
		const data = [
			{
				session: { sessionId: upcomingSessionId },
				status: 'present',
				clientId: loggedInUser?.id,
				comment: '',
			},
		];

		dispatch(saveAttendance({ attendance: data }));
	}

	let actionBtn;
	if (checkedIn) {
		actionBtn = (
			<button
				className="row btn border-top btn-white btn-block font-17 mx-0 mt-2"
				style={{
					background: `var(--attendance-${status})`,
					color: 'white',
					textTransform: 'capitalize',
				}}
			>
				{status}
			</button>
		);
	} else if (canCheckIn) {
		actionBtn = (
			<button
				className="row btn border-top btn-block btn-lg font-17 mx-0 mt-2"
				style={{ background: 'var(--main-accent)', color: 'white' }}
				onClick={() =>
					hasPermission
						? checkIn()
						: history.push('/patient/dashboard/modal/covid')
				}
			>
				Check-In
			</button>
		);
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining(moment.utc(upcomingSession).fromNow());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const lastSessionValue = group.lastSession
		? moment.utc(group.lastSession).local().format('YYYY-MM-DD')
		: '-';
	let upcomingSessionValue = '-';
	if (group.upcomingSession) {
		if (diff < 24) {
			upcomingSessionValue = timeRemaining;
		} else {
			upcomingSessionValue = moment
				.utc(group.nextSession)
				.local()
				.format('YYYY-MM-DD');
		}
	}

	return (
		<div className="card-container request-card bg-white">
			<div className="row pb-0 px-3 pt-3 justify-content-center">
				<div
					className="card-title font-weight-bold mb-2 text-center font-22"
					style={{ fontSize: '18px' }}
				>
					{startCase(group.groupName)} {BULLET} {group.location}
					<div className="font-14 mt-1">
						{moment
							.utc(group.startTime || '')
							.local()
							.format('h:mm a')}{' '}
						-{moment.utc(group.endTime).local().format('h:mm a')}
					</div>
				</div>
				<div className="row w-100 mb-4 mx-2 justify-content-around border-bottom">
					<div className="col-6 px-4 text-center py-3">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							Last Session
						</div>
						<div className="font-14">{lastSessionValue}</div>
					</div>
					<div className="col-6 px-4 text-center py-3">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							Next Session
						</div>
						<div className="font-14">{upcomingSessionValue}</div>
					</div>
				</div>
				<div className="row mx-0 mb-2">
					{group.clinician && (
						<div className="col">
							<ImageDetailsCell
								src={group.clinician.profilePicture}
								heading={group.clinician.clinicianName}
								primarySubHeading={group.clinician.therapistType}
							/>
						</div>
					)}
				</div>
			</div>
			<div
				className="row btn border-top btn-white btn-block btn-lg link-color font-17 mx-0 mt-2 justify-content-end"
				onClick={() =>
					history.push(`/${type}/dashboard/groupDetails/${group.programId}`)
				}
			>
				View Group
			</div>
			{actionBtn}
		</div>
	);
};

export default TherapyCard;
