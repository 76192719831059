import { parseISO } from 'date-fns';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { useEffect, useReducer } from 'react';

import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';

import PharmacyInput from './PharmacyInput';

const rxReducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				[action.name]: action.value,
			};
		case 'SELECT':
			return {
				...state,
				...action.value,
			};
		case 'CHECKBOX':
			return {
				...state,
				[action.name]: !state[action.name],
			};
		case 'DATE':
			return {
				...state,
				[action.id]: action.value,
			};
		case 'CITY':
			return {
				...state,
				clientCity: action.value,
			};
		case 'STATE':
			return {
				...state,
				clientState: action.value,
			};
		case 'NUMBER':
			return {
				...state,
				[action.id]: action.value,
			};
		case 'LOADED_STATE':
			return {
				...action.state,
			};
		default:
			return state;
	}
};

const RXRequest = (props) => {
	const {
		data,
		readOnly,
		isMobile,
		submitForm,
		closeModal,
		states,
		cities,
		zipcodes,
		fetchCities,
		fetchZipcodes,
	} = props;
	const [state, dispatch] = useReducer(rxReducer, data);

	const handleChange = (e) => {
		if (readOnly) {
			return;
		}

		const { target } = e;
		// event.preventDefault();
		const { name, value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		dispatch({ type: 'CHANGE', name, value });
	};

	const handleNumberChange = (id, values) => {
		dispatch({ type: 'NUMBER', id, value: values.value });
	};

	const handleDateChange = (id) => (date) => {
		if (readOnly) {
			return;
		}

		let dateFormat;

		if (date) {
			dateFormat = parseISO(moment(date).format('YYYY-MM-DD'));
		}

		dispatch({ type: 'DATE', id, value: dateFormat });
	};

	const handleSubmit = () => {
		if (readOnly) {
			closeModal();
			return;
		}

		submitForm('OPEN', state);
	};

	const isFormIncomplete = () => {
		if (readOnly) return true;

		return !(
			state.clientName &&
			state.clientPhone &&
			state.clientPhone.length >= 10 &&
			state.requiredOn
		);
	};

	let containerStyle = {};

	if (isMobile) {
		containerStyle = {
			width: '90%',
			maxHeight: '74vh',
			overflowY: 'auto',
			overflowX: 'hidden',
		};
	}

	useEffect(() => {
		if (state.pharmacyState?.value) {
			fetchCities(state.pharmacyState);
		}
	}, [state.pharmacyState]);

	useEffect(() => {
		if (state.pharmacyCity?.value) {
			fetchZipcodes(state.pharmacyCity);
		}
	}, [state.pharmacyCity]);

	const containerClass = `container-fluid bg-white py-3 ${
		isMobile ? 'px-2 mx-auto' : 'mx-4'
	}`;
	const inputGroupName = `${
		isMobile ? 'row justify-content-center' : 'd-flex'
	}`;
	const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
	const inputStyle = { width: isMobile && '100%', maxWidth: '400px' };

	return (
		<div className="row mx-0 custom-bg-color">
			<div className={containerClass} style={containerStyle}>
				<form>
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<label
								htmlFor="clientName"
								className="text-uppercase cust-black-global text-dark font-10"
							>
								Client Name*
							</label>
							<div className="d-flex align-items-center border p-2">
								<input
									readOnly={readOnly}
									type="text"
									id="clientName"
									name="clientName"
									value={state.clientName}
									aria-describedby="clinicianNameHelp"
									placeholder="Client Name"
									className="font-17"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<PhoneNumberInput
								id="clientPhone"
								label="MOBILE NO.*"
								labelClass="text-uppercase cust-black-global text-dark font-10"
								value={state.clientPhone}
								placeholder="Enter 10 Digit Mobile Number"
								handleInput={handleNumberChange}
								readOnly={readOnly}
							/>
						</div>
					</div>
					{
						// This change is only for legacy rx requests made before 9/2021
						readOnly && data.pharmacyName ? (
							<div className={inputGroupName}>
								<div className={inputClassName} style={inputStyle}>
									<label
										htmlFor="rxNumber"
										className="text-uppercase cust-black-global text-dark font-10"
									>
										Pharmacy Name*
									</label>
									<div className="d-flex align-items-center border p-2">
										<input
											readOnly={readOnly}
											type="text"
											id="pharmacyName"
											name="pharmacyName"
											value={state.pharmacyName}
											aria-describedby="clinicianNameHelp"
											placeholder="Enter Pharmacy Name"
											className="font-17"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className={inputClassName} style={inputStyle}>
									<PhoneNumberInput
										id="rxNumber"
										label="PHARMACY PHONE*"
										labelClass="text-uppercase cust-black-global text-dark font-10"
										value={state.rxNumber}
										placeholder="Enter Pharmacy Phone"
										handleInput={handleNumberChange}
										readOnly={readOnly}
									/>
								</div>
							</div>
						) : (
							<PharmacyInput
								pharmacies={data.pharmacies}
								dispatch={dispatch}
								states={states}
								cities={cities}
								zipcodes={zipcodes}
								readOnly={readOnly}
							/>
						)
					}
					<div className={inputGroupName}>
						<div className={inputClassName} style={inputStyle}>
							<label
								htmlFor="rxNumber"
								className="text-uppercase cust-black-global text-dark font-10"
							>
								RX Name*
							</label>
							<div className="d-flex align-items-center border p-2">
								<input
									readOnly={readOnly}
									type="text"
									id="rxName"
									name="rxName"
									value={state.rxName}
									aria-describedby="clinicianNameHelp"
									placeholder="Enter RX Name"
									className="font-17"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className={inputClassName} style={inputStyle}>
							<CustomDatePicker
								disabled={readOnly}
								label={
									<span className="form-group">WHEN DO YOU NEED IT?*</span>
								}
								labelClass="text-uppercase cust-black-global text-dark font-10"
								id="requiredOn"
								name="requiredOn"
								selected={state.requiredOn}
								handleDateChange={handleDateChange('requiredOn')}
								minDate={new Date()}
								placeholderText=""
							/>
						</div>
					</div>

					<div className="d-flex col-12 flex-column form-group">
						<label
							htmlFor="notesForDoctor"
							className="font-10 cust-black-global text-dark text-uppercase"
						>
							Notes For Doctor
						</label>
						<textarea
							readOnly={readOnly}
							value={state.notes}
							className="form-control font-17 custom-no-radius"
							id="notes"
							name="notes"
							rows="5"
							placeholder="Notes"
							onChange={handleChange}
						/>
					</div>
					<div className="d-flex col-12 flex-column form-group">
						NOTE: It can take up to 3 business days to get a refill.
					</div>
				</form>
			</div>
			{!readOnly && (
				<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
					<button
						onClick={handleSubmit}
						disabled={isFormIncomplete()}
						type="button"
						className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
					>
						<span className="mr-0 pl-3 w-100">
							{readOnly ? 'OKAY' : 'SUBMIT'}
						</span>
						<i className="fas fa-check align-self-center" />
					</button>
				</div>
			)}
		</div>
	);
};

RXRequest.propTypes = {
	data: PropTypes.object,
};

export default RXRequest;
