import { union } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import EnrollmentAgreement from 'components/EnrollmentAgreement';
import FilterNav from 'components/FilterNav';
import LoadingIndicator from 'components/LoadingIndicator';
import RequestSentModal from 'components/RequestSentModal';
import StatusModal from 'components/RequestSentModal';
import SigningModal from 'components/SigningModal';
import { makeSelectAuth, makeSelectIsMobile } from 'containers/App/selectors';
import BucketCard from 'pages/browsegroups/BucketCard';
import EnrollCart from 'pages/browsegroups/EnrollCart';
import GroupCard from 'pages/browsegroups/GroupCard';
import GroupDateModal from 'pages/browsegroups/GroupDateModal';
import {
	filterByLetter,
	filterData,
	getDayFromIndex,
	getDayIndex,
	getFilterOptions,
	getRepeatDaysFromFrequency,
} from 'utils/helpers';

import {
	clearUrl,
	createRequest,
	createRequestIndividual,
	loadPrograms,
} from './actions';
import groupFilters from './filters';
import './index.css';
import makeSelectBrowseGroups, {
	makeSelectAllPrograms,
	makeSelectResponse,
	makeSelectSigningUrl,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class BrowseGroups extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			receiver: '',
			enrollClicked: false,
			modalData: {},
			bucket: [],
			groups: [],
			enrollmentData: [],
			responseMessage: '',
			total: 0,
			showEnrollmentAgreement: false,
			loading: false,
			loadingGroups: false,
			siteUrl: '',
			signingError: false,
			signingModal: false,
			filters: [],
			dropdownOpen: false,
		};
	}

	addFilter = (filter) => {
		let { filters } = this.state;

		filters = filters.filter((x) => x.name !== filter.name);

		filters.push(filter);
		this.setState({ filters });
	};

	removeFilter = (name) => {
		let { filters } = this.state;
		filters = filters.filter((x) => x.name !== name);
		this.setState({ filters });
	};

	componentDidMount() {
		const { fetchPrograms } = this.props;
		fetchPrograms();
		this.setState({ loadingGroups: true });
	}

	closeRequestSentModal = () => {
		this.setState({
			responseMessage: '',
			signingError: false,
		});
	};

	isSigning = () => {
		return (
			this.state.signingModal ||
			this.state.showEnrollmentAgreement ||
			this.state.loading
		);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.programs.length > 0 && !this.isSigning()) {
			this.setState({
				groups: nextProps.programs,
			});

			if (this.state.loadingGroups) {
				this.setState({ loadingGroups: false });
			}
		}

		if (nextProps.responseMessage) {
			this.setState({
				responseMessage: nextProps.responseMessage,
			});
		}

		if (nextProps.siteUrl) {
			this.setState({
				siteUrl: nextProps.siteUrl,
				signingModal: true,
				loading: false,
				bucket: [],
				total: 0,
			});
		} else if (nextProps.siteUrl === undefined) {
			this.setState({
				loading: false,
				signingError: true,
				signingModal: false,
				siteUrl: '',
				bucket: [],
			});
		}
	}

	signingToggle = () => {
		this.props.clearSiteUrl();
		this.setState((prevState) => ({
			signingModal: !prevState.signingModal,
		}));

		this.props.fetchPrograms();
	};

	createEnrollData = (data) => {
		const { auth } = this.props;
		const { id, name, email } = auth.loggedInUser;

		data.senderUserId = id;
		data.name = name;
		data.email = email;

		const enrollmentData = {
			type: 'Enrollment Request',
			state: 'PENDING_APPROVAL',
			senderUserId: id,
			name,
			email,
			payload: data,
		};
		enrollmentData.reqDate = moment().utc().format('MMM D, YYYY').toString();

		return enrollmentData;
	};

	handleSubmitRequest = (payload) => {
		const { createEnrollmentRequest } = this.props;
		const enrollmentData = this.createEnrollData(payload);

		createEnrollmentRequest(enrollmentData);
		this.setState({
			showEnrollmentAgreement: false,
			loading: true,
		});
	};

	handleSubmitIndividualRequest = (payload) => {
		const { createEnrollmentRequestIndividual } = this.props;
		const enrollmentData = this.createEnrollData(payload);

		createEnrollmentRequestIndividual(enrollmentData);

		this.setState({
			showEnrollmentAgreement: false,
			loading: false,
			bucket: [],
			total: 0,
		});
	};

	handleSubmit = (e) => {
		if (e) e.preventDefault();

		const { bucket } = this.state;
		const enrollmentData = {
			groupsData: [],
		};

		bucket.forEach((bucketData) => {
			const groupName = bucketData['group_name'];
			const startDate = moment(bucketData['upcomingSession']).format(
				'YYYY-MM-DD',
			);
			const programId = bucketData['program_id'];
			const groupSize = bucketData['group_size'];
			enrollmentData.groupsData.push({
				groupName,
				startDate,
				programId,
				groupSize,
			});
		});
		if (enrollmentData.groupsData.length > 0) {
			this.setState({
				showEnrollmentAgreement: true,
				enrollmentData,
			});
		} else {
			// We have handled all groups so we can reset the state and clear it out
			this.setState({
				showEnrollmentAgreement: false,
				loading: false,
				agreementsSigned: 0,
				bucket: [],
			});
		}
	};

	hideEnrollmentAgreementToggle = () => {
		this.setState({
			showEnrollmentAgreement: false,
		});
	};

	addToBucket = (groupId) => {
		let { groups } = this.state;
		let { total } = this.state;
		const { bucket } = this.state;

		groups.forEach((group) => {
			if (group.program_id === groupId) {
				total += group.price ? parseInt(group.price, 10) : 0;
				bucket.push(group);
			}
		});
		groups = groups.filter((group) => group.program_id !== groupId);

		this.setState({
			groups,
			bucket,
			total,
			enrollClicked: false,
		});
	};

	removeFromBucket = (groupId) => {
		let { bucket } = this.state;
		const { groups } = this.state;
		let { total } = this.state;

		bucket.forEach((group) => {
			if (group.program_id === groupId) {
				total -= group.price;
				groups.push(group);
			}
		});
		bucket = bucket.filter((group) => group.program_id !== groupId);

		this.setState({
			groups,
			bucket,
			total,
		});
	};

	showGroupDateModal = (groupId, modalData) => {
		const { groups } = this.state;
		groups.forEach((group) => {
			if (group.program_id === groupId) {
				this.setState({
					modalData,
					enrollClicked: true,
				});
			}
		});
	};

	hideGroupDateModal = () => {
		this.setState({
			enrollClicked: false,
		});
	};

	generateFilters = () => {
		const { programs = [] } = this.props;
		return {
			location: getFilterOptions(programs, 'location'),
			clinician: getFilterOptions(programs, 'clinician_name'),
			groupSize: getFilterOptions(programs, 'group_size'),
			group: getFilterOptions(programs, 'group_type'),
			day: this.getDateFilters(programs),
		};
	};

	filterDropdown = (name, value, programs) => {
		const filteredData =
			name === 'day'
				? this.filterDataByDay(programs, name, value)
				: filterData(programs, name, value);

		return filteredData;
	};

	getDateFilters = (programs) => {
		let filters = [];

		if (programs) {
			programs.forEach((program) => {
				const repeatDays = getRepeatDaysFromFrequency(
					program.repeat_frequency,
					program.repeats_on,
				);
				filters = union(filters, repeatDays.days);
			});
			const dayFilters = filters.map((day) => getDayIndex(day));
			return dayFilters.sort().map((dayIndex) => getDayFromIndex(dayIndex));
		}

		return filters;
	};

	filterDataByDay = (programs, name, value) =>
		programs.filter((program) =>
			getRepeatDaysFromFrequency(
				program.repeat_frequency,
				program.repeats_on,
			).repeatDays.includes(value),
		);

	toggleDropdown = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	render() {
		const {
			receiver,
			enrollClicked,
			modalData,
			showEnrollmentAgreement,
			bucket,
			total,
			signingModal: signingModal,
			siteUrl,
			loading,
			signingError,
			responseMessage,
			enrollmentData,
			filters,
			loadingGroups,
		} = this.state;

		const { auth, isMobile, programs, dispatch } = this.props;

		let groups = programs;

		if (receiver) groups = filterByLetter(groups, 'group_name', receiver);

		filters.forEach((filter) => {
			groups = filter.callback(filter.name, filter.option, groups);
		});

		groups = groups.filter((g) => {
			const inBucket = !bucket.find((b) => {
				return b.program_id === g.program_id;
			});

			return inBucket;
		});

		const successMessage = (
			<div>
				<div className="font-13">
					Your enrollment request was submitted successfully.
				</div>
				<div className="font-13">A clinician will contact you shortly.</div>
			</div>
		);

		return (
			<div>
				{loading && <LoadingIndicator />}
				{signingError && (
					<StatusModal
						successStatus={false}
						hide={this.closeRequestSentModal}
						message="Something went wrong. Please try again."
					/>
				)}
				<div className="mt-1">
					<div className="container-fluid bg-white px-5">
						<div className="row custom-search py-3 border-0">
							<div className="col-1 d-flex justify-content-end ali">
								<i className="fas fa-search custom-search-text font-21" />
							</div>
							<input
								placeholder="Search Therapy Name"
								className="col-11 custom-search-text font-21"
								type="text"
								value={receiver}
								onChange={(e) => {
									this.setState({
										receiver: e.target.value.substr(0, 20), // For Search funtionality
									});
								}}
							/>
						</div>
					</div>
					<FilterNav
						filtersList={groupFilters(this.generateFilters())}
						filterDropdown={this.filterDropdown}
						addFilter={this.addFilter}
						removeFilter={this.removeFilter}
						currentFilters={filters}
						isMobile={isMobile}
						dispatch={dispatch}
						hasTabs
					/>
					<div
						className={`${
							isMobile ? 'row py-2 w-100 mx-auto' : 'px-1 flex-column py-5'
						}`}
					>
						<div
							className={`row w-100 ${
								isMobile && 'mx-0 justify-content-center'
							}`}
						>
							{!isMobile && (
								<div className="custom-card-label-search-my-bucket-parent text-uppercase text-dark col-md-7 col-lg-4 col-xl-3 pl-5">
									List of Therapies to Enroll
								</div>
							)}
						</div>
						<div
							className="d-flex justify-content-between"
							style={{ width: '100%' }}
						>
							<div
								className={`row mx-0 ${
									!isMobile &&
									'col-md-7 col-lg-8 col-xl-9  custom-card-container'
								} py-1 justify-content-${isMobile ? 'center' : 'start'}`}
								style={{ width: '100%' }}
							>
								{loadingGroups ? (
									<SmallLoadingIndicator
										text={'Loading...'}
										color="var(--main-color)"
									/>
								) : groups.length === 0 ? (
									<h4>No Results</h4>
								) : (
									groups.map((groupDetails) => (
										<GroupCard
											cardGroupDetails={groupDetails}
											openDateModal={this.showGroupDateModal}
											isMobile={isMobile}
											key={groupDetails.program_id}
										/>
									))
								)}
							</div>
							{!isMobile && (
								<div className="col-md-5 col-lg-4 col-xl-3 custom-bucket-container">
									<div className="card custom-bcard bg-white">
										<div className="d-flex justify-content-between py-2 px-4">
											<div className="col-8 d-flex justify-content-start font-10 text-cust-grey text-uppercase font-weight-bold py-2 pl-0 pr-2">
												Therapy Name
											</div>
											<div className="col-2 d-flex justify-content-start font-10 text-cust-grey text-uppercase font-weight-bold py-2 px-0">
												Price
											</div>
											<div className="col-2" />
										</div>
										<div className="card-body p-0">
											<div className="border-bottom">
												{bucket.map((element) => (
													<BucketCard
														key={element.id}
														cardGroupDetail={element}
														remove={this.removeFromBucket}
													/>
												))}
											</div>
										</div>
										<div className="d-flex justify-content-between py-2 px-4">
											<div className="col-9 d-flex justify-content-start py-4 pl-0 pr-2 font-13 font-weight-bold text-dark text-uppcase">
												Total
											</div>
											<div className="col-4 d-flex justify-content-start py-4 px-0 font-13 font-weight-bold text-dark text-uppcase">
												${total}
												/m
											</div>
											<div className="col-2" />
										</div>
										<div className="d-flex border">
											<button
												type="button"
												className="btn btn-primary primary-btn-color font-12 w-100 p-4"
												onClick={this.handleSubmit}
												disabled={!bucket.length}
											>
												<span className="mr-4">Proceed to Enroll</span>
												<i className="fas fa-arrow-right" />
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>

					{enrollClicked && (
						<GroupDateModal
							cardGroupDetails={modalData}
							hide={this.hideGroupDateModal}
							addToBucket={this.addToBucket}
						/>
					)}
					{responseMessage && (
						<RequestSentModal
							hide={this.closeRequestSentModal}
							successStatus={true}
							message={successMessage}
						/>
					)}
					{showEnrollmentAgreement && (
						<EnrollmentAgreement
							hide={this.hideEnrollmentAgreementToggle}
							auth={auth}
							data={enrollmentData}
							submitRequest={this.handleSubmitRequest}
							isMobile={isMobile}
						/>
					)}
					<SigningModal
						isOpen={signingModal}
						siteUrl={siteUrl}
						toggle={this.signingToggle}
					/>
				</div>

				{isMobile && !responseMessage && (
					<EnrollCart
						bucket={bucket}
						total={total}
						isMobile={isMobile}
						removeFromBucket={this.removeFromBucket}
						handleSubmit={this.handleSubmit}
					/>
				)}
			</div>
		);
	}
}

BrowseGroups.propTypes = {
	programs: PropTypes.array,
	auth: PropTypes.object,
	fetchPrograms: PropTypes.func,
	createEnrollmentRequest: PropTypes.func,
	browseGroups: PropTypes.any,
	siteUrl: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	browseGroups: makeSelectBrowseGroups(),
	programs: makeSelectAllPrograms(),
	auth: makeSelectAuth(),
	siteUrl: makeSelectSigningUrl(),
	responseMessage: makeSelectResponse(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchPrograms: () => dispatch(loadPrograms()),
		createEnrollmentRequest: (data) => dispatch(createRequest(data)),
		createEnrollmentRequestIndividual: (data) =>
			dispatch(createRequestIndividual(data)),
		clearSiteUrl: () => dispatch(clearUrl()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseGroups);
