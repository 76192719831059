import { useField } from 'formik';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	fetchCities,
	fetchStates,
	fetchZipcodes,
} from 'containers/App/actions';
import Input from 'v2/forms/Input';
import Select from 'v2/forms/Select';

function StreetField({ name, id }) {
	return (
		<Input
			label="Street"
			id={id}
			name={name}
			placeholder="Street Address"
			required
		/>
	);
}

export default function Address({ handleChange, ...rest }) {
	const dispatch = useDispatch();
	const { states, cities, zipcodes } = useSelector((state) =>
		state.get('app').toJS(),
	);

	const [field, , helpers] = useField(rest);
	const { setValue } = helpers;

	const { value } = field;
	const { city, state } = value;

	useEffect(() => {
		dispatch(fetchStates());
	}, []);

	function StateField() {
		function onChange(option) {
			dispatch(fetchCities(option.value));
			setValue({
				...value,
				state: option.value,
				city: null,
				zipcode: null,
			});
			handleChange(option);
		}

		return (
			<Select
				label="State"
				id={`${field.name}.state`}
				name={`${field.name}.state`}
				options={states}
				placeholder="Select State"
				onChange={onChange}
				required
			/>
		);
	}

	function CityField() {
		function onChange(option) {
			dispatch(fetchZipcodes(option.value));
			setValue({
				...value,
				city: option.value,
				zipcode: null,
			});
			handleChange(option);
		}

		return (
			<Select
				label="city"
				id={`${field.name}.city`}
				name={`${field.name}.city`}
				options={cities[state] || []}
				placeholder="Select City"
				onChange={onChange}
				isDisabled={!cities[state]?.length}
				required
			/>
		);
	}

	function ZipcodeField() {
		return (
			<Select
				label="zip code"
				id={`${field.name}.zipcode`}
				name={`${field.name}.zipcode`}
				options={zipcodes[city] || []}
				placeholder="Select Zip Code"
				onChange={handleChange}
				isDisabled={!zipcodes[city]?.length}
				required
			/>
		);
	}

	return (
		<div>
			<StreetField id={`${field.id}.street`} name={`${field.name}.street`} />
			<div className="row">
				<div className="col">
					<StateField />
				</div>
				<div className="col">
					<CityField />
				</div>
				<div className="col">
					<ZipcodeField />
				</div>
			</div>
		</div>
	);
}
