/**
 *
 * AdultSleepHabits
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { STEPS } from 'pages/intake/constants';
import { generateObjectArray } from 'utils/helpers';

/* eslint-disable react/prefer-stateless-function */
const AdultSleepHabits = (props) => {
	const { handleChange, prevProgress, nextProgress, data, isMobile } = props;
	const {
		troubleFallingAsleep,
		troubleFallingAsleepDescribe,
		qualityOfSleepDescribe,
		doYouSnore,
		stopBreathingAsleep,
		wakeMiddleNight,
		fallbackToSleep,
	} = data;

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Sleep Habits
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultSleepHabits)}>
				<div className="card d-flex flex-column p-4 pb-0">
					<div className="row mb-3">
						<label
							htmlFor="troubleFallingAsleep"
							className="form-font col-md-8"
						>
							DO YOU HAVE ANY TROUBLE FALLING ASLEEP ?
						</label>
						<SelectButtonGroup
							id="troubleFallingAsleep"
							value={troubleFallingAsleep}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.AdultSleepHabits)}
							className="col-md-4"
						/>
					</div>

					{troubleFallingAsleep === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES, WHAT PREVENTS YOU FROM FALLING ASLEEP?"
							id="troubleFallingAsleepDescribe"
							value={troubleFallingAsleepDescribe}
							placeholder="Describe"
							handleInput={handleChange(STEPS.AdultSleepHabits)}
						/>
					)}
					<TextArea
						className="mb-4 mt-n2"
						label="HOW IS THE QUALITY OF YOUR SLEEP (e.g. LIGHT, DEEP, etc.) ?"
						id="qualityOfSleepDescribe"
						value={qualityOfSleepDescribe}
						placeholder="Describe"
						handleInput={handleChange(STEPS.AdultSleepHabits)}
					/>

					<div className="row mb-3">
						<label htmlFor="doYouSnore" className="form-font col-md-6">
							DO YOU SNORE ?
						</label>
						<SelectButtonGroup
							id="doYouSnore"
							value={doYouSnore}
							buttons={generateObjectArray(['Yes', 'No', "Don't Know"])}
							handleSelect={handleChange(STEPS.AdultSleepHabits)}
							className="col-md-6"
						/>
					</div>

					<div className="row mb-3">
						<label htmlFor="stopBreathingAsleep" className="form-font col-md-6">
							HAVE YOU BEEN TOLD THAT YOU STOP BREATHING OR GASP FOR BREATH WHEN
							ASLEEP ?
						</label>
						<SelectButtonGroup
							id="stopBreathingAsleep"
							value={stopBreathingAsleep}
							buttons={generateObjectArray(['Yes', 'No', "Don't Know"])}
							handleSelect={handleChange(STEPS.AdultSleepHabits)}
							className="col-md-6"
						/>
					</div>

					<div className="row mb-3">
						<label htmlFor="wakeMiddleNight" className="form-font col-md-6">
							DO YOU WAKE UP IN THE MIDDLE OF THE NIGHT ?
						</label>
						<SelectButtonGroup
							id="wakeMiddleNight"
							value={wakeMiddleNight}
							buttons={generateObjectArray(['Yes', 'No', "Don't Know"])}
							handleSelect={handleChange(STEPS.AdultSleepHabits)}
							className="col-md-6"
						/>
					</div>

					<div className="row mb-3">
						<label htmlFor="fallbackToSleep" className="form-font col-md-6">
							IF SO, HOW OFTEN AND ARE YOU ABLE TO FALL BACK TO SLEEP ?
						</label>
						<SelectButtonGroup
							id="fallbackToSleep"
							value={fallbackToSleep}
							buttons={generateObjectArray(['Usually', 'Rarely', 'Sometimes'])}
							handleSelect={handleChange(STEPS.AdultSleepHabits)}
							className="col-md-6"
						/>
					</div>
				</div>
				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

AdultSleepHabits.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default AdultSleepHabits;
