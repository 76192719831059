import { isNil } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import CustomDatePicker from 'atoms/CustomDatePicker';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';
import { email as emailValidation, required } from 'utils/validations';

/**
 *
 * PatientDetails
 *
 */

const PatientDetails = (props) => {
	const {
		nextStep,
		prevStep,
		handleChange,
		handleDateChange,
		data,
		isMobile,
	} = props;
	const {
		registrationType,
		name,
		preferredName,
		childName,
		maritalStatus,
		dob,
		sex,
		genderIdentity,
		sexualOrientation,
		pronouns,
	} = data;

	const nameSplit = name.split(' ');
	const firstName = nameSplit[0];
	let middleName = '';
	let lastName = '';

	if (nameSplit.length === 2) {
		lastName = nameSplit[1];
	} else if (nameSplit.length > 2) {
		lastName = nameSplit[nameSplit.length - 1];
		middleName = nameSplit.splice(1, nameSplit.length - 2).join(' ');
	}

	const handleNameChange = (event) => {
		let value;
		if (event.target.name === 'firstName') {
			value = `${event.target.value} ${middleName || ''} ${lastName || ''}`;
		} else if (event.target.name === 'middleName') {
			value = `${firstName || ''} ${event.target.value} ${lastName || ''}`;
		} else if (event.target.name === 'lastName') {
			value = `${firstName || ''} ${middleName || ''} ${event.target.value}`;
		}

		value = value.replace(/\s+/g, ' ');

		handleChange('patientDetails')({ target: { name: 'name', value } });
	};

	function isFormComplete() {
		return (
			Boolean(dob) &&
			Boolean(firstName) &&
			Boolean(lastName) &&
			Boolean(maritalStatus)
		);
	}

	return (
		<Form>
			{registrationType !== 'child' && (
				<>
					<div className="form-row">
						<div className="col-md-6 form-group mb-0">
							<TextInput
								label="PATIENT'S FIRST NAME*"
								id="firstName"
								value={firstName}
								placeholder="John"
								handleInput={handleNameChange}
								validations={[required]}
							/>
						</div>
						<div className="col-md-6 form-group mb-0">
							<TextInput
								label="PATIENT'S LAST NAME*"
								id="lastName"
								value={lastName}
								placeholder="Doe"
								handleInput={handleNameChange}
								validations={[required]}
							/>
						</div>
					</div>
					<div className="form-row mb-0">
						<div className="col-md-6 form-group mb-0">
							<TextInput
								label="PATIENT'S MIDDLE NAME"
								id="middleName"
								value={middleName}
								placeholder="Robert"
								handleInput={handleNameChange}
							/>
						</div>
						<div className="col-md-6 form-group mb-0">
							<TextInput
								label="PATIENT'S PREFERRED NAME"
								id="preferredName"
								value={preferredName}
								placeholder="Luke"
								handleInput={handleChange('patientDetails')}
							/>
						</div>
					</div>
				</>
			)}
			{registrationType === 'child' && (
				<div>
					<div className="form-group mb-0">
						<TextInput
							label="GUARDIAN'S FULL NAME*"
							id="name"
							value={name}
							placeholder="John Lucan Doe"
							handleInput={handleChange('patientDetails')}
							validations={[required]}
						/>
					</div>
					<div className="form-group">
						<TextInput
							label="PATIENT'S FULL NAME*"
							id="childName"
							value={childName || ''}
							placeholder="John Lucan Doe"
							handleInput={handleChange('patientDetails')}
							validations={[required]}
						/>
					</div>
				</div>
			)}
			<div className="form-group">
				<label htmlFor="maritalStatus" className="form-font">
					MARITAL STATUS*
				</label>
				<SelectButtonGroup
					id="maritalStatus"
					value={maritalStatus}
					buttons={generateObjectArray([
						'Never Married',
						'Married',
						'Divorced',
						'Separated',
						'Widowed',
						'Partnered',
					])}
					className={`${isMobile && 'row mx-0'}`}
					buttonClass="font-14"
					handleSelect={handleChange('patientDetails')}
				/>
			</div>

			<div className="form-row">
				<CustomDatePicker
					className="col-md-6"
					label={<span className="form-group">DATE OF BIRTH*</span>}
					id="dob"
					selected={dob}
					handleDateChange={handleDateChange('patientDetails', 'dob')}
					maxDate={new Date()}
					placeholderText="Select Date of Birth"
				/>
				<div className="col-md-6 form-group">
					<label htmlFor="sex" className="form-font">
						SEX ASSSIGNED AT BIRTH*
					</label>
					<SelectButtonGroup
						id="sex"
						value={sex}
						buttons={generateObjectArray(['Male', 'Female', 'Other'])}
						handleSelect={handleChange('patientDetails')}
						validations={[required]}
						className={`${isMobile && 'row mx-0'}`}
					/>
				</div>
			</div>
			<div className="form-row mb-0">
				<div className="col-md-6 form-group mb-0">
					<TextInput
						label="GENDER IDENTITY"
						id="genderIdentity"
						value={genderIdentity}
						placeholder=""
						handleInput={handleChange('patientDetails')}
					/>
				</div>
				<div className="col-md-6 form-group mb-0">
					<TextInput
						label="SEXUAL ORIENTATION"
						id="sexualOrientation"
						value={sexualOrientation}
						placeholder=""
						handleInput={handleChange('patientDetails')}
					/>
				</div>
			</div>
			<div className="col-14 form-group">
				<TextInput
					label="PRONOUNS"
					id="pronouns"
					value={pronouns}
					placeholder=""
					handleInput={handleChange('patientDetails')}
				/>
			</div>
			<div className="d-flex mb-3" />
			<div className="d-flex mt-3">
				<div className="col-6 pl-0">
					<button
						type="button"
						className="btn btn-white link-color border btn-lg btn-block font-weight-bold d-flex justify-content-start font-15"
						onClick={prevStep}
					>
						<div className="col-4 d-flex justify-content-start align-self-center">
							<img className="fas fa-arrow-left" alt="arrow-left" />
						</div>
						<div className="col-4 d-flex justify-content-center">
							<span>PREV</span>
						</div>
					</button>
				</div>
				<div className="col-6 p-0 d-flex">
					<button
						type="button"
						className="btn btn-primary btn-color btn-lg btn-block font-weight-bold font-15"
						onClick={nextStep}
						disabled={!isFormComplete()}
					>
						NEXT
					</button>
				</div>
			</div>
		</Form>
	);
};

PatientDetails.propTypes = {
	nextStep: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleDateChange: PropTypes.func.isRequired,
	handleNumberChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default PatientDetails;
