import history from 'utils/history';

/**
 *
 * RxRequests
 *
 */
import _, { capitalize } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import RequestCard from 'cards/RequestCard';
import ChangePharmacyModal from 'components/ChangePharmacyModal';
import FormShare from 'components/FormShare';
import PatientRequestModal from 'components/PatientRequestModal';
import {
	fetchCities,
	fetchStates,
	fetchZipcodes,
	generateToast,
	updateRequestSuccess,
} from 'containers/App/actions';
import { fetchPharmacies } from 'containers/App/actions';
import { headerStyle } from 'containers/App/constants';
import {
	makeSelectAuth,
	makeSelectCities,
	makeSelectPharmacies,
	makeSelectStates,
	makeSelectZipcodes,
} from 'containers/App/selectors';
import { updatePatientRx } from 'containers/Requests/actions';
import { generateButtons } from 'containers/Requests/helpers';
import { filterByLetter } from 'utils/helpers';

import { changePharmacy } from './actions';
import PharmacyCell from './cells/Pharmacy';
import makeSelectRxRequests from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class RxRequests extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			requestModal: false,
			groupObject: {},
			requestType: '',
			data: {},
			assignClinician: false,
			changePharmacy: false,
			pharmacy: {
				name: '',
				phone: '',
				address: {
					street: '',
					state: '',
					city: '',
				},
			},
			client: {},
			clinicianId: null,
		};
	}

	assignClinician = (clinician) => {
		const { assignClinician } = this.props;
		const { requestId, patientId } = this.state;
		const data = {
			patientId,
			clinician,
			requestId,
			requestType: 'RX_REQUESTS',
		};

		assignClinician(data);
		this.setState((prevState) => ({
			assignClinician: !prevState.assignClinician,
		}));
	};

	changePharmacy = (pharmacy) => {
		const { requestId, patientId } = this.state;
		const data = {
			pharmacy: {
				...pharmacy,
				pharmacyCity: pharmacy.pharmacyCity.value,
				pharmacyState: pharmacy.pharmacyState.value,
				pharmacyZipcode: pharmacy.pharmacyZipcode.value,
			},
			requestId,
			patientId,
		};

		this.props.changePharmacy(data, () => {
			const request = this.props.data.find((d) => d.requestId === requestId);
			const { payload } = request;
			const {
				auth: {
					loggedInUser: { roleName },
				},
				dispatch,
			} = this.props;

			if (roleName === 'admin') {
				this.props.updateLocalRequest({
					...request,
					id: request.requestId,
					payload: {
						...payload,
						...pharmacy,
					},
				});
			} else {
				this.props.updatePatientRx({
					...request,
					payload: {
						...payload,
						...data.pharmacy,
					},
				});
			}

			dispatch(
				generateToast({
					type: 'custom-success',
					text: 'Pharmacy Updated Successfully!',
				}),
			);
		});
		this.setState((prevState) => ({
			changePharmacy: !prevState.changePharmacy,
			patientId: null,
			requestId: null,
			pharmacy: null,
		}));
	};

	showRequest = (payload, requestType) => {
		const { groupObject } = payload;

		// eslint-disable-next-line no-param-reassign
		if (requestType === 'Rx') requestType = 'Request for Rx';
		// eslint-disable-next-line no-param-reassign
		const data = _.omit(payload, ['groupObject']);
		this.setState({ requestModal: true, requestType, groupObject, data });
	};

	closeRequestModal = () => {
		this.setState({
			requestModal: false,
		});
	};

	approve = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'APPROVED');
	};

	deny = (requestId, comment) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'DENIED', comment);
	};

	activate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, true);
		this.setState({ updatingRequestId: requestId });
	};

	deactivate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, false);
		this.setState({ updatingRequestId: requestId });
	};

	delete = (requestId) => {
		const { deleteRequest } = this.props;
		deleteRequest(requestId);
		this.setState({ updatingRequestId: requestId });
	};

	showClinicianList = (client, clinicianId, requestId) => {
		this.setState((prevState) => ({
			assignClinician: !prevState.assignClinician,
			requestId,
			client,
			clinicianId,
		}));
	};

	showPharmacyModal = (data) => {
		if (this.state.changePharmacy) {
			this.setState((prevState) => ({
				changePharmacy: !prevState.changePharmacy,
				patientId: null,
				pharmacy: null,
				requestId: null,
			}));

			return;
		}

		const { patientId, requestorId, payload, requestId } = data;

		this.props.fetchStates();
		this.props.fetchPharmacies(patientId || requestorId);

		const {
			pharmacyAddress,
			pharmacyCity,
			pharmacyId,
			pharmacyName,
			pharmacyState,
			pharmacyZipcode,
			rxNumber,
		} = payload;

		this.setState((prevState) => ({
			changePharmacy: !prevState.changePharmacy,
			patientId: patientId || requestorId,
			requestId,
			pharmacy: {
				street: pharmacyAddress,
				id: pharmacyId,
				name: pharmacyName,
				state: { value: pharmacyState, label: pharmacyState },
				city: { value: pharmacyCity, label: pharmacyCity },
				zipcode: { value: pharmacyZipcode, label: pharmacyZipcode },
				phone: rxNumber,
			},
		}));
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	// eslint-disable-next-line consistent-return
	getColumns = () => {
		const {
			auth: {
				loggedInUser: { roleName },
			},
		} = this.props;

		const columns = {
			client: {
				field: 'requestorName',
				title: 'CLIENT',
				headerStyle,
				render: (row) => (
					<div
						className="hand"
						onClick={() => this.goToProfile(row.requestorId)}
					>
						<ImageDetailsCell
							src={row.profilePicture}
							heading={row.requestorName}
							primarySubHeading={capitalize(row.patientType)}
						/>
					</div>
				),
			},
			pharmacy: {
				field: 'pharmacyName',
				title: 'PHARMACY',
				headerStyle,
				render: (row) => (
					<PharmacyCell data={row} changePharmacy={this.showPharmacyModal} />
				),
			},
			status: {
				field: 'requestState',
				title: 'STATUS',
				headerStyle,
				render: (row) => <div className="mt-2 font-13">{row.requestState}</div>,
			},
			clinician: {
				field: 'payload',
				title: 'CLINICIAN',
				headerStyle,
				render: (row) => {
					const { payload } = row;
					const clinician = payload.clinician || payload.assignedClinician;

					return clinician ? (
						<div>
							<ImageDetailsCell
								src={clinician.profilePicture}
								heading={clinician.name}
								primarySubHeading={clinician.therapistType}
							/>
							{roleName === 'admin' && (
								<div
									className="link-color font-11"
									style={{ cursor: 'pointer' }}
									onClick={() =>
										this.showClinicianList(
											{ name: row.requestorId, id: row.requestorId },
											clinician.id,
											row.requestId,
										)
									}
								>
									Change
								</div>
							)}
						</div>
					) : roleName === 'admin' ? (
						<div
							onClick={() =>
								this.showClinicianList(
									{ name: row.requestorId, id: row.requestorId },
									row.mainClin,
									row.requestId,
								)
							}
							className="link-color mt-2 mx-2 font-13 hand"
						>
							Assign Clinician
						</div>
					) : (
						<div>-</div>
					);
				},
			},
			request: {
				field: 'rx',
				title: 'REQUEST',
				headerStyle,
				render: (row) => (
					<div
						className="link-color mt-2  font-13 hand"
						onClick={() => this.showRequest(row.payload, row.requestType)}
					>
						View Request
					</div>
				),
			},
			createdAt: {
				field: 'createdAt',
				title: 'SUBMITTED ON',
				headerStyle,
				render: (row) => (
					<div className="mt-2 mx-2 font-13">
						{moment(row.createdAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
			actions: {
				field: 'requestState',
				title: 'ACTIONS',
				headerStyle,
				headerClasses: 'table-header font-10 text-uppercase',
				render: (row) => (
					<div className="d-flex">
						{generateButtons(row, this).map((button) => button)}
					</div>
				),
				sort: false,
			},
		};
		const admin = [
			'client',
			'pharmacy',
			'status',
			'clinician',
			'request',
			'createdAt',
			'actions',
		];
		const patient = ['pharmacy', 'status', 'clinician', 'request', 'createdAt'];

		let columnsToUse = patient;
		if (roleName === 'admin' || roleName === 'clinician') {
			columnsToUse = admin;
		}

		return columnsToUse.map((c) => columns[c]);
	};

	render() {
		const {
			data,
			clinicians,
			states,
			cities,
			zipcodes,
			auth: {
				loggedInUser: { roleName },
			},
			loading,
			isMobile,
			userSearch = '',
		} = this.props;
		const { assignClinician, changePharmacy, client, clinicianId } = this.state;
		const rxColumns = this.getColumns();

		let filteredData = [];

		if (data) {
			filteredData = filterByLetter(data, 'requestorName', userSearch);
		}

		const rowStyle = (rowData) => {
			if (!rowData.isActive) {
				return {
					backgroundColor: 'rgb(255 231 231)',
				};
			}
		};

		return (
			<div>
				{isMobile ? (
					<div className="row mx-1">
						{loading ? (
							<SmallLoadingIndicator
								text={'Loading...'}
								color="var(--main-color)"
							/>
						) : (
							filteredData.map((r) => {
								const clinician = {};

								if (r.payload.clinician) {
									clinician.clinicianName = r.payload.clinician.name;
									clinician.therapistType = r.payload.clinician.therapistType;
								}

								let heading;
								if (roleName === 'patient') {
									heading = (
										<div>
											<div>Rx Request</div>
											{r.payload.pharmacyName}
										</div>
									);
								} else {
									heading = (
										<div>
											<div>Rx Request</div>
											<div>{r.payload.pharmacyName}</div>
										</div>
									);
								}

								return (
									<RequestCard
										heading={heading}
										request={r}
										clinician={clinician}
										requestor={{
											heading: r.requestorName,
											subheading: r.requestorState,
											id: r.requestorId,
										}}
										onClick={() => this.showRequest(r.payload, r.requestType)}
										key={r.requestId}
									/>
								);
							})
						)}
					</div>
				) : (
					<Table
						keyField="newIntakes"
						data={filteredData}
						columns={rxColumns}
						classes={roleName === 'clinician' ? 'table-responsive-lg' : null}
						placeholder="No perscription requests"
						loading={loading}
						rowStyle={rowStyle}
					/>
				)}
				{this.state.requestModal && (
					<PatientRequestModal
						hide={this.closeRequestModal}
						groupObject={this.state.groupObject}
						requestType={this.state.requestType}
						data={this.state.data}
						isMobile={isMobile}
					/>
				)}
				{assignClinician && (
					<FormShare
						clinicians={clinicians}
						type="assignClinician"
						title={`Assign ${client.name} to...`}
						hide={this.showClinicianList}
						assignClinician={this.assignClinician}
						currentClinicianId={clinicianId}
					/>
				)}
				{changePharmacy && (
					<ChangePharmacyModal
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						pharmacy={this.state.pharmacy}
						pharmacies={this.props.pharmacies.map((p) => ({
							...p,
							city: { value: p.address?.city, label: p.address?.city },
							state: { value: p.address?.state, label: p.address?.state },
							zipcode: { value: p.address?.zipcode, label: p.address?.zipcode },
							street: p.address?.street,
						}))}
						changePharmacy={this.changePharmacy}
						modalOpen={this.state.changePharmacy}
						showPharmacyModal={this.showPharmacyModal}
						fetchCities={this.props.fetchCities}
						fetchZipcodes={this.props.fetchZipcodes}
					/>
				)}
			</div>
		);
	}
}

RxRequests.propTypes = {
	data: PropTypes.array,
	clinicians: PropTypes.array,
	assignClinician: PropTypes.func,
	changePharmacy: PropTypes.func,
	states: PropTypes.array,
	// dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	rxRequests: makeSelectRxRequests(),
	cities: makeSelectCities(),
	zipcodes: makeSelectZipcodes(),
	states: makeSelectStates(),
	pharmacies: makeSelectPharmacies(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		fetchCities: (stateCode, stateName) =>
			dispatch(fetchCities(stateCode, stateName)),
		fetchZipcodes: (cityName) => dispatch(fetchZipcodes(cityName)),
		fetchStates: () => dispatch(fetchStates()),
		fetchPharmacies: (id) => dispatch(fetchPharmacies(id)),
		changePharmacy: (data, cb) => dispatch(changePharmacy(data, cb)),
		updateLocalRequest: (data) => dispatch(updateRequestSuccess(data)),
		updatePatientRx: (data) => dispatch(updatePatientRx(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RxRequests);
