import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';

import Redirect from 'components/Redirect';
import { makeSelectAuth, makeSelectIsMobile } from 'containers/App/selectors';
import Dashboard from 'containers/Dashboard';
import FeedbackForm from 'containers/FeedbackForm';
import { makeSelectAllPrograms } from 'containers/Therapies/selectors';
import { isProduction } from 'utils/constants';

import makeSelectAdmin from './selectors';

/* eslint-disable react/prefer-stateless-function */
const Admin = (props) => {
	const {
		isMobile,
		programs,
		auth: { viewMode },
	} = props;
	const [isFormOpen, setIsFormOpen] = useState(false);

	const toggleForm = () => {
		setIsFormOpen(!isFormOpen);
	};

	return (
		<div>
			<Switch>
				<Route path="/admin/dashboard" component={Dashboard} />
				<Route
					exact
					path="/admin"
					component={() => <Redirect redirectPath="/admin/dashboard" />}
				/>
				<Route
					exact
					path="/"
					component={() => <Redirect redirectPath="/admin/dashboard" />}
				/>
				<Route component={() => <Redirect redirectPath="/admin/dashboard" />} />
			</Switch>
			{!isMobile && (viewMode !== 'patient' || !isProduction) && (
				<div
					style={{ position: 'fixed', bottom: '0', right: '0', zIndex: 1000 }}
				>
					<button
						className={`btn btn-primary btn-color font-15 font-weight-bold justify-content-end ${
							isMobile && 'mobile-button'
						}`}
						onClick={toggleForm}
						style={{ position: 'absolute', bottom: 0, right: 12 }}
					>
						Feedback
					</button>
					<FeedbackForm
						programs={programs}
						isMobile={isMobile}
						isOpen={isFormOpen}
						toggle={toggleForm}
					/>
				</div>
			)}
		</div>
	);
};

Admin.propTypes = {};

const mapStateToProps = createStructuredSelector({
	admin: makeSelectAdmin(),
	isMobile: makeSelectIsMobile(),
	programs: makeSelectAllPrograms(),
	auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
