import PropTypes from 'prop-types';

import React from 'react';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { BULLET } from 'constants/main';
import { capitalizeWords } from 'utils/helpers';

import './BucketCard.css';

/* eslint-disable react/prefer-stateless-function */
class BucketCard extends React.Component {
	render() {
		const { cardGroupDetail, remove, isMobile } = this.props;

		return (
			<div className={'card custom-bcard-dimensions'}>
				<div className={`card-body ${isMobile && 'pb-0'}`}>
					<div
						className={`d-flex font-13 ${
							isMobile && 'justify-content-between'
						}`}
					>
						<div className="col-8 d-flex justify-content-start pl-0 pr-2">
							<div
								className={
									cardGroupDetail.group_type.length > 15
										? 'custom-bcard-heading custom-bcard-ellipsis text-dark font-weight-bold'
										: 'custom-bcard-heading text-dark font-weight-bold'
								}
							>
								{`${cardGroupDetail.group_type} ${BULLET} ${capitalizeWords(
									cardGroupDetail.location,
								)}`}
							</div>
						</div>
						{!isMobile && (
							<div className="col-2 text-left custom-bcard-heading text-dark px-0">
								${cardGroupDetail.price}
							</div>
						)}
						<div
							className="col-2 text-right align-middle hand"
							onClick={() => remove(cardGroupDetail.program_id)}
						>
							<i className="fas fa-times" />
						</div>
					</div>
					{isMobile && (
						<div className="row mx-0 font-13 text-left custom-bcard-heading text-dark px-0">
							Price: ${cardGroupDetail.price}
						</div>
					)}
					<div className="d-flex align-items-center py-3 px-0">
						<ImageDetailsCell
							src={cardGroupDetail.profilePicture}
							heading={cardGroupDetail.clinician_name}
							primarySubHeading={cardGroupDetail.therapist_type}
						/>
					</div>
				</div>
				{isMobile && <hr />}
			</div>
		);
	}
}

BucketCard.propTypes = {
	cardGroupDetail: PropTypes.object.isRequired,
	remove: PropTypes.func.isRequired,
};

export default BucketCard;
