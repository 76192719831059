/*
 *
 * UserDropDown actions
 *
 */
import {
	DEFAULT_ACTION,
	FETCH_FEEDBACK,
	FETCH_FEEDBACK_ERROR,
	FETCH_FEEDBACK_SUCCESS,
	UPDATE_FEEDBACK,
	UPDATE_FEEDBACK_ERROR,
	UPDATE_FEEDBACK_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function fetchFeedback(id) {
	return {
		type: FETCH_FEEDBACK,
		id,
	};
}
export function fetchFeedbackSuccess(feedback) {
	return {
		type: FETCH_FEEDBACK_SUCCESS,
		feedback,
	};
}
export function fetchFeedbackError(error) {
	return {
		type: FETCH_FEEDBACK_ERROR,
		error,
	};
}

export function updateFeedback(id) {
	return {
		type: UPDATE_FEEDBACK,
		id,
	};
}
export function updateFeedbackSuccess(id) {
	return {
		type: UPDATE_FEEDBACK_SUCCESS,
		id,
	};
}
export function updateFeedbackError(error) {
	return {
		type: UPDATE_FEEDBACK_ERROR,
		error,
	};
}
