/**
 *
 * PrivacyPracticesIntake
 *
 */
import PropTypes from 'prop-types';

import React from 'react';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';
import { isProduction } from 'utils/constants';

/* eslint-disable react/prefer-stateless-function */
class PrivacyPracticesIntake extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
		};
	}

	componentDidMount() {
		const { auth } = this.props;
		this.setState({ name: auth.loggedInUser.name });
	}

	nextProgress = (event) => {
		const {
			nextProgress,
			privacyPracticesChecked,
			privacyNoticeNotSignedDescribe,
		} = this.props;
		const data = {
			checks: {
				privacyPracticesChecked,
				privacyNoticeNotSignedDescribe,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	render() {
		const {
			privacyPracticesChecked,
			privacyNoticeNotSignedDescribe,
			prevProgress,
			handleAcceptance,
			handleChange,
			isMobile,
		} = this.props;

		const formComplete =
			!isProduction ||
			privacyPracticesChecked ||
			privacyNoticeNotSignedDescribe !== '';

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center text-center font-weight-bold mb-5 mt-5">
					Notice of Privacy Practices
				</h2>
				<div className="card d-flex flex-column p-4 bb-0">
					<span className="pt-5 pb-4">
						<strong>
							THIS NOTICE DESCRIBES HOW MEDICAL AND SUBSTANCE USE RELATED
							INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU MAY
							ACCESS THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.
						</strong>
					</span>

					<h3>About this notice</h3>
					<span className="pt-1 pb-4">
						Mind Therapy Clinic is required by law to maintain the privacy of
						your health information, to provide you with a notice of its legal
						duties and privacy practices, and to follow the information
						practices that are described in this notice. This notice explains
						how your health information may be used and disclosed and your
						rights related to your health information. You have a right to
						request and receive a paper copy of this notice.
					</span>
					<span className="pt-1 pb-4">
						Information regarding your health care, including payment of your
						health care, is protected by the Health Insurance Portability and
						Accountability Act of 1996 (“HIPAA”), 42 U.S.C. § 1320s et seq., and
						45 C.F.R. Parts 160 & 164. If you receive substance use treatment
						from Mind Therapy Clinic, your information related to these
						services, including whether or not you receive these services, is
						also protected under the Confidentiality Law, 42 C.F.R. Part 2
						(“Part 2”). Mind Therapy Clinic may not use or disclose your health
						information except as described in this notice and as permitted
						under the law.
					</span>
					<span className="pt-1 pb-4">
						This notice applies to health information about you that is obtained
						by or on behalf of Mind Therapy Clinic. Health information is
						information that relates to your past, present, or future physical
						or mental health or condition, the provision of health care products
						and services to you or payment for such services. We will notify you
						of any breach involving your health information in accordance with
						applicable law.
					</span>
					<h3>How we may disclose your health information</h3>
					<span className="pt-1 pb-4">
						As permitted by law, your treatment provider is permitted to and may
						choose to use or disclose protected health information (information
						regarding your treatment at the Mind Therapy Clinic or other
						relevant information that may identify you) (“PHI”) without your
						authorization for the purposes described in this notice.
					</span>
					<span className="pt-1 pb-4">
						When it is important to do so, we will only use or disclose the
						minimum necessary PHI to the extent a recipient needs to know the
						information. By law, mental health professionals shall safeguard the
						confidential information obtained in the course of practice,
						research, teaching or any other professional duties. Except for the
						purposes set forth below, the mental health professional may only
						disclose your PHI to others with your written consent.
					</span>
					<span className="pt-1 pb-4">
						Please note that the ethical standards of mental health
						professionals are, in many cases, more stringent than federal and
						state regulations and restrict us from unnecessarily disseminating
						information about you. We will only do so as necessary, and will use
						caution with any information pertaining to you or your health
						status.
					</span>
					<h3>
						Examples of how we may use and disclose PHI (Except any information
						related to substance use treatment you receive at Mind Therapy
						Clinic)
					</h3>
					<span className="pt-1 pb-4">
						The following section describes how Mind Therapy Clinic may use and
						disclose your PHI, other than information relating to any substance
						use treatment you may receive at Mind Therapy Clinic (for how such
						information may be used and disclosed, please see “Examples of How
						We May Use and Disclose Your Health Information that Relates to
						Substance Use Treatment Received at Mind Therapy Clinic”, below).
					</span>
					<span className="pt-1 pb-1">
						<strong>
							Examples of disclosure for treatment, payment, and health care
							operations
						</strong>
					</span>
					<span className="pt-1 pb-4">
						<strong>• Treatment </strong>. We may use PHI about you to provide
						you with medical treatment or services. We may disclose PHI about
						you to doctors, nurses, technicians, office staff, or other
						personnel who are involved in your care or health care decisions. We
						may contact you to provide appointment reminders or information
						about treatment alternatives or other health-related benefits and
						services that may be of interest to you.
					</span>
					<span className="pt-1 pb-4">
						<strong>• Payment </strong>. We may use and disclose PHI about you
						so that the treatment and services we provide to you may be billed
						to and payment may be collected from you, an insurance company or a
						third party.
					</span>
					<span className="pt-1 pb-4">
						<strong>• Health care operations </strong>. We may use and disclose
						PHI about you in order to run Mind Therapy Clinic and make sure that
						you and our other clients receive quality care. For example, we may
						use your information to conduct cost-management and patient-care
						planning activities for Mind Therapy Clinic.
					</span>
					<h3>Examples of other uses and disclosures</h3>
					<span className="pt-1 pb-4">
						We also may use and disclose your PHI without your prior
						authorization for the following purposes:
					</span>
					<span className="pt-1 pb-4">
						• To Business Associates of ours, with whom we contract for
						services. Examples of Business Associates include consultants,
						accountants, lawyers, and third-party billing companies. We require
						these Business Associates to agree to protect the confidentiality of
						your PHI. <br /> • To health oversight agencies or authorities for
						health oversight activities, such as auditing and licensing. <br />•
						For public health purposes, including reports to public health
						agencies or legal authorities charged with preventing or controlling
						disease, injury, or disability and reports to employers for
						work-related illness or injuries for workplace safety purposes.
						<br /> • To law enforcement authorities for law enforcement purposes
						as required or permitted by law for example, in response to a
						subpoena or court order, in response to a request from law
						enforcement, and to report limited information in certain
						circumstances. <br /> • As
						Required by Law. We will disclose your PHI when required to do so by
						federal, state or local law. <br /> • To make reports on abuse,
						neglect, or domestic violence to a government authority if we
						reasonably believe you are a victim of abuse or neglect. We will
						only disclose this type of information to the extent required by
						law, if you agree to the disclosure, or if the disclosure is allowed
						by law and we believe it is necessary to prevent serious harm to you
						or someone else. <br /> • To avert a serious threat to public health
						or safety, or to prevent serious harm to an individual. <br /> • To
						coroners and medical examiners and funeral directors, as necessary,
						to carry out their duties.
						<br /> • For specialized government functions; for
						example, as required by military authorities or to federal officials
						for intelligence, counterintelligence, protection of the President,
						and other national security activities authorized by law. <br /> •
						To correctional Institutions if you are or become an inmate of a
						correctional institution, for your health and the health and safety
						of other individuals. <br /> • For workers compensation purposes as
						necessary to comply with worker&apos;s compensation or other similar
						programs established by law. <br /> • Incidental Disclosures. While
						we will take reasonable steps to safeguard the privacy of your
						health information, certain disclosures of your health information
						may occur during, or as an unavoidable result of our otherwise
						permissible uses or disclosures of your health information.
					</span>
					<span className="pt-1 pb-4">
						In other instances, we may use or disclose PHI without written
						authorization but provide you with an opportunity to agree or object
						to the use or disclosure. This will occur when a use or disclosure
						to a family member, relative or close personal friend, or any other
						individual you identify, is important to provide these individuals
						with information regarding your health care, payment, location,
						general condition or death, or to assist in disaster relief efforts.
						In emergency circumstances, we may not be able to obtain your
						agreement or objection; in these cases we will use our professional
						judgment to act in your best interests.
					</span>
					<h3>
						Examples of how we may use and disclose your health information that
						relates to substance use treatment received at Mind Therapy Clinic
					</h3>
					<span className="pt-1 pb-4">
						The following section describes how Mind Therapy Clinic may use and
						disclose your health information that relates to substance use
						treatment received at our clinic. Under federal regulations referred
						to as Part 2, more stringent confidentiality and privacy protections
						apply to your health information that identifies you as a substance
						use treatment patient (“Part 2 Information”). We may not disclose to
						a person outside of Mind Therapy Clinic that you received substance
						use treatment at our clinic, nor may Mind Therapy Clinic disclose
						any Part 2 Information, except as permitted by federal law.
					</span>
					<span className="pt-1 pb-4">
						Mind Therapy Clinic must obtain your written consent before it may
						disclose Part 2 Information for payment purposes. Generally, you
						must also sign a written consent before Mind Therapy Clinic can
						share Part 2 Information for treatment or for health care
						operations.
					</span>
					<span className="pt-1 pb-1">
						<strong>Examples of Disclosures Of Part 2 Information</strong>
					</span>
					<span className="pt-1 pb-4">
						Under federal law, we may use and disclose Part 2 Information
						without your prior consent for the following purposes: <br /> •
						Pursuant to an agreement with a qualified service
						organization/business associate, with whom we contract for services;
						<br /> • For research, audit, or evaluations; <br /> • To report a
						crime committed on Mind Therapy Clinic’s premises or against Mind
						Therapy Clinic’s personnel; <br /> • To medical personnel in a
						medical emergency; <br /> • To appropriate authorities to report
						suspected child abuse or neglect; and <br /> • As allowed by court
						order.
					</span>
					<h3>Other uses and disclosures</h3>
					<span className="pt-1 pb-4">
						Your written authorization is required to disclose psychotherapy
						notes, except in cases in which we must use such information to
						defend ourselves in legal action/proceedings involving you. Before
						Mind Therapy Clinic may use or disclose any information about your
						health in a manner not described in this Notice, it must first
						obtain your specific written consent allowing it to make the
						disclosure. Any such written consent may be revoked by you orally or
						in writing. Upon receipt of the written revocation, we will stop
						using or disclosing your health information, except to the extent
						that we have already taken action in reliance on the authorization.
					</span>
					<h3>Your rights</h3>
					<span className="pt-1 pb-4">
						You have individual rights over the use and disclosure of your PHI,
						including the rights listed below. You may exercise any of these
						rights by submitting your request in writing. Please contact the
						Office Manager to obtain the applicable request form. We will
						evaluate each request and communicate to you whether or not we can
						honor the request. We may also charge a reasonable fee for costs
						associated with your request to the extent permitted by law. We will
						notify in advance of the cost, and you may withdraw your request
						before you incur any cost.
					</span>
					<h5>
						<strong>Restrict use</strong>
					</h5>
					<span className="pt-1 pb-4">
						You may request, in writing, restrictions on certain uses and
						disclosures of your information. We will consider but are not
						legally required to accept most requests. After review of your
						request, we will notify you of our determination in writing. We must
						accept your request only if the restricted disclosure is to a health
						plan for the purpose of carrying out payment or health care
						operations, disclosure of such information is not required by law,
						and the restricted information pertains to an item or service for
						which you paid in full out-of-pocket. Upon request, you may receive
						confidential communications by alternative means or at alternative
						locations. This includes an alternative mailing address or telephone
						number.
					</span>
					<h5>
						<strong>Inspect and copy</strong>
					</h5>
					<span className="pt-1 pb-4">
						With a few exceptions, you have the right to access and obtain a
						copy of the health information that we maintain about you. If we
						maintain an electronic health record containing your health
						information, you have the right to obtain the health information in
						an electronic format. You may ask us to send a copy of your health
						information to other individuals or entities that you designate. We
						may deny your request to inspect and copy in certain circumstances.
						If you are denied access to your health information, you may request
						that the denial be reviewed in some cases.
					</span>
					<h5>
						<strong>Request corrections</strong>
					</h5>
					<span className="pt-1 pb-4">
						You have the right to request in writing that we correct information
						in your record that you believe is incorrect or add information that
						you believe is missing.
					</span>
					<h5>
						<strong>Know about disclosures</strong>
					</h5>
					<span className="pt-1 pb-4">
						You have the right to an accounting of instances where we have
						disclosed your health information for certain purposes other than
						for treatment, payment, health care operations, or other exceptions.
						Your request must be made in writing and may be for disclosures made
						up to 6 years before the date of your request.
					</span>
					<h5>
						<strong>File complaints</strong>
					</h5>
					<span className="pt-1 pb-4">
						If you are concerned that we have violated your privacy or disagree
						with a decision we made about access to your record, you may file a
						complaint about the clinic and submit it to our HIPAA Officer.
					</span>
					<span className="pt-1 pb-4">
						You also may file a written complaint to the Secretary of the U.S.
						Department of Health and Human Services.
					</span>
					<span className="pt-1 pb-4">
						You will not be penalized nor retaliated against if you file a
						complaint.
					</span>
					<h3>Changes to this Notice</h3>
					<span className="pt-1 pb-4">
						This notice is effective as of June 1, 2019 . We may change the
						terms of our notice at any time. The new notice will be effective
						for the health information that we maintain. The revised notice will
						be posted at our places of service. You may request a copy of the
						current notice at any time by contacting our HIPAA Officer.
					</span>
				</div>
				<hr className="m-0" />
				<div className="card d-flex flex-column p-4 mb-4 bt-0">
					<h3>Acknowledgement</h3>
					<div className="form-group mt-4">
						<span className="d-inline align-self-center mr-2">I/We</span>
						<input
							value={this.state.name}
							type="text"
							name="name"
							onChange={this.handleChange}
							className="form-control text-muted accept-input d-inline align-self-center"
							placeholder="Your Name"
						/>
						<span className="d-inline align-self-center ml-2">
							, acknowledge receiving and reading a copy of the above
							information, and have had the opportunity to ask whatever
							questions necessary for clarification.{' '}
						</span>
						<IAccept
							handleAcceptance={handleAcceptance}
							name="privacyPracticesChecked"
							value={privacyPracticesChecked}
						/>
					</div>
					{!privacyPracticesChecked && (
						<div className="d-flex flex-column mt-4">
							<span>
								If you do not accept the above policies, please provide a reason
								below
							</span>
							<textarea
								type="text"
								name="privacyNoticeNotSignedDescribe"
								id="privacyNoticeNotSignedDescribe"
								className="form-control text-muted mt-2"
								placeholder="Describe"
								onChange={handleChange}
								value={privacyNoticeNotSignedDescribe}
							/>
						</div>
					)}
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!formComplete}
							onClick={this.nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		);
	}
}

PrivacyPracticesIntake.propTypes = {
	privacyPracticesChecked: PropTypes.bool.isRequired,
	privacyNoticeNotSignedDescribe: PropTypes.string.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default PrivacyPracticesIntake;
