import history from 'utils/history';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import ActionButton from 'atoms/ActionButton';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import RequestCard from 'cards/RequestCard';
import CommentPopover from 'components/CommentPopover';
import EnrollmentAgreement from 'components/EnrollmentAgreement';
import LoadingIndicator from 'components/LoadingIndicator';
import StatusModal from 'components/RequestSentModal';
import SigningModal from 'components/SigningModal';
import { BULLET } from 'constants/main';
import { headerStyle } from 'containers/App/constants';
import { makeSelectIsMobile } from 'containers/App/selectors';
import { generateButtons } from 'containers/Requests/helpers';
import signImage from 'svgs/sign';

import { clearUrl, signDocument, submitEnrollmentRequest } from './actions';
import makeSelectEnrollmentRequests, {
	makeSelectIsUpdating,
	makeSelectSigningUrl,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class EnrollmentRequests extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showEnrollmentAgreement: false,
			requestId: '',
			groupName: '',
			startDate: '',
			siteUrl: '',
			modal: false,
			programId: '',
			signingError: false,
			showAddCommentPopover: false,
			loadingSigning: false,
			commentObject: {},
			updatingRequestId: null,
			showInactiveRequests: {
				enroll: false,
				member: false,
			},
		};
	}

	togglePopover = (row) => {
		let commentObject = {};
		if (row.requestState === 'DENIED') {
			commentObject = {
				id: `enrollment${row.requestId}`,
				reasonForDenial: row.reasonForDenial,
			};
		}
		this.setState((prevState) => ({
			showAddCommentPopover: !prevState.showAddCommentPopover,
			commentObject,
		}));
	};

	getPopoverStatus = () => this.state.showAddCommentPopover;

	onHover = (status) => {
		this.setState({
			showAddCommentPopover: status === 'enter' ? 'true' : false,
		});
	};

	docusignToggle = () => {
		this.props.clearSiteUrl();
		this.setState((prevState) => ({
			modal: !prevState.modal,
		}));
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	closeRequestSentModal = () => {
		this.setState({
			signingError: false,
		});
	};

	handleSubmitRequest = (data) => {
		const { dispatch } = this.props;
		dispatch(submitEnrollmentRequest(data));
		this.setState((prevState) => ({
			showEnrollmentAgreement: !prevState.showEnrollmentAgreement,
			loading: !prevState.loading,
		}));
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.siteUrl) {
			this.setState({
				siteUrl: nextProps.siteUrl,
				modal: true,
				loadingDocusign: false,
			});
		} else if (nextProps.siteUrl === undefined) {
			this.setState({
				loadingDocusign: false,
				signingError: true,
				modal: false,
			});
		}

		if (this.state.updatingRequestId && !nextProps.isUpdating) {
			this.setState({ updatingRequestId: null });
		}
	}

	showEnrollmentAgreementToggle = (data) => {
		this.setState((prevState) => ({
			showEnrollmentAgreement: !prevState.showEnrollmentAgreement,
			requestId: data.requestId,
			groupName: data.groupName,
			startDate: moment(data.startDate).format('YYYY-MM-DD'),
			programId: data.programId,
		}));
	};

	signDocument = (data) => {
		this.props.signDocument(data.requestId);
		this.setState(() => ({
			requestId: data.requestId,
			groupName: data.groupName,
			startDate: moment(data.startDate).format('YYYY-MM-DD'),
			programId: data.programId,
		}));
	};

	hideEnrollmentAgreementTogglle = () => {
		this.setState((prevState) => ({
			showEnrollmentAgreement: !prevState.showEnrollmentAgreement,
		}));
	};

	approve = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'APPROVED');
		this.setState({ updatingRequestId: requestId });
	};

	deny = (requestId, comment) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'DENIED', comment);
		this.setState({ updatingRequestId: requestId });
	};

	activate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, true);
		this.setState({ updatingRequestId: requestId });
	};

	deactivate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, false);
		this.setState({ updatingRequestId: requestId });
	};

	delete = (requestId) => {
		const { deleteRequest } = this.props;
		deleteRequest(requestId);
		this.setState({ updatingRequestId: requestId });
	};

	sendAgreement = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'ENROLLMENT_AGREEMENT_SENT');
	};

	// eslint-disable-next-line consistent-return
	getColumns = () => {
		const { downloadFile, role, viewPdf } = this.props;
		const admin = [
			'client',
			'group',
			'clinician',
			'intake',
			'agreement',
			'createdAt',
			'status',
			'actions',
		];
		const patient = ['group', 'clinician', 'agreement', 'createdAt', 'status'];
		const columns = {
			group: {
				field: 'groupName',
				title: 'THERAPY',
				headerStyle,
				headerClasses: 'table-header font-10',
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.groupName}
					</div>
				),
			},
			clinician: {
				field: 'clinicianName',
				title: 'CLINICIAN',
				headerStyle,
				headerClasses: 'table-header font-10',
				render: (row) => (
					<ImageDetailsCell
						src={row.profilePicture}
						heading={row.clinicianName}
						primarySubHeading={row.therapistType}
						className="mt-2"
					/>
				),
			},
			agreement: {
				field: 'requestorDocs',
				title: 'AGREEMENT',
				headerStyle,
				render: (row) =>
					row.requestState === 'AWAITING SIGNATURE' ||
					row.requestState === 'ENROLLMENT_AGREEMENT_SENT' ||
					row.requestState === 'OPEN' ||
					row.requestState === 'PENDING_APPROVAL' ? (
						<div className="justify-content-center mt-2">-</div>
					) : !row.uuid || row.uuid.length < 1 ? (
						<div className="d-flex text-muted justify-content-start align-items-center mt-2 font-12">
							DOCUMENT UNAVAILABLE
						</div>
					) : (
						<div
							className="d-flex hand  link-color justify-content-start align-items-center mt-2 font-13"
							onClick={() => viewPdf(row)}
						>
							VIEW AGREEMENT
						</div>
					),
			},
			createdAt: {
				field: 'createdAt',
				title: 'SUBMITTED ON',
				headerStyle,
				headerClasses: 'table-header font-10 text-uppercase',
				render: (row) => (
					<div className=" mt-2 font-13" cursor="pointer">
						{moment(row.createdAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
			status: {
				field: 'requestState',
				title: 'STATUS',
				headerStyle,
				render: (row) => {
					if (
						(row.requestState === 'ENROLLMENT_AGREEMENT_SENT' ||
							row.requestState === 'AWAITING SIGNATURE') &&
						role === 'patient'
					) {
						return (
							<div className="d-flex">
								<ActionButton
									image={signImage}
									hoverColor={'var(--main-color)'}
									alt="Sign Now"
									onClick={() => this.signDocument(row)}
								/>
							</div>
						);
					}
					return (
						<div
							className="d-flex align-items-center mt-2 font-13"
							cursor="pointer"
						>
							{row.requestState ? row.requestState.split('_').join(' ') : '-'}
							{row.requestState === 'DENIED' && (
								<div
									className="ml-2 hand"
									id={`enrollment${row.requestId}`}
									onClick={() => this.togglePopover(row)}
								>
									<i className="fa fa-2x fa-info-circle" aria-hidden="true" />
								</div>
							)}
						</div>
					);
				},
			},
			client: {
				field: 'requestorName',
				title: 'CLIENT',
				headerStyle,
				render: (row) => (
					<div className="hand" onClick={() => this.goToProfile(row.userId)}>
						<ImageDetailsCell
							src={row.profilePicture}
							heading={row.requestorName}
							primarySubHeading={row.type}
							secondarySubHeading={row.requestorPhone}
							className="mt-2"
						/>
					</div>
				),
			},
			intake: {
				field: 'intakeDocs',
				title: 'INTAKE DOC(S)',
				headerStyle,
				render: (row) =>
					row.intakeDocs?.length > 0 ? (
						<div
							className="d-flex hand  link-color align-items-center mt-2 mx-0 font-13"
							onClick={() => {
								row.intakeDocs.forEach((doc) => {
									downloadFile(doc.uuid);
								});
							}}
						>
							DOWNLOAD {row.intakeDocs.length} FILE
							{row.intakeDocs.length > 1 && 'S'}
						</div>
					) : (
						<div className="mt-2">-</div>
					),
			},
			actions: {
				field: 'requestState',
				title: 'ACTIONS',
				headerStyle,
				headerClasses: 'table-header font-10 text-uppercase',
				render: (row) => (
					<div className="d-flex">
						{generateButtons(row, this).map((button) => button)}
					</div>
				),
			},
		};

		let columnsToUse = patient;
		if (role === 'admin' || role === 'clinician') {
			columnsToUse = admin;
		}

		return columnsToUse.map((c) => columns[c]);
	};

	changeClinician = (clinician) => {
		const { changeClinician } = this.props;
		const { patientId } = this.state;
		const data = { patientId, clinicianId: clinician.id };
		changeClinician(data);
		this.setState({
			showChangeClinician: false,
		});
	};

	render() {
		const {
			data = [],
			auth,
			role,
			loading,
			isMobile,
			downloadFile,
		} = this.props;
		const {
			siteUrl,
			modal,
			signingError,
			loadingSigning: loadingDocusign,
			updatingRequestId,
		} = this.state;
		const {
			showEnrollmentAgreement,
			requestId,
			groupName,
			startDate,
			programId,
			commentObject,
			showAddCommentPopover,
		} = this.state;
		const enrollReqColumns = this.getColumns();
		const enrollmentData = {
			requestId,
			groupName,
			startDate,
			programId,
		};

		data.forEach(
			(r) =>
				(r.requestState =
					r.requestId === updatingRequestId ? 'UPDATING' : r.requestState),
		);

		const rowStyle = (rowData) => {
			if (!rowData.isActive) {
				return {
					backgroundColor: 'rgb(255 231 231)',
				};
			}
		};

		const showComment = commentObject.id && showAddCommentPopover;
		return (
			<div>
				{loadingDocusign && <LoadingIndicator />}
				{signingError && (
					<StatusModal
						successStatus={false}
						hide={this.closeRequestSentModal}
						message="Something went wrong. Please try again."
					/>
				)}
				{isMobile ? (
					<div className="row mx-1">
						{loading ? (
							<SmallLoadingIndicator
								text={'Loading...'}
								color="var(--main-color)"
							/>
						) : (
							data.map((r) => (
								<RequestCard
									heading={`${r.groupName} ${BULLET} ${r.location}`}
									request={r}
									requestor={{
										profile: r.profileUrl,
										heading: r.requestorName,
										subheading: r.requestorState,
										id: r.userId,
									}}
									clinician={{
										clinicianName: r.clinicianName,
										therapistType: r.therapistType,
									}}
									onClick={() => downloadFile(r.uuid)}
									key={r.requestId}
								/>
							))
						)}
					</div>
				) : (
					<Table
						keyField="enrollReq"
						data={data}
						columns={enrollReqColumns}
						placeholder="No requests to display"
						classes={
							// eslint-disable-next-line no-nested-ternary
							role === 'clinician'
								? 'table-responsive-xl'
								: role === 'admin'
								? 'table-responsive-xl'
								: null
						}
						loading={loading}
						rowStyle={rowStyle}
					/>
				)}
				{showEnrollmentAgreement && (
					<EnrollmentAgreement
						hide={this.hideEnrollmentAgreementTogglle}
						auth={auth}
						data={enrollmentData}
						submitRequest={this.handleSubmitRequest}
						isMobile={isMobile}
					/>
				)}
				{showComment && (
					<CommentPopover
						toggle={this.togglePopover}
						target={commentObject.id}
						isOpen={this.state.showAddCommentPopover}
						title="Reason for Denial"
						comment={commentObject.reasonForDenial}
						mode="view"
					/>
				)}
				<SigningModal
					isOpen={modal}
					siteUrl={siteUrl}
					toggle={this.docusignToggle}
				/>
			</div>
		);
	}
}

EnrollmentRequests.propTypes = {
	dispatch: PropTypes.func,
	data: PropTypes.array,
	role: PropTypes.string,
	downloadFile: PropTypes.func,
	updateRequest: PropTypes.func,
	auth: PropTypes.object,
	siteUrl: PropTypes.string,
	clearSiteUrl: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	enrollmentRequests: makeSelectEnrollmentRequests(),
	siteUrl: makeSelectSigningUrl(),
	isMobile: makeSelectIsMobile(),
	isUpdating: makeSelectIsUpdating(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		clearSiteUrl: () => dispatch(clearUrl()),
		signDocument: (id) => dispatch(signDocument(id)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentRequests);
