import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import adminFeatures from 'accessible-features/admin';
import clinicianFeatures from 'accessible-features/clinician';
import patientFeatures from 'accessible-features/patient';
import routes from 'accessible-features/routes';
import { enableScroll } from 'containers/Dashboard/actions';

const TabMenu = (props) => {
	const [selectedTab, setTab] = useState(null);
	const [headerObject, setHeader] = useState([]);

	const { routeProps, isMobile, dispatch } = props;
	const {
		auth: {
			loggedInUser: { roleName },
		},
	} = routeProps;

	const changeActive = (pathName) => {
		routes.map((value) =>
			pathName === value.pathName ? setTab(value.tabName) : null,
		);
	};

	useEffect(() => {
		// let headerObject = {};
		if (roleName === 'admin') {
			setHeader(adminFeatures);
		}
		if (roleName === 'clinician') {
			setHeader(clinicianFeatures);
		}

		if (roleName === 'patient') {
			setHeader(patientFeatures);
		}

		changeActive(routeProps.location.pathname);
	}, [routeProps]);

	useEffect(() => {
		changeActive(routeProps.location.pathname);
	}, [routeProps]);

	return (
		<div className="row justify-content-around mx-1">
			{headerObject.map((tab) => {
				return (
					<NavLink
						className={`col nav-link text-center mtc-nav header-text text-dark ${
							isMobile ? 'pt-3 px-2' : ''
						}`}
						to={tab.to}
						onClick={() => {
							dispatch(enableScroll());
							changeActive(tab.tabId);
						}}
						activeClassName="active"
						key={tab.to}
					>
						{selectedTab === tab.tabId
							? tab.tabIconActive
							: tab.tabIconInactive}

						<span className="d-block mt-1 tab-link tab-link-mobile">
							{isMobile ? tab.mobileTabName : tab.tabName}
						</span>
					</NavLink>
				);
			})}
		</div>
	);
};

export default TabMenu;
