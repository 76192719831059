import moment from 'moment';

import { tabLabels } from './constants';

const todoFilters = (options, activeTab) => {
	switch (activeTab) {
		case tabLabels.NEW_INTAKES:
			return [
				{
					type: 'Dropdown Input',
					name: 'clinicianName',
					label: 'clinician',
					key: 'clinicianNameOther',
					selectOptions: options.clinicianName,
					selectLabel: 'CLINICIAN',
					defaultSelect: 'All Clinicians',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-new_intakes',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
			];
		case tabLabels.RX_REQUESTS:
			return options.type === 'admin'
				? [
						{
							type: 'Dropdown Input',
							name: 'pharmacyName',
							label: 'pharmacy',
							selectOptions: options.pharmacyName,
							selectLabel: 'PHARMACY',
							defaultSelect: 'All Pharmacies',
						},
						{
							type: 'Date Picker',
							name: 'dateRange-rx',
							from: options.from,
							to: options.to,
							label: 'CUSTOM RANGE',
						},
						{
							type: 'Toggle',
							name: 'showInactiveRx',
							label: 'Show Inactive',
							selectLabel: 'SHOW INACTIVE',
							isSelected: options.showInactiveRx,
						},
				  ]
				: [
						{
							type: 'Dropdown Input',
							name: 'clinicianName',
							label: 'clinician',
							selectOptions: options.clinicianName,
							selectLabel: 'CLINICIAN',
							defaultSelect: 'All Clinicians',
						},
						{
							type: 'Date Picker',
							name: 'dateRange-rx',
							from: options.from,
							to: options.to,
							label: 'CUSTOM RANGE',
						},
						{
							type: 'Toggle',
							name: 'showInactiveRx',
							label: 'Show Inactive',
							selectLabel: 'SHOW INACTIVE',
							isSelected: options.showInactiveRx,
						},
				  ];

		case tabLabels.ENROLLMENT_REQUESTS:
			return [
				{
					type: 'Dropdown Input',
					name: 'groupName',
					label: 'group',
					selectOptions: options.groupName,
					selectLabel: 'THERAPY NAME',
					defaultSelect: 'All Therapy',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-enroll',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
				{
					type: 'Toggle',
					name: 'showInactiveEnroll',
					label: 'Show Inactive',
					selectLabel: 'SHOW INACTIVE',
					isSelected: options.showInactiveEnroll,
				},
			];
		case tabLabels.OTHER_REQUESTS:
			return [
				{
					type: 'Dropdown Input',
					name: 'roleName',
					label: 'role',
					selectOptions: options.roleName,
					selectLabel: 'USER TYPE',
					defaultSelect: 'All Types',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-other',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
				{
					type: 'Toggle',
					name: 'showInactiveOther',
					label: 'Show Inactive',
					selectLabel: 'SHOW INACTIVE',
					isSelected: options.showInactiveOther,
				},
			];
		case tabLabels.MEMBER_REQUESTS:
			return [
				{
					type: 'Dropdown Input',
					name: 'requestType',
					label: 'type',
					selectOptions: options.requestType,
					selectLabel: 'REQUEST TYPE',
					defaultSelect: 'All Requests',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-member',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
				{
					type: 'Toggle',
					name: 'showInactiveMember',
					label: 'Show Inactive',
					selectLabel: 'SHOW INACTIVE',
					isSelected: options.showInactiveMember,
				},
			];
		case tabLabels.CLIENT_FEEDBACK:
			return [
				{
					type: 'Dropdown Input',
					name: 'type',
					label: 'type',
					selectOptions: options.type,
					selectLabel: 'TYPE',
					defaultSelect: 'All Types',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-feedback',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
				{
					type: 'Single Select',
					name: 'status',
					label: 'status',
					selectOptions: options.completed,
					selectLabel: 'STATUS',
					defaultSelect: 'Any Status',
				},
			];
		case tabLabels.ABSENCES:
			return [
				{
					type: 'Dropdown Input',
					name: 'groupName',
					label: 'group',
					selectOptions: options.groupName,
					selectLabel: 'THERAPY',
					defaultSelect: 'All Therapies',
				},
				{
					type: 'Date Picker',
					name: 'dateRange-absences',
					minDate: options.minDate || moment(),
					maxDate: options.maxDate || moment(),
					label: 'CUSTOM RANGE',
				},
			];
	}

	return [];
};

export default todoFilters;
