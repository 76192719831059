import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';


interface Props {
    prevProgress: () => void,
    nextProgress: () => () => void,
    isMobile: boolean,
}

export default function KetamineAdministration(props: Props): JSX.Element {
    const { prevProgress, nextProgress, isMobile } = props;

    const handleNext = () => {
        nextProgress()();
    };


    return (
        <div
            className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
        >
            <div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
                <div className="pt-1 pb-4">
                    <h4>Route of Administration:</h4>
                    <span>
                        Ketamine can be administered in a variety of ways: via an
                        intravenous ketamine infusion (IV), an intramuscular injection
                        (IM), a subcutaneous injection (SC) intranasally, (sublingual)
                        orally as a dissolving troche or oral dissolving tablet. Routes
                        vary in the onset of action, bioavailability, and clearing time
                        through the system for each individual. While there is generally a
                        predictable response based on past administration, it is possible
                        that patients may experience variable physiological and subjective
                        experiences with the same dose.
                    </span>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Route</th>
                                <th scope="col">Bioavailability</th>
                                <th scope="col">Onset</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IV</td>
                                <td>100%</td>
                                <td>1 min</td>
                            </tr>
                            <tr>
                                <td>IM</td>
                                <td>93%</td>
                                <td>1-5 min</td>
                            </tr>
                            <tr>
                                <td>Nasal</td>
                                <td>25-50%</td>
                                <td>30 min</td>
                            </tr>
                            <tr>
                                <td>Oral</td>
                                <td>17-24%</td>
                                <td>&lt;30 min</td>
                            </tr>
                            <tr>
                                <td>Sublingual</td>
                                <td>24-30%</td>
                                <td>&lt;15 min</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <span>
                        Ketamine has a half-life of 2.5 hours. The first stage of
                        metabolism is 10-15 minutes which is excreted through the kidneys.
                        A small percentage is unchanged. The majority is converted into
                        longer metabolites, such as hydroxynorketamine (HNK), which is
                        felt to mediate the antidepressant effects and can be detected in
                        the urine for up to a week after treatment.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Dosing protocols:</h4>
                    <span>
                        There are a variety of dosing protocols in practice. Much research
                        and attention has been focused on the provision of 0.Smg/kg of
                        ketamine by IV infusion over 45 minutes, in a repeated series
                        consisting of 2/week for 3 weeks. Other described protocols
                        include the provision of a single infusion, daily or weekly dosing
                        - by IV, IM, or oral routes of administration. There is
                        significant ongoing research into the initial and maintenance
                        protocol to define optimal response. Much attention is focused on
                        the maintenance of response, noting that the drop off in response
                        can approach 90% following a positive response. Combining
                        psychotherapy with ketamine dosing serves to prolong the effect by
                        addressing behavioral and psychological factors that can
                        perpetuate depression, anxiety, and other distress states.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Effect:</h4>
                    <span>
                        Ketamine effects can be designated as occurring at the time of
                        dosing - acute, and beyond the time it takes for ketamine to
                        metabolize physically- ongoing, noting that longer-acting
                        metabolites persist up to a week. The acute subjective effects of
                        ketamine dosing can range from sub perceptual disturbances in
                        cognitive processing and body sensation to full dissociative
                        states in which one feels separate from the body and thoughts
                        dissolve fully. Research evidence suggests that some level of
                        dissociation may be correlated with treatment response for
                        depression. These experiences are classified as non-ordinary
                        states of consciousness and may represent novel experiences for
                        patients. It is possible that some patients may experience a
                        departure from their usual mind and physical state as challenging
                        or unsettling at the moment. The treatment environment, supportive
                        therapist stance, and dosing protocol are designed to optimize the
                        positive nature of the subjective experiences. Initial dosing (ie.
                        50-100mg oral ketamine) is designed to place patients in a
                        &apos;trance&apos; state that is often described as a &quot;glass of wine
                        effect&quot;. Patients are given the option to increase the dose by
                        taking an additional oral dose of 50-100mg.
                    </span>
                </div>
            </div>

            <div className="d-flex mt-4 mb-5 justify-content-center">
                <div className="col-6">
                    <PrevButton onClick={prevProgress} isMobile={isMobile} />
                </div>
                <div className="col-6">
                    <NextButton onClick={handleNext} isMobile={isMobile} />
                </div>
            </div>
        </div>
    );
}
