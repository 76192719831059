/*
 *
 * BrowseGroups actions
 *
 */
import {
	CLEAR_URL,
	CREATE_REQUEST,
	CREATE_REQUEST_ERROR,
	CREATE_REQUEST_INDIVIDUAL,
	CREATE_REQUEST_INDIVIDUAL_ERROR,
	CREATE_REQUEST_INDIVIDUAL_SUCCESS,
	CREATE_REQUEST_SUCCESS,
	DEFAULT_ACTION,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function loadPrograms() {
	return {
		type: LOAD_PROGRAMS,
	};
}

export function loadProgramsSuccess(programs) {
	return {
		type: LOAD_PROGRAMS_SUCCESS,
		programs,
	};
}

export function loadProgramsError(error) {
	return {
		type: LOAD_PROGRAMS_ERROR,
		error,
	};
}

export function createRequest(data) {
	return {
		type: CREATE_REQUEST,
		data,
	};
}

export function createRequestSuccess(data) {
	return {
		type: CREATE_REQUEST_SUCCESS,
		data,
	};
}

export function createRequestError(error) {
	return {
		type: CREATE_REQUEST_ERROR,
		error,
	};
}

export function createRequestIndividual(data) {
	return {
		type: CREATE_REQUEST_INDIVIDUAL,
		data,
	};
}

export function createRequestIndividualSuccess(data) {
	return {
		type: CREATE_REQUEST_INDIVIDUAL_SUCCESS,
		data,
	};
}

export function createRequestIndividualError(error) {
	return {
		type: CREATE_REQUEST_INDIVIDUAL_ERROR,
		error,
	};
}

export function clearUrl() {
	return {
		type: CLEAR_URL,
	};
}
