/*
 *
 * Admin actions
 *
 */
import {
	CHANGE_CLINICIAN,
	CHANGE_CLINICIAN_ERROR,
	CHANGE_CLINICIAN_SUCCESS,
	DEFAULT_ACTION,
	LOAD_CLIENTS,
	LOAD_CLIENTS_ERROR,
	LOAD_CLIENTS_SUCCESS,
	LOAD_CLINICIANS,
	LOAD_CLINICIANS_ERROR,
	LOAD_CLINICIANS_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function loadClinicians() {
	return {
		type: LOAD_CLINICIANS,
	};
}
export function loadCliniciansSuccess(clinicians) {
	return {
		type: LOAD_CLINICIANS_SUCCESS,
		clinicians,
	};
}
export function loadCliniciansError(err) {
	return {
		type: LOAD_CLINICIANS_ERROR,
		err,
	};
}

export function loadClients() {
	return {
		type: LOAD_CLIENTS,
	};
}
export function loadClientsSuccess(clients) {
	return {
		type: LOAD_CLIENTS_SUCCESS,
		clients,
	};
}
export function loadClientsError(err) {
	return {
		type: LOAD_CLIENTS_ERROR,
		err,
	};
}

export function changeClinician(data) {
	return {
		type: CHANGE_CLINICIAN,
		data,
	};
}
export function changeClinicianSuccess(data) {
	return {
		type: CHANGE_CLINICIAN_SUCCESS,
		data,
	};
}
export function changeClinicianError(err) {
	return {
		type: CHANGE_CLINICIAN_ERROR,
		err,
	};
}
