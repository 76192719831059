import { call, put, takeLatest } from 'redux-saga/effects';

import { intakeTestApi } from 'pages/intake/api';
import {
	doMagicAPI,
	docLogAPI,
	enrollmentRequestAPI,
	questionnaireAPI,
	roiAPI,
	telehealthAPI,
} from 'utils/api';

import {
	doMagicError,
	doMagicSuccess,
	fetchDocLogError,
	fetchDocLogSuccess,
	testEnrollmentError,
	testEnrollmentSuccess,
	testIntakeNewError,
	testIntakeNewSuccess,
	testQuestionnaireError,
	testQuestionnaireSuccess,
	testRoiError,
	testRoiSuccess,
	testTelehealthError,
	testTelehealthSuccess,
} from './actions';
import {
	DO_MAGIC,
	FETCH_DOC_LOG,
	TEST_ENROLLMENT,
	TEST_INTAKE_NEW,
	TEST_QUESTIONNAIRE,
	TEST_ROI,
	TEST_TELEHEALTH,
} from './constants';

export default function* adminConsoleSaga() {
	yield takeLatest(TEST_TELEHEALTH, testTelehealth);
	yield takeLatest(TEST_ROI, testRoi);
	yield takeLatest(TEST_INTAKE_NEW, testIntakeNew);
	yield takeLatest(TEST_ENROLLMENT, testEnrollment);
	yield takeLatest(TEST_QUESTIONNAIRE, testQuestionnaire);
	yield takeLatest(FETCH_DOC_LOG, fetchDocLog);
	yield takeLatest(DO_MAGIC, doMagic);
}

export function* doMagic(action) {
	try {
		const response = yield call(doMagicAPI, action.data);
		if (response.response && response.response.status > 400) {
			yield put(doMagicError(response.response.statusText));
		} else {
			yield put(doMagicSuccess(response.url));
		}
	} catch (err) {
		yield put(doMagicError(err));
	}
}

export function* testRoi(action) {
	try {
		const response = yield call(roiAPI, action.data);
		if (response.response && response.response.status > 400) {
			yield put(testRoiError(response.response.statusText));
		} else {
			yield put(testRoiSuccess(response.url));
		}
	} catch (err) {
		yield put(testRoiError(err));
	}
}

export function* testQuestionnaire(action) {
	try {
		const response = yield call(questionnaireAPI, action.data);
		if (response.response && response.response.status > 400) {
			yield put(testQuestionnaireError(response.response.statusText));
		} else {
			yield put(testQuestionnaireSuccess(response.url));
		}
	} catch (err) {
		yield put(testQuestionnaireError(err));
	}
}

export function* testTelehealth(action) {
	try {
		const response = yield call(telehealthAPI, action.data);
		if (response.response && response.response.status > 400) {
			yield put(testTelehealthError(response.response.statusText));
		} else {
			yield put(testTelehealthSuccess(response.url));
		}
	} catch (err) {
		yield put(testTelehealthError(err));
	}
}

export function* testEnrollment(action) {
	try {
		const response = yield call(enrollmentRequestAPI, action.data);
		if (response.response && response.response.status > 400) {
			yield put(testEnrollmentError(response.response.statusText));
		} else {
			yield put(testEnrollmentSuccess(response.url));
		}
	} catch (err) {
		yield put(testEnrollmentError(err));
	}
}

export function* testIntakeNew(action) {
	try {
		const response = yield call(intakeTestApi, action.data);
		if (response.response && response.response.status > 400) {
			yield put(testIntakeNewError(response.response.statusText));
		} else {
			yield put(testIntakeNewSuccess(response.url));
		}
	} catch (err) {
		yield put(testIntakeNewError(err));
	}
}

export function* fetchDocLog() {
	try {
		const response = yield call(docLogAPI);
		if (response.docs) {
			yield put(fetchDocLogSuccess(response.docs));
		} else {
			yield put(fetchDocLogError('Could not load documents'));
		}
	} catch (err) {
		yield put(fetchDocLogError(err));
	}
}
