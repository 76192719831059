import PropTypes from 'prop-types';

import Avatar from 'atoms/Avatar/index.tsx';
import AvatarTextOverlay from 'atoms/AvatarTextOverlay';
import DefaultAvatar from 'atoms/DefaultAvatar';

/**
 *
 * <ImageListCell />, a functional component to display images
 */
const ImageListCell = (props) => {
	const { imageUrls } = props;
	const avatarStyle = {
		marginLeft: '-5px',
		border: '2px solid white',
		fontSize: `${40 * 0.4}px`,
	};
	const imageCount = imageUrls.length;

	return (
		<div className="d-flex align-items-center">
			{/** Avatar for first 3 people */}
			{imageUrls.slice(0, 3).map(
				(url, index) =>
					url &&
					(url.src ? (
						<div key={index}>
							<Avatar
								src={url.src}
								style={{ ...avatarStyle, zIndex: imageCount - index }}
								width="40px"
							/>
						</div>
					) : (
						<div key={index}>
							<DefaultAvatar
								name={imageUrls[index].patientName}
								style={{ ...avatarStyle, zIndex: imageCount - index }}
								width="40px"
							/>
						</div>
					)),
			)}

			{/** Avatar with people count for more than 3 people */}
			{imageCount > 3 && (
				<AvatarTextOverlay
					// src={imageUrls[3].src}
					text={`+${imageCount - 3}`}
					width="34px"
					fontSize="12px"
				/>
			)}
		</div>
	);
};

ImageListCell.propTypes = {
	// array of image url
	imageUrls: PropTypes.array,
};

export default ImageListCell;
