/**
 * Create the store with dynamic reducers
 */
import {
	connectRouter,
	routerMiddleware,
} from 'connected-react-router/immutable';

import { fromJS } from 'immutable';

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import adminReducer from 'containers/Admin/reducer';
import adminSaga from 'containers/Admin/saga';
import adminConsoleReducer from 'containers/AdminConsole/reducer';
import adminConsoleSaga from 'containers/AdminConsole/saga';
import appReducer from 'containers/App/reducer';
import appSaga from 'containers/App/saga';
import attendanceReducer from 'containers/Attendance/reducer';
import attendanceSaga from 'containers/Attendance/saga';
import browseGroupsPrivateReducer from 'containers/BrowseGroupsPrivate/reducer';
import browseGroupsPrivateSaga from 'containers/BrowseGroupsPrivate/saga';
import clinicianReducer from 'containers/Clinician/reducer';
import clinicianSaga from 'containers/Clinician/saga';
import clinicianRegisterReducer from 'containers/ClinicianRegister/reducer';
import clinicianRegisterSaga from 'containers/ClinicianRegister/saga';
import clinicianToDosReducer from 'containers/ClinicianToDos/reducer';
import clinicianToDosSaga from 'containers/ClinicianToDos/saga';
import cliniciansAndClientsReducer from 'containers/CliniciansAndClients/reducer';
import cliniciansAndClientsSaga from 'containers/CliniciansAndClients/saga';
import dashboardReducer from 'containers/Dashboard/reducer';
import dashboardSaga from 'containers/Dashboard/saga';
import enrollmentRequestsReducer from 'containers/EnrollmentRequests/reducer';
import enrollmentRequestsSaga from 'containers/EnrollmentRequests/saga';
import feedbackReducer from 'containers/Feedback/reducer';
import feedbackSaga from 'containers/Feedback/saga';
import feedbackFormReducer from 'containers/FeedbackForm/reducer';
import feedbackFormSaga from 'containers/FeedbackForm/saga';
import formsReducer from 'containers/Forms/reducer';
import formsSaga from 'containers/Forms/saga';
import geoReducer from 'containers/Geo/reducer';
import geoSaga from 'containers/Geo/saga';
import groupDetailReducer from 'containers/GroupDetail/reducer';
import groupDetailSaga from 'containers/GroupDetail/saga';
import memberRequestsReducer from 'containers/MemberRequests/reducer';
import memberRequestsSaga from 'containers/MemberRequests/saga';
import notificationsReducer from 'containers/Notifications/reducer';
import notificationsSaga from 'containers/Notifications/saga';
import patientReducer from 'containers/Patient/reducer';
import patientSaga from 'containers/Patient/saga';
import patientProfileReducer from 'containers/PatientProfile/reducer';
import patientProfileSaga from 'containers/PatientProfile/saga';
import patientRegisterReducer from 'containers/PatientRegister/reducer';
import patientRegisterSaga from 'containers/PatientRegister/saga';
import reportsReducer from 'containers/Reports/reducer';
import reportsSaga from 'containers/Reports/saga';
import requestsReducer from 'containers/Requests/reducer';
import requestsSaga from 'containers/Requests/saga';
import rxRequestsReducer from 'containers/RxRequests/reducer';
import rxRequestsSaga from 'containers/RxRequests/saga';
import signInReducer from 'containers/SignIn/reducer';
import signInSaga from 'containers/SignIn/saga';
import signUpReducer from 'containers/SignUp/reducer';
import signUpSaga from 'containers/SignUp/saga';
import termsAndConditionReducer from 'containers/TermsAndCondition/reducer';
import termsAndConditionSaga from 'containers/TermsAndCondition/saga';
import therapiesReducer from 'containers/Therapies/reducer';
import therapiesSaga from 'containers/Therapies/saga';
import toDosReducer from 'containers/ToDos/reducer';
import toDosSaga from 'containers/ToDos/saga';
import userDropDownReducer from 'containers/UserDropDown/reducer';
import userDropDownSaga from 'containers/UserDropDown/saga';
import userForgotPasswordReducer from 'containers/UserForgotPassword/reducer';
import userForgotPasswordSaga from 'containers/UserForgotPassword/saga';
import browseGroupsReducer from 'pages/browsegroups/reducer';
import browseGroupsSaga from 'pages/browsegroups/saga';
import patientIntakeReducer from 'pages/intake/reducer';
import patientIntakeSaga from 'pages/intake/saga';
import modalReducer from 'reducers/Modal';
import measuresSaga from 'v2/measures/saga';
import measuresReducer from 'v2/measures/reducer';

import { isProduction } from 'utils/constants';

import createReducer from './reducers';

function* rootSaga() {
	yield all([
		appSaga(),

		adminSaga(),
		adminConsoleSaga(),
		attendanceSaga(),

		browseGroupsSaga(),
		browseGroupsPrivateSaga(),

		clinicianSaga(),
		clinicianRegisterSaga(),
		cliniciansAndClientsSaga(),
		clinicianToDosSaga(),

		dashboardSaga(),

		enrollmentRequestsSaga(),

		feedbackSaga(),
		feedbackFormSaga(),
		formsSaga(),

		geoSaga(),
		groupDetailSaga(),

		memberRequestsSaga(),

		notificationsSaga(),

		patientSaga(),
		patientIntakeSaga(),
		patientProfileSaga(),
		patientRegisterSaga(),

		reportsSaga(),
		requestsSaga(),
		rxRequestsSaga(),

		signInSaga(),
		signUpSaga(),

		termsAndConditionSaga(),
		therapiesSaga(),
		toDosSaga(),

		userDropDownSaga(),
		userForgotPasswordSaga(),

		measuresSaga(),
	]);
}

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [sagaMiddleware, routerMiddleware(history)];

	const enhancers = [applyMiddleware(...middlewares)];

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	/* eslint-disable no-underscore-dangle, indent */
	const composeEnhancers =
		!isProduction &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
			: compose;
	/* eslint-enable */

	const reducers = {
		app: appReducer,

		admin: adminReducer,
		adminConsole: adminConsoleReducer,
		attendance: attendanceReducer,

		browseGroups: browseGroupsReducer,
		browseGroupsPrivate: browseGroupsPrivateReducer,

		clinician: clinicianReducer,
		clinicianRegister: clinicianRegisterReducer,
		cliniciansAndClients: cliniciansAndClientsReducer,
		clinicianToDos: clinicianToDosReducer,

		dashboard: dashboardReducer,

		enrollmentRequests: enrollmentRequestsReducer,

		feedback: feedbackReducer,
		feedbackForm: feedbackFormReducer,
		forms: formsReducer,

		geo: geoReducer,
		groupDetail: groupDetailReducer,

		memberRequests: memberRequestsReducer,
		modal: modalReducer,

		notifications: notificationsReducer,

		patient: patientReducer,
		patientIntake: patientIntakeReducer,
		patientProfile: patientProfileReducer,
		patientRegister: patientRegisterReducer,

		reports: reportsReducer,
		requests: requestsReducer,
		rxRequests: rxRequestsReducer,

		signIn: signInReducer,
		signUp: signUpReducer,

		termsAndCondition: termsAndConditionReducer,
		therapies: therapiesReducer,
		todos: toDosReducer,

		userDropDown: userDropDownReducer,
		userForgotPassword: userForgotPasswordReducer,

		measures: measuresReducer,
	};

	const store = createStore(
		createReducer({
			router: connectRouter(history),
			...reducers,
		}),
		fromJS(initialState),
		composeEnhancers(...enhancers),
	);

	// Extensions
	sagaMiddleware.run(rootSaga);
	store.injectedReducers = {}; // Reducer registry
	store.injectedSagas = {}; // Saga registry

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
		});
	}

	return store;
}
