/**
 *
 * AdultSubstanceAbuse
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import MoreInfo from 'atoms/MoreInfo';
import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { generateObjectArray } from 'utils/helpers';

import './index.css';

const substances = [
	{ name: 'TOBACCO', examples: '' },
	{ name: 'ALCOHOL', examples: '' },
	{ name: 'MARIJUANA OR K2/"SPICE"', examples: '' },
	{ name: 'COCAINE', examples: '' },
	{
		name: 'OPIATES',
		examples:
			'Heroin, Krantom, Morphine, Percocet, Oxycodone,Tylenol #3, Dilaudid/ Hydromorphone',
		style: { container: {}, popup: { minWidth: '240px' } },
	},
	{
		name: 'TRANQUILIZERS/SEDATIVES',
		examples: 'Xanax, Ativan, Klonopin, Valium',
	},
	{ name: 'PCP OR LSD', examples: '' },
	{ name: 'MUSHROOMS', examples: '' },
	{ name: 'OTHERS', examples: '' },
];

/* eslint-disable react/prefer-stateless-function */
const AdultSubstanceAbuse = (props) => {
	const { nextProgress, prevProgress, handleChange, data, isMobile } = props;
	const {
		useAlcohol,
		useAlcoholDescribe,
		afterDrinkingDescribe,
		useNicotine,
		useNicotineDescribe,
		drinkCaffeinatedDrinks,
		drinkCaffeinatedDrinksDescribe,
		useRecreationalDrugs,
		useRecreationalDrugsDescribe,
		appointmentDueToSubstances,
		appointmentDueToSubstancesDescribe,
		friendsFamilyVoiceConcern,
		threatenDueToAlcohol,
		threatenDueToAlcoholDescribe,
		geneticProblemOfAlcohol,
		geneticProblemOfAlcoholDescribe,
	} = data;

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Substance Abuse
			</h2>
			<Form onSubmit={nextProgress({}, 'adultSubstanceAbuse')}>
				<div className="card d-flex flex-column p-4 pb-0">
					<div className="row mt-3 mb-3">
						<label htmlFor="useAlcohol" className="form-font col-8">
							DO YOU USE ALCOHOL ?
						</label>
						<SelectButtonGroup
							id="useAlcohol"
							value={useAlcohol}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>

					{useAlcohol === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, HOW MANY DRINKS PER NIGHT, AND HOW MANY NIGHTS PER
              WEEK DO YOU DRINK ?"
							id="useAlcoholDescribe"
							value={useAlcoholDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}
					<TextArea
						className="mb-3"
						label="WHEN YOU DRINK DO YOU DRINK TO GET BUZZED, DRUNK OR BLACK-OUT ?"
						id="afterDrinkingDescribe"
						value={afterDrinkingDescribe}
						placeholder="Describe"
						handleInput={handleChange('adultSubstanceAbuse')}
					/>

					<div className="row mt-3 mb-3">
						<label htmlFor="useNicotine" className="form-font col-8">
							DO YOU USE NICOTINE ?
						</label>
						<SelectButtonGroup
							id="useNicotine"
							value={useNicotine}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{useNicotine === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, HOW MANY/OFTEN ?"
							id="useNicotineDescribe"
							value={useNicotineDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}

					<div className="row mt-3 mb-3">
						<label htmlFor="drinkCaffeinatedDrinks" className="form-font col-8">
							DO YOU DRINK CAFFEINATED DRINKS ?
						</label>
						<SelectButtonGroup
							id="drinkCaffeinatedDrinks"
							value={drinkCaffeinatedDrinks}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{drinkCaffeinatedDrinks === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, HOW MANY/OFTEN ?"
							id="drinkCaffeinatedDrinksDescribe"
							value={drinkCaffeinatedDrinksDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}

					<div className="row mt-3 mb-3">
						<label htmlFor="useRecreationalDrugs" className="form-font col-8">
							DO YOU USE ANY RECREATIONAL DRUGS ?
						</label>
						<SelectButtonGroup
							id="useRecreationalDrugs"
							value={useRecreationalDrugs}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{useRecreationalDrugs === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, HOW MANY/OFTEN ?"
							id="useRecreationalDrugsDescribe"
							value={useRecreationalDrugsDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}

					{/* For input table */}
					{isMobile ? (
						<div>
							<h4>Substance History</h4>
							{substances.map((substance) => (
								<div className="card p-2 my-3" key={substance.name}>
									<div className="row mx-0">
										<h5>{substance.name}</h5>
										{substance.examples && (
											<div className="ml-1">
												<MoreInfo
													text={substance.examples}
													style={substance.style}
												/>
											</div>
										)}
									</div>
									<div className="row mx-0">
										<label htmlFor={`${substance.name}Amount`}>
											Amount and Frequency
										</label>
										<input
											value={data[`${substance.name}HowMany`]}
											type="text"
											name={`${substance.name}HowMany`}
											id={`${substance.name}HowMany`}
											className="form-control"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
									<div className="row mx-0">
										<label htmlFor={`${substance.name}FirstUse`}>
											Age of First Use
										</label>
										<input
											value={data[`${substance.name}FirstUseAge`]}
											type="number"
											name={`${substance.name}FirstUseAge`}
											id={`${substance.name}FirstUseAge`}
											className="form-control"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
									<div className="row mx-0">
										<label htmlFor={`${substance.name}LastUse`}>Last Use</label>
										<input
											value={data[`${substance.name}LastUse`]}
											type="text"
											name={`${substance.name}LastUse`}
											id={`${substance.name}LastUse`}
											className="form-control"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
									<div className="row mx-0">
										<label htmlFor={`${substance.name}Reason`}>
											Reason and Consequence
										</label>
										<input
											value={data[`${substance.name}Reason`]}
											type="text"
											name={`${substance.name}Reason`}
											id={`${substance.name}Reason`}
											className="form-control"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="col-12 mt-5 mb-5 p-0 d-flex flex-column border-left border-top">
							<div className="d-flex">
								<div className="col-3 px-1 py-3 d-flex justify-content-center border-bottom border-right">
									<span className="font-12 align-self-center">
										SUBSTANCE OF CHOICE
									</span>
								</div>
								<div className="col-3 px-1 py-3 d-flex flex-column justify-content-center border-bottom border-right">
									<span className="font-12 align-self-center text-center">
										AMOUNT AND FREQUENCY ?
									</span>
									<span className="font-12 align-self-center">
										(e.g. - 25 gm/day)
									</span>
								</div>
								<div className="col-3 d-flex p-0">
									<div className="col-6 px-1 py-3 d-flex justify-content-center border-bottom border-right">
										<span className="font-12 align-self-center text-center">
											AGE OF FIRST USE ?
										</span>
									</div>
									<div className="col-6 px-1 py-3 d-flex justify-content-center border-bottom border-right">
										<span className="font-12 align-self-center">LAST USE</span>
									</div>
								</div>
								<div className="col-3 px-1 py-3 d-flex justify-content-center border-bottom border-right">
									<span className="font-12 align-self-center">
										REASON AND CONSEQUENCE
									</span>
								</div>
							</div>

							{substances.map((substance) => (
								<div className="d-flex" key={substance.name}>
									<div className="col-3 px-1 py-3 d-flex border-bottom border-right">
										<span className="font-12 align-self-start">
											{substance.name}
										</span>
									</div>
									<div className="col-3 px-1 py-3 d-flex justify-content-center border-bottom border-right">
										<input
											value={data[`${substance.name}HowMany`]}
											type="text"
											name={`${substance.name}HowMany`}
											id={`${substance.name}HowMany`}
											className="form-control no-border text-center align-self-center"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
									<div className="col-3 d-flex p-0">
										<div className="col-6 px-1 py-3 d-flex justify-content-center border-bottom border-right">
											<input
												value={data[`${substance.name}FirstUseAge`]}
												type="number"
												name={`${substance.name}FirstUseAge`}
												id={`${substance.name}FirstUseAge`}
												className="form-control no-border text-center"
												placeholder=""
												onChange={handleChange('adultSubstanceAbuse')}
											/>
										</div>
										<div className="col-6 px-1 py-3 d-flex justify-content-center border-bottom border-right">
											<input
												value={data[`${substance.name}LastUse`]}
												type="text"
												name={`${substance.name}LastUse`}
												id={`${substance.name}LastUse`}
												className="form-control no-border text-center"
												placeholder=""
												onChange={handleChange('adultSubstanceAbuse')}
											/>
										</div>
									</div>
									<div className="col-3 px-1 py-3 d-flex justify-content-center border-bottom border-right">
										<input
											value={data[`${substance.name}Reason`]}
											type="text"
											name={`${substance.name}Reason`}
											id={`${substance.name}Reason`}
											className="form-control no-border text-center"
											placeholder=""
											onChange={handleChange('adultSubstanceAbuse')}
										/>
									</div>
								</div>
							))}
						</div>
					)}
					<div className="row mt-3 mb-3">
						<label
							htmlFor="appointmentDueToSubstances"
							className="form-font col-8"
						>
							DOES YOUR USE OF ANY ALCOHOL OR THESE SUBSTANCES PLAY A PART IN
							THE REASON FOR YOUR APPOINTMENT TODAY ?
						</label>
						<SelectButtonGroup
							id="appointmentDueToSubstances"
							value={appointmentDueToSubstances}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{appointmentDueToSubstances === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, PLEASE EXPLAIN ?"
							id="appointmentDueToSubstancesDescribe"
							value={appointmentDueToSubstancesDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}

					<div className="row mt-3 mb-3">
						<label
							htmlFor="friendsFamilyVoiceConcern"
							className="form-font col-8"
						>
							DOES YOUR FAMILY OR FRIENDS VOICE CONCERN ?
						</label>
						<SelectButtonGroup
							id="friendsFamilyVoiceConcern"
							value={friendsFamilyVoiceConcern}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>

					<div className="row mt-3 mb-3">
						<label htmlFor="threatenDueToAlcohol" className="form-font col-8">
							HAVE YOU EVER BEEN TREATED FOR ALCOHOL OR SUBSTANCE ABUSE IN THE
							PAST ?
						</label>
						<SelectButtonGroup
							id="threatenDueToAlcohol"
							value={threatenDueToAlcohol}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{threatenDueToAlcohol === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, WHEN AND WHAT TYPE OF TREATMENT DID YOU RECEIVE ?"
							id="threatenDueToAlcoholDescribe"
							value={threatenDueToAlcoholDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}

					<div className="row mt-3 mb-3">
						<label
							htmlFor="geneticProblemOfAlcohol"
							className="form-font col-8"
						>
							DO ANY GENETIC RELATIVES HAVE A HISTORY OF PROBLEMS WITH ALCOHOL
							OR SUBSTANCE ABUSE ?
						</label>
						<SelectButtonGroup
							id="geneticProblemOfAlcohol"
							value={geneticProblemOfAlcohol}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange('adultSubstanceAbuse')}
							className="col-4"
						/>
					</div>
					{geneticProblemOfAlcohol === 'yes' && (
						<TextArea
							className="mb-3"
							label="IF YES, WHICH RELATIVE(S)"
							id="geneticProblemOfAlcoholDescribe"
							value={geneticProblemOfAlcoholDescribe}
							placeholder="Describe"
							handleInput={handleChange('adultSubstanceAbuse')}
						/>
					)}
				</div>
				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

AdultSubstanceAbuse.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
};

export default AdultSubstanceAbuse;
