/*
 *
 * Requests actions
 *
 */
import {
	CLEAR_URL,
	DEFAULT_ACTION,
	FETCH_PHARMACY,
	FETCH_PHARMACY_ERROR,
	FETCH_PHARMACY_SUCCESS,
	FETCH_PROGRAMS,
	FETCH_PROGRAMS_ERROR,
	FETCH_PROGRAMS_SUCCESS,
	FETCH_REQUEST,
	FETCH_REQUEST_ERROR,
	FETCH_REQUEST_SUCCESS,
	LOAD_PATIENT_GROUP_REQUESTS,
	LOAD_PATIENT_GROUP_REQUESTS_ERROR,
	LOAD_PATIENT_GROUP_REQUESTS_SUCCESS,
	LOAD_PATIENT_ROI_DATA,
	LOAD_PATIENT_ROI_DATA_ERROR,
	LOAD_PATIENT_ROI_DATA_SUCCESS,
	LOAD_PATIENT_RX_REQUESTS,
	LOAD_PATIENT_RX_REQUESTS_ERROR,
	LOAD_PATIENT_RX_REQUESTS_SUCCESS,
	REQUEST_TELEHEALTH,
	REQUEST_TELEHEALTH_ERROR,
	REQUEST_TELEHEALTH_SUCCESS,
	SIGN_DOC,
	SIGN_DOC_ERROR,
	SIGN_DOC_SUCCESS,
	SUBMIT_PATIENT_ROI_DATA,
	SUBMIT_PATIENT_ROI_DATA_ERROR,
	SUBMIT_PATIENT_ROI_DATA_SUCCESS,
	UPDATE_PATIENT_RX_REQUEST,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function fetchPrograms() {
	return {
		type: FETCH_PROGRAMS,
	};
}

export function fetchProgramsSuccess(data) {
	return {
		type: FETCH_PROGRAMS_SUCCESS,
		data,
	};
}

export function fetchProgramsError(err) {
	return {
		type: FETCH_PROGRAMS_ERROR,
		err,
	};
}

export function fetchRequest(id) {
	return {
		type: FETCH_REQUEST,
		id,
	};
}
export function fetchRequestSuccess(data) {
	return {
		type: FETCH_REQUEST_SUCCESS,
		data,
	};
}
export function fetchRequestError(err) {
	return {
		type: FETCH_REQUEST_ERROR,
		err,
	};
}

export function loadPatientRxRequests() {
	return {
		type: LOAD_PATIENT_RX_REQUESTS,
	};
}

export function loadPatientRxRequestsSuccess(data) {
	return {
		type: LOAD_PATIENT_RX_REQUESTS_SUCCESS,
		data,
	};
}

export function loadPatientRxRequestsError(err) {
	return {
		type: LOAD_PATIENT_RX_REQUESTS_ERROR,
		err,
	};
}

export function loadPatientRequests() {
	return {
		type: LOAD_PATIENT_GROUP_REQUESTS,
	};
}

export function loadPatientRequestsSuccess(data) {
	return {
		type: LOAD_PATIENT_GROUP_REQUESTS_SUCCESS,
		data,
	};
}

export function loadPatientRequestsError(err) {
	return {
		type: LOAD_PATIENT_GROUP_REQUESTS_ERROR,
		err,
	};
}

export function updatePatientRx(data) {
	return {
		type: UPDATE_PATIENT_RX_REQUEST,
		data,
	};
}

export function fetchPharmacy(id) {
	return {
		type: FETCH_PHARMACY,
		id,
	};
}

export function fetchPharmacySuccess(data) {
	return {
		type: FETCH_PHARMACY_SUCCESS,
		data,
	};
}

export function fetchPharmacyError(err) {
	return {
		type: FETCH_PHARMACY_ERROR,
		err,
	};
}

export function loadPatientRoiData(data) {
	return {
		type: LOAD_PATIENT_ROI_DATA,
		data,
	};
}

export function loadPatientRoiDataSuccess(data) {
	return {
		type: LOAD_PATIENT_ROI_DATA_SUCCESS,
		data,
	};
}

export function loadPatientRoiDataError(err) {
	return {
		type: LOAD_PATIENT_ROI_DATA_ERROR,
		err,
	};
}

export function submitPatientRoiData(data) {
	return {
		type: SUBMIT_PATIENT_ROI_DATA,
		data,
	};
}

export function submitPatientRoiDataSuccess(data) {
	return {
		type: SUBMIT_PATIENT_ROI_DATA_SUCCESS,
		data,
	};
}

export function submitPatientRoiDataError(err) {
	return {
		type: SUBMIT_PATIENT_ROI_DATA_ERROR,
		err,
	};
}

export function requestTelehealth(data) {
	return {
		type: REQUEST_TELEHEALTH,
		data,
	};
}

export function requestTelehealthSuccess(data) {
	return {
		type: REQUEST_TELEHEALTH_SUCCESS,
		data,
	};
}

export function requestTelehealthError(err) {
	return {
		type: REQUEST_TELEHEALTH_ERROR,
		err,
	};
}

export function signDoc(id) {
	return {
		type: SIGN_DOC,
		id,
	};
}

export function signDocSuccess(data) {
	return {
		type: SIGN_DOC_SUCCESS,
		data,
	};
}

export function signDocError(err) {
	return {
		type: SIGN_DOC_ERROR,
		err,
	};
}

export function clearUrl() {
	return {
		type: CLEAR_URL,
	};
}
