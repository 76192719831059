const filters = (options) => [
	{
		type: 'Single Select',
		name: 'clinician_name',
		selectOptions: options.clinician,
		selectLabel: 'CLINICIAN',
		defaultSelect: 'All Clinicians',
	},
	{
		type: 'Single Select',
		name: 'location',
		selectOptions: options.location,
		selectLabel: 'LOCATION',
		defaultSelect: 'All Locations',
	},
	{
		type: 'Single Select',
		name: 'group_size',
		selectOptions: options.groupSize,
		selectLabel: 'SIZE',
		defaultSelect: 'Any Size',
	},
	{
		type: 'Single Select',
		name: 'group_type',
		selectOptions: options.group,
		selectLabel: 'THERAPY NAME',
		defaultSelect: 'Any',
	},
	{
		type: 'Single Select',
		name: 'day',
		selectOptions: options.day,
		selectLabel: 'DAYS',
		defaultSelect: 'All Days',
	},
];

export default filters;
