import PropTypes from 'prop-types';

import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	:hover input ~ div {
		background-color: #eee;
	}

	input:checked ~ div {
		background-color: var(--main-color);
	}

	input:checked ~ div:after {
		display: block;
	}
`;

const Checkbox = styled.div`
	position: absolute;
	top: 2px;
	left: 2px;
	height: 20px;
	width: 20px;
	background-color: white;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.5);

	&:after {
		content: '';
		position: absolute;
		display: none;
		left: 7px;
		top: 2px;
		width: 5px;
		height: 11px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

const CustomCheckbox = (props) => {
	const { checked, onChange, styles = {}, classNames = {} } = props;
	return (
		<Container className={classNames.container} style={styles.container}>
			<input type="checkbox" className="visually-hidden" checked={checked} />
			<Checkbox className={classNames.checkbox} onClick={onChange} />
		</Container>
	);
};

CustomCheckbox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	styles: PropTypes.object,
	classNames: PropTypes.object,
};

export default CustomCheckbox;
