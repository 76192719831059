import styled from 'styled-components';

const Wrapper = styled.div`
	margin: 0 auto;
	width: 40px;
	height: 40px;
	position: relative;
	opacity: initial;
`;

export default Wrapper;
