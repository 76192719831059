import { CSSProperties } from 'react';

interface Props {
	src: string;
	width: string;
	style: CSSProperties;
}

/**
 * <Avatar />, a functional component to display circular image.
 */
export default function Avatar(props: Props): JSX.Element {
	const { src, width = '32px', style } = props;
	const avatarStyle = {
		borderRadius: '50%',
		width,
		height: width,
		backgroundColor: '#c9c6c6',
	};

	return <img src={src} style={{ ...avatarStyle, ...style }} alt="" />;
}
