import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * <PrevNextButtons />, a functional component for PrevNextButtons
 */
const PrevNextButtons = (props) => {
	const { className, prevProgress, nextProgress, isLastPage, isBusy } = props;

	const modifiedClass = `${className} d-flex justify-content-center`;

	return (
		<div className={modifiedClass}>
			<div className="col-5">
				<button
					type="button"
					className="btn btn-white bg-white link-color border btn-lg btn-block font-15 font-weight-bold d-flex justify-content-start"
					onClick={prevProgress}
				>
					<div className="col-4 d-flex justify-content-start align-self-center">
						<img className="fas fa-arrow-left" alt="custom-arrow-left" />
					</div>
					<div className="col-4 d-flex justify-content-center">
						<span>PREV</span>
					</div>
				</button>
			</div>
			<div className="col-5">
				<button
					disabled={isBusy}
					className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-center"
					onClick={nextProgress}
				>
					<div className="col-2"></div>
					<div className="col-8 d-flex justify-content-center">
						<span>
							{isBusy ? 'PLEASE WAIT' : isLastPage ? 'FINISH' : 'NEXT'}
						</span>
					</div>
					<div className="col-2 d-flex justify-content-end align-self-center">
						{isLastPage ? (
							<img className="fas fa-check" alt="finish" />
						) : (
							<img className="fas fa-arrow-right" alt="custom-arrow-right" />
						)}
					</div>
				</button>
			</div>
		</div>
	);
};

PrevNextButtons.propTypes = {
	className: PropTypes.string,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func,
	isLastPage: PropTypes.bool.isRequired,
};

export default PrevNextButtons;
