import _ from 'lodash';

import React from 'react';
import { Input } from 'reactstrap';

import DeleteButton from 'atoms/DeleteButton';
import PhoneNumberInput from 'atoms/PhoneNumberInput';

const EmergencyContact = (props) => {
	const { data = [], dispatch } = props;

	const handleChange = () => {
		throw new Error('Handle change is not implemented.');
	};

	const addContact = () => {
		dispatch({}, 'ADD_CONTACT');
	};

	const deleteContact = (index) => {
		dispatch({ index }, 'REMOVE_CONTACT');
	};

	return (
		<div>
			{data?.length > 0 ? (
				data.map((contact, i) => {
					return (
						<div key={`emergency-contact-${i}`}>
							<DeleteButton
								onClick={() => deleteContact(i)}
								popupText={'Delete Contact'}
								styles={{
									container: {
										marginTop: '-0.25rem',
										marginBottom: '-0.25rem',
									},
								}}
							/>
							<div className="form-row">
								<div className="form-group col">
									<div className="form-group">
										<label
											htmlFor={`emergencyContact-${i}`}
											className="form-font"
										>
											CONTACT NAME*
										</label>
										<Input
											id={`emergencyContact-${i}`}
											value={_.startCase(contact.emergencyContact)}
											placeholder="Enter Contact Name"
											onChange={(e) =>
												handleChange(i, 'emergencyContact', e.target.value)
											}
										/>
									</div>
								</div>
								<div className="form-group col">
									<div className="form-group">
										<label htmlFor={`relationship-${i}`} className="form-font">
											RELATIONSHIP*
										</label>
										<Input
											id={`relationship-${i}`}
											value={_.startCase(contact.relationship)}
											placeholder="Enter Relationship"
											onChange={(e) =>
												handleChange(i, 'relationship', e.target.value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<PhoneNumberInput
										label="HOME PHONE*"
										id={`homePhone-${i}`}
										value={contact.homePhone}
										placeholder="Enter 10 Digit Phone Number"
										handleInput={(id, values) =>
											handleChange(i, 'homePhone', values.value)
										}
									/>
								</div>
								<div className="form-group col">
									<PhoneNumberInput
										label="WORK PHONE*"
										id={`workPhone-${i}`}
										value={contact.workPhone}
										placeholder="Enter 10 Digit Phone Number"
										handleInput={(id, values) =>
											handleChange(i, 'workPhone', values.value)
										}
									/>
								</div>
							</div>
							<hr />
						</div>
					);
				})
			) : (
				<div className="w-100 text-center py-3" style={{ fontSize: '28px' }}>
					No Emergency Contacts on File
				</div>
			)}
			<div className="form-row w-100 justify-content-end pb-3">
				<div className="col">
					<button
						disabled={false}
						onClick={addContact}
						className="btn btn-white link-color border btn-lg btn-block font-15 mt-3 font-weight-bold w-100"
					>
						+ ADD NEW CONTACT
					</button>
				</div>
			</div>
		</div>
	);
};

export default EmergencyContact;
