/*
 *
 * UserDropDown constants
 *
 */

export const DEFAULT_ACTION = 'src/Feedback/DEFAULT_ACTION';

export const SUBMIT_FEEDBACK = 'src/Feedback/SUBMIT_FEEDBACK';
export const SUBMIT_FEEDBACK_SUCCESS = 'src/Feedback/SUBMIT_FEEDBACK_SUCCESS';
export const SUBMIT_FEEDBACK_ERROR = 'src/Feedback/SUBMIT_FEEDBACK_ERROR';
