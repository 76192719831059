import { startCase } from 'lodash';

import moment from 'moment';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { headerStyle } from 'containers/App/constants';

const requestsColumns = [];

const abbreviationToName = {
	ROI: 'Release of Information',
	Rx: 'Rx',
	LEAVE: 'Leave of Absence',
	DISCONTINUANCE: 'Discontinuance',
	'Patient-Intake': 'Patient Intake',
	'Intake-Questionnaire': 'Intake Questionnaire',
	'ENROLLMENT-REQUEST': 'Enrollment Request',
	Telehealth: 'Telehealth',
};

const sanitizeName = (data) => {
	const { documentType, filename } = data;
	if (documentType === 'FORM-SHARE') {
		return filename;
	}

	return startCase(abbreviationToName[documentType]);
};

const getDocsColumns = (type, viewForm) => {
	const generateSubHeading = (user) => {
		if (user.roleId === 1) {
			return 'Admin';
		} else if (user.roleId === 2) {
			return user.therapistType;
		} else {
			return 'Client';
		}
	};

	const columns = [
		{
			field: 'documentType',
			title: 'TYPE',
			headerStyle,
			headerClasses: 'table-header font-10 text-uppercase',
			render: (row) => (
				<div
					className={`mt-2 mx-2 font-13 hand ${
						type === 'pending' ? '' : 'link-color'
					}`}
					onClick={() => type !== 'pending' && viewForm(row)}
				>
					{sanitizeName(row)}
				</div>
			),
		},
		{
			field: 'createdAt',
			title: 'CREATED AT',
			headerStyle,
			headerClasses: 'table-header font-10 text-uppercase',
			render: (row) => (
				<div className="mt-2 mx-2 font-13">
					{moment(row.createdAt).format('MMM D, YYYY') || '-'}
				</div>
			),
		},
		{
			field: 'updatedAt',
			title: 'UPDATED AT',
			headerStyle,
			headerClasses: 'table-header font-10 text-uppercase',
			render: (row) => (
				<div className="mt-2 mx-2 font-13">
					{type === 'pending' && row.updatedAt !== row.createdAt
						? moment(row.updatedAt).format('MMM D, YYYY') || '-'
						: '-'}
				</div>
			),
		},
	];

	if (type === 'pending') {
		columns.splice(1, 0, {
			field: 'sender',
			title: 'REQUESTOR',
			headerStyle,
			headerClasses: 'table-header font-10 text-uppercase',
			render: (row) => (
				<div className="mt-2 mx-2 font-13">
					{row.sender?.name ? (
						<ImageDetailsCell
							heading={row.sender.name}
							primarySubHeading={generateSubHeading(row.sender)}
						/>
					) : (
						'-'
					)}
				</div>
			),
		});
		/* columns.splice(2, 0, {
      field: 'state',
      title: 'STATE',
      headerStyle,
      headerClasses: 'table-header font-10 text-uppercase',
      render: (row) => (
        <div className="mt-2 mx-2 font-13">{row.request.state}</div>
      ),
    }); */
	}

	return columns;
};

export { requestsColumns, getDocsColumns };
