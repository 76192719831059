import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Nav } from 'reactstrap';

import CustomCheckbox from 'atoms/CustomCheckbox';
import CustomDropdown from 'atoms/CustomDropdown';
import DropdownInput from 'atoms/DropdownInput';
import FilterDatePicker from 'atoms/FilterDatePicker';
import { noop } from 'utils/common';

import './index.css';
import FilterNavMobile from './mobile';

class FilterNav extends Component {
	state = { from: {}, to: {} };
	handleSelect = (name, callback, label) => (option) => {
		const { addFilter } = this.props;
		const filter = {
			name,
			callback,
			option,
			label,
		};

		addFilter(filter, true);
	};

	handleDateChange = (type, name, value) => {
		const { addFilter, removeFilter, filterByDate } = this.props;

		if (!value || typeof value === 'undefined') {
			removeFilter(`${name}-${type}`);
		} else {
			const filter = {
				name: `${name}-${type}`,
				callback: filterByDate,
				option: value,
			};

			addFilter(filter);
		}

		this.setState((prevState) => {
			const values = prevState[type];

			return {
				[type]: {
					...values,
					[name]: value,
				},
			};
		});
	};

	handleCheck = (name) => {
		const { handleCheck = noop } = this.props;
		this.setState((prevState) => ({
			[name]: !prevState[name],
		}));
		handleCheck(name);
	};

	handleReset = (name) => {
		const { removeFilter } = this.props;
		removeFilter(name);
	};

	getDateOption = (type, name) => {
		return this.state[type][name];
	};

	getOption = (name) => {
		const { currentFilters } = this.props;

		if (!currentFilters) return null;

		const result = currentFilters.find((f) => f.name === name);

		if (result) {
			return result.option;
		}

		return null;
	};

	getFilterList = () => {
		const { filtersList, filterDropdown, isMobile } = this.props;

		return filtersList.map((filter) => {
			let component;
			switch (filter.type) {
				case 'Single Select':
					component = (
						<CustomDropdown
							key={filter.name}
							selectOptions={filter.selectOptions}
							selectedOption={this.getOption(filter.name)}
							selectLabel={filter.selectLabel}
							handleSelect={this.handleSelect(
								filter.name,
								filterDropdown,
								filter.label,
							)}
							defaultSelect={filter.defaultSelect}
							handleReset={() => this.handleReset(filter.name)}
							isMobile={isMobile}
						/>
					);
					break;
				case 'Date Picker':
					component = (
						<FilterDatePicker
							className="col-auto br-1 p-3"
							key={filter.name}
							name={filter.name}
							label={filter.label}
							from={this.getDateOption('from', filter.name)}
							to={this.getDateOption('to', filter.name)}
							minDate={filter.minDate}
							maxDate={filter.maxDate}
							handleDateChange={this.handleDateChange}
							isMobile={isMobile}
						/>
					);
					break;
				case 'Dropdown Input':
					component = (
						<DropdownInput
							key={filter.key || filter.name}
							selectOptions={filter.selectOptions}
							selectLabel={filter.selectLabel}
							selectedOption={this.getOption(filter.name)}
							handleSelect={this.handleSelect(
								filter.name,
								filterDropdown,
								filter.label,
							)}
							defaultSelect={filter.defaultSelect}
							handleReset={() => this.handleReset(filter.name)}
							isMobile={isMobile}
						/>
					);
					break;
				case 'Toggle':
					component = (
						<div
							className={`align-items-center ${
								isMobile ? 'p-0 py-2' : ' p-3'
							} col-auto ${!isMobile && 'br-1'}`}
						>
							<div
								className={`text-muted m-0 ${
									isMobile ? 'font-13 pb-2' : 'font-9'
								}`}
							>
								{filter.selectLabel}
								<CustomCheckbox
									checked={this.state[filter.name]}
									classNames={{
										checkbox: 'ml-4 mt-2',
									}}
									onChange={() => this.handleCheck(filter.name)}
								/>
							</div>
						</div>
					);
					break;
				default:
					break;
			}

			return <React.Fragment key={filter.name}>{component}</React.Fragment>;
		});
	};

	render() {
		const { isMobile, search, kebab, dispatch } = this.props;

		let filterList = this.getFilterList();

		if (isMobile) {
			const { currentFilters, hasTabs } = this.props;

			return (
				<FilterNavMobile
					currentFilters={currentFilters}
					handleReset={this.handleReset}
					search={search}
					kebab={kebab}
					hasTabs={hasTabs}
					filterList={filterList}
					dispatch={dispatch}
				/>
			);
		}

		return (
			<div className="container-fluid mb-3">
				<Nav className={'row filter-nav bg-white pl-3'}>{filterList}</Nav>
				{search}
			</div>
		);
	}
}

FilterNav.propTypes = {
	filtersList: PropTypes.array,
	filterDropdown: PropTypes.func,
	handleReset: PropTypes.func,
	filterPriceRange: PropTypes.func,
	isMobile: PropTypes.bool,
};

export default FilterNav;
