/*
 *
 * ClinicianRegister actions
 *
 */
import {
	CLINICIAN_REGISTRATION,
	ERROR,
	FETCH_DATA,
	FETCH_STATES,
	FETCH_STATES_ERROR,
	FETCH_STATES_SUCCESS,
	SUCCESS,
	SUCCESS_CHECK_ONBOARDING_CLINICIAN,
	UPLOAD_CREDENTIAL,
	UPLOAD_CREDENTIAL_ERROR,
	UPLOAD_CREDENTIAL_SUCCESS,
} from './constants';

export function fetchStates() {
	return {
		type: FETCH_STATES,
	};
}

export function fetchStatesSuccess(states) {
	return {
		type: FETCH_STATES_SUCCESS,
		states,
	};
}

export function fetchStatesError(err) {
	return {
		type: FETCH_STATES_ERROR,
		error: err,
	};
}

export function uploadCredential(files) {
	return {
		type: UPLOAD_CREDENTIAL,
		files,
	};
}

export function uploadCredentialSuccess(fileUploadMsg) {
	return {
		type: UPLOAD_CREDENTIAL_SUCCESS,
		fileUploadMsg,
	};
}

export function uploadCredentialError(err) {
	return {
		type: UPLOAD_CREDENTIAL_ERROR,
		error: err,
	};
}

export function clinicianRegistration(data) {
	return {
		type: CLINICIAN_REGISTRATION,
		data,
	};
}

export function success(data) {
	return {
		type: SUCCESS,
		data,
	};
}

export function error() {
	return {
		type: ERROR,
	};
}

export function successCheckOnboading(data) {
	return {
		type: SUCCESS_CHECK_ONBOARDING_CLINICIAN,
		data,
	};
}

export function fetchData(data) {
	return {
		type: FETCH_DATA,
		data,
	};
}
