/*
 *
 * Groups reducer
 *
 */
import { fromJS } from 'immutable';

import moment from 'moment';

import { SAVE_ATTENDANCE_SUCCESS } from 'containers/Attendance/constants';
import { returnJS } from 'utils/helpers';

import {
	CREATE_PROGRAM,
	CREATE_PROGRAM_ERROR,
	CREATE_PROGRAM_SUCCESS,
	DEACTIVATE_PROGRAM,
	DEACTIVATE_PROGRAM_ERROR,
	DEACTIVATE_PROGRAM_SUCCESS,
	DEFAULT_ACTION,
	DELETE_PROGRAM,
	DELETE_PROGRAM_ERROR,
	DELETE_PROGRAM_SUCCESS,
	LOAD_GROUP_TYPES,
	LOAD_GROUP_TYPES_ERROR,
	LOAD_GROUP_TYPES_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
	UPDATE_PROGRAM,
	UPDATE_PROGRAM_ERROR,
	UPDATE_PROGRAM_SUCCESS,
} from './constants';

export const initialState = fromJS({
	groupTypes: [],
	clinicians: [],
	createProgramSuccess: true,
	deactivateProgramSuccess: true,
	deleteProgramSuccess: true,
	programs: [],
	isFetching: false,
	isUpdating: false,
});

function updatePrograms(state, data) {
	const programs = returnJS(state.get('programs'));

	const newPrograms = programs.map((p) => {
		if (p.programId === data.programId) {
			let program = p;

			data.q.forEach((query) => {
				if (query === 'startTime' || query === 'endTime') {
					p[query] = moment(`12-31-01 ${data[query]}`).toString();
				} else {
					p[query] = data[query];
				}
			});

			return program;
		} else {
			return p;
		}
	});

	return newPrograms;
}

function therapiesReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case LOAD_GROUP_TYPES:
			return state.set('groupTypes', []);
		case LOAD_GROUP_TYPES_SUCCESS:
			return state.set('groupTypes', action.groupTypes);
		case LOAD_GROUP_TYPES_ERROR:
			return state.set('error', action.error);

		case CREATE_PROGRAM:
			return state;
		case CREATE_PROGRAM_SUCCESS:
			return state.set('createProgramSuccess', true);
		case CREATE_PROGRAM_ERROR:
			return state
				.set('createProgramSuccess', false)
				.set('error', action.error);

		case DELETE_PROGRAM:
			return state;
		case DELETE_PROGRAM_SUCCESS:
			return state.set('deleteProgramSuccess', true);
		case DELETE_PROGRAM_ERROR:
			return state
				.set('deleteProgramSuccess', false)
				.set('error', action.error);

		case UPDATE_PROGRAM:
			return state.set('isUpdating', true);
		case UPDATE_PROGRAM_SUCCESS:
			return state
				.set('isUpdating', false)
				.set('programs', updatePrograms(state, action.data));
		case UPDATE_PROGRAM_ERROR:
			return state.set('isUpdating', false).set('error', action.error);

		case DEACTIVATE_PROGRAM:
			return state;
		case DEACTIVATE_PROGRAM_SUCCESS:
			return state.set('deactivateProgramSuccess', true);
		case DEACTIVATE_PROGRAM_ERROR:
			return state
				.set('deactivateProgramSuccess', false)
				.set('error', action.error);

		case LOAD_PROGRAMS:
			return state.merge({
				programs: [],
				isFetching: true,
			});
		case LOAD_PROGRAMS_SUCCESS:
			return state.merge({
				programs: action.programs,
				isFetching: false,
			});
		case LOAD_PROGRAMS_ERROR:
			return state.merge({
				error: action.error,
				isFetching: false,
			});
		case SAVE_ATTENDANCE_SUCCESS:
			/* eslint-disable no-case-declarations */
			const [record] = action.data.attendance;
			const oldPrograms = state.get('programs').toJS();
			const newPrograms = oldPrograms.map((pro) => {
				if (pro.upcomingSessionId === record.session.sessionId) {
					pro.status = record.status;
				}

				return pro;
			});
			return state.merge({
				programs: newPrograms,
			});
		default:
			return state;
	}
}

export default therapiesReducer;
