import history from 'utils/history';

import { lowerCase, startCase } from 'lodash';

import moment from 'moment';

import React from 'react';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

const viewRequests = ['LEAVE', 'ROI', 'GENERAL', 'Rx', 'DISCONTINUANCE'];

const RequestCard = (props) => {
	const {
		request,
		heading,
		subheading,
		onClick,
		clinician = {},
		requestor,
	} = props;

	const hasDocument = (request) => {
		return viewRequests.includes(request.requestType) || isDownload(request);
	};

	const isDownload = (request) => {
		return request.uuid && request.uuid.length > 1;
	};

	return (
		<div className="card-container request-card bg-white border-bottom p-3">
			{heading && (
				<div
					className="card-title font-weight-bold mb-2 text-center"
					style={{ fontSize: '18px' }}
				>
					{heading}
				</div>
			)}
			<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2 text-center">
				{subheading}
			</div>
			<div className="row mx-0 mb-2 justify-content-between">
				{requestor && (
					<div className="col-6">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							Requested By
						</div>
						<div
							className="hand"
							onClick={() => history.push(`patient/${requestor.id}/profile`)}
						>
							<ImageDetailsCell
								src={requestor.profileUrl}
								heading={requestor.heading}
								primarySubHeading={requestor.subheading}
							/>
						</div>
					</div>
				)}
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Status
					</div>
					<div className="font-13">
						{startCase(lowerCase(request.requestState))}
					</div>
				</div>
			</div>
			<div className="row mx-0 justify-content-between">
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						View Document
					</div>
					<div>
						{hasDocument(request) ? (
							<div
								className="d-flex hand  link-color justify-content-start align-items-center font-13"
								onClick={onClick}
							>
								{isDownload(request) ? 'DOWNLOAD' : 'VIEW'}
							</div>
						) : (
							<div className="d-flex text-muted justify-content-start align-items-center font-13">
								DOCUMENT UNAVAILABLE
							</div>
						)}
					</div>
				</div>
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Submitted On
					</div>
					<div className="font-13">
						{request.createdAt
							? moment(request.createdAt).format('YYYY-MM-DD')
							: '-'}
					</div>
				</div>
			</div>
			{clinician.clinicianName && (
				<div>
					<hr />
					<div className="row mx-0 mb-2">
						<div className="col-auto mx-auto">
							<ImageDetailsCell
								src={clinician.clinicianProfile}
								heading={clinician.clinicianName}
								primarySubHeading={clinician.therapistType}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default RequestCard;
