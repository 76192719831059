import { isNil } from 'lodash';

import PropTypes from 'prop-types';

import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import './index.css';

/**
 *
 * <PhoneNumberInput />, a functional component formatting number input
 */
const PhoneNumberInput = (props) => {
	const {
		label,
		placeholder,
		handleInput,
		className,
		id,
		value,
		isRequired,
		readOnly,
		labelClass,
		customClass,
		style,
	} = props;

	const [touched, setTouched] = useState(false);

	const modifiedClass = `${className} form-group `;
	const phoneStrip =
		value && value.substring(0, 2) === '+1' ? value.substring(2) : value;

	return (
		<div className={modifiedClass}>
			<label htmlFor={id} className={labelClass || 'form-font'}>
				{label}
			</label>
			<NumberFormat
				readOnly={readOnly}
				id={id}
				value={phoneStrip}
				className={
					touched &&
					((isRequired && !value && !isNil(value)) ||
						(value && value.length < 10))
						? 'w-100 number-input is-invalid-input form-control'
						: 'w-100 number-input form-control' + ` ${customClass}`
				}
				placeholder={placeholder}
				onValueChange={(values) => handleInput(id, values)}
				format="(###) ###-####"
				mask=""
				style={style}
				onBlur={() => setTouched(true)}
			/>
			{touched && isRequired && !value && !isNil(value) && (
				<span className="form-error">Field is required</span>
			)}
			{touched && value && value.length < 10 && (
				<span className="form-error">Enter at least 10 digits</span>
			)}
		</div>
	);
};

PhoneNumberInput.propTypes = {
	handleInput: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	isRequired: PropTypes.bool,
	readOnly: PropTypes.bool,
};

export default PhoneNumberInput;
