import PropTypes from 'prop-types';

import React from 'react';
import Input from 'react-validation/build/input';

/**
 *
 * <TextInput />, a functional component for TextInput
 */
const TextInput = (props) => {
	const {
		label,
		placeholder,
		handleInput,
		className,
		id,
		value,
		validations,
		type,
		readOnly,
		maxLength,
		minLength,
		error,
		style,
	} = props;

	const modifiedClass = `${className} form-group`;
	const errorStyle = 'form-error';

	return (
		<div className={modifiedClass}>
			<label htmlFor={id} className="form-font">
				{label}
			</label>
			<Input
				maxLength={maxLength}
				minLength={minLength}
				value={value}
				type={type}
				name={id}
				id={id}
				className="form-control text-muted"
				placeholder={placeholder}
				onChange={handleInput}
				validations={validations}
				readOnly={readOnly}
				style={style}
			/>
			<span className={errorStyle}>{error}</span>
		</div>
	);
};

TextInput.propTypes = {
	handleInput: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	validations: PropTypes.array,
	type: PropTypes.string,
	readOnly: PropTypes.bool,
	maxLength: PropTypes.any,
	minLength: PropTypes.any,
};

TextInput.defaultProps = {
	type: 'text',
};

export default TextInput;
