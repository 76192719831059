import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

const selectRouter = (state) => state.get('router');
const selectApp = (state) => state.get('app');

const makeSelectLocation = () =>
	createSelector(selectRouter, (routerState) =>
		returnJS(routerState.get('location')),
	);

const makeSelectRoleFromLocation = () =>
	createSelector(selectRouter, (routerState) => {
		const location = returnJS(routerState.get('location'));
		if (new RegExp(/^\/admin/).test(location.pathname)) {
			return 'admin';
		}
		if (new RegExp(/^\/clinician/).test(location.pathname)) {
			return 'clinician';
		}
		if (new RegExp(/^\/patient/).test(location.pathname)) {
			return 'patient';
		}
		return undefined;
	});

const makeSelectPharmacies = () =>
	createSelector(selectApp, (substate) => returnJS(substate.get('pharmacies')));

const makeSelectAuth = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('auth')));

const makeSelectToastMessage = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('toastMessage')),
	);

const makeSelectStates = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('states')));

const makeSelectUserId = () =>
	createSelector(selectApp, (substate) =>
		returnJS(substate.getIn(['auth', 'loggedInUser', 'id'])),
	);

const makeSelectEnrollReq = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('enrollReq')));

const makeSelectDocumentList = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('documentList')),
	);

const makeSelectLoadingDocuments = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('loadingDocuments')),
	);

const makeSelectRequestStatus = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('requestSuccessStatus')),
	);

const makeSelectMemberReq = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('memberReq')));

const makeSelectClinicians = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('clinicians')));

const makeSelectCities = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('cities')));

const makeSelectZipcodes = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('zipcodes')));

const makeSelectIsFetchingEnrollReq = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('isFetchingEnrollReq')),
	);

const makeSelectIsFetchingMemberReq = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('isFetchingMemberReq')),
	);

const makeSelectIsUpdatingRequest = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('isUpdatingRequest')),
	);

const makeSelectServerError = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('serverError')),
	);

const makeSelectServerVersion = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('version')));

const makeSelectIsMobile = () =>
	createSelector(selectApp, (appState) => returnJS(appState.get('isMobile')));

const makeSelectDownloadError = () =>
	createSelector(selectApp, (appState) =>
		returnJS(appState.get('downloadError')),
	);
const makeSelectFile = () =>
	createSelector(selectApp, (substate) => returnJS(substate.get('file')));
const makeSelectIsLoadingFile = () =>
	createSelector(selectApp, (substate) =>
		returnJS(substate.get('isLoadingFile')),
	);
const makeSelectError = () =>
	createSelector(selectApp, (substate) => returnJS(substate.get('error')));

export {
	makeSelectLocation,
	makeSelectAuth,
	makeSelectRoleFromLocation,
	makeSelectToastMessage,
	makeSelectPharmacies,
	makeSelectStates,
	makeSelectCities,
	makeSelectZipcodes,
	makeSelectEnrollReq,
	makeSelectUserId,
	makeSelectMemberReq,
	makeSelectClinicians,
	makeSelectRequestStatus,
	makeSelectDocumentList,
	makeSelectLoadingDocuments,
	makeSelectIsFetchingEnrollReq,
	makeSelectIsFetchingMemberReq,
	makeSelectServerError,
	makeSelectIsMobile,
	makeSelectServerVersion,
	makeSelectIsUpdatingRequest,
	makeSelectDownloadError,
	makeSelectFile,
	makeSelectIsLoadingFile,
	makeSelectError,
};
