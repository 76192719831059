import * as Yup from 'yup';

const CARD = 'credit card';
const BANK = 'bank';

export const PAYMENT_TYPES = {
	CARD,
	BANK,
};
export const paymentOptions = [
	{ label: 'Credit Card', value: true },
	{ label: 'Bank Account', value: false },
];

export const defaultPaymentType = PAYMENT_TYPES.CARD;

export const accountHolderTypes = ['Personal', 'Business'];
export const accountTypes = ['Checking', 'Savings'];

export const initialPaymentValues = {
	userInfo: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
	},

	isCCForm: true,

	bankDetails: {
		bankName: '',
		accountType: accountTypes[0],
		accountHolderType: accountHolderTypes[0],
	},

	address: {
		street: '',
		city: '',
		state: '',
		zipcode: '',
	},
};

const REQUIRED_ERROR = 'Field is required';
export const requiredAddressSchema = Yup.object().shape({
	street: Yup.string().required(REQUIRED_ERROR),
	city: Yup.string().required(REQUIRED_ERROR),
	state: Yup.string().required(REQUIRED_ERROR),
	zipcode: Yup.string().required(REQUIRED_ERROR),
});

export const PatientPaymentValidation = Yup.object().shape({
	isCCForm: Yup.boolean(),

	userInfo: Yup.object().when('isCCForm', {
		is: true,
		then: Yup.object().shape({
			firstName: Yup.string().required(REQUIRED_ERROR),
			lastName: Yup.string().required(REQUIRED_ERROR),
			phone: Yup.string().required(REQUIRED_ERROR),
			email: Yup.string().required(REQUIRED_ERROR),
		}),
		otherwise: Yup.object().shape({
			phone: Yup.string().required(REQUIRED_ERROR),
			email: Yup.string().required(REQUIRED_ERROR),
		}),
	}),

	bankDetails: Yup.object().when('isCCForm', {
		is: false,
		then: Yup.object().shape({
			bankName: Yup.string().required(REQUIRED_ERROR),
		}),
	}),

	address: requiredAddressSchema,
});

export const GuarantorValidation = Yup.object().shape({
	guarantor: Yup.object().shape({
		name: Yup.string().required(REQUIRED_ERROR),
		email: Yup.string()
			.email('Please enter a vaild email address')
			.required(REQUIRED_ERROR),
	}),
});

export const dummyData = {
	cardNumber: '4242424242424242',
	cardExpirationMonth: '02',
	cardExpirationYear: '25',
	billingZipcode: '19426',
	cardName: 'Mary Sue',
	cardEmail: 'test@test.com',
	cardType: 'Visa',
	cardPhone: '5551231231',
	bankDetails: {
		bankName: 'First Union',
		accountNumber: '123123123',
		routingNumber: '123123123',
		holderName: 'Bob Smith',
		holderEmail: 'test@test.com',
		holderPhone: '5551231212',
		accountType: 'Checking',
		accountHolderType: 'Personal',
	},
};
