import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
	patientRegistrationAPI,
	patientRegistrationFetchDataAPI,
} from 'utils/api';

import { error, success } from './actions';
import { FETCH_DATA, PATIENT_REGISTRATION } from './constants';
import makeSelectPatientRegister from './selectors';

export function* performRegistration() {
	const patientRegister = yield select(makeSelectPatientRegister());

	try {
		const response = yield call(patientRegistrationAPI, patientRegister);
		yield put(success(response));
	} catch (err) {
		yield put(error());
	}
}

export function* performFetchData() {
	const patientRegister = yield select(makeSelectPatientRegister());
	try {
		const response = yield call(
			patientRegistrationFetchDataAPI,
			patientRegister,
		);
		yield put(success(response));
	} catch (err) {
		yield put(error());
	}
}

export default function* patientRegisterSaga() {
	yield takeLatest(PATIENT_REGISTRATION, performRegistration);
	yield takeLatest(FETCH_DATA, performFetchData);
}
