import PropTypes from 'prop-types';

import React from 'react';
import { NavLink } from 'react-router-dom';

import adminFeatures from 'accessible-features/admin';
import clinicianFeatures from 'accessible-features/clinician';
import patientFeatures from 'accessible-features/patient';
import routes from 'accessible-features/routes';
import NotificationDropdown from 'containers/Notifications';
import UserDropDown from 'containers/UserDropDown';
import imgLogo from 'images/logo_header.svg';
import { isProduction } from 'utils/constants';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class CommonHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedTab: null,
			headerObject: [],
		};
	}

	changeActive = (pathName) => {
		routes.map((value) =>
			pathName === value.pathName
				? this.setState({ selectedTab: value.tabName })
				: null,
		);
	};

	setFeatures() {
		const { type, routeProps } = this.props;

		// let headerObject = {};
		if (type === 'admin') {
			this.setState({ headerObject: adminFeatures });
		}
		if (type === 'clinician') {
			this.setState({ headerObject: clinicianFeatures });
		}

		if (type === 'patient') {
			this.setState({ headerObject: patientFeatures });
		}

		this.changeActive(routeProps.location.pathname);
	}

	componentDidMount() {
		this.setFeatures();
	}

	componentDidUpdate(prevProps) {
		const { routeProps } = this.props;

		if (
			prevProps.routeProps.location.pathname !== routeProps.location.pathname
		) {
			this.changeActive(routeProps.location.pathname);
		}
	}

	UNSAFE_componentWillReceiveProps() {
		/* if (this.props.type !== nextProps.type) {
      this.setFeatures();
    } */
	}

	imageStyle = () => {
		const { isMobile } = this.props;

		let style;

		if (isMobile) {
			style = {
				left: '50%',
				transform: 'translate(-50%, -50%)',
			};
		} else {
			style = {
				left: '2rem',
				top: '50%',
				transform: 'translate(0%, -50%)',
			};
		}

		return {
			...style,
			top: '50%',
			position: 'absolute',
			transition: 'all 0.3s ease-in-out',
		};
	};

	render() {
		const { selectedTab, headerObject } = this.state;
		const { user, name, isMobile, height } = this.props;

		return (
			<nav
				className="navbar nav-shadow navbar-expand-md navbar-light bg-white text-dark px-0 pb-0"
				style={{ height }}
			>
				<div className="container-fluid pl-3 pr-3">
					<div className={isMobile ? 'w-100' : ''}>
						<img style={this.imageStyle()} src={imgLogo} alt="Mind Therapy" />
						{!isProduction && (
							<div
								style={{
									color: 'red',
									position: 'absolute',
									left: isMobile ? 10 : 20,
									top: isMobile ? 20 : 64,
								}}
							>
								DEV SERVER
							</div>
						)}
					</div>
					{!isMobile && (
						<div className="collapse navbar-collapse w-100">
							<div className="mx-auto">
								{!name ? (
									<ul className="navbar-nav">
										{headerObject.map((tab) => (
											<li key={tab.tabId} className="nav-item">
												<NavLink
													className="nav-link text-center mtc-nav header-text"
													to={tab.to}
													onClick={() => this.changeActive(tab.tabId)}
													activeClassName="active"
												>
													{selectedTab === tab.tabId
														? tab.tabIconActive
														: tab.tabIconInactive}

													<span className="d-block mt-1 font-12">
														{tab.tabName}
													</span>
												</NavLink>
											</li>
										))}
									</ul>
								) : (
									<ul className="nav-bar nav mx-auto" />
								)}
							</div>
						</div>
					)}
					<ul
						className="row"
						style={{
							position: 'absolute',
							top: isMobile ? '2px' : '1rem',
							right: isMobile ? '0rem' : '0.25rem',
						}}
					>
						{user.onboardingComplete && (
							<div className="mt-2">
								<NotificationDropdown />
							</div>
						)}
						<UserDropDown user={user} isMobile={isMobile} />
					</ul>
				</div>
			</nav>
		);
	}
}

CommonHeader.propTypes = {
	user: PropTypes.object,
	routeProps: PropTypes.object,
	name: PropTypes.string,
};

export default CommonHeader;
