import { call, put, takeLatest } from 'redux-saga/effects';

import { feedbackApi } from 'utils/api';

import { submitFeedbackError, submitFeedbackSuccess } from './actions';
import { SUBMIT_FEEDBACK } from './constants';

export default function* feedbackFormSaga() {
	yield takeLatest(SUBMIT_FEEDBACK, submitFeedback);
}

export function* submitFeedback(data) {
	try {
		const response = yield call(feedbackApi, data.payload);
		if (response.response && response.response.status >= 400) {
			yield put(submitFeedbackError());
		} else {
			yield put(submitFeedbackSuccess());
		}
	} catch (err) {
		yield put(submitFeedbackError(err));
	}
}
