import { uniqBy } from 'lodash';

import { useEffect, useState } from 'react';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import DeleteButton from 'atoms/DeleteButton';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import iconFormsActive from 'images/ic_forms_active.svg';

const UploadFormModal = (props) => {
	const { isOpen, toggle, uploadFiles, isUploading } = props;
	const [files, setFiles] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
	const [dragError, setDragError] = useState('');
	const [dragCounter, setDragCounter] = useState(0);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		if (uploading && !isUploading) {
			setUploading(false);
			toggle();
		}
	}, [uploading, setUploading, isUploading]);

	useEffect(() => {
		if (dragCounter <= 0 && isDragging) {
			setIsDragging(false);
			setDragError('');
		}
	}, [dragCounter, isDragging, setIsDragging]);

	const handleChange = (e) => {
		addFiles(e.target.files);
	};

	const addFiles = (filesToAdd) => {
		const newFiles = Array.from(files);

		for (const file of filesToAdd) {
			const split = file.name.split('.');
			const last = split[split.length - 1];

			if (last === 'pdf') {
				newFiles.push(file);
			}
		}

		/* for (const file of newFiles) {
      const { value } = formTypeOptions[formTypeOptions.length - 1];
      Object.defineProperty(file, 'docType', {
        value,
        writable: false
      })
    } */

		setFiles(uniqBy(newFiles), 'name');
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setDragCounter(dragCounter + 1);
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setIsDragging(true);
			for (const item of e.dataTransfer.items) {
				const { type } = item;
				let accepted = false;
				if (type.includes('pdf')) {
					accepted = true;
				}

				if (!accepted) {
					setDragError('Must be a .pdf');
				}
			}
		}
	};

	const handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setDragCounter(dragCounter - 1);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			addFiles(e.dataTransfer.files);
			e.dataTransfer.clearData();
			setDragCounter(0);
		}
	};

	const removeFile = (name) => {
		let newFiles = files;
		newFiles = newFiles.filter((x) => x.name !== name);
		setFiles(newFiles);
	};

	/* const updateType = (i, type) => {
    let newFiles = Array.from(files);
    Object.defineProperty(newFiles[i], 'type', { value: type, writable: false });
    setFiles(newFiles);
  } */

	const doUpload = () => {
		setUploading(true);
		uploadFiles(files);
	};

	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal-dialog-centered"
				backdrop="static"
				style={{
					maxWidth: 600,
				}}
			>
				<ModalHeader toggle={toggle} className="px-4 border-0 container-fluid">
					<div>Upload Form{files.length > 1 ? 's' : ''}</div>
				</ModalHeader>
				<ModalBody className="px-4 pt-1">
					<div
						className="p-3 mb-3"
						id="drop-zone"
						style={{
							border: `1px ${
								isDragging ? 'dashed' : 'solid'
							} var(--main-color)`,
							borderRadius: 5,
							position: 'relative',
						}}
						onDrop={handleDrop}
						onDragOver={handleDrag}
						onDragLeave={handleDragOut}
						onDragEnter={handleDragIn}
					>
						{isDragging && (
							<div
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									background: 'rgba(255, 255, 255, 0.9)',
									zIndex: 9999,
								}}
							>
								<div
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										cursor: dragError ? 'not-allowed' : 'auto',
										color: dragError ? 'var(--main-alert)' : 'black',
										width: '100%',
										textAlign: 'center',
									}}
								>
									{dragError || 'Drop here'}
								</div>
							</div>
						)}
						{files.length === 0 ? (
							<div>Please add forms below or drop them into this box</div>
						) : (
							<div>
								{files.map((f, i) => (
									<>
										<div className="row mx-0 justify-content-start">
											<div className="col-1">
												<img className="mr-3" src={iconFormsActive} alt="" />
											</div>
											{/* <div className="col-4">
                      <select onChange={e => updateType(i, e.target.value)}>
                        {formTypeOptions.map(o => (
                          <option 
                            value={o.value} 
                            selected={o.value === f.type}
                          >
                            {o.label}
                          </option>
                        ))}
                      </select>
                    </div> */}
											<div
												className="col-auto"
												style={{
													marginTop: 3,
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													width: 400,
												}}
											>
												{f.name}
											</div>
											<div className="col-auto">
												<DeleteButton
													popupText={'Remove File'}
													onClick={() => removeFile(f.name)}
													styles={{
														popup: { container: { width: 140 } },
													}}
												/>
											</div>
										</div>
										{i < files.length - 1 && <hr />}
									</>
								))}
							</div>
						)}
					</div>
					<input
						type="file"
						id="upload-file"
						name="forms"
						onChange={handleChange}
						accept=".pdf"
						disabled={files.length === 10}
						multiple
						hidden
					/>
					<label
						htmlFor="upload-file"
						className="btn btn-primary bg-white link-color border py-2 font-14 mb-0"
						style={{ height: 45 }}
					>
						Browse...
					</label>
					<button
						disabled={files.length === 0 || isUploading}
						className="btn btn-primary btn-color font-14 mx-3"
						style={{ height: 45, minWidth: 140 }}
						onClick={doUpload}
					>
						{isUploading ? (
							<SmallLoadingIndicator
								style={{
									width: '20px',
									height: '20px',
									marginTop: '0.25rem !important',
								}}
								className="my-0"
							/>
						) : files.length === 0 ? (
							'No Forms Selected'
						) : (
							`Upload Form${files.length > 1 ? 's' : ''}`
						)}
					</button>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default UploadFormModal;
