import { delay } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { loadClients } from 'containers/Admin/actions';
import { generateToast } from 'containers/App/actions';
import { toastOptions } from 'utils/toast';

import {
	error,
	passwordVerified,
	removePasswordError,
	showPasswordError,
	toggleEmail,
	togglePassword,
	togglePhone,
	toggleSettings,
	updateAuth,
	updateMultiError,
	updateMultiSuccess,
	updateUsers,
} from './actions';
import {
	listUsersAPI,
	updatePasswordAPI,
	updatePasswordOthersAPI,
	updateUserAPI,
	updateUserMultiAPI,
	verifyPasswordAPI,
} from './api';
import {
	LIST_USERS,
	LOGOUT,
	PASSWORD_ERROR,
	UPDATE_EMAIL,
	UPDATE_MULTI,
	UPDATE_NAME,
	UPDATE_PASSWORD,
	UPDATE_PASSWORD_OTHERS,
	UPDATE_PHONE,
	VERIFY_PASSWORD,
} from './constants';

export function* performLogout() {
	try {
		// This saga is used only for making a call to the backend for logging purposes.
	} catch (err) {
		yield put(error(err));
	}
}

export function* performVerifyPassword(action) {
	const { data } = action;

	try {
		const response = yield call(verifyPasswordAPI, data);

		if (response.status === 200) {
			yield put(passwordVerified());
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showPasswordError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performUpdateName(action) {
	const { data } = action;

	try {
		const response = yield call(updateUserAPI, data);

		if (response.status === 200) {
			yield put(toggleSettings());
			const auth = JSON.parse(localStorage.getItem('auth'));
			auth.loggedInUser.name = response.data.message.name;
			localStorage.setItem('auth', JSON.stringify(auth));
			yield put(
				generateToast({ type: 'success', text: 'Name Updated Successfully' }),
			);
			yield put(updateAuth(auth));
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performUpdateEmail(action) {
	const { data } = action;

	try {
		const response = yield call(updateUserAPI, data);

		if (response.status === 200) {
			yield put(toggleEmail());
			const auth = JSON.parse(localStorage.getItem('auth'));
			auth.loggedInUser.email = response.data.message.email;
			localStorage.setItem('auth', JSON.stringify(auth));
			yield put(
				generateToast({ type: 'success', text: 'Email Updated Successfully' }),
			);
			yield put(updateAuth(auth));
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performUpdatePhone(action) {
	const { data } = action;

	try {
		const response = yield call(updateUserAPI, data);

		if (response.status === 200) {
			yield put(togglePhone());
			yield put(
				generateToast({
					type: 'success',
					text: 'Phone Number Updated Successfully',
				}),
			);
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showPasswordError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performUpdatePassword(action) {
	const { data } = action;

	try {
		const response = yield call(updatePasswordAPI, data);

		if (response.status === 200) {
			yield put(togglePassword());
			yield put(
				generateToast({
					type: 'success',
					text: 'Password Updated Successfully',
				}),
			);
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showPasswordError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performUpdatePasswordOthers(action) {
	const { data } = action;

	try {
		const response = yield call(updatePasswordOthersAPI, data);

		if (response.status === 200) {
			yield put(togglePassword());
			yield put(
				generateToast({
					text: 'Password Updated Successfully',
					position: 'bottom-left',
					options: toastOptions,
				}),
			);
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(showPasswordError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performUpdateMulti(action) {
	const { data } = action;

	try {
		const response = yield call(updateUserMultiAPI, data);

		if (response.status === 200) {
			yield put(updateMultiSuccess(response));
			yield put(loadClients());
			yield put(
				generateToast({ type: 'success', text: 'Users updated successfully!' }),
			);
		}
	} catch (err) {
		if (err.response.status === 400) {
			yield put(updateMultiError(err.response.data.error));
		} else {
			yield put(error(err));
		}
	}
}

export function* performListUsers(action) {
	const { data } = action;

	try {
		const response = yield call(listUsersAPI, data);

		if (response.status === 200) {
			yield put(updateUsers(response.data));
		}
	} catch (err) {
		yield put(error(err));
	}
}

export function* performPasswordError() {
	try {
		yield delay(5000);
		yield put(removePasswordError(null));
	} catch (err) {
		yield put(error(err));
	}
}

export default function* userDropDownSaga() {
	yield takeLatest(LOGOUT, performLogout);
	yield takeLatest(VERIFY_PASSWORD, performVerifyPassword);
	yield takeLatest(UPDATE_EMAIL, performUpdateEmail);
	yield takeLatest(UPDATE_NAME, performUpdateName);
	yield takeLatest(UPDATE_PHONE, performUpdatePhone);
	yield takeLatest(UPDATE_PASSWORD, performUpdatePassword);
	yield takeLatest(UPDATE_PASSWORD_OTHERS, performUpdatePasswordOthers);
	yield takeLatest(LIST_USERS, performListUsers);
	yield takeLatest(PASSWORD_ERROR, performPasswordError);
	yield takeLatest(UPDATE_MULTI, performUpdateMulti);
}
