import PropTypes from 'prop-types';

import { Component, createRef } from 'react';
import { Progress } from 'reactstrap';

import { ketamineTabs, groupTabs } from './tabs';

const EmptyComponent = () => <div></div>;

class EnrollmentAgreementTabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentProgress: 0,
			steps: [{ component: EmptyComponent }],
			checks: {},
			siteUrl: '',
		};

		this.scrollRef = createRef();
	}

	componentDidMount = () => {
		const { data } = this.props;
		const showGroupStandards = data.groupsData.some(group => group.groupSize == 'Group');
		const showKetamineConsent = data.groupsData.some(group => group.groupSize == 'Individual');
		
		let steps = [];
		if(showGroupStandards) steps = [...groupTabs];
		if(showKetamineConsent) steps = [...steps, ...ketamineTabs] ;
		this.setState({ steps });
	}

	componentDidUpdate = (_prevProps, prevState) => {
		if (prevState.currentProgress !== this.state.currentProgress) {
			this.props.scrollTop();
		}

	}

	handleAcceptance = (key) => {
		const value = this.state.checks[key];
		this.setState({ checks: { ...this.state.checks, [key]: !value } });
	};

	nextProgress = () => () => {
		const { currentProgress } = this.state;
		const { submitRequest } = this.props;
		const { data } = this.props;

		if (currentProgress === this.state.steps.length - 1) {
			submitRequest(data);
			return;
		}
		const newCurrentProgress = currentProgress + 1;
		this.setState({ currentProgress: newCurrentProgress });
	};

	prevProgress = () => {
		const { currentProgress } = this.state;

		if (currentProgress > 0) {
			const newCurrentProgress = currentProgress - 1;
			this.setState({ currentProgress: newCurrentProgress });
		}
	};

	render() {
		const {
			isMobile,
		} = this.props;

		
		const containerStyle = {};

		if (isMobile) {
			containerStyle.overflowY = 'auto';
			containerStyle.maxHeight =
				window.innerHeight -
				205 -
				(this.state.currentProgress > 2 && this.state.currentProgress !== 5 ? 36 : 0);
		}

		const { component: Component, checkName } = this.state.steps[this.state.currentProgress];
		const props = {
            handleAcceptance: this.handleAcceptance,
            nextProgress: this.nextProgress,
            prevProgress: this.prevProgress,
			name: this.props.auth.loggedInUser.name,
			data: this.props.data,
			reqDate: new Date(),
			isMobile,
        };
        props[checkName] = this.state.checks[checkName];

		return (
			<div>
				<div
					className="py-2 mt-2"
					style={{
						position: 'sticky',
						top: 0,
						background: 'white',
						zIndex: 500,
					}}
				>
					<Progress
						className="prog mx-2"
						color="success"
						value={((this.state.currentProgress + 1) / this.state.steps.length) * 100}
					/>
				</div>
				<div className={`container-fluid ${isMobile && 'px-1'}`}>
					<div className="position-relative">
						<div
							className={`container ${isMobile && 'px-1 border'}`}
							style={containerStyle}
						>
							<Component {...props}/>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

EnrollmentAgreementTabs.propTypes = {
	auth: PropTypes.object,
	data: PropTypes.object,
	submitRequest: PropTypes.func,
};

export default EnrollmentAgreementTabs;
