import PropTypes from 'prop-types';

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import imageAttachment from 'images/ic_attachment.svg';
import iconChecked from 'images/ic_checked.svg';

import './index.css';

const documentsList = [
	'IntakeForm',
	'Registration Form',
	'Financial Agreement',
	'Payment Agreement',
	'Therapeutic Group Enrollment',
	'Request For Information',
	'Group Standards',
	'Group Therapy Consentation',
	'Behavioral Guidelines',
];

/* eslint-disable react/prefer-stateless-function */
class PatientInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			contactRequestor: false,
		};
	}

	toggle = () => {
		const { modal } = this.state;

		this.setState({ modal: !modal });
	};

	showContactRequestor = () => {
		this.setState({ contactRequestor: true });
	};

	hideContactRequestor = () => {
		this.setState({ contactRequestor: false });
	};

	render() {
		const { hide } = this.props;
		const { contactRequestor } = this.state;

		return (
			<div>
				{contactRequestor ? (
					<Modal
						isOpen={this.state.modal}
						onClosed={this.showPatientInfo}
						toggle={this.hideContactRequestor}
					>
						<ModalHeader toggle={this.hideContactRequestor}>
							Contact Requestor
						</ModalHeader>
						<ModalBody>
							<div className="container co-font">
								<span className="text-muted form-font">REQUESTED DATE</span>
								<div className="container-fluid p-0 mb-3">
									<DatePicker
										inline
										selected={this.state.startDate}
										onChange={this.handleChange}
									/>
								</div>

								<span className="text-muted form-font">NOTES</span>
								<textarea
									type="text"
									className="form-control"
									rows="8"
									placeholder="Some notes goes here."
								/>
								<button
									type="button"
									className="btn btn-lg btn-block btn-primary btn-color mt-3"
								>
									SUBMIT
								</button>
							</div>
						</ModalBody>
					</Modal>
				) : (
					<Modal className="my-modal" isOpen={this.state.modal} toggle={hide}>
						<ModalHeader toggle={hide} />
						<ModalBody>
							<div className="container-fluid co-font">
								<div className="d-flex">
									<div className="col-5 d-flex flex-column p-0">
										<div className="d-flex align-items-center">
											<i className="fas fa-user-circle fa-6x" />
										</div>
										<div className="d-flex flex-column ml-2">
											<h1 className="text-black m-0">Mellanie Jossy</h1>
											<p className="text-muted m-0">San Francisco</p>
										</div>
										<hr />
										{documentsList.map((value) => (
											<div className="d-flex" key={value}>
												<div className="col-2 p-0">
													<img src={imageAttachment} alt="" />
												</div>
												<div className="col-8 p-0">
													<p className="m-0 link-color text-truncate">
														{value}
													</p>
												</div>
												<div className="col-2 p-0">
													<img src={iconChecked} alt="" />
												</div>
											</div>
										))}
									</div>
									<div className="col-7 d-flex flex-column">
										<span className="mb-3 d-flex"> DEMOGRAPHIC </span>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Age</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">24</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Gender</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Female</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Birth Date</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">19 October 1994</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Marital Status</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Single</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Address</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">56, Old Hyde Road, San Jose</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Phone Number</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">(203) 298 2198</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Guardian</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Mennie Jossy (Aunt)</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Insurance</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Aetna</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Referrer</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Day 1 Rehabilitation Center</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Assigned Clinician</span>
											</div>
											<div className="col-6 d-flex align-items-center p-0">
												<i className="fas fa-user-circle fa-2x mr-2" />
												<p className="link-color mb-0">Dr. Jay Caroll</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Client Type</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">Outpatient</p>
											</div>
										</div>
										<div className="d-flex">
											<div className="col-6 p-0">
												<span>Intake Date</span>
											</div>
											<div className="col-6 p-0">
												<p className="mb-1">4 January 2019</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className="container co-font">
								<span className="font-weight-bold">
									Group Enrollment Registration
								</span>
								<div className="d-flex">
									<span>Date of Requests: </span>
									<p className="m-0">9 January 2019</p>
								</div>
								<div className="d-flex">
									<span>Notes: </span>
									<p className="m-0">
										Registered for 2 months, reasons of why patient is
										registered to be displayed here.{' '}
									</p>
								</div>
							</div>
							<div className="container">
								<span className="font-weight-bold">Contact Requestor</span>
								<div className="d-flex">
									<span>Date Responded: </span>
									<p className="m-0">9 Februray 2019</p>
								</div>
								<div className="d-flex">
									<span>Notes: </span>
									<p className="m-0">Some notes goes here.</p>
								</div>
							</div>
							<div className="container d-flex mt-5">
								<div className="col-6 p-0">
									<span className="font-weight-bold">OK&apos;d BY</span>
									<div className="d-flex">
										<i className="fas fa-user-circle fa-2x" />
										<i className="fas fa-user-circle fa-2x mlm" />
										<i className="fas fa-user-circle fa-2x mlm" />
									</div>
								</div>
								<div className="col-6 p-0">
									<span className="font-weight-bold">GROUPS ENROLLED</span>
									<p className="link-color m-0">
										Cognitive Behavioral Therapy, Individual Therapy
									</p>
								</div>
							</div>
							<div className="container d-flex justify-content-end mt-3">
								<button
									type="button"
									className="btn btn-white link-color ml-2 border"
									onClick={this.showContactRequestor}
								>
									CONTACT REQUESTOR
								</button>
								<button
									type="button"
									className="btn btn-primary btn-color ml-3"
								>
									APPROVE
								</button>
							</div>
						</ModalBody>
					</Modal>
				)}
			</div>
		);
	}
}

PatientInfo.propTypes = {
	hide: PropTypes.func,
};

export default PatientInfo;
