export const releaseChecks = [
	{ label: 'Evaluation', value: 'evalChecked' },
	{ label: 'Lab Results', value: 'labResChecked' },
	{ label: 'Progress Reports', value: 'progRepChecked' },
	{ label: 'Medication Info', value: 'medInfoChecked' },
	{ label: 'Testing Results', value: 'testResChecked' },
	{ label: 'Diagnosis', value: 'diagnChecked' },
	{ label: 'Summary Reports', value: 'sumRepChecked' },
	{ label: 'Entire Record', value: 'entRecChecked' },
];

export const purposeChecks = [
	{ label: 'Planning Treatment', value: 'planTreatChecked' },
	{ label: 'Continuing Treatment', value: 'contTreatChecked' },
	{ label: 'Case Review', value: 'caseRevChecked' },
	{ label: 'Update File', value: 'updateFileChecked' },
];
