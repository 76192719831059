/**
 *
 * GeoDropdown
 *
 */
import PropTypes from 'prop-types';

import React from 'react';

import SelectAutoComplete from 'atoms/SelectAutoComplete';

// import styled from 'styled-components';

const GeoDropdown = (props) => {
	let { state, city, zipcode } = props;
	const {
		handleChange,
		states,
		cities,
		zipcodes,
		isRequired,
		zipCodeNotRequired,
		error = { city: '', state: '', zipcode: '' },
	} = props;

	const errorStyle = 'form-error';

	let cityRequired = isRequired;
	let stateRequired = isRequired;
	let zipcodeRequired = isRequired;
	if (typeof isRequired === 'object') {
		for (let type of isRequired) {
			switch (type) {
				case 'city':
					cityRequired = true;
					break;
				case 'state':
					stateRequired = true;
					break;
				case 'zipcode':
					zipcodeRequired = true;
					break;
				default:
					break;
			}
		}
	}

	if (state && !state.value) state = null;
	if (city && !city.value) city = null;
	if (zipcode && !zipcode.value) zipcode = null;
	const className = zipCodeNotRequired
		? 'col-md-6 form-group'
		: 'col-md-4 form-group';
	return (
		<div>
			<div className="form-row">
				<div className={className}>
					<label htmlFor="state" className={'form-font'}>
						{stateRequired ? 'STATE*' : 'STATE'}
					</label>
					<SelectAutoComplete
						controlStyles={{
							borderColor: error.state ? '#e99083 !important' : '#ced4da',
						}}
						handleChange={handleChange('state')}
						options={states || []}
						value={state}
						placeholder="Select State"
					/>
					<span className={errorStyle}>{error.state}</span>
				</div>

				<div className={className}>
					<label htmlFor="city" className={'form-font'}>
						{cityRequired ? 'CITY*' : 'CITY'}
					</label>
					<SelectAutoComplete
						controlStyles={{
							borderColor: error.city ? '#e99083 !important' : '#ced4da',
						}}
						handleChange={handleChange('city')}
						options={cities || []}
						value={city}
						placeholder="Select City"
					/>
					<span className={errorStyle}>{error.city}</span>
				</div>
				{!zipCodeNotRequired && (
					<div className={className}>
						<label htmlFor="zipcode" className={'form-font'}>
							{zipcodeRequired ? 'ZIP CODE*' : 'ZIP CODE'}
						</label>
						<SelectAutoComplete
							controlStyles={{
								borderColor: error.zipcode ? '#e99083 !important' : '#ced4da',
							}}
							handleChange={handleChange('zipcode')}
							options={zipcodes || []}
							value={zipcode}
							placeholder="Zip Code"
						/>
						<span className={errorStyle}>{error.zipcode}</span>
					</div>
				)}
			</div>
		</div>
	);
};

GeoDropdown.propTypes = {
	state: PropTypes.string.isRequired,
	city: PropTypes.string,
	zipcode: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	states: PropTypes.array.isRequired,
	cities: PropTypes.array.isRequired,
	zipcodes: PropTypes.array.isRequired,
	isRequired: PropTypes.bool.isRequired,
	zipCodeNotRequired: PropTypes.bool,
};

export default GeoDropdown;
