import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

import React, {
	useCallback,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';

import ActionButton from 'atoms/ActionButton';
import DeleteButton from 'atoms/DeleteButton';
import {
	ContextMenuButton,
	PageInput,
	ToolbarButton,
} from 'atoms/PdfViewer/style';
import { signatureDims } from 'components/AddSignatures/constants';
import addImage from 'svgs/add';
import helpImage from 'svgs/help';
import { getLogger } from 'utils/logger';
import { pointInRect } from 'utils/math';

const logger = getLogger('atoms.PdfEditor');

/**
 * Holds information for the state of the mouse
 * @typedef {Object<string, any>} MouseState
 * @property {number} x The mouse's x coodrinate on the canvas
 * @property {number} y The mouse's y coodrinate on the canvas
 * @property {Signature}  hover The currently hovered signature
 * @property {Signature} select The currently selected signature
 * @property {string} cursor The CSS cursor style
 */
const mouseInitState = {
	x: 0,
	y: 0,
	hover: null,
	select: null,
	cursor: 'auto',
};

/**
 * Handles processing mouse actions
 * @param {MouseState} state The current mouse state
 * @param {Object} action The action being processed
 * @returns {MouseState} The new mouse state
 */
const mouseReducer = (state, action) => {
	switch (action.type) {
		case 'set_pos':
			return {
				...state,
				x: Math.floor(action.x),
				y: Math.floor(action.y),
			};
		case 'set_select':
			return {
				...state,
				select: action.select,
			};
		case 'set_hover':
			return {
				...state,
				hover: action.hover,
			};
		case 'set_cursor':
			return {
				...state,
				cursor: action.cursor,
			};
		default:
			return state;
	}
};

/**
 * Holds information for the state of the contextmenu
 * @typedef {Object<string, any>} ContextMenuState
 * @property {number} x The menu's x coodrinate on the canvas
 * @property {number} y The menu's y coodrinate on the canvas
 * @property {boolean} show Flag for showing
 * @property {Signature} entity Currently selected signature
 */
const contextMenuInitState = {
	x: 0,
	y: 0,
	show: false,
	entity: null,
};

/**
 * Handles processing Context Menu actions
 * @param {ContextMenuState} state The current state of the menu
 * @param {Object} action The action being processed
 * @returns {ContextMenuState} The new state of the menu
 */
const contextMenuReducer = (state, action) => {
	switch (action.type) {
		case 'open':
			return {
				...state,
				show: true,
				x: action.x,
				y: action.y,
				entity: action.entity,
			};
		case 'close':
			return {
				...state,
				show: false,
				entity: null,
			};
		default:
			return state;
	}
};

/**
 *
 * @param {{
 *  file: string,
 *  signatures: Array<Signature>,
 *  style: CSSStyleDeclaration,
 *  onEvent: (action: import('history').Action) => any,
 *  mode: string
 * }} props {
 *   file: The file to be rendered in base64,
 *   signatures: Array of signatures to be rendered
 *   style: Custom styling
 *   onEvent: Allow parent component to react to events
 *   mode: The current editing mode
 * }
 * @returns {React.FC} A functional React component that renders a pdf and its
 * signature fields
 */
export default function PdfEditor({ file, signatures, style, onEvent, mode }) {
	/**
	 * Setup states and reducers
	 */
	const [contextMenu, contextMenuDispatch] = useReducer(
		contextMenuReducer,
		contextMenuInitState,
	);
	const [mouse, mouseDispatch] = useReducer(mouseReducer, mouseInitState);
	const { cursor } = mouse;
	const [showHelp, setShowHelp] = useState(false);

	const toggleHelp = () => {
		setShowHelp(!showHelp);
	};

	/**
	 * Setup properties for the page display
	 */
	const [marginLeft, setMarginLeft] = useState(0);
	const [marginTop] = useState('2rem');
	const [scale] = useState(1.5);
	const [dims, setDims] = useState([0, 0]);
	const [width, height] = dims;

	/**
	 * References to necessary elements;
	 */
	const containerRef = useRef();
	const canvasRef = useRef();
	const overlayCanvasRef = useRef();
	const contextMenuRef = useRef();

	/**
	 * Setting up pdfjsLib
	 */
	pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
	const pdfData = atob(file);
	const [pdfRef, setPdfRef] = useState();
	const [currentPage, setCurrentPage] = useState(1);

	/**
	 * Handle resizing the margin on window resize
	 */
	const resizeMargin = useCallback(() => {
		if (!containerRef.current || !canvasRef.current) return;

		const containerWidth = containerRef.current.getBoundingClientRect().width;
		setMarginLeft(Math.max((containerWidth - canvasRef.current.width) / 2, 50));
	}, [containerRef, canvasRef, setMarginLeft]);

	/**
	 * Handle deleting a signature
	 */
	const deleteSignature = useCallback(() => {
		onEvent({
			type: 'remove_signature',
			id: contextMenu.entity.id,
		});
		contextMenuDispatch({ type: 'close' });
	}, [contextMenu, onEvent, contextMenuDispatch]);

	/**
	 * Handle adding a signature
	 */
	const addSignature = useCallback(() => {
		onEvent({
			type: 'add_signature',
			x: Math.floor(mouse.x / scale - signatureDims.width / 2),
			y: Math.floor(mouse.y / scale - signatureDims.height / 2),
			page: currentPage,
		});

		if (contextMenu.show) {
			contextMenuDispatch({ type: 'close' });
		}
	}, [mouse, onEvent, contextMenu, contextMenuDispatch]);

	/**
	 * Rendering the pdf canvas
	 */
	const renderPage = useCallback(() => {
		pdfRef &&
			pdfRef.getPage(currentPage).then(function (page) {
				const viewport = page.getViewport({ scale });
				const canvas = canvasRef.current;
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				setDims([canvas.width, canvas.height]);
				resizeMargin();

				const renderContext = {
					canvasContext: canvas.getContext('2d'),
					viewport,
				};
				page.render(renderContext);
			});
	}, [pdfRef, currentPage]);

	/**
	 * Render the signature overlay canvas
	 */
	const renderOverlay = useCallback(() => {
		if (!overlayCanvasRef.current) return;

		const canvas = overlayCanvasRef.current;
		const ctx = canvas.getContext('2d');

		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.font = '16px Montserrat';

		const drawSignature = (s) => {
			ctx.globalAlpha = !s.id ? 0.8 : 1;

			ctx.fillStyle = '#007cbb';
			if (mouse.hover?.id === s?.id) {
				ctx.fillStyle = '#0770ba';
			}
			ctx.fillRect(s.x, s.y, s.width, s.height);

			ctx.fillStyle = 'white';
			ctx.fillText('Signature Field', s.x + s.width / 2, s.y + s.height / 2);

			ctx.globalAlpha = 1;
		};

		if (canvas.width !== width) canvas.width = width;
		if (canvas.height !== height) canvas.height = height;

		ctx.clearRect(0, 0, width, height);

		if (mode === 'signature' || mouse.select) {
			drawSignature(
				{
					x: mouse.x - (signatureDims.width / 2) * scale,
					y: mouse.y - (signatureDims.height / 2) * scale,
					width: signatureDims.width * scale,
					height: signatureDims.height * scale,
				},
				0.25,
			);
		}

		signatures.forEach((s) => {
			if (mouse.select?.id === s?.id || s.page !== currentPage) return;

			drawSignature({
				x: s.x * scale,
				y: s.y * scale,
				width: s.width * scale,
				height: s.height * scale,
				id: s.id,
			});
		});
	}, [overlayCanvasRef, signatures, width, height, mouse, mode, currentPage]);

	/**
	 * Render the context menu
	 * @returns {JSX}
	 */
	const renderContextMenu = () => {
		if (contextMenu.entity) {
			return (
				<ContextMenuButton
					hoverColor="var(--main-alert)"
					onClick={deleteSignature}
				>
					Delete Signature
				</ContextMenuButton>
			);
		} else {
			return (
				<ContextMenuButton onClick={addSignature}>
					Add Signature
				</ContextMenuButton>
			);
		}
	};

	/**
	 * useEffects:
	 * -Load the file if it changes
	 * -Rerender page when needed
	 * -Rerender overlay when needed
	 */
	useEffect(() => {
		const loadingTask = pdfjsLib.getDocument({ data: pdfData });
		loadingTask.promise.then(
			(loadedPdf) => {
				setPdfRef(loadedPdf);
			},
			function (reason) {
				logger.error(reason);
			},
		);
	}, [file]);
	useEffect(renderPage, [pdfRef, currentPage, renderPage]);
	useEffect(renderOverlay, [dims, signatures, renderOverlay, mouse, mode]);

	/**
	 * Adding in mouse listeners
	 */
	const handleMousemove = useCallback(
		(e) => {
			if (!overlayCanvasRef.current) return;

			const rect = overlayCanvasRef.current.getBoundingClientRect();

			mouseDispatch({
				type: 'set_pos',
				x: e.clientX - rect.left,
				y: e.clientY - rect.top,
			});

			let hover = null;
			signatures.forEach((s) => {
				if (s.page !== currentPage) return;

				if (
					pointInRect(mouse, {
						x: s.x * scale,
						y: s.y * scale,
						width: s.width * scale,
						height: s.height * scale,
					})
				) {
					hover = s;
				}
			});

			if (hover !== mouse.hover) {
				mouseDispatch({ type: 'set_hover', hover });
			}
		},
		[overlayCanvasRef, mouseDispatch, signatures, mouse, currentPage],
	);

	const handleMousedown = useCallback(
		(e) => {
			if (
				!overlayCanvasRef.current ||
				!containerRef.current?.contains(e.target) ||
				contextMenuRef.current?.contains(e.target)
			)
				return;

			e.preventDefault();

			const { which } = e;

			if (which === 3) {
				if (mode) {
					onEvent({ type: 'clear_mode' });
				} else if (mouse.select) {
					mouseDispatch({ type: 'set_select' });
				} else {
					contextMenuDispatch({
						type: 'open',
						x: mouse.x + overlayCanvasRef.current.offsetLeft,
						y: mouse.y + overlayCanvasRef.current.offsetTop,
						entity: mouse.hover,
					});
				}
			} else {
				if (contextMenu.show) {
					contextMenuDispatch({ type: 'close' });
				}

				if (mouse.hover) {
					mouseDispatch({
						type: 'set_select',
						select: mouse.hover,
					});
				} else {
					if (mode === 'signature') {
						addSignature();
					}
				}
			}
		},
		[
			containerRef,
			overlayCanvasRef,
			mouse,
			mouseDispatch,
			onEvent,
			currentPage,
		],
	);

	const handleMouseup = useCallback(
		(e) => {
			if (
				!overlayCanvasRef.current ||
				!containerRef.current?.contains(e.target)
			)
				return;

			e.preventDefault();

			const { which } = e;

			if (mouse.select) {
				if (which === 1) {
					mouseDispatch({
						type: 'set_hover',
						hover: mouse.select,
					});
					mouseDispatch({
						type: 'set_select',
						select: null,
					});
					onEvent({
						type: 'update_signature',
						id: mouse.select.id,
						x: Math.floor(mouse.x / scale - signatureDims.width / 2),
						y: Math.floor(mouse.y / scale - signatureDims.height / 2),
					});
				}
			}
		},
		[
			containerRef,
			overlayCanvasRef,
			mouse,
			mouseDispatch,
			onEvent,
			currentPage,
		],
	);

	const handleKeydown = useCallback(
		(e) => {
			if (!overlayCanvasRef.current) return;

			const { key } = e;

			if (key === 'Escape') {
				onEvent({
					type: 'clear_mode',
				});
			}
		},
		[overlayCanvasRef, onEvent],
	);

	const handleContextMenu = useCallback(
		(e) => {
			if (containerRef.current?.contains(e.target)) {
				e.preventDefault();
			}
		},
		[containerRef],
	);

	useEffect(() => {
		let newCursor = 'auto';

		if (mode) {
			if (mouse.hover) {
				newCursor = 'not-allowed';
			} else {
				newCursor = 'crosshair';
			}
		} else {
			if (mouse.hover) {
				newCursor = 'grab';
			} else if (mouse.select) {
				newCursor = 'grabbing';
			}
		}

		if (newCursor !== cursor) {
			mouseDispatch({
				type: 'set_cursor',
				cursor: newCursor,
			});
		}
	}, [mode, mouse, mouseDispatch, cursor]);

	useEffect(() => {
		const callbacks = {
			resize: resizeMargin,
			mousemove: handleMousemove,
			mousedown: handleMousedown,
			mouseup: handleMouseup,
			keydown: handleKeydown,
			contextmenu: handleContextMenu,
		};

		for (const type in callbacks) {
			window.addEventListener(type, callbacks[type]);
		}

		return () => {
			for (const type in callbacks) {
				window.removeEventListener(type, callbacks[type]);
			}
		};
	}, [
		resizeMargin,
		handleMousemove,
		handleMousedown,
		handleKeydown,
		handleContextMenu,
	]);

	/**
	 * Page handling
	 */
	const nextPage = () =>
		pdfRef &&
		currentPage < pdfRef.numPages &&
		handlePageChange(currentPage + 1);

	const prevPage = () => currentPage > 1 && handlePageChange(currentPage - 1);

	const handlePageChange = (num) => {
		if (num < 1 || num > pdfRef?.numPages) return;

		mouseDispatch({ type: 'set_select', select: null });

		setCurrentPage(parseInt(num));
	};

	const popupStyle = {
		background: 'var(--main-light-grey)',
	};

	return (
		<div
			style={{
				width: '100%',
				height: window.innerHeight - 200,
			}}
		>
			<div
				style={{
					overflow: 'visible',
					background: 'white',
					border: '1px solid rgba(0, 0, 0, 0.25)',
				}}
				className="row p-1 mx-0 justify-content-between"
			>
				<div className="col-auto px-0">
					<ToolbarButton active={mode === 'signature'}>
						<ActionButton
							image={addImage}
							alt="Add Signature"
							onClick={() => onEvent({ type: 'set_mode', mode: 'signature' })}
							fillColor={
								mode === 'signature' ? 'var(--main-color)' : 'var(--main-grey)'
							}
							popupStyle={{
								...popupStyle,
								top: -5,
							}}
						/>
					</ToolbarButton>
				</div>
				<div className="col-auto">
					<div className="row h-100 align-content-center">
						<div
							className="hand"
							onClick={prevPage}
							style={{
								width: 20,
								height: 40,
							}}
						>
							<span
								className="custom-arrow left"
								style={{
									marginTop: 13,
									borderColor:
										currentPage === 1
											? 'var(--main-light-grey)'
											: 'var(--main-color)',
								}}
							/>
						</div>
						<PageInput
							className="no-spinner bg-white mr-2 px-1 text-right"
							type="number"
							min={1}
							max={pdfRef?.numPages || 1}
							value={currentPage}
							onChange={(e) => handlePageChange(e.target.value)}
							style={{
								color: 'black',
							}}
						/>
						<div
							className="mr-2"
							style={{
								minWidth: 28,
								marginTop: 5,
							}}
						>
							/ {pdfRef?.numPages}
						</div>
						<div
							className="hand"
							onClick={nextPage}
							style={{
								width: 20,
								height: 40,
							}}
						>
							<span
								className="custom-arrow right"
								style={{
									marginTop: 13,
									borderColor:
										currentPage === pdfRef?.numPages
											? 'var(--main-light-grey)'
											: 'var(--main-color)',
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-auto px-0">
					<ActionButton
						alt="Help"
						image={helpImage}
						onClick={toggleHelp}
						popupStyle={popupStyle}
					/>
				</div>
			</div>
			<div
				style={{
					...style,
					overflow: 'auto',
					position: 'relative',
					cursor,
				}}
				ref={containerRef}
			>
				<canvas
					ref={canvasRef}
					style={{
						marginLeft,
						marginRight: marginLeft,
						marginTop,
						marginBottom: marginTop,
					}}
				/>
				<canvas
					ref={overlayCanvasRef}
					style={{
						marginLeft,
						position: 'absolute',
						top: marginTop,
						left: 0,
					}}
				/>
				{contextMenu.show && (
					<div
						ref={contextMenuRef}
						className="bg-white shadow"
						style={{
							position: 'absolute',
							top: contextMenu.y,
							left: contextMenu.x,
						}}
					>
						{renderContextMenu()}
					</div>
				)}
			</div>
			{showHelp && (
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						left: 0,
						top: 0,
						background: 'rgba(0, 0, 0, 0.75)',
					}}
				>
					<div
						className="bg-white border p-3"
						style={{
							position: 'absolute',
							width: 400,
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							borderRadius: 5,
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: 10,
								right: 15,
							}}
						>
							<DeleteButton onClick={toggleHelp} />
						</div>
						<div className="font-weight-bold font-24 mb-2">
							Edit Form - Help
						</div>
						<div>
							<p>
								<div className="font-italic">With tool selected: </div>
								<div className="ml-2">-Left click uses the tool</div>
								<div className="ml-2">-Right click clears the current tool</div>
								<div className="ml-2 mb-2">-Escape clears the current tool</div>
								<div className="font-italic">Without tool selected:</div>
								<div className="ml-2">
									-Right click the page to add a signature
								</div>
								<div className="ml-2">
									-Right click on a signature (without a tool selected) to
									remove it
								</div>
								<div className="ml-2">-Left click a signature to drag it</div>
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
