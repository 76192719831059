/*
 *
 * RxRequests reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CHANGE_PHARMACY,
	CHANGE_PHARMACY_ERROR,
	CHANGE_PHARMACY_SUCCESS,
	DEFAULT_ACTION,
} from './constants';

export const initialState = fromJS({});

function rxRequestsReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case CHANGE_PHARMACY:
			return state.set('changePharmacyStatus', false);
		case CHANGE_PHARMACY_SUCCESS:
			return state.set('changePharmacyStatus', true);
		case CHANGE_PHARMACY_ERROR:
			return state.set('changePharmacyStatus', false);
		default:
			return state;
	}
}

export default rxRequestsReducer;
