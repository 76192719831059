import React, { useCallback, useReducer } from 'react';

import PdfEditor from 'atoms/PdfEditor';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';

import { signatureDims } from './constants';

class Signature {
	constructor(x, y, page) {
		this.x = x;
		this.y = y;
		this.width = signatureDims.width;
		this.height = signatureDims.height;
		this.page = page;
		this.id = Math.random();
	}
}

const initState = (data) => ({
	mode: null,
	signatures: data.documentData?.signatures || [],
	isDirty: false,
});

const reducer = (state, action) => {
	switch (action.type) {
		case 'set_mode':
			return {
				...state,
				mode: state.mode === action.mode ? null : action.mode,
				isDirty: true,
			};
		case 'clear_mode':
			return {
				...state,
				mode: null,
			};
		case 'add_signature':
			return {
				...state,
				signatures: [
					...state.signatures,
					new Signature(action.x, action.y, action.page),
				],
				isDirty: true,
			};
		case 'remove_signature':
			return {
				...state,
				signatures: state.signatures.filter((x) => x.id !== action.id),
				isDirty: true,
			};
		case 'update_signature':
			return {
				...state,
				signatures: state.signatures.map((x) =>
					x.id === action.id
						? {
								...x,
								x: action.x,
								y: action.y,
						  }
						: x,
				),
				isDirty: true,
			};
		case 'save':
			return {
				...state,
				isDirty: false,
			};
		default:
			return state;
	}
};

const AddSignatures = (props) => {
	const { file, form, prevStep, nextStep, saveForm, isUpdating, isDirty } =
		props;
	const [state, dispatch] = useReducer(reducer, initState(form));

	const { mode, signatures } = state;

	const handleSaveForm = (cb) => {
		saveForm({ documentData: { ...form.documentData, signatures } }, () => {
			dispatch({ type: 'save' });
			if (cb) {
				cb();
			}
		});
	};

	const handleEvent = (action) => {
		dispatch(action);
	};

	const handleNextStep = useCallback(() => {
		if (state.isDirty) {
			handleSaveForm(nextStep);
		} else {
			nextStep();
		}
	}, [state, nextStep, handleSaveForm]);

	return (
		<div>
			<PdfEditor
				style={{
					height: 'calc(100% - 70px)',
					background: 'grey',
					border: '1px solid gainsboro',
					borderTop: '1px solid transparent',
				}}
				file={file}
				signatures={signatures}
				mode={mode}
				onEvent={handleEvent}
			/>
			<div className="w-100 mt-1 justify-content-center text-center">
				<button
					className="btn btn-primary bg-white link-color border font-14 mx-3"
					style={{ height: 45, minWidth: 140 }}
					onClick={prevStep}
				>
					CANCEL
				</button>
				<button
					disabled={isUpdating || (!state.isDirty && !isDirty)}
					className="btn btn-primary btn-color font-14 mx-3"
					style={{ height: 45, minWidth: 140 }}
					onClick={handleSaveForm}
				>
					{isUpdating ? (
						<SmallLoadingIndicator
							style={{ width: '20px', height: '20px', margin: '0 auto' }}
							className=""
						/>
					) : (
						'SAVE'
					)}
				</button>
				<button
					disabled={isUpdating}
					className="btn btn-primary btn-color font-14 mx-3"
					style={{ height: 45, minWidth: 140 }}
					onClick={handleNextStep}
				>
					{isUpdating ? (
						<SmallLoadingIndicator
							style={{ width: '20px', height: '20px', margin: '0 auto' }}
							className=""
						/>
					) : (
						`NEXT${state.isDirty || isDirty ? '*' : ''}`
					)}
				</button>
			</div>
		</div>
	);
};

export default AddSignatures;
