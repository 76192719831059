import PropTypes from 'prop-types';

import React, { useState } from 'react';
import Form from 'react-validation/build/form';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import PharmacyInput from 'components/RXRequest/PharmacyInput';
import useIsMobile from 'hooks/isMobile.ts';

/**
 *
 * ChangePharmacyModal
 *
 */
function ChangePharmacyModal(props) {
	const {
		pharmacies,
		changePharmacy,
		showPharmacyModal,
		modalOpen,
		states,
		cities,
		zipcodes,
		fetchCities,
		fetchZipcodes,
		pharmacy,
	} = props;

	const isMobile = useIsMobile();
	const [state, setState] = useState({
		pharmacyName: pharmacy.name,
		rxNumber: pharmacy.phone,
		pharmacyCity: pharmacy.city,
		pharmacyState: pharmacy.state,
		pharmacyAddress: pharmacy.street,
		pharmacyZipcode: pharmacy.zipcode,
		pharmacyId: pharmacy.id,
	});

	const [errors, setErrors] = useState({});

	const dispatch = (action) => {
		const { type, name, value } = action;

		if (name === 'pharmacyState') {
			fetchCities(value.value);
		} else if (name === 'pharmacyCity') {
			fetchZipcodes(value.value);
		}

		switch (type) {
			case 'CHANGE':
				setState({
					...state,
					[name]: value,
				});
				setErrors({
					...errors,
					[name]: null,
				});
				break;
			case 'SELECT':
				setState({
					...state,
					...value,
				});
				break;
			default:
				break;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (state.pharmacyName && state.rxNumber) {
			changePharmacy(state);
		} else {
			const newErrors = [];
			if (!state.pharmacyName) {
				newErrors.pharmacyName = 'Pharmacy name is required';
			}
			if (!state.rxNumber) {
				newErrors.rxNumber = 'Pharmacy phone is required';
			}
			setErrors({ ...errors, ...newErrors });
		}
	};

	return (
		<div>
			<Modal
				isOpen={modalOpen}
				className="modal-dialog-centered custom-modal"
				backdrop="static"
			>
				<ModalHeader
					toggle={showPharmacyModal}
					className={`align-center text-center px-${
						isMobile ? '2' : '4'
					} border-0 container-fluid custom-bg-color`}
					cssModule={{
						'modal-title': `d-flex justify-content-center w-100 pt-2 font-${
							isMobile ? '20' : '35'
						} text-dark font-weight-bold`,
					}}
				>
					<div className=""> CHANGE PHARMACY</div>
				</ModalHeader>
				<ModalBody
					className={`pb-3 custom-bg-color ${isMobile && 'px-0 pt-0'}`}
					style={{
						borderRadius: '0.3rem',
						maxHeight: isMobile ? 'calc(90vh - 2rem)' : 'auto',
					}}
				>
					<div className="container-fluid bg-white">
						<Form className="p-4">
							<PharmacyInput
								dispatch={dispatch}
								cities={cities}
								zipcodes={zipcodes}
								states={states}
								pharmacies={pharmacies}
								selectedPharmacyId={pharmacy.id}
								errors={errors}
							/>
							<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
								<button
									onClick={handleSubmit}
									type="submit"
									className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
								>
									<span className="mr-0 w-100">SUBMIT</span>
								</button>
							</div>
						</Form>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}

ChangePharmacyModal.propTypes = {
	changePharmacy: PropTypes.func,
	pharmacies: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	showPharmacyModal: PropTypes.func,
	modalOpen: PropTypes.bool,
};

export default ChangePharmacyModal;
