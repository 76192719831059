/*
 *
 * EnrollmentRequests reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CLEAR_URL,
	DEFAULT_ACTION,
	SIGN_DOCUMENT,
	SIGN_DOCUMENT_ERROR,
	SIGN_DOCUMENT_SUCCESS,
	SUBMIT_ENROLLMENT_REQUESTS,
	SUBMIT_ENROLLMENT_REQUESTS_ERROR,
	SUBMIT_ENROLLMENT_REQUESTS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	url: '',
	error: '',
	isUpdating: false,
});

function enrollmentRequestsReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case SUBMIT_ENROLLMENT_REQUESTS:
		case SIGN_DOCUMENT:
			return state.merge({ url: '', error: '' });
		case SUBMIT_ENROLLMENT_REQUESTS_SUCCESS:
		case SIGN_DOCUMENT_SUCCESS:
			return state.merge({ url: action.data.url, error: '' });
		case SUBMIT_ENROLLMENT_REQUESTS_ERROR:
		case SIGN_DOCUMENT_ERROR:
			return state.set('error', action.err);
		case CLEAR_URL:
			return state.set('url', '');
		default:
			return state;
	}
}

export default enrollmentRequestsReducer;
