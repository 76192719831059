import moment from 'moment';

import { typeToFullName } from 'utils/requests';

const vowels = ['a', 'e', 'i', 'o', 'u'];
const beginsWithVowel = (string) => {
	return vowels.includes(string.toLowerCase()[0]);
};

export const generateNotificationMessage = (notification, goto, role) => {
	let {
		actor = {},
		filename = '',
		receiver = {},
		request = {},
		state = '',
		type = '',
		updatedAt = '',
	} = notification;

	const fromNowLocal = (date) => {
		const localTime = moment.utc(date).local();
		return moment(localTime).fromNow();
	};

	if (!type) type = state;

	const requestType = type.toLowerCase().includes('form')
		? filename
		: typeToFullName(type);

	let actionMessage = '';
	let appendix = '';

	const isActor = actor.id === receiver.id;
	const isReceiver = receiver.name === request?.clientName;

	switch (state) {
		case 'AWAITING SIGNATURE':
			actionMessage = `would like ${
				isReceiver ? 'you' : `${request?.clientName}`
			} to sign`;
			break;
		case 'APPROVED':
			actionMessage = `approved ${
				isReceiver ? 'your' : `${request?.clientName}'s`
			}`;
			break;
		case 'COMPLETED':
			actionMessage = 'signed';
			// For now if the type is not a form, we are going to add an "a" at the end
			if (type.toLowerCase().includes('roi')) actionMessage = 'submitted a';
			break;
		case 'DECLINED':
			actionMessage = 'declined';
			break;
		case 'DENIED':
			actionMessage = `denied ${
				isReceiver ? 'your' : `${request?.clientName}'s`
			}`;
			break;
		case 'PENDING_APPROVAL':
		case 'OPEN':
			if (type.toLowerCase().includes('form')) {
				actionMessage = 'shared';
				appendix = `with ${isReceiver ? 'you' : request?.clientName}`;
			} else
				actionMessage = `submitted a${beginsWithVowel(requestType) ? 'n' : ''}`;
			break;
	}

	const generateRoute = () => {
		let route = '';
		let routeState = { type, request };

		switch (type) {
			case 'LEAVE':
			case 'ROI':
				route = role === 'clinician' ? 'todos' : 'requests';
				routeState.activeTab = 'member';
				break;
			case 'Rx':
				route = 'todos';
				routeState.activeTab = 'rx';
				break;
			case 'New Clinician Request':
				route = 'todos';
				break;
			case 'FORM':
				const stateToTab = {
					'AWAITING SIGNATURE': 'pending',
					COMPLETED: 'signed',
					DECLINED: 'declined',
					OPEN: 'shared',
				};

				route = 'forms';
				routeState.activeTab = stateToTab[state];
				routeState.searchText = filename;
				break;
			case 'Enrollment Request':
				route = role === 'clinician' ? 'todos' : 'requests';
				break;
			case 'DISCONTINUANCE':
				route = role === 'clinician' ? 'todos' : 'requests';
				break;
			case 'COMPLETED REGISTRATION':
				route = 'todos';
				routeState.activeTab = 'other';
				break;
			default:
				break;
		}

		return { route, state: routeState };
	};

	const routeInfo = generateRoute();

	const message = `${isActor ? 'You' : actor.name || 'A user'} ${actionMessage}
      ${requestType} ${appendix}`;

	return (
		<div className="px-2 font-14">
			{message.trim()}.
			<div className="row justify-content-between mt-2 font-12">
				<div
					className="col-auto link-color hand"
					onClick={() => goto(routeInfo)}
				>
					View
				</div>
				<div className="col-auto font-italic">{fromNowLocal(updatedAt)}</div>
			</div>
		</div>
	);
};
