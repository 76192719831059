import { useEffect, useState } from 'react';

const maxSize = 1025;

export default function useIsMobile(): boolean {
	const [isMobile, setIsMobile] = useState(window.innerWidth < maxSize);

	useEffect(() => {
		const checkIsMobile = () => {
			setIsMobile(window.innerWidth < maxSize);
		};

		checkIsMobile();

		window.addEventListener('resize', checkIsMobile);

		return () => window.removeEventListener('resize', checkIsMobile);
	}, []);

	return isMobile;
}

export const TEST_ONLY = {
	maxSize,
};
