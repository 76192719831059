import imgForms from 'images/ic_forms.svg';
import imgFormsActive from 'images/ic_forms_active.svg';
import imgGroups from 'images/ic_groups.svg';
import imgGroupsActive from 'images/ic_groups_active.svg';
import imgMyClients from 'images/ic_my_clients.svg';
import imgMyClientsActive from 'images/ic_my_clients_active.svg';
import imgRequests from 'images/ic_requests.svg';
import imgRequestsActive from 'images/ic_requests_active.svg';
import imgTodos from 'images/ic_todos.svg';
import imgTodosActive from 'images/ic_todos_active.svg';

const adminFeatures = [
	{
		tabId: 'adminTodos',
		tabName: 'To-Dos',
		mobileTabName: 'To-Dos',
		tabIconActive: (
			<img className="tab-img" src={imgTodosActive} alt="TodosActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgTodos} alt="TodosInactive" />
		),
		to: '/admin/dashboard/todos',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'adminRequests',
		tabName: 'Requests',
		mobileTabName: 'Requests',
		tabIconActive: (
			<img className="tab-img" src={imgRequestsActive} alt="RequestsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgRequests} alt="RequestsInactive" />
		),
		to: '/admin/dashboard/requests',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'adminCliniciansAndClients',
		tabName: 'Clinicians & Clients',
		mobileTabName: 'Users',
		tabIconActive: (
			<img className="tab-img" src={imgMyClientsActive} alt="MyClientsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgMyClients} alt="MyClientsInactive" />
		),
		to: '/admin/dashboard/cliniciansandclients',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'adminGroups',
		tabName: 'Therapies',
		mobileTabName: 'Therapies',
		tabIconActive: (
			<img className="tab-img" src={imgGroupsActive} alt="GroupsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgGroups} alt="GroupsInactive" />
		),
		to: '/admin/dashboard/groups',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'adminForms',
		tabName: 'Measures & Forms',
		mobileTabName: 'Forms',
		tabIconActive: (
			<img className="tab-img" src={imgFormsActive} alt="FormsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgForms} alt="FormsInactive" />
		),
		to: '/admin/dashboard/forms',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	// {
	//   tabId: 'adminReports',
	//   tabName: 'Reports',
	//   tabIconActive: <img src={imgReportsActive} alt="ReportsActive" />,
	//   tabIconInactive: <img src={imgReports} alt="ReportsInactive" />,
	//   to: '/admin/dashboard/reports',
	//   childs: [
	//     {
	//       tabId: 'new-intake',
	//       tabName: 'New Intakes',
	//     },
	//   ],
	// },
];

export default adminFeatures;
