import React from 'react';

const sendImage = (
	<svg
		stroke="currentColor"
		fill="currentColor"
		strokeWidth="0"
		viewBox="0 0 512 512"
		height="1.25em"
		width="1.25em"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z"></path>
	</svg>
);

export default sendImage;
