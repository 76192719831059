import React from 'react';
import { useSelector } from 'react-redux';

import { MODALS_BY_NAME } from './list';

export default function ModalManagerNoRoute() {
	const { name, isOpen, props } = useSelector((state) => state.get('modal'));

	const Component = MODALS_BY_NAME[name];

	if (!Component || !isOpen) return null;

	return (
		<>
			<Component {...props} />
		</>
	);
}
