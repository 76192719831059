const routes = [
	{
		pathName: '/admin/dashboard',
		tabName: 'adminTodos',
	},
	{
		pathName: '/admin/dashboard/todos',
		tabName: 'adminTodos',
	},
	{
		pathName: '/admin/dashboard/requests',
		tabName: 'adminRequests',
	},
	{
		pathName: '/admin/dashboard/reports',
		tabName: 'adminReports',
	},

	{
		pathName: '/admin/dashboard/cliniciansandclients',
		tabName: 'adminCliniciansAndClients',
	},
	{
		pathName: '/admin/dashboard/groups',
		tabName: 'adminGroups',
	},
	{
		pathName: '/admin/dashboard/forms',
		tabName: 'adminForms',
	},
	{
		pathName: '/clinician/dashboard',
		tabName: 'clinicianTodos',
	},
	{
		pathName: '/clinician/dashboard/todos',
		tabName: 'clinicianTodos',
	},
	{
		pathName: '/clinician/dashboard/attendance',
		tabName: 'clinicianAttendance',
	},
	{
		pathName: '/clinician/dashboard/groups',
		tabName: 'clinicianMyGroups',
	},
	{
		pathName: '/clinician/dashboard/myclients',
		tabName: 'clinicianMyClients',
	},
	{
		pathName: '/clinician/dashboard/forms',
		tabName: 'clinicianForms',
	},
	/* {
    pathName: '/patient/dashboard',
    tabName: 'patientTodos',
  }, */
	{
		pathName: '/patient/dashboard/todos',
		tabName: 'patientTodos',
	},
	{
		pathName: '/patient/dashboard/mygroups',
		tabName: 'patientMyGroups',
	},
	{
		pathName: '/patient/dashboard/requests',
		tabName: 'patientRequests',
	},
	{
		pathName: '/patient/dashboard/forms',
		tabName: 'patientForms',
	},
	{
		pathName: '/patient/dashboard/browsegroups',
		tabName: 'patientBrowseGroups',
	},
];

export default routes;
