/**
 *
 * ClinicianToDos
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import Avatar from 'atoms/Avatar/index.tsx';
import DefaultAvatar from 'atoms/DefaultAvatar';
import PatientInfo from 'components/PatientInfo';

import './index.css';
import makeSelectClinicianToDos from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class ClinicianToDos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			patientInfoVisible: false,
		};
	}

	showPatientInfo = () => {
		this.setState({ patientInfoVisible: true });
	};

	hidePatientInfo = () => {
		this.setState({ patientInfoVisible: false });
	};

	render() {
		const { patientInfoVisible } = this.state;
		const { src } = this.props;
		return (
			<div className="container-fluid co-font">
				<div className="row bg-white justify-content-center">
					<div className="d-flex m-5">
						{/* <i className="fas fa-user-circle fa-6x" /> */}
						{src ? (
							<Avatar src={src} />
						) : (
							<DefaultAvatar className="big" name="Jeffery Devido" />
						)}
						<div className="d-flex flex-column justify-content-center ml-2">
							<h4 className="font-weight-bold clinician">Jeffery Devido, MD</h4>
							<p className="qual">M.D., Psychiatrist</p>
						</div>
					</div>
				</div>
				<div className="container-fluid pl-5 pr-5 pt-3 d-flex">
					<div className="col-6">
						<span className="heading-clinician-todos">
							NEW REQUESTS FOR THERAPY ENROLLMENT
						</span>
						<div className="card">
							<div className="d-flex p-2">
								<div className="col-6 d-flex" onClick={this.showPatientInfo}>
									<div className="d-flex align-items-center">
										{/* <i className="fas fa-user-circle fa-2x" /> */}
										<DefaultAvatar name="Mellanie Jossy" />
									</div>
									<div className="d-flex flex-column ml-2">
										<h6 className="mt-1 name">Mellanie Jossy</h6>
										<p className="text-muted m-0 desc">
											Cognitive Behaviour Therapy
										</p>
										<p className="text-muted m-0 loc">San Francisco</p>
									</div>
								</div>
								<ul className="col-6 d-flex justify-content-center align-items-center list-unstyled p-0 m-0">
									<li>
										<button
											type="button"
											className="btn btn-primary btn-sm btn-color"
										>
											APPROVE
										</button>
									</li>
									<li>
										<button
											type="button"
											className="btn btn-white btn-sm link-color ml-2 border"
										>
											CONTACT
										</button>
									</li>
								</ul>
							</div>
						</div>
						<hr className="m-0" />
						<div className="card">
							<div className="d-flex p-2">
								<div className="col-6 d-flex">
									<div className="d-flex align-items-center">
										{/* <i className="fas fa-user-circle fa-2x" /> */}
										<DefaultAvatar name="Innaki Williams" />
									</div>
									<div className="d-flex flex-column ml-2">
										<h6 className="mt-1 name">Innaki Williams</h6>
										<p className="text-muted m-0 desc">
											Cognitive Behaviour Therapy
										</p>
										<p className="text-muted m-0 loc">San Francisco</p>
									</div>
								</div>
								<ul className="col-6 d-flex justify-content-center align-items-center list-unstyled p-0 m-0">
									<li>
										<button
											type="button"
											className="btn btn-primary btn-sm btn-color"
										>
											APPROVE
										</button>
									</li>
									<li>
										<button
											type="button"
											className="btn btn-white btn-sm link-color ml-2 border"
										>
											CONTACT
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-6">
						<span className="heading-clinician-todos">
							REQUESTS BY THERAPY MEMBERS
						</span>
						<div className="card">
							<div className="d-flex p-2">
								<div className="d-flex">
									<div className="d-flex align-items-top">
										{/* <i className="fas fa-user-circle fa-2x" /> */}
										<DefaultAvatar name="Joe Cole" />
									</div>
									<div className="d-flex flex-column ml-2">
										<h6 className="mt-1 name">Joe Cole</h6>
										<p className="text-muted m-0 desc">
											Cognitive Behaviour Therapy
										</p>
										<p className="text-muted m-0 loc">San Francisco</p>
										<p className=" m-0 font-italic detail">
											Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
											Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
											natoque penatibus et magnis dis parturient mo …
										</p>
										<ul className="d-flex justify-content-end list-unstyled mt-3">
											<li>
												<button
													type="button"
													className="btn btn-primary btn-sm btn-color"
												>
													APPROVE
												</button>
											</li>
											<li>
												<button
													type="button"
													className="btn btn-white btn-sm link-color ml-2 border"
												>
													DENY
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<hr className="m-0" />
						<div className="card">
							<div className="d-flex p-2">
								<div className="d-flex">
									<div className="d-flex align-items-top">
										{/* <i className="fas fa-user-circle fa-2x" /> */}
										<DefaultAvatar name="Mellanie Jossy" />
									</div>
									<div className="d-flex flex-column ml-2">
										<h6 className="mt-1 name">Mellanie Jossy</h6>
										<p className="text-muted m-0 desc">
											Cognitive Behaviour Therapy
										</p>
										<p className="text-muted m-0 loc">San Francisco</p>
										<p className="m-0 font-italic detail">
											Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
											Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
											natoque penatibus et magnis dis parturient mo …
										</p>
										<ul className="d-flex justify-content-end list-unstyled mt-3">
											<li>
												<button
													type="button"
													className="btn btn-primary btn-sm btn-color"
												>
													APPROVE
												</button>
											</li>
											<li>
												<button
													type="button"
													className="btn btn-white btn-sm link-color ml-2 border"
												>
													DENY
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{patientInfoVisible ? (
						<PatientInfo hide={this.hidePatientInfo} />
					) : null}
				</div>
			</div>
		);
	}
}

ClinicianToDos.propTypes = {
	src: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	clinicianToDos: makeSelectClinicianToDos(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicianToDos);
