/*
 *
 * Requests constants
 *
 */

export const DEFAULT_ACTION = 'src/Requests/DEFAULT_ACTION';

export const FETCH_PROGRAMS = 'src/Requests/FETCH_PROGRAMS';
export const FETCH_PROGRAMS_SUCCESS = 'src/Requests/FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_ERROR = 'src/Requests/FETCH_PROGRAMS_ERROR';

export const FETCH_PHARMACY = 'src/Requests/FETCH_PHARMACY';
export const FETCH_PHARMACY_SUCCESS = 'src/Requests/FETCH_PHARMACY_SUCCESS';
export const FETCH_PHARMACY_ERROR = 'src/Requests/FETCH_PHARMACY_ERROR';

export const FETCH_REQUEST = 'src/Requests/FETCH_REQUEST';
export const FETCH_REQUEST_SUCCESS = 'src/Requests/FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_ERROR = 'src/Requests/FETCH_REQUEST_ERROR';

export const UPDATE_PATIENT_RX_REQUEST =
	'src/Requests/UPDATE_PATIENT_RX_REQUEST';

export const LOAD_PATIENT_GROUP_REQUESTS =
	'src/Requests/LOAD_PATIENT_GROUP_REQUESTS';
export const LOAD_PATIENT_GROUP_REQUESTS_SUCCESS =
	'src/Requests/LOAD_PATIENT_GROUP_REQUESTS_SUCCESS';
export const LOAD_PATIENT_GROUP_REQUESTS_ERROR =
	'src/Requests/LOAD_PATIENT_GROUP_REQUESTS_ERROR';

export const LOAD_PATIENT_RX_REQUESTS = 'src/Requests/LOAD_PATIENT_RX_REQUESTS';
export const LOAD_PATIENT_RX_REQUESTS_SUCCESS =
	'src/Requests/LOAD_PATIENT_RX_REQUESTS_SUCCESS';
export const LOAD_PATIENT_RX_REQUESTS_ERROR =
	'src/Requests/LOAD_PATIENT_RX_REQUESTS_ERROR';

export const LOAD_PATIENT_ROI_DATA = 'src/Requests/LOAD_PATIENT_ROI_DATA';
export const LOAD_PATIENT_ROI_DATA_SUCCESS =
	'src/Requests/LOAD_PATIENT_ROI_DATA_SUCCESS';
export const LOAD_PATIENT_ROI_DATA_ERROR =
	'src/Requests/LOAD_PATIENT_ROI_DATA_ERROR';
export const SUBMIT_PATIENT_ROI_DATA = 'src/Requests/SUBMIT_PATIENT_ROI_DATA';
export const SUBMIT_PATIENT_ROI_DATA_SUCCESS =
	'src/Requests/SUBMIT_PATIENT_ROI_DATA_SUCCESS';
export const SUBMIT_PATIENT_ROI_DATA_ERROR =
	'src/Requests/SUBMIT_PATIENT_ROI_DATA_ERROR';

export const REQUEST_TELEHEALTH = 'src/Requests/REQUEST_TELEHEALTH';
export const REQUEST_TELEHEALTH_SUCCESS =
	'src/Requests/REQUEST_TELEHEALTH_SUCCESS';
export const REQUEST_TELEHEALTH_ERROR = 'src/Requests/REQUEST_TELEHEALTH_ERROR';

export const SIGN_DOC = 'src/Requests/SIGN_DOC';
export const SIGN_DOC_SUCCESS = 'src/Requests/SIGN_DOC_SUCCESS';
export const SIGN_DOC_ERROR = 'src/Requests/SIGN_DOC_ERROR';

export const CLEAR_URL = 'src/Requests/CLEAR_URL';

export const tabLabels = {
	ENROLLMENT_REQUESTS: '1',
	GROUP_REQUESTS: '2',
	RX_REQUESTS: '3',
	MEMBER_REQUESTS: '4',
};
