import moment from 'moment';

import ActionButton from 'atoms/ActionButton';
import CustomButton from 'atoms/CustomButton';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { headerStyle } from 'containers/App/constants';
import iconFormsActive from 'images/ic_forms_active.svg';
import deleteImage from 'svgs/delete';
import downloadImage from 'svgs/download';
import sendImage from 'svgs/send';

const commonColumns = (actions) => [
	{
		field: 'filename',
		title: 'NAME',
		headerStyle,
		render: (row) => (
			<div
				className="font-13 my-1 hand"
				onClick={() => actions.view(row)}
				style={{
					whiteSpace: 'nowrap',
					overflowX: 'hidden',
					overflowY: 'hidden',
				}}
			>
				<img className="mr-3" src={iconFormsActive} alt="" />
				<span>{row.filename}</span>
			</div>
		),
	},
	{
		field: 'createdAt',
		title: 'CREATED AT',
		headerStyle,
		render: (row) => (
			<div className="font-13 my-2 ellipsis">
				{moment(row.createdAt).format('YYYY-MM-DD')}
			</div>
		),
	},
	{
		field: 'updatedAt',
		title: 'UPDATED AT',
		headerStyle,
		render: (row) => (
			<div className="font-13 my-2 ellipsis">
				{moment(row.updatedAt).format('YYYY-MM-DD')}
			</div>
		),
	},
];

const templatesColumns = (actions) => [
	...commonColumns(actions),
	{
		field: 'actions',
		title: 'ACTIONS',
		headerStyle: {
			...headerStyle,
			textAlign: 'right',
			paddingRight: '1.75rem',
		},
		sorting: false,
		render: (row) => (
			<div className="row justify-content-end mr-2">
				{/* <div className="col-auto px-0">
          <ActionButton
            image={tagImage}
            alt="Tag"
            onClick={() => actions.tag(row)}
          />
        </div> */}
				<div className="col-auto px-0">
					<ActionButton
						image={sendImage}
						alt="Share"
						onClick={() => actions.share(row)}
					/>
				</div>
				<div className="col-auto px-0">
					<ActionButton
						image={downloadImage}
						alt="Download"
						onClick={() => actions.download(row.uuid)}
					/>
				</div>
				{/* <div className="col-auto px-0">
          <ActionButton
            image={editImage}
            alt="Edit"
            onClick={() => actions.edit(row.id)}
          />
        </div> */}
				<div className="col-auto px-0">
					<ActionButton
						image={deleteImage}
						hoverColor={'var(--main-alert)'}
						alt="Delete"
						onClick={() => actions.delete(row)}
					/>
				</div>
			</div>
		),
	},
];

const sharedFormsColumns = (actions, type, role) => {
	const columns = [
		role === 'patient'
			? {
					field: 'sender',
					title: 'SENDER',
					headerStyle,
					render: (row) => (
						<div>
							{row.sender ? (
								<ImageDetailsCell
									src={row.sender.profilePicture}
									heading={row.sender.name}
									primarySubHeading={
										row.sender.therapistType || 'Administrator'
									}
								/>
							) : (
								<div>-</div>
							)}
						</div>
					),
			  }
			: {
					field: 'client',
					title: 'CLIENT',
					headerStyle,
					render: (row) => (
						<div>
							{row.client && (
								<ImageDetailsCell
									src={row.client.profilePicture}
									heading={row.client.name}
								/>
							)}
						</div>
					),
			  },
		{
			field: 'formName',
			title: 'FORM NAME',
			headerStyle,
			render: (row) => {
				const canDownload =
					row.state?.includes('COMPLETED') || row.state?.includes('OPEN');
				return (
					<div
						className={`font-13 my-1 ${canDownload ? 'hand' : ''}`}
						onClick={() => canDownload && actions.view(row)}
						style={{
							whiteSpace: 'nowrap',
							overflowX: 'hidden',
							overflowY: 'hidden',
						}}
					>
						{row.formName ? (
							<div>
								{canDownload && (
									<img className="mr-3" src={iconFormsActive} alt="" />
								)}
								<span>{row.formName}</span>
							</div>
						) : (
							'-'
						)}
					</div>
				);
			},
		},
		{
			field: 'createdAt',
			title: 'CREATED AT',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2 ellipsis">
					{moment(row.createdAt).format('YYYY-MM-DD')}
				</div>
			),
		},
		{
			field: 'updatedAt',
			title: 'UPDATED AT',
			headerStyle,
			render: (row) => (
				<div className="font-13 my-2 ellipsis">
					{moment(row.updatedAt).format('YYYY-MM-DD')}
				</div>
			),
		},
	];

	if (role !== 'patient' && type === 'pending') {
		columns.splice(1, 0, {
			field: 'templateName',
			title: 'TEMPLATE',
			headerStyle,
			render: (row) => (
				<div
					className="font-13 my-1 hand"
					onClick={() => actions.view(row, 'template')}
					style={{
						whiteSpace: 'nowrap',
						overflowX: 'hidden',
						overflowY: 'hidden',
					}}
				>
					<img className="mr-3" src={iconFormsActive} alt="" />
					<span>{row.templateName}</span>
				</div>
			),
		});
	}

	if (type === 'pending' && role === 'patient') {
		columns.push({
			field: 'actions',
			title: 'ACTIONS',
			headerStyle: {
				...headerStyle,
				textAlign: 'right',
				paddingRight: '1.75rem',
			},
			sorting: false,
			render: (row) => (
				<div className="row justify-content-end mr-2">
					<div className="mr-3">
						<CustomButton
							type="primary"
							title="Sign Now"
							onClick={() => actions.sign(row)}
						/>
					</div>
					<div>
						<CustomButton
							type="secondary"
							title="Decline"
							onClick={() => actions.decline(row)}
						/>
					</div>
				</div>
			),
		});
	}

	if (type === 'declined') {
		columns.push({
			field: 'reason',
			title: 'REASON',
			headerStyle,
			sorting: false,
			render: (row) => (
				<div
					className="link-color hand font-13"
					onClick={() => actions.viewReason(row)}
				>
					View Reason
				</div>
			),
		});
	}

	return columns;
};

export { templatesColumns, sharedFormsColumns };
