import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
	name: '',
	props: {},
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal: (state, action) => {
			state.isOpen = true;
			state.name = action.payload.name;
			state.props = action.payload.props;
		},
		closeModal: (state) => {
			state.isOpen = false;
			state.name = '';
			state.props = {};
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;
const modalReducer = modalSlice.reducer;
export default modalReducer;
