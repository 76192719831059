import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the groupDetail state domain
 */

const selectPatientProfileDomain = (state) =>
	state.get('patientProfile', initialState);

const makeSelectUserInfo = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('userInfo')),
	);

const makeSelectLoadingInfo = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('loadingInfo')),
	);

const makeSelectIsUpdating = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('isUpdating')),
	);

const makeSelectUserGroups = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('groups')),
	);

const makeSelectAllGroups = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('allGroups')),
	);

const makeSelectLoadingGroups = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('loadingGroups')),
	);

const makeSelectUserAttendance = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('attendance')),
	);

const makeSelectLoadingAttendance = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('loadingAttendance')),
	);

const makeSelectUserDocs = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('docs')),
	);

const makeSelectLoadingDocs = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('loadingDocs')),
	);

const makeSelectUserError = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('userError')),
	);

const makeSelectError = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('error')),
	);

const makeSelectUpdateError = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('updateError')),
	);

const makeSelectUpdateSuccess = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(returnJS(substate.get('updateSuccess'))),
	);

const makeSelectGetSessions = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('sessions')),
	);

const makeSelectIsAdding = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('isAdding')),
	);

const makeSelectAddingError = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('addingError')),
	);

const makeSelectRequests = () =>
	createSelector(selectPatientProfileDomain, (substate) =>
		returnJS(substate.get('requests')),
	);

export {
	makeSelectUserInfo,
	makeSelectLoadingInfo,
	makeSelectIsUpdating,
	makeSelectUserGroups,
	makeSelectAllGroups,
	makeSelectLoadingGroups,
	makeSelectUserAttendance,
	makeSelectLoadingAttendance,
	makeSelectUserDocs,
	makeSelectLoadingDocs,
	makeSelectUserError,
	makeSelectError,
	makeSelectUpdateError,
	makeSelectUpdateSuccess,
	makeSelectGetSessions,
	makeSelectIsAdding,
	makeSelectAddingError,
	makeSelectRequests,
};
