import React from 'react';

const style = (show) => ({
	position: 'absolute',
	right: '0.5rem',
	top: '50%',
	transform: `translateY(-50%) rotate(${show ? 180 : 0}deg)`,
	transition: 'all 0.3s ease-in-out',
	cursor: 'pointer',
});

const DropdownToggle = ({ show, toggle }) => {
	return (
		<div style={style(show)} onClick={toggle}>
			<svg
				stroke="currentColor"
				fill="currentColor"
				strokeWidth="0"
				viewBox="0 0 24 24"
				height="2em"
				width="2em"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
			</svg>
		</div>
	);
};

export default DropdownToggle;
