import PropTypes from 'prop-types';

import React from 'react';

import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
class Telehealth extends React.Component {
	nextProgress = (event) => {
		const { nextProgress } = this.props;
		const { telehealthChecked } = this.props;

		const data = {
			checks: {
				telehealthChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	render() {
		const { telehealthChecked, handleAcceptance, prevProgress, isMobile } =
			this.props;

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mb-5 mt-5">
					Telehealth Consent
				</h2>
				<div className="card d-flex flex-column p-4 bb-0">
					<span className="pt-1 pb-5">
						I hereby consent to engage in telehealth with Mind Therapy Clinic
						and its clinicians as part of my psychiatric treatment. I understand
						that “telehealth” includes the practice of health care delivery,
						diagnosis, consultation, treatment, transfer of medical data, and
						education using interactive audio, video, or data communications. I
						understand that teletherapy also involves the communication of my
						medical/mental information, both orally and visually, to health care
						practitioners located in California or outside of California.
					</span>
					<span className="pt-1 pb-5">
						Because of recent advances in communication technology, the field of
						teletherapy has evolved. It has allowed individuals who may not have
						local access to a mental health professional to use electronic means
						to receive services. Because it is relatively new, there is not a
						lot of research indicating that it is an effective means of
						receiving therapy. An important part of therapy is sitting face to
						face with an individual, where non-verbal communication (body
						signals) are readily available to both therapist and client. Without
						this information, teletherapy may be slower to progress or less
						effective. With the telephone, the client’s tone of voice, pauses
						and choice of words become especially important and therefore an
						important focus of the sessions. With therapy via email, the written
						word is the exclusive focus. What is important here is that you are
						aware that teletherapy may or may not be as effective as in-person
						therapy and therefore we must pay close attention to your progress
						and periodically evaluate the effectiveness of this form of therapy.
					</span>
					<span className="pt-1 pb-5">
						With teletherapy, there is the question of where is the therapy
						occurring – at the therapist’s office or the location of the client?
						The law has not yet clarified this issue; therefore, it is my policy
						to inform clients that they are receiving services from my office
						and therefore are bound by the laws of the State of California. In
						addition, clients must reside within the State of California. These
						laws are primarily related to confidentiality as outlined in this
						form and my disclosure form.
					</span>
					<span className="pt-1 pb-5">
						I understand that I have the following rights with respect to
						telehealth:
					</span>
					<span className="pt-1 pb-5">
						1) I have the right to withhold or withdraw consent at any time
						without affecting my right to future care or treatment nor risking
						the loss or withdrawal of any program benefits to which I would
						otherwise be entitled.
					</span>
					<span className="pt-1 pb-5">
						2) The laws that protect the confidentiality of my medical
						information also apply to telehealth. As such, I understand that the
						information disclosed by me during the course of my therapy is
						generally confidential. However, there are both mandatory and
						permissive exceptions to confidentiality, including, but not limited
						to reporting child, elder, and dependent adult abuse; expressed
						threats of violence towards an ascertainable victim; and where I
						make my mental or emotional state an issue in a legal proceeding. I
						also understand that the dissemination of any personally
						identifiable images or information from the teletherapy interaction
						to researchers or other entities shall not occur without my written
						consent.
					</span>
					<span className="pt-1 pb-5">
						3) I understand that there are risks and consequences from
						teletherapy, including, but not limited to, the possibility, despite
						reasonable efforts on the part of my clinicians, that: the
						transmission of my medical information could be disrupted or
						distorted by technical failures; the transmission of my medical
						information could be interrupted by unauthorized persons; and/or the
						electronic storage of my medical information could be accessed by
						unauthorized persons. In addition, I understand that
						telehealth-based services and care may not be as complete as
						face-to-face services. I also understand that if my clinician
						believes I would be better served by another form of psychological
						or psychiatric services (e.g. face-to-face services) I will be
						referred to a clinician who can provide such services in my area.
						Finally, I understand that there are potential risks and benefits
						associated with any form of psychological treatment, and that
						despite my efforts and the efforts of my clinicians, my condition
						may not be improved, and in some cases may even get worse.
					</span>
					<span className="pt-1 pb-5">
						4) I understand that I may benefit from telehealth, but that results
						cannot be guaranteed or assured.
					</span>
					<span className="pt-1 pb-5">
						5) I understand that I have a right to access my medical information
						and copies of medical records in accordance with California law.
					</span>
					<span className="pt-1 pb-5">
						I have read and understand the information provided above. I have
						discussed it with my clinician, and all of my questions have been
						answered to my satisfaction.
					</span>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="telehealthChecked"
						value={telehealthChecked}
						isRequired
					/>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-5">
						<button
							type="button"
							className="btn btn-white bg-white link-color border btn-lg btn-block font-15 font-weight-bold d-flex justify-content-start"
							onClick={prevProgress}
						>
							<div className="col-4 d-flex justify-content-start align-self-center">
								<img className="fas fa-arrow-left" alt="arrow-left" />
							</div>
							<div className="col-4 d-flex justify-content-center">
								<span>PREV</span>
							</div>
						</button>
					</div>
					<div className="col-5">
						<button
							disabled={!telehealthChecked}
							className="btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-end"
							onClick={this.nextProgress}
						>
							<div className="col-4 d-flex justify-content-center">
								<span>NEXT</span>
							</div>
							<div className="col-4 d-flex justify-content-end align-self-center">
								<img className={'fas fa-arrow-right'} alt="finish" />
							</div>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

Telehealth.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
	modal: PropTypes.bool.isRequired,
	siteUrl: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default Telehealth;
