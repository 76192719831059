import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import styled from 'styled-components';

import AddSignatures from 'components/AddSignatures';
import ChooseRecipients from 'components/ChooseRecipients';

import './index.css';

const steps = [
	{
		name: 'Edit',
	},
	{
		name: 'Select recipients',
	},
];

const TitleInput = styled.input`
	border: 1px solid white;

	+ div {
		border: 1px solid transparent;
	}

	:hover + div {
		border: 1px solid rgb(0, 0, 0, 0.15);
	}

	:focus + div {
		border: 1px solid var(--main-color);
	}
`;

const ShareFormModal = (props) => {
	const {
		isOpen,
		toggle,
		clients,
		groups,
		form = {},
		handleSubmit,
		file,
		saveForm,
		isUpdating,
	} = props;

	const [step, setStep] = useState(0);
	const [filename, setFilename] = useState(form.filename || 'Untitled.pdf');
	const [isDirty, setIsDirty] = useState(false);

	const maxStep = steps.length;

	const nextStep = () => {
		if (step < maxStep) {
			if (isDirty) {
				doSaveForm();
			}
			setStep(step + 1);
		}
	};

	const prevStep = () => {
		if (step > 0) {
			setStep(step - 1);
		}
	};

	const doSubmit = (data, cb) => {
		handleSubmit(data, `${filename}.pdf`, cb);
	};

	const doSaveForm = (data, cb) => {
		saveForm(
			{
				...data,
				filename: `${filename}.pdf`,
			},
			() => {
				setIsDirty(false);

				if (cb) {
					cb();
				}
			},
		);
	};

	useEffect(() => {
		if (form) {
			let nameArray = form.filename.split('.');
			nameArray.splice(nameArray.length - 1, 1);
			setFilename(nameArray.join(''));
		}
	}, [form, setFilename]);

	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal-dialog-centered wide-title"
				backdrop="static"
				size={step === 0 ? 'xl' : 'lg'}
				style={{
					overflow: 'hidden',
					height: step === 0 ? 'calc(100% - 0.5rem)' : 'auto',
					margin: step === 0 ? '0.25rem auto' : '1.75rem auto',
				}}
			>
				<ModalHeader
					toggle={toggle}
					className="px-4 pb-0 border-0 container-fluid w-100"
				>
					<div
						className="font-weight-bold"
						style={{
							fontSize: 32,
						}}
					>
						Share Form with Clients - {steps[step].name}
					</div>
					{step === 0 && (
						<div
							className="w-100 mt-2"
							style={{
								position: 'relative',
							}}
						>
							<TitleInput
								type="text"
								className="font-weight-bold pl-2 mb-2 w-100"
								value={filename}
								onChange={(e) => {
									setFilename(e.target.value);
									setIsDirty(true);
								}}
							/>
							<div
								className="font-weight-bold py-1 px-2"
								style={{
									position: 'absolute',
									top: '-0.25rem',
									left: 0,
									pointerEvents: 'none',
								}}
							>
								<span style={{ visibility: 'hidden' }}>{filename}</span>
								<span>.pdf</span>
							</div>
						</div>
					)}
				</ModalHeader>
				<ModalBody
					className={`px-4 pt-${step === 0 ? 0 : 1}`}
					style={{
						height: step === 0 ? 'auto' : 630,
					}}
				>
					{step === 0 ? (
						<AddSignatures
							file={file}
							form={form}
							saveForm={doSaveForm}
							prevStep={toggle}
							nextStep={nextStep}
							isUpdating={isUpdating}
							isDirty={isDirty}
						/>
					) : (
						<ChooseRecipients
							clients={clients}
							groups={groups}
							nextStep={doSubmit}
							prevStep={prevStep}
							isSending={isUpdating}
						/>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
};

export default ShareFormModal;
