// import { take, call, put, select } from 'redux-saga/effects';
// Individual exports for testing
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectUserId } from 'containers/App/selectors';
import {
	fetchPatientGroupRequestsAPI,
	fetchPatientPrograms,
	fetchRequestAPI,
	findPharmacy,
	patientRegistrationFetchDataAPI,
	roiAPI,
	signDocAPI,
	telehealthAPI,
} from 'utils/api';

import {
	fetchPharmacyError,
	fetchPharmacySuccess,
	fetchProgramsError,
	fetchProgramsSuccess,
	fetchRequestError,
	fetchRequestSuccess,
	loadPatientRequestsError,
	loadPatientRequestsSuccess,
	loadPatientRoiDataError,
	loadPatientRoiDataSuccess,
	loadPatientRxRequestsError,
	loadPatientRxRequestsSuccess,
	requestTelehealthError,
	requestTelehealthSuccess,
	signDocError,
	signDocSuccess,
	submitPatientRoiDataError,
	submitPatientRoiDataSuccess,
} from './actions';
import {
	FETCH_PHARMACY,
	FETCH_PROGRAMS,
	FETCH_REQUEST,
	LOAD_PATIENT_GROUP_REQUESTS,
	LOAD_PATIENT_ROI_DATA,
	LOAD_PATIENT_RX_REQUESTS,
	REQUEST_TELEHEALTH,
	SIGN_DOC,
	SUBMIT_PATIENT_ROI_DATA,
} from './constants';

export default function* requestsSaga() {
	yield takeLatest(FETCH_PROGRAMS, getPrograms);
	yield takeLatest(FETCH_PHARMACY, getPharmacy);
	yield takeLatest(FETCH_REQUEST, getRequest);
	yield takeLatest(LOAD_PATIENT_GROUP_REQUESTS, loadPatientGroupRequests);
	yield takeLatest(LOAD_PATIENT_RX_REQUESTS, loadPatientRxRequests);
	yield takeLatest(LOAD_PATIENT_ROI_DATA, loadPatientRoiData);
	yield takeLatest(SUBMIT_PATIENT_ROI_DATA, submitRoi);
	yield takeLatest(REQUEST_TELEHEALTH, requestTelehealth);
	yield takeLatest(SIGN_DOC, signDoc);
	// See example in containers/HomePage/saga.js
}

export function* getPrograms() {
	const id = yield select(makeSelectUserId());
	try {
		const response = yield call(fetchPatientPrograms, { id, enrolled: true });
		yield put(fetchProgramsSuccess(response));
	} catch (err) {
		yield put(fetchProgramsError(err));
	}
}

export function* getRequest(action) {
	const { id } = action;

	try {
		const response = yield call(fetchRequestAPI, { id });

		if (response) {
			yield put(fetchRequestSuccess(response.data));
		} else {
			yield put(fetchRequestError('Could not load request'));
		}
	} catch (err) {
		yield put(fetchRequestError(err));
	}
}

export function* loadPatientGroupRequests() {
	try {
		const response = yield call(fetchPatientGroupRequestsAPI, false);
		yield put(loadPatientRequestsSuccess(response));
	} catch (err) {
		yield put(loadPatientRequestsError(err));
	}
}

export function* loadPatientRxRequests() {
	try {
		const response = yield call(fetchPatientGroupRequestsAPI, true);
		yield put(loadPatientRxRequestsSuccess(response));
	} catch (err) {
		yield put(loadPatientRxRequestsError(err));
	}
}

export function* getPharmacy({ id }) {
	try {
		const response = yield call(findPharmacy, id);
		yield put(fetchPharmacySuccess(response));
	} catch (err) {
		yield put(fetchPharmacyError(err));
	}
}

export function* loadPatientRoiData(action) {
	try {
		const response = yield call(patientRegistrationFetchDataAPI, action.data);
		yield put(loadPatientRoiDataSuccess(response));
	} catch (err) {
		yield put(loadPatientRoiDataError(err));
	}
}

export function* submitRoi(action) {
	try {
		const response = yield call(roiAPI, action.data);
		yield put(submitPatientRoiDataSuccess(response));
	} catch (err) {
		yield put(submitPatientRoiDataError(err));
	}
}

export function* requestTelehealth(action) {
	try {
		const response = yield call(telehealthAPI, action.data);
		yield put(requestTelehealthSuccess(response));
	} catch (err) {
		yield put(requestTelehealthError(err));
	}
}

export function* signDoc(action) {
	try {
		const response = yield call(signDocAPI, action.id);
		yield put(signDocSuccess(response));
	} catch (err) {
		yield put(signDocError(err));
	}
}
