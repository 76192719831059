import { Slide } from 'react-toastify';

export const toastOptions = {
	autoClose: 2000,
	hideProgressBar: false,
	transition: Slide,
	className: 'attendance-toast-container',
	bodyClassName: 'attendance-toast-body',
	progressClassName: 'attendance-toast-progress',
};
