/*
 *
 * App reducer
 *
 */
import { fromJS } from 'immutable';

import { isMobile } from 'react-device-detect';

import { SUCCESS_CHECK_ONBOARDING_CLINICIAN } from 'containers/ClinicianRegister/constants';
import { SUBMIT_JOTFORM_SUCCESS } from 'containers/Forms/constants';
import { LOGIN_USER_SUCCESS } from 'containers/SignIn/constants';
import { LOGOUT, UPDATE_AUTH } from 'containers/UserDropDown/constants';
import { SUCCESS_CHECK_ONBOARDING } from 'pages/intake/constants';
import { getAuthFromLocalStorage } from 'utils/authInLocalStorage';

import {
	CLEAR_DOWNLOAD_ERROR,
	CLEAR_FILE,
	CREATE_REQUEST,
	CREATE_REQUEST_ERROR,
	CREATE_REQUEST_SUCCESS,
	DEFAULT_ACTION,
	DELETE_REQUEST,
	DELETE_REQUEST_ERROR,
	DELETE_REQUEST_SUCCESS,
	DOWNLOAD_FILE,
	DOWNLOAD_FILE_ERROR,
	DOWNLOAD_FILE_SUCCESS,
	FETCH_CITIES,
	FETCH_CITIES_ERROR,
	FETCH_CITIES_SUCCESS,
	FETCH_DOCUMENT_LIST,
	FETCH_DOCUMENT_LIST_ERROR,
	FETCH_DOCUMENT_LIST_SUCCESS,
	FETCH_ENROLL_REQ,
	FETCH_ENROLL_REQ_ERROR,
	FETCH_ENROLL_REQ_SUCCESS,
	FETCH_MEMBER_REQ,
	FETCH_MEMBER_REQ_ERROR,
	FETCH_MEMBER_REQ_SUCCESS,
	FETCH_PHARMACIES,
	FETCH_PHARMACIES_ERROR,
	FETCH_PHARMACIES_SUCCESS,
	FETCH_STATES,
	FETCH_STATES_ERROR,
	FETCH_STATES_SUCCESS,
	FETCH_ZIPCODES,
	FETCH_ZIPCODES_ERROR,
	FETCH_ZIPCODES_SUCCESS,
	GET_PERMISSIONS,
	GET_PERMISSIONS_SUCCESS,
	LOAD_CLINICIANS,
	LOAD_CLINICIANS_ERROR,
	LOAD_CLINICIANS_SUCCESS,
	PING_SERVER,
	PING_SERVER_ERROR,
	PING_SERVER_SUCCESS,
	REMOVE_TOAST_MESSAGE,
	SET_MOBILE,
	SET_VIEW_MODE,
	TOAST_ERROR,
	TOAST_GENERATED,
	UPDATE_REQUEST,
	UPDATE_REQUEST_ERROR,
	UPDATE_REQUEST_SUCCESS,
	VIEW_PDF,
	VIEW_PDF_ERROR,
	VIEW_PDF_SUCCESS,
} from './constants';

export const initialState = fromJS({
	auth: { ...getAuthFromLocalStorage(), viewMode: null },
	permissions: [],
	toastMessage: null,
	states: [],
	cities: {},
	zipcodes: {},
	updatedRequest: {},
	newRequest: {},
	clinicians: [],
	requestSuccessStatus: true,
	documentList: [],
	loadingDocuments: false,
	file: null,
	isLoadingFile: false,

	downloadError: false,

	isMobile: false,

	enrollReq: [],
	isFetchingEnrollReq: false,

	memberReq: [],
	isFetchingMemberReq: false,

	isUpdatingRequest: false,

	serverError: false,
	version: null,
});

function setOnboarding(action, initial) {
	let state = initial;
	const auth = JSON.parse(localStorage.getItem('auth'));
	if (auth) {
		auth.loggedInUser.onboardingComplete = action.data.onboardingComplete;
		localStorage.setItem('auth', JSON.stringify(auth));
		state = state.setIn(
			['auth', 'loggedInUser', 'onboardingComplete'],
			action.data.onboardingComplete,
		);
	}
	return state;
}

const modifyData = (data) =>
	data.map((item) => ({
		label: item.name,
		value: item.code,
	}));

const modifyCities = (cities, action) => {
	const modifiedCities = cities;
	if (!cities[action.stateCode]) {
		modifiedCities[action.stateCode] = action.data.data.map((item) => ({
			label: item,
			value: item,
		}));
	}
	return modifiedCities;
};

const modifyZipcodes = (zipcodes, action) => {
	const modifiedZipcodes = zipcodes;
	if (!zipcodes[action.cityName]) {
		modifiedZipcodes[action.cityName] = action.data.data.map((item) => ({
			label: item,
			value: item,
		}));
	}
	return modifiedZipcodes;
};

const deleteLocalRequest = (state, request) => {
	state.set('isUpdatingRequest', false);
	let type;
	if (request.type == 'Enrollment Request') {
		type = 'enrollReq';
	} else {
		type = 'memberReq';
	}

	const requests = state.get(type).toJS();
	const index = requests.findIndex((r) => r.requestId === request.id);
	requests.splice(index, 1);

	return state.set(type, fromJS(requests));
};

const updateLocalRequest = (state, request) => {
	state.set('isUpdatingRequest', false);
	let requests, newRequests;
	if (request.type == 'Enrollment Request') {
		requests = state.get('enrollReq').toJS();
		newRequests = requests.map((r) => {
			if (r.requestId === request.id) {
				return {
					...r,
					...request,
					requestState: request.state,
					reasonForDenial: request.payload?.reasonForDenial,
				};
			} else {
				return r;
			}
		});

		return state.set('enrollReq', fromJS(newRequests));
	} else {
		requests = state.get('memberReq').toJS();
		newRequests = requests.map((r) => {
			if (r.requestId === request.id) {
				return {
					...r,
					...request,
					requestState: request.state,
					reasonForDenial: request.payload?.reasonForDenial,
				};
			} else {
				return r;
			}
		});
		return state.set('memberReq', fromJS(newRequests));
	}
};

function appReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case LOGIN_USER_SUCCESS:
			localStorage.setItem('auth', JSON.stringify(action.successData));
			localStorage.setItem('token', action.successData.token);
			return state.mergeDeep({
				auth: action.successData,
				permissions: action.permissions || [],
			});
		case SUCCESS_CHECK_ONBOARDING:
			return setOnboarding(action, state);
		case SUCCESS_CHECK_ONBOARDING_CLINICIAN:
			return setOnboarding(action, state);
		case LOGOUT:
			localStorage.clear();
			return state.merge({
				auth: {},
				isMobile: window.innerWidth < 1025 || isMobile,
			});

		case VIEW_PDF:
			return state.merge({
				isLoadingFile: true,
				file: null,
			});
		case VIEW_PDF_SUCCESS:
			return state.merge({
				file: action.file,
				isLoadingFile: false,
			});
		case VIEW_PDF_ERROR:
			return state.merge({
				error: action.error,
				isLoadingFile: false,
			});

		case TOAST_GENERATED:
			return state.set('toastMessage', action.toastMessage);
		case UPDATE_AUTH:
			return state.mergeDeep({ auth: action.auth });
		case REMOVE_TOAST_MESSAGE:
			return state.set('toastMessage', action.nullMessage);
		case TOAST_ERROR:
			return state.set('error', action.error);
		case FETCH_STATES:
			return state;
		case FETCH_STATES_SUCCESS:
			return state.set('states', modifyData(action.data.data));
		case FETCH_STATES_ERROR:
			return state.set('error', action.err);
		case PING_SERVER:
			return state;
		case PING_SERVER_SUCCESS:
			return state.merge({
				version: action.data.version,
				serverError: false,
			});
		case PING_SERVER_ERROR:
			return state.set('serverError', true);

		case SET_MOBILE:
			return state.set('isMobile', action.value);

		case FETCH_DOCUMENT_LIST:
			return state.merge({
				loadingDocuments: true,
				documentList: [],
			});
		case FETCH_DOCUMENT_LIST_SUCCESS:
			return state.merge({
				loadingDocuments: false,
				documentList: action.data,
			});
		case FETCH_DOCUMENT_LIST_ERROR:
			return state.merge({
				loadingDocuments: false,
				error: action.err,
			});
		case UPDATE_REQUEST:
		case DELETE_REQUEST:
			return state.set('isUpdatingRequest', true);
		case UPDATE_REQUEST_SUCCESS:
			return updateLocalRequest(state, action.data);
		case UPDATE_REQUEST_ERROR:
		case DELETE_REQUEST_ERROR:
			return state.merge({
				isUpdatingRequest: false,
				error: action.err,
			});
		case DELETE_REQUEST_SUCCESS:
			return deleteLocalRequest(state, action.data);
		case CREATE_REQUEST:
			return state.set('newRequest', {});
		case CREATE_REQUEST_SUCCESS:
			return state
				.set('newRequest', action.data.data)
				.set('requestSuccessStatus', true);
		case CREATE_REQUEST_ERROR:
			return state.set('error', action.err).set('requestSuccessStatus', false);
		case LOAD_CLINICIANS:
			return state.set('clinicians', []);
		case LOAD_CLINICIANS_SUCCESS:
			return state.set('clinicians', action.clinicians);
		case LOAD_CLINICIANS_ERROR:
			return state.set('error', action.error);

		case FETCH_PHARMACIES:
			return state.set('pharmacies', []);
		case FETCH_PHARMACIES_SUCCESS:
			return state.set(
				'pharmacies',
				Array.isArray(action.data) ? action.data : [],
			);
		case FETCH_PHARMACIES_ERROR:
			return state.set('error', action.err);

		case FETCH_ENROLL_REQ:
			return state.merge({
				enrollReq: [],
				isFetchingEnrollReq: true,
			});
		case FETCH_ENROLL_REQ_SUCCESS:
			return state.merge({
				enrollReq: action.data.data,
				isFetchingEnrollReq: false,
			});
		case FETCH_ENROLL_REQ_ERROR:
			return state.merge({
				error: action.err,
				isFetchingEnrollReq: false,
			});

		case FETCH_MEMBER_REQ:
			return state.merge({
				memberReq: [],
				isFetchingMemberReq: true,
			});
		case FETCH_MEMBER_REQ_SUCCESS:
			return state.merge({
				memberReq: action.data.data,
				isFetchingMemberReq: false,
			});
		case FETCH_MEMBER_REQ_ERROR:
			return state.merge({
				error: action.err,
				isFetchingMemberReq: false,
			});

		case FETCH_CITIES:
			return state;
		case FETCH_CITIES_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const cities = state.get('cities').toJS();
			return state.set('cities', fromJS(modifyCities(cities, action)));
		case FETCH_CITIES_ERROR:
			return state.set('error', action.err);
		case FETCH_ZIPCODES:
			return state;
		case FETCH_ZIPCODES_SUCCESS:
			// eslint-disable-next-line no-case-declarations
			const zipcodes = state.get('zipcodes').toJS();
			return state.set('zipcodes', fromJS(modifyZipcodes(zipcodes, action)));
		case FETCH_ZIPCODES_ERROR:
			return state.set('error', action.err);

		case CLEAR_DOWNLOAD_ERROR:
			return state.set('downloadError', false);
		case DOWNLOAD_FILE:
			return state.merge({ downloadError: false });
		case DOWNLOAD_FILE_SUCCESS:
			return state.merge({ file: action.file.data, downloadError: false });
		case DOWNLOAD_FILE_ERROR:
			return state.merge({ error: action.error, downloadError: true });

		case GET_PERMISSIONS:
			return state.merge({ permissions: [] });
		case GET_PERMISSIONS_SUCCESS:
			return state.merge({ permissions: action.permissions || [] });

		case SUBMIT_JOTFORM_SUCCESS:
			return state.merge({ permissions: action.permissions || [] });

		case CLEAR_FILE:
			return state.set('file', null);

		case SET_VIEW_MODE:
			return state.set(
				'auth',
				fromJS({ ...state.get('auth').toJS(), viewMode: action.mode }),
			);
		default:
			return state;
	}
}

export default appReducer;
