/*
 *
 * CliniciansAndClients reducer
 *
 */
import { fromJS } from 'immutable';

import {
	ADD_CLIENT,
	ADD_CLIENT_ERROR,
	ADD_CLIENT_SUCCESS,
	CHANGE_CLINCIAN,
	CHANGE_CLINCIAN_ERROR,
	CHANGE_CLINCIAN_SUCCESS,
	CLEAR_NEW_CLIENT,
	DEFAULT_ACTION,
	DELETE_CLINCIAN,
	DELETE_CLINCIAN_ERROR,
	DELETE_CLINCIAN_SUCCESS,
	EDIT_CLINCIAN,
	EDIT_CLINCIAN_ERROR,
	EDIT_CLINCIAN_SUCCESS,
	GET_CLINCIAN,
	GET_CLINCIAN_ERROR,
	GET_CLINCIAN_SUCCESS,
	LOAD_ALL_CLIENTS,
	LOAD_ALL_CLIENTS_ERROR,
	LOAD_ALL_CLIENTS_SUCCESS,
	LOAD_ALL_CLINICIANS,
	LOAD_ALL_CLINICIANS_ERROR,
	LOAD_ALL_CLINICIANS_SUCCESS,
	LOAD_CLIENTS,
	LOAD_CLIENTS_ERROR,
	LOAD_CLIENTS_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	allClients: [],
	allClinicians: [],
	deletedClinician: {},
	editedClinician: {},
	getClinician: {},
	changedClinician: {},
	newClient: {},
	isFetchingClients: false,
	isFetchingClinicians: false,
});

function cliniciansAndClientsReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case LOAD_ALL_CLIENTS:
			return state.merge({
				allClients: [],
				isFetchingClients: true,
			});
		case LOAD_ALL_CLIENTS_SUCCESS:
			return state.merge({
				allClients: action.data,
				isFetchingClients: false,
			});
		case LOAD_ALL_CLIENTS_ERROR:
			return state;

		case LOAD_ALL_CLINICIANS:
			return state.merge({
				allClinicians: [],
				isFetchingClinicians: true,
			});
		case LOAD_ALL_CLINICIANS_SUCCESS:
			return state.merge({
				allClinicians: action.data,
				isFetchingClinicians: false,
			});
		case LOAD_ALL_CLINICIANS_ERROR:
			return state;

		case LOAD_CLIENTS:
			return state.set('clients', []);
		case LOAD_CLIENTS_SUCCESS:
			return state.set('clients', action.data);
		case LOAD_CLIENTS_ERROR:
			return state.set('error', action.error);

		case DELETE_CLINCIAN:
			return state.set('deletedClinician', {});
		case DELETE_CLINCIAN_SUCCESS:
			return state.set('deletedClinician', action.data);
		case DELETE_CLINCIAN_ERROR:
			return state;

		case EDIT_CLINCIAN:
			return state.set('editedClinician', {});
		case EDIT_CLINCIAN_SUCCESS:
			return state.set('editedClinician', action.data);
		case EDIT_CLINCIAN_ERROR:
			return state;

		case CHANGE_CLINCIAN:
			return state.set('changedClinician', {});
		case CHANGE_CLINCIAN_SUCCESS:
			return state.set('changedClinician', action.data);
		case CHANGE_CLINCIAN_ERROR:
			return state;

		case GET_CLINCIAN:
			return state.set('getClinician', {});
		case GET_CLINCIAN_SUCCESS:
			return state.set('getClinician', action.data);
		case GET_CLINCIAN_ERROR:
			return state.set('getClinician', action.error);
		case ADD_CLIENT:
			return state.set('newClient', {});
		case ADD_CLIENT_SUCCESS:
			return state.set('newClient', action.data);
		case ADD_CLIENT_ERROR:
			return state.set('newClient', action.error);
		case CLEAR_NEW_CLIENT:
			return state.set('newClient', {});

		case LOAD_PROGRAMS:
			return state.set('programs', []);
		case LOAD_PROGRAMS_SUCCESS:
			return state.set('programs', action.programs);
		case LOAD_PROGRAMS_ERROR:
			return state.set('error', action.error);

		default:
			return state;
	}
}

export default cliniciansAndClientsReducer;
