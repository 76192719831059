import { startCase } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

/**
 *
 * <CustomDropdown />, a functional component to display dopdown with single select option.
 *
 */

class CustomDropdown extends React.Component {
	state = { dropdownOpen: false };

	componentDidMount() {
		const { defaultSelect } = this.props;
		if (defaultSelect) this.setState({ selectedOption: defaultSelect });
	}

	toggle = () => {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
			inputSearch: '',
		}));
	};

	handleClick = (option) => {
		const { handleSelect } = this.props;
		handleSelect(option);
	};

	handleReset = () => {
		const { handleReset } = this.props;
		handleReset();
	};

	render() {
		const {
			selectOptions,
			selectLabel,
			defaultSelect,
			isMobile,
			selectedOption,
			customStyles = {
				container: {},
				button: {},
				dropdownItem: {},
				dropdownMenu: {},
			},
			inline,
			noReset,
		} = this.props;
		const { dropdownOpen } = this.state;

		return (
			<div
				className={`align-items-center ${
					isMobile || inline ? 'p-0 py-2' : 'p-3 br-1'
				} ${inline ? 'd-flex' : 'col-auto'}`}
				style={customStyles.container}
			>
				<div
					className={`text-muted m-0 ${
						isMobile ? 'font-13' : 'font-9'
					} ${inline}`}
				>
					{selectLabel}
				</div>
				<div>
					{!isMobile && selectedOption && !noReset && (
						<button
							style={styles.reset}
							className="text-muted font-20"
							onClick={this.handleReset}
						>
							×
						</button>
					)}
					<ButtonDropdown
						nav
						className="justify-content-end"
						style={styles.button}
						isOpen={dropdownOpen}
						toggle={this.toggle}
					>
						<DropdownToggle nav caret className="w-100">
							<span
								className={`text-dark mr-3  ${
									isMobile || inline ? 'font-18' : 'font-13'
								}`}
							>
								{selectedOption ? startCase(selectedOption) : defaultSelect}
							</span>
						</DropdownToggle>
						<DropdownMenu>
							{selectOptions.map((option, i) => (
								<DropdownItem key={i} onClick={() => this.handleClick(option)}>
									{startCase(option)}
								</DropdownItem>
							))}
							{!noReset && (
								<>
									<DropdownItem divider />
									<DropdownItem onClick={this.handleReset} toggle={false}>
										Reset
									</DropdownItem>
								</>
							)}
						</DropdownMenu>
					</ButtonDropdown>
				</div>
			</div>
		);
	}
}

const styles = {
	button: {
		marginLeft: '10px',
	},
	reset: {
		position: 'absolute',
		left: '5px',
		top: '42%',
	},
};

CustomDropdown.propTypes = {
	handleSelect: PropTypes.func.isRequired,
	selectLabel: PropTypes.string.isRequired,
	selectOptions: PropTypes.array.isRequired,
	defaultSelect: PropTypes.string,
	handleReset: PropTypes.func,
};

export default CustomDropdown;
