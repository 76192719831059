import { call, put, takeLatest } from 'redux-saga/effects';

import { enrollmentRequestAPI, fetchPrograms } from 'utils/api';

import {
	createRequestError,
	createRequestIndividualSuccess,
	createRequestSuccess,
	loadProgramsError,
	loadProgramsSuccess,
} from './actions';
import {
	CREATE_REQUEST,
	CREATE_REQUEST_INDIVIDUAL,
	LOAD_PROGRAMS,
} from './constants';

export default function* browseGroupsSaga() {
	yield takeLatest(LOAD_PROGRAMS, getPrograms);
	yield takeLatest(CREATE_REQUEST, createEnrollmentRequest);
	yield takeLatest(
		CREATE_REQUEST_INDIVIDUAL,
		createIndividualEnrollmentRequest,
	);
}

export function* getPrograms() {
	try {
		const response = yield call(fetchPrograms);
		if (Array.isArray(response)) {
			yield put(loadProgramsSuccess(response));
		} else {
			yield put(loadProgramsError('Error retrieving programs'));
		}
	} catch (err) {
		yield put(loadProgramsError(err));
	}
}

export function* createEnrollmentRequest(action) {
	try {
		const response = yield call(enrollmentRequestAPI, action.data);
		yield put(createRequestSuccess(response));
		yield getPrograms();
	} catch (err) {
		yield put(createRequestError(err));
	}
}

export function* createIndividualEnrollmentRequest(action) {
	try {
		const response = yield call(enrollmentRequestAPI, action.data);
		yield put(createRequestIndividualSuccess(response));
		yield getPrograms();
	} catch (err) {
		yield put(createRequestError(err));
	}
}
