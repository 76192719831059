import { parseISO } from 'date-fns';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import CustomDatePicker from 'atoms/CustomDatePicker';
import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import TextInput from 'atoms/TextInput';
import IAccept from 'components/IAccept';
import { email as emailValidation } from 'utils/validations';

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-destructuring */
class Insurance extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			personResponsible: '',
			personResponsibleDob: '',
			personResponsiblePhone: '',
			personResponsibleAddress: '',
			personResponsibleEmail: '',
			patientHere: 'yes',
			occupation: '',
			employer: '',
			employerAddress: '',
			employerPhone: '',
			patientCoveredByInsurance: 'yes',
			primaryInsurance: '',
			primarySubscriberName: '',
			primarySubscriberSS: '',
			primarySubscriberDob: '',
			primaryGroupNumber: '',
			primaryPolicyNumber: '',
			primarySubscriberRelationship: 'self',
			secondaryInsurance: '',
			secondarySubscriberName: '',
			secondaryGroupNumber: '',
			secondaryPolicyNumber: '',
			secondarySubscriberRelationship: 'self',
		};
	}

	changeState(patientIntakeFields) {
		const { insurance } = patientIntakeFields;
		if (insurance) {
			// const { insurance } = patientIntake;
			this.setState({
				personResponsible:
					this.state.personResponsible || insurance.personResponsible,
				personResponsibleDob:
					this.state.personResponsibleDob || insurance.personResponsibleDob,
				personResponsiblePhone:
					this.state.personResponsiblePhone || insurance.personResponsiblePhone,
				personResponsibleAddress:
					this.state.personResponsibleAddress ||
					insurance.personResponsibleAddress,
				personResponsibleEmail:
					this.state.personResponsibleEmail || insurance.personResponsibleEmail,
				patientHere: this.state.patientHere || insurance.patientHere,
				occupation: this.state.occupation || insurance.occupation,
				employer: this.state.employer || insurance.employer,
				employerAddress:
					this.state.employerAddress || insurance.employerAddress,
				employerPhone: this.state.employerPhone || insurance.employerPhone,
				patientCoveredByInsurance:
					this.state.patientCoveredByInsurance ||
					insurance.patientCoveredByInsurance,
				primaryInsurance:
					this.state.primaryInsurance || insurance.primaryInsurance,
				primarySubscriberName:
					this.state.primarySubscriberName || insurance.primarySubscriberName,
				primarySubscriberSS:
					this.state.primarySubscriberSS || insurance.primarySubscriberSS,
				primarySubscriberDob:
					this.state.primarySubscriberDob || insurance.primarySubscriberDob,
				primaryGroupNumber:
					this.state.primaryGroupNumber || insurance.primaryGroupNumber,
				primaryPolicyNumber:
					this.state.primaryPolicyNumber || insurance.primaryPolicyNumber,
				primarySubscriberRelationship:
					this.state.primarySubscriberRelationship ||
					insurance.primarySubscriberRelationship,
				secondaryInsurance:
					this.state.secondaryInsurance || insurance.secondaryInsurance,
				secondarySubscriberName:
					this.state.secondarySubscriberName ||
					insurance.secondarySubscriberName,
				secondaryGroupNumber:
					this.state.secondaryGroupNumber || insurance.secondaryGroupNumber,
				secondaryPolicyNumber:
					this.state.secondaryPolicyNumber || insurance.secondaryPolicyNumber,
				secondarySubscriberRelationship:
					this.state.secondarySubscriberRelationship ||
					insurance.secondarySubscriberRelationship,
			});
		}
	}

	componentDidMount() {
		if (
			this.props.patientIntakeFields.insurance &&
			Object.getOwnPropertyNames(this.props.patientIntakeFields.insurance)
				.length > 0
		) {
			this.changeState(this.props.patientIntakeFields);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.patientIntakeFields.insurance &&
			Object.getOwnPropertyNames(nextProps.patientIntakeFields.insurance)
				.length > 0
		) {
			this.changeState(nextProps.patientIntakeFields);
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleDateChange = (field) => (date) => {
		let dateFormat;

		if (date) {
			dateFormat = parseISO(moment(date).format('YYYY-MM-DD'));
		}
		this.setState({
			[field]: dateFormat,
		});
	};

	prevProgress = () => {
		const { prevProgress } = this.props;

		prevProgress();
	};

	handleNumberChange = (id, values) => {
		this.setState({ [id]: values.value });
	};

	nextProgress = (event) => {
		const { nextProgress, auth, insuranceInformationreChecked } = this.props;
		const data = {};

		data.id = auth.loggedInUser.id;

		data.insurance = {
			personResponsible: this.state.personResponsible,
			personResponsibleDob: this.state.personResponsibleDob,
			personResponsiblePhone: this.state.personResponsiblePhone,
			personResponsibleAddress: this.state.personResponsibleAddress,
			personResponsibleEmail: this.state.personResponsibleEmail,
			patientHere: this.state.patientHere,
			occupation: this.state.occupation,
			employer: this.state.employer,
			employerAddress: this.state.employerAddress,
			employerPhone: this.state.employerPhone,
			patientCoveredByInsurance: this.state.patientCoveredByInsurance,
			primaryInsurance: this.state.primaryInsurance,
			primarySubscriberName: this.state.primarySubscriberName,
			primarySubscriberSS: this.state.primarySubscriberSS,
			primarySubscriberDob: this.state.primarySubscriberDob,
			primaryGroupNumber: this.state.primaryGroupNumber,
			primaryPolicyNumber: this.state.primaryPolicyNumber,
			primarySubscriberRelationship: this.state.primarySubscriberRelationship,
			secondaryInsurance: this.state.secondaryInsurance,
			secondarySubscriberName: this.state.secondarySubscriberName,
			secondaryGroupNumber: this.state.secondaryGroupNumber,
			secondaryPolicyNumber: this.state.secondaryPolicyNumber,
			secondarySubscriberRelationship:
				this.state.secondarySubscriberRelationship,
		};
		data.checks = {
			insuranceInformationreChecked,
		};

		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();

		nextProgress(data)(event);
	};

	render() {
		const { insuranceInformationreChecked, handleAcceptance, isMobile } =
			this.props;

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mt-5">
					Insurance Information
				</h2>
				<span className="align-self-center text-center font-italic mb-5">
					INSURANCE INFORMATION IS FOR USE IN MEDICATION AND TREATMENT
					AUTHORIZATIONS
				</span>
				<Form>
					<div className="card d-flex flex-column p-4 bb-0">
						<div className="form-row">
							<div className="col-md-5 form-group">
								<TextInput
									label="PERSON RESPONSIBLE FOR BILL"
									value={this.state.personResponsible || ''}
									id="personResponsible"
									placeholder="Mc Kennedy"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3 form-group">
								<CustomDatePicker
									className="col-md-12"
									label={<span className="form-group">DATE OF BIRTH</span>}
									id="personResponsibleDob"
									selected={this.state.personResponsibleDob}
									handleDateChange={this.handleDateChange(
										'personResponsibleDob',
									)}
									placeholderText="Select Date"
									maxDate={new Date()}
								/>
							</div>
							<div className="col-md-4">
								<PhoneNumberInput
									label="PHONE NUMBER"
									id="personResponsiblePhone"
									value={this.state.personResponsiblePhone}
									placeholder="Enter 10 Digit Phone Number"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="PHONE NUMBER"
                  id="personResponsiblePhone"
                  value={this.state.personResponsiblePhone || ''}
                  placeholder="Enter 10 Digit Phone Number"
                  handleInput={this.handleChange}
                  validations={
                    this.state.personResponsible === ''
                      ? [isPhoneNumber]
                      : [required, isPhoneNumber]
                  }
                  maxLength="10"
                /> */}
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="ADDRESS (IF DIFFERENT THAN PATIENT ADDRESS)"
									value={this.state.personResponsibleAddress || ''}
									id="personResponsibleAddress"
									placeholder="56, Old Hype Road, San Jose"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6">
								<TextInput
									label="EMAIL ADDRESS"
									value={this.state.personResponsibleEmail || ''}
									id="personResponsibleEmail"
									placeholder="kennedy@gmail.com"
									handleInput={this.handleChange}
									validations={[emailValidation]}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-4 form-group">
								<label htmlFor="patientHere" className="form-font w-100">
									IS THIS PERSON A PATIENT HERE?
								</label>
								<div
									className="btn-group btn-group-toggle w-100"
									data-toggle="buttons"
								>
									<label
										className={
											this.state.patientHere === 'yes'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="patientHere"
											id="patientHereYes"
											autoComplete="off"
											value="yes"
											onChange={this.handleChange}
										/>
										Yes
									</label>
									<label
										className={
											this.state.patientHere === 'no'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="patientHere"
											id="patientHereNo"
											autoComplete="off"
											value="no"
											onChange={this.handleChange}
										/>
										No
									</label>
								</div>
							</div>
							<div className="col-md-4 form-group">
								<TextInput
									label="OCCUPATION"
									value={this.state.occupation || ''}
									id="occupation"
									placeholder="Occupation"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-4 form-group">
								<TextInput
									label="EMPLOYER"
									value={this.state.employer || ''}
									id="employer"
									placeholder="Employer"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="EMPLOYER ADDRESS"
									value={this.state.employerAddress || ''}
									id="employerAddress"
									placeholder="Employer Address"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6">
								<PhoneNumberInput
									label="EMPLOYER PHONE NUMBER"
									id="employerPhone"
									value={this.state.employerPhone}
									placeholder="Enter 10 Digit Employer Phone Number"
									handleInput={this.handleNumberChange}
								/>
								{/* <TextInput
                  label="EMPLOYER PHONE NUMBER"
                  value={this.state.employerPhone || ''}
                  id="employerPhone"
                  placeholder="Employer Phone Number"
                  handleInput={this.handleChange}
                  validations={[isPhoneNumber]}
                  maxLength="10"
                /> */}
							</div>
						</div>
					</div>
					<hr className="m-0" />
					<div className="card d-flex flex-column p-4 bt-0 bb-0">
						<div className="form-row">
							<div className="col-md-4 form-group">
								<label
									htmlFor="patientCoveredByInsurance"
									className="form-font w-100"
								>
									IS THE PATIENT COVERED BY INSURANCE?
								</label>
								<div
									className="btn-group btn-group-toggle w-100"
									data-toggle="buttons"
								>
									<label
										className={
											this.state.patientCoveredByInsurance === 'yes'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="patientCoveredByInsurance"
											id="patientCoveredByInsuranceYes"
											autoComplete="off"
											value="yes"
											onChange={this.handleChange}
										/>
										Yes
									</label>
									<label
										className={
											this.state.patientCoveredByInsurance === 'no'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="patientCoveredByInsurance"
											id="patientCoveredByInsuranceNo"
											autoComplete="off"
											value="no"
											onChange={this.handleChange}
										/>
										No
									</label>
								</div>
							</div>
							<div className="col-md-8 form-group">
								<TextInput
									label="PLEASE INDICATE PRIMARY INSURANCE"
									value={this.state.primaryInsurance || ''}
									id="primaryInsurance"
									placeholder="Primary Insurance"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="SUBSCRIBER'S NAME"
									value={this.state.primarySubscriberName || ''}
									id="primarySubscriberName"
									placeholder="Subscriber's Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3">
								<TextInput
									label="SUBSCRIBER'S SS"
									value={this.state.primarySubscriberSS || ''}
									id="primarySubscriberSS"
									placeholder="Subscriber's SS"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3 form-group">
								<CustomDatePicker
									className="form-group"
									label={<span className="form-font"> DATE OF BIRTH</span>}
									id="primarySubscriberDob"
									selected={this.state.primarySubscriberDob}
									handleDateChange={this.handleDateChange(
										'primarySubscriberDob',
									)}
									placeholderText="Select Date"
									maxDate={new Date()}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-3 form-group">
								<TextInput
									label="GROUP NUMBER"
									value={this.state.primaryGroupNumber || ''}
									id="primaryGroupNumber"
									placeholder="Group Number"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3 form-group">
								<TextInput
									label="POLICY NUMBER"
									value={this.state.primaryPolicyNumber || ''}
									id="primaryPolicyNumber"
									placeholder="Policy Number"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-6">
								<label
									htmlFor="primarySubscriberRelationship"
									className="form-font"
								>
									PATIENT&apos;S RELATIONSHIP TO SUBSCRIBER
								</label>
								<div
									className="btn-group btn-group-toggle w-100"
									data-toggle="buttons"
								>
									<label
										className={
											this.state.primarySubscriberRelationship === 'self'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="primarySubscriberRelationship"
											id="primarySubscriberSelf"
											autoComplete="off"
											onChange={this.handleChange}
											value="self"
										/>
										Self
									</label>
									<label
										className={
											this.state.primarySubscriberRelationship === 'child'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="primarySubscriberRelationship"
											id="primarySubscriberChild"
											autoComplete="off"
											onChange={this.handleChange}
											value="child"
										/>
										Child
									</label>
									<label
										className={
											this.state.primarySubscriberRelationship === 'spouse'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="primarySubscriberRelationship"
											id="primarySubscriberSpouse"
											autoComplete="off"
											onChange={this.handleChange}
											value="spouse"
										/>
										Spouse
									</label>
									<label
										className={
											this.state.primarySubscriberRelationship === 'other'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="primarySubscriberRelationship"
											id="primarySubscriberOther"
											autoComplete="off"
											onChange={this.handleChange}
											value="other"
										/>
										Other
									</label>
								</div>
							</div>
						</div>
					</div>
					<hr className="m-0" />
					<div className="card d-flex flex-column p-4 bt-0 bb-0">
						<div className="form-row">
							<div className="col-md-12 form-group">
								<TextInput
									label="NAME OF SECONDARY INSURANCE (IF APPLICABLE)"
									value={this.state.secondaryInsurance || ''}
									id="secondaryInsurance"
									placeholder="Secondary Insurance"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 form-group">
								<TextInput
									label="SUBSCRIBER'S NAME"
									value={this.state.secondarySubscriberName || ''}
									id="secondarySubscriberName"
									placeholder="Subscriber's Name"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3 form-group">
								<TextInput
									label="GROUP NUMBER"
									value={this.state.secondaryGroupNumber || ''}
									id="secondaryGroupNumber"
									placeholder="Group Number"
									handleInput={this.handleChange}
								/>
							</div>
							<div className="col-md-3 form-group">
								<TextInput
									label="POLICY NUMBER"
									value={this.state.secondaryPolicyNumber || ''}
									id="secondaryPolicyNumber"
									placeholder="Policy Number"
									handleInput={this.handleChange}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6">
								<label
									htmlFor="secondarySubscriberRelationship"
									className="form-font"
								>
									PATIENT&apos;S RELATIONSHIP TO SUBSCRIBER
								</label>
								<div
									className="btn-group btn-group-toggle w-100"
									data-toggle="buttons"
								>
									<label
										className={
											this.state.secondarySubscriberRelationship === 'self'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="secondarySubscriberRelationship"
											id="secondarySubscriberSelf"
											autoComplete="off"
											onChange={this.handleChange}
											value="self"
										/>
										Self
									</label>
									<label
										className={
											this.state.secondarySubscriberRelationship === 'child'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="secondarySubscriberRelationship"
											id="secondarySubscriberChild"
											autoComplete="off"
											onChange={this.handleChange}
											value="child"
										/>
										Child
									</label>
									<label
										className={
											this.state.secondarySubscriberRelationship === 'spouse'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="secondarySubscriberRelationship"
											id="secondarySubscriberSpouse"
											autoComplete="off"
											onChange={this.handleChange}
											value="spouse"
										/>
										Spouse
									</label>
									<label
										className={
											this.state.secondarySubscriberRelationship === 'other'
												? 'btn border toggle-options radio-active'
												: 'btn border text-secondary toggle-options'
										}
									>
										<input
											type="radio"
											name="secondarySubscriberRelationship"
											id="secondarySubscriberOther"
											autoComplete="off"
											onChange={this.handleChange}
											value="other"
										/>
										Other
									</label>
								</div>
							</div>
						</div>
					</div>
					<hr className="m-0" />
					<div className="card d-flex flex-column p-4 mb-4 bt-0">
						<div className="pt-5 pb-5">
							<span>
								The above information is true and complete to the best of my
								knowledge. In the event Mind Therapy Clinic submits claims on my
								behalf to my healthcare insurance provider, I authorize my
								insurance benefits to be paid directly to Mind Therapy Clinic. I
								understand that I am financially responsible for any balance
								that is not paid by my insurance and that Mind Therapy Clinic
								cannot guarantee the extent to which my care will be paid by
								insurance. I also authorize Mind Therapy Clinic or insurance
								company to release any information required to process my
								claims.{' '}
							</span>
						</div>

						<IAccept
							handleAcceptance={handleAcceptance}
							name="insuranceInformationreChecked"
							value={insuranceInformationreChecked}
						/>
					</div>
					<div className="d-flex mt-4 mb-5 justify-content-center">
						<div className="col-6">
							<PrevButton onClick={this.prevProgress} isMobile={isMobile} />
						</div>
						<div className="col-6">
							<NextButton onClick={this.nextProgress} isMobile={isMobile} />
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

Insurance.propTypes = {
	patientIntakeFields: PropTypes.object.isRequired,
	insuranceInformationreChecked: PropTypes.bool.isRequired,
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default Insurance;
