/* eslint-disable consistent-return */

/**
 *
 * TherapiesTable
 *
 */
import moment from 'moment';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import ImageListCell from 'atoms/TableCell/ImageListCell';
import TextLinkCell from 'atoms/TableCell/TextLinkCell';
import { headerStyle } from 'containers/App/constants';
import { noop } from 'utils/common';

import NextSessionCell from './cells/NextSession';
import './index.css';

const defaultHandlers = {
	checkIn: noop,
};

export const getColumns = (type, { checkIn } = defaultHandlers) => {
	const userCols = {
		patient: [
			'therapy',
			'upcomingSession',
			'lastSession',
			'clinician',
			'location',
		],
		clinician: [
			'therapy',
			'size',
			'location',
			'lastSession',
			'upcomingSession',
			'clients',
			'status',
		],
		admin: [
			'therapy',
			'size',
			'location',
			'lastSession',
			'upcomingSession',
			'clients',
			'status',
		],
	};
	const columns = {
		therapy: {
			field: 'groupName',
			title: 'NAME',
			headerStyle,
			render: (row) => (
				<TextLinkCell
					title={row.groupName}
					linkRoute={`/${type}/dashboard/groupDetails/${row.programId}`}
					data={row}
				/>
			),
		},
		size: {
			field: 'groupSize',
			title: 'SIZE',
			headerStyle,
			render: (row) => <div className="mt-2 font-13">{row.groupSize}</div>,
		},
		location: {
			field: 'location',
			title: 'LOCATION',
			headerStyle,
			render: (row) => <div className="mt-2 font-13">{row.location}</div>,
		},
		lastSession: {
			field: 'lastSession',
			title: 'LAST SESSION',
			headerStyle,
			render: (row) => (
				<div className="mt-2 font-13">
					{row.lastSession ? moment(row.lastSession).format('YYYY-MM-DD') : '-'}
				</div>
			),
		},
		upcomingSession: {
			field: 'upcomingSession',
			// Margin is offset to help it look centered due to sorting arrow
			title: 'NEXT SESSION',
			headerStyle: {
				...headerStyle,
			},
			render: (row) => (
				<NextSessionCell {...row} type={type} checkIn={checkIn} />
			),
		},
		clinician: {
			field: 'clinician',
			title: 'CLINICIAN',
			headerStyle,
			render: (row) =>
				row.clinician && (
					<ImageDetailsCell
						src={row.clinician.profilePicture}
						heading={row.clinician.clinicianName}
						primarySubHeading={row.clinician.therapistType}
					/>
				),
		},
		status: {
			field: 'inPerson',
			title: 'STATUS',
			headerStyle,
			render: (row) => (
				<div className="mt-2 mx-0 font-13">
					{row.inPerson ? 'In Person' : 'Remote'}
				</div>
			),
		},
		clients: {
			field: 'clientsEnrolled',
			title: 'CLIENTS ENROLLED',
			headerStyle: { ...headerStyle, width: '175px' },
			render: (row) =>
				row.clientsEnrolled.length > 0 ? (
					<ImageListCell imageUrls={row.clientsEnrolled} />
				) : (
					'-'
				),
			cellStyle: {
				width: 200,
			},
		},
	};

	return userCols[type].map((c) => columns[c]);
};
