import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the forms state domain
 */

const selectFormsDomain = (state) => state.get('forms', initialState);

/**
 * Other specific selectors
 */

const makeSelectFormShare = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('formShareVisible')),
	);

const makeSelectIsUploading = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('isUploading')),
	);

const makeSelectUploadResult = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('uploadResult')),
	);

const makeSelectFormsList = () =>
	createSelector(selectFormsDomain, (substate) => ({
		templates: returnJS(substate.get('templates')),
		sharedForms: returnJS(substate.get('sharedForms')),
	}));

const makeSelectErrors = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('errors')),
	);

const makeSelectIsFetching = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('isFetching')),
	);

const makeSelectIsUpdating = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('isUpdating')),
	);

const makeSelectIsSending = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('isSending')),
	);

const makeSelectClients = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('clients')),
	);

const makeSelectGroups = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('groups')),
	);

const makeSelectSigningUrl = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('signingUrl')),
	);

const makeSelectFileToEdit = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('file')),
	);

const makeSelectUrl = () =>
	createSelector(selectFormsDomain, (substate) =>
		returnJS(substate.get('url')),
	);

/**
 * Default selector used by Forms
 */

const makeSelectForms = () =>
	createSelector(selectFormsDomain, (substate) => returnJS(substate));

export default makeSelectForms;
export {
	selectFormsDomain,
	makeSelectFormShare,
	makeSelectIsUploading,
	makeSelectUploadResult,
	makeSelectFormsList,
	makeSelectErrors,
	makeSelectIsFetching,
	makeSelectIsUpdating,
	makeSelectClients,
	makeSelectGroups,
	makeSelectSigningUrl,
	makeSelectIsSending,
	makeSelectFileToEdit,
	makeSelectUrl,
};
