import PropTypes from 'prop-types';

import React from 'react';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import tick from 'images/tick.gif';
import { numbersOnly } from 'utils/validations';

import './index.css';

/**
 *
 * Verification
 *
 */

/* eslint-disable react/prefer-stateless-function */
class Verification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			code: 0,
			phone: '',
			inputs: 4,
			count: 0,
			hasErrored: false,
		};

		this.keyListener = null;
	}

	toggleSelection = () => {
		this.setState({ hasErrored: false });
		const { inputs } = this.state;
		const { phoneValidation } = this.props;

		if (inputs === 4) {
			this.setState({ inputs: 10, phone: '', count: 0 });
			phoneValidation(false);
		} else {
			this.setState({ inputs: 4, code: 0 });
			phoneValidation(true);
		}
	};

	phoneValidationInternal = () => {
		const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/m;
		let errorPhone = false;

		const { phone } = this.state;
		// eslint-disable-next-line no-unused-expressions
		if (!phone) {
			errorPhone = true;
		} else if (phoneReg.test(phone)) {
			errorPhone = false;
		} else {
			errorPhone = true;
		}

		if (errorPhone) {
			return false;
		}
		return true;
	};

	updateNumber = () => {
		this.setState({ hasErrored: false });
		const { phone } = this.state;
		const { signUp, storeData, checkUser, displayError } = this.props;
		const isPhoneValidated = this.phoneValidationInternal();

		const msg = 'Invalid Number';
		const twilioPhoneValidation = signUp.phoneValidated;

		if (!isPhoneValidated && !twilioPhoneValidation) {
			displayError(msg);
			return;
		}

		if (phone > 0) {
			const data = {
				...signUp,
				phone,
			};
			storeData(data);
			checkUser();

			this.setState({ inputs: 4, code: 0 });
		}
	};

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	resendOtp = () => {
		this.setState((prevState) => ({
			count: prevState.count + 1,
		}));
		const { sendOtp } = this.props;
		sendOtp();
	};

	handleOtpInput = (value) => {
		if (value.length > 0) {
			const isNumber = numbersOnly(value);
			if (!isNumber) {
				this.setState({ code: value });
			} else {
				this.setState({ hasErrored: true });
			}
		}
	};

	handleEnter = () => {
		const { validateOtp } = this.props;
		const { inputs, code } = this.state;

		if (inputs === 4) {
			validateOtp(code);
		}
	};

	componentDidMount = () => {
		this.keyListener = document.addEventListener('keydown', (e) => {
			if (e.key === 'Enter') {
				this.handleEnter();
			}
		});
	};

	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.keyListener);
	};

	render() {
		const { signUp, hide, validateOtp } = this.props;
		const { modal, code, inputs, phone, count, hasErrored } = this.state;

		const countries = ['US', 'IN'];

		return (
			<div>
				<Modal
					isOpen={modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={hide} className="modal-header-custom px-5">
						<div className="modal-header-custom-content justify-content-center text-center">
							{inputs === 4 ? 'Verification Code' : 'Edit Number'}
						</div>
					</ModalHeader>
					<ModalBody className="px-5 pt-0">
						<div className="d-flex flex-column">
							<div className="modal-body-custom-content">
								We have sent the verification code to your number
								{`${signUp.phone} `}
								by SMS.
								<span
									className="ml-1 link-color hand"
									onClick={this.toggleSelection}
								>
									{inputs === 4 ? 'Edit Number' : 'Cancel'}
								</span>
							</div>
							{signUp.errorMessage && (
								<div className="d-flex justify-content-center error-font mb-2 mt-3">
									{signUp.errorMessage}
								</div>
							)}
							<div className="code-font d-flex justify-content-center my-2 flex-column">
								{inputs === 4 ? (
									<OtpInput
										onChange={(value) => this.handleOtpInput(value)}
										containerStyle="col-12 p-0 otp justify-content-center"
										inputStyle="border m-2 rounded"
										numInputs={4}
										value={this.state.code}
										renderInput={(props) => <input {...props} />}
									/>
								) : (
									<PhoneInput
										placeholder="Enter phone number"
										value={phone}
										international={false}
										country="US"
										countries={countries}
										className="col-12 p-0 phone-number-font"
										onChange={(value) => this.setState({ phone: value })}
									/>
								)}
							</div>
							{hasErrored && (
								<span className="form-error mb-2">
									This OTP consists only of numbers.
								</span>
							)}
							<div className="d-flex justify-content-between mb-2">
								<button
									type="button"
									className="btn btn-color btn-primary w-100 modal-verify-now text-uppercase"
									onClick={
										inputs === 4 ? () => validateOtp(code) : this.updateNumber
									}
								>
									{inputs === 4 ? 'VERIFY NOW' : 'UPDATE'}
								</button>
							</div>
							{count !== 0 && (
								<div className="mt-2 d-flex justify-content-center modal-if-you">
									<span className="mr-2">OTP resent </span>

									<img src={tick} height="20" width="20" alt="TodosInactive" />
								</div>
							)}
							{inputs === 4 && (
								<div className="mt-2 d-flex justify-content-center modal-if-you">
									If you did not receive the code?
									<span
										className="ml-1 link-color hand"
										onClick={this.resendOtp}
									>
										Resend
									</span>
								</div>
							)}
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

Verification.propTypes = {
	signUp: PropTypes.object.isRequired,
	hide: PropTypes.func.isRequired,
	storeData: PropTypes.func.isRequired,
	checkUser: PropTypes.func.isRequired,
	sendOtp: PropTypes.func.isRequired,
	phoneValidation: PropTypes.func.isRequired,
	validateOtp: PropTypes.func.isRequired,
	displayError: PropTypes.func.isRequired,
};

export default Verification;
