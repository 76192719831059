import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectAuth } from 'containers/App/selectors';
import { fetchStatesAPI } from 'containers/Geo/api';
import { findUser } from 'utils/api';

import {
	error,
	fetchStatesError,
	fetchStatesSuccess,
	success,
	successCheckOnboading,
	uploadCredentialError,
	uploadCredentialSuccess,
} from './actions';
import {
	clinicianRegistrationAPI,
	fetchClinicianData,
	uploadCredentialAPI,
} from './api';
import {
	CLINICIAN_REGISTRATION,
	FETCH_DATA,
	FETCH_STATES,
	SUCCESS,
	UPLOAD_CREDENTIAL,
} from './constants';
import makeSelectClinicianRegister from './selectors';

export function* performRegistration() {
	const clinicianRegister = yield select(makeSelectClinicianRegister());
	try {
		const response = yield call(clinicianRegistrationAPI, clinicianRegister);
		yield put(success(response));
	} catch (err) {
		yield put(error());
	}
}

export function* checkOnboarding() {
	const clinicianRegister = yield select(makeSelectClinicianRegister());

	try {
		// api call
		const response = yield call(findUser, clinicianRegister);
		yield put(successCheckOnboading(response));
	} catch (err) {
		yield put(error());
	}
}

export function* fetchData() {
	const clinicianRegister = yield select(makeSelectClinicianRegister());
	try {
		const response = yield call(fetchClinicianData, clinicianRegister);
		yield put(success(response));
	} catch (err) {
		yield put(error());
	}
}

export function* fetchAllStates() {
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(fetchStatesAPI);
		yield put(fetchStatesSuccess(response));
	} catch (err) {
		yield put(fetchStatesError(err));
	}
}

export function* uploadCredential(action) {
	const { loggedInUser } = yield select(makeSelectAuth());
	const data = {
		id: loggedInUser.id,
		document: 'credential',
	};
	try {
		// Call our request helper (see 'utils/api')
		const response = yield call(uploadCredentialAPI, data, {
			file: action.files[0],
		});
		yield put(uploadCredentialSuccess(response));
	} catch (err) {
		yield put(uploadCredentialError(err));
	}
}

// Individual exports for testing
export default function* clinicianRegisterSaga() {
	// See example in containers/HomePage/saga.js
	yield takeLatest(UPLOAD_CREDENTIAL, uploadCredential);
	yield takeLatest(FETCH_STATES, fetchAllStates);
	yield takeLatest(CLINICIAN_REGISTRATION, performRegistration);
	yield takeLatest(SUCCESS, checkOnboarding);
	yield takeLatest(FETCH_DATA, fetchData);
}
