import history from 'utils/history';

import { uniqBy } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SearchBar from 'atoms/SearchBar';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import KebabMenuCell from 'atoms/TableCell/KebabMenuCell';
import TherapyCard from 'cards/TherapyCard';
import ButtonToolBar from 'components/ButtonToolBar';
import DownloadReportModal from 'components/DownloadReportModal';
import FilterNav from 'components/FilterNav';
import StatusModal from 'components/RequestSentModal';
import UpdateGroup from 'components/UpdateGroup';
import ValidateModal from 'components/ValidateSentModal';
import { loadClinicians } from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectClinicians,
	makeSelectIsMobile,
} from 'containers/App/selectors';
import { saveAttendance } from 'containers/Attendance/actions';
import { compareDates, compareWords } from 'utils/columnCompare';
import { filterByLetter, filterData, getFilterOptions } from 'utils/helpers';

import { getColumns } from './TherapiesTable';
import {
	createProgram,
	deactivateProgram,
	deleteProgram,
	downloadAttendanceReport,
	loadGroupTypes,
	loadPrograms,
	updateProgram,
} from './actions';
import groupFilters from './filters';
import './index.css';
import makeSelectGroups, {
	makeSelectAllPrograms,
	makeSelectIsFetching,
} from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class Therapies extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCreateGroup: false,
			showStatusModal: false,
			showDeactivateModal: false,
			showDeleteModal: false,
			editData: {},
			mode: '',
			allGroups: [],
			showDownloadReportModal: false,
			programToBeDeleted: '',
			programToBeDeactivated: '',
			filters: [],
			therapySearch: '',
			sentUpdate: false,
			showInactive: false,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(loadGroupTypes());
		dispatch(loadClinicians());
		dispatch(loadPrograms());
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.programs) {
			this.setState({
				allGroups: nextProps.programs,
			});
		}

		if (this.state.sentUpdate && !nextProps.isUpdating) {
			this.setState({
				sentUpdate: false,
				successState: true,
				showStatusModal: true,
				customMessage: 'Therapy Updated Successfully',
			});
		}
	}

	toggleInactive = () => {
		this.setState({ showInactive: !this.state.showInactive });
	};

	closeRequestSentModal = () => {
		this.setState({
			showStatusModal: false,
			customMessage: '',
		});
	};

	handleHide = () => {
		this.setState((prevState) => ({
			showCreateGroup: !prevState.showCreateGroup,
		}));
	};

	handleEdit = (row) => {
		this.setState({
			editData: row,
			mode: 'edit',
		});
		this.handleHide();
	};

	handleCreate = () => {
		this.setState({
			mode: 'create',
		});
		this.handleHide();
	};

	showDownloadReportModal = () => {
		this.setState((prevState) => ({
			showDownloadReportModal: !prevState.showDownloadReportModal,
		}));
	};

	closeDownloadReportModal = () => {
		this.setState({
			showDownloadReportModal: false,
		});
	};

	handleMonthChange = (month) => {
		this.setState({
			DownloadReportMonth: month.value,
		});
	};

	handleYearChange = (year) => {
		this.setState({
			DownloadReportYear: year.value,
		});
	};

	handleDownloadReport = () => {
		const { AttendanceReport } = this.props;
		AttendanceReport({
			month: parseInt(this.state.DownloadReportMonth, 10),
			year: parseInt(this.state.DownloadReportYear, 10),
		});
	};

	handleDeactivateModal = (row) => {
		this.setState({
			programToBeDeactivated: row.programId,
		});
		this.toggleDeactivateModal();
	};

	toggleDeactivateModal = () => {
		this.setState((prevState) => ({
			showDeactivateModal: !prevState.showDeactivateModal,
		}));
	};

	handleDeleteModal = (row) => {
		this.setState({
			programToBeDeleted: {
				id: row.programId,
				name: row.groupName,
			},
		});
		this.toggleDeleteModal();
	};

	toggleDeleteModal = () => {
		this.setState((prevState) => ({
			showDeleteModal: !prevState.showDeleteModal,
		}));
	};

	getButton = () => [
		{
			label: 'ADD NEW THERAPY',
			className: 'btn btn-primary btn-color font-10 mr-2',
			handleClick: this.handleCreate,
		},
		{
			label: 'DOWNLOAD REPORT',
			className: 'btn btn-primary btn-color font-10 mr-2',
			handleClick: this.showDownloadReportModal,
		},
	];

	createProgramAndSession = (data) => {
		const { dispatch } = this.props;
		dispatch(createProgram(data));
		this.setState({ showStatusModal: true });
	};

	getMenu = () => {
		const headerStyle = {
			color: '#999999',
		};
		const { auth, type } = this.props;
		if (type === 'patient') {
			return {
				title: '',
				headerStyle: { ...headerStyle, width: '10px', marginLeft: '10px' },
				render: (row) => (
					<KebabMenuCell
						dropdownItems={[
							{
								id: 1,
								name: 'Therapy Details',
								callback: () =>
									history.push({
										pathname: `/${auth.loggedInUser.roleName}/dashboard/groupDetails/${row.programId}`,
										data: row,
									}),
								args: [],
								enabled: true,
							},
						]}
						direction={row.tableData.id > 3 ? 'up' : 'down'}
					/>
				),
			};
		}

		return {
			title: '',
			headerStyle: { ...headerStyle, width: '10px', marginLeft: '10px' },
			render: (row) => (
				<KebabMenuCell
					dropdownItems={this.generateKebabItems(row)}
					direction={row.tableData.id > 3 ? 'up' : 'down'}
				/>
			),
		};
	};

	generateKebabItems = (row) => {
		const { auth } = this.props;
		const rowIndex = row.tableData.id;
		const items = [];

		items.push({
			id: 1,
			name: 'Therapy Details',
			callback: () =>
				history.push({
					pathname: `/${auth.loggedInUser.roleName}/dashboard/groupDetails/${row.programId}`,
					data: row,
				}),
			args: [],
			enabled: true,
		});
		items.push({
			id: 2,
			name: 'Edit Therapy',
			callback: this.handleEdit,
			args: [row, rowIndex],
			enabled: true,
		});
		row.isActive &&
			items.push({
				id: 5,
				name: 'Deactivate',
				callback: this.handleDeactivateModal,
				args: [row],
				enabled: true,
			});
		items.push({
			id: 6,
			name: 'Delete',
			callback: this.handleDeleteModal,
			args: [row],
			enabled: true,
		});

		return items;
	};

	generateFilters = () => {
		const { programs } = this.props;
		return {
			location: getFilterOptions(programs, 'location'),
			groupSize: getFilterOptions(programs, 'groupSize'),
			clinicianName: getFilterOptions(programs, 'clinician.clinicianName'),
			inPerson: this.getStatusFilter(programs),
		};
	};

	getStatusFilter = (programs) => {
		const filters = [];

		if (!Array.isArray(programs)) programs = [];
		if (programs) {
			programs.forEach((program) => {
				const status = program.inPerson ? 'In Person' : 'Remote';
				if (filters.indexOf(status) === -1) filters.push(status);
			});
		}

		return filters;
	};

	addFilter = (filter, replace = false) => {
		const { filters } = this.state;
		let newFilters = [...filters];

		if (replace) {
			newFilters = newFilters.filter((x) => x.name !== filter.name);
		}

		newFilters.push(filter);

		this.setState({ filters: newFilters });
	};

	removeFilter = (name) => {
		let { filters } = this.state;

		filters = filters.filter((x) => x.name !== name);

		this.setState({ filters });
	};

	filterByLetter = (name, value, programs) => {
		let path = '';
		if (name === 'clinicianName') {
			path = 'clinician.clinicianName';
		} else {
			path = name;
		}

		let filteredData = programs;
		if (name === 'status') {
			const status = value === 'ACTIVE';
			filteredData = programs.filter((program) => {
				// eslint-disable-next-line no-unneeded-ternary
				const isActive = program.isActive ? true : false;
				return isActive === status;
			});
		} else {
			filteredData = filterByLetter(programs, path, value);
		}

		return filteredData;
	};

	filterDropdown = (name, value, programs) => {
		let path = '';
		if (name === 'clinicianName') {
			path = 'clinician.clinicianName';
		} else {
			path = name;
		}

		let filteredData = programs;
		if (name === 'status') {
			const status = value === 'In Person';
			filteredData = programs.filter((program) => {
				// eslint-disable-next-line no-unneeded-ternary
				const inPerson = program.inPerson ? true : false;
				return inPerson === status;
			});
		} else {
			filteredData = filterData(programs, path, value);
		}

		return filteredData;
	};

	searchForTherapy = () => {
		const { programs } = this.props;
		const { therapySearch } = this.state;

		let dropdownList = [];

		if (Array.isArray(programs)) {
			dropdownList = programs.map((x) => ({
				label: x.groupName,
				value: x.groupName,
			}));
			dropdownList = dropdownList.filter((x) => x.label);
			dropdownList = uniqBy(dropdownList, (x) => x.label);
			dropdownList = dropdownList.sort((a, b) =>
				compareWords(a.label, b.label, 'asc'),
			);
		}

		return (
			<SearchBar
				value={therapySearch}
				onSearch={(value) => this.setState({ therapySearch: value })}
				style={{
					searchBox: { height: 52 },
				}}
				dropdownList={dropdownList}
				placeholder="Search for Therapy"
			/>
		);
	};

	updateProgram = (data) => {
		const { doUpdateProgram } = this.props;

		doUpdateProgram(data);
		this.setState({ sentUpdate: true });
	};

	checkIn = (sessionId) => {
		const { doCheckIn, auth } = this.props;

		const data = [
			{
				session: { sessionId },
				status: 'present',
				clientId: auth?.loggedInUser?.id,
				comment: '',
			},
		];

		doCheckIn({ attendance: data });
	};

	render() {
		const {
			type,
			groups,
			clinicians,
			auth,
			DeleteProgram,
			DeactivateProgram,
			isFetching,
			isMobile,
			programs = [],
			dispatch,
		} = this.props;
		const { filters, therapySearch, customMessage, showInactive } = this.state;

		const successStatus = groups.createProgramSuccess;

		let allGroups = programs;
		if (!showInactive) {
			allGroups = allGroups.filter((group) => group.isActive);
		}

		filters.forEach((filter) => {
			allGroups = filter.callback(filter.name, filter.option, allGroups);
		});
		if (!Array.isArray(allGroups)) allGroups = [];

		allGroups = filterByLetter(allGroups, 'groupName', therapySearch);
		allGroups = allGroups.sort((a, b) =>
			compareDates(a.upcomingSession, b.upcomingSession, 'asc'),
		);

		let myGroups = getColumns(auth.loggedInUser.roleName, {
			checkIn: this.checkIn,
		});
		if (type !== 'patient') {
			myGroups = myGroups.concat(this.getMenu());
		}

		const successMessage = (
			<div>
				<span>{customMessage || 'Therapy Creation Successful'}</span>
				<span className="d-block font-10 text-cust-grey text-uppercase mb-n15">
					Sessions for this therapy have been created for the next 12 months.
				</span>
				<span className="d-block font-10 text-cust-grey text-uppercase">
					Please contact admin to extend sessions.
				</span>
			</div>
		);
		const message = successStatus ? successMessage : 'Therapy Creation Failed';
		const { showCreateGroup, mode, editData } = this.state;

		const buttons = this.getButton();

		function MobileView() {
			return (
				<div
					className="row mx-0"
					style={{ overflowY: 'auto', overflowX: 'hidden' }}
				>
					{isFetching ? (
						<SmallLoadingIndicator
							text={'Loading...'}
							color="var(--main-color)"
						/>
					) : (
						allGroups.map((group) => (
							<TherapyCard group={group} type={type} key={group.programId} />
						))
					)}
				</div>
			);
		}

		const filterList = groupFilters(this.generateFilters(), type);
		const rowStyle = (rowData) => {
			if (!rowData.isActive) {
				return {
					backgroundColor: 'rgb(255 231 231)',
				};
			}
		};

		return (
			<div
				className={`container-fluid ${isMobile ? 'px-2 mt-1' : 'px-5 mt-5'}`}
			>
				<FilterNav
					filtersList={filterList}
					filterDropdown={this.filterDropdown}
					addFilter={this.addFilter}
					removeFilter={this.removeFilter}
					handleCheck={this.toggleInactive}
					currentFilters={filters}
					isMobile={isMobile}
					dispatch={dispatch}
					search={this.searchForTherapy()}
				>
					{type !== 'patient' &&
						isMobile &&
						buttons.map((b) => (
							<button
								className={`btn-lg my-2 ${b.className}`}
								onClick={b.handleClick}
								key={b.label}
							>
								{b.label}
							</button>
						))}
				</FilterNav>
				{type !== 'patient' && !isMobile && (
					<ButtonToolBar
						title="List of Therapies"
						buttons={auth.loggedInUser.roleName === 'patient' ? [] : buttons}
					/>
				)}
				{isMobile ? (
					<MobileView />
				) : (
					<Table
						data={allGroups || []}
						columns={myGroups}
						placeholder="No groups to display"
						loading={isFetching}
						rowStyle={rowStyle}
					/>
				)}
				{showCreateGroup && (
					<UpdateGroup
						role={auth.loggedInUser.roleName}
						id={auth.loggedInUser.id}
						type={type}
						hide={this.handleHide}
						mode={mode}
						groupTypes={groups.groupTypes}
						clinicians={clinicians}
						createProgram={this.createProgramAndSession}
						groupData={editData}
						updateProgram={this.updateProgram}
					/>
				)}
				{this.state.showStatusModal && (
					<StatusModal
						successStatus={successStatus}
						hide={this.closeRequestSentModal}
						message={message}
					/>
				)}
				{this.state.showDeactivateModal && (
					<StatusModal
						mode="confirmation"
						successStatus={false}
						hide={this.toggleDeactivateModal}
						handleDelete={DeactivateProgram}
						message="Are you sure?"
						id={{ id: this.state.programToBeDeactivated }}
					/>
				)}
				{this.state.showDeleteModal && (
					<ValidateModal
						successStatus={false}
						hide={this.toggleDeleteModal}
						handleDelete={DeleteProgram}
						message="Once deleted, a program and ALL of its data will be deleted forever! This action CANNOT Be undone."
						toBeDeleted={this.state.programToBeDeleted}
					/>
				)}
				{this.state.showDownloadReportModal && (
					<DownloadReportModal
						hide={this.closeDownloadReportModal}
						handleMonthChange={this.handleMonthChange}
						handleYearChange={this.handleYearChange}
						handleDownloadReport={this.handleDownloadReport}
					/>
				)}
			</div>
		);
	}
}

Therapies.propTypes = {
	type: PropTypes.string.isRequired,
	groups: PropTypes.any,
	programs: PropTypes.any,

	dispatch: PropTypes.func,
	clinicians: PropTypes.array,
	auth: PropTypes.object,
	AttendanceReport: PropTypes.func,
	DeactivateProgram: PropTypes.func,
	DeleteProgram: PropTypes.func,
	doUpdateProgram: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	groups: makeSelectGroups(),
	clinicians: makeSelectClinicians(),
	programs: makeSelectAllPrograms(),
	auth: makeSelectAuth(),
	isFetching: makeSelectIsFetching(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		// createProgramAndSession: data => dispatch(createProgram(data)),
		AttendanceReport: (data) => dispatch(downloadAttendanceReport(data)),
		DeactivateProgram: (data) => dispatch(deactivateProgram(data)),
		DeleteProgram: (data) => dispatch(deleteProgram(data)),
		doUpdateProgram: (data) => dispatch(updateProgram(data)),
		doCheckIn: (data) => dispatch(saveAttendance(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Therapies);
