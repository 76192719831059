export const actionButtonStyle = (showPopup, otherStyle) => ({
	container: {
		background: 'var(--main-grey)',
		color: 'white',
		fontSize: 14,
		whiteSpace: 'nowrap',
		right: 'none',
		left: '50%',
		top: 0,
		transform: `translate(-50%, -100%) ${
			showPopup ? 'scale(1)' : 'scale(0.85)'
		}`,
		...otherStyle,
	},
});
