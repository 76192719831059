import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import TextInput from 'atoms/CustTextInput';
import CustomDatePicker from 'atoms/CustomDatePicker';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import TextArea from 'atoms/TextArea';
import IAccept from 'components/IAccept';
import { required } from 'utils/validations';

class DiscontinueRequest extends React.Component {
	constructor(props) {
		super(props);
		this.type = 'discontinuance';
		this.state = {
			approved: false,
			denied: false,
		};
	}

	componentDidMount = () => {
		const {
			data: { state },
		} = this.props;

		if (state) {
			if (state === 'APPROVED') {
				this.setState({ approved: true, denied: false });
			} else if (state === 'DENIED') {
				this.setState({ approved: false, denied: true });
			}
		}
	};

	handleCheckBox = (e) => {
		const key = e.target.name;
		let other = 'denied';

		if (key === 'denied') {
			other = 'approved';
		}

		this.setState({
			[key]: !this.state[key],
			[other]: false,
		});
	};

	handleSelect = (name) => (options) => {
		const { readOnly, hasOutsideProgram, handleSelect, handleChange } =
			this.props;
		if (readOnly) {
			return;
		}

		let outsideProgram = false;
		options.forEach((option) => {
			if (option.value === 'other') {
				outsideProgram = true;
			}
		});

		if (outsideProgram !== hasOutsideProgram) {
			handleChange(this.type)({
				target: { value: outsideProgram, name: 'hasOutsideProgram' },
			});
		}

		handleSelect(this.type)(options, name);
	};

	handleAcceptance = (value) => {
		const { handleAcceptance } = this.props;
		if (this.closedRequest()) {
			return;
		}

		handleAcceptance(this.type)(value);
	};

	handleChange = (e) => {
		const { handleChange } = this.props;
		if (this.closedRequest()) {
			return;
		}

		handleChange(this.type)(e);
	};

	handleDateChange = (component) => (date) => {
		const { readOnly, handleDateChange } = this.props;
		if (readOnly) {
			return;
		}

		if (component === 'discontinueDate') {
			const today = moment();
			const requestDate = moment(date);
			let effectiveDate = requestDate;

			if (requestDate.diff(today, 'days') < 7) {
				effectiveDate = today.add(7, 'days');
			}

			handleDateChange(this.type, 'effectiveDate')(effectiveDate);
		}

		handleDateChange(this.type, component)(date);
	};

	onSubmit = () => {
		const { closeModal, onSubmit, role } = this.props;
		if (this.closedRequest()) {
			closeModal();
			return;
		}

		let status = 'OPEN';

		if (role !== 'patient') {
			status = this.state.approved ? 'APPROVED' : 'DENIED';
		}

		onSubmit(status);
	};

	formCompleted = () => {
		const { data, role } = this.props;
		const {
			clientName,
			programSelected,
			requestDate,
			confirmChecked,
			reasonForDenial,
			hasOutsideProgram,
			outsideProgram,
		} = data;

		const patientRequirements =
			clientName.length > 0 &&
			programSelected &&
			requestDate &&
			confirmChecked &&
			(!hasOutsideProgram || outsideProgram.length > 0);

		if (role === 'patient') {
			return patientRequirements;
		}

		const { approved, denied } = this.state;
		const requirements =
			(approved || denied) && (denied ? reasonForDenial?.length > 0 : true);

		return requirements;
	};

	closedRequest = () => {
		const { readOnly, data, role } = this.props;

		if (readOnly) {
			return true;
		}

		const { state } = data;

		if (!state) {
			return false;
		}

		if (state === 'APPROVED' || state === 'DENIED') {
			return true;
		} else {
			if (role === 'patient') {
				return true;
			} else {
				return false;
			}
		}
	};

	composePrograms = (programs) => {
		return programs.map((item) => ({
			label: item.groupName,
			value: item['program_id'],
		}));
	};

	render() {
		const { data, readOnly, programs, role, isMobile } = this.props;
		const { hasOutsideProgram } = data;
		const { approved, denied } = this.state;
		const groupList = readOnly ? [] : this.composePrograms(programs);
		if (!hasOutsideProgram) {
			groupList.push({
				label: 'Other',
				value: 'other',
			});
		}

		const closedRequest = this.closedRequest();

		let containerStyle = {};

		if (isMobile) {
			containerStyle = {
				width: '90%',
				maxHeight: '74vh',
				overflowY: 'auto',
				overflowX: 'hidden',
			};
		}

		const containerClass = `container-fluid bg-white py-3 ${
			isMobile ? 'px-2 mx-auto' : 'mx-4'
		}`;
		const inputGroupName = `${
			isMobile ? 'row' : 'd-flex'
		} align-items-center justify-content-center`;
		const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
		const inputStyle = { width: isMobile && '100%', maxWidth: '400px' };

		return (
			<div className="row custom-bg-color mx-0">
				<div className={containerClass} style={containerStyle}>
					<div className="py-2 custom-loa-text text-dark font-16 px-3">
						<p>
							Please note, Mind Therapy Clinic requires at least one (1) week
							notice to process this request.
						</p>
					</div>
					<div className="d-flex p-3 flex-column form-group mb-0">
						<div className={inputGroupName}>
							<div className={inputClassName} style={inputStyle}>
								<TextInput
									readOnly={readOnly}
									id="clientName"
									name="clientName"
									label="CLIENT NAME*"
									value={data.clientName}
									placeholder="Your Name"
									handleInput={this.handleChange}
									validations={[required]}
								/>
							</div>
							<div className={'col text-center font-16 pb-3'}>
								gives notice of request to discontinue
							</div>
						</div>
						<div className="d-flex align-items-center mt-2">
							<div className="col px-0">
								<SelectAutoComplete
									disabled={readOnly}
									label="THERAPIES*"
									handleChange={this.handleSelect('programSelected')}
									options={groupList}
									value={data.programSelected}
									placeholder="Therapy or Therapies to Discontinue"
									multiple={true}
								/>
							</div>
							{/* <div className="col-sm-1 mt-3 align-middle font-16">
                on
              </div>
              <div className="col-4 align-middle font-16">
                <CustomDatePicker
                  disabled={readOnly}
                  className="col-md-12"
                  label={<span className="form-group">REQUESTED DATE*</span>}
                  handleDateChange={this.handleDateChange('discontinueDate')}
                  id="toDate"
                  selected={data.discontinueDate}
                  placeholderText="Select from Date"
                />
              </div> */}
						</div>
						{hasOutsideProgram && (
							<div className="d-flex pt-3 flex-column form-group mb-0">
								<div className="d-flex align-items-center">
									<TextInput
										className="col px-0"
										readOnly={readOnly}
										id="outsideProgram"
										name="outsideProgram"
										label="OTHER THERAPIES*"
										value={data.outsideProgram}
										placeholder="Other Therapies"
										handleInput={this.handleChange}
										validations={[required]}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="d-flex p-3 pb-0 flex-column form-group mb-0 font-16 font-italic">
						I understand that I will be officially discharged one (1) week from
						the date of this request if approved by my primary therapist or
						other authorized staff member at Mind Therapy Clinic.
					</div>
					<div className={inputGroupName}>
						<div
							className={`${
								isMobile ? 'col-auto' : 'col-4'
							} form-group align-middle font-16`}
							style={inputStyle}
						>
							<CustomDatePicker
								disabled={true}
								label={<span className="form-group">TODAY&apos;S DATE</span>}
								id="toDate"
								selected={data.requestDate}
								placeholderText="Select from Date"
							/>
						</div>
						<div
							className={`${
								isMobile ? 'col-auto' : 'col-4'
							} form-group align-middle font-16`}
							style={inputStyle}
						>
							<CustomDatePicker
								disabled={closedRequest || role === 'patient'}
								label={
									<span className="form-group">
										{role === 'patient' && 'EARLIEST '}EFFECTIVE DATE
									</span>
								}
								handleDateChange={this.handleDateChange('effectiveDate')}
								id="effectiveDate"
								selected={data.effectiveDate}
								placeholderText="Select Effective Date"
								minDate={moment().add(7, 'days').toDate()}
							/>
						</div>
					</div>
					<div>
						<div className={'w-100 justify-content-end pr-3'}>
							<IAccept
								isRequired
								handleAcceptance={this.handleAcceptance}
								name="confirmChecked"
								value={data.confirmChecked}
							/>
						</div>
					</div>
					{(role === 'admin' ||
						role === 'clinician' ||
						this.closedRequest()) && (
						<div>
							<div className="form-group d-flex align-items-center mx-2">
								<div className="form-group col border border-right-0 p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											disabled={closedRequest}
											type="checkbox"
											className="custom-control-input"
											id="approve"
											onChange={this.handleCheckBox}
											name="approved"
											checked={approved}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="approve"
										>
											Approve
										</label>
									</div>
								</div>
								<div className="form-group col border p-2 my-1">
									<div className="custom-control custom-checkbox">
										<input
											disabled={closedRequest}
											type="checkbox"
											className="custom-control-input"
											id="denied"
											onChange={this.handleCheckBox}
											name="denied"
											checked={denied}
										/>
										<label
											className="custom-control-label font-15"
											htmlFor="denied"
										>
											Deny
										</label>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mr-2">
								{denied && (
									<div className="d-flex w-100 align-items-center">
										<TextArea
											readOnly={closedRequest}
											className="w-100"
											id="reasonForDenial"
											name="reasonForDenial"
											label="REASON FOR DENIAL*"
											value={data.reasonForDenial}
											placeholder="Reason for denial"
											handleInput={this.handleChange}
											style={{ resize: 'none' }}
											validations={[required]}
										/>
									</div>
								)}
							</div>
						</div>
					)}
					<div className="d-flex justify-content-center align-items-center w-100 p-4 mb-3">
						<button
							disabled={!this.formCompleted()}
							type="button"
							className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
							onClick={this.onSubmit}
						>
							<span className="mr-0 pl-3 w-100">
								{closedRequest ? 'OKAY' : 'SUBMIT'}
							</span>
							<i className="fas fa-check align-self-center" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

DiscontinueRequest.propTypes = {
	data: PropTypes.object,
};

export default DiscontinueRequest;
