import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';

import Redirect from 'components/Redirect';
import { generateToast } from 'containers/App/actions';
import { makeSelectAuth, makeSelectIsMobile } from 'containers/App/selectors';
import Dashboard from 'containers/Dashboard';
import FeedbackForm from 'containers/FeedbackForm';
import PatientRegister from 'containers/PatientRegister';
import { makeSelectAllPrograms } from 'containers/Therapies/selectors';
import { logout } from 'containers/UserDropDown/actions';
import Intake from 'pages/intake/Intake';

import makeSelectPatient from './selectors';

/* eslint-disable react/prefer-stateless-function */
export const Patient = (props) => {
	const { isMobile, programs, auth } = props;
	const { onboardingComplete } = auth.loggedInUser;
	const [isFormOpen, setIsFormOpen] = useState(false);
	const toggleForm = () => {
		setIsFormOpen(!isFormOpen);
	};

	const getPatientLandingPage = () => {
		if (onboardingComplete) return '/patient/dashboard';

		return '/patient/registration';
	};

	return (
		<>
			<Switch>
				{!onboardingComplete && (
					<Route
						exact
						path="/patient/registration"
						component={PatientRegister}
					/>
				)}
				{!onboardingComplete && (
					<Route exact path="/patient/intake" component={Intake} />
				)}
				{onboardingComplete && (
					<Route path="/patient/dashboard" component={Dashboard} />
				)}
				<Route
					exact
					path="/patient"
					component={() => <Redirect redirectPath={getPatientLandingPage()} />}
				/>
				<Route
					exact
					path="/"
					component={() => <Redirect redirectPath={getPatientLandingPage()} />}
				/>
				<Route
					component={() => <Redirect redirectPath={getPatientLandingPage()} />}
				/>
			</Switch>
			{onboardingComplete && !isMobile && (
				<div
					style={{ position: 'fixed', bottom: '0', right: '0', zIndex: 1000 }}
				>
					<button
						className={`btn btn-primary btn-color font-15 font-weight-bold justify-content-end ${
							isMobile && 'mobile-button'
						}`}
						onClick={toggleForm}
						style={{ position: 'absolute', bottom: 0, right: '12px' }}
					>
						Feedback
					</button>
					<FeedbackForm
						programs={programs}
						isMobile={isMobile}
						isOpen={isFormOpen}
						toggle={toggleForm}
					/>
				</div>
			)}
		</>
	);
};

Patient.propTypes = {
	// location: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	// doLogout: PropTypes.func,
	// performGenerateToast: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	patient: makeSelectPatient(),
	auth: makeSelectAuth(),
	isMobile: makeSelectIsMobile(),
	programs: makeSelectAllPrograms(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		doLogout: () => dispatch(logout()),
		performGenerateToast: (msg) => dispatch(generateToast(msg)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
