/**
 *
 * SigningModal
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import useIsMobile from 'hooks/isMobile.ts';

import './index.css';

const SigningModal = (props) => {
	const { isOpen, toggle, siteUrl } = props;
	const isMobile = useIsMobile();

	return (
		<div>
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				className={`${isMobile && 'modal-mobile'}`}
				size={isMobile ? 'auto' : 'xl'}
			>
				<ModalHeader toggle={toggle}>
					Mind Therapy Clinic Client Information and Agreement
				</ModalHeader>
				<ModalBody>
					<div className="demo-iframe-holder ">
						<iframe title="Signing" src={siteUrl} />
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

SigningModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	siteUrl: PropTypes.string.isRequired,
};

export default SigningModal;
