import { chunk } from 'lodash';

/**
 *
 * ChildAppointmentReason
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import Checkbox from 'atoms/Checkbox';
import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { STEPS } from 'pages/intake/constants';
// import components
import { generateObjectArray } from 'utils/helpers';

/* eslint-disable react/prefer-stateless-function */
const ChildAppointmentReason = (props) => {
	const { prevProgress, nextProgress, data, handleCheck, handleChange } = props;

	const {
		appointmentReason,
		symptomsDuration,
		symptomsRelatedToLifeSituation,
		symptomsDetails,
		doesSymptomsOccurInCycle,
		symptomsCycleDetails,
		didExperienceTraumas,
		traumaDetails,
		childSymptoms,
		otherMedicalSymptoms,
	} = data;

	return (
		<div className="container-fluid d-flex flex-column px-5">
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Reason For This Appointment
			</h2>

			<Form onSubmit={nextProgress({}, STEPS.ChidlAppointmentReason)}>
				<div className="card d-flex flex-column p-4 mb-4">
					<TextArea
						className="mb-3"
						label="WHAT ARE THE REASONS YOU SCHEDULED AN APPOINTMENT WITH US?"
						id="appointmentReason"
						value={appointmentReason}
						handleInput={handleChange(STEPS.ChidlAppointmentReason)}
					/>

					<TextInput
						className="mb-4"
						label="HOW LONG HAS YOUR CHILD HAD THESE SYMPTOMS?"
						id="symptomsDuration"
						value={symptomsDuration}
						handleInput={handleChange(STEPS.ChidlAppointmentReason)}
					/>

					<div className="row mb-3">
						<label
							htmlFor="symptomsRelatedToLifeSituation"
							className="form-font col-8"
						>
							ARE THESE SYMPTOMS RELATED TO A LIFE SITUATION?
						</label>
						<SelectButtonGroup
							id="symptomsRelatedToLifeSituation"
							value={symptomsRelatedToLifeSituation}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChidlAppointmentReason)}
							className="col-4"
						/>
					</div>
					{symptomsRelatedToLifeSituation === 'yes' && (
						<TextInput
							className="mb-4 mt-n4"
							label="IF YES, PLEASE EXPLAIN:"
							id="symptomsDetails"
							value={symptomsDetails}
							handleInput={handleChange(STEPS.ChidlAppointmentReason)}
						/>
					)}

					<div className="row mb-3">
						<label
							htmlFor="doesSymptomsOccurInCycle"
							className="form-font col-8"
						>
							DO THESE SYMPTOMS SEEM TO COME AND GO REGULARLY, AS IN A CYCLE?
						</label>
						<SelectButtonGroup
							id="doesSymptomsOccurInCycle"
							value={doesSymptomsOccurInCycle}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChidlAppointmentReason)}
							className="col-4"
						/>
					</div>

					{doesSymptomsOccurInCycle === 'yes' && (
						<TextInput
							className="mb-4 mt-n4"
							label="IF YES, PLEASE DESCRIBE:"
							id="symptomsCycleDetails"
							value={symptomsCycleDetails}
							handleInput={handleChange(STEPS.ChidlAppointmentReason)}
						/>
					)}

					<div className="row mb-4">
						<label htmlFor="didExperienceTraumas" className="form-font col-8">
							HAS YOUR CHILD EXPERIENCED POST-TRAUMATIC STRESS DISORDER (PTSD)
							OR ANY TRAUMAS INCLUDING PHYSICAL, EMOTIONAL OR SEXUAL ABUSE?
						</label>
						<SelectButtonGroup
							id="didExperienceTraumas"
							value={didExperienceTraumas}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChidlAppointmentReason)}
							className="col-4"
						/>
					</div>

					{didExperienceTraumas === 'yes' && (
						<TextInput
							className="mb-4 mt-n4"
							label="IF YES, PLEASE DESCRIBE:"
							id="traumaDetails"
							value={traumaDetails}
							handleInput={handleChange(STEPS.ChidlAppointmentReason)}
						/>
					)}

					<label htmlFor="childSymptoms" className="form-font mb-3">
						PLEASE CHECK THE BOXES BELOW FOR ALL THAT APPLY TO YOUR CHILD:
					</label>
					<div className="d-flex flex-column border-top border-left mt-2 mb-4">
						{chunk(childSymptoms, 2).map((row) => (
							<div className="d-flex p-0" key={`child-symptoms-${row}`}>
								{row.map((symptom) => (
									<Checkbox
										key={symptom.id}
										className="col-6 border-right border-bottom"
										item={symptom}
										checkboxGroup="childSymptoms"
										parent="childAppointmentReason"
										handleCheck={handleCheck}
									/>
								))}
							</div>
						))}
					</div>

					<TextArea
						className="mb-3"
						label="ARE THERE OTHER MEDICAL SYMPTOMS WE SHOULD KNOW ABOUT (e.g. FORGETFULNESS, WEIGHT CHANGES, DRY, COARSE SKIN/HAIR, CHANGE IN BOWEL HABITS etc.)?"
						id="otherMedicalSymptoms"
						value={otherMedicalSymptoms}
						handleInput={handleChange(STEPS.ChidlAppointmentReason)}
					/>
				</div>
				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

ChildAppointmentReason.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleCheck: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default ChildAppointmentReason;
