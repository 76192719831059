/*
 *
 * SignIn actions
 *
 */
import {
	CLEAR_ERROR,
	DEFAULT_ACTION,
	FORGOT_PASS,
	FORGOT_PASS_MSG,
	LOGIN_USER_FAILURE,
	LOGIN_USER_FETCH,
	LOGIN_USER_SUCCESS,
	TOGGLE_FORGOT_PASS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function loginUser(loginData) {
	return {
		type: LOGIN_USER_FETCH,
		loginData,
	};
}

export function loginUserSuccess({ successData, permissions }) {
	return {
		type: LOGIN_USER_SUCCESS,
		successData,
		permissions,
	};
}

export function loginUserFailure(failureData) {
	return {
		type: LOGIN_USER_FAILURE,
		failureData,
	};
}

export function clearError() {
	return {
		type: CLEAR_ERROR,
	};
}

export function toggleForgotPass() {
	return {
		type: TOGGLE_FORGOT_PASS,
	};
}

export function forgotPassword(data) {
	return {
		type: FORGOT_PASS,
		data,
	};
}

export function forgotPasswordMessage(data) {
	return {
		type: FORGOT_PASS_MSG,
		data,
	};
}
