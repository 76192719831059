import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Popup from 'atoms/Popup';
import useLongPress from 'hooks/useLongPress.ts';
import { noop } from 'utils/common';

import { actionButtonStyle } from './style';

const Button = styled.button`
	width: 40px;
	height: 40px;

	svg,
	polygon {
		fill: ${(props) => props.fillColor};
	}

	path {
		stroke: ${(props) => props.fillColor};
	}

	&:hover svg,
	&:hover polygon {
		fill: ${(props) => props.hoverColor};
	}

	&:hover path {
		stroke: ${(props) => props.hoverColor};
	}
`;

const ActionButton = (props) => {
	const {
		image,
		onClick = noop,
		hoverColor = 'var(--main-color)',
		fillColor = 'var(--main-grey)',
		alt = '',
		buttonStyle,
		className,
		popupStyle,
	} = props;
	const [showPopup, setShowPopup] = useState(false);

	const onLongPress = () => {
		setShowPopup(true);
	};

	useEffect(() => {
		const onMouseUp = () => {
			if (showPopup) {
				setShowPopup(false);
			}
		};
		window.addEventListener('onmouseup', onMouseUp);

		return () => window.removeEventListener('onmouseup', onMouseUp);
	}, []);

	const longPressEvent = useLongPress(onLongPress, onClick, {
		shouldPreventDefault: true,
		delay: 500,
	});

	return (
		<div style={{ position: 'relative' }} className={className}>
			<Button
				{...longPressEvent}
				hoverColor={hoverColor}
				fillColor={fillColor}
				onMouseEnter={() => setShowPopup(true)}
				onMouseLeave={() => setShowPopup(false)}
				style={buttonStyle}
			>
				{image}
			</Button>
			{alt && (
				<Popup
					isOpen={showPopup}
					text={alt}
					style={actionButtonStyle(showPopup, popupStyle)}
					noTri
				/>
			)}
		</div>
	);
};

ActionButton.propTypes = {
	image: PropTypes.element,
	onClick: PropTypes.func,
	hoverColor: PropTypes.string,
	fillColor: PropTypes.string,
	alt: PropTypes.string,
	buttonStyle: PropTypes.object,
	popupStyle: PropTypes.object,
	// placeholderText: PropTypes.string,
};

export default ActionButton;
