import PropTypes from 'prop-types';

import React from 'react';

import CommentPopover from 'components/CommentPopover';

import './index.css';

/**
 *
 * <DefaultAvatar />, a functional component to display default circular image with initials.
 */
class DefaultAvatar extends React.Component {
	state = {
		showAddCommentPopover: false,
		comment: '',
	};

	togglePopover = () => {
		const { id, name, handleClick } = this.props;
		if ((id && id.indexOf('excused') > -1) || name === 'excused') {
			this.setState((prevState) => ({
				showAddCommentPopover: !prevState.showAddCommentPopover,
			}));
		} else if (handleClick) {
			handleClick();
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.comment) {
			this.setState({
				comment: nextProps.comment,
			});
		}
	}

	handleInput = (event) => {
		const { target } = event;
		event.preventDefault();
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	handleCommentSubmit = () => {
		const { handleClick } = this.props;
		const { comment } = this.state;
		this.setState({ showAddCommentPopover: false });
		handleClick(comment);
	};

	onHover = (mode, status) => {
		if (mode === 'view')
			this.setState({
				showAddCommentPopover: status === 'enter' ? 'true' : false,
			});
	};

	render() {
		const { name, style, className, mode, id, width, loading } = this.props;
		const { showAddCommentPopover, comment } = this.state;

		const isCommentVisible =
			id && (mode === 'edit' || (mode === 'view' && comment));
		let initials = '';
		if (name) {
			const nameSplit = name.split(' ');
			initials = '';
			const firstName = nameSplit[0];
			initials += firstName.length && firstName[0].toUpperCase();

			if (nameSplit.length > 1) {
				const lastName = nameSplit[nameSplit.length - 1];
				initials += lastName.length && lastName[0].toUpperCase();
			}
		}

		return (
			<div>
				<div
					id={id}
					className={`avatar-circle ${className}`}
					style={{
						...style,
						width: `${width}px`,
						height: `${width}px`,
					}}
					onClick={this.togglePopover}
					onMouseEnter={() => this.onHover(mode, 'enter')}
					onMouseLeave={() => this.onHover(mode, 'leave')}
				>
					<div className="d-flex justify-content-center w-100 h-100 align-items-center p-3">
						<span
							className="initials"
							style={{
								fontSize: `${0.4 * width}px`,
							}}
						>
							{loading ? 'L' : initials}
						</span>
					</div>
				</div>
				{isCommentVisible && (
					<CommentPopover
						toggle={this.togglePopover}
						target={id}
						isOpen={showAddCommentPopover}
						title="Reason for Excuse"
						comment={comment}
						handleInput={this.handleInput}
						handleSubmit={this.handleCommentSubmit}
						mode={mode}
					/>
				)}
			</div>
		);
	}
}

DefaultAvatar.propTypes = {
	name: PropTypes.string,
	style: PropTypes.object,
	handleClick: PropTypes.func,
	className: PropTypes.string,
	mode: PropTypes.string,
	id: PropTypes.string,
	comment: PropTypes.string,
};

DefaultAvatar.defaultProps = {
	name: '',
	width: 40,
};

export default DefaultAvatar;
