const filters = (options) => [
	{
		type: 'Single Select',
		name: 'therapist_type',
		selectOptions: options.therapistType,
		selectLabel: 'THERAPIST TYPE',
		defaultSelect: 'Any',
	},
];

export default filters;
