/*
 *
 * Forms constants
 *
 */

export const SHOW_FORM_SHARE = 'src/Forms/SHOW_FORM_SHARE';

export const FETCH_FORMS = 'src/Forms/FETCH_FORMS';
export const FETCH_FORMS_SUCCESS = 'src/Forms/FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_ERROR = 'src/Forms/FETCH_FORMS_ERROR';

export const FETCH_FORM = 'src/Forms/FETCH_FORM';
export const FETCH_FORM_SUCCESS = 'src/Forms/FETCH_FORM_SUCCESS';
export const FETCH_FORM_ERROR = 'src/Forms/FETCH_FORM_ERROR';

export const DECLINE_FORM = 'src/Forms/DECLINE_FORM';
export const DECLINE_FORM_SUCCESS = 'src/Forms/DECLINE_FORM_SUCCESS';
export const DECLINE_FORM_ERROR = 'src/Forms/DECLINE_FORM_ERROR';

export const SIGN_DOC = 'src/Forms/SIGN_DOC';
export const SIGN_DOC_SUCCESS = 'src/Forms/SIGN_DOC_SUCCESS';
export const SIGN_DOC_ERROR = 'src/Forms/SIGN_DOC_ERROR';

export const FETCH_CLIENTS = 'src/Forms/FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = 'src/Forms/FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_ERROR = 'src/Forms/FETCH_CLIENTS_ERROR';

export const FETCH_GROUPS = 'src/Forms/FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = 'src/Forms/FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_ERROR = 'src/Forms/FETCH_GROUPS_ERROR';

export const UPDATE_FORM = 'src/Forms/UPDATE_FORM';
export const UPDATE_FORM_SUCCESS = 'src/Forms/UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_ERROR = 'src/Forms/UPDATE_FORM_ERROR';

export const CREATE_SIGNING_DOC = 'src/Forms/CREATE_SIGNING_DOC';
export const CREATE_SIGNING_DOC_SUCCESS =
	'src/Forms/CREATE_SIGNING_DOC_SUCCESS';
export const CREATE_SIGNING_DOC_ERROR = 'src/Forms/CREATE_SIGNING_DOC_ERROR';

export const SHARE_FORM = 'src/Forms/SHARE_FORM';
export const SHARE_FORM_SUCCESS = 'src/Forms/SHARE_FORM_SUCCESS';
export const SHARE_FORM_ERROR = 'src/Forms/SHARE_FORM_ERROR';

export const DELETE_FORM = 'src/Forms/DELETE_FORM';
export const DELETE_FORM_SUCCESS = 'src/Forms/DELETE_FORM_SUCCESS';
export const DELETE_FORM_ERROR = 'src/Forms/DELETE_FORM_ERROR';

export const UPLOAD_FORMS = 'src/Forms/UPLOAD_FORMS';
export const UPLOAD_FORMS_RESPONSE = 'src/Forms/UPLOAD_FORMS_RESPONSE';
export const UPLOAD_FORMS_ERROR = 'src/Forms/UPLOAD_FORMS_ERROR';

export const SUBMIT_JOTFORM = 'src/Forms/SUBMIT_JOTFORM';
export const SUBMIT_JOTFORM_SUCCESS = 'src/Forms/SUBMIT_JOTFORM_SUCCESS';
export const SUBMIT_JOTFORM_ERROR = 'src/Forms/SUBMIT_JOTFORM_ERROR';

export const CLEAR_UPLOAD_RESULT = 'src/Forms/CLEAR_UPLOAD_RESULT';
