/*
 *
 * App actions
 *
 */
import {
	CLEAR_DOWNLOAD_ERROR,
	CLEAR_FILE,
	CREATE_REQUEST,
	CREATE_REQUEST_ERROR,
	CREATE_REQUEST_SUCCESS,
	DEFAULT_ACTION,
	DELETE_REQUEST,
	DELETE_REQUEST_ERROR,
	DELETE_REQUEST_SUCCESS,
	DOWNLOAD_FILE,
	DOWNLOAD_FILE_ERROR,
	DOWNLOAD_FILE_SUCCESS,
	FETCH_CITIES,
	FETCH_CITIES_ERROR,
	FETCH_CITIES_SUCCESS,
	FETCH_DOCUMENT_LIST,
	FETCH_DOCUMENT_LIST_ERROR,
	FETCH_DOCUMENT_LIST_SUCCESS,
	FETCH_ENROLL_REQ,
	FETCH_ENROLL_REQ_ERROR,
	FETCH_ENROLL_REQ_SUCCESS,
	FETCH_MEMBER_REQ,
	FETCH_MEMBER_REQ_ERROR,
	FETCH_MEMBER_REQ_SUCCESS,
	FETCH_PHARMACIES,
	FETCH_PHARMACIES_ERROR,
	FETCH_PHARMACIES_SUCCESS,
	FETCH_STATES,
	FETCH_STATES_ERROR,
	FETCH_STATES_SUCCESS,
	FETCH_ZIPCODES,
	FETCH_ZIPCODES_ERROR,
	FETCH_ZIPCODES_SUCCESS,
	GET_PERMISSIONS,
	GET_PERMISSIONS_ERROR,
	GET_PERMISSIONS_SUCCESS,
	LOAD_CLINICIANS,
	LOAD_CLINICIANS_ERROR,
	LOAD_CLINICIANS_SUCCESS,
	PING_SERVER,
	PING_SERVER_ERROR,
	PING_SERVER_SUCCESS,
	REMOVE_TOAST_MESSAGE,
	SET_MOBILE,
	SET_VIEW_MODE,
	TOAST_ERROR,
	TOAST_GENERATED,
	UPDATE_REQUEST,
	UPDATE_REQUEST_ERROR,
	UPDATE_REQUEST_SUCCESS,
	VIEW_PDF,
	VIEW_PDF_ERROR,
	VIEW_PDF_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function clearDownloadError() {
	return {
		type: CLEAR_DOWNLOAD_ERROR,
	};
}

export function fetchPharmacies(id) {
	return {
		type: FETCH_PHARMACIES,
		id,
	};
}

export function fetchPharmaciesSuccess(data) {
	return {
		type: FETCH_PHARMACIES_SUCCESS,
		data,
	};
}

export function fetchPharmaciesError(err) {
	return {
		type: FETCH_PHARMACIES_ERROR,
		err,
	};
}

export function setMobile(value) {
	return {
		type: SET_MOBILE,
		value,
	};
}

export function generateToast(toastMessage) {
	return {
		type: TOAST_GENERATED,
		toastMessage,
	};
}

export function toastError(error) {
	return {
		type: TOAST_ERROR,
		error,
	};
}

export function removeToastMessage(nullMessage) {
	return {
		type: REMOVE_TOAST_MESSAGE,
		nullMessage,
	};
}

export function fetchStates() {
	return {
		type: FETCH_STATES,
	};
}

export function fetchStatesSuccess(data) {
	return {
		type: FETCH_STATES_SUCCESS,
		data,
	};
}

export function fetchStatesError(err) {
	return {
		type: FETCH_STATES_ERROR,
		err,
	};
}

export function pingServer() {
	return {
		type: PING_SERVER,
	};
}
export function pingServerSuccess(data) {
	return {
		type: PING_SERVER_SUCCESS,
		data,
	};
}
export function pingServerError(err) {
	return {
		type: PING_SERVER_ERROR,
		err,
	};
}

export function fetchDocumentList() {
	return {
		type: FETCH_DOCUMENT_LIST,
	};
}

export function fetchDocumentListSuccess(data) {
	return {
		type: FETCH_DOCUMENT_LIST_SUCCESS,
		data,
	};
}

export function fetchDocumentListError(err) {
	return {
		type: FETCH_DOCUMENT_LIST_ERROR,
		err,
	};
}

export function createRequest(data) {
	return {
		type: CREATE_REQUEST,
		data,
	};
}

export function createRequestSuccess(data) {
	return {
		type: CREATE_REQUEST_SUCCESS,
		data,
	};
}

export function createRequestError(err) {
	return {
		type: CREATE_REQUEST_ERROR,
		err,
	};
}

export function loadClinicians() {
	return {
		type: LOAD_CLINICIANS,
	};
}

export function loadCliniciansSuccess(clinicians) {
	return {
		type: LOAD_CLINICIANS_SUCCESS,
		clinicians,
	};
}

export function loadCliniciansError(err) {
	return {
		type: LOAD_CLINICIANS_ERROR,
		err,
	};
}

export function updateRequest(data) {
	return {
		type: UPDATE_REQUEST,
		data,
	};
}
export function updateRequestSuccess(data) {
	return {
		type: UPDATE_REQUEST_SUCCESS,
		data,
	};
}
export function updateRequestError(err) {
	return {
		type: UPDATE_REQUEST_ERROR,
		err,
	};
}

export function deleteRequest(data) {
	return {
		type: DELETE_REQUEST,
		data,
	};
}
export function deleteRequestSuccess(data) {
	return {
		type: DELETE_REQUEST_SUCCESS,
		data,
	};
}
export function deleteRequestError(err) {
	return {
		type: DELETE_REQUEST_ERROR,
		err,
	};
}

export function viewPdf(uuid, cb, onErr) {
	return {
		type: VIEW_PDF,
		uuid,
		cb,
		onErr,
	};
}
export function viewPdfSuccess(file) {
	return {
		type: VIEW_PDF_SUCCESS,
		file,
	};
}
export function viewPdfError(error) {
	return {
		type: VIEW_PDF_ERROR,
		error,
	};
}

export function fetchEnrollRequests() {
	return {
		type: FETCH_ENROLL_REQ,
	};
}

export function fetchEnrollRequestsSuccess(data) {
	return {
		type: FETCH_ENROLL_REQ_SUCCESS,
		data,
	};
}

export function fetchEnrollRequestsError(err) {
	return {
		type: FETCH_ENROLL_REQ_ERROR,
		err,
	};
}

export function fetchMemberRequests() {
	return {
		type: FETCH_MEMBER_REQ,
	};
}

export function fetchMemberRequestsSuccess(data) {
	return {
		type: FETCH_MEMBER_REQ_SUCCESS,
		data,
	};
}

export function fetchMemberRequestsError(err) {
	return {
		type: FETCH_MEMBER_REQ_ERROR,
		err,
	};
}

export function fetchCities(stateCode) {
	return {
		type: FETCH_CITIES,
		stateCode,
	};
}
export function fetchCitiesSuccess(stateCode, data) {
	return {
		type: FETCH_CITIES_SUCCESS,
		stateCode,
		data,
	};
}
export function fetchCitiesError(stateCode, err) {
	return {
		type: FETCH_CITIES_ERROR,
		stateCode,
		err,
	};
}

export function downloadFile(docId, container) {
	return {
		type: DOWNLOAD_FILE,
		docId,
		container,
	};
}

export function downloadFileSuccess(file) {
	return {
		type: DOWNLOAD_FILE_SUCCESS,
		file,
	};
}

export function downloadFileError(error) {
	return {
		type: DOWNLOAD_FILE_ERROR,
		error,
	};
}

export function setViewMode(mode) {
	return {
		type: SET_VIEW_MODE,
		mode,
	};
}

export function fetchZipcodes(cityName) {
	return {
		type: FETCH_ZIPCODES,
		cityName,
	};
}

export function fetchZipcodesSuccess(cityName, data) {
	return {
		type: FETCH_ZIPCODES_SUCCESS,
		cityName,
		data,
	};
}

export function fetchZipcodesError(cityName, err) {
	return {
		type: FETCH_ZIPCODES_ERROR,
		cityName,
		err,
	};
}

export function clearFile() {
	return {
		type: CLEAR_FILE,
	};
}

export function getPermissions() {
	return {
		type: GET_PERMISSIONS,
	};
}

export function getPermissionsSuccess(data) {
	return {
		type: GET_PERMISSIONS_SUCCESS,
		permissions: data.permissions,
	};
}

export function getPermissionsError(err) {
	return {
		type: GET_PERMISSIONS_ERROR,
		err,
	};
}
