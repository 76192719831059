import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

/**
 *
 * <TextLinkCell />, a functional component to display text link with styling.
 */
const TextLinkCell = (props) => (
	<Link
		to={{
			pathname: props.linkRoute,
			data: props.data,
		}}
	>
		<div className="link-color mt-2 font-13" style={props.style}>
			{props.title}
		</div>
	</Link>
);

TextLinkCell.propTypes = {
	// title to display
	title: PropTypes.string,
	// link to route
	linkRoute: PropTypes.string,
	// Custom style for <TextLinkCell/>
	style: PropTypes.objectOf(PropTypes.string),
	data: PropTypes.object,
};

export default TextLinkCell;
