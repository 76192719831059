import PropTypes from 'prop-types';

import imageAttachment from 'images/ic_attachment.svg';

/**
 *
 * <AttachmentCell />, a functional component to display Attachment icon with title.
 */
const AttachmentCell = (props) => {
	const { title } = props;
	return (
		<div className="d-flex justify-content-start mr-4 hand">
			<img className="" src={imageAttachment} alt="Attachment" />
			<h6 className="mt-2 ml-2 font-13">{title}</h6>
		</div>
	);
};

AttachmentCell.propTypes = {
	// title to display
	title: PropTypes.string,
};

export default AttachmentCell;
