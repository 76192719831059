import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <FemalePatients />, a functional component for Adult Intake Evaluation
 */
const FemalePatients = (props) => {
	const {
		handleChange,
		regularPeriods,
		irregularPeriodsDetail,
		takingContraceptives,
		moodChangeAfterContraceptives,
		moodChangeAfterContraceptivesDetail,
		menopausalSymptoms,
		visitedDoctorForMenopausal,
		treatmentForMenopausal,
		pmsSymptoms,
		pmsSymptomsDetail,
		isMobile,
	} = props;

	return (
		<div>
			<h6>Female Patients</h6>
			<div className="mb-2 row">
				<label
					htmlFor="regularPeriods"
					className={`form-font col-${isMobile ? 12 : 6}`}
				>
					DO YOU HAVE REGULAR PERIODS ?
				</label>
				<SelectButtonGroup
					id="regularPeriods"
					value={regularPeriods}
					buttons={generateObjectArray(['Yes', 'No', 'N/A'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-6'}`}
				/>
			</div>
			{regularPeriods === 'no' && (
				<TextArea
					className="mb-4"
					label="IF NO, PLEASE DESCRIBE:"
					id="irregularPeriodsDetail"
					value={irregularPeriodsDetail}
					handleInput={handleChange}
				/>
			)}

			<div className="mb-2 row">
				<label
					htmlFor="takingContraceptives"
					className={`form-font col-${isMobile ? 12 : 6}`}
				>
					ARE YOU TAKING CONTRACEPTIVES ?
				</label>
				<SelectButtonGroup
					id="takingContraceptives"
					value={takingContraceptives}
					buttons={generateObjectArray(['Yes', 'No', 'N/A'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-6'}`}
				/>
			</div>
			{takingContraceptives === 'yes' && (
				<div className="mb-2 row">
					<label
						htmlFor="moodChangeAfterContraceptives"
						className={`form-font col-${isMobile ? 12 : 8}`}
					>
						IF YES, DID YOU NOTICE A CHANGE IN YOUR MOOD WHEN YOU STARTED OR
						STOPPED BIRTH CONTROL ?
					</label>
					<SelectButtonGroup
						id="moodChangeAfterContraceptives"
						value={moodChangeAfterContraceptives}
						buttons={generateObjectArray(['Yes', 'No'])}
						handleSelect={handleChange}
						className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
					/>
				</div>
			)}
			{moodChangeAfterContraceptives === 'yes' && (
				<TextArea
					className="mb-4"
					label="IF YES, PLEASE DESCRIBE:"
					id="moodChangeAfterContraceptivesDetail"
					value={moodChangeAfterContraceptivesDetail}
					handleInput={handleChange}
				/>
			)}

			<div className="mb-2 row">
				<label
					htmlFor="menopausalSymptoms"
					className={`form-font col-${isMobile ? 12 : 6}`}
				>
					HAVE YOU NOTICED ANY PERIMENOPAUSAL/MENOPAUSAL SYMPTOMS (i.e., HAIR
					FALLING OUT, DRY EYES, IRREGULAR PERIODS, IRRITABILITY, VAGINAL
					DRYNESS, etc.) ?
				</label>
				<SelectButtonGroup
					id="menopausalSymptoms"
					value={menopausalSymptoms}
					buttons={generateObjectArray(['Yes', 'No', 'N/A'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-6'}`}
				/>
			</div>
			{menopausalSymptoms === 'yes' && (
				<div className="mb-2 row">
					<label
						htmlFor="visitedDoctorForMenopausal"
						className={`form-font col-${isMobile ? 12 : 8}`}
					>
						IF YES, HAVE YOU CONSULTED A DOCTOR ABOUT THIS ?
					</label>
					<SelectButtonGroup
						id="visitedDoctorForMenopausal"
						value={visitedDoctorForMenopausal}
						buttons={generateObjectArray(['Yes', 'No'])}
						handleSelect={handleChange}
						className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
					/>
				</div>
			)}
			{visitedDoctorForMenopausal === 'yes' && (
				<TextArea
					className="mb-4"
					label="DID THE DOCTOR DO ANY ADDITIONAL TESTS OTHER THAN BLOOD WORK, OR HOW DID THE DOCTOR TREAT YOUR CONDITION?"
					id="treatmentForMenopausal"
					value={treatmentForMenopausal}
					handleInput={handleChange}
				/>
			)}

			<div className="mb-2 row">
				<label
					htmlFor="pmsSymptoms"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					DO YOU EXPERIENCE ANY PMS SYMPTOMS ?
				</label>
				<SelectButtonGroup
					id="pmsSymptoms"
					value={pmsSymptoms}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
				/>
			</div>
			{pmsSymptoms === 'yes' && (
				<TextArea
					className="mb-4"
					label="IF YES, PLEASE DESCRIBE:"
					id="pmsSymptomsDetail"
					value={pmsSymptomsDetail}
					handleInput={handleChange}
				/>
			)}
		</div>
	);
};

FemalePatients.propTypes = {
	regularPeriods: PropTypes.string.isRequired,
	irregularPeriodsDetail: PropTypes.string.isRequired,
	takingContraceptives: PropTypes.string.isRequired,
	moodChangeAfterContraceptives: PropTypes.string.isRequired,
	moodChangeAfterContraceptivesDetail: PropTypes.string.isRequired,
	menopausalSymptoms: PropTypes.string.isRequired,
	visitedDoctorForMenopausal: PropTypes.string.isRequired,
	treatmentForMenopausal: PropTypes.string.isRequired,
	pmsSymptoms: PropTypes.string.isRequired,
	pmsSymptomsDetail: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default FemalePatients;
