import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { error, updateCities, updateStates, updateZipcodes } from './actions';
import { fetchCitiesAPI, fetchStatesAPI, fetchZipcodesAPI } from './api';
import { FETCH_CITIES, FETCH_STATES, FETCH_ZIPCODES } from './constants';

export function* performFetchStates() {
	try {
		const response = yield call(fetchStatesAPI);
		yield put(updateStates(response.data));
	} catch (err) {
		yield put(error());
	}
}

export function* performFetchCities(actions) {
	const data = { stateCode: actions.stateCode };
	try {
		const response = yield call(fetchCitiesAPI, data);
		yield put(updateCities(response.data, actions.stateName, actions.keyName));
	} catch (err) {
		yield put(error());
	}
}

export function* performFetchZipcodes(actions) {
	const data = { cityName: actions.cityName };
	try {
		const response = yield call(fetchZipcodesAPI, data);
		yield put(updateZipcodes(response.data, actions.keyName));
	} catch (err) {
		yield put(error());
	}
}

export default function* geoSaga() {
	yield takeLatest(FETCH_STATES, performFetchStates);
	yield takeEvery(FETCH_CITIES, performFetchCities);
	yield takeEvery(FETCH_ZIPCODES, performFetchZipcodes);
}
