import history from 'utils/history';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

/**
 *
 * <SelectButtonGroup />, a functional component to display buttons with single select option.
 *
 */
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import Table from 'atoms/Table';
import { headerStyle } from 'containers/App/constants';

class PatientTherapies extends React.Component {
	state = {
		showOptions: false,
		programsSelected: [],
		allSelected: false,
	};

	toggle = () => {
		this.setState({ showOptions: !this.state.showOptions });
	};

	updateEnrollment = () => {
		const { updateEnrollment } = this.props;
		const { programsSelected } = this.state;

		updateEnrollment(programsSelected);

		this.setState({ programsSelected: [], allSelected: false });
		this.selectNone();
	};

	handleSelect = (id) => {
		const { programsSelected } = this.state;

		if (programsSelected.includes(id)) {
			programsSelected.splice(programsSelected.indexOf(id), 1);
		} else {
			programsSelected.push(id);
		}

		this.setState({ programsSelected, checked: !this.state.checked });
	};

	selectNone = () => {
		const checkboxes = document.getElementsByName('selectbox');
		for (const checkbox of checkboxes) {
			checkbox.checked = false;
		}
	};

	handleSelectAll = () => {
		const { allSelected } = this.state;
		const programsSelected = [];

		if (!allSelected) {
			const { data } = this.props;
			data.forEach((program) => {
				programsSelected.push(program.program_id);
			});
		}

		const checkboxes = document.getElementsByName('selectbox');
		for (const checkbox of checkboxes) {
			checkbox.checked = !allSelected;
		}

		this.setState({ programsSelected, allSelected: !allSelected });
	};

	isSelected = (id) => {
		const { programsSelected } = this.state;

		return programsSelected.includes(id);
	};

	getColumns = () => {
		const { showOptions, programsSelected } = this.state;
		const { type, toggleAddGroup } = this.props;

		const cols = [
			{
				field: 'program_id',
				headerStyle,
				width: '75px',
				title: (
					<div className="mt-2 ml-2 font-13">
						<input
							className="hand custom-control custom-checkbox"
							type="checkbox"
							name="selectbox"
							style={{
								width: '1.25em',
								height: '1.25em',
							}}
							onClick={() => this.handleSelectAll()}
						/>
					</div>
				),
				render: (row) => (
					<div className="mt-2 mx-2 font-13">
						<input
							className="hand custom-control custom-checkbox"
							type="checkbox"
							name="selectbox"
							style={{
								width: '1.25em',
								height: '1.25em',
							}}
							onClick={() => this.handleSelect(row.program_id)}
						/>
					</div>
				),
				sorting: false,
			},
			{
				field: 'groupName',
				title: 'THERAPY',
				headerStyle,
				width: '280px',
				render: (row) => (
					<div
						className=" mt-2 mx-2 font-13 link-color hand"
						onClick={() =>
							history.push({
								pathname: `/${type}/dashboard/groupDetails/${row.program_id}`,
							})
						}
					>
						{row.groupName}
					</div>
				),
			},
			{
				field: 'location',
				title: 'LOCATION',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13">
						{row.location} -
						<br />
						{row.lastSession &&
							`${moment.utc(row.lastSession).local().format('h:mma')} ${moment
								.utc(row.lastSession)
								.local()
								.format('ddd')}`}
					</div>
				),
			},
			{
				field: 'lastSession',
				title: 'LAST SESSION',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.lastSession
							? moment.utc(row.lastSession).local().format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'upcomingSession',
				title: 'UPCOMING SESSION',
				width: '200px',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.upcomingSession
							? moment.utc(row.upcomingSession).local().format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'endDate',
				title: 'END DATE',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{row.endDate
							? moment.utc(row.endDate).local().format('MM-DD-YYYY')
							: '-'}
					</div>
				),
			},
			{
				field: 'program_id',
				headerStyle: {
					...headerStyle,
					color: '#999999',
				},
				title: (
					<div>
						<ButtonDropdown isOpen={showOptions} toggle={this.toggle}>
							<DropdownToggle
								className="btn btn-primary btn-color font-12 mr-2 mt-10 btn btn-secondary"
								caret
							>
								Options
							</DropdownToggle>
							<DropdownMenu
								flip={false}
								style={{
									transform: 'translate3d(-127px, 0px, 0px)',
								}}
							>
								<DropdownItem onClick={toggleAddGroup}>
									Add to Group
								</DropdownItem>
								<DropdownItem
									disabled={programsSelected.length === 0}
									onClick={() => this.updateEnrollment()}
								>
									Update Enrollment
								</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					</div>
				),
				render: () => <div></div>,
				sorting: false,
			},
		];

		if (type === 'patient') {
			cols.splice(0, 1);
			cols.splice(cols.length - 1, 1);
		}

		return cols;
	};

	render() {
		const { data, loading } = this.props;
		const enrolledGroupsColumns = this.getColumns();
		return (
			<div>
				<Table
					data={data}
					columns={enrolledGroupsColumns}
					loading={loading}
					pagination={true}
					showWhenEmpty
				/>
			</div>
		);
	}
}

PatientTherapies.propTypes = {
	data: PropTypes.array.isRequired,
};

export default PatientTherapies;
