import history from 'utils/history';

import moment from 'moment';

import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

const ToDoCard = (props) => {
	const { data, assignClinician, downloadFile } = props;
	const {
		docs,
		profilePicture,
		name,
		email,
		phone,
		clinicianName,
		createdAt,
		therapistType,
		userId,
		onBoardingComplete,
	} = data;

	const hasIntakeDoc = () => {
		return docs.length > 0 && docs[0].uuid && docs[0].uuid.length > 0;
	};

	let formattedPhoneNumber = formatPhoneNumber(
		phone.substring(0, 2) === '+1' ? phone : '+1' + phone,
	);

	return (
		<div className="card-container request-card bg-white border-bottom px-3 py-4 mb-0">
			<div
				className="mx-2 my-2 hand"
				onClick={() => history.push(`patient/${userId}/profile`)}
			>
				<ImageDetailsCell
					src={profilePicture || ''}
					heading={name}
					primarySubHeading={email}
					secondarySubHeading={formattedPhoneNumber}
					width="50"
					classes={{
						heading: 'font-16',
						primarySubHeading: 'font-14',
					}}
				/>
			</div>
			<div className="row mx-0 mt-3">
				<div className="col-6">
					<div className="mb-2">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase">
							Registered On
						</div>
						<div className="font-14">
							{moment(createdAt).format('YYYY-MM-DD')}
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase">
						View Intake Document
					</div>
					{hasIntakeDoc() ? (
						<div
							className="d-flex hand  link-color justify-content-start align-items-center font-13"
							onClick={() => downloadFile(docs[0].uuid)}
						>
							DOWNLOAD
						</div>
					) : onBoardingComplete ? (
						<div className="d-flex text-muted justify-content-start align-items-center font-12">
							DOCUMENT UNAVAILABLE
						</div>
					) : (
						<div className="d-flex text-muted justify-content-start align-items-center font-12">
							INTAKE INCOMPLETE
						</div>
					)}
				</div>
			</div>
			<hr />
			<div className="row mx-0">
				<div className="col-auto mx-auto">
					{clinicianName ? (
						<ImageDetailsCell
							src={profilePicture || ''}
							heading={clinicianName}
							primarySubHeading={therapistType}
							classes={{
								heading: 'font-16',
								primarySubHeading: 'font-14',
							}}
						/>
					) : (
						<div
							onClick={() => assignClinician(userId)}
							className="link-color hand"
						>
							Assign Clinician
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ToDoCard;
