import React from 'react';

import classnames from 'classnames';

import Label from './Label';

function buildErrorMessage(collectJSError = {}) {
	const { isValid, reason } = collectJSError;

	if (isValid) return '';

	if (/empty/gi.test(reason)) {
		return 'Field is required';
	}

	return reason;
}

export default function CollectJSInput({ label, id, errors }) {
	const error = errors[id];
	const errorMessage = buildErrorMessage(error);
	const hasError = Boolean(errorMessage);

	return (
		<>
			<Label id={id} label={label} hasError={hasError} required />
			<div
				id={id}
				className={classnames('form-control text-muted collectjs', {
					'is-invalid-input': hasError,
				})}
			/>
			<span className="form-error">{errorMessage}</span>
		</>
	);
}
