import {
	FETCH_NOTIFICATIONS,
	FETCH_NOTIFICATIONS_ERROR,
	FETCH_NOTIFICATIONS_SUCCESS,
	UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_ERROR,
	UPDATE_NOTIFICATIONS_SUCCESS,
} from './constants';

export const fetchNotifications = () => {
	return {
		type: FETCH_NOTIFICATIONS,
	};
};
export const fetchNotificationsSuccess = (data) => {
	return {
		type: FETCH_NOTIFICATIONS_SUCCESS,
		data,
	};
};
export const fetchNotificationsError = (err) => {
	return {
		type: FETCH_NOTIFICATIONS_ERROR,
		err,
	};
};

export const updateNotifications = (data) => {
	return {
		type: UPDATE_NOTIFICATIONS,
		data,
	};
};
export const updateNotificationsSuccess = (data) => {
	return {
		type: UPDATE_NOTIFICATIONS_SUCCESS,
		data,
	};
};
export const updateNotificationsError = (err) => {
	return {
		type: UPDATE_NOTIFICATIONS_ERROR,
		err,
	};
};
