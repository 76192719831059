import { cloneDeep } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import SelectAutoComplete from 'atoms/SelectAutoComplete';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import AttendanceTable from 'components/GroupAttendance/AttendanceTable';
import ValidateSentModal from 'components/ValidateSentModal';
import { compareWords } from 'utils/columnCompare';

const initState = {
	selectedYear: { label: null, value: null },
	selectedMonth: { label: null, value: null },
	selectedClient: { label: null, value: null },
	viewAsPatient: false,
	selectedSessions: [],
	allSessionsSelected: false,
	attendancesToUpdate: [],
	attendancesToDelete: [],
	localAttendance: [],
	isFetching: false,
};

const reducer = (state, action) => {
	const { attendancesToUpdate, localAttendance } = state;
	let attendance;

	const applyLocalUpdate = (record) => {
		const attendance = cloneDeep(localAttendance);
		let updated = false;

		let toUpdate = attendance.find((x) => x.patientId === record.clientId);

		toUpdate.attendance.forEach((x) => {
			if (x.sessionId === record.session.sessionId) {
				if (record.status === 'clear') {
					x.status = '';
				} else {
					x.status = record.status;
				}

				if (record.notes) x.notes = record.notes;

				updated = true;
			}
		});

		if (!updated && record.status !== 'clear') {
			toUpdate.attendance.push({ ...record.session, status: record.status });
		}

		return attendance;
	};

	const applyLocalChanges = (attendance) => {
		attendancesToUpdate.forEach((a) => {
			attendance = applyLocalUpdate(a);
		});

		return attendance;
	};

	switch (action.type) {
		case 'new_program':
			return {
				...state,
				selectedYear: { label: null, value: null },
				selectedMonth: { label: null, value: null },
				localAttendance: [],
				isFetching: false,
			};
		case 'set_selected_year':
			return {
				...state,
				selectedYear: action.year,
			};
		case 'set_selected_month':
			return {
				...state,
				selectedMonth: action.month,
				localAttendance: [],
			};
		case 'select_client':
			return {
				...state,
				selectedClient: action.client,
			};
		case 'toggle_view':
			return {
				...state,
				viewAsPatient: !state.viewAsPatient,
			};
		case 'set_selected_sessions':
			return {
				...state,
				selectedSessions: action.selectedSessions,
			};
		case 'toggle_session':
			const { selectedSessions } = state;
			if (selectedSessions.find((s) => s === action.sessionId)) {
				return {
					...state,
					selectedSessions: selectedSessions.filter(
						(s) => s !== action.sessionId,
					),
				};
			} else {
				selectedSessions.push(action.sessionId);
				return {
					...state,
					selectedSessions,
				};
			}
		case 'to_update':
			const newUpdates = attendancesToUpdate.filter(
				(a) =>
					a.session.sessionId !== action.record.session.sessionId ||
					a.clientId !== action.record.clientId,
			);

			newUpdates.push(action.record);
			attendance = applyLocalUpdate(action.record);

			return {
				...state,
				attendancesToUpdate: newUpdates,
				localAttendance: attendance,
			};
		case 'clear_updates':
			return {
				...state,
				attendancesToUpdate: [],
			};
		case 'set_local_attendance':
			attendance = applyLocalChanges(action.attendance);

			return {
				...state,
				localAttendance: attendance,
				isFetching: false,
			};
		case 'begin_fetching':
			return {
				...state,
				isFetching: true,
			};
		case 'done_fetching':
			return {
				...state,
				isFetching: false,
			};
		default:
			return state;
	}
};

const GroupAttendance = (props) => {
	const {
		attendance,
		sessions,
		fetchAttendance,
		fetchSessions,
		toggleShowAddSession,
		toggleShowExtendSessions,
		saveAttendance,
		programDetails = {},
		type,
		auth,
		deleteSessions,
		isSaving,
	} = props;

	const [state, dispatch] = useReducer(reducer, initState);
	const [allSelected, setAllSelected] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const [years, setYears] = useState(new Set());
	const [months, setMonths] = useState(new Set());

	const {
		selectedMonth,
		selectedYear,
		attendancesToUpdate,
		viewAsPatient,
		selectedSessions,
		allSessionsSelected,
		localAttendance,
		selectedClient,
		isFetching,
	} = state;

	// If the program changes, fetch the new sessions
	const getSessions = useCallback(() => {
		const id = programDetails.programId;

		if (!id || id === undefined) return;

		fetchSessions({
			programId: id,
		});
	}, [programDetails, fetchSessions]);

	useEffect(() => {
		getSessions();
		dispatch({ type: 'new_program' });
	}, [programDetails.programId]);

	// On sessions change set the years list
	const onSessionsChange = useCallback(() => {
		if (sessions.length === 0) return;
		const newYears = new Set();
		sessions.forEach((s) => newYears.add(moment(s.month).format('YYYY')));
		setYears(newYears);
	}, [setYears, sessions, years]);
	useEffect(onSessionsChange, [sessions.length, programDetails.programId]);

	// On years change set the selected year
	useEffect(() => {
		const today = moment();
		let year = today.format('YYYY');
		if (moment(years[0]).isAfter(today)) {
			year = years[0];
		}
		dispatch({
			type: 'set_selected_year',
			year: { label: year, value: year },
		});
	}, [years]);

	// On selected year change the months
	const updateMonths = useCallback(() => {
		if (sessions.length === 0 || !selectedYear?.value) return;
		const newMonths = new Set();
		sessions.forEach((s) => {
			const year = moment(s.month).year();
			if (year === parseInt(selectedYear.value))
				newMonths.add(moment(s.month).format('MM'));
		});
		setMonths(newMonths);
	}, [setMonths, sessions, selectedYear, setMonths, dispatch]);

	useEffect(updateMonths, [years, selectedYear.value]);

	// Set selected month when months change
	const setMonth = useCallback(() => {
		const today = moment();
		let month = today.format('MM');

		// If they have selected a year that's not this year, default to the first possible month
		if (today.format('YYYY') !== selectedYear.value || !months.has(month)) {
			month = Array.from(months)[0];
		}

		dispatch({
			type: 'set_selected_month',
			month: { label: month, value: month },
		});
	}, [dispatch, selectedYear, months]);
	useEffect(setMonth, [months]);

	// On selected month change fetch the attendance
	const onSelectedMonthChange = useCallback(() => {
		if (isFetching || !genDate()) return;

		const id = programDetails.programId;
		if (!id || id === undefined) return;
		dispatch({ type: 'begin_fetching' });

		fetchAttendance(
			{
				programId: id,
				month: moment(selectedMonth.value, 'MM').format('M'),
				year: selectedYear.value,
				patientId: 0,
			},
			() => dispatch({ type: 'done_fetching' }),
		);
	}, [selectedMonth, selectedYear, isFetching]);
	useEffect(onSelectedMonthChange, [selectedMonth.value]);

	useEffect(() => {
		dispatch({ type: 'set_local_attendance', attendance });
	}, [attendance]);

	const reqDeleteSessions = (ids) => {
		deleteSessions(ids);
		setAllSelected(false);
		dispatch({ type: 'set_selected_sessions', selectedSessions: [] });
	};

	const genDate = () => {
		if (!selectedYear.value || !selectedMonth.value) return null;

		return `${selectedYear.value}-${selectedMonth.value}`;
	};

	const generateClientAttendance = () => {
		if (!Array.isArray(sessions) || sessions.length === 0) return [];

		const currentSession = sessions.find((s) => s.month === genDate());

		let clientAttendance = localAttendance.map((client) => {
			return (
				client.name &&
				inClientDateRange(client, currentSession) &&
				currentSession && {
					attendanceData: client,
					sessions: currentSession.sessionDetails,
				}
			);
		});

		clientAttendance = clientAttendance.filter(
			(x) => x !== false && typeof x !== 'undefined',
		);
		clientAttendance = clientAttendance.sort((a, b) =>
			compareWords(a.attendanceData.name, b.attendanceData.name, 'asc'),
		);

		return clientAttendance;
	};

	const generateClientList = () => {
		let { clientsEnrolled = [] } = programDetails;

		clientsEnrolled = clientsEnrolled.filter((x) => x !== null);

		const options = clientsEnrolled.map((data) => ({
			label: data.patientName,
			value: data.patientId,
		}));

		return options;
	};

	const handleAttendanceChange = (clientId, session) => (status, comment) => {
		dispatch({
			type: 'to_update',
			record: { clientId, session, status, comment },
			attendance,
		});
	};

	const handleYearChange = (option) => {
		dispatch({ type: 'set_selected_year', year: option });
	};

	const handleMonthChange = (option) => {
		dispatch({ type: 'set_selected_month', month: option });
	};

	const handleSelectAllSessions = () => {
		const currentSessions = sessions.find(
			(s) => s.month === genDate(),
		).sessionDetails;

		let selectedSessions = currentSessions.map((s) => s.sessionId);

		if (allSelected) {
			selectedSessions = [];
		}

		dispatch({ type: 'set_selected_sessions', selectedSessions });
		setAllSelected(!allSelected);
	};

	const handleSelectClient = (option) => {
		dispatch({ type: 'select_client', client: option });
	};

	const handleSelectSession = (sessionId) => {
		dispatch({ type: 'toggle_session', sessionId });
	};

	const inClientDateRange = (client, sessionData) => {
		if (!sessionData?.sessionDetails) return true;
		const { sessionDetails } = sessionData;
		const firstSession = sessionDetails[0];
		const lastSession = sessionDetails[sessionDetails.length - 1];

		return (
			moment(client.startDate).isBefore(lastSession.startDate) &&
			(!client.endDate || moment(client.endDate).isAfter(firstSession.endDate))
		);
	};

	const renderPatientView = () => {
		let attendanceToUse = attendance;

		if (viewAsPatient) {
			if (!selectedClient.value) {
				return (
					<h4 className="d-flex p-5 justify-content-center text-muted">
						NO CLIENT SELECTED
					</h4>
				);
			}

			attendanceToUse = attendance.find(
				(data) => (data.patientId = selectedClient.value),
			).attendance;
		}

		const attendanceDisplay = sessions.map((session) => {
			if (
				attendanceToUse[0] &&
				moment(session.month).isBefore(
					moment(attendanceToUse[0].startDate).month,
				)
			) {
				return <div key={session.id}></div>;
			}

			let monthAttendanceData = attendanceToUse.find((data) => {
				return data.month === session.month;
			});
			if (!monthAttendanceData) {
				monthAttendanceData = {
					month: session.month,
				};
			}

			let data = [
				{
					sessions: session.sessionDetails,
					attendanceData: monthAttendanceData,
				},
			];

			const sessionData = sessions.find(
				(s) => s.month === genDate(),
			)?.sessionDetails;

			return (
				<div className="mt-2" key={session.id}>
					<span className="text-uppercase text-muted ml-3 font-weight-bold">
						{moment(session.month).format('MMMM YYYY')}
					</span>
					<div className="container-fluid bg-white mt-2 pb-2">
						<AttendanceTable
							sessionData={data}
							type={type}
							editing={false}
							handleAttendanceChange={handleAttendanceChange}
							sessions={sessionData}
							viewAsPatient
						/>
					</div>
				</div>
			);
		});

		return attendanceDisplay.length ? (
			attendanceDisplay
		) : (
			<h4 className="d-flex p-5 justify-content-center text-muted">
				{type === 'patient' ? 'NO ATTENDANCE RECORD' : 'NO ENROLLED PATIENTS'}
			</h4>
		);
	};

	const renderAdminView = () => {
		const sessionData = sessions.find(
			(s) => s.month === genDate(),
		)?.sessionDetails;

		return (
			<AttendanceTable
				auth={auth}
				sessionData={generateClientAttendance()}
				type={type}
				editing={true}
				handleSelectSession={handleSelectSession}
				handleSelectAllSessions={handleSelectAllSessions}
				handleAttendanceChange={handleAttendanceChange}
				selectedSessions={selectedSessions}
				allSelected={allSessionsSelected}
				loading={isFetching}
				sessions={sessionData}
			/>
		);
	};

	const save = () => {
		saveAttendance({ attendance: attendancesToUpdate }, localAttendance);
		dispatch({ type: 'clear_updates' });
	};

	const toggleConfirmDelete = () => {
		setShowConfirmDelete(!showConfirmDelete);
	};

	const toggleOptions = () => {
		setShowOptions(!showOptions);
	};

	/* const toggleView = () => {
		dispatch({ type: 'toggle_view' });
	}; */

	return (
		<div className="container-fluid bg-white p-3">
			{type !== 'patient' && (
				<div
					id="attendance-commands"
					className="row mx-0 justify-content-between"
				>
					<div style={{ width: 600 }}>
						{viewAsPatient ? (
							<SelectAutoComplete
								handleChange={handleSelectClient}
								options={generateClientList()}
								value={selectedClient}
								placeholder="Select Client"
								indicatorClass="fas fa-sort dropdown-indicator text-primary"
								optionStyles={{ fontSize: '14px' }}
								singleValueStyles={{
									color: 'var(--main-color)',
									fontSize: '14px',
								}}
							/>
						) : (
							<div className="d-flex ml-3">
								<div className="mr-3" style={{ width: 100 }}>
									<label className="form-font">Year</label>
									<SelectAutoComplete
										handleChange={handleYearChange}
										options={Array.from(years).map((y) => ({
											label: y,
											value: y,
										}))}
										value={selectedYear}
										placeholder="Select State"
										indicatorClass="fas fa-sort dropdown-indicator text-primary"
										optionStyles={{ fontSize: '14px' }}
										singleValueStyles={{
											color: 'var(--main-color)',
											fontSize: '14px',
										}}
									/>
								</div>
								<div style={{ width: 75 }}>
									<label className="form-font">Month</label>
									<SelectAutoComplete
										handleChange={handleMonthChange}
										options={Array.from(months).map((m) => ({
											label: m,
											value: m,
										}))}
										value={selectedMonth}
										placeholder="Select State"
										indicatorClass="fas fa-sort dropdown-indicator text-primary"
										optionStyles={{ fontSize: '14px' }}
										singleValueStyles={{
											color: 'var(--main-color)',
											fontSize: '14px',
										}}
									/>
								</div>
							</div>
						)}
					</div>
					<div style={{ height: 42 }}>
						<button
							disabled={isSaving || attendancesToUpdate.length === 0}
							className="btn btn-primary primary-btn-color px-3 py-2 h-100 font-10 mr-2"
							onClick={save}
						>
							{isSaving ? (
								<SmallLoadingIndicator
									style={{ width: '21px', height: '21px', margin: '0 auto' }}
									className=""
								/>
							) : (
								'SAVE'
							)}
						</button>
						<ButtonDropdown
							direction="down"
							isOpen={showOptions}
							toggle={toggleOptions}
							className="h-100"
						>
							<DropdownToggle
								className="btn btn-primary primary-btn-color px-3 py-2 font-10 h-100"
								style={{ borderRadius: '0px' }}
							>
								OPTIONS
								<span
									className={`ml-2 custom-arrow ${
										showOptions ? 'up' : 'down'
									} white`}
									style={showOptions ? { marginTop: 3 } : { marginBottom: 3 }}
								/>
							</DropdownToggle>
							<DropdownMenu right className="mb-3" flip={false}>
								{/* {(type === 'admin' || type === 'clinician') && (
                  <DropdownItem
                    onClick={toggleView}
                    className="request-button py-3 px-4 font-15 text-dark"
                  >
                    {viewAsPatient ? 'Admin View' : 'Patient View'}
                  </DropdownItem>
                )} */}
								<DropdownItem
									onClick={toggleShowAddSession}
									className="request-button py-3 px-4 font-15 text-dark"
								>
									Add New Session
								</DropdownItem>
								<DropdownItem
									onClick={toggleShowExtendSessions}
									className="request-button py-3 px-4 font-15 text-dark"
								>
									Extend Sessions
								</DropdownItem>
								<DropdownItem
									onClick={toggleConfirmDelete}
									disabled={selectedSessions.length === 0}
									className={`request-button py-3 px-4 font-15 ${
										selectedSessions.length === 0 ? 'text-grey' : 'text-dark'
									}`}
								>
									Delete Selected Sessions
								</DropdownItem>
							</DropdownMenu>
						</ButtonDropdown>
					</div>
				</div>
			)}
			<div id="attendance-display" className="mt-4">
				{type === 'patient' || viewAsPatient
					? renderPatientView()
					: renderAdminView()}
			</div>
			{showConfirmDelete && (
				<ValidateSentModal
					successStatus={false}
					hide={toggleConfirmDelete}
					handleDelete={reqDeleteSessions}
					message="Once deleted, a session and ALL of its data will be deleted forever! This action CANNOT Be undone."
					toBeDeleted={selectedSessions}
				/>
			)}
		</div>
	);
};

GroupAttendance.propTypes = {
	fetchAttendance: PropTypes.func,
	updateAttendance: PropTypes.func,
	deleteAttendance: PropTypes.func,
	attendance: PropTypes.array,
	programDetails: PropTypes.any,
	fetchSessions: PropTypes.func,
	sessions: PropTypes.array,
	type: PropTypes.any,
};

export default GroupAttendance;
