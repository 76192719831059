import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import FeedbackCard from 'cards/FeedbackCard';
import FeedbackModal from 'components/FeedbackModal';
import RequestSentModal from 'components/RequestSentModal';
import { makeSelectIsMobile } from 'containers/App/selectors';

import { fetchFeedback, updateFeedback } from './actions';
import columns from './columns';
import {
	makeSelectError,
	makeSelectIsFetching,
	makeSelectIsUpdating,
	makeSelectSuccess,
} from './selectors';
import './style.css';

const Feedback = (props) => {
	const {
		updateFeedback,
		feedback = [],
		isFetching,
		success,
		error,
		getFeedback,
		isMobile,
	} = props;

	const [isUpdating, setIsUpdating] = useState(false);
	const [showRequestSent, setShowRequestSent] = useState(false);
	const [message, setMessage] = useState('');
	const [showFeedback, setShowFeedback] = useState(false);
	const [selectedFeedback, setSelectedFeedback] = useState(null);

	useEffect(() => {
		getFeedback();
	}, []);

	useEffect(() => {
		if (isUpdating) {
			let msg;
			if (success) {
				msg = 'Feedback successfully updated.';
			} else if (error) {
				msg = 'Feedback could not be updated. Please contact an administrator.';
			}

			if (success || error) {
				setShowRequestSent(true);
				setIsUpdating(false);
				setSelectedFeedback(null);
				setMessage(msg);
			}
		}
	}, [success, error]);

	const handleSubmit = () => {
		if (!selectedFeedback.completed) {
			updateFeedback(selectedFeedback.id);
			setIsUpdating(true);
		}

		setShowFeedback(false);
	};

	const closeRequestSentModal = () => {
		setShowRequestSent(false);
		setMessage('');
	};

	const selectFeedback = (feedback) => {
		setSelectedFeedback(feedback);
		setShowFeedback(true);
	};

	return (
		<div>
			{isFetching ? (
				<SmallLoadingIndicator text="Loading" />
			) : feedback.length === 0 ? (
				<div className="container-fluid bg-white mb-3 p-2">
					<div className="text-center w-100 font-30 py-3 uppercase">
						No Client Feedback
					</div>
				</div>
			) : (
				<div>
					{feedback.length === 0 ? (
						<div className="container-fluid bg-white mb-3 p-2">No Feedback</div>
					) : isMobile ? (
						feedback.map((f) => {
							return (
								<FeedbackCard
									key={f.id}
									feedback={f}
									submittedBy={{}}
									onClick={selectFeedback}
								/>
							);
						})
					) : (
						<Table
							data={feedback}
							columns={columns(selectFeedback)}
							keyField={'id'}
						/>
					)}
				</div>
			)}
			{showRequestSent && (
				<RequestSentModal
					hide={closeRequestSentModal}
					successStatus={success}
					message={message}
				/>
			)}
			{showFeedback && (
				<FeedbackModal
					isOpen={showFeedback}
					hide={() => setShowFeedback(false)}
					feedback={selectedFeedback}
					submit={handleSubmit}
				/>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	error: makeSelectError(),
	success: makeSelectSuccess(),
	isFetching: makeSelectIsFetching(),
	isUpdating: makeSelectIsUpdating(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		getFeedback: (id) => dispatch(fetchFeedback(id)),
		updateFeedback: (id) => dispatch(updateFeedback(id)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
