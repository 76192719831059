import { startCase, toLower } from 'lodash';

import moment from 'moment';

import React, { Component } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import { options } from 'components/PatientProfileEditModal/selectOptions';

class PatientDemographic extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emergencyContactIndex: 0,
		};

		this.keys = [
			'isActive',
			'preferredName',
			'gender',
			'genderIdentity',
			'sexualOrientation',
			'pronouns',
			'dob',
			'maritalStatus',
			'address',
			'emergencyContact',
			'emergencyContactHomePhone',
			'emergencyContactWorkPhone',
			'insurance',
			'referrer',
			'clientType',
			'payerInfo',
			'canSendEmail',
			'canLeaveVoicemail',
		];
	}

	componentDidUpdate = () => {
		const { data } = this.props;
		if (
			data.clientType?.includes('IOP') &&
			!this.keys.includes('startDate') &&
			!this.keys.includes('dischargeDate')
		) {
			this.keys.push('startDate');
			this.keys.push('dischargeDate');
		}
	};

	handleContactChange = (e) => {
		this.setState({ emergencyContactIndex: e.target.value });
	};

	generateRow(key, value) {
		const { data = {} } = this.props;
		const { emergencyContactIndex } = this.state;

		if (value === null || typeof value === 'undefined') {
			value = '-';
		}

		switch (key) {
			case 'maritalStatus':
			case 'gender':
				return {
					key: startCase(key),
					value: startCase(value),
				};
			case 'address':
				return {
					key: startCase(key),
					value: (
						<div>
							<div>{value.street}</div>
							<div>
								{startCase(toLower(value.city))}, {value.state} {value.zipcode}
							</div>
						</div>
					),
				};
			case 'referrer':
				return {
					key: startCase(key),
					value: `${startCase(value.name)} ${
						value.type && `(${startCase(value.type)})`
					}`,
				};
			case 'dob':
				return {
					key: 'Date of Birth',
					value: moment(value).format('MMMM DD, YYYY'),
				};
			case 'startDate':
				return {
					key: 'Start Date',
					value: value === '-' ? '-' : moment(value).format('MMMM DD, YYYY'),
				};
			case 'dischargeDate':
				return {
					key: 'Discharge Date',
					value: value === '-' ? '-' : moment(value).format('MMMM DD, YYYY'),
				};
			case 'insurance':
				return {
					key: 'Primary Insurance',
					value: value?.primaryInsurance
						? startCase(value?.primaryInsurance)
						: '-',
				};
			case 'emergencyContact': {
				return {
					key: `Emergency Contact${value.length > 1 ? 's' : ''}`,
					value:
						typeof value !== 'string' && value.length > 1 ? (
							<select onChange={this.handleContactChange}>
								{value.map((contact = {}, i) => {
									return (
										<option value={i} key={`emergency-contact-${i}`}>
											{contact.emergencyContact}{' '}
											{contact.relationship && `(${contact.relationship})`}
										</option>
									);
								})}
							</select>
						) : typeof value !== 'string' && value.length === 1 ? (
							`${value[0].emergencyContact} ${
								value[0].relationship && `(${value[0].relationship})`
							}`
						) : (
							'-'
						),
				};
			}
			case 'emergencyContactHomePhone': {
				return {
					key: 'Contact Home Phone',
					value: data?.emergencyContact?.[emergencyContactIndex]?.homePhone
						? formatPhoneNumber(
								`+1${data.emergencyContact[emergencyContactIndex].homePhone}`,
						  )
						: '-',
				};
			}
			case 'emergencyContactWorkPhone': {
				return {
					key: 'Contact Work Phone',
					value: data?.emergencyContact?.[emergencyContactIndex]?.workPhone
						? formatPhoneNumber(
								`+1${data.emergencyContact[emergencyContactIndex].workPhone}`,
						  )
						: '-',
				};
			}
			case 'clientType': {
				return {
					key: 'Client Type',
					value: startCase(options[key][value]),
				};
			}
			case 'isActive': {
				return {
					key: 'Status',
					value: data.isActive ? 'Active' : 'Inactive',
				};
			}
			case 'payerInfo': {
				return {
					key: 'Payer Email',
					value: data.payerInfo?.email || '-',
				};
			}
			case 'preferredName': {
				return {
					key: 'Preferred Name',
					value: data.preferredName || '-',
				};
			}
			case 'genderIdentity': {
				return {
					key: 'Gender Identity',
					value: data.genderIdentity || '-',
				};
			}
			case 'sexualOrientation': {
				return {
					key: 'Sexual Orientation',
					value: data.sexualOrientation || '-',
				};
			}
			case 'pronouns': {
				return {
					key: 'Pronouns',
					value: data.pronouns || '-',
				};
			}
			default:
				return {
					key: startCase(key),
					value: startCase(value),
				};
		}
	}

	render() {
		const {
			data, // toggleEdit
			type,
		} = this.props;

		return (
			<div className="d-flex bg-white p-4 w-100">
				<div className="w-100">
					{this.keys.map((key) => {
						if (type !== 'admin' && key === 'payerInfo') {
							return;
						}

						const value = data[key];
						const output = this.generateRow(key, value);
						return (
							<div className="row w-100" key={output.key}>
								<div
									className="col p-2 align-top font-13"
									style={{ width: '45%' }}
								>
									{output.key}
								</div>
								<div className="col p-2 font-13">{output.value}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default PatientDemographic;
