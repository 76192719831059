import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { BULLET } from 'constants/main';
import { capitalizeWords } from 'utils/helpers';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class SelectGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			receiver: '',
		};
	}

	groups = (programs) =>
		programs.map((item) => ({
			name: item.name,
			programId: item.program_id,
			groupName: item.groupName,
			location: item.location,
			clinician: {
				name: item.clinician_name,
				title: item.therapist_type,
				id: item.id,
			},
		}));

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	arrowClicked = (value) => {
		const { goTo } = this.props;
		let flag = 0;
		if (value) {
			goTo(value);
			flag = 1;
		}
		if (flag === 1) {
			this.closeModal();
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { receiver } = this.state;

		this.setState({ receiver });
	};

	render() {
		const { modal, receiver } = this.state;
		const { programs } = this.props;
		const groupList = this.groups(programs).filter(
			(groupItem) =>
				groupItem.name.toLowerCase().indexOf(receiver.toLowerCase()) !== -1,
		);
		return (
			<div>
				<Modal
					isOpen={modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.closeModal} className="px-4 border-0">
						<div className="font-30 custom-modal-heading py-2 pr-2 pl-0">
							Select Therapy
						</div>
					</ModalHeader>
					<ModalBody>
						<div className="row custom-search py-3">
							<div className="d-flex mx-2 w-100">
								<div className="col-1 pr-0">
									<i className="fas fa-search custom-color-black" />
								</div>
								<input
									placeholder="Search Therapy Name"
									className="col-11 pl-3 custom-search-text font-15"
									type="text"
									value={receiver}
									onChange={(e) => {
										this.setState({
											receiver: e.target.value.substr(0, 20), // For Search funtionality
										});
									}}
								/>
							</div>
						</div>
						<div className="row flex-column">
							{groupList.map((value) => (
								<div key={value}>
									<div className="d-flex border-bottom justify-content-between align-items-center px-4 py-3">
										<div className="d-flex flex-column">
											<div className="text-dark font-15 custom-group-list-heading w-75 mb-1">
												{`${value.groupName} ${BULLET} ${capitalizeWords(
													value.location,
												)}`}
											</div>
											<div className="d-flex">
												<div className="d-flex justify-content-start ml-0 my-2 mr-2 p-0">
													<ImageDetailsCell
														src={value.profilePicture}
														heading={value.clinician.name}
														primarySubHeading={value.clinician.title}
													/>
													{/* <div className="pr-1">
                            <i className="fas fa-user-circle fa-2x" />
                          </div> */}
													{/* <div className="ml-1"> */}
													{/* <h6 className="text-dark m-0 font-15">
                              {value.clinician.name}
                            </h6>
                            <p className="text-muted m-0 font-11">
                              {value.clinician.title}
                            </p> */}
													{/* </div> */}
												</div>
											</div>
										</div>
										<div
											onClick={() => {
												this.arrowClicked(value);
											}}
											className="custom-click-style"
										>
											<i className="fas fa-angle-right custom-arrow" />
										</div>
									</div>
								</div>
							))}
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

SelectGroup.propTypes = {
	hide: PropTypes.func.isRequired,
	goTo: PropTypes.func.isRequired,
	programs: PropTypes.array,
};

export default SelectGroup;
