import { fromJS } from 'immutable';
// import { returnJS } from 'utils/helpers';

import {
    CREATE_OQ_CLIENT,
    CREATE_OQ_CLIENT_SUCCESS,
    CREATE_OQ_CLIENT_ERROR,
    CREATE_OQ_EMPLOYEE,
    CREATE_OQ_EMPLOYEE_SUCCESS,
    CREATE_OQ_EMPLOYEE_ERROR,
    GET_OQ_PROFILE,
    GET_OQ_PROFILE_SUCCESS,
    GET_OQ_PROFILE_ERROR,
    GET_OQ_CLIENT,
    GET_OQ_CLIENT_SUCCESS,
    GET_OQ_CLIENT_ERROR,
    GET_OQ_ADMINISTRATION_URL,
    GET_OQ_ADMINISTRATION_URL_SUCCESS,
    GET_OQ_ADMINISTRATION_URL_ERROR,
    SEND_OQ_ADMINISTRATION_SMS,
    SEND_OQ_ADMINISTRATION_SMS_SUCCESS,
    SEND_OQ_ADMINISTRATION_SMS_ERROR,
} from './constants';
import { MeasuresActionTypes } from './types';

export const initialState = fromJS({
    hasOQAccount: false,
    isFetchingProfile: false,
    isCreatingClient: false,
    isCreatingEmployee: false,
    clientHasOQAccount: false,
    isFetchingClient: false,
    isFetchingURL: false,
    administrationURL: '',
    historyURL: '',
    recentQuestionnaireDate: '',
    isSendingSMS: false,
    errorMessage: '',
    successMessage: '',
});

import { getLogger } from 'utils/logger';
const logger = getLogger('v2.measures.reducer');

function measuresReducer(state = initialState, action: MeasuresActionTypes): typeof initialState {
    state = state.merge({
        errorMessage: '',
        successMessage: '',
    });

    switch (action.type) {
        case GET_OQ_CLIENT:
            logger.info('case GET_OQ_CLIENT');
            if (!action.data) return state;
            return state.merge({
                clientHasOQAccount: false,
                isFetchingClient: true,
            });
        case GET_OQ_CLIENT_SUCCESS:
            logger.info('case GET_OQ_CLIENT_SUCCESS');
            return state.merge({
                clientHasOQAccount: true,
                isFetchingClient: false,
                // administrationURL: action.data.adminURL,
                administrationURL: '',
                historyURL: action.data.historyURL,
                recentQuestionnaireDate: action.data.recentQuestionnaireDate,
            });
        case GET_OQ_CLIENT_ERROR:
            logger.info('case GET_OQ_CLIENT_ERROR');
            return state.merge({
                clientHasOQAccount: false,
                isFetchingClient: false,
                // errorMessage: action.data,
            });
        case GET_OQ_PROFILE:
            logger.info('case GET_OQ_PROFILE');
            return state.merge({
                hasOQAccount: false,
                isFetchingProfile: true,
            });
        case GET_OQ_PROFILE_SUCCESS:
            logger.info('case GET_OQ_PROFILE_SUCCESS');
            return state.merge({
                hasOQAccount: true,
                isFetchingProfile: false,
            });
        case GET_OQ_PROFILE_ERROR:
            logger.info('case GET_OQ_PROFILE_ERROR');
            return state.merge({
                hasOQAccount: false,
                isFetchingProfile: false,
                // errorMessage: action.data,
            });
        case CREATE_OQ_CLIENT:
            logger.info('case CREATE_OQ_CLIENT');
            return state.set('isCreatingClient', true);
        case CREATE_OQ_CLIENT_SUCCESS:
            logger.info('case CREATE_OQ_CLIENT_SUCCESS');
            return state.merge({
                isCreatingClient: false,
                successMessage: 'Client created successfully',
            });
        case CREATE_OQ_CLIENT_ERROR:
            logger.info('case CREATE_OQ_CLIENT_ERROR');
            return state.merge({
                isCreatingClient: false,
                errorMessage: action.data,
            });
        case CREATE_OQ_EMPLOYEE:
            logger.info('case CREATE_OQ_EMPLOYEE');
            return state.set('isCreatingEmployee', true);
        case CREATE_OQ_EMPLOYEE_SUCCESS:
            logger.info('case CREATE_OQ_EMPLOYEE_SUCCESS');
            return state.merge({
                isCreatingEmployee: false,
                successMessage: 'Employee created successfully',
            });
        case CREATE_OQ_EMPLOYEE_ERROR:
            logger.info('case CREATE_OQ_EMPLOYEE_ERROR');
            return state.merge({
                isCreatingEmployee: false,
                errorMessage: action.data,
            });
        case GET_OQ_ADMINISTRATION_URL:
            logger.info('case GET_OQ_ADMINISTRATION_URL');
            return state.merge({
                isFetchingURL: true,
                administrationURL: '',
            });
        case GET_OQ_ADMINISTRATION_URL_SUCCESS:
            logger.info('case GET_OQ_ADMINISTRATION_URL_SUCCESS');
            return state.merge({
                isFetchingURL: false,
                administrationURL: action.data,
                successMessage: 'URL created successfully',
            });
        case GET_OQ_ADMINISTRATION_URL_ERROR:
            logger.info('case GET_OQ_ADMINISTRATION_URL_ERROR');
            return state.merge({
                isFetchingURL: false,
                errorMessage: action.data,
            });
        case SEND_OQ_ADMINISTRATION_SMS:
            logger.info('case SEND_OQ_ADMINISTRATION_SMS');
            return state.merge({ isSendingSMS: true });
        case SEND_OQ_ADMINISTRATION_SMS_SUCCESS:
            logger.info('case SEND_OQ_ADMINISTRATION_SMS_SUCCESS');
            return state.merge({
                isSendingSMS: false,
                successMessage: 'SMS sent successfully',
            });
        case SEND_OQ_ADMINISTRATION_SMS_ERROR:
            logger.info('case SEND_OQ_ADMINISTRATION_SMS_ERROR');
            return state.merge({
                isSendingSMS: false,
                errorMessage: action.data,
            });

        default:
            return state;
    }
};

export default measuresReducer;
