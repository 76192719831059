/*
 *
 * PatientRegister actions
 *
 */
import { ERROR, FETCH_DATA, PATIENT_REGISTRATION, SUCCESS } from './constants';

export function patientRegistration(data) {
	return {
		type: PATIENT_REGISTRATION,
		data,
	};
}

export function success(response) {
	return {
		type: SUCCESS,
		data: response,
	};
}

export function error(err) {
	return {
		type: ERROR,
		data: err,
	};
}

export function fetchData(data) {
	return {
		type: FETCH_DATA,
		data,
	};
}
