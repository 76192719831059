import imgAttendance from 'images/ic_attendance.svg';
import imgAttendanceActive from 'images/ic_attendance_active.svg';
import imgForms from 'images/ic_forms.svg';
import imgFormsActive from 'images/ic_forms_active.svg';
import imgMyGroups from 'images/ic_groups.svg';
import imgMyGroupsActive from 'images/ic_groups_active.svg';
import imgMyClients from 'images/ic_my_clients.svg';
import imgMyClientsActive from 'images/ic_my_clients_active.svg';
import imgTodos from 'images/ic_todos.svg';
import imgTodosActive from 'images/ic_todos_active.svg';

const clinicianFeatures = [
	{
		tabId: 'clinicianTodos',
		tabName: 'To-Dos',
		mobileTabName: 'To-Dos',
		tabIconActive: (
			<img className="tab-img" src={imgTodosActive} alt="TodosActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgTodos} alt="TodosInactive" />
		),
		to: '/clinician/dashboard/todos',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'clinicianAttendance',
		tabName: 'Attendance',
		mobileTabName: 'Attendance',
		tabIconActive: (
			<img
				className="tab-img"
				src={imgAttendanceActive}
				alt="AttendanceActive"
			/>
		),
		tabIconInactive: (
			<img className="tab-img" src={imgAttendance} alt="AttendanceInactive" />
		),
		to: '/clinician/dashboard/attendance',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'clinicianMyGroups',
		tabName: 'My Therapies',
		mobileTabName: 'Therapies',
		tabIconActive: (
			<img className="tab-img" src={imgMyGroupsActive} alt="MyGroupsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgMyGroups} alt="MyGroupsInactive" />
		),
		to: '/clinician/dashboard/groups',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'clinicianMyClients',
		tabName: 'My Clients',
		mobileTabName: 'Clients',
		tabIconActive: (
			<img className="tab-img" src={imgMyClientsActive} alt="MyClientsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgMyClients} alt="MyClientsInactive" />
		),
		to: '/clinician/dashboard/myclients',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
	{
		tabId: 'clinicianForms',
		tabName: 'Measures & Forms',
		mobileTabName: 'Forms',
		tabIconActive: (
			<img className="tab-img" src={imgFormsActive} alt="FormsActive" />
		),
		tabIconInactive: (
			<img className="tab-img" src={imgForms} alt="FormsInactive" />
		),
		to: '/clinician/dashboard/forms',
		childs: [
			{
				tabId: 'new-intake',
				tabName: 'New Intakes',
			},
		],
	},
];

export default clinicianFeatures;
