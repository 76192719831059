/*
 *
 * UserForgotPassword reducer
 *
 */
import { fromJS } from 'immutable';

import { DEFAULT_ACTION, FORGOT_UPDATE_PASSWORD_MSG } from './constants';

export const initialState = fromJS({
	forgotUpdatePasswordMsg: '',
});

function userForgotPasswordReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case FORGOT_UPDATE_PASSWORD_MSG:
			return state.set('forgotUpdatePasswordMsg', action.data.data.msg);
		default:
			return state;
	}
}

export default userForgotPasswordReducer;
