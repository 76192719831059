import { startCase } from 'lodash';

import { formatPhoneNumber } from 'react-phone-number-input';

import TextCell from 'atoms/TableCell/TextCell';

function PharmacyDisplay({ name, phone = '', city = '' }) {
	return (
		<TextCell
			title={name}
			primaryHeading={formatPhoneNumber(phone)}
			secondaryHeading={startCase(city?.toLowerCase())}
		/>
	);
}

export default function Pharmacy({ data, changePharmacy }) {
	const { pharmacy, payload } = data;

	if (!pharmacy && !payload.pharmacyName) {
		return (
			<div
				className="link-color mt-2 mx-2 font-13 hand"
				onClick={() => changePharmacy(data)}
			>
				Assign Pharmacy
			</div>
		);
	}

	if (pharmacy) {
		return (
			<div>
				<PharmacyDisplay
					name={pharmacy.name}
					phone={'+1' + pharmacy.phone}
					city={pharmacy.address?.city}
				/>
				<div
					className="link-color font-11 hand"
					onClick={() => changePharmacy(data)}
				>
					Change
				</div>
			</div>
		);
	}

	return (
		<div>
			<PharmacyDisplay
				title={payload.pharmacyName}
				primaryHeading={'+1' + payload.rxNumber}
				secondaryHeading={payload.pharmacyCity}
			/>
			<div
				className="link-color font-11 hand"
				onClick={() => changePharmacy(data)}
			>
				Change
			</div>
		</div>
	);
}

Pharmacy.displayName = 'PharmacyCell';
