/*
 *
 * Attendance reducer
 *
 */
import { fromJS } from 'immutable';

import {
	DEFAULT_ACTION,
	DELETE_SESSIONS,
	DELETE_SESSIONS_ERROR,
	DELETE_SESSIONS_SUCCESS,
	GET_ATTENDANCE,
	GET_ATTENDANCE_ERROR,
	GET_ATTENDANCE_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
	SAVE_ATTENDANCE,
	SAVE_ATTENDANCE_ERROR,
	SAVE_ATTENDANCE_SUCCESS,
} from './constants';

export const initialState = fromJS({
	attendances: [],
	sessions: [],
	programs: [],
	loadingPrograms: false,
});

function deleteSession(state, action) {
	const { ids } = action;
	const sessions = state.get('sessions').toJS();

	sessions.forEach((session) => {
		let { sessionDetails } = session;

		sessionDetails = sessionDetails.filter((s) => !ids.includes(s.sessionId));

		session.sessionDetails = sessionDetails;
	});
	return sessions;
}

function attendanceReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case GET_ATTENDANCE:
			return state;
		case GET_ATTENDANCE_SUCCESS:
			return state.set('attendances', fromJS(action.data));
		case GET_ATTENDANCE_ERROR:
			return state.set('error', action.error);
		case DELETE_SESSIONS:
			return state;
		case DELETE_SESSIONS_SUCCESS:
			return state.set('sessions', fromJS(deleteSession(state, action)));
		case DELETE_SESSIONS_ERROR:
			return state.set('error', action.error);
		case GET_SESSIONS:
			return state;
		case GET_SESSIONS_SUCCESS:
			return state.set('sessions', fromJS(action.data));
		case GET_SESSIONS_ERROR:
			return state.set('error', action.error);
		case SAVE_ATTENDANCE:
			return state;
		case SAVE_ATTENDANCE_SUCCESS:
			return state;
		case SAVE_ATTENDANCE_ERROR:
			return state.set('error', action.error);
		case LOAD_PROGRAMS:
			return state.set('loadingPrograms', true);
		case LOAD_PROGRAMS_SUCCESS:
			return state.merge({
				programs: fromJS(action.programs),
				loadingPrograms: false,
			});
		case LOAD_PROGRAMS_ERROR:
			return state.merge({
				error: action.error,
				loadingPrograms: false,
			});
		default:
			return state;
	}
}

export default attendanceReducer;
