import api from 'utils/api';

export const verifyPasswordAPI = (data) => {
	const localData = { ...data };
	const { id } = localData;
	delete localData.id;
	return api.post(`/user/${id}/password/verify`, localData);
};

export const updateUserAPI = (data) => {
	return api.put(`/user/${data.id}`, { data });
};

export const updateUserMultiAPI = (data) => {
	return api.put('/user-update-multi', { data });
};

export const updatePasswordAPI = (data) => {
	return api.put(`/user/${data.id}/password`, data);
};

export const updatePasswordOthersAPI = (data) => {
	const { adminId, patientId, newPassword } = data;

	return api.put(
		`/admin/update-password?adminId=${adminId}&patientId=${patientId}&newPassword=${newPassword}`,
	);
};

export const listUsersAPI = (data) => {
	const { id } = data;
	return api.get(`/find/users?id=${id}`);
};
