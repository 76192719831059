import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the attendance state domain
 */

const selectAttendanceDomain = (state) => state.get('attendance', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Attendance
 */

const makeSelectAttendance = () =>
	createSelector(selectAttendanceDomain, (substate) => substate);

const makeSelectGetAttendance = () =>
	createSelector(selectAttendanceDomain, (substate) =>
		returnJS(substate.get('attendances')),
	);

const makeSelectGetSessions = () =>
	createSelector(selectAttendanceDomain, (substate) =>
		returnJS(substate.get('sessions')),
	);

const makeSelectAllPrograms = () =>
	createSelector(selectAttendanceDomain, (substate) =>
		returnJS(substate.get('programs')),
	);

const makeSelectLoadingPrograms = () =>
	createSelector(selectAttendanceDomain, (substate) =>
		returnJS(substate.get('loadingPrograms')),
	);

export default makeSelectAttendance;
export {
	selectAttendanceDomain,
	makeSelectGetAttendance,
	makeSelectGetSessions,
	makeSelectAllPrograms,
	makeSelectLoadingPrograms,
};
