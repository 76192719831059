import PropTypes from 'prop-types';

import React from 'react';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

import './index.css';

/**
 *
 * GroupTherapyConsentFrom
 *
 */

/* eslint-disable react/prefer-stateless-function */
const GroupTherapyConsentFrom = (props) => {
	const {
		handleAcceptance,
		groupTherapyConsentFormChecked,
		name,
		isMobile,
		prevProgress,
	} = props;

	const nextProgress = (event) => {
		const { nextProgress } = props;

		const data = {
			checks: {
				groupTherapyConsentFormChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	const formComplete = () => {
		return groupTherapyConsentFormChecked;
	};

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Group Therapy Consent Form
			</h2>
			<div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
				<p className="pb-4">
					<span className="d-inline align-self-center mr-2">I,</span>

					<input
						type="text"
						value={name}
						aria-describedby="browseGroupConsentClientName"
						className="form-control text-muted accept-input d-inline align-self-center"
						placeholder="Your Name"
						style={{ minWidth: '180px' }}
					/>
					<span className="d-inline align-self-center ml-2">
						, am hereby agreeing to participate in Group Treatment at Mind
						Therapy Clinic. This means I will be receiving treatment and
						interacting with other individuals who are also engaged in the group
						treatment process. I understand that group treatment may involve
						learning personal details about other members of the group. I
						understand that at times I may choose to reveal personal details
						about myself to the group. I know that I am under no obligation to
						reveal anything about myself I do not freely choose to discuss at
						group level. I am pledging my confidentiality to the group as
						described below. I understand all group members will be required to
						make the same pledge.
					</span>
				</p>
				<p className="pt-1 pb-4">
					<h4>Confidentiality of all Clients in Group is a Necessity:</h4>
					<span>
						CONFIDENTIALITY OF ALL CLIENTS IN GROUP IS A NECESSITY. The identity
						of other group members shall not be explicitly or indirectly
						revealed to anyone outside the group by any group member. Staff will
						maintain as confidential all group information including the
						identity of group members to anyone outside of the Mind Therapy
						clinical team. My signature below certifies that I will not reveal
						any personal information about other group members to anyone. I will
						also maintain as confidential the identity of any client or visitor
						I may encounter at Mind Therapy Clinic.
					</span>
				</p>
				<p className="pt-1 pb-4">
					<h4>Movement Groups Disclaimer and Waiver:</h4>
					<span>
						Groups including movement offer clients the opportunity to
						participate in yoga as well as bring mindfulness into practice with
						outdoor activities such as hiking, nature walks and other activities
						cultivating present moment awareness. I understand that these groups
						include physical activity, where the risk of injury is always
						present and cannot be entirely eliminated. If I experience any pain
						or discomfort, I will listen to my body, discontinue the activity,
						and ask for support from the facilitator. I will not hold Mind
						Therapy Clinic and/or its practitioners responsible for any
						discomfort and/or injuries, which may occur as a result of my
						participation.
					</span>
				</p>

				<div className={isMobile ? 'pt-3' : ''}>
					<IAccept
						handleAcceptance={handleAcceptance}
						name="groupTherapyConsentFormChecked"
						value={groupTherapyConsentFormChecked}
						isRequired
					/>
				</div>
			</div>
			<div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!formComplete()}
							onClick={nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

GroupTherapyConsentFrom.propTypes = {
	step: PropTypes.func.isRequired,
	previousStep: PropTypes.func.isRequired,
	patientDetail: PropTypes.object.isRequired,
	modal: PropTypes.bool,
	siteUrl: PropTypes.string,
	handleAcceptance: PropTypes.func.isRequired,
	groupTherapyConsentFormChecked: PropTypes.bool.isRequired,
	toggle: PropTypes.func,
	name: PropTypes.string,
};

export default GroupTherapyConsentFrom;
