/**
 *
 * Clinician
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';

import Redirect from 'components/Redirect';
import { makeSelectAuth } from 'containers/App/selectors';
import ClinicianRegister from 'containers/ClinicianRegister';
import Dashboard from 'containers/Dashboard';

import makeSelectClinician from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class Clinician extends React.Component {
	getClinicianLandingPage = () => {
		const { onboardingComplete } = this.props.auth.loggedInUser;
		if (onboardingComplete) return '/clinician/dashboard';

		return '/clinician/registration';
	};

	render() {
		const { onboardingComplete } = this.props.auth.loggedInUser;
		return (
			<Switch>
				{!onboardingComplete && (
					<Route
						exact
						path="/clinician/registration"
						component={ClinicianRegister}
					/>
				)}
				{onboardingComplete && (
					<Route path="/clinician/dashboard" component={Dashboard} />
				)}
				<Route
					exact
					path="/clinician"
					component={() => (
						<Redirect redirectPath={this.getClinicianLandingPage()} />
					)}
				/>
				<Route
					exact
					path="/"
					component={() => (
						<Redirect redirectPath={this.getClinicianLandingPage()} />
					)}
				/>
				<Route
					component={() => (
						<Redirect redirectPath={this.getClinicianLandingPage()} />
					)}
				/>
			</Switch>
		);
	}
}

Clinician.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
	clinician: makeSelectClinician(),
	auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Clinician);
