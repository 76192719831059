/**
 *
 * ChildSleepHabits
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import TextInput from 'atoms/TextInput';
import { STEPS } from 'pages/intake/constants';
// import components
import { generateObjectArray } from 'utils/helpers';

const singleSelectQuestions = [
	{
		id: 'ableToFallbackToSleep',
		label: 'IS YOUR CHILD ABLE TO FALL BACK TO SLEEP?',
	},
	{
		id: 'doesSnore',
		label: 'DOES YOUR CHILD SNORE?',
	},
	{
		id: 'hasBreathingProblemWhileAsleep',
		label: 'DOES YOUR CHILD STOP BREATHING OR GASP FOR BREATH WHEN ASLEEP?',
	},
	{
		id: 'unexplainedBedwetting',
		label: 'DOES YOUR CHILD HAVE UNEXPLAINED BEDWETTING?',
	},
	{
		id: 'alterationsInMood',
		label: 'DOES YOUR CHILD EXPERIENCE ALTERATIONS IN MOOD?',
	},
	{
		id: 'misbehave',
		label: 'DOES YOUR CHILD MISBEHAVE TO A GREATER DEGREE THAN OTHER CHILDREN?',
	},
	{
		id: 'poorSchoolPerformance',
		label: 'DOES YOUR CHILD HAVE POOR SCHOOL PERFORMANCE?',
	},
	{
		id: 'doesSleepwalk',
		label: 'DOES YOUR CHILD SLEEPWALK?',
	},
	{
		id: 'haveNightTerrors',
		label: 'DOES YOUR CHILD HAVE NIGHT TERRORS?',
	},
];

/* eslint-disable react/prefer-stateless-function */
const ChildSleepHabits = (props) => {
	const { prevProgress, nextProgress, data, handleChange } = props;
	const {
		troubleSleeping,
		troubleSleepingDetails,
		sleepingQuality,
		doesWakeUpDuringNight,
		nightWakingFrequency,
		childSleepHabitDescription,
		anyHeadInjury,
		headInjuryDetails,
		amnesiaAfterHeadInjury,
		moodChangeAfterHeadInjury,
		moodChangeDetails,
		hospitalizationAfterHeadInjury,
		hospitalizationDetails,
		scanPerformed,
		scanDetails,
	} = data;
	return (
		<div className="container-fluid d-flex flex-column px-5">
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Sleep Habits
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.ChildSleepHabits)}>
				<div className="card d-flex flex-column p-4 mb-4">
					<div className="row mt-3 mb-3">
						<label htmlFor="troubleSleeping" className="form-font col-8">
							DOES YOUR CHILD HAVE TROUBLE FALLING ASLEEP?
						</label>
						<SelectButtonGroup
							id="troubleSleeping"
							value={troubleSleeping}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChildSleepHabits)}
							className="col-4"
						/>
					</div>
					{troubleSleeping === 'yes' && (
						<TextArea
							className="mb-4 mt-n2"
							label="IF YES, HOW LONG DOES IT TAKE FOR YOUR CHILD TO FALL ASLEEP? PLEASE DESCRIBE WHY YOUR CHILD HAS TROUBLE IF YOU KNOW:"
							id="troubleSleepingDetails"
							value={troubleSleepingDetails}
							handleInput={handleChange(STEPS.ChildSleepHabits)}
						/>
					)}

					<TextArea
						className="mb-4 mt-n2"
						label="HOW IS THE QUALITY OF YOUR CHILD'S SLEEP (e.g., LIGHT, DEEP, etc.)?"
						id="sleepingQuality"
						value={sleepingQuality}
						handleInput={handleChange(STEPS.ChildSleepHabits)}
					/>

					<div className="row mt-3 mb-3">
						<label htmlFor="doesWakeUpDuringNight" className="form-font col-8">
							DOES YOUR CHILD WAKE UP IN THE MIDDLE OF THE NIGHT?
						</label>
						<SelectButtonGroup
							id="doesWakeUpDuringNight"
							value={doesWakeUpDuringNight}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChildSleepHabits)}
							className="col-4"
						/>
					</div>
					{doesWakeUpDuringNight === 'yes' && (
						<TextInput
							className="mb-4 mt-n2"
							label="IF YES, HOW OFTEN?"
							id="nightWakingFrequency"
							value={nightWakingFrequency}
							handleInput={handleChange(STEPS.ChildSleepHabits)}
						/>
					)}

					{singleSelectQuestions.map((question) => (
						<div className="row mt-3 mb-3" key={question.label}>
							<label htmlFor="troubleSleeping" className="form-font col-8">
								{question.label}
							</label>
							<SelectButtonGroup
								id={question.id}
								value={data[question.id]}
								buttons={generateObjectArray(['Yes', 'No'])}
								handleSelect={handleChange(STEPS.ChildSleepHabits)}
								className="col-4"
							/>
						</div>
					))}

					<TextArea
						className="mb-4"
						label="PLEASE DESCRIBE ANY OF THE ITEMS ABOVE:"
						id="childSleepHabitDescription"
						value={childSleepHabitDescription}
						handleInput={handleChange(STEPS.ChildSleepHabits)}
					/>
				</div>

				<div className="card d-flex flex-column p-4 mb-4">
					<h3>Head Injuries</h3>

					<div className="row mt-3 mb-3">
						<label htmlFor="anyHeadInjury" className="form-font col-8">
							HAS YOUR CHILD EVER HAD ANY HEAD INJURY, SPORTS INJURY TO THE
							HEAD, FALLS, CONCUSSIONS OR CAR ACCIDENTS?
						</label>
						<SelectButtonGroup
							id="anyHeadInjury"
							value={anyHeadInjury}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange(STEPS.ChildSleepHabits)}
							className="col-4"
						/>
					</div>
					{anyHeadInjury === 'yes' && (
						<div>
							<TextArea
								className="mb-4 mt-n2"
								label="IF YES, PLEASE DESCRIBE WHERE ON THE HEAD THE INJURY OCCURED AND AT WHAT AGE:"
								id="headInjuryDetails"
								value={headInjuryDetails}
								handleInput={handleChange(STEPS.ChildSleepHabits)}
							/>
							<div className="row mt-3 mb-3">
								<label
									htmlFor="amnesiaAfterHeadInjury"
									className="form-font col-6"
								>
									DID YOUR CHILD EXPERIENCE AMNESIA OR LOSE CONSCIOUSNESS AFTER
									THE INJURY?
								</label>
								<SelectButtonGroup
									id="amnesiaAfterHeadInjury"
									value={amnesiaAfterHeadInjury}
									buttons={generateObjectArray(['Yes', 'No', "Don't Know"])}
									handleSelect={handleChange(STEPS.ChildSleepHabits)}
									className="col-6"
								/>
							</div>

							<div className="row mt-3 mb-3">
								<label
									htmlFor="moodChangeAfterHeadInjury"
									className="form-font col-8"
								>
									HAS THERE BEEN ANY CHANGE IN MOOD OR MEMORY SINCE THE HEAD
									TRAUMA OCCURED?
								</label>
								<SelectButtonGroup
									id="moodChangeAfterHeadInjury"
									value={moodChangeAfterHeadInjury}
									buttons={generateObjectArray(['Yes', 'No'])}
									handleSelect={handleChange(STEPS.ChildSleepHabits)}
									className="col-4"
								/>
							</div>
							{moodChangeAfterHeadInjury === 'yes' && (
								<TextArea
									className="mb-4 mt-n2"
									label="IF YES, PLEASE DESCRIBE THE CHANGE IN MOOD OR MEMORY:"
									id="moodChangeDetails"
									value={moodChangeDetails}
									handleInput={handleChange(STEPS.ChildSleepHabits)}
								/>
							)}

							<div className="row mt-3 mb-3">
								<label
									htmlFor="hospitalizationAfterHeadInjury"
									className="form-font col-8"
								>
									WAS YOUR CHILD HOSPITALIZED FOR THE HEAD INJURY?
								</label>
								<SelectButtonGroup
									id="hospitalizationAfterHeadInjury"
									value={hospitalizationAfterHeadInjury}
									buttons={generateObjectArray(['Yes', 'No'])}
									handleSelect={handleChange(STEPS.ChildSleepHabits)}
									className="col-4"
								/>
							</div>
							{hospitalizationAfterHeadInjury === 'yes' && (
								<TextArea
									className="mb-4 mt-n2"
									label="IF YES, PLEASE PROVIDE DETAILS OF THE HOSPITALIZATION:"
									id="hospitalizationDetails"
									value={hospitalizationDetails}
									handleInput={handleChange(STEPS.ChildSleepHabits)}
								/>
							)}

							<div className="row mt-3 mb-3">
								<label htmlFor="scanPerformed" className="form-font col-8">
									WAS ANY TYPE OF SCAN PERFORMED (CAT, MRI, EEG, etc.)?
								</label>
								<SelectButtonGroup
									id="scanPerformed"
									value={scanPerformed}
									buttons={generateObjectArray(['Yes', 'No'])}
									handleSelect={handleChange(STEPS.ChildSleepHabits)}
									className="col-4"
								/>
							</div>
							{scanPerformed === 'yes' && (
								<TextArea
									className="mb-4 mt-n2"
									label="IF YES, WHAT DID IT SHOW?"
									id="scanDetails"
									value={scanDetails}
									handleInput={handleChange(STEPS.ChildSleepHabits)}
								/>
							)}
						</div>
					)}
				</div>

				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

ChildSleepHabits.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default ChildSleepHabits;
