import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';


interface Props {
    prevProgress: () => void,
    nextProgress: () => () => void,
    isMobile: boolean,
}

export default function KetamineOverview(props: Props): JSX.Element {
    const { prevProgress, nextProgress, isMobile } = props;

    const handleNext = () => {
        nextProgress()();
    };

    return (
        <div
            className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
        >
            <h2 className="align-self-center font-weight-bold mb-5 mt-5">
                Informed Consent In-Office Ketamine-Assisted Psychotherapy
            </h2>
            <div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
                <div className="pt-1 pb-4">
                    <h4>Overview:</h4>
                    <span>
                        Ketamine is a dissociative class compound, with an FDA schedule
                        III designation. It is indicated for and commonly used as an
                        anesthetic medication. Ketamine has been found to be helpful for
                        treating depression, even in individuals who have not responded to
                        other interventions. It has a unique effect, in that it can work
                        very rapidly, with individuals frequently seeing improvement in
                        their depression within hours. However, ketamine response is not
                        guaranteed and even in responders, there can be a high rate of
                        relapse back to the depressed state, even with repeat dosing that
                        increases over time. The ongoing beneficial effects of ketamine
                        that can be realized include general mood improvement, lessening
                        of anhedonia, and reduction/resolution of suicidal ideation.
                        Improvements to levels of anxiety and behavioral patterns of
                        sleep, appetite, and energy can also be realized. Ketamine has
                        also demonstrated benefits in anxiety conditions, including PTSD,
                        and may yield gains to patterns of obsessive thinking or
                        rumination. Coupling these biological effects with psychotherapy
                        and behavioral change is designed to maximize benefits and
                        sustained gains.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Off-label use:</h4>
                    <span>
                        Ketamine does not have an indication for the treatment of
                        depression, anxiety or any other psychiatric condition by the FDA.
                        Therefore, the provision of ketamine for these conditions is an
                        &apos;off-label&apos; use. This is a legal prescribing practice
                        and occurs quite commonly- up to 20% of medication in the US, by
                        some measures. For example, the use of tricyclic antidepressants
                        for pain, or stimulants for depression with a profound
                        neuro-vegetative component.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Mechanism:</h4>
                    <span>
                        Most clearly, ketamine operates on a receptor level as NMDA
                        antagonist and regulates the availability of the neurotransmitter
                        glutamate in the brain. The antidepressant effect appears to be
                        mediated by downstream signal effects of AMPA receptors. A variety
                        of other receptors are targeted and contribute to the acute and
                        ongoing effects of treatment.
                    </span>
                </div>
            </div>

            <div className="d-flex mt-4 mb-5 justify-content-center">
                <div className="col-6">
                    <PrevButton onClick={prevProgress} isMobile={isMobile} />
                </div>
                <div className="col-6">
                    <NextButton onClick={handleNext} isMobile={isMobile} />
                </div>
            </div>
        </div>
    );
}
