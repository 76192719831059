/*
 *
 * Forms reducer
 *
 */
import { fromJS } from 'immutable';

import { returnJS } from 'utils/helpers';

import {
	CLEAR_UPLOAD_RESULT,
	CREATE_SIGNING_DOC,
	CREATE_SIGNING_DOC_ERROR,
	CREATE_SIGNING_DOC_SUCCESS,
	DECLINE_FORM,
	DECLINE_FORM_ERROR,
	DECLINE_FORM_SUCCESS,
	DELETE_FORM,
	DELETE_FORM_ERROR,
	DELETE_FORM_SUCCESS,
	FETCH_CLIENTS,
	FETCH_CLIENTS_ERROR,
	FETCH_CLIENTS_SUCCESS,
	FETCH_FORM,
	FETCH_FORMS,
	FETCH_FORMS_ERROR,
	FETCH_FORMS_SUCCESS,
	FETCH_FORM_ERROR,
	FETCH_FORM_SUCCESS,
	FETCH_GROUPS,
	FETCH_GROUPS_ERROR,
	FETCH_GROUPS_SUCCESS,
	SHARE_FORM,
	SHARE_FORM_ERROR,
	SHARE_FORM_SUCCESS,
	SHOW_FORM_SHARE,
	SIGN_DOC,
	SIGN_DOC_ERROR,
	SIGN_DOC_SUCCESS,
	SUBMIT_JOTFORM,
	SUBMIT_JOTFORM_ERROR,
	SUBMIT_JOTFORM_SUCCESS,
	UPDATE_FORM,
	UPDATE_FORM_ERROR,
	UPDATE_FORM_SUCCESS,
	UPLOAD_FORMS,
	UPLOAD_FORMS_ERROR,
	UPLOAD_FORMS_RESPONSE,
} from './constants';

export const initialState = fromJS({
	formShareVisible: false,
	isUploading: false,
	uploadResult: { docsSaved: [], errors: [] },
	file: null,
	templates: [],
	sharedForms: [],
	clients: [],
	groups: [],
	url: '',
	errors: {
		forms: false,
		clients: false,
		groups: false,
		update: false,
	},
	isFetching: {
		forms: false,
		clients: false,
		groups: false,
	},
	isUpdating: false,
	isSending: false,
});

function addForms(state, res) {
	const templates = state.get('templates').toJS();
	return [...res.documents, ...templates];
}

function setFormState(state, form, data) {
	let newForms = state.get('sharedForms').toJS();

	return newForms.map((f) =>
		f.formUUID === form.uuid
			? {
					...f,
					...data,
			  }
			: f,
	);
}

function updateForm(state, form, type) {
	let newForms;
	newForms = state.get(type).toJS();

	const formIndex = newForms.findIndex((f) => f.uuid === form.uuid);
	if (formIndex >= 0) {
		newForms[formIndex] = form;
	} else {
		newForms.splice(0, 0, form);
	}

	return newForms;
}

function deleteForm(state, form, type) {
	let newForms = state.get(type).toJS();

	newForms = newForms.filter((f) => f.uuid !== form.uuid);

	return newForms;
}

function generateResult(res) {
	let docsSaved = [];

	res.documents.forEach((doc) => {
		docsSaved.push(`${doc.filename} uploaded successfully`);
	});

	return {
		docsSaved,
		errors: res.errors,
	};
}

function fetchType(state, type) {
	return state.merge({
		[type]: [],
		errors: {
			...state.get('errors').toJS(),
			[type]: false,
		},
		isFetching: {
			...state.get('isFetching').toJS(),
			[type]: true,
		},
	});
}

function fetchTypeSuccess(state, result, type) {
	return state.merge({
		[type]: result,
		errors: {
			...state.get('errors').toJS(),
			[type]: false,
		},
		isFetching: {
			...state.get('isFetching').toJS(),
			[type]: false,
		},
	});
}

function fetchTypeError(state, type) {
	return state.merge({
		[type]: [],
		errors: {
			...state.get('errors').toJS(),
			[type]: true,
		},
		isFetching: {
			...state.get('isFetching').toJS(),
			[type]: false,
		},
	});
}

function formsReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_FORM_SHARE:
			return state.set('formShareVisible', action.formShareVisible);

		case CLEAR_UPLOAD_RESULT:
			return state.set('uploadResult', { docsSaved: [], errors: [] });

		case FETCH_FORMS:
			state = fetchType(state, 'templates');
			return fetchType(state, 'sharedForms');
		case FETCH_CLIENTS:
			return fetchType(state, 'clients');
		case FETCH_GROUPS:
			return fetchType(state, 'groups');

		case FETCH_FORMS_SUCCESS:
			state = fetchTypeSuccess(state, action.forms.templates, 'templates');
			return fetchTypeSuccess(state, action.forms.sharedForms, 'sharedForms');
		case FETCH_CLIENTS_SUCCESS:
			const { clients } = action;
			let clientInfo = clients.map((c) => ({
				name: c.client.name,
				id: c.client.patientId,
				groups: [...c.programIds],
				email: c.client.email,
			}));

			return fetchTypeSuccess(state, clientInfo, 'clients');
		case FETCH_GROUPS_SUCCESS:
			const { groups } = action;
			let groupInfo = groups.map((g) => ({ name: g.groupName, id: g.id }));

			return fetchTypeSuccess(state, groupInfo, 'groups');

		case FETCH_FORMS_ERROR:
			state = fetchTypeError(state, 'templates');
			return fetchTypeError(state, 'sharedForms');
		case FETCH_GROUPS_ERROR:
			return fetchTypeError(state, 'groups');
		case FETCH_CLIENTS_ERROR:
			return fetchTypeError(state, 'clients');

		case FETCH_FORM:
		case DECLINE_FORM:
			return state.set('isUpdating', true);
		case FETCH_FORM_SUCCESS:
		case DECLINE_FORM_SUCCESS:
			return state.merge({
				sharedForms: setFormState(state, action.form, {
					state: 'DECLINED',
					declineReason: action.form.declineReason,
				}),
				isUpdating: false,
				url: '',
			});
		case FETCH_FORM_ERROR:
		case DECLINE_FORM_ERROR:
			return state.set('isUpdating', false);

		case UPDATE_FORM:
			state = state.merge({
				isUpdating: true,
			});

			return fetchType(state, 'update');
		case UPDATE_FORM_SUCCESS:
			return state.merge({
				templates: updateForm(state, action.document, 'templates'),
				isUpdating: false,
				uploadResult: {
					docsSaved: [`${action.document.filename} updated successfully`],
					errors: [],
				},
			});
		case UPDATE_FORM_ERROR:
			state = fetchTypeError(state, 'update');
			return state.set('isUpdating', false);
		case CREATE_SIGNING_DOC:
			return state.merge({
				isSending: true,
			});
		case CREATE_SIGNING_DOC_SUCCESS:
			const { successes = [], failures = [] } = action.result;
			const sharedForms = returnJS(state.get('sharedForms'));

			state = state.merge({
				isSending: false,
				isUpdating: false,
				uploadResult: {
					docsSaved: [
						`Successfully shared ${successes.length} form${
							successes.length > 1 ? 's' : ''
						}`,
					],
					errors: failures.map(
						(f) => `Could not share form with ${f.client?.name}`,
					),
				},
				sharedForms: [...sharedForms, ...successes],
			});

			return state;

		case SIGN_DOC:
			return state.merge({
				isUpdating: true,
				url: '',
			});
		case SIGN_DOC_SUCCESS:
			return state.merge({
				url: action.url,
				isUpdating: false,
			});
		case SIGN_DOC_ERROR:
		case CREATE_SIGNING_DOC_ERROR:
			return state.merge({
				isUpdating: false,
				isSending: false,
				errors: ['Could not share file!'],
			});

		case DELETE_FORM:
			return state.merge({
				isUpdating: true,
			});
		case DELETE_FORM_SUCCESS:
			return state.merge({
				templates: deleteForm(state, action.document, 'templates'),
				isUpdating: false,
				uploadResult: {
					docsSaved: [`${action.document.filename} deleted successfully`],
					errors: [],
				},
			});
		case DELETE_FORM_ERROR:
			return state.merge({
				isUpdating: false,
				uploadResult: {
					docsSaved: [],
					errors: [action.error || 'Document could not be deleted'],
				},
			});

		case UPLOAD_FORMS:
			return state.merge({
				isUploading: true,
				uploadResult: { docsSaved: [], errors: [] },
			});
		case UPLOAD_FORMS_RESPONSE:
			return state.merge({
				templates: addForms(state, action.res),
				isUploading: false,
				uploadResult: generateResult(action.res),
			});
		case UPLOAD_FORMS_ERROR:
			return state.merge({
				isUploading: false,
				uploadResult: { docsSaved: [], errors: action.errors },
			});

		case SHARE_FORM:
			state = fetchType(state, 'share');
			return state.set('isUpdating', false);
		case SHARE_FORM_SUCCESS:
			state = fetchTypeSuccess(state, 'share');
			return state.merge({
				file: action.file,
			});
		case SHARE_FORM_ERROR:
			return fetchTypeError(state, 'share');

		case SUBMIT_JOTFORM:
			return state.merge({
				isUpdating: true,
			});
		case SUBMIT_JOTFORM_SUCCESS:
			return state.merge({
				isUpdating: false,
			});
		case SUBMIT_JOTFORM_ERROR:
			return state.merge({
				isUpdating: false,
				errors: [action.err || 'Could not submit form!'],
			});

		default:
			return state;
	}
}

export default formsReducer;
