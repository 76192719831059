/*
 *
 * Patient Profile constants
 *
 */

export const FETCH_PATIENT_PROFILE = 'src/PatientProfile/FETCH_PATIENT_PROFILE';
export const FETCH_PATIENT_PROFILE_ERROR =
	'src/PatientProfile/FETCH_PATIENT_PROFILE_ERROR';
export const FETCH_PATIENT_PROFILE_SUCCESS =
	'src/PatientProfile/FETCH_PATIENT_PROFILE_SUCCESS';

export const FETCH_PATIENT_GROUPS = 'src/PatientProfile/FETCH_PATIENT_GROUPS';
export const FETCH_PATIENT_GROUPS_ERROR =
	'src/PatientProfile/FETCH_PATIENT_GROUPS_ERROR';
export const FETCH_PATIENT_GROUPS_SUCCESS =
	'src/PatientProfile/FETCH_PATIENT_GROUPS_SUCCESS';

export const FETCH_GROUPS = 'src/PatientProfile/FETCH_GROUPS';
export const FETCH_GROUPS_ERROR = 'src/PatientProfile/FETCH_GROUPS_ERROR';
export const FETCH_GROUPS_SUCCESS = 'src/PatientProfile/FETCH_GROUPS_SUCCESS';

export const ADD_CLIENT_TO_GROUP = 'src/PatientProfile/ADD_CLIENT_TO_GROUP';
export const ADD_CLIENT_TO_GROUP_ERROR =
	'src/PatientProfile/ADD_CLIENT_TO_GROUP_ERROR';
export const ADD_CLIENT_TO_GROUP_SUCCESS =
	'src/PatientProfile/ADD_CLIENT_TO_GROUP_SUCCESS';

export const UNENROLL = 'src/PatientProfile/UNENROLL';
export const UNENROLL_ERROR = 'src/PatientProfile/UNENROLL_ERROR';
export const UNENROLL_SUCCESS = 'src/PatientProfile/UNENROLL_SUCCESS';

export const FETCH_PATIENT_ATTENDANCE =
	'src/PatientProfile/FETCH_PATIENT_ATTENDANCE';
export const FETCH_PATIENT_ATTENDANCE_ERROR =
	'src/PatientProfile/FETCH_PATIENT_ATTENDANCE_ERROR';
export const FETCH_PATIENT_ATTENDANCE_SUCCESS =
	'src/PatientProfile/FETCH_PATIENT_ATTENDANCE_SUCCESS';

export const FETCH_PATIENT_DOCS = 'src/PatientProfile/FETCH_PATIENT_DOCS';
export const FETCH_PATIENT_DOCS_ERROR =
	'src/PatientProfile/FETCH_PATIENT_DOCS_ERROR';
export const FETCH_PATIENT_DOCS_SUCCESS =
	'src/PatientProfile/FETCH_PATIENT_DOCS_SUCCESS';

export const UPDATE_PATIENT_INFO = 'src/PatientProfile/UPDATE_PATIENT_INFO';
export const UPDATE_PATIENT_INFO_ERROR =
	'src/PatientProfile/UPDATE_PATIENT_INFO_ERROR';
export const UPDATE_PATIENT_INFO_SUCCESS =
	'src/PatientProfile/UPDATE_PATIENT_INFO_SUCCESS';

export const UPDATE_ENROLLMENT = 'src/PatientProfile/UPDATE_ENROLLMENT';
export const UPDATE_ENROLLMENT_ERROR =
	'src/PatientProfile/UPDATE_ENROLLMENT_ERROR';
export const UPDATE_ENROLLMENT_SUCCESS =
	'src/PatientProfile/UPDATE_ENROLLMENT_SUCCESS';

export const GET_SESSIONS = 'src/PatientProfile/GET_SESSIONS';
export const GET_SESSIONS_ERROR = 'src/PatientProfile/GET_SESSIONS_ERROR';
export const GET_SESSIONS_SUCCESS = 'src/PatientProfile/GET_SESSIONS_SUCCESS';

export const CLEAR_PROFILE = 'src/PatientProfile/CLEAR_PROFILE';

export const mtcClinTypes = [
	'Individual Therapist',
	'Family Therapist',
	'Case Manager',
	'Psychiatrist',
];
