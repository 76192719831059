import { generateObjectArray } from 'utils/helpers';

let childSymptoms = [
	'Runny or stuffy nose',
	'Frequent coughing or wheezing',
	'Dark Circles or bags under the eyes',
	'Aggresion such as biting, hitting, spitting, pinching, punching, and kicking',
	'One or both ears red and/or burning',
	'Refusal to be touched',
	'Dry flaky lips',
];
let childAdhdDevelopmentMilestones = [
	'Had colic',
	'Had sleep problems',
	'Was fussy and unhappy',
	'Did not enjoy cuddling',
	'Was intense and/or loud',
	'Was hard to arouse while asleep',
	'Was unpredictable in feeding and sleeping',
	'Was sensitive to noise, texture, clothing',
	'Had bedwetting or problems with soiling',
	'Was restless, squirmy, into everything',
	'Had difficulty in how he/she handled change in routine',
	'Protested when first introduced to new foods, places, or people',
	'Was not calmed by holding or stroking',
];
let childAdhdBehaviors = [
	'Loses things',
	'Is forgetful in daily activities',
	'Fails to pay attention to details or is careless',
	'Has difficulty organizing tasks and activities',
	"Doesn't seem to listen to what is being said",
	'Is easily distracted by external stimuli',
	'Has trouble keeping attention on tasks or play',
	'Shifts from one uncompleted activity to another',
	'Has difficulty remaining seated',
	'Has difficulty awaiting turns',
	'Has difficulty playing quietly',
	'Talks excessively',
	'Interrupts or intrudes on others',
	'Squirms in seat or fidgets',
	'Inappropriately runs or climbs',
	'Appear driven or "on the go"',
	'Blurts out answers to questions before they have been completed',
	'Avoids, dislikes or is reluctant to engage in tasks requiring sustained mental effort',
	'Neither follows through on instructions nor completes chores, schoolwork or jobs (not because of oppositional behavior or failure to understand)',
];
let childAdhdSymptoms = [
	'Attention',
	'Impulsivity',
	'Focus',
	'Distractibility',
	'Oppositional',
	'Restless / Always active',
];
let disorders = [
	'Anxiety',
	'ADHD',
	'OCD',
	'Depression',
	'Schizophrenia',
	'Eating Disorder',
	'Bipolar Disorder',
	'Learning Disorders',
	'Personality Disorders',
	'Trauma-related Disorder',
	'Somatic Symptom Disorder',
	'Austism Spectrum Disorder',
	'Alcohol / Substance Abuse Disorders',
	'Other',
];
let supportersList = [
	'Family',
	'Coworkers',
	'Friends',
	'Significant other(s)',
	'Others',
];
let adultMedicalConditions = [
	'High/Low blood pressure',
	'High cholesterol',
	'Heart disease',
	'Cancer',
	'Diabetes',
	'Liver problems',
	'Kidney problems',
	'Respiratory problems',
	'Asthma',
	'Nervous system disorder',
	'Seizures',
	'Gastrointestinal problems',
	'Blood disorder',
	'Thyroid disorder',
	'Other glandular disorder',
	'Sleep disorder',
	'Headaches/Migraines',
	'Pain disorder',
];
let adultFamilyMedicalConditions = [
	'High/Low blood pressure',
	'High cholesterol',
	'Heart disease',
	'Cancer',
	'Diabetes',
	'Liver problems',
	'Kidney problems',
	'Respiratory problems',
	'Asthma',
	'Nervous system disorder',
	'Seizures',
	'Gastrointestinal problems',
	'Blood disorder',
	'Thyroid disorder',
	'Other glandular disorder',
	'Sleep disorder',
	'Headaches/Migraines',
	'Pain disorder',
];

childSymptoms = generateObjectArray(childSymptoms, { isSelected: false });
childAdhdDevelopmentMilestones = generateObjectArray(
	childAdhdDevelopmentMilestones,
	{ isSelected: false },
);
childAdhdBehaviors = generateObjectArray(childAdhdBehaviors, {
	isSelected: false,
});
childAdhdSymptoms = generateObjectArray(childAdhdSymptoms, {
	isSelected: false,
});
disorders = generateObjectArray(disorders, {
	isSelected: false,
});
supportersList = generateObjectArray(supportersList, { isSelected: false });
adultMedicalConditions = generateObjectArray(adultMedicalConditions, {
	isSelected: false,
});
adultFamilyMedicalConditions = generateObjectArray(
	adultFamilyMedicalConditions,
	{
		isSelected: false,
	},
);

export const stateFields = {
	childBasicInfo: {
		name: '',
		dob: '',
		age: '',
		date: '',
		othersPresent: '',
		personCompletingQuestionnaire: '',
		maritalStatus: '',
		legallyAuthorizedDecisonMaker: '',
		currentLivingSituation: '',
		allergicToMedication: 'no',
		allergyDetails: '',
		currentMedications: '',
		currentMedicationDosage: '',
		pastMedications: '',
		reasonToDiscontinue: '',
	},
	childAppointmentReason: {
		appointmentReason: '',
		symptomsDuration: '',
		symptomsRelatedToLifeSituation: 'no',
		symptomsDetails: '',
		doesSymptomsOccurInCycle: 'no',
		symptomsCycleDetails: '',
		didExperienceTraumas: 'no',
		traumaDetails: '',
		childSymptoms,
		otherMedicalSymptoms: '',
	},
	childAdhdInfo: {
		previouslyADHDEvaluated: 'no',
		evaluationDetails: '',
		adhdSymptoms: childAdhdSymptoms,
		symptomsAtHome: '',
		symptomsAtSchool: '',
		symptomsAtRelation: '',
		symptomsDuration: '',
		symptomsPresence: '',
		symptomsPresenceDetails: '',
		childDevelopmentMilestones: childAdhdDevelopmentMilestones,
		childBehaviors: childAdhdBehaviors,
	},
	childSleepHabits: {
		troubleSleeping: 'no',
		troubleSleepingDetails: '',
		sleepingQuality: '',
		doesWakeUpDuringNight: 'no',
		nightWakingFrequency: '',
		ableToFallbackToSleep: 'no',
		doesSnore: 'no',
		hasBreathingProblemWhileAsleep: 'no',
		unexplainedBedwetting: 'no',
		alterationsInMood: 'no',
		misbehave: 'no',
		poorSchoolPerformance: 'no',
		doesSleepwalk: 'no',
		haveNightTerrors: 'no',
		childSleepHabitDescription: '',
		anyHeadInjury: 'no',
		headInjuryDetails: '',
		amnesiaAfterHeadInjury: '',
		moodChangeAfterHeadInjury: 'no',
		moodChangeDetails: '',
		hospitalizationAfterHeadInjury: 'no',
		hospitalizationDetails: '',
		scanPerformed: 'no',
		scanDetails: '',
	},
	childOtherIntake: {
		childFamilyLife: '',
		peopleLivingWithChild: '',
		grade: '',
		school: '',
		schoolPerformance: '',
		hasIEP: 'no',
		hasIEPUnderstanding: 'no',
		iepUnderstanding: '',
		iepFollowed: '',
		hasLegalHistory: 'no',
		legalHistoryDetails: '',
		presentlyOnProbation: 'no',
		probationRequirements: '',
	},
	adultPsychiatricHistory: {
		pastPsychotherapy: 'no',
		pastPsychotherapists: '',
		diagnosesHistory: disorders,
		psychiatricHospitalization: 'no',
		psychiatricHospitalizationReason: '',
		psychiatricHospitalizationDetails: '',
		familypsychiatricHistory: 'no',
		familypsychiatricHistoryDetails: '',
		suicideAttempt: 'no',
		suicideAttemptDetails: '',
		familySuicideHistory: 'no',
		familySuicideHistoryDetails: '',
		selfMultilation: 'no',
		selfMultilationDetails: '',
	},
	adultMedicalHistory: {
		headInjuries: 'no',
		headInjuryArea: '',
		amnesiaAfterHeadInjury: '',
		moodChangeAfterHeadInjury: '',
		moodChangeAfterHeadInjuryDetails: '',
		hospitalizedAfterHeadInjury: '',
		hospitalizedAfterHeadInjuryDetails: '',
		scanPerformed: '',
		scanDetails: '',
		painProblem: 'no',
		painDetails: '',
		painScale: '',
		painDuration: '',
		ongoingPainTreatment: '',
		ongoingPainTreatmentDetails: '',
		regularPeriods: 'nA',
		irregularPeriodsDetail: '',
		takingContraceptives: 'no',
		moodChangeAfterContraceptives: '',
		moodChangeAfterContraceptivesDetail: '',
		menopausalSymptoms: 'no',
		visitedDoctorForMenopausal: '',
		treatmentForMenopausal: '',
		pmsSymptoms: 'no',
		pmsSymptomsDetail: '',
	},
	adultOtherHistory: {
		childhoodHistory: '',
		spiritualFactors: '',
		supportSystem: 'no',
		supporters: supportersList,
		vocationalHistory: '',
		servedInMilitary: 'no',
		militaryBranch: '',
		dischargeReceived: '',
		legalProblem: 'no',
		legalProblemDetails: '',
		onProbation: 'no',
		probationRequirements: '',
	},
	adultMedical: {
		relativesOnMedication: 'no',
		relativesMedicationHelpful: 'no',
		recentPhysicalDate: '',
		concernAboutHealth: 'no',
		describeHealthConcerns: '',
		bloodWorkDone: 'no',
		anythingAbnormal: '',
		currentMedicationList: '',
		dosageForMedicationList: '',
		pastMedication: '',
		medicineDiscontinueReason: '',
		prescriberNameLocation: '',
		relativeMedicationList: '',
		medicalConditions: adultMedicalConditions,
	},
	adultMedicalContinued: {
		appetite: '',
		recentWeightChanges: 'no',
		recentWeightChangesDescribe: '',
		sufferAnorexia: 'no',
		sufferAnorexiaDescribe: '',
		funtioningSexually: 'no',
		medicationSideEffects: 'no',
		medicalSideEffectDescribe: '',
		otherMedicalSymtoms: '',
		familyMedicalConditions: adultFamilyMedicalConditions,
	},
	adultSleepHabits: {
		troubleFallingAsleep: 'no',
		troubleFallingAsleepDescribe: '',
		qualityOfSleepDescribe: '',
		doYouSnore: 'dontKnow',
		stopBreathingAsleep: 'dontKnow',
		wakeMiddleNight: 'no',
		fallbackToSleep: 'sometimes',
		feelRestedInMorning: 'sometimes',
		sleepProblemDuration: '',
	},
	adultSubstanceAbuse: {
		useAlcohol: 'no',
		useAlcoholDescribe: '',
		afterDrinkingDescribe: '',
		useNicotine: 'no',
		useNicotineDescribe: '',
		drinkCaffeinatedDrinks: 'no',
		drinkCaffeinatedDrinksDescribe: '',
		useRecreationalDrugs: 'no',
		useRecreationalDrugsDescribe: '',
		appointmentDueToSubstances: 'no',
		appointmentDueToSubstancesDescribe: '',
		friendsFamilyVoiceConcern: 'no',
		threatenDueToAlcohol: 'no',
		threatenDueToAlcoholDescribe: '',
		geneticProblemOfAlcohol: 'no',
		geneticProblemOfAlcoholDescribe: '',
	},
};
