import React, { useState } from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
} from 'reactstrap';

import classnames from 'classnames';
import { noop } from 'utils/common';

const NavTab = (props) => {
	const {
		type,
		getLabel,
		activeTab,
		handleChange = noop,
		isMobile,
		switchTab,
		labels = { patient: [], clinician: [], admin: [] },
	} = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const getNavLink = () => {
		let headingLabel = [];
		let userLabels = labels[type] || [];
		let classes = (id) =>
			classnames(
				{
					active: activeTab === id.toString(),
					mobile: isMobile,
					'link-color': isMobile,
					'font-13': !isMobile,
					'font-18': isMobile,
				},
				'nav-link hand',
			);

		headingLabel = userLabels.map((value) => (
			<NavItem key={value.id}>
				<div
					className={classes(value.id)}
					onClick={() => {
						switchTab(value.id.toString());
					}}
					style={{ position: 'relative' }}
				>
					{activeTab === value.id.toString() && isMobile && (
						<span
							className="custom-arrow right"
							style={{ position: 'absolute', left: 0, top: 17 }}
						></span>
					)}
					{value.name}
					{value.notify && (
						<div
							style={{ position: 'absolute', top: 20, right: 10, zIndex: 100 }}
						>
							<div className="notification-bubble" />
						</div>
					)}
				</div>
			</NavItem>
		));

		return headingLabel;
	};

	return (
		<div
			className={`${
				isMobile ? 'mb-1' : 'mb-3'
			} d-flex justify-content-between filter-nav`}
		>
			{isMobile ? (
				<ButtonDropdown
					direction="down"
					isOpen={dropdownOpen}
					toggle={toggleDropdown}
					className="w-100"
				>
					<DropdownToggle
						className="btn btn-white bg-white link-color btn-lg btn-block font-15 font-weight-bold d-flex custom-caret justify-content-center font-17"
						nav
						caret
					>
						{getLabel(activeTab, type)}
					</DropdownToggle>
					{dropdownOpen && (
						<DropdownMenu className="w-100">
							{getNavLink(type).map((nav) => (
								<DropdownItem key={nav}>{nav}</DropdownItem>
							))}
						</DropdownMenu>
					)}
				</ButtonDropdown>
			) : (
				<div>
					<Nav
						tabs
						className="actions m-0 link-color w-100 border-bottom-0 shadow-none"
					>
						{getNavLink(type)}
					</Nav>
					<div className="col-1 p-0 d-flex justify-content-end bg-white">
						<div
							className="col-6 fa-filter border-bottom-0 shadow-none"
							onClick={handleChange}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default NavTab;
