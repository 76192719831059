import history from 'utils/history';

import { noop } from 'lodash';

import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';

import './Covid.css';

export default function Covid({ toggle = noop }) {
	const { auth: { loggedInUser } = { loggedInUser: {} }, isMobile } =
		useSelector((state) => state.get('app').toJS());
	const { name = '', email = '', phone = '', id } = loggedInUser;
	const [firstName = '', lastName = ''] = name.split(' ');
	const [isLoading, setIsLoading] = useState(true);

	let phoneTransform = phone.replace('+1', '');

	const query = `name[first]=${firstName}&name[last]=${lastName}&email=${email}&phone=${phoneTransform}&userId=${id}&return=${window.location.origin}`;

	return (
		<Modal
			isOpen={true}
			className="modal-dialog-centered custom-modal covid-modal"
			backdrop="static"
			toggle={toggle}
			style={{
				width: '100%',
				maxWidth: 752,
				minHeight: 500,
				margin: '0.5rem auto',
			}}
		>
			<div
				className="bg-white centered w-100 fade"
				style={{
					opacity: isLoading ? 1 : 0,
					height: 840,
					transition: '0.3s opacity ease-in-out',
					pointerEvents: 'none',
				}}
			>
				<div className="centered">
					<SmallLoadingIndicator text="Loading..." color="var(--main-color)" />
				</div>
			</div>
			{!isLoading && (
				<CloseButton
					style={{ position: 'absolute', top: 10, right: 10, zIndex: 10 }}
					onClick={() => history.push('/patient/dashboard')}
				/>
			)}
			<iframe
				id="JotFormIFrame-220375767115154"
				title="COVID-19 Vaccination Card Upload Form"
				allowfullscreen="true"
				allow="geolocation; microphone; camera"
				src={`https://hipaa.jotform.com/220375767115154?${query}`}
				style={{
					minWidth: '100%',
					minHeight: isMobile ? 1140 : 840,
					border: 'none',
					borderRadius: '0.3rem',
					background: 'transparent',
					zIndex: 9,
				}}
				onLoad={() => {
					window.parent.scrollTo(0, 0);
					setIsLoading(false);
				}}
			/>
		</Modal>
	);
}
