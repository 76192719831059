import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PatientActionsCard from './PatientActionsCard';
import AccountCreationCard from './AccountCreationCard';


import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';

import { loadAllClients } from 'containers/CliniciansAndClients/actions';
//import useIsMobile from 'hooks/isMobile';

import {
    getOQProfile,
} from './actions';
import { generateToast } from 'containers/App/actions';

const MeasureCard = (): JSX.Element => {

    const dispatch = useDispatch();

    const userId = useSelector((state: RootState) =>
        state.get('app').get('auth').get('loggedInUser').get('id')
    );

    const {
        isFetchingProfile,
        hasOQAccount,
        isCreatingEmployee,
        errorMessage,
        successMessage,
    } = useSelector((state: RootState) => state.get('measures').toJS());

    useEffect(() => {
        dispatch(loadAllClients());
        if (!hasOQAccount) { // Don't check for OQ account again if already succeeded
            dispatch(getOQProfile({ userId: userId.toString() }));
        }
    }, []);

    useEffect(() => {
        if (errorMessage) {
            dispatch(generateToast({ type: 'custom-error', text: errorMessage }));
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage) {
            dispatch(generateToast({ type: 'custom-success', text: successMessage }));
        }
    }, [successMessage]);

    return (
        <div className="px-0">
            <div className="d-flex mb-3 shadow-sm">
                <a
                    className="border bg-white p-4 font-13 font-weight-bold text-decoration-none"
                    style={{ color: 'inherit' }}
                    href="https://www.oqanalyst.com/Mind-Therapy-Clinic/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    OQ Analyst Measures
                </a>
            </div>
            <div>
                {isFetchingProfile || isCreatingEmployee ?
                    <div className="w-100 pb-4 bg-white">
                        <SmallLoadingIndicator text="Loading..." color="var(--main-color)" />
                    </div>
                    :
                    hasOQAccount ?
                        <PatientActionsCard />
                        :
                        <AccountCreationCard />
                }
            </div>
        </div>
    );
};

export default MeasureCard;
