import React, { useState } from 'react';

import DeleteButton from 'atoms/DeleteButton';
import { toggleScroll } from 'containers/Dashboard/actions';
import searchIcon from 'svgs/search';

const FilterNavMobile = (props) => {
	const { currentFilters, handleReset, search, kebab, hasTabs, filterList } =
		props;

	const [showSearch, setShowSearch] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [zIndex, setZIndex] = useState(-1);

	const toggleSearch = () => {
		setShowSearch(!showSearch);
	};

	const toggleFilters = () => {
		const { dispatch } = props;
		const zIndexMin = -1;
		const zIndexMax = 500;

		dispatch(toggleScroll());

		setShowFilters(!showFilters);

		if (zIndex === zIndexMin) {
			setZIndex(zIndexMax);
		} else {
			setTimeout(() => {
				setZIndex(zIndexMin);
			}, 300);
		}
	};

	return (
		<div>
			<div
				className="filter-nav-mobile mb-2"
				style={{
					height: showSearch ? 120 : 60,
					transition: '0.3s all ease-in-out',
				}}
			>
				<div
					className="bg-white border"
					style={{
						transition:
							'height 0.3s ease-in-out' /* height: `${42 + Math.ceil(currentFilters.length / 2) * 48}px` */,
					}}
				>
					<div
						className="row mx-0 bg-white"
						style={{ position: 'relative', zIndex: 10 }}
					>
						<div className="bg-white link-color btn-lg font-weight-bold d-flex my-2 justify-content-start font-15">
							Filters
						</div>
						{currentFilters.map((f) => (
							<button
								className="col-auto bg-light border rounded px-3 mr-2 my-2"
								style={{ overflowX: 'hidden', whiteSpace: 'noWrap' }}
								onClick={() => handleReset(f.name)}
								key={f.name}
							>
								{f.label}: {f.option}{' '}
								<span style={{ fontSize: '18px' }}>×</span>
							</button>
						))}
						<button
							className="bg-light border px-2 my-2 mr-2 rounded"
							onClick={toggleFilters}
						>
							<div>+</div>
						</button>
						<div
							className="row mx-0"
							style={{
								position: 'absolute',
								right: 0,
								top: 0,
								height: '100%',
							}}
						>
							{search && (
								<button
									className={kebab ? 'pr-1' : 'pr-3'}
									onClick={toggleSearch}
								>
									{searchIcon}
								</button>
							)}
							<div className="mt-2">{kebab}</div>
						</div>
					</div>
				</div>
				<div
					style={{
						transform: showSearch ? 'translateY(-1px)' : 'translateY(-54px)',
						transition: '0.3s all ease-in-out',
						zIndex: -1,
						height: 60,
						overflowY: 'hidden',
					}}
				>
					{search}
				</div>
			</div>
			<div
				style={{
					position: 'absolute',
					overflow: 'hidden',
					height: `calc(100% - ${hasTabs ? '132px' : '70px'})`,
					width: '100%',
					left: 0,
					bottom: 0,
					zIndex,
				}}
			>
				<div
					className="p-4 bg-white filter-drawer"
					style={{
						transform: showFilters ? 'translateY(0%)' : 'translateY(100%)',
						height: `${window.innerHeight}px`,
					}}
				>
					<DeleteButton onClick={toggleFilters} />
					{filterList.map(
						(filter, i) =>
							filter && (
								<div className="row mx-0" key={`${filter.label}-${i}`}>
									{filter}
								</div>
							),
					)}
					<div className="w-100 text-center mt-4">
						<button
							onClick={toggleFilters}
							className="btn btn-primary primary-btn-color px-3 py-4 font-22 rounded"
						>
							Apply Filters
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FilterNavMobile;
