import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';


interface Props {
    prevProgress: () => void,
    nextProgress: () => () => void,
    isMobile: boolean,
}

export default function KetamineTreatment(props: Props): JSX.Element {
    const { prevProgress, nextProgress, isMobile } = props;

    const handleNext = () => {
        nextProgress()();
    };

    return (
        <div
            className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
        >
            <div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
                <div className="pt-1 pb-4">
                    <h4>Preparation Prior to treatment:</h4>
                    <br />
                    <ul>
                        <li>
                            Patients are expected to abstain from all substance use for a
                            period of 48 hours; including alcohol tobacco, cannabis, illicit
                            substances.
                        </li>
                        <li>
                            In certain instance, a urine toxicology screen may requires
                            prior to treatment, including on the day of treatment
                        </li>
                        <li>
                            <b>No dietary intake for at least 4 hours prior treatment. </b>
                            <br />A small intake of water is permitted. Hold medication that
                            may raise blood pressure - ie. stimulants
                        </li>
                        <li>
                            Continue on anti-hypertension and diabetic medication, with
                            dose of adjustment of insulin based on dietary intake
                            adjustment.
                        </li>
                    </ul>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Standard course experience with treatment:</h4>
                    <br />
                    <p>
                        Preparation: 0-15 minutes
                    </p>
                    <ul>
                        <li>Evaluation of mindset and readiness for treatment</li>
                        <li>
                            Blood pressure screening, with treatment for parameter of
                            lS0/90mrn Hg o Pt with blood pressure above the threshold
                            will need to take medication to control blood pressure on
                            the day of treatment and possibly during the course of
                            treatment. Clonidine is available in oral formulation
                        </li>
                        <li>
                            Confirmation that an after-care/support person is available for
                            pick-up from the office.
                        </li>
                        <li>Completion of depression and related screening forms</li>
                    </ul>
                    <p>
                        Dosing: Onset 15-30 minutes
                    </p>
                    <ul>
                        <li>
                            Oral troche or rapid dissolving - initially dosed between 25mg
                            - 100mg based on body weight, intensity of depression, subjective
                            evaluation of patient capacity to tolerate dissociative state.
                        </li>
                        <li>
                            Oral troche - patients can expect a medicinal tasting bolus of
                            saliva to accumulate in the mouth, to be held for 15 minutes
                            and then swallowed.
                        </li>
                    </ul>
                    <p>
                        Trance state: 30-45 minutes
                    </p>
                    <ul>
                        <li>
                            Generally restful, typically non-verbal, supported by soothing
                            music via headphones and eye shade to minimize light effect.
                            Patient may accept or refuse the music and/or eyeshades at any
                            point in the treatment.
                        </li>
                        <li>
                            Patient will typically experience a heaviness in the physical
                            body, possibly with the loss of sensation, followed by a
                            separation from the usual state of cognitive processing,
                            such that verbal expression may become limited and even absent.
                        </li>
                        <li>
                            Patients are generally rousable to an alert and interactive state,
                            and may be checked in on by verbal cue to determine if there are any
                            concerns. An attending therapist will be present throughout the
                            trance state.
                        </li>
                        <li>
                            There will be no physical contact of the provider unless there is
                            concern for a medical emergency.
                            Agreements with the attending therapist for non-sexual touch (ie.
                            hand, shoulder, foot) in order to support the patient will be
                            discussed prior to dosing and is entirely up to the patient at all
                            times.
                        </li>
                        <li>
                            Some patients may experience unfamiliar states - perhaps
                            heaviness/floating sensation, vertigo like sensation, physical
                            discomfort (nausea), the presentation of distressing psychological
                            material. Your attending therapist is present to support you.
                        </li>
                        <li>
                            During this phase of treatment blood pressure may be checked.
                        </li>
                        <li>
                            Patient also consents to allow for video monitoring of the
                            experience in order to assure safety and mutually mitigate concern
                            for behavior that may be aversive, intrusive or experienced as
                            such by the patient in an altered state of awareness.
                        </li>
                    </ul>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Integration Phase: 30-45 minutes</h4>
                    <span>
                        Opportunity for reflection and discussion - Patient will have the
                        opportunity to be engaged verbally to describe experience and
                        potentially engage in discussion of thoughts, emotional states and
                        physical sensations. Verbal engagement is not mandatory at this
                        point and patients may prefer silence in this period. Verbal
                        expressions may be recorded by attending therapist&apos;s notations,
                        patient&apos;s journaling and/or drawing, or use of patient&apos;s private
                        recording device - ie. audio on the phone.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>Disengagement from treatment</h4>
                    <span>
                        At any point in the treatment course, the patient may disengage
                        from the defined and recommended process - ie. sit up and take off
                        the eye shade. However, patients will not be allowed to leave the
                        office on their own until 90 minutes after dosing of ketamine. The
                        patient may request that the defined after-care support assume
                        custody at any point during the treatment, with full release into
                        care to be determined by the physician administering the dose.
                    </span>
                </div>
                <div className="pt-1 pb-4">
                    <h4>After-care</h4>
                    <span>
                        Patients agree to establish an after-care support person for 4
                        hours following treatment. Patient will leave the clinic in the
                        care of this person. Patient agrees not to drive a motorized
                        vehicle, ride a bicycle, or exercise vigorously for 8 hours
                        following treatment. A light meal is recommended following
                        treatment.
                    </span>
                </div>
            </div>

            <div className="d-flex mt-4 mb-5 justify-content-center">
                <div className="col-6">
                    <PrevButton onClick={prevProgress} isMobile={isMobile} />
                </div>
                <div className="col-6">
                    <NextButton onClick={handleNext} isMobile={isMobile} />
                </div>
            </div>
        </div>
    );
}
