import React from 'react';

import useIsMobile from 'hooks/isMobile.ts';
import CollectJSInput from 'v2/forms/CollectJSInput';
import Input from 'v2/forms/Input';
import Select from 'v2/forms/Select';

import { accountHolderTypes, accountTypes } from './constants';

const accountTypeOptions = accountTypes.map((type) => ({
	label: type,
	value: type,
}));
const accountHolderTypeOptions = accountHolderTypes.map((type) => ({
	label: type,
	value: type,
}));

export function CreditCardForm(props) {
	const { errors = {} } = props;

	return (
		<>
			<h4>Credit Card Information</h4>
			<div className="form-row">
				<div className="col-md-6">
					<Input
						label="CARD HOLDER FIRST NAME*"
						id="userInfo.firstName"
						name="userInfo.firstName"
						placeholder="John"
					/>
				</div>
				<div className="col-md-6">
					<Input
						label="LAST NAME*"
						id="userInfo.lastName"
						name="userInfo.lastName"
						placeholder="Doe"
					/>
				</div>
			</div>
			<div className="form-row mb-3">
				<div className="col-md-6 custom-input">
					<CollectJSInput
						label="Credit Card Number"
						id="ccnumber"
						errors={errors}
					/>
				</div>
				<div className="col-md-3 custom-input">
					<CollectJSInput label="Expiration Date" id="ccexp" errors={errors} />
				</div>
				<div className="col-md-3 custom-input">
					<CollectJSInput label="CVV" id="cvv" errors={errors} />
				</div>
			</div>
		</>
	);
}

export function ACHForm(props) {
	const { errors } = props;

	const isMobile = useIsMobile();

	return (
		<>
			<h4>Bank Account Information</h4>
			<div className="form-row mb-3">
				<div className="col-md-12 custom-input">
					<CollectJSInput
						label="Account Holder Name"
						id="checkname"
						errors={errors}
					/>
				</div>
			</div>
			<div className="form-row">
				<div className="col-md-6">
					<Input
						label="BANK NAME*"
						id="bankDetails.bankName"
						name="bankDetails.bankName"
						placeholder="First Financial"
					/>
				</div>
				<div className={`col-md-3 ${!isMobile && 'pr-0'} pr-1`}>
					<label htmlFor="accountType" className="form-font">
						ACCOUNT TYPE*
					</label>
					<Select
						placeholder="Month"
						id="bankDetails.accountType"
						name="bankDetails.accountType"
						options={accountTypeOptions}
					/>
				</div>
				<div className={`col-md-3 ${!isMobile && 'pl-0'}`}>
					<label htmlFor="accountHolderType" className="form-font">
						ACCOUNT HOLDER TYPE*
					</label>
					<Select
						id="bankDetails.accountHolderType"
						name="bankDetails.accountHolderType"
						options={accountHolderTypeOptions}
					/>
				</div>
			</div>
			<div className="form-row mb-3">
				<div className="col-md-6 custom-input">
					<CollectJSInput
						label="Account Number"
						id="checkaccount"
						errors={errors}
					/>
				</div>
				<div className="col-md-6 custom-input">
					<CollectJSInput
						label="Routing Number"
						id="checkaba"
						errors={errors}
					/>
				</div>
			</div>
		</>
	);
}
