/*
 *
 * EnrollmentRequests constants
 *
 */

export const DEFAULT_ACTION = 'src/EnrollmentRequests/DEFAULT_ACTION';
export const SUBMIT_ENROLLMENT_REQUESTS =
	'src/EnrollmentRequests/SUBMIT_ENROLLMENT_REQUESTS';
export const SUBMIT_ENROLLMENT_REQUESTS_SUCCESS =
	'src/EnrollmentRequests/SUBMIT_ENROLLMENT_REQUESTS_SUCCESS';
export const SUBMIT_ENROLLMENT_REQUESTS_ERROR =
	'src/EnrollmentRequests/SUBMIT_ENROLLMENT_REQUESTS_ERROR';

export const SIGN_DOCUMENT = 'src/EnrollmentRequests/SIGN_DOCUMENT';
export const SIGN_DOCUMENT_SUCCESS =
	'src/EnrollmentRequests/SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_ERROR = 'src/EnrollmentRequests/SIGN_DOCUMENT_ERROR';

export const CLEAR_URL = 'src/EnrollmentRequests/CLEAR_URL';
