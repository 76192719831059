import history from 'utils/history';

import { startCase } from 'lodash';

import moment from 'moment';

import React from 'react';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';

const FeedbackCard = (props) => {
	const { feedback, onClick } = props;
	const { sender, completed } = feedback;

	const submittedBy = {
		profileUrl: sender.profileUrl,
		heading: sender.name || 'Anonymous',
	};

	return (
		<div className="card-container request-card bg-white border-bottom p-3">
			<div
				className="card-title font-weight-bold mb-2 text-center"
				style={{ fontSize: '18px' }}
			>
				{startCase(feedback.type)} Feedback
			</div>
			<div className="row mx-0 mb-2 justify-content-between">
				{submittedBy && (
					<div className="col-6">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							Requested By
						</div>
						<div
							className="hand"
							onClick={() => history.push(`patient/${submittedBy.id}/profile`)}
						>
							<ImageDetailsCell
								src={submittedBy.profileUrl}
								heading={submittedBy.heading}
							/>
						</div>
					</div>
				)}
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Status
					</div>
					<div className="font-13">
						{completed ? 'Completed' : 'Requires Action'}
					</div>
				</div>
			</div>
			<div className="row mx-0 justify-content-between">
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						View Feedback
					</div>
					<div className="hand link-color" onClick={() => onClick(feedback)}>
						View
					</div>
				</div>
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Submitted On
					</div>
					<div className="font-13">
						{feedback.createdAt
							? moment(feedback.createdAt).format('YYYY-MM-DD')
							: '-'}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeedbackCard;
