import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextInput from 'atoms/TextInput';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <MilitaryHistory />, a functional component for Adult Intake Evaluation
 */
const MilitaryHistory = (props) => {
	const {
		handleChange,
		servedInMilitary,
		militaryBranch,
		dischargeReceived,
		isMobile,
	} = props;

	return (
		<div className="card d-flex flex-column p-4">
			<h3 className="mb-5">Military</h3>

			<div className="mb-2 row">
				<label
					htmlFor="servedInMilitary"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					HAVE YOU EVER SERVED IN THE MILITARY?
				</label>
				<SelectButtonGroup
					id="servedInMilitary"
					value={servedInMilitary}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-2' : 'col-4'}`}
				/>
			</div>
			{servedInMilitary === 'yes' && (
				<TextInput
					className="mb-4"
					label="IF YES, WHAT BRANCH OF THE MILITARY:"
					id="militaryBranch"
					value={militaryBranch}
					handleInput={handleChange}
				/>
			)}
			{servedInMilitary === 'yes' && (
				<TextInput
					className="mb-4"
					label="WHAT TYPE OF DISCHARGE DID YOU RECEIVE?"
					id="dischargeReceived"
					value={dischargeReceived}
					handleInput={handleChange}
				/>
			)}
		</div>
	);
};

MilitaryHistory.propTypes = {
	servedInMilitary: PropTypes.string.isRequired,
	militaryBranch: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	dischargeReceived: PropTypes.func.isRequired,
};

export default MilitaryHistory;
