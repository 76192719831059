import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DeleteButton from 'atoms/DeleteButton';
import useIsMobile from 'hooks/isMobile.ts';

import './index.css';

const CustomInput = (props) => (
	<button
		className="btn btn-white border col-12 "
		onClick={props.onClick}
		type="button"
	>
		<div className="d-flex justify-content-start align-self-center">
			{props.value ? (
				props.value
			) : (
				<span className="placeholder-text">{props.placeholder}</span>
			)}
		</div>
	</button>
);

CustomInput.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.any,
	placeholder: PropTypes.any,
};

/**
 *
 * <CustomDatePicker />, a functional component for DatePicker
 */
const CustomDatePicker = (props) => {
	let {
		label,
		labelClass,
		handleDateChange,
		className,
		selected,
		id,
		maxDate,
		minDate,
		disabled,
		popperPlacement = 'bottom-start',
		popperModifiers,
		highlightedDates = [],
		options = {},
		customClass,
		placeholder = 'MM/DD/YYYY',
		isClearable,
		// placeholderText,
	} = props;

	if (typeof selected === 'string') {
		if (selected.length > 0) {
			selected = moment(selected).toDate();
		}
	}
	if (typeof minDate === 'string') {
		if (minDate.length > 0) {
			minDate = moment(minDate).toDate();
		}
	}
	if (typeof maxDate === 'string') {
		if (maxDate.length > 0) {
			maxDate = moment(maxDate).toDate();
		}
	}

	const modifiedClass = `${className}`;

	const isMobile = useIsMobile();

	// Todo! Add better highlighted dates option
	if (!isMobile) {
		if (minDate) highlightedDates.push({ 'minDate-highlight': [minDate] });
		if (maxDate) highlightedDates.push({ 'maxDate-highlight': [maxDate] });
	}

	const font = `font-${options.fontSize || 17}`;
	const dateClass = `${customClass || `date-border w-100 ${font}`} ${
		disabled ? 'disabled' : ''
	}`;

	return (
		<div className={modifiedClass} style={{ position: 'relative' }}>
			{label && (
				<label htmlFor={id} className={labelClass || 'form-font'}>
					{label}
				</label>
			)}
			{selected && isClearable && (
				<div
					style={{
						position: 'absolute',
						top: '50%',
						right: 8,
						zIndex: 10,
						transform: 'translateY(-50%)',
					}}
				>
					<DeleteButton
						styles={{
							container: {
								background: 'var(--main-color)',
								width: 16,
								height: 16,
								borderRadius: 8,
								position: 'relative',
							},
							content: {
								color: 'white',
								fontSize: 14,
								position: 'absolute',
								top: 7,
								left: '50%',
								transform: 'translate(-50%, -50%)',
								opacity: 1,
								fontWeight: 300,
							},
						}}
						onClick={() => handleDateChange()}
					/>
				</div>
			)}
			{isMobile ? (
				<DatePicker
					// customInput={<CustomInput />}
					className={dateClass}
					selected={selected === 'Invalid date' ? '' : selected}
					onChange={handleDateChange}
					maxDate={maxDate}
					minDate={minDate}
					dateFormat="MM/dd/yyyy"
					showYearDropdown
					scrollableYearDropdown
					yearDropdownItemNumber={50}
					disabledKeyboardNavigation
					popperPlacement={popperPlacement}
					popperModifiers={popperModifiers}
					placeholderText={placeholder}
					disabled={disabled}
					// highlightDates={highlightedDates}
					// withPortal
					// inline
				/>
			) : (
				<DatePicker
					// customInput={<CustomInput />}
					className={dateClass}
					selected={selected === 'Invalid date' ? '' : selected}
					onChange={handleDateChange}
					maxDate={maxDate}
					minDate={minDate}
					dateFormat="MM/dd/yyyy"
					showYearDropdown
					scrollableYearDropdown
					yearDropdownItemNumber={50}
					disabledKeyboardNavigation
					popperPlacement={popperPlacement}
					popperModifiers={popperModifiers}
					placeholderText={placeholder}
					disabled={disabled}
					highlightDates={highlightedDates}
					// withPortal
					// inline
				/>
			)}
		</div>
	);
};

CustomDatePicker.propTypes = {
	handleDateChange: PropTypes.func.isRequired,
	label: PropTypes.any,
	selected: PropTypes.any,
	className: PropTypes.string,
	id: PropTypes.string,
	maxDate: PropTypes.object,
	minDate: PropTypes.object,
	disabled: PropTypes.bool,
	// placeholderText: PropTypes.string,
};

export default CustomDatePicker;
