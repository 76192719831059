import React from 'react';

import './index.css';

const ToggleSwitch = (props) => {
	const { onClick, selected, className = '' } = props;

	return (
		<div className={`text-center ${className}`}>
			<label className="switch-small">
				<input type="checkbox" onChange={onClick} checked={selected} />
				<div className="slider-small round" />
			</label>
		</div>
	);
};

export default ToggleSwitch;
