const filters = [
	{
		type: 'Single Select',
		name: 'clientType',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'CLIENT TYPE',
		defaultSelect: 'Any',
	},
	{
		type: 'Single Select',
		name: 'referrer',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'REFERRER',
		defaultSelect: 'Any',
	},
	{
		type: 'Single Select',
		name: 'assignedClinician',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'ASSIGNED CLINICIAN',
		defaultSelect: 'All Clinicians',
	},
	{
		type: 'Single Select',
		name: 'primaryPhysician',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'PRIMARY PHYSICIAN / THERAPIST',
		defaultSelect: 'All Clinicians',
	},
	{
		type: 'Single Select',
		name: 'location',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'LOCATION',
		defaultSelect: 'Any',
	},
	{
		type: 'Single Select',
		name: 'symptoms',
		selectOptions: ['Option1', 'Option2'],
		selectLabel: 'SYMPTOMS',
		defaultSelect: 'Any',
	},
];

export default filters;
