import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchProgramsPrivate } from 'utils/api';

import {
	loadProgramsPrivateError,
	loadProgramsPrivateSuccess,
} from './actions';
import { LOAD_PROGRAMS_PRIVATE } from './constants';

export default function* browseGroupsPrivateSaga() {
	yield takeLatest(LOAD_PROGRAMS_PRIVATE, getProgramsPrivate);
}

export function* getProgramsPrivate() {
	try {
		const response = yield call(fetchProgramsPrivate);
		if (Array.isArray(response)) {
			yield put(loadProgramsPrivateSuccess(response));
		} else {
			yield put(loadProgramsPrivateError('Could not load programs'));
		}
	} catch (err) {
		yield put(loadProgramsPrivateError(err));
	}
}
