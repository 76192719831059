/*
 *
 * ToDos reducer
 *
 */
import { fromJS } from 'immutable';

import {
	ASSIGN_CLINICIAN,
	ASSIGN_CLINICIAN_ERROR,
	ASSIGN_CLINICIAN_SUCCESS,
	DEFAULT_ACTION,
	FETCH_PHARMACIES,
	FETCH_PHARMACIES_ERROR,
	FETCH_PHARMACIES_SUCCESS,
	LOAD_NEW_INTAKES,
	LOAD_NEW_INTAKES_ERROR,
	LOAD_NEW_INTAKES_SUCCESS,
	LOAD_OTHER_REQ,
	LOAD_OTHER_REQ_ERROR,
	LOAD_OTHER_REQ_SUCCESS,
	LOAD_RX_REQUESTS,
	LOAD_RX_REQUESTS_ERROR,
	LOAD_RX_REQUESTS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	otherRequests: [],
	newIntakes: [],
	rxRequests: [],
	isFetchingOtherReq: false,
	isFetchingNewIntakes: false,
	isFetchingRXReq: false,
	assignClinicianStatus: true,
	changePharmacyStatus: true,
	pharmacies: [],
});

function toDosReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;

		case ASSIGN_CLINICIAN:
			return state.set('assignClinicianStatus', false);
		case ASSIGN_CLINICIAN_SUCCESS:
			return state.set('assignClinicianStatus', true);
		case ASSIGN_CLINICIAN_ERROR:
			return state.set('assignClinicianStatus', false);

		case FETCH_PHARMACIES:
			return state.set('pharmacies', []);
		case FETCH_PHARMACIES_SUCCESS:
			return state.set('pharmacies', action.data);
		case FETCH_PHARMACIES_ERROR:
			return state.set('error', action.err);

		case LOAD_OTHER_REQ:
			return state.merge({
				otherRequests: [],
				isFetchingOtherReq: true,
			});
		case LOAD_OTHER_REQ_SUCCESS:
			return state.merge({
				otherRequests: action.otherRequests.data,
				isFetchingOtherReq: false,
			});
		case LOAD_OTHER_REQ_ERROR:
			return state.merge({
				error: action.error,
				isFetchingOtherReq: false,
			});

		case LOAD_RX_REQUESTS:
			return state.merge({
				rxRequests: [],
				isFetchingRXReq: true,
			});
		case LOAD_RX_REQUESTS_SUCCESS:
			return state.merge({
				rxRequests: action.rxRequests.data,
				isFetchingRXReq: false,
			});
		case LOAD_RX_REQUESTS_ERROR:
			return state.merge({
				error: action.error,
				isFetchingRXReq: false,
			});

		case LOAD_NEW_INTAKES:
			return state.merge({
				newIntakes: [],
				isFetchingNewIntakes: true,
			});
		case LOAD_NEW_INTAKES_SUCCESS:
			return state.merge({
				newIntakes: action.newIntakes.data,
				isFetchingNewIntakes: false,
			});
		case LOAD_NEW_INTAKES_ERROR:
			return state.merge({
				error: action.error,
				isFetchingNewIntakes: false,
			});

		default:
			return state;
	}
}

export default toDosReducer;
