
export const CREATE_OQ_CLIENT = 'src/Measures/CREATE_OQ_CLIENT';
export const CREATE_OQ_CLIENT_SUCCESS = 'src/Measures/CREATE_OQ_CLIENT_SUCCESS';
export const CREATE_OQ_CLIENT_ERROR = 'src/Measures/CREATE_OQ_CLIENT_ERROR';

export const CREATE_OQ_EMPLOYEE = 'src/Measures/CREATE_OQ_EMPLOYEE';
export const CREATE_OQ_EMPLOYEE_SUCCESS = 'src/Measures/CREATE_OQ_EMPLOYEE_SUCCESS';
export const CREATE_OQ_EMPLOYEE_ERROR = 'src/Measures/CREATE_OQ_EMPLOYEE_ERROR';

export const GET_OQ_PROFILE = 'src/Measures/GET_OQ_PROFILE';
export const GET_OQ_PROFILE_SUCCESS = 'src/Measures/GET_OQ_PROFILE_SUCCESS';
export const GET_OQ_PROFILE_ERROR = 'src/Measures/GET_OQ_PROFILE_ERROR';

export const GET_OQ_CLIENT = 'src/Measures/GET_OQ_CLIENT';
export const GET_OQ_CLIENT_SUCCESS = 'src/Measures/GET_OQ_CLIENT_SUCCESS';
export const GET_OQ_CLIENT_ERROR = 'src/Measures/GET_OQ_CLIENT_ERROR';

export const GET_OQ_ADMINISTRATION_URL = 'src/Measures/GET_OQ_ADMINISTRATION_URL';
export const GET_OQ_ADMINISTRATION_URL_SUCCESS = 'src/Measures/GET_OQ_ADMINISTRATION_URL_SUCCESS';
export const GET_OQ_ADMINISTRATION_URL_ERROR = 'src/Measures/GET_OQ_ADMINISTRATION_URL_ERROR';

export const SEND_OQ_ADMINISTRATION_SMS = 'src/Measures/SEND_OQ_ADMINISTRATION_SMS';
export const SEND_OQ_ADMINISTRATION_SMS_SUCCESS = 'src/Measures/SEND_OQ_ADMINISTRATION_SMS_SUCCESS';
export const SEND_OQ_ADMINISTRATION_SMS_ERROR = 'src/Measures/SEND_OQ_ADMINISTRATION_SMS_ERROR';
