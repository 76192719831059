// import components

/**
 *
 * AdultOtherHistory
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import { STEPS } from 'pages/intake/constants';

import AcademicHistory from './AcademicHistory';
import ChildhoodHistory from './ChildhoodHistory';
import LegalHistory from './LegalHistory';
import MilitaryHistory from './MilitaryHistory';

/* eslint-disable react/prefer-stateless-function */
const AdultOtherHistory = (props) => {
	const {
		prevProgress,
		nextProgress,
		data,
		handleChange,
		handleCheck,
		isMobile,
		isUploading,
	} = props;
	const {
		childhoodHistory,
		spiritualFactors,
		supportSystem,
		supporters,
		vocationalHistory,
		servedInMilitary,
		militaryBranch,
		dischargeReceived,
		legalProblem,
		legalProblemDetails,
		onProbation,
		probationRequirements,
	} = data;

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Intake Questionnaire
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.AdultOtherHistory)}>
				<ChildhoodHistory
					childhoodHistory={childhoodHistory}
					spiritualFactors={spiritualFactors}
					supportSystem={supportSystem}
					supporters={supporters}
					handleChange={handleChange(STEPS.AdultOtherHistory)}
					handleSupporterCheck={handleCheck}
					isMobile={isMobile}
				/>

				<AcademicHistory
					vocationalHistory={vocationalHistory}
					handleChange={handleChange(STEPS.AdultOtherHistory)}
					isMobile={isMobile}
				/>

				<MilitaryHistory
					servedInMilitary={servedInMilitary}
					militaryBranch={militaryBranch}
					dischargeReceived={dischargeReceived}
					handleChange={handleChange(STEPS.AdultOtherHistory)}
					isMobile={isMobile}
				/>

				<LegalHistory
					legalProblem={legalProblem}
					legalProblemDetails={legalProblemDetails}
					onProbation={onProbation}
					probationRequirements={probationRequirements}
					handleChange={handleChange(STEPS.AdultOtherHistory)}
					isMobile={isMobile}
				/>

				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					busy={isUploading}
					isLastPage
				/>
			</Form>
		</div>
	);
};

AdultOtherHistory.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
	modal: PropTypes.bool.isRequired,
	siteUrl: PropTypes.string.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default AdultOtherHistory;
