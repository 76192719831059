/*   eslint no-case-declarations: 0 */

/*   eslint guard-for-in: 0 */

/*   eslint no-restricted-syntax: 0 */

/*
 *
 * PatientIntake reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CHECK_ONBOARDING,
	DOC_CREATE,
	DOC_CREATE_SUCCESS,
	DOC_SIGN_SUCCESS,
	ERROR,
	FETCH_DATA,
	PATIENT_INTAKE,
	SET_PREV_PATH,
	STEPS,
	SUCCESS,
} from './constants';

export const initialState = fromJS({
	id: '',
	doctor: {},
	therapist: {},
	pharmacy: {},
	insurance: {},
	questionnaire: {},
	cardDetails: {},
	bankDetails: {},
	guarantor: {},
	registrationType: '',
	path: '',
	url: '',
	docInfo: '',
	checks: {},
	intakeProgress: 0,
	registrationProgress: 0,
	maxIntakeProgress: 0,
	createDocSuccess: false,
	creatingDoc: false,
});

function updatePatientIntake(action, initial) {
	let state = initial;
	const keys = Object.getOwnPropertyNames(action.data);
	for (const i in keys) {
		if (keys[i] === STEPS.Questionnaire) {
			let ques = fromJS(state.get(keys[i]));
			ques = ques.mergeDeep(action.data[keys[i]]);
			// eslint-disable-next-line no-param-reassign
			action.data[keys[i]] = ques.toJS();
		}
		state = state.set(keys[i], action.data[keys[i]]);
	}
	return state;
}

function updateStateFromSubmittedFields(action, initial) {
	let state = initial;

	const data = {};
	if (action.data.doctor) {
		data.doctor = action.data.doctor;
	}
	if (action.data.therapist) {
		data.therapist = action.data.therapist;
	}
	if (action.data.pharmacy) {
		data.pharmacy = action.data.pharmacy;
	}
	if (action.data.patientDetails) {
		if (action.data.patientDetails.registrationType) {
			data.registrationType = action.data.patientDetails.registrationType;
		}
		if (action.data.patientDetails.insurance) {
			data.insurance = action.data.patientDetails.insurance;
		}
		if (action.data.patientDetails.questionnaire) {
			data.questionnaire = action.data.patientDetails.questionnaire;
		}
		if (action.data.patientDetails.guarantor) {
			data.guarantor = action.data.patientDetails.guarantor;
		}
	}
	if (action.data.checks) {
		data.checks = action.data.checks;
	}
	if (action.data.intakeProgress) {
		data.intakeProgress = action.data.intakeProgress;
	}
	if (action.data.registrationProgress) {
		data.registrationProgress = action.data.registrationProgress;
	}
	if (action.data.maxIntakeProgress) {
		data.maxIntakeProgress = action.data.maxIntakeProgress;
	}

	if (action.data.address) {
		data.address = action.data.address;
	}

	if (action.data.vaultId) {
		data.vaultId = action.data.vaultId;
	}

	const keys = Object.getOwnPropertyNames(data);
	for (const i in keys) {
		state = state.set(keys[i], data[keys[i]]);
	}
	return state;
}

function patientIntakeReducer(state = initialState, action) {
	switch (action.type) {
		case PATIENT_INTAKE:
			return updatePatientIntake(action, state);
		case SUCCESS:
			return updateStateFromSubmittedFields(action, state);
		case FETCH_DATA:
			return updatePatientIntake(action, state);
		case ERROR:
			return state.merge({
				createDocSuccess: false,
				creatingDoc: false,
			});
		case DOC_SIGN_SUCCESS:
			return updatePatientIntake(action, state);
		case DOC_CREATE:
			return state.merge({
				createDocSuccess: false,
				creatingDoc: true,
			});
		case DOC_CREATE_SUCCESS:
			let newState = updatePatientIntake(action, state);
			return newState.merge({
				createDocSuccess: true,
				creatingDoc: false,
			});
		case CHECK_ONBOARDING:
			return state.merge({
				url: '',
				docInfo: '',
			});
		case SET_PREV_PATH:
			return state.set('path', action.data);
		default:
			return state;
	}
}

export default patientIntakeReducer;
