/*
 *
 * PatientRegister constants
 *
 */

export const PATIENT_REGISTRATION = 'src/PatientRegister/PATIENT_REGISTRATION';
export const SUCCESS = 'src/PatientRegister/SUCCESS';
export const ERROR = 'src/PatientRegister/ERROR';
export const FETCH_DATA = 'src/PatientRegister/FETCH_DATA';

export const registrationSteps = 4;
