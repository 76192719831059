import moment from 'moment';

import React, { useEffect } from 'react';
import { PlusCircle, XCircle } from 'react-bootstrap-icons';

import ActionButton from 'atoms/ActionButton';
import CustomDatePicker from 'atoms/CustomDatePicker';
import SelectAutoComplete from 'atoms/SelectAutoComplete';
import {
	generateAllOptions,
	generateSelectOption,
} from 'components/PatientProfileEditModal/selectOptions';

const inputHeight = window.innerWidth < 1200 ? 46 : 49;

const LevelOfCare = (props) => {
	const { dispatch, data = [] } = props;

	useEffect(() => {
		if (data.length === 0) {
			addRow();
		}
	}, [data]);

	function addRow(index = 0) {
		const newData = [...data];
		let startDate;
		if (newData[index]?.endDate) {
			startDate = moment(newData[index].endDate).add(1, 'd').utc().toString();
		}
		newData.splice(index + 1, 0, {
			type: '',
			id: Math.random(),
			startDate,
		});
		dispatch({ value: newData }, 'UPDATE');
	}

	function deleteRow(index) {
		dispatch(index, 'REMOVE_LOC');
	}

	function handleSelect(index) {
		return (option) => {
			const newData = [...data];
			newData[index] = {
				...newData[index],
				type: option.value,
			};
			dispatch({ value: newData }, 'UPDATE');
		};
	}

	function handleDateChange(type, index) {
		return (date) => {
			const newData = [...data];
			newData[index] = {
				...newData[index],
				[type]: moment(date).utc().toString(),
			};
			dispatch({ value: newData }, 'UPDATE');
		};
	}

	function determineMinStartDate(index) {
		if (index === 0 || !data[index - 1]?.endDate) {
			return;
		}

		return moment(data[index - 1].endDate)
			.add(1, 'd')
			.toString();
	}

	function determineMaxEndDate(index) {
		if (index === data.length - 1 || !data[index + 1]?.startDate) {
			return;
		}

		return moment(data[index + 1].startDate)
			.subtract(1, 'd')
			.toString();
	}

	return (
		<div>
			{data.map((loc, i) => (
				<div className="row" key={`level-of-care-${loc.id}`}>
					<div className="col">
						<label className="form-font">CLIENT TYPE*</label>
						<SelectAutoComplete
							value={generateSelectOption('clientType', loc.type)}
							name="type"
							handleChange={handleSelect(i)}
							options={generateAllOptions('clientType')}
							controlStyles={{ height: inputHeight }}
							placeholder="Select client type"
						/>
					</div>
					<div className="col-3">
						<label className="form-font">START DATE*</label>
						<CustomDatePicker
							handleDateChange={handleDateChange('startDate', i)}
							selected={loc.startDate}
							minDate={determineMinStartDate(i)}
							maxDate={
								loc.endDate
									? moment(loc.endDate).subtract(1, 'd').toString()
									: null
							}
						/>
					</div>
					<div className="col-3">
						<label className="form-font">END DATE</label>
						<CustomDatePicker
							handleDateChange={handleDateChange('endDate', i)}
							selected={loc.endDate}
							minDate={
								loc.startDate
									? moment(loc.startDate).add(1, 'd').toString()
									: null
							}
							maxDate={determineMaxEndDate(i)}
						/>
					</div>
					<div className="col-auto pl-0">
						<label className="form-font" />
						<div className="d-flex mt-1">
							<ActionButton
								image={<PlusCircle strokeWidth={0.5} />}
								alt="Add Row"
								onClick={() => addRow(i)}
							/>
							<ActionButton
								image={<XCircle strokeWidth={0.5} />}
								alt="Delete Row"
								hoverColor="var(--main-alert)"
								onClick={() => deleteRow(i)}
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default LevelOfCare;
