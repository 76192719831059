/*
 *
 * Geo reducer
 *
 */
import { fromJS } from 'immutable';

import {
	ERROR,
	FETCH_CITIES,
	FETCH_STATES,
	FETCH_ZIPCODES,
	SET_ZIPCODE,
	SUCCESS,
	UPDATE_CITIES,
	UPDATE_STATES,
	UPDATE_ZIPCODES,
} from './constants';

export const initialState = fromJS({
	states: [],
	data: {},
});

function geoReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_STATES:
			return state;
		case FETCH_CITIES:
			return state;
		case FETCH_ZIPCODES:
			return state.setIn(
				['data', `${action.keyName}`, 'city'],
				action.cityName,
			);
		case UPDATE_STATES:
			return state.mergeDeep({ states: action.states });
		case UPDATE_CITIES:
			return state
				.setIn(['data', `${action.keyName}`, 'cities'], action.cities)
				.setIn(['data', `${action.keyName}`, 'state'], action.stateName);
		case UPDATE_ZIPCODES:
			return state.setIn(
				['data', `${action.keyName}`, 'zipcodes'],
				action.zipcodes,
			);
		case SET_ZIPCODE:
			return state.setIn(
				['data', `${action.keyName}`, 'zipcode'],
				action.zipcode,
			);
		case SUCCESS:
			return state;
		case ERROR:
			return state;
		default:
			return state;
	}
}

export default geoReducer;
