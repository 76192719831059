import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';

import TabMenu from 'atoms/TabMenu';
import CommonHeader from 'components/CommonHeader';
import FormEditor from 'components/FormEditor';
import Redirect from 'components/Redirect';
import AdminConsole from 'containers/AdminConsole';
import { getPermissions, setViewMode } from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectIsMobile,
	makeSelectLocation,
} from 'containers/App/selectors';
import Attendance from 'containers/Attendance';
import Clinician from 'containers/ClinicianToDos';
import CliniciansAndClients from 'containers/CliniciansAndClients';
import Forms from 'containers/Forms';
import GroupDetail from 'containers/GroupDetail';
import PatientProfile from 'containers/PatientProfile';
import Reports from 'containers/Reports';
import Requests from 'containers/Requests';
import Therapies from 'containers/Therapies';
import ToDos from 'containers/ToDos';
import { logout, toggleSettings } from 'containers/UserDropDown/actions';
import ModalManager from 'modals/ModalManager';
import BrowseGroups from 'pages/browsegroups/BrowseGroups';
import ModalManagerNoRoute from 'v2/modals/ManagerNoRoute';

import makeSelectDashboard from './selectors';

export class Dashboard extends React.PureComponent {
	state = {
		contentHeight: 0,
		scrolling: false,
	};

	setContentHeight = () => {
		const { isMobile } = this.props;

		const footerHeight = isMobile ? 68 : 0;
		const headerHeight = isMobile ? 60 : 100;

		const newHeight = window.innerHeight - footerHeight - headerHeight - 2;

		if (newHeight !== this.state.content) {
			this.setState({
				contentHeight: newHeight,
			});
		}
	};

	componentDidMount = () => {
		this.setContentHeight();
		if (this.props.auth.isLoggedIn) {
			this.props.getPermissions();
		}
	};

	componentDidUpdate = (nextProps) => {
		if (this.props.isMobile !== nextProps.isMobile) {
			this.setContentHeight();
		}
	};

	render() {
		if (!this.props.auth) return <div />;

		const { contentHeight, scrolling } = this.state;
		const {
			auth: { loggedInUser, viewMode },
			auth: {
				loggedInUser: { isVerified, roleName },
			},
			isMobile,
			logout,
			toggleSettings,
			dispatch,
		} = this.props;

		const notPatient = !(roleName === 'patient');
		const notVerified = notPatient && isVerified;
		const notVerifiedMessage = !isVerified && notPatient && (
			<div className="v-h-center-outer">
				<div className="v-h-center-container">
					Your account is yet to be verified by an admin.
				</div>
			</div>
		);

		const footerHeight = isMobile ? 68 : 0;
		const headerHeight = isMobile ? 60 : 100;
		const contentStyle = {
			overflowX: 'hidden',
			minHeight: `${contentHeight}px`,
		};

		return (
			<div>
				<ModalManagerNoRoute />
				<div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
					<CommonHeader
						routeProps={this.props}
						user={loggedInUser}
						type={roleName}
						isMobile={isMobile}
						logout={logout}
						toggleSettings={toggleSettings}
						height={headerHeight}
					/>
				</div>
				<div className="content w-100">
					<div className={`${scrolling && 'scroll'}`} style={contentStyle}>
						{/* Admin Routes */}
						{notVerifiedMessage}
						{roleName === 'admin' && notVerified && (
							<div>
								<Switch>
									<Route
										exact
										path="/admin/dashboard/todos"
										render={(routeProps) => (
											<ToDos
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										path="/admin/dashboard/requests"
										render={(routeProps) => (
											<Requests
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										exact
										path="/admin/dashboard/cliniciansandclients"
										render={(routeProps) => (
											<CliniciansAndClients
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										path="/admin/dashboard/profile/:id"
										render={(routeProps) => (
											<PatientProfile
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										path="/admin/dashboard/groups"
										render={(routeProps) => (
											<Therapies
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										exact
										path="/admin/dashboard/forms"
										render={(routeProps) => (
											<Forms
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										exact
										path="/admin/dashboard/forms/editor"
										render={(routeProps) => (
											<FormEditor routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										path="/admin/dashboard/reports"
										render={(routeProps) => <Reports routeProps={routeProps} />}
									/>
									<Route
										path="/admin/dashboard/clinician"
										render={(routeProps) => (
											<Clinician routeProps={routeProps} />
										)}
									/>
									<Route
										path="/admin/dashboard/groupDetails/:id"
										render={(routeProps) => (
											<GroupDetail
												routeProps={routeProps}
												type={viewMode || roleName}
											/>
										)}
									/>
									<Route
										path="/admin/dashboard/console"
										component={AdminConsole}
									/>
									<Route
										exact
										path="/admin/dashboard"
										component={() => (
											<Redirect redirectPath="/admin/dashboard/todos" />
										)}
									/>
									<Route
										component={() => (
											<Redirect redirectPath="/admin/dashboard/todos" />
										)}
									/>
								</Switch>
							</div>
						)}
						{/* Clinician Routes */}
						{roleName === 'clinician' && notVerified && (
							<div>
								<Switch>
									<Route
										exact
										path="/clinician/dashboard/todos"
										render={(routeProps) => (
											<ToDos routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard/attendance"
										render={(routeProps) => (
											<Attendance routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard/groups"
										render={(routeProps) => (
											<Therapies routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard/myclients"
										render={(routeProps) => (
											<CliniciansAndClients
												routeProps={routeProps}
												type={roleName}
											/>
										)}
									/>
									<Route
										path="/clinician/dashboard/profile/:id"
										render={(routeProps) => (
											<PatientProfile routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard/forms"
										render={(routeProps) => (
											<Forms routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard/forms/editor"
										render={(routeProps) => (
											<FormEditor routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										path="/clinician/dashboard/groupDetails/:id"
										render={(routeProps) => (
											<GroupDetail routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/clinician/dashboard"
										component={() => (
											<Redirect redirectPath="/clinician/dashboard/todos" />
										)}
									/>
									<Route
										component={() => (
											<Redirect redirectPath="/clinician/dashboard/todos" />
										)}
									/>
								</Switch>
							</div>
						)}
						{/* Patient Routes */}
						{roleName === 'patient' && (
							<div>
								<Switch>
									{/* <Route
                    exact
                    path="/patient/dashboard/todos"
                    render={routeProps => (
                        <ToDos routeProps={routeProps} type={roleName} />
                    )}
                  /> */}

									<Route
										path="/patient/dashboard/modal/:modalName"
										component={ModalManager}
									/>
									<Route
										exact
										path="/patient/dashboard/mygroups"
										render={(routeProps) => (
											<Therapies routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/patient/dashboard/requests"
										render={(routeProps) => (
											<Requests routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/patient/dashboard/forms"
										render={(routeProps) => (
											<Forms routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										exact
										path="/patient/dashboard/browsegroups"
										render={(routeProps) => (
											<BrowseGroups routeProps={routeProps} />
										)}
									/>
									<Route
										path="/patient/dashboard/groupDetails/:id"
										render={(routeProps) => (
											<GroupDetail routeProps={routeProps} type={roleName} />
										)}
									/>
									<Route
										path="/patient/dashboard/profile/:id"
										render={(routeProps) => {
											const {
												location: { pathname },
											} = routeProps;
											const locSplit = pathname.split('/');
											const targetId = parseInt(locSplit[locSplit.length - 2]);
											if (targetId === loggedInUser.id) {
												return (
													<PatientProfile
														routeProps={routeProps}
														type={roleName}
													/>
												);
											}

											return (
												<Redirect redirectPath="/patient/dashboard/mygroups" />
											);
										}}
									/>
									<Route
										exact
										path="/patient/dashboard"
										component={() => (
											<Redirect redirectPath="/patient/dashboard/mygroups" />
										)}
									/>
								</Switch>
							</div>
						)}
					</div>
				</div>
				{isMobile && (
					<div
						className="w-100 bg-white shadow"
						style={{
							position: 'sticky',
							bottom: 0,
							height: `${footerHeight}px`,
						}}
					>
						<TabMenu
							routeProps={this.props}
							user={loggedInUser}
							isMobile={isMobile}
							logout={logout}
							toggleSettings={toggleSettings}
							dispatch={dispatch}
						/>
					</div>
				)}
			</div>
		);
	}
}

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
	dashboard: makeSelectDashboard(),
	location: makeSelectLocation(),
	auth: makeSelectAuth(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		logout: () => dispatch(logout()),
		toggleSettings: () => dispatch(toggleSettings()),
		setViewMode: (mode) => dispatch(setViewMode(mode)),
		getPermissions: () => dispatch(getPermissions()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
