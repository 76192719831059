import { Form } from 'formik';

import React from 'react';

export default function _Form({ children, ...rest }) {
	return (
		<Form {...rest}>
			{children}
			<input
				style={{
					opacity: 0,
					height: 0,
					position: 'absolute',
					top: 0,
					left: 0,
					pointerEvents: 'none',
				}}
				type="submit"
			/>
		</Form>
	);
}
