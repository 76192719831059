import PropTypes from 'prop-types';

import React, { useState } from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import styled from 'styled-components';

import kebabImage from 'svgs/kebab';

/**
 *
 * <KebabMenuCell />, a functional component to display image with heading and subheading
 */

const Kebab = styled.div`
	path {
		fill: ${(props) => (props.isActive ? 'var(--main-color)' : 'black')};
	}
`;
const KebabMenuCell = (props) => {
	const { dropdownItems = [], noMargin, direction = 'down' } = props;
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = (callback, args = []) => {
		callback(...args);
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={`d-flex justify-content-end ${!noMargin && 'mr-4'} mt-1`}>
			<ButtonDropdown
				nav
				inNavbar
				direction={direction}
				isOpen={isOpen}
				toggle={toggle}
			>
				<DropdownToggle nav>
					<Kebab
						className={`d-flex justify-content-end ${!noMargin && 'mr-4'}`}
						isActive={isOpen}
					>
						{kebabImage}
					</Kebab>
				</DropdownToggle>
				<DropdownMenu right flip={false}>
					{dropdownItems.map(
						(item) =>
							item.enabled && (
								<DropdownItem
									key={item.id}
									onClick={() => {
										handleClick(item.callback, item.args);
									}}
								>
									{item.name}
								</DropdownItem>
							),
					)}
				</DropdownMenu>
			</ButtonDropdown>
		</div>
	);
};

KebabMenuCell.propTypes = {
	dropdownItems: PropTypes.array,
};

export default KebabMenuCell;
