/**
 *
 * GroupStandards
 *
 */
import PropTypes from 'prop-types';

import React from 'react';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
class GroupStandards extends React.Component {
	formComplete = () => {
		const { groupStandardsChecked } = this.props;

		return groupStandardsChecked;
	};

	nextProgress = (event) => {
		const { nextProgress } = this.props;
		const { groupStandardsChecked } = this.props;

		const data = {
			checks: {
				groupStandardsChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	render() {
		const { groupStandardsChecked, handleAcceptance, isMobile, prevProgress } =
			this.props;

		return (
			<div
				className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
			>
				<h2 className="align-self-center font-weight-bold mb-5 mt-5">
					Group Standards
				</h2>
				<div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
					<p className="pb-4 ">
						As a therapeutic outpatient program that requires a healthy
						environment, Mind Therapy Clinic and its staff have established the
						following group standards to provide the conditions under which
						clients and staff can do the best work possible. Clients are
						expected to review and agree to uphold these, or, as importantly, to
						ask for help when unable to do so. To optimize experience for staff
						and clients in therapy groups, the following standards are adopted:
					</p>
					<p className="pt-1 pb-4">
						<h4>Confidentiality:</h4>
						<span>
							The names, faces, conversations you encounter must be
							confidential. &quot;Who you see here, what you hear here, stays
							here.&quot; You may share about yourself only.
						</span>
					</p>

					<p className="pt-1 pb-4">
						<h4>Late Policy:</h4>
						<span>
							There is a grace period of 10 minutes in case of an emergency.
							However, being considerate of others’ time by being on time is how
							you can be a community member and support yourself and others.
							Doors will be locked after 10 minutes to prevent further
							interruptions of groups.
						</span>
					</p>

					<p className="pt-1 pb-4">
						<h4>Food:</h4>
						<span>
							There is NO eating during group. Beverages are ok as long as
							clients are on time.
						</span>
					</p>
					<p className="pt-1 pb-4">
						<h4>Phone:</h4>
						<span>
							There is NO mobile phone use during group. Mobile phones are
							gathered in a place designated by the group facilitator. Do not to
							take mobile phones to the bathroom.
						</span>
					</p>
					<p className="pt-1 pb-4">
						<h4>Smoking:</h4>
						<span>
							There is No Smoking, Vaping or Chewing Tobacco during group. All
							smokers are asked to smoke away from building entrances and return
							to groups on time.
						</span>
					</p>
					<p className="pt-1 pb-4">
						<h4>Sobriety:</h4>
						<span>
							Supporting the abstinence needs of other clients is essential.
							Refrain from glorifying or romanticizing drug or alcohol use.
							Attending group intoxicated will result in being asked to leave
							the group.
						</span>
					</p>
					<p className="pt-1 pb-4">
						<h4>Breaks:</h4>
						<span>
							Groups are generally 80 minutes in length including one, 10-minute
							break, which is contingent on behavior. This includes returning to
							group on time after the break and other standards listed above.
							Group facilitators may modify the break procedure for any given
							group.
						</span>
					</p>

					<p className="pt-1 pb-4">
						These group standards in addition to the behavioral guidelines are
						here to help us create a respectful, open and safe space to do our
						work and learn. If you feel like you are unable to adhere to these
						standards, please ask for help.
					</p>
					<p className="pt-1 pb-4">
						I understand I am expected to conform to these guidelines and to
						participate in healthy ways in this outpatient community. I
						understand my continued enrollment here depends on my willingness to
						uphold these guidelines and ask for help when I can’t.
					</p>

					<IAccept
						handleAcceptance={handleAcceptance}
						name="groupStandardsChecked"
						value={groupStandardsChecked}
						isRequired
					/>
				</div>
				<div className="d-flex mt-4 mb-5 justify-content-center">
					<div className="col-6">
						<PrevButton onClick={prevProgress} isMobile={isMobile} />
					</div>
					<div className="col-6">
						<NextButton
							disabled={!this.formComplete()}
							onClick={this.nextProgress}
							isMobile={isMobile}
						/>
					</div>
				</div>
			</div>
		);
	}
}

GroupStandards.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	groupStandardsChecked: PropTypes.bool.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default GroupStandards;
