// import components
import { chunk } from 'lodash';

import PropTypes from 'prop-types';

import Checkbox from 'atoms/Checkbox';

/**
 *
 * <BackgroundInformation />, a functional component for Child Intake Evaluation
 */
const BackgroundInformation = (props) => {
	const { data, handleCheck } = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			<h3>ADD/ADHD/Learning Disorder Background Information</h3>

			<label htmlFor="childDevelopmentMilestones" className="form-font">
				DEVELOPMENT MILESTONES: (Check any of the following that may apply. As
				an infant, the patient usually:)
			</label>
			<div className="d-flex flex-column border-top border-left mt-2 mb-5">
				{chunk(data.childDevelopmentMilestones, 2).map((row) => (
					// eslint-disable-next-line react/no-array-index-key
					<div className="d-flex p-0" key={`childdevelopment-${row}`}>
						{row.map((symptom) => (
							<Checkbox
								key={symptom.id}
								className="col-6 border-right border-bottom"
								item={symptom}
								checkboxGroup="childDevelopmentMilestones"
								parent="childAdhdInfo"
								handleCheck={handleCheck}
							/>
						))}
					</div>
				))}
			</div>

			<label htmlFor="childBehaviors" className="form-font">
				BEHAVIOR/CHARACTERISTICS: (Check any of the following symptoms that
				apply to the patient (off medication) either now or in the past)
			</label>
			<div className="d-flex flex-column border-top border-left mt-2 mb-5">
				{chunk(data.childBehaviors, 2).map((row) => (
					// eslint-disable-next-line react/no-array-index-key
					<div className="d-flex p-0" key={`child-behavior-${row}`}>
						{row.map((symptom) => (
							<Checkbox
								key={symptom.id}
								className="col-6 border-right border-bottom"
								item={symptom}
								checkboxGroup="childBehaviors"
								parent="childAdhdInfo"
								handleCheck={handleCheck}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

BackgroundInformation.propTypes = {
	data: PropTypes.object.isRequired,
	handleCheck: PropTypes.func.isRequired,
};

export default BackgroundInformation;
