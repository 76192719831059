/**
 *
 * <DropdownInput />, a functional component to display dopdown with single select option.
 *
 */
import { startCase } from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
} from 'reactstrap';

import { filterByLetter } from 'utils/helpers';

class DropdownInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			inputSearch: '',
			dropdownOpen: false,
			isSearching: false,
			options: [],
		};

		this.hasReset = false;
	}

	toggle = () => {
		if (!this.hasReset) {
			this.hasReset = true;
			this.handleReset();
		}

		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
			inputSearch: '',
		}));
	};

	setDropdown = (val) => {
		this.setState(() => ({
			dropdownOpen: val,
		}));
	};

	componentDidMount() {
		const { selectOptions } = this.props;
		this.setState({
			options: selectOptions,
		});
	}

	handleClick = (option) => {
		const { handleSelect, selectOptions } = this.props;
		handleSelect(option);
		this.setState({
			inputSearch: '',
			options: selectOptions,
		});
	};

	handleReset = () => {
		const { handleReset, selectOptions } = this.props;
		this.setState({
			inputSearch: '',
			options: selectOptions,
		});
		handleReset();
	};

	handleChange = (e) => {
		e.preventDefault();
		const input = e.target.value;
		if (input === '') {
			this.handleReset();
			return;
		}

		const { selectOptions } = this.props;
		const options = filterByLetter(selectOptions, '', input);

		this.setState({
			inputSearch: input,
			options,
		});
	};

	getValue = () => {
		const { inputSearch } = this.state;
		const { defaultSelect } = this.props;

		if (inputSearch === defaultSelect) {
			return '';
		}

		return inputSearch;
	};

	render() {
		const { selectLabel, defaultSelect, isMobile, selectedOption } = this.props;
		let { options, dropdownOpen } = this.state;

		if (!Array.isArray(options)) options = [];

		options = options.slice(0, 10);

		return (
			<div
				className={`align-items-center ${
					isMobile ? 'p-0 py-2' : ' p-3'
				} col-auto ${!isMobile && 'br-1'}`}
			>
				<div
					className={`text-muted m-0 ${isMobile ? 'font-13 pb-2' : 'font-9'}`}
				>
					{selectLabel}
				</div>
				<div>
					{!isMobile && selectedOption && (
						<button
							style={styles.reset}
							className="text-muted font-20"
							onClick={this.handleReset}
						>
							×
						</button>
					)}
					<ButtonDropdown
						nav
						className="btn-dropdown"
						style={{ marginLeft: '10px' }}
						isOpen={dropdownOpen}
						toggle={this.toggle}
					>
						<DropdownToggle nav caret className="w-100">
							<span
								className={`text-dark mr-3  ${
									isMobile ? 'font-18' : 'font-13'
								}`}
							>
								{startCase(selectedOption || defaultSelect)}
							</span>
						</DropdownToggle>
						<DropdownMenu>
							<InputGroup style={styles.inputGroup} flip="false">
								<Input
									placeholder="Search here"
									value={this.getValue()}
									onChange={(e) => this.handleChange(e)}
									style={styles.input}
									className="dropdown-input"
								/>
							</InputGroup>
							<DropdownItem divider />
							{options.map(
								(option) =>
									option && (
										<DropdownItem
											key={option}
											onClick={() => this.handleClick(option)}
										>
											{startCase(option)}
										</DropdownItem>
									),
							)}
							<DropdownItem divider />
							<DropdownItem onClick={this.handleReset} toggle={false}>
								Reset
							</DropdownItem>
						</DropdownMenu>
					</ButtonDropdown>
				</div>
			</div>
		);
	}
}

const styles = {
	inputGroup: {
		width: '100%',
		padding: '10px',
	},
	input: {
		border: 'none',
		outline: 'none',
	},
	reset: {
		position: 'absolute',
		left: '-1px',
		top: '42%',
	},
};

DropdownInput.propTypes = {
	handleSelect: PropTypes.func.isRequired,
	selectLabel: PropTypes.string.isRequired,
	selectOptions: PropTypes.array.isRequired,
	defaultSelect: PropTypes.string,
	handleReset: PropTypes.func,
};

export default DropdownInput;
