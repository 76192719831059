import { uniqBy } from 'lodash';

import React from 'react';
import { useState } from 'react';
import { Input } from 'reactstrap';

import SelectAutoComplete from 'atoms/SelectAutoComplete';
import SelectButtonGroup from 'atoms/SelectButtonGroup';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import { noop } from 'utils/common';
import { generateObjectArray } from 'utils/helpers';

const FeedbackView = (props) => {
	const {
		mode,
		feedback,
		programs,
		sentFeedback,
		handleCheck = noop,
		handleInput = noop,
		handleSelect = noop,
		submit,
		hide,
	} = props;

	const {
		type,
		topic,
		message,
		anonymous,
		program,
		clinician,
		webFeedback,
		completed,
	} = feedback;

	const [isCompleted, setIsCompleted] = useState(completed);

	const handleCompleted = () => {
		setIsCompleted(!isCompleted);
	};

	if (mode === 'view') {
		if (clinician) {
			clinician.label = clinician.name;
			clinician.value = clinician.id;
		}

		if (program) {
			program.label = program.name;
			program.value = program.id;
		}
	}

	const additionalInfo = () => {
		switch (type) {
			case 'program':
				let programList = [];

				if (mode === 'edit') {
					programList = programs.map((p) => ({
						label: p.groupName,
						value: p.programId,
					}));
					programList.push({ label: 'Other', value: 0 });
				} else {
					programList.push({
						label: program.name || 'Other',
						value: program.id || 0,
					});
				}
				return (
					<div className="mb-2">
						<label htmlFor="selectedProgram" className="form-font mb-2">
							Program*
						</label>
						<SelectAutoComplete
							handleChange={handleSelect('program')}
							options={programList}
							value={program}
							placeholder="Select Program"
							disabled={mode === 'view'}
						/>
					</div>
				);
			case 'clinician':
				let cliniciansList = [];

				if (mode === 'edit') {
					cliniciansList = programs.map((p) => ({
						label: p.clinician.clinicianName,
						value: p.clinician.clinicianId,
					}));
					cliniciansList.push({ label: 'Other', value: 0 });
				} else {
					cliniciansList.push({
						label: clinician.name || 'Other',
						value: clinician.id || 0,
					});
				}

				cliniciansList = uniqBy(cliniciansList, (c) => c.value);

				return (
					<div className="mb-2">
						<label htmlFor="selectedClinician" className="form-font mb-2">
							Clinician*
						</label>
						<SelectAutoComplete
							handleChange={handleSelect('clinician')}
							options={cliniciansList}
							value={clinician}
							placeholder="Select Clinician"
							disabled={mode === 'view'}
						/>
					</div>
				);
			case 'mtcApp':
				return (
					<div className="mb-2">
						<label htmlFor="selectedWebFeedback" className="form-font mb-2">
							MTC Feedback Topic*
						</label>
						<SelectButtonGroup
							id="feedbackType"
							value={webFeedback}
							buttons={generateObjectArray(['Bug', 'Suggestion', 'Other'])}
							handleSelect={handleCheck('webFeedback')}
							className="row mx-0"
							buttonClass="font-14 w-auto"
							disabled={mode === 'view'}
						/>
					</div>
				);
			default:
				return;
		}
	};

	const missingFields = () => {
		if (topic === '' || message === '') return true;

		switch (type) {
			case 'program':
				return !program;
			case 'clinician':
				return !clinician;
			case 'webApp':
				return !webFeedback;
			default:
				return false;
		}
	};

	return (
		<div>
			{mode === 'view' && (
				<div className="mx-0">
					<label htmlFor="completed" className="form-font mb-2 mr-2">
						Status
					</label>
					<div>
						<span className="mr-2">Completed</span>
						<input
							type="checkbox"
							checked={isCompleted}
							id="completed"
							onChange={handleCompleted}
							className="hand"
							style={{ width: '16px', height: '16px' }}
							disabled={completed}
						/>
					</div>
				</div>
			)}
			<div className="mb-2">
				<label htmlFor="feedbackType" className="form-font mb-2">
					Topic*
				</label>
				<SelectButtonGroup
					id="feedbackType"
					value={type}
					buttons={generateObjectArray([
						'Program',
						'Billing',
						'Clinician',
						'MTC App',
						'Other',
					])}
					handleSelect={handleCheck('feedbackType')}
					className="row mx-0"
					buttonClass="font-14 w-auto"
					disabled={mode === 'view'}
				/>
			</div>
			{additionalInfo()}
			<div className="mb-2">
				<label htmlFor="feedbackSubject" className="form-font mb-2">
					Subject*
				</label>
				<Input
					placeholder="Enter Subject"
					onChange={handleInput}
					name="topic"
					value={topic}
					disabled={mode === 'view'}
				/>
			</div>
			<div className="mb-2">
				<label htmlFor="feedbackMessage" className="form-font mb-2">
					Message*
				</label>
				<textarea
					placeholder="Enter your feedback here. Thank you!"
					className="form-control"
					style={{ width: '100%', height: '250px', resize: 'none' }}
					onChange={handleInput}
					name="message"
					value={message}
					disabled={mode === 'view'}
				/>
			</div>
			<div className="mb-2">
				<label htmlFor="anonymous" className="form-font mb-2">
					Submit anonymously?*
				</label>
				<SelectButtonGroup
					id="anonymous"
					value={anonymous}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleCheck('anonymous')}
					className="row mx-0"
					buttonClass="font-14 w-auto"
					disabled={mode === 'view'}
				/>
			</div>
			{mode === 'edit' ? (
				<div className="row mx-0">
					<button
						className="btn btn-primary btn-color btn-block font-15 font-weight-bold justify-content-end"
						onClick={submit}
						disabled={missingFields()}
					>
						{sentFeedback ? (
							<SmallLoadingIndicator
								style={{
									width: '20px',
									height: '20px',
									marginTop: '0.25rem !important',
								}}
								className="my-0"
							/>
						) : (
							'SUBMIT FEEDBACK'
						)}
					</button>
				</div>
			) : (
				<div className="row justify-content-between px-5 py-2 pt-3">
					<button
						className="btn btn-white bg-white border link-color btn-lg col-5 font-15"
						onClick={hide}
					>
						<div className="row justify-content-center">
							<div className="col-4 d-flex justify-content-center">
								<span>CANCEL</span>
							</div>
						</div>
					</button>
					<button
						className="btn btn-primary btn-lg col-5 btn-color font-15"
						onClick={submit}
					>
						<div className="row justify-content-center">
							<div className="col-4 d-flex justify-content-center">
								<span>
									{completed || completed === isCompleted ? 'FINISH' : 'SUBMIT'}
								</span>
							</div>
						</div>
					</button>
				</div>
			)}
		</div>
	);
};

export default FeedbackView;
