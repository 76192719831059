/*
 *
 * Geo constants
 *
 */

export const FETCH_STATES = 'src/PatientRegister/FETCH_STATES';
export const FETCH_CITIES = 'src/PatientRegister/FETCH_CITIES';
export const FETCH_ZIPCODES = 'src/PatientRegister/FETCH_ZIPCODES';
export const UPDATE_STATES = 'src/PatientRegister/UPDATE_STATES';
export const UPDATE_CITIES = 'src/PatientRegister/UPDATE_CITIES';
export const UPDATE_ZIPCODES = 'src/PatientRegister/UPDATE_ZIPCODES';
export const SET_ZIPCODE = 'src/PatientRegister/SET_ZIPCODE';
export const SUCCESS = 'src/PatientRegister/SUCCESS';
export const ERROR = 'src/PatientRegister/ERROR';
