/**
 *
 * UpdateEmail
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import PasswordInput from 'atoms/PasswordInput';

import './index.css';

class UpdateEmail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: true,
			password: '',
			email: '',
			errorEmail: false,
		};
	}

	emailValidation = () => {
		// eslint-disable-next-line
		const emailReg =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		let errorEmail = false;
		const { email } = this.state;

		if (!email) {
			errorEmail = true;
		} else if (emailReg.test(email)) {
			errorEmail = false;
		} else {
			errorEmail = true;
		}

		this.setState({
			errorEmail,
		});

		if (errorEmail) {
			return true;
		}
		return false;
	};

	toggle = () => {
		const { toggle, toggleSettings } = this.props;

		toggleSettings();
		toggle();
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handlePasswordSubmit = () => {
		const { password } = this.state;
		const { user, verifyPassword } = this.props;

		const data = {
			id: user.id,
			password,
		};
		verifyPassword(data);
	};

	handleEmailSubmit = () => {
		const isEmailValidated = this.emailValidation();
		const { email } = this.state;
		const { user, updateEmail } = this.props;

		if (isEmailValidated) {
			return;
		}

		const data = {
			id: user.id,
			email,
		};

		updateEmail(data);
	};

	render() {
		const { password, email, errorEmail } = this.state;
		const { passwordVerified, errorMsg } = this.props;

		return (
			<div>
				{!passwordVerified ? (
					<Modal
						className="modal-dialog-centered"
						isOpen={this.state.modal}
						toggle={this.toggle}
					>
						<ModalHeader toggle={this.toggle} className="border-remove" />
						<ModalBody>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12">
										<h3 className="m-0 heading-update-email">
											Please enter your password to continue update email
										</h3>
										<div className="d-flex justify-content-center error-font mb-2 mt-3">
											{errorMsg}
										</div>
										<div className="form-group mt-3">
											<PasswordInput
												label={
													<label className="form-font" htmlFor="password">
														PASSWORD
													</label>
												}
												value={password}
												onChange={this.handleChange}
												name="password"
												className="form-control"
												placeholder="Enter password"
											/>
										</div>
										<div className="mt-4">
											<button
												onClick={this.handlePasswordSubmit}
												type="button"
												className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
											>
												CONTINUE
											</button>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				) : (
					<Modal
						className="modal-dialog-centered"
						isOpen={this.state.modal}
						toggle={this.toggle}
					>
						<ModalHeader toggle={this.toggle} className="border-remove" />
						<ModalBody>
							<div className="container-fluid">
								<div className="row">
									<div className="col-12">
										<h3 className="m-0 heading-update-email">
											Enter your new email
										</h3>
										<div className="form-group mt-3">
											<label className="form-font" htmlFor="email">
												NEW EMAIL
											</label>
											<input
												value={email}
												onChange={this.handleChange}
												type="email"
												name="email"
												className={`form-control ${
													errorEmail ? 'is-invalid' : null
												}`}
												id="InputEmail"
												aria-describedby="emailHelp"
												placeholder="Enter new email"
											/>
											{errorEmail && (
												<div className="invalid-feedback">
													Invalid email address.
												</div>
											)}
										</div>
										<div className="mt-4">
											<button
												onClick={this.handleEmailSubmit}
												type="button"
												className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-13"
											>
												UPDATE EMAIL
											</button>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
					</Modal>
				)}
			</div>
		);
	}
}

UpdateEmail.propTypes = {
	toggle: PropTypes.func.isRequired,
	toggleSettings: PropTypes.func.isRequired,
	verifyPassword: PropTypes.func.isRequired,
	passwordVerified: PropTypes.bool.isRequired,
	updateEmail: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	errorMsg: PropTypes.string,
};

export default UpdateEmail;
