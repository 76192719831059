import { Editor, EditorState, RichUtils } from 'draft-js';

import history from 'utils/history';

import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { headerStyle } from 'containers/App/constants';

const initState = {
	title: 'New Document',
};

const TitleInput = styled.input`
	border: 1px solid white;

	:hover {
		border: 1px solid rgb(0, 0, 0, 0.15);
	}
`;

const ToolbarButton = styled.button`
	width: 38px;
	height: 38px;
	margin: 6px 2px;
	background: ${(props) => (props.active ? 'rgb(0, 0, 0, 0.1)' : 'white')};

	:hover {
		background: rgb(0, 0, 0, 0.2);
		cursor: pointer;
	}
`;

const FontButtonControl = styled.button`
	width: 80px;
	text-align: center;
	position: relative;
	padding-right: 18px;

	span {
		position: absolute;
		top: calc(50% - 6px);
		right: 0;
		margin-left: 2px;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const FontButton = styled.button`
	display: block;
	width: 100%;
	text-align: center;

	:hover {
		background: var(--main-color);
		color: white;
		cursor: pointer;
	}
`;

const initStyleMap = {};
for (let i = 8; i <= 100; i++) {
	initStyleMap[`FONT_SIZE_${i}`] = { fontSize: (i * 5) / 3 };
}

const FormEditor = (props) => {
	const { data = initState } = props;

	const [styleMap] = useState(initStyleMap);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [showFontSizeMenu, setShowFontSizeMenu] = useState(false);
	const fontSizeMenu = useRef();
	const handleFontSizeControllerClick = (e) => {
		e.preventDefault();
		setShowFontSizeMenu(!showFontSizeMenu);
	};

	const [title, setTitle] = useState(data.title);
	const [margins] = useState({ x: 1, y: 1 });
	const ppi = 128;
	const [pageDimensions] = useState({
		x: 8.5,
		y: 11,
	});

	const inchesToPixels = (inch) => {
		return inch * ppi;
	};

	useEffect(() => {
		const checkIfFontSizeMenuToggle = (e) => {
			const inMenu = fontSizeMenu.current?.contains(e.target);
			if (showFontSizeMenu && !inMenu) {
				setShowFontSizeMenu(false);
			}
		};

		window.addEventListener('mousedown', checkIfFontSizeMenuToggle);

		return () =>
			window.removeEventListener('mousedown', checkIfFontSizeMenuToggle);
	}, [showFontSizeMenu]);

	/* eslint-disable @typescript-eslint/no-empty-function */
	useEffect(() => {}, [pageDimensions]);

	const editor = useRef(null);
	const focusEditor = () => {
		editor.current.focus();
	};

	const onStyleIconClick = (action) => (e) => {
		e.preventDefault();

		const newState = RichUtils.toggleInlineStyle(editorState, action);
		setEditorState(newState);
		focusEditor();
	};

	const onFontSizeChange = (val) => (e) => {
		e.preventDefault();

		const oldStyles = editorState
			.getCurrentInlineStyle()
			.toJS()
			.filter((x) => x.includes('FONT_SIZE'));
		let newState = editorState;
		oldStyles.forEach(
			(s) => (newState = RichUtils.toggleInlineStyle(newState, s)),
		);

		newState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${val}`);

		setEditorState(newState);
		setShowFontSizeMenu(false);
		focusEditor();
	};

	const getFontSize = () => {
		const currentStyles = editorState
			.getCurrentInlineStyle()
			.toJS()
			.filter((s) => s.includes('FONT_SIZE'));

		if (currentStyles.length === 0) {
			return 12;
		} else if (currentStyles.length > 1) {
			return '-';
		} else {
			let parts = currentStyles[0].split('_');
			return parts[parts.length - 1];
		}
	};

	const handleKeyCommand = (command, state) => {
		let newState;

		switch (command) {
			case 'backspace':
				newState = RichUtils.handleKeyCommand(state, command);
				break;
			default:
				newState = RichUtils.toggleInlineStyle(state, command.toUpperCase());
				break;
		}

		if (newState) {
			setEditorState(newState);
			return 'handled';
		}

		return 'not-handled';
	};

	const styleIcons = [
		{ text: 'B', action: 'BOLD', style: { fontWeight: 600 } },
		{ text: 'I', action: 'ITALIC', style: { fontStyle: 'italic' } },
		{
			text: 'U',
			action: 'UNDERLINE',
			style: { borderBottom: '1px solid black' },
		},
	];
	const fontSizes = [];
	for (let i = 8; i < 100; i++) {
		fontSizes.push({ label: i, value: i });
	}

	return (
		<div className="container-fluid px-0">
			<div className="bg-white py-3 px-5 border-bottom row justify-content-between">
				<div className="col-auto">
					<div
						className="text-uppercase pl-2"
						style={{ ...headerStyle, border: '2px solid white' }}
					>
						<span
							style={{ color: 'var(--main-color)', cursor: 'pointer' }}
							onClick={() => history.push('/admin/dashboard/forms')}
						>
							Forms
						</span>
						<span style={{ color: 'black' }}> &gt; Edit</span>
					</div>
					<div className="font-26 font-weight-bold mt-1">
						<TitleInput
							type="text"
							className="font-weight-bold pl-2"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-auto pt-3">
					<button className="mx-2 btn btn-white bg-white border alert-color font-weight-bold font-12 text-uppercase">
						Delete
					</button>
					<button className="mx-2 btn btn-white bg-white border link-color font-weight-bold font-12 text-uppercase">
						Save to Draft
					</button>
					<button className="mx-2 btn btn-primary primary-btn-color px-4 py-2 font-12 text-uppercase">
						Publish
					</button>
				</div>
			</div>
			<div className="row mx-0 px-5 bg-white">
				{styleIcons.map((i) => (
					<ToolbarButton
						key={i.action}
						onMouseDown={onStyleIconClick(i.action)}
						active={editorState
							.getCurrentInlineStyle()
							.toJS()
							.includes(i.action)}
					>
						<span style={i.style}>{i.text}</span>
					</ToolbarButton>
				))}
				<div className="my-auto" style={{ position: 'relative' }}>
					<FontButtonControl onMouseDown={handleFontSizeControllerClick}>
						{getFontSize()}
						<span
							className={`custom-arrow ${showFontSizeMenu ? 'up' : 'down'}`}
						></span>
					</FontButtonControl>
					{showFontSizeMenu && (
						<div
							className="shadow"
							ref={fontSizeMenu}
							style={{
								position: 'absolute',
								height: 300,
								background: 'white',
								overflowY: 'auto',
								width: 80,
							}}
						>
							{fontSizes.map((o) => (
								<FontButton
									key={o.value}
									onMouseDown={onFontSizeChange(o.value)}
								>
									{o.label}
								</FontButton>
							))}
						</div>
					)}
				</div>
			</div>
			<div
				className="my-4 mx-auto shadow"
				style={{
					background: 'white',
					width: inchesToPixels(pageDimensions.x),
					height: inchesToPixels(pageDimensions.y),
					padding: `${inchesToPixels(margins.y)}px ${inchesToPixels(
						margins.x,
					)}px`,
					cursor: 'text',
				}}
				onClick={focusEditor}
			>
				<Editor
					customStyleMap={styleMap}
					ref={editor}
					editorState={editorState}
					onChange={setEditorState}
					handleKeyCommand={handleKeyCommand}
				/>
			</div>
		</div>
	);
};

export default FormEditor;
