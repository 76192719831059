import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the toDos state domain
 */
const selectAppDomain = (state) => state.get('app', initialState);
const selectToDosDomain = (state) => state.get('todos', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ToDos
 */

const makeSelectToDos = () =>
	createSelector(selectToDosDomain, (substate) => returnJS(substate));

const makeSelectUserId = () =>
	createSelector(selectAppDomain, (substate) =>
		returnJS(substate.getIn(['auth', 'loggedInUser', 'id'])),
	);

const makeSelectOtherRequests = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('otherRequests')),
	);

const makeSelectRxRequests = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('rxRequests')),
	);

const makeSelectNewIntakes = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('newIntakes')),
	);

const makeSelectIsFetchingNewIntakes = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('isFetchingNewIntakes')),
	);

const makeSelectIsFetchingOtherReq = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('isFetchingOtherReq')),
	);

const makeSelectIsFetchingRXReq = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('isFetchingRXReq')),
	);

const makeSelectPharmacies = () =>
	createSelector(selectToDosDomain, (substate) =>
		returnJS(substate.get('pharmacies')),
	);
// export default makeSelectToDos;
export {
	makeSelectToDos,
	makeSelectUserId,
	makeSelectOtherRequests,
	makeSelectNewIntakes,
	makeSelectRxRequests,
	makeSelectIsFetchingNewIntakes,
	makeSelectIsFetchingOtherReq,
	makeSelectIsFetchingRXReq,
	makeSelectPharmacies,
};
