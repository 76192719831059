import PropTypes from 'prop-types';

import { Component, createRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import DeleteButton from 'atoms/DeleteButton';
import EnrollmentAgreementTabs from 'components/EnrollmentAgreementTabs';

class EnrollmentAgreement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
		};

		this.scrollRef = createRef();
	}

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	scrollTop = () => {
		this.scrollRef.current.scrollIntoView();
	}

	render() {
		const { modal } = this.state;
		const { auth, data, submitRequest, isMobile } = this.props;

		return (
			<div>
				<Modal
					isOpen={modal}
					className={`modal-dialog-centered custom-modal modal-xl ${
						isMobile && 'modal-mobile'
					}`}
					backdrop="static"
				>
					<ModalHeader className="px-4 pb-0 border-0 container-fluid text-center justify-content-center" >
						<div style={{ position: 'absolute', top: '10px', right: '20px' }} ref={this.scrollRef}>
							<DeleteButton onClick={this.closeModal} />
						</div>
					</ModalHeader>
					<ModalBody className={`pb-0 ${isMobile && 'px-1'}`}>
						<EnrollmentAgreementTabs
							auth={auth}
							data={data}
							submitRequest={submitRequest}
							isMobile={isMobile}
							scrollTop={this.scrollTop}
						/>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

EnrollmentAgreement.propTypes = {
	hide: PropTypes.func.isRequired,
	auth: PropTypes.object,
	data: PropTypes.object,
	submitRequest: PropTypes.func,
};

export default EnrollmentAgreement;
