import PropTypes from 'prop-types';

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import failImg from 'images/fail.png';
import IlluSuccessImg from 'images/illu_success.png';

import './index.css';

/* eslint-disable react/prefer-stateless-function */
class ValidateSentModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			enableConfirm: false,
		};
		this.defaultCheck = 'I Accept';
	}

	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	handleDelete = () => {
		const { handleDelete, toBeDeleted, hide } = this.props;
		handleDelete(toBeDeleted);
		hide();
	};

	handleKeyDown = (e) => {
		if (e.key === 'Enter' && this.state.enableConfirm) {
			e.preventDefault();
			this.handleDelete();
		}
	};

	handleChange = (event) => {
		const { toBeDeleted = {} } = this.props;
		const check = toBeDeleted.name || this.defaultCheck;
		const enabled = event.target.value === check;

		this.setState({
			enableConfirm: enabled,
		});
	};

	render() {
		const { modal } = this.state;

		const { successStatus, message, toBeDeleted = {} } = this.props;
		return (
			<div>
				<Modal
					isOpen={modal}
					className="modal-dialog-centered"
					backdrop="static"
				>
					<ModalHeader toggle={this.closeModal} className="border-0" />
					<ModalBody toggle={this.closeModal} className="container-fluid pt-0">
						<div className="d-flex flex-column">
							<div className="p-2 align-self-center">
								<img
									src={successStatus ? IlluSuccessImg : failImg}
									height="200"
									width="200"
									alt="TodosInactive"
								/>
							</div>
							<div className="align-self-center w-100">
								<div className="custom-req-sent-heading p-3 font-25 text-dark text-center">
									{message}
								</div>
							</div>
							<div className="align-self-center w-100">
								<div className="p-3 font-18 text-dark text-center">
									Please type{' '}
									<span className="text-danger">
										{toBeDeleted.name || this.defaultCheck}
									</span>{' '}
									to confirm deletion
								</div>
								<div className="px-5">
									<input
										className="form-control"
										onChange={this.handleChange.bind(this)}
										onKeyDown={this.handleKeyDown}
										type="text"
										placeholder="Type Here"
									/>
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-around align-items-center pt-4 px-4 pb-2">
							<button
								onClick={this.closeModal}
								type="button"
								className="btn btn-primary btn-lg bg-white link-color border text-uppercase font-13 col-5"
							>
								Cancel
							</button>
							<button
								disabled={!this.state.enableConfirm}
								onClick={this.handleDelete}
								type="button"
								className="btn btn-primary btn-lg btn-block btn-danger text-uppercase font-13 col-5"
							>
								Confirm
							</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

ValidateSentModal.propTypes = {
	hide: PropTypes.func,
	groupObject: PropTypes.object,
	requestType: PropTypes.object,
	successStatus: PropTypes.bool,
	message: PropTypes.string,
	handleDelete: PropTypes.func,
	toBeDeleted: PropTypes.any,
};

export default ValidateSentModal;
