/*
 *
 * SignIn constants
 *
 */

export const DEFAULT_ACTION = 'src/SignIn/DEFAULT_ACTION';
export const LOGIN_USER_FETCH = 'src/SingIn/LOGIN_USER_FETCH';
export const LOGIN_USER_SUCCESS = 'src/SingIn/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'src/SingIn/LOGIN_USER_FAILURE';
export const CLEAR_ERROR = 'src/SingIn/CLEAR_ERROR';
export const TOGGLE_FORGOT_PASS = 'src/Dashboard/TOGGLE_FORGOT_PASS';
export const FORGOT_PASS = 'src/Dashboard/FORGOT_PASS';
export const FORGOT_PASS_MSG = 'src/Dashboard/FORGOT_PASS_MSG';
