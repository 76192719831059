import React, { useState } from 'react';

import Table from 'atoms/Table';

const DocumentTable = (props) => {
	const { data, columns, title } = props;
	const [show, setShow] = useState(true);

	const calcHeight = () => {
		if (!show) {
			return 20;
		} else {
			if (data.length === 0) {
				return 100;
			} else {
				return 1000;
			}
		}
	};
	return (
		<div
			className="my-2"
			style={{
				maxHeight: calcHeight(),
				height: 'auto',
				overflowY: 'hidden',
				transition: '0.5s all ease-in-out',
			}}
		>
			<div className="d-flex w-100 justify-content-between mb-2">
				<div className="text-uppercase font-weight-bold font-11">{title}</div>
				<span
					className={`mt-2 mx-1 custom-arrow ${show ? 'up' : 'down'}`}
					onClick={() => setShow(!show)}
				/>
			</div>
			<Table
				keyField="signedDocs"
				data={data}
				columns={columns}
				key="docId"
				pagination={true}
			/>
		</div>
	);
};

export default DocumentTable;
