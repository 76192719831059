import PropTypes from 'prop-types';

import React from 'react';

import DocumentTable from './DocumentTable';
import { getDocsColumns } from './columns';

const noSignatureDocs = [
	'Telehealth',
	'Patient-Intake',
	'Intake-Questionnaire',
];

const UserDocuments = (props) => {
	const { viewForm, docs, requests } = props;
	const pendingDocs = [];
	const completedDocs = [];
	docs.forEach((doc) => {
		if (
			noSignatureDocs.includes(doc.documentType) ||
			doc.request.state ||
			doc.request.state === 'APPROVED' ||
			doc.request.state === 'COMPLETED'
		) {
			completedDocs.push(doc);
		} else {
			pendingDocs.push(doc);
		}
	});

	return (
		<div>
			<DocumentTable
				title="Pending Documents"
				data={pendingDocs}
				columns={getDocsColumns('pending', viewForm)}
			/>
			<DocumentTable
				title="Completed Documents"
				data={[...completedDocs, ...requests]}
				columns={getDocsColumns('completed', viewForm)}
			/>
		</div>
	);
};

UserDocuments.propTypes = {
	data: PropTypes.array.isRequired,
};

export default UserDocuments;
