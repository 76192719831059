import { startCase } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { useSelector } from 'react-redux';

import ActionButton from 'atoms/ActionButton';
import CustomButton from 'atoms/CustomButton';
import deleteImage from 'svgs/delete';
import downloadImage from 'svgs/download';
import sendImage from 'svgs/send';

const FormCard = (props) => {
	const role = useSelector((state) =>
		state.get('app').get('auth').get('loggedInUser').get('roleName'),
	);

	const {
		form = {},
		heading,
		actions,
		subheading,
		type,
		isSender = false,
	} = props;

	const { client, createdAt, updatedAt } = form;

	const share = (
		<div className="col-auto px-0 mx-3" key="share-action">
			<ActionButton
				image={sendImage}
				onClick={() => actions.share(form)}
				className="d-flex justify-content-center"
			/>
			<label className="form-font">Share</label>
		</div>
	);

	const download = (
		<div className="col-auto px-0 mx-3" key="download-action">
			<ActionButton
				image={downloadImage}
				onClick={() => actions.download(form.uuid)}
				className="d-flex justify-content-center"
			/>
			<label className="form-font">Download</label>
		</div>
	);

	const deleteAction = (
		<div className="col-auto px-0 mx-3" key="delete-action">
			<ActionButton
				image={deleteImage}
				hoverColor={'var(--main-alert)'}
				onClick={() => actions.delete(form)}
				className="d-flex justify-content-center"
			/>
			<label className="form-font">Delete</label>
		</div>
	);

	const signAction = (
		<div className="col-auto mr-3">
			<CustomButton
				type="primary"
				title="Sign Now"
				onClick={() => actions.sign(form)}
			/>
		</div>
	);

	const declineAction = (
		<div className="col-auto mr-3">
			<CustomButton
				type="secondary"
				title="Decline"
				onClick={() => actions.decline(form)}
			/>
		</div>
	);

	const canAct = true;
	const actionMap = {
		templates: [share, download, deleteAction],
		pending: role === 'patient' ? [signAction, declineAction] : [],
		signed: [],
		declined: [],
		shared: [],
	};

	const actionButtons = actionMap[type];

	return (
		<div className="card-container request-card bg-white border-bottom p-3">
			{heading && (
				<div
					className="card-title font-weight-bold mb-2 text-center hand"
					style={{ fontSize: '18px' }}
					onClick={() => actions.view(form)}
				>
					{startCase(heading)}
				</div>
			)}
			<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2 text-center">
				{subheading}
			</div>
			<div className="row mx-0 mb-4 justify-content-between text-center">
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Submitted On
					</div>
					<div>{createdAt && moment(createdAt).format('YYYY-MM-DD')}</div>
				</div>
				<div className="col-6">
					<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
						Updated On
					</div>
					<div>
						{updatedAt
							? updatedAt && moment(updatedAt).format('YYYY-MM-DD')
							: '-'}
					</div>
				</div>
			</div>
			<div className="row mx-0 mb-4 justify-content-between text-center">
				{client?.name && (
					<div className="col-6">
						<div className="text-cust-grey font-10 custom-card-label text-uppercase py-2">
							{isSender ? 'Receiver' : 'Submitted By'}
						</div>
						{client.name}
					</div>
				)}
			</div>
			{canAct ? (
				actionButtons.length ? (
					<div className="row border-top pt-2 justify-content-center">
						{actionMap[type]}
					</div>
				) : null
			) : (
				<div className="row btn border-top btn-white btn-block btn-lg link-color font-17 mx-0 mt-2 justify-content-end">
					DOCUMENT UNAVAILABLE
				</div>
			)}
		</div>
	);
};

FormCard.propTypes = {
	form: PropTypes.object,
	heading: PropTypes.string,
	subheading: PropTypes.string,
};

export default FormCard;
