import { MouseEventHandler, useCallback, useRef, useState } from 'react';

type MouseEventHandlers = {
	onMouseDown: (e: MouseEvent) => void;
	onTouchStart: (e: MouseEvent) => void;
	onMouseUp: (e: MouseEvent) => void;
	onMouseLeave: (e: MouseEvent) => void;
	onTouchEnd: (e: MouseEvent) => void;
};

export default function useLongPress(
	onLongPress: MouseEventHandler,
	onClick: MouseEventHandler,
	{ shouldPreventDefault = true, delay = 300 } = {},
): MouseEventHandlers {
	const [longPressTriggered, setLongPressTriggered] = useState(false);
	const timeout = useRef<NodeJS.Timeout>();
	const target = useRef<Node>();

	const start = useCallback(
		(event) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener('touchend', preventDefault, {
					passive: false,
				});
				target.current = event.target;
			}

			if (timeout.current) {
				timeout.current = setTimeout(() => {
					onLongPress(event);
					setLongPressTriggered(true);
				}, delay);
			}
		},
		[onLongPress, delay, shouldPreventDefault],
	);

	const clear = useCallback(
		(event, shouldTriggerClick = true) => {
			timeout.current && clearTimeout(timeout.current);
			shouldTriggerClick && !longPressTriggered && onClick(event);
			setLongPressTriggered(false);
			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault);
			}
		},
		[shouldPreventDefault, onClick, longPressTriggered],
	);

	return {
		onMouseDown: (e: MouseEvent) => start(e),
		onTouchStart: (e: MouseEvent) => start(e),
		onMouseUp: (e: MouseEvent) => clear(e),
		onMouseLeave: (e: MouseEvent) => clear(e, false),
		onTouchEnd: (e: MouseEvent) => clear(e),
	};
}

const preventDefault = (event: Event | TouchEvent) => {
	if (
		event instanceof TouchEvent &&
		event.touches.length < 2 &&
		event.preventDefault
	) {
		event.preventDefault();
	}
};
