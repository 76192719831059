/**
 *
 * DrugAlcoholPolicy
 *
 */
import PropTypes from 'prop-types';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import IAccept from 'components/IAccept';

/* eslint-disable react/prefer-stateless-function */
const DrugAlcoholPolicy = (props) => {
	const { handleAcceptance, drugAlcoholPolicyChecked, prevProgress, isMobile } =
		props;

	const nextProgress = (event) => {
		const { nextProgress } = props;

		const data = {
			checks: {
				drugAlcoholPolicyChecked,
			},
		};
		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();
		nextProgress(data)(event);
	};

	const formComplete = () => {
		return drugAlcoholPolicyChecked;
	};

	return (
		<div
			className={`container-fluid d-flex flex-column px-${isMobile ? 0 : 5}`}
		>
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				Drug & Alcohol Policy
			</h2>
			<div className={`card d-flex flex-column p-${isMobile ? '1' : '4'}`}>
				<p className="pb-4">
					Mind Therapy Clinic may at any time randomly test any and all of its
					clients via urine analysis and/or breathalyzer test to determine
					presence of possible alcohol or other drug use or abuse.
				</p>
				<p className="pt-1 pb-4">
					<h4>Purpose: </h4>
					<span>
						Mind Therapy Clinic is a drug- and alcohol-free environment to
						foster all clients’ recovery process and overall mental and physical
						well-being.
					</span>
				</p>

				<p className="pt-1 pb-4">
					<h4>Policy:</h4>
					<span>
						Clients who come to treatment under the inﬂuence or appear
						intoxicated will be asked to leave. Although staff will attempt to
						do their best to encourage clients not to engage in risky behavior
						while intoxicated, Mind Therapy Clinic and its staff are not liable
						for clients’ actions when intoxicated or abusing substances or
						prescription medications.
					</span>
				</p>

				<p className="pt-1 pb-4">
					<h4>Please Note:</h4>
					<span>
						Staff will contact police when clients test positive for alcohol,
						refuse to turn over car keys and insist on driving. This policy will
						ensure everybody’s safety. In the event a client refuses to be
						tested and staff suspects the client is under the inﬂuence the
						client will be asked to leave for the day.{' '}
					</span>
				</p>
				<IAccept
					handleAcceptance={handleAcceptance}
					name="drugAlcoholPolicyChecked"
					value={drugAlcoholPolicyChecked}
					isRequired
				/>
			</div>
			<div className="d-flex mt-4 mb-5 justify-content-center">
				<div className="col-6">
					<PrevButton onClick={prevProgress} isMobile={isMobile} />
				</div>
				<div className="col-6">
					<NextButton
						disabled={!formComplete()}
						onClick={nextProgress}
						isMobile={isMobile}
					/>
				</div>
			</div>
		</div>
	);
};

DrugAlcoholPolicy.propTypes = {
	step: PropTypes.func.isRequired,
	previousStep: PropTypes.func.isRequired,
	drugAlcoholPolicyChecked: PropTypes.bool.isRequired,
	handleAcceptance: PropTypes.func.isRequired,
};

export default DrugAlcoholPolicy;
