import React from 'react';

const required = (value) => {
	if (!value?.toString().trim().length) {
		// We can return string or jsx as the 'error' prop for the validated Component
		return <span className="form-error">Field is required.</span>;
	}
};

const lettersOnly = (value) => {
	const val = value.match(/[a-zA-Z\s]*$/);
	if (!val) {
		// components['password'][0].value !== components['confirm'][0].value
		// 'confirm' - name of input
		// components['confirm'] - array of same-name components because of checkboxes and radios

		return (
			<span className="form-error">
				This field must only consist of letters.
			</span>
		);
	}
};

const numbersOnly = (value) => {
	const reg = /^\d+$/;
	const val = reg.test(value);

	if (value && value.toString().trim().length > 0 && !val) {
		// components['password'][0].value !== components['confirm'][0].value
		// 'confirm' - name of input
		// components['confirm'] - array of same-name components because of checkboxes and radios

		return (
			<span className="form-error">
				This field must only consist of numbers.
			</span>
		);
	}
};

const numbersAndSpacesOnly = (value) => {
	const reg = /^\d+$/;
	const val = reg.test(value.replace(/\s+/g, ''));

	if (value && value.toString().trim().length > 0 && !val) {
		// components['password'][0].value !== components['confirm'][0].value
		// 'confirm' - name of input
		// components['confirm'] - array of same-name components because of checkboxes and radios

		return (
			<span className="form-error">
				This field must only consist of numbers.
			</span>
		);
	}
};

// email validations.

const email = (value) => {
	if (!value || value.toString().trim().length < 1) {
		return <span className="form-error">This email is not valid.</span>;
	}
};

// alphanumeric value check

const alphaNumeric = (value) => {
	const val = value.match(/[a-zA-Z0-9\s]*$/);
	if (!val) {
		return (
			<span className="form-error">
				This field must only consist of letters and numbers.
			</span>
		);
	}
};

function maxlen(length = 25) {
	return function lenValidator(value) {
		// get the maxLength from component's props
		if (value && value.toString().trim().length > length) {
			// Return jsx
			return (
				<span className="form-error">
					{`Only ${length} characters is allowed for this field`}
				</span>
			);
		}
	};
}

const isCreditCard = (value) => {
	// get the maxLength from component's props
	if (value && value.toString().trim().length < 15) {
		// Return jsx
		return (
			<span className="form-error">Enter a valid credit card number.</span>
		);
	}
};

const isPhoneNumber = (value) => {
	const isnumbersOnly = numbersOnly(value);
	// get the maxLength from component's props
	if (isnumbersOnly) {
		return isnumbersOnly;
	}
	if (
		value &&
		value.toString().trim().length > 0 &&
		value.toString().trim().length < 10 &&
		!isnumbersOnly
	) {
		// Return jsx
		// return `Atleast 10 characters.`;
		return <span className="form-error">Enter at least 10 digits.</span>;
	}
};

const password = (value, props, components) => {
	// NOTE: Tricky place. The 'value' argument is always current component's value.
	// So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
	// But if we're changing 'confirm' component - the condition will always be true
	// If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
	if (value !== components.confirm[0].value) {
		// components['password'][0].value !== components['confirm'][0].value
		// 'confirm' - name of input
		// components['confirm'] - array of same-name components because of checkboxes and radios
		return 'Passwords are not equal';
	}
};

const getCardType = (number) => {
	// visa
	let re = new RegExp('^4');
	if (number.match(re) != null) return 'Visa';

	// Mastercard
	// Updated for Mastercard 2017 BINs expansion
	if (
		/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
			number,
		)
	)
		return 'Mastercard';

	// AMEX
	re = new RegExp('^3[47]');
	if (number.match(re) != null) return 'AMEX';

	// Discover
	re = new RegExp(
		'^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)',
	);
	if (number.match(re) != null) return 'Discover';

	// Diners
	re = new RegExp('^36');
	if (number.match(re) != null) return 'Diners';

	// Diners - Carte Blanche
	re = new RegExp('^30[0-5]');
	if (number.match(re) != null) return 'Diners - Carte Blanche';

	// JCB
	re = new RegExp('^35(2[89]|[3-8][0-9])');
	if (number.match(re) != null) return 'JCB';

	// Visa Electron
	re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
	if (number.match(re) != null) return 'Visa Electron';

	return '';
};

export {
	required,
	email,
	maxlen,
	lettersOnly,
	numbersOnly,
	password,
	alphaNumeric,
	isPhoneNumber,
	isCreditCard,
	getCardType,
	numbersAndSpacesOnly,
};
