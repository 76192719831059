import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the userDropDown state domain
 */

const selectFeedbackDomain = (state) => state.get('feedback', initialState);

const makeSelectError = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('error')),
	);

const makeSelectSuccess = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('success')),
	);

const makeSelectFeedback = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('feedback')),
	);

const makeSelectIsUpdating = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('isUpdating')),
	);

const makeSelectIsFetching = () =>
	createSelector(selectFeedbackDomain, (substate) =>
		returnJS(substate.get('isFetching')),
	);

export default selectFeedbackDomain;
export {
	makeSelectError,
	makeSelectSuccess,
	makeSelectFeedback,
	makeSelectIsUpdating,
	makeSelectIsFetching,
};
