export function getFields(isCCForm) {
	if (isCCForm) {
		return {
			ccnumber: {
				selector: '#ccnumber',
				enableCardBrandPreviews: true,
				placeholder: 'Card Number',
			},
			ccexp: {
				selector: '#ccexp',
				placeholder: 'Card Expiration',
			},
			cvv: {
				placeholder: 'CVV',
				selector: '#cvv',
			},
		};
	}

	return {
		checkname: {
			placeholder: 'Name on Check',
			selector: '#checkname',
		},
		checkaba: {
			placeholder: 'Routing Number',
			selector: '#checkaba',
		},
		checkaccount: {
			placeholder: 'Account Number',
			selector: '#checkaccount',
		},
	};
}
