import React, { useState } from 'react';

import DeleteButton from 'atoms/DeleteButton';
import BucketCard from 'pages/browsegroups/BucketCard';

const EnrollCart = (props) => {
	const [isOpen, setOpen] = useState(false);
	const { bucket, isMobile, total, removeFromBucket, handleSubmit } = props;

	const handleRemove = (id) => {
		removeFromBucket(id);
	};

	const toggleOpen = () => {
		setOpen(!isOpen);
	};

	const headerHeight = 60;
	const footerHeight = 64;

	const menuStyle = {
		bottom: `${footerHeight}px`,
		left: '0px',
		boxShadow: 'none',
		transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
		transition: '0.4s transform ease-in-out',
		width: '100%',
		position: 'absolute',
		height: `calc(${window.innerHeight}px - ${headerHeight + footerHeight}px)`,
	};

	return (
		<div className="w-100" style={{ position: 'fixed', bottom: '0px' }}>
			<button
				className="btn btn-primary btn-color font-'16' mr-2 mt-1"
				style={{
					borderRadius: '5px',
					textAlign: 'center',
					position: 'absolute',
					bottom: 'calc(64px + 1rem)',
					left: '0.5rem',
					boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
				}}
				onClick={toggleOpen}
			>
				To Enroll
				<div
					style={{
						position: 'absolute',
						fontSize: bucket.length === 0 ? '0px' : '14px',
						width: bucket.length === 0 ? '0px' : '20px',
						height: bucket.length === 0 ? '0px' : '20px',
						borderRadius: '20px',
						transform: 'translate(50%, -50%)',
						background: 'red',
						top: 0,
						right: 0,
						transition: '0.2s all ease-in-out',
					}}
				>
					{bucket.length > 0 ? bucket.length : ''}
				</div>
			</button>
			<div
				className="bg-white justify-content-center text-center"
				style={menuStyle}
			>
				<DeleteButton
					onClick={toggleOpen}
					styles={{ container: { top: '0.5rem', right: '1rem' } }}
				/>
				<h4 className="mb-4">Selected Therapies</h4>
				{bucket.length === 0 ? (
					<div className="mx-4 font-14">
						Please select a therapy to enroll in.
					</div>
				) : (
					<div className="justify-content-between">
						<div
							style={{ background: 'white', width: '100%', boxShadow: 'none' }}
						>
							<div
								className="px-4 py-3 bg-light"
								style={{
									height: `calc(${window.innerHeight}px - 353px)`,
									overflowY: 'auto',
								}}
							>
								{bucket.map((item) => (
									<div style={{ padding: '0px' }} key={item.id}>
										<BucketCard
											key={item.id}
											cardGroupDetail={item}
											remove={handleRemove}
											isMobile={isMobile}
										/>
									</div>
								))}
							</div>
							<div className="d-flex justify-content-between py-2 px-4">
								<div className="col d-flex justify-content-start py-4 pl-0 pr-2 font-13 font-weight-bold text-dark text-uppcase">
									Total
								</div>
								<div className="col-auto d-flex justify-content-start py-4 px-0 font-13 font-weight-bold text-dark text-uppcase">
									${total}
									/m
								</div>
								<div className="col-2" />
							</div>
							<div className="d-flex border">
								<button
									type="button"
									className="btn btn-primary primary-btn-color font-12 w-100 p-4"
									onClick={handleSubmit}
									disabled={!bucket.length}
								>
									<span className="mr-4">Proceed to Enroll</span>
									<i className="fas fa-arrow-right" />
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EnrollCart;
