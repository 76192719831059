import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the groups state domain
 */

const selectGroupsDomain = (state) => state.get('therapies', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Groups
 */

const makeSelectGroups = () =>
	createSelector(selectGroupsDomain, (substate) => returnJS(substate));

const makeSelectGroupTypes = () =>
	createSelector(selectGroupsDomain, (substate) =>
		returnJS(substate.get('groupTypes')),
	);

const makeSelectAllPrograms = () =>
	createSelector(selectGroupsDomain, (substate) =>
		returnJS(substate.get('programs')),
	);

const makeSelectIsFetching = () =>
	createSelector(selectGroupsDomain, (substate) =>
		returnJS(substate.get('isFetching')),
	);

export default makeSelectGroups;
export {
	selectGroupsDomain,
	makeSelectGroupTypes,
	makeSelectAllPrograms,
	makeSelectIsFetching,
};
