import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from 'atoms/PasswordInput';
import {
    createOQEmployee,
} from './actions';

type FormDataTypes = {
    [key: string]: string;
};

type FormErrorsTypes = {
    [key: string]: boolean;
};

const AccountCreationCard = (): JSX.Element => {
    const [formData, setFormData] = useState<FormDataTypes>({
        username: '',
        password: '',
        confirmPassword: '',
    });
    const [formErrors, setFormErrors] = useState<FormErrorsTypes>({
        username: false,
        password: false,
        confirmPassword: false,
        matchPasswords: false,
    });
    const userId = useSelector((state: RootState) =>
        state.get('app').get('auth').get('loggedInUser').get('id')
    );

    const dispatch = useDispatch();

    const handleCreateAccount = (e: React.FormEvent) => {
        e.preventDefault();
        const errors = formValidation();
        setFormErrors(errors);
        const valid = Object.values(errors).every(value => value === false);
        if (valid) {
            dispatch(createOQEmployee({
                employeeId: userId + '',
                username: formData.username,
                password: formData.password,
            }));
        }
    };

    function formValidation(): FormErrorsTypes {
        const formErrors: FormErrorsTypes = {
            username: false,
            password: false,
            confirmPassword: false,
            matchPasswords: false,
        };
        // Check if fields are empty
        for (const key in formData) {
            formErrors[key] = formData[key] ? false : true;
        }

        if (formData.password != formData.confirmPassword) {
            formErrors.matchPasswords = true;
        }

        return formErrors;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="card bg-white p-3 w-100 mx-0">
            <form className="w-100 form-group m-0" onSubmit={handleCreateAccount}>
                <div className="w-100 d-flex flex-column align-items-center">
                    <span className="px-5 pb-4">Create your OQ Analyst account</span>
                    <div className="px-5 d-flex flex-column col-md-6">
                        <label
                            htmlFor="InputUsername"
                            className="text-uppercase form-font font-10"
                        >
                            USERNAME
                        </label>
                        <input
                            value={formData.username}
                            onChange={handleChange}
                            type="text"
                            name="username"
                            className={`form-control ${formErrors.username ? 'is-invalid' : null
                                }`}
                            id="InputUsername"
                            aria-describedby="nameHelp"
                            placeholder="Enter Username"
                        />
                        {formErrors.username && (
                            <div className="invalid-feedback">
                                Please enter your username.
                            </div>
                        )}
                    </div>
                    <div className="mt-2 px-5 d-flex flex-column col-md-6">
                        <PasswordInput
                            label={
                                <label
                                    htmlFor="InputPassword"
                                    className="text-uppercase form-font font-10"
                                >
                                    PASSWORD
                                </label>
                            }
                            value={formData.password}
                            onChange={handleChange}
                            type="password"
                            name="password"
                            className={`form-control ${formErrors.password ? 'is-invalid' : null
                                }`}
                            id="InputPassword"
                            placeholder="Enter Password"
                            errors={
                                <div className="invalid-feedback">Please enter a password.</div>
                            }
                        />
                    </div>
                    <div className="mt-2 px-5 d-flex flex-column col-md-6">
                        <PasswordInput
                            label={
                                <label
                                    htmlFor="ConfirmPassword"
                                    className="text-uppercase form-font font-10"
                                >
                                    CONFIRM PASSWORD
                                </label>
                            }
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            type="password"
                            name="confirmPassword"
                            className={`form-control ${formErrors.confirmPassword || formErrors.matchPasswords
                                ? 'is-invalid'
                                : null
                                }`}
                            id="ConfirmPassword"
                            placeholder="Confirm Your Password"
                            errors={
                                <>
                                    {formErrors.confirmPassword && (
                                        <div className="invalid-feedback">
                                            Re-enter your password.
                                        </div>
                                    )}
                                    {formErrors.matchPasswords && (
                                        <div className="invalid-feedback">
                                            Password does not match
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                    <div className="px-5 pt-3 pb-1 mx-0 justify-content-end d-flex col-md-6">
                        <button
                            key={'myButton'}
                            className="btn btn-primary primary-btn-color px-3 py-2 font-10"
                            onClick={handleCreateAccount}
                        >
                            <span>Create Account</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AccountCreationCard;