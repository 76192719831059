import PropTypes from 'prop-types';

import React from 'react';
import CheckBox from 'react-animated-checkbox';

/**
 *
 * FileUpload
 *
 */
import Dropzone from 'react-dropzone';

/* eslint-disable react/prefer-stateless-function */
const FileUpload = (props) => {
	const { upload, message, files } = props;
	return (
		<div>
			<section className="container" />
			{files.length === 0 ? (
				<Dropzone onDrop={(acceptedFiles) => upload(acceptedFiles)}>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p className="bg-mustard px-3 py-3 form-guideline-font">
									{message}
								</p>
							</div>
						</section>
					)}
				</Dropzone>
			) : (
				<div className="row">
					<CheckBox
						checked
						checkBoxStyle={{
							checkedColor: '#00DB06',
							size: 20,
						}}
						duration={400}
					/>
					<p className="pl-2">{files[0].name} has been selected.</p>
				</div>
			)}
		</div>
	);
};

FileUpload.propTypes = {
	upload: PropTypes.func.isRequired,
	messsage: PropTypes.any.isRequired,
	numberOfFiles: PropTypes.array.isRequired,
};

export default FileUpload;
