import { cloneDeep, isInteger, startCase } from 'lodash';

import React, { useReducer, useState } from 'react';
import {
	Modal,
	ModalBody,
	ModalHeader,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';

import classnames from 'classnames';
import RequestSentModal from 'components/RequestSentModal';
import loadIcon from 'images/load-icon.gif';

import CareGroup from './CareGroup';
import EmergencyContact from './EmergencyContact';
import Info from './Info';
import LevelOfCare from './LevelOfCare';

const keys = {
	information: [
		'name',
		'dob',
		'email',
		'phone',
		'maritalStatus',
		'gender',
		'address',
		'clientType',
		'referrer',
		'insurance',
		'startDate',
		'dischargeDate',
		'isActive',
	],
};

const initState = (data) => {
	const clone = cloneDeep(data);
	const {
		emergencyContacts = [],
		mainClinician = {},
		careGroup = { mtc: [], outside: [] },
		levelOfCare = [],
	} = clone;
	const state = {
		information: {},
		emergencyContacts: emergencyContacts,
		careGroup: { ...careGroup, mainClinician },
		cliniciansToDelete: { mtc: [], outside: [] },
		isDirty: false,
		showWarning: false,
		levelOfCare,
		locToDelete: [],
	};

	for (const id in keys) {
		const localKeys = keys[id];
		for (const key of localKeys) {
			state[id][key] = clone[key];
		}
	}

	return state;
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'HANDLE_CHANGE':
			return {
				...state,
				isDirty: true,
				[action.id]: {
					...state[action.id],
					[action.target.name]: action.target.value,
				},
			};
		case 'UPDATE':
			return {
				...state,
				isDirty: true,
				[action.id]: action.target.value,
			};
		case 'REMOVE_LOC':
			const newLOC = [...state.levelOfCare];
			const { id } = newLOC[action.target];
			newLOC.splice(action.target, 1);
			return {
				...state,
				isDirty: true,
				levelOfCare: newLOC,
				locToDelete: [...state.locToDelete, id],
			};
		case 'ADD_CONTACT':
			return {
				...state,
				emergencyContacts: [
					...state.emergencyContacts,
					{
						emergencyContact: '',
						relationship: '',
						homePhone: '',
						workPhone: '',
					},
				],
			};
		case 'REMOVE_CONTACT': {
			const newContacts = state.emergencyContacts;
			newContacts.splice(action.target, 1);
			return {
				...state,
				isDirty: true,
				emergencyContacts: newContacts,
			};
		}
		case 'ADD_CLINICIAN': {
			const group = state.careGroup[action.target.type] || [];
			group.push({
				id: null,
				name: '',
				type: '',
				phone: '',
				...action.target.clinician,
			});

			return {
				...state,
				isDirty: true,
				careGroup: {
					...state.careGroup,
					[action.target.type]: group,
				},
			};
		}
		case 'SELECT_CLINICIAN': {
			let updatedClinician = state.careGroup[action.target.type];
			if (action.target.index >= 0) {
				updatedClinician[action.target.index] = {
					...updatedClinician[action.target.index],
					...action.target.clinician,
				};
			} else {
				updatedClinician = {
					...updatedClinician,
					...action.target.clinician,
				};
			}

			return {
				...state,
				isDirty: true,
				careGroup: {
					...state.careGroup,
					[action.target.type]: updatedClinician,
				},
			};
		}
		case 'REMOVE_CLINICIAN': {
			const newGroup = state.careGroup[action.target.type];
			newGroup.splice(action.target.index, 1);

			return {
				...state,
				isDirty: true,
				careGroup: {
					...state.careGroup,
					[action.target.type]: newGroup,
				},
				cliniciansToDelete: {
					...state.cliniciansToDelete,
					[action.target.type]: [
						...state.cliniciansToDelete[action.target.type],
						action.target.id,
					],
				},
			};
		}
		case 'TOGGLE_WARNING':
			return {
				...state,
				showWarning: !state.showWarning,
			};
		case 'RESET':
			return initState(action.data);
		default:
			return state;
	}
};

const PatientProfileEditModal = (props) => {
	const {
		data,
		isOpen,
		toggle,
		cities,
		states,
		zipcodes,
		fetchCities,
		fetchZipcodes,
		clinicians,
		doSubmit,
	} = props;

	const tabs = [
		'information',
		'emergency_contacts',
		'care_providers',
		'level_of_care',
	];
	const [activeTab, setActiveTab] = useState(tabs[0]);
	const [state, dispatch] = useReducer(reducer, initState(data));
	const [isUpdating, setIsUpdating] = useState(false);
	const [showResetConfirm, setShowResetConfirm] = useState(false);

	const {
		information,
		emergencyContacts,
		careGroup,
		isDirty,
		showWarning,
		levelOfCare,
	} = state;

	const handleDispatch =
		(id) =>
		(target, type = 'HANDLE_CHANGE') => {
			dispatch({ type, id, target });
		};

	const renderTabContent = () => {
		let content;

		switch (activeTab) {
			case 'information':
				content = (
					<Info
						data={information}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						fetchCities={fetchCities}
						fetchZipcodes={fetchZipcodes}
						dispatch={handleDispatch('information')}
					/>
				);
				break;
			case 'emergency_contacts':
				content = (
					<EmergencyContact
						data={emergencyContacts}
						dispatch={handleDispatch('emergencyContact')}
					/>
				);
				break;
			case 'care_providers':
				content = (
					<CareGroup
						data={careGroup}
						dispatch={handleDispatch('careGroup')}
						clinicians={clinicians}
					/>
				);
				break;
			case 'level_of_care':
				content = (
					<LevelOfCare
						data={levelOfCare}
						dispatch={handleDispatch('levelOfCare')}
					/>
				);
				break;
			default:
				content = <div>Oops!</div>;
				break;
		}

		return content;
	};

	const toggleShowResetConfirm = () => {
		setShowResetConfirm(!showResetConfirm);
	};

	const reset = () => {
		dispatch({ type: 'RESET', data });
	};

	const handleSubmit = () => {
		if (!state.isDirty) return toggle();

		const dataToSubmit = data;

		for (const id in keys) {
			const localKeys = keys[id];
			for (const key of localKeys) {
				dataToSubmit[key] = state[id][key];
			}
		}

		dataToSubmit.careGroup = state.careGroup;
		dataToSubmit.mainClinician = state.careGroup.mainClinician;
		delete dataToSubmit.careGroup.mainClinician;
		dataToSubmit.emergencyContacts = state.emergencyContacts;

		let { levelOfCare, locToDelete } = state;
		levelOfCare = levelOfCare.filter((loc) => loc.type && loc.startDate);

		dataToSubmit.levelOfCare = levelOfCare;

		dataToSubmit.locToDelete = locToDelete.filter((id) => isInteger(id));

		if (!dataToSubmit.address.zipcode) {
			dataToSubmit.address.zipcode = '';
		}

		dataToSubmit.cliniciansToDelete = {
			mtc: state.cliniciansToDelete.mtc.filter((c) => c !== null),
			outside: state.cliniciansToDelete.outside.filter((c) => c !== null),
		};

		for (let clin of dataToSubmit.careGroup.outside) {
			if (clin.id && !clin.name && !clin.type) {
				dataToSubmit.cliniciansToDelete.outside.push(clin.id);
			}
		}

		// Append US country code to the phone value
		dataToSubmit.phone = '+1' + dataToSubmit.phone;

		setIsUpdating(true);

		doSubmit(dataToSubmit);
	};

	const handleToggle = () => {
		if (state.isDirty) {
			dispatch({ type: 'TOGGLE_WARNING' });
		} else {
			toggle();
		}
	};

	const isComplete = () => {
		return (
			state.information.name &&
			state.information.email &&
			state.information.address.street &&
			state.information.address.state &&
			state.information.address.city &&
			state.information.phone &&
			state.information.clientType &&
			state.information.gender
		);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={handleToggle}
			size="lg"
			className="h-100 edit-profile"
			cssModule={{
				modal: 'modal patient-profile',
			}}
		>
			<ModalHeader
				toggle={handleToggle}
				className="align-center border-0 container-fluid custom-bg-color pb-0"
				cssModule={{
					'modal-title':
						'd-flex justify-content-center w-100 pt-2 text-dark font-weight-bold',
				}}
			>
				<div style={{ fontSize: '28px' }}>UPDATE CLIENT INFORMATION</div>
			</ModalHeader>
			<ModalBody
				className="custom-bg-color"
				style={{ height: 'calc(100% - 60px)', overflow: 'hidden' }}
			>
				<Nav
					tabs
					className="actions m-0 shadow-none border-bottom custom-bg-color"
				>
					{tabs.map((tab) => (
						<NavItem key={tab}>
							<NavLink
								className={classnames(
									{
										active: activeTab === tab,
									},
									'font-13 hand bg-white',
								)}
								onClick={() => {
									setActiveTab(tab);
								}}
							>
								{startCase(tab)}
							</NavLink>
						</NavItem>
					))}
				</Nav>
				<div
					className="container-fluid bg-white p-3"
					style={{
						height: 'calc(100% - 120px)',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
				>
					{renderTabContent()}
				</div>
				<div>
					<div className="row mt-3">
						<div className="col-12">
							<div className="form-row justify-content-between">
								<div className="col">
									<button
										onClick={toggle}
										type="button"
										className="btn btn-white bg-white btn-lg btn-block link-color border font-15"
									>
										CANCEL
									</button>
								</div>
								<div className="col">
									<button
										onClick={toggleShowResetConfirm}
										type="button"
										className="btn btn-white bg-white btn-lg btn-block link-color border font-15"
										disabled={!isDirty}
									>
										RESET
									</button>
								</div>
								<div className="col">
									<button
										disabled={!isComplete()}
										onClick={handleSubmit}
										type="button"
										className="btn btn-primary btn-lg btn-block btn-color font-15"
									>
										{isUpdating ? (
											<img src={loadIcon} alt="Loading..." height="23px" />
										) : (
											<span>{isDirty ? 'SAVE' : 'DONE'}</span>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{showWarning && (
					<RequestSentModal
						mode="confirmation"
						successStatus={false}
						hide={() => dispatch({ type: 'TOGGLE_WARNING' })}
						handleDelete={toggle}
						message={
							<div>
								You have unsaved data! <br /> Are you sure you want to exit?
							</div>
						}
					/>
				)}
				{showResetConfirm && (
					<RequestSentModal
						mode="confirmation"
						successStatus={false}
						hide={toggleShowResetConfirm}
						handleDelete={reset}
						message={<div>This will reset all changes! Are you sure?</div>}
					/>
				)}
			</ModalBody>
		</Modal>
	);
};

export default PatientProfileEditModal;
