import PropTypes from 'prop-types';

import React, { useEffect, useRef, useState } from 'react';

import CommentPopover from 'components/CommentPopover';

import Circle from './Circle';
import './index.css';

const statusMap = {
	P: {
		value: 'present',
	},
	V: {
		value: 'virtual',
	},
	A: {
		value: 'absent',
	},
	E: {
		value: 'excused',
	},
	C: {
		value: 'clear',
	},
};

function generateCircleAngles(fanWidth, numTurns) {
	let angleStart = 180 + (180 - fanWidth) / 2;
	const angleEnd = angleStart + fanWidth;
	const angleDelta = (angleEnd - angleStart) / numTurns;
	for (let status in statusMap) {
		const block = statusMap[status];
		block.angle = angleStart;
		angleStart += angleDelta;
	}
}

function CircularMenu(props) {
	const [showMenu, setMenu] = useState(false);
	const [letter, setLetter] = useState('');
	const [comment, setComment] = useState(props.comment);
	const [showCommentPopover, setCommentPopover] = useState(false);
	const { handleChange, circleStyle } = props;

	const optionsRef = useRef(null);

	const fanWidth = 120;
	const numStatuses = 4;
	generateCircleAngles(fanWidth, numStatuses - 1);

	const { status } = props;
	useEffect(() => {
		const letter = status && status.length ? status[0].toUpperCase() : '';

		if (comment) {
			setComment(comment);
		}
		setLetter(letter);
	}, [status, comment]);

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});

	const handleClick = (e) => {
		const optionsDiv = optionsRef.current;

		if (showCommentPopover || (optionsDiv && optionsDiv.contains(e.target)))
			return;

		setMenu(false);
	};

	const handleShowMenu = () => {
		setMenu(true);
		setCommentPopover(false);
	};

	const handleChangeAtt = (newLetter) => {
		setMenu(false);

		if (letter === newLetter || (newLetter === 'C' && letter === '')) return;

		if (newLetter !== 'C') {
			setLetter(newLetter);
		} else {
			setLetter('');
		}

		handleChange(statusMap[newLetter].value);
	};

	const handleInput = (event) => {
		const { target } = event;
		const { value } = target;
		setComment(value);
	};

	const handleCommentSubmit = () => {
		setLetter('E');
		setMenu(false);
		handleChange(statusMap.E.value, comment);
	};

	const togglePopover = () => {
		setCommentPopover(!showCommentPopover);
	};

	const getPosition = (angle) => {
		const style = {
			transform: `rotate(${angle}deg) translate(50px) rotate(-${angle}deg)`,
		};

		return style;
	};

	return (
		<div
			className="circle-container"
			ref={optionsRef}
			style={{ position: 'relative' }}
		>
			<div className="center">
				<Circle
					handleClick={handleShowMenu}
					letter={letter === 'C' ? '' : letter}
					type="main"
					style={circleStyle}
				/>
			</div>
			{showMenu && (
				<div
					style={{
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-10px, 0px)',
						zIndex: 1050,
					}}
				>
					<div style={{ ...getPosition(statusMap['A'].angle), zIndex: 3 }}>
						<Circle
							letter="A"
							handleClick={handleChangeAtt}
							type="option"
							style={{ position: 'absolute' }}
						/>
					</div>
					<div style={{ ...getPosition(statusMap['P'].angle), zIndex: 3 }}>
						<Circle
							letter="P"
							handleClick={handleChangeAtt}
							type="option"
							style={{ position: 'absolute' }}
						/>
					</div>
					<div style={{ ...getPosition(statusMap['V'].angle), zIndex: 3 }}>
						<Circle
							letter="V"
							handleClick={handleChangeAtt}
							type="option"
							style={{ position: 'absolute' }}
						/>
					</div>
					<div style={{ ...getPosition(statusMap['E'].angle), zIndex: 3 }}>
						<Circle
							id="markExcused"
							letter="E"
							handleClick={togglePopover}
							type="option"
							style={{ position: 'absolute' }}
						/>
						<CommentPopover
							toggle={togglePopover}
							target="markExcused"
							isOpen={showCommentPopover}
							title="Reason for Excuse"
							comment={comment}
							handleInput={handleInput}
							handleSubmit={handleCommentSubmit}
							mode={props.mode}
						/>
					</div>
					<div style={{ ...getPosition(statusMap['C'].angle), zIndex: 3 }}>
						<Circle
							letter="C"
							handleClick={handleChangeAtt}
							type="option"
							style={{ position: 'absolute' }}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

CircularMenu.propTypes = {
	status: PropTypes.string,
	comment: PropTypes.string,
	mode: PropTypes.string,
	handleChange: PropTypes.func,
};

export default CircularMenu;
