import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the browseGroups state domain
 */

const selectBrowseGroupsDomain = (state) =>
	state.get('browseGroups', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by BrowseGroups
 */

const makeSelectBrowseGroups = () =>
	createSelector(selectBrowseGroupsDomain, (substate) => substate);

const makeSelectAllPrograms = () =>
	createSelector(selectBrowseGroupsDomain, (substate) =>
		returnJS(substate.get('programs')),
	);

const makeSelectSigningUrl = () =>
	createSelector(selectBrowseGroupsDomain, (substate) =>
		returnJS(substate.get('url')),
	);

const makeSelectResponse = () =>
	createSelector(selectBrowseGroupsDomain, (substate) =>
		returnJS(substate.get('message')),
	);

export default makeSelectBrowseGroups;
export {
	selectBrowseGroupsDomain,
	makeSelectAllPrograms,
	makeSelectSigningUrl,
	makeSelectResponse,
};
