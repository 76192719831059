import { Formik } from 'formik';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import NextButton from 'atoms/FormButtons/NextButton';
import PrevButton from 'atoms/FormButtons/PrevButton';
import ToggleSwitch from 'atoms/ToggleSwitch';
import IAccept from 'components/IAccept';
import LoadingIndicator from 'components/LoadingIndicator';
import RequestSentModal from 'components/RequestSentModal';
import useIsMobile from 'hooks/isMobile.ts';
import { saveUserPaymentApi } from 'utils/api';
import Input from 'v2/forms/Input';

import PaymentForm from './PaymentForm';
import {
	GuarantorValidation,
	PatientPaymentValidation,
	initialPaymentValues,
} from './constants';

export default function PatientPaymentIntake(props) {
	const {
		IOPChecked,
		handleAcceptance,
		IOPAgreementChecked,
		guarantorChecked,
		auth,
	} = props;

	const intakeData = useSelector((state) => state.get('patientIntake').toJS());
	const hasVaultId = Boolean(intakeData.vaultId);

	const [useVaultInformation, setUseVaultInformation] = useState(hasVaultId);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [useGuarantor, setUseGuarantor] = useState(guarantorChecked);

	const { name } = auth?.loggedInUser ?? {};

	const isMobile = useIsMobile();

	async function saveUserPaymentInfo(formValues) {
		const backendResp = await saveUserPaymentApi({
			...formValues,
		});
		setIsSubmitting(false);

		if (backendResp.success) {
			nextProgress(formValues);
		} else {
			setSubmitError(
				'Payment information could not be validated. Please contact an administrator',
			);
		}
	}

	function toggleUseVaultInformation() {
		setUseVaultInformation(!useVaultInformation);
	}

	function nextProgress(values) {
		const { nextProgress, auth, IOPChecked, IOPAgreementChecked } = props;

		const { guarantor } = values;
		const data = {};

		data.id = auth.loggedInUser.id;
		data.guarantor = useGuarantor ? guarantor : {};

		data.checks = {
			IOPAgreementChecked,
			IOPChecked,
			guarantorChecked: useGuarantor,
		};

		const keys = Object.getOwnPropertyNames(data);
		data.q = keys.join();

		nextProgress(data)();
	}

	function onSubmit(values) {
		if (useVaultInformation || useGuarantor) {
			nextProgress(values);
			return;
		}

		setIsSubmitting(true);
		window.CollectJS.startPaymentRequest();
	}

	const agreementInformation = (
		<div className="card d-flex flex-column px-5 bb-0">
			<span className="pt-5 pb-4">
				Fees for the first month of IOP are due at the time of admission. To
				obtain optimal clinical benefits three (3) months is suggested as a
				minimum commitment. If treatment is discontinued mid month, fees will be
				prorated based on the number of days in the enrolled month. Enrollment
				in the IOP is ongoing; you will be automatically charged for one month
				at the beginning of each renewal month. One week’s notice must be given
				before leaving the program. In the event a client is administratively
				discharged or abruptly terminates services, a refund for unused days of
				service will be made less an administrative fee equal to 25% of one
				month’s fees. Alternatively, families can opt to have the entire unused
				amount as a credit to be applied to a future course of treatment.
			</span>
			<span className="pt-1 pb-4 font-weight-bold">
				Insurance will not provide reimbursement for any missed IOP days. I
				understand I will be charged a fee as per the current daily fee for each
				missed IOP day, or for each partially completed IOP day as they cannot
				be billed to insurance.
			</span>
			<span className="pt-1 pb-4">
				If I do not agree with the billing provided in my billing statement, I
				understand that I have thirty (30) days from the date of the statement
				to report errors or objections. If there are problems with my account,
				it is my responsibility to contact Mind Therapy Clinic to address the
				problem or to discuss a workable solution. If a workable solution cannot
				be reached, then I agree to the arbitration process described in the
				Financial Agreement.
			</span>
			<span className="pt-1 pb-4">
				I agree to keep the credit card information current, and I authorize
				Mind Therapy Clinic to securely store my credit card information, and
				charge it should I have an outstanding balance in the future. If there
				is a remaining credit balance on any other account with Mind Therapy
				Clinic, I authorize transfer of those funds to cover any outstanding
				balance I may incur. My stored credit card may be processed
				automatically for outstanding balances unpaid by my healthcare insurance
				company.
			</span>
			<span className="pt-1 pb-4">
				<h4>LEAVE OF ABSENCE REQUESTS</h4>A written request for leave of absence
				is required for any absence. Due to the extensive programming and
				logistics involved with the programming, Mind Therapy Clinic requires a
				minimum of one (1) week advance notice. The notice can be provided by
				completing and submitting a Mind Therapy Clinic Leave of Absence Request
				form through the web app. A minimum duration for a leave of absence is
				one (1) full day. You are financially responsible for any unexcused
				absences. Discontinuation of treatment may also be submitted through the
				web app.
			</span>
			<span className="pt-1 pb-4">
				<h4>DISCONTINUATION OF TREATMENT</h4>
				To terminate treatment in the Intensive Outpatient Program against the
				advisement of your clinical team, the clinic requires a ONE (1) WEEK
				NOTICE submitted in writing including signature [not text or email] to
				the patient’s primary care therapist, case manager, family therapist,
				program director or clinical director. Verbal notice will not be
				considered as official notice.
			</span>

			<div className="form-row align-items-center justify-content-end">
				<div className="col-md-4 py-3">
					<IAccept
						handleAcceptance={handleAcceptance}
						name="IOPChecked"
						value={IOPChecked}
						isRequired
					/>
				</div>
			</div>
			<hr className="m-0" />
			<div>
				<h2 className="text-center mb-3 mt-5">Financially Responsible Party</h2>
			</div>
			<div>Please choose one of the following:</div>
			<div className="row mx-0 py-2">
				<div className="col-auto">
					<input
						type="checkbox"
						className="hand"
						id="noGuarantor"
						onChange={() => setUseGuarantor(false)}
						checked={!useGuarantor}
						value={!useGuarantor}
						name="noGuarantor"
					/>
				</div>
				<div>
					<label className="font-15" htmlFor="noGuarantor">
						I, {name}, am financially responsible for the treatment.
					</label>
				</div>
			</div>
			<div className={`row mx-0 pb-${guarantorChecked ? 2 : 4}`}>
				<div className="col-auto">
					<input
						type="checkbox"
						className="hand"
						id="guarantorChecked"
						onChange={() => setUseGuarantor(true)}
						checked={useGuarantor}
						value={useGuarantor}
						name="guarantorChecked"
					/>
				</div>
				<div>
					<label className="font-15" htmlFor="guarantorChecked">
						There is a guarantor or other financially responsible party.
					</label>
				</div>
			</div>
			{useGuarantor && (
				<>
					<div className="form-row align-items-center">
						<div className="col-md-4">
							<Input
								label="FINANCIALLY RESPONSIBLE PARTY NAME*"
								id="guarantor.name"
								name="guarantor.name"
								placeholder="John Doe"
							/>
						</div>
						<div className="col-md-4">
							<Input
								label="FINANCIALLY RESPONSIBLE PARTY EMAIL*"
								id="guarantor.email"
								name="guarantor.email"
								placeholder="johndoe@gmail.com"
							/>
						</div>
					</div>
					<div className="pb-4 font-italic">
						Mind Therapy Clinic will send a separate Financial Agreement to the
						party listed above.
					</div>
				</>
			)}
		</div>
	);

	return (
		<>
			<LoadingIndicator
				text="Verifying payment information..."
				isLoading={isSubmitting}
			/>
			<Formik
				initialValues={{
					...initialPaymentValues,
					guarantor: intakeData.guarantor,
				}}
				onSubmit={onSubmit}
				validationSchema={
					useGuarantor ? GuarantorValidation : PatientPaymentValidation
				}
				validateOnMount
			>
				{({ handleSubmit, isValid, values }) => (
					<div
						className={`container-fluid d-flex flex-column px-${
							isMobile ? 0 : 5
						}`}
					>
						<h2 className="text-center font-weight-bold mb-5 mt-5">
							Intensive Outpatient Program/Partial Hospitalization Program
							Agreement
						</h2>
						{agreementInformation}
						<hr className="m-0" />
						<div className="card d-flex flex-column p-5 mb-0 bt-0 bb-0">
							<h2 className="align-self-center mb-5">
								Credit Card Authorization Form
							</h2>
							<div>
								<div className="pt-1 pb-5 font-weight-bold">
									I understand, that there is a patient responsibility portion
									of the program cost, which is an estimate of the amount that
									my insurance plan may not cover.
								</div>
								<div className="pt-1 pb-5 font-weight-bold">
									I understand that the patient responsibility may change when
									the insurance reimbursement amount is determined.
								</div>
							</div>
							<div className="pt-1 pb-3 font-weight-bold">
								I understand that there are no cancellations or refunds for
								missed sessions for IOP services. I have been informed that
								insurance will not reimburse or cover missed days. I/we further
								understand clients are expected to comport themselves in
								accordance with a set of behavioral expectations in order to
								maintain enrollment in the Mind Therapy Clinic IOP.
							</div>
							<div className="col-md-12 form-group">
								<IAccept
									handleAcceptance={handleAcceptance}
									name="IOPAgreementChecked"
									value={IOPAgreementChecked}
									isRequired
								/>
							</div>
						</div>
						{!useGuarantor && (
							<div>
								<div
									className={`card d-flex flex-column p-4 pt-0 ${
										!useVaultInformation ? 'bb-0' : ''
									}`}
								>
									{hasVaultId && (
										<div>
											<div className="row mt-2">
												<div className="col-auto pr-0">
													<ToggleSwitch
														onClick={toggleUseVaultInformation}
														selected={useVaultInformation}
														className="mt-1"
													/>
												</div>
												<div className="col-auto">
													Use previously submitted payment information.
												</div>
											</div>
											{useVaultInformation && (
												<div>
													For your privacy and safety your previously submitted
													payment information has been hidden.
												</div>
											)}
										</div>
									)}
								</div>
								{!useVaultInformation && (
									<PaymentForm
										onSaveToken={saveUserPaymentInfo}
										defaultAddress={intakeData.address}
										display={!useVaultInformation}
									/>
								)}
							</div>
						)}
						<div className="d-flex mt-4 mb-5 justify-content-center">
							<div className="col-6">
								<PrevButton onClick={props.prevProgress} isMobile={isMobile} />
							</div>
							<div className="col-6">
								<NextButton
									isMobile={isMobile}
									onClick={() =>
										useGuarantor || useVaultInformation
											? onSubmit(values)
											: handleSubmit(values)
									}
									disabled={
										(!useVaultInformation && !isValid) ||
										!IOPChecked ||
										!IOPAgreementChecked
									}
								/>
							</div>
						</div>
					</div>
				)}
			</Formik>
			{submitError && (
				<RequestSentModal
					message={submitError}
					hide={() => setSubmitError('')}
				/>
			)}
		</>
	);
}
