import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-validation/build/form';

import PrevNextButtons from 'atoms/PrevNextButtons';
import { STEPS } from 'pages/intake/constants';

import BackgroundInformation from './BackgroundInformation';
import SymptomInformation from './SymptomInformation';

const AdhdInfo = (props) => {
	const { prevProgress, nextProgress, data, handleChange, handleCheck } = props;
	const {
		previouslyADHDEvaluated,
		evaluationDetails,
		adhdSymptoms,
		symptomsAtHome,
		symptomsAtSchool,
		symptomsAtRelation,
		symptomsDuration,
		symptomsPresence,
		symptomsPresenceDetails,
		childDevelopmentMilestones,
		childBehaviors,
	} = data;
	return (
		<div className="container-fluid d-flex flex-column px-5">
			<h2 className="align-self-center font-weight-bold mb-5 mt-5">
				ADD/ADHD Symptom Information
			</h2>
			<Form onSubmit={nextProgress({}, STEPS.ChildAdhdInfo)}>
				<SymptomInformation
					data={{
						previouslyADHDEvaluated,
						evaluationDetails,
						adhdSymptoms,
						symptomsAtHome,
						symptomsAtSchool,
						symptomsAtRelation,
						symptomsDuration,
						symptomsPresence,
						symptomsPresenceDetails,
					}}
					handleChange={handleChange(STEPS.ChildAdhdInfo)}
					handleCheck={handleCheck}
				/>

				<BackgroundInformation
					data={{ childDevelopmentMilestones, childBehaviors }}
					handleCheck={handleCheck}
				/>
				<PrevNextButtons
					prevProgress={prevProgress}
					className="mt-4 mb-5"
					isLastPage={false}
				/>
			</Form>
		</div>
	);
};

AdhdInfo.propTypes = {
	prevProgress: PropTypes.func.isRequired,
	nextProgress: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleCheck: PropTypes.func.isRequired,
};

export default AdhdInfo;
