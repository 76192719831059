import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the patientRegister state domain
 */

const selectPatientRegisterDomain = (state) =>
	state.get('patientRegister', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PatientRegister
 */

const makeSelectPatientRegister = () =>
	createSelector(selectPatientRegisterDomain, (substate) => returnJS(substate));

export default makeSelectPatientRegister;
export { selectPatientRegisterDomain };
