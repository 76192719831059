import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';

const LoadingModal = (props) => {
	const { isOpen, text } = props;

	return (
		<Modal isOpen={isOpen} className="modal-dialog-centered" size="sm">
			<ModalBody>
				<SmallLoadingIndicator
					color="var(--main-color)"
					text={text || 'Loading...'}
				/>
			</ModalBody>
		</Modal>
	);
};

export default LoadingModal;
