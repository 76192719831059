import moment from 'moment';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { headerStyle } from 'containers/App/constants';
import { compareDates } from 'utils/columnCompare';

const columns = (actions) => [
	{
		field: 'client',
		title: 'CLIENT',
		headerStyle,
		render: (row) => (
			<div
				className="font-13 my-1 hand"
				onClick={() => actions.goToProfile(row.patient.id)}
				style={{
					whiteSpace: 'nowrap',
					overflowX: 'hidden',
					overflowY: 'hidden',
				}}
			>
				<ImageDetailsCell
					src={row.patient.profilePicture}
					heading={row.patient.name}
					primarySubHeading={row.patient.type}
				/>
			</div>
		),
	},
	{
		field: 'date',
		title: 'DATE',
		headerStyle,
		render: (row) => (
			<div className="font-13 my-2 ellipsis">
				{moment(row.attendance.date).format('YYYY-MM-DD')}
			</div>
		),
		customSort: (a, b) =>
			compareDates(a.attendance.date, b.attendance.date, 'desc'),
	},
	{
		field: 'programName',
		title: 'THERAPY',
		headerStyle,
		render: (row) => <div className="font-13 my-2">{row.program.name}</div>,
	},
];

export default columns;
