// import components
import PropTypes from 'prop-types';

import TextArea from 'atoms/TextArea';

/**
 *
 * <ChildhoodHistory />, a functional component for Child Intake Evaluation
 */
const ChildhoodHistory = (props) => {
	const { data, handleChange } = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			{/* <h3>Childhood/Cultural History</h3> */}

			<TextArea
				className="mb-4"
				label="PLEASE DESCRIBE YOUR CHILD'S FAMILY LIFE, INCLUDE WHETHER THE FAMILY IS INTACT, PARENTS ARE DIVORCED AND IF SO, HOW OLD YOUR CHILD WAS, AND CUSTODY ARRANGEMENTS, etc."
				id="childFamilyLife"
				value={data.childFamilyLife}
				handleInput={handleChange}
			/>

			<TextArea
				className="mb-4"
				label="PLEASE TELL US WHO LIVES WITH THE CHILD INCLUDING THE NAMES, RELATIONSHIP TO THE CHILD, AND AGE OF EACH PERSON:"
				id="peopleLivingWithChild"
				value={data.peopleLivingWithChild}
				handleInput={handleChange}
			/>
		</div>
	);
};

ChildhoodHistory.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default ChildhoodHistory;
