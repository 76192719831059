import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
// import components
import TextArea from 'atoms/TextArea';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <LegalHistory />, a functional component for Child Intake Evaluation
 */
const LegalHistory = (props) => {
	const { data, handleChange } = props;

	return (
		<div className="card d-flex flex-column p-4 mb-4">
			<h3>Legal History</h3>

			<div className="row mt-3 mb-3">
				<label htmlFor="hasLegalHistory" className="form-font col-8">
					HAS YOUR CHILD EVER BEEN INVOLVED WITH THE LEGAL SYSTEM?
				</label>
				<SelectButtonGroup
					id="hasLegalHistory"
					value={data.hasLegalHistory}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className="col-4"
				/>
			</div>
			{data.hasLegalHistory === 'yes' && (
				<TextArea
					label="IF YES, PLEASE EXPLAIN:"
					id="legalHistoryDetails"
					value={data.legalHistoryDetails}
					handleInput={handleChange}
				/>
			)}

			<div className="row mt-3 mb-3">
				<label htmlFor="presentlyOnProbation" className="form-font col-8">
					IS YOUR CHILD PRESENTLY ON DIVERSION OR PROBATION?
				</label>
				<SelectButtonGroup
					id="presentlyOnProbation"
					value={data.presentlyOnProbation}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className="col-4"
				/>
			</div>
			{data.presentlyOnProbation === 'yes' && (
				<TextArea
					label="IF YES, WHAT ARE THE REQUIREMENTS OF THE DIVERSION OR PROBATION?"
					id="probationRequirements"
					value={data.probationRequirements}
					handleInput={handleChange}
				/>
			)}
		</div>
	);
};

LegalHistory.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default LegalHistory;
