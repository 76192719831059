import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the groupDetail state domain
 */

const selectGroupDetailDomain = (state) =>
	state.get('groupDetail', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by GroupDetail
 */

const makeSelectGroupDetail = () =>
	createSelector(selectGroupDetailDomain, (substate) => returnJS(substate));

const makeSelectGetAttendance = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('attendance')),
	);

const makeSelectGetProgram = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('program')),
	);

const makeSelectGetClients = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('clients')),
	);

const makeSelectGetSessions = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('sessions')),
	);

const makeSelectReloadAttendance = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('reloadAttendance')),
	);

const makeSelectUpdateRequestSent = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('updateRequestSent')),
	);

const makeSelectUpdateRequestSuccess = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('updateSuccess')),
	);

const makeSelectUpdateRequestError = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('updateError')),
	);

const makeSelectAllClients = () =>
	createSelector(selectGroupDetailDomain, (substate) =>
		returnJS(substate.get('allClients')),
	);

export default makeSelectGroupDetail;
export {
	selectGroupDetailDomain,
	makeSelectGetAttendance,
	makeSelectGetSessions,
	makeSelectGetClients,
	makeSelectGetProgram,
	makeSelectReloadAttendance,
	makeSelectUpdateRequestSent,
	makeSelectUpdateRequestError,
	makeSelectUpdateRequestSuccess,
	makeSelectAllClients,
};
