import styled from 'styled-components';

export const ContextMenuButton = styled.button`
	font-size: 18px;
	width: 200px;
	margin: 4px 0px;

	:hover {
		background: ${(props) => props.hoverColor || 'var(--main-color)'};
		color: white;
	}
`;

export const ToolbarButton = styled.div`
	border: 1px solid
		${(props) =>
			props.active ? 'var(--main-color)' : 'var(--main-light-grey)'};
	border-radius: 5px;

	:hover {
		border: 1px solid
			${(props) => (props.active ? 'var(--main-color)' : 'var(--main-grey)')};
	}
`;

export const PageInput = styled.input`
	border: 1px solid var(--main-light-grey);
	border-radius: 5px;

	:hover {
		border: 1px solid var(--main-grey);
	}

	:focus {
		border: 1px solid var(--main-color);
	}
`;
