import PropTypes from 'prop-types';

import SelectButtonGroup from 'atoms/SelectButtonGroup';
import TextArea from 'atoms/TextArea';
import { generateObjectArray } from 'utils/helpers';

/**
 *
 * <HeadInjuries />, a functional component for Adult Intake Evaluation
 */
const HeadInjuries = (props) => {
	const {
		headInjuries,
		headInjuryArea,
		amnesiaAfterHeadInjury,
		moodChangeAfterHeadInjury,
		moodChangeAfterHeadInjuryDetails,
		hospitalizedAfterHeadInjury,
		hospitalizedAfterHeadInjuryDetails,
		scanPerformed,
		scanDetails,
		handleChange,
		isMobile,
	} = props;

	return (
		<div>
			<h6>Head Injuries</h6>
			<div className="mb-2 row">
				<label
					htmlFor="headInjuries"
					className={`form-font col-${isMobile ? 12 : 8}`}
				>
					HAVE YOU EVER HAD ANY HEAD INJURY, SPORTS INJURY TO THE HEAD, FALLS,
					CONCUSSIONS OR CAR ACCIDENTS ?
				</label>
				<SelectButtonGroup
					id="headInjuries"
					value={headInjuries}
					buttons={generateObjectArray(['Yes', 'No'])}
					handleSelect={handleChange}
					className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
				/>
			</div>
			{headInjuries === 'yes' && (
				<div>
					<TextArea
						className="mb-4"
						label="IF YES, PLEASE DESCRIBE WHERE ON THE HEAD THE INJURY OCCURED AND AT WHAT AGE:"
						id="headInjuryArea"
						value={headInjuryArea}
						handleInput={handleChange}
					/>
					<div className="mb-2 row">
						<label
							htmlFor="amnesiaAfterHeadInjury"
							className={`form-font col-${isMobile ? 12 : 8}`}
						>
							DID YOU EXPERIENCE AMNESIA OR LOSE CONSCIOUSNESS AFTER THE INJURY
							?
						</label>
						<SelectButtonGroup
							id="amnesiaAfterHeadInjury"
							value={amnesiaAfterHeadInjury}
							buttons={generateObjectArray(['Yes', 'No', "Don't Know"])}
							handleSelect={handleChange}
							className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
						/>
					</div>

					<div className="mb-2 row">
						<label
							htmlFor="moodChangeAfterHeadInjury"
							className={`form-font col-${isMobile ? 12 : 8}`}
						>
							HAS THERE BEEN ANY CHANGE IN MOOD OR MEMORY SINCE THE HEAD TRAUMA
							OCCURED ?
						</label>
						<SelectButtonGroup
							id="moodChangeAfterHeadInjury"
							value={moodChangeAfterHeadInjury}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange}
							className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
						/>
					</div>
					{moodChangeAfterHeadInjury === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE DESCRIBE THE CHANGE IN MOOD OR MEMORY:"
							id="moodChangeAfterHeadInjuryDetails"
							value={moodChangeAfterHeadInjuryDetails}
							handleInput={handleChange}
						/>
					)}

					<div className="mb-2 row">
						<label
							htmlFor="hospitalizedAfterHeadInjury"
							className={`form-font col-${isMobile ? 12 : 8}`}
						>
							WERE YOU HOSPITALIZED FOR THE HEAD INJURY ?
						</label>
						<SelectButtonGroup
							id="hospitalizedAfterHeadInjury"
							value={hospitalizedAfterHeadInjury}
							buttons={generateObjectArray(['Yes', 'No'])}
							handleSelect={handleChange}
							className={`mb-2 ${isMobile ? 'row mx-3' : 'col-4'}`}
						/>
					</div>
					{hospitalizedAfterHeadInjury === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, PLEASE PROVIDE DETAILS OF THE HOSPITALIZATION:"
							id="hospitalizedAfterHeadInjuryDetails"
							value={hospitalizedAfterHeadInjuryDetails}
							handleInput={handleChange}
						/>
					)}

					<div className="mb-2 row">
						<label
							htmlFor="scanPerformed"
							className={`form-font col-${isMobile ? 12 : 6}`}
						>
							WAS ANY TYPE OF SCAN PERFORMED (CAT, MRI, ECG, etc.) ?
						</label>
						<SelectButtonGroup
							id="scanPerformed"
							value={scanPerformed}
							buttons={generateObjectArray(['Yes', 'No', "Don't know"])}
							handleSelect={handleChange}
							className={`mb-2 ${isMobile ? 'row mx-3' : 'col-6'}`}
						/>
					</div>
					{scanPerformed === 'yes' && (
						<TextArea
							className="mb-4"
							label="IF YES, WHAT DID IT SHOW?:"
							id="scanDetails"
							value={scanDetails}
							handleInput={handleChange}
						/>
					)}
				</div>
			)}
		</div>
	);
};

HeadInjuries.propTypes = {
	headInjuries: PropTypes.string.isRequired,
	headInjuryArea: PropTypes.string.isRequired,
	amnesiaAfterHeadInjury: PropTypes.string.isRequired,
	moodChangeAfterHeadInjury: PropTypes.string.isRequired,
	moodChangeAfterHeadInjuryDetails: PropTypes.string.isRequired,
	hospitalizedAfterHeadInjury: PropTypes.string.isRequired,
	hospitalizedAfterHeadInjuryDetails: PropTypes.string.isRequired,
	scanPerformed: PropTypes.string.isRequired,
	scanDetails: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default HeadInjuries;
