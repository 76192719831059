import history from 'utils/history';

import _ from 'lodash';

/**
 *
 * SignUp
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import PasswordInput from 'atoms/PasswordInput';
import VerificationModal from 'components/Verification';
import { generateToast } from 'containers/App/actions';
import {
	makeSelectIsMobile,
	makeSelectLocation,
} from 'containers/App/selectors';
import loadIcon from 'images/load-icon.gif';
import logoLogin from 'images/logo_login.svg';

import zxcvbn from 'zxcvbn';

import {
	checkUserExist,
	phoneValidation,
	sendOtp,
	showError,
	storeData,
	toggleVerification,
	validateOtp,
} from './actions';
import './index.css';
import makeSelectSignUp from './selectors';

export class SignUp extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			email: '',
			phone: '',
			password: '',
			confirmPassword: '',
			errorName: false,
			errorEmail: false,
			errorPhone: false,
			errorPassword: false,
			errorConfirmPassword: false,
			errorPasswordMatch: false,
			errorPasswordWeak: false,
			errorMessage: '',
			showLoading: false,
			toggleOtp: true,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { createToast } = this.props;
		const { errorMessage, showVerificationModal } = nextProps.signUp;

		if (this.state.errorMessage === '' || this.state.errorMessage === null) {
			if (errorMessage && errorMessage.includes('Otp')) {
				createToast({
					type: 'error',
					text: 'Incorrect verification code',
				});
			}

			this.setState({
				errorMessage,
				showLoading: false,
			});
		}
		if (showVerificationModal) {
			this.setState({
				showLoading: false,
			});
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });

		this.setState({ errorMessage: '' });
	};

	handleClick = () => {
		const { toggleOtp } = this.state;
		this.setState({ toggleOtp: !toggleOtp });
	};

	formValidation = () => {
		// eslint-disable-next-line
		const emailReg = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/m;
		let errorName = false;
		let errorEmail = false;
		let errorPhone = false;
		let errorPassword = false;
		let errorConfirmPassword = false;
		let errorPasswordMatch = false;
		let errorPasswordWeak = false;
		const { name, email, phone, password, confirmPassword } = this.state;
		// eslint-disable-next-line no-unused-expressions
		!name ? (errorName = true) : (errorName = false);
		if (!email) {
			errorEmail = true;
		} else if (emailReg.test(email)) {
			errorEmail = false;
		} else {
			errorEmail = true;
		}
		// eslint-disable-next-line no-unused-expressions
		!password ? (errorPassword = true) : (errorPassword = false);
		if (password && zxcvbn(password).score < 3) {
			errorPasswordWeak = true;
		}
		// eslint-disable-next-line no-unused-expressions
		!confirmPassword
			? (errorConfirmPassword = true)
			: (errorConfirmPassword = false);
		// eslint-disable-next-line no-unused-expressions
		if (!phone) {
			errorPhone = true;
		} else if (phoneReg.test(phone)) {
			errorPhone = false;
		} else {
			errorPhone = true;
		}
		if (password && confirmPassword && password !== confirmPassword) {
			errorPasswordMatch = true;
		}
		this.setState({
			errorName,
			errorEmail,
			errorPhone,
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
			errorPasswordWeak,
		});
		if (
			errorName ||
			errorEmail ||
			errorPhone ||
			errorPassword ||
			errorConfirmPassword ||
			errorPasswordMatch ||
			errorPasswordWeak
		) {
			return true;
		}
		return false;
	};

	submitForm = (event) => {
		event.preventDefault();
		const isFormValidated = this.formValidation();
		const { name, email, phone, password, confirmPassword, toggleOtp } =
			this.state;
		const { storeInput, checkUser } = this.props;

		if (isFormValidated) {
			return;
		}

		const data = {
			name: name.trim(),
			email: email.trim(),
			phone,
			password,
			confirmPassword,
			toggleOtp,
		};
		this.setState({
			showLoading: true,
		});
		storeInput(data);
		checkUser();
	};

	getRole = () => {
		const { location } = this.props;
		const role = location.pathname.split('/');

		return role[1];
	};

	moveToSignIn = () => {
		history.push('/signin');
	};

	switchSignUp = () => {
		const role = this.getRole();

		if (role === 'patient') {
			history.push('/clinician/signup');
		} else {
			history.push('/patient/signup');
		}
	};

	render() {
		const {
			name,
			email,
			phone,
			password,
			confirmPassword,
			errorName,
			errorEmail,
			errorPhone,
			errorPassword,
			errorConfirmPassword,
			errorPasswordMatch,
			errorPasswordWeak,
			errorMessage,
			showLoading,
		} = this.state;

		const {
			signUp,
			sendOtpFunc,
			phoneValidationFunc,
			validateOtpFunc,
			toggle,
			storeInput,
			checkUser,
			displayError,
			isMobile,
		} = this.props;

		const passwordDetails = zxcvbn(password);

		const role = this.getRole();
		let article = 'a';
		if (role === 'admin') {
			article = 'an';
		}

		let strColor = 'red';
		let strWidth = '0%';
		switch (passwordDetails.score) {
			case 0:
			  strColor = 'red';
			  strWidth = '1%';
			  break;
			case 1:
			  strColor = 'orange';
			  strWidth = '25%';
			  break;
			case 2:
			  strColor = 'yellow';
			  strWidth = '50%';
			  break;
			case 3:
			  strColor = '#5cff47';
			  strWidth = '75%';
			  break;
			case 4:
			  strColor = 'green';
			  strWidth = '100%';
			  break;
			default:
		  }
		
		  var passwordBarStyle =
		  	password ? { backgroundColor: strColor, height: '5px', width: strWidth, transition: 'all 300ms ease-in-out' }
			: { height: '5px', width: '0%' };

		return (
			<div className="container-fluid p-0 d-flex h-100vh bg-white overflow-hidden">
				{!isMobile && (
					<div className="p-0 image-login-container container-fluid" />
				)}
				<div
					className={`${
						isMobile ? 'col' : 'col-6'
					} d-flex align-self-center flex-column px-xl-5 overflow-auto h-100`}
				>
					<div className="d-flex justify-content-center mb-4">
						<img src={logoLogin} alt="" />
					</div>
					{!signUp.showVerificationModal && (
						<div className="d-flex justify-content-center error-font">
							{errorMessage}
						</div>
					)}
					<div className="mt-2 px-5 d-flex justify-content-center text-center w-100 font-30 font-weight-bold">
						{_.startCase(role)} SignUp
					</div>
					<div className="mt-2 px-5 d-flex justify-content-center already-have-an-acco font-13">
						Not {article} {role}?
						<span
							className="ml-1 link-color font-weight-bold hand"
							onClick={this.switchSignUp}
						>
							Click Here
						</span>
					</div>
					<form className="w-100" onSubmit={this.submitForm}>
						<div className="px-5">
							<label
								htmlFor="InputName"
								className="text-uppercase form-font font-10"
							>
								NAME
							</label>
							<input
								value={name}
								onChange={this.handleChange}
								type="text"
								name="name"
								className={`form-control ${errorName ? 'is-invalid' : null}`}
								id="InputName"
								aria-describedby="nameHelp"
								placeholder="Enter Name"
							/>
							{errorName && (
								<div className="invalid-feedback">Please enter your name.</div>
							)}
						</div>
						<div className="mt-2 px-5">
							<label
								htmlFor="InputEmail"
								className="text-uppercase form-font font-10"
							>
								EMAIL
							</label>
							<input
								value={email}
								onChange={this.handleChange}
								type="email"
								name="email"
								className={`form-control ${errorEmail ? 'is-invalid' : null}`}
								id="InputEmail"
								aria-describedby="emailHelp"
								placeholder="Enter Email"
							/>
							{errorEmail && (
								<div className="invalid-feedback">Invalid email address.</div>
							)}
						</div>
						<div className="mt-2 px-5">
							<label
								htmlFor="phone"
								className="text-uppercase form-font font-10"
							>
								MOBILE NUMBER
							</label>
							<PhoneInput
								maxLength={20}
								placeholder="Enter Mobile Number"
								value={phone}
								international={false}
								country="US"
								className={`form-control ${errorPhone ? 'is-invalid' : null}`}
								onChange={(number) => this.setState({ phone: number })}
							/>
							{errorPhone && (
								<div className="invalid-feedback">Invalid phone number.</div>
							)}
						</div>
						<div className="mt-2 px-5">
							<PasswordInput
								label={
									<label
										htmlFor="InputPassword"
										className="text-uppercase form-font font-10"
									>
										PASSWORD
									</label>
								}
								value={password}
								onChange={this.handleChange}
								type="password"
								name="password"
								className={`form-control ${
									errorPassword ? 'is-invalid' : null
								}`}
								id="InputPassword"
								placeholder="Enter Password"
							/>
							<div style={passwordBarStyle}/>
							{errorPassword && (
								<div className="invalid-feedback d-block">Please enter a password.</div>
							)}
							{errorPasswordWeak && passwordDetails.score < 3 && (
								<div className="invalid-feedback d-block">
									Please enter a stronger password.
									{passwordDetails.feedback.suggestions?.map((suggestion, i) => (
										<div key={i}>{suggestion}</div>
									))}
								</div>
							)}
						</div>
						<div className="mt-2 px-5">
							<PasswordInput
								label={
									<label
										htmlFor="ConfirmPassword"
										className="text-uppercase form-font font-10"
									>
										CONFIRM PASSWORD
									</label>
								}
								value={confirmPassword}
								onChange={this.handleChange}
								type="password"
								name="confirmPassword"
								className={`form-control ${
									errorConfirmPassword || errorPasswordMatch
										? 'is-invalid'
										: null
								}`}
								id="ConfirmPassword"
								placeholder="Confirm Your Password"
							/>
							{errorConfirmPassword && (
								<div className="invalid-feedback d-block">Re-enter your password.</div>
							)}
							{errorPasswordMatch && (
								<div className="invalid-feedback d-block">Password does not match</div>
							)}
						</div>
						<div className="mt-3 px-5 text-right">
							<label
								className="text-uppercase form-font font-10"
								htmlFor="toggleOtp"
							>
								Proceed To Verify Mobile&nbsp;
							</label>
							<span className="switch">
								<input
									name="toggleOtp"
									type="checkbox"
									checked={this.state.toggleOtp}
									onChange={this.handleClick}
								/>
								<span className="slider round" onClick={this.handleClick} />
							</span>
							<label className="form-font font-4" htmlFor="toggleOtp">
								By checking above, you agree to receive text messages
								from Mind Therapy Clinic for purposes related to account creation
								and treatment. Message/data rates apply. Consent is not a condition
								of admission for treatment.
								<br/>
								For more information, see our&nbsp;
								<a
									href="https://www.mindtherapyclinic.com/text-messaging-terms-conditions"
									rel="noopener"
								>
									Terms and Conditions
								</a>
								.
							</label>
						</div>

						<div className="d-flex justify-content-between mt-3 px-5">
							<button
								onClick={this.submitForm}
								type="submit"
								className="btn btn-primary btn-lg btn-block btn-color text-uppercase font-15"
							>
								{showLoading ? (
									<img src={loadIcon} alt="Loading..." height="23px" />
								) : (
									<span>SIGN UP</span>
								)}
							</button>
						</div>
						{signUp.showVerificationModal ? (
							<VerificationModal
								hide={toggle}
								sendOtp={sendOtpFunc}
								phoneValidation={phoneValidationFunc}
								storeData={storeInput}
								checkUser={checkUser}
								signUp={signUp}
								validateOtp={validateOtpFunc}
								displayError={displayError}
							/>
						) : null}
						<div className="mt-4 px-5 d-flex justify-content-center already-have-an-acco font-13">
							Already have an account?
							<span
								className="ml-1 link-color font-weight-bold hand"
								onClick={this.moveToSignIn}
							>
								Sign In
							</span>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

SignUp.propTypes = {
	location: PropTypes.object.isRequired,
	signUp: PropTypes.object.isRequired,
	toggle: PropTypes.func.isRequired,
	sendOtpFunc: PropTypes.func.isRequired,
	phoneValidationFunc: PropTypes.func.isRequired,
	validateOtpFunc: PropTypes.func.isRequired,
	storeInput: PropTypes.func.isRequired,
	checkUser: PropTypes.func.isRequired,
	displayError: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	signUp: makeSelectSignUp(),
	location: makeSelectLocation(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		toggle: () => dispatch(toggleVerification()),
		checkUser: () => dispatch(checkUserExist()),
		storeInput: (name, email, phone, password, confirmPassword) =>
			dispatch(storeData(name, email, phone, password, confirmPassword)),
		sendOtpFunc: () => dispatch(sendOtp()),
		phoneValidationFunc: (isValidNumber) =>
			dispatch(phoneValidation(isValidNumber)),
		validateOtpFunc: (code) => dispatch(validateOtp(code)),
		displayError: (message) => dispatch(showError(message)),
		createToast: (message) => dispatch(generateToast(message)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
