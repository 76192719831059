import PropTypes from 'prop-types';

import React, { useState } from 'react';

import styled from 'styled-components';

import Popup from 'atoms/Popup';
import CommentPopover from 'components/CommentPopover';

import { actionButtonStyle } from './style';

const Button = styled.button`
	width: 40px;
	height: 40px;

	svg,
	polygon {
		fill: ${(props) => props.fillColor};
	}

	path {
		stroke: ${(props) => props.fillColor};
	}

	&:hover svg,
	&:hover polygon {
		fill: ${(props) => props.hoverColor};
	}

	&:hover path {
		stroke: ${(props) => props.hoverColor};
	}
`;

const ActionButtonWithComment = (props) => {
	const {
		image,
		hoverColor = 'var(--main-color)',
		fillColor = 'var(--main-grey)',
		alt = '',
		buttonStyle,
		popupStyle,
		title,
		handleSubmit,
		initComment = '',
		mode = 'edit',
		id,
	} = props;
	const [showComment, setShowComment] = useState(false);
	const [comment, setComment] = useState(initComment);
	const [showPopup, setShowPopup] = useState(false);

	const toggleComment = () => setShowComment(!showComment);

	const handleInput = (e) => {
		setComment(e.target.value);
	};
	const onSubmit = () => {
		handleSubmit(comment);
	};

	return (
		<div style={{ position: 'relative' }}>
			<Button
				onClick={toggleComment}
				hoverColor={hoverColor}
				fillColor={fillColor}
				onMouseEnter={() => setShowPopup(true)}
				onMouseLeave={() => setShowPopup(false)}
				style={buttonStyle}
				id={`customButton${id}`}
			>
				{image}
			</Button>
			{alt && (
				<Popup
					isOpen={showPopup}
					text={alt}
					style={actionButtonStyle(showPopup, popupStyle)}
					noTri
				/>
			)}
			<CommentPopover
				toggle={toggleComment}
				target={`customButton${id}`}
				isOpen={showComment}
				title={title}
				comment={comment}
				handleInput={handleInput}
				handleSubmit={onSubmit}
				mode={mode}
			/>
		</div>
	);
};

ActionButtonWithComment.propTypes = {
	image: PropTypes.element,
	onClick: PropTypes.func,
	hoverColor: PropTypes.string,
	fillColor: PropTypes.string,
	alt: PropTypes.string,
	buttonStyle: PropTypes.object,
	popupStyle: PropTypes.object,
	// placeholderText: PropTypes.string,
};

export default ActionButtonWithComment;
