import { startCase } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomDatePicker from 'atoms/CustomDatePicker';

const UpdateEnrollmentModal = (props) => {
	const { isOpen, toggle, programs, client, onSubmit, multi, title } = props;
	const [startDates, setStartDates] = useState([]);
	const [endDates, setEndDates] = useState([]);

	useEffect(() => {
		const { startDate, endDate } = client;

		const newEndDates = [];
		const newStartDates = [];

		programs.forEach((p) => {
			let newStartDate = p.startDate || startDate;
			let newEndDate = p.endDate || endDate;

			newStartDates.push(
				newStartDate
					? moment(newStartDate).format('MM-DD-YYYY')
					: moment().format('MM-DD-YYYY'),
			);
			newEndDates.push(
				newEndDate ? moment(newEndDate).format('MM-DD-YYYY') : null,
			);
		});

		setStartDates(newStartDates);
		setEndDates(newEndDates);
	}, [client, programs]);

	const handleDateChange = (label, date, i = 0) => {
		if (label === 'endDate') {
			const dateCopy = Array.from(endDates);
			if (!date) {
				dateCopy[i] = null;
			} else {
				dateCopy[i] = moment(date).format('MM-DD-YYYY');
			}
			setEndDates(dateCopy);
		} else {
			const dateCopy = Array.from(startDates);
			dateCopy[i] = moment(date).format('MM-DD-YYYY');

			setStartDates(dateCopy);
		}
	};

	const doUpdate = () => {
		let sDates = startDates.filter((s) => s);
		let eDates = endDates.filter((e) => e);

		onSubmit(sDates, eDates);
	};

	const popperModifiers = {
		flip: {
			behavior: ['bottom'], // don't allow it to flip to be above
		},
		preventOverflow: {
			enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
		},
		hide: {
			enabled: false, // turn off since needs preventOverflow to be enabled
		},
	};

	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal-dialog-centered"
				backdrop="static"
				size={multi ? 'lg' : 'md'}
			>
				<ModalHeader
					toggle={toggle}
					className="align-center border-0 container-fluid custom-bg-color"
					cssModule={{
						'modal-title':
							'd-flex justify-content-center w-100 pt-2 text-dark font-weight-bold',
					}}
				>
					<div style={{ fontSize: '28px' }}>
						{title ? title : `Update ${startCase(client.name)}'s Enrollment`}
					</div>
				</ModalHeader>
				<ModalBody className="custom-bg-color py-0">
					<div className="row custom-bg-color">
						<div className="container-fluid bg-white mx-4 mb-4 pt-3">
							{multi ? (
								programs.map((p, i) => (
									<div className="form-group" key={`program-${i}`}>
										<div className="my-auto ml-3 font-weight-bold">
											{p.groupName}
										</div>
										<div className="row px-3">
											<div className="col">
												<CustomDatePicker
													label={<span className="form-group">START DATE</span>}
													id="startDate"
													selected={startDates[i]}
													handleDateChange={(date) =>
														handleDateChange('startDate', date, i)
													}
													placeholderText="Select Date to Start"
													popperPlacement="bottom"
													popperModifiers={popperModifiers}
												/>
											</div>
											<div className="col">
												{endDates[i] ? (
													<CustomDatePicker
														label={<span className="form-group">END DATE</span>}
														id="endDate"
														selected={endDates[i]}
														minDate={client.startDate}
														handleDateChange={(date) =>
															handleDateChange('endDate', date, i)
														}
														daysHighlighted={[client.startDate]}
														placeholderText="Select Date to Unenroll"
														popperPlacement="bottom"
														popperModifiers={popperModifiers}
														isClearable
													/>
												) : (
													<>
														<label className={'form-font'}>END DATE</label>
														<button
															className="btn btn-white bg-white link-color border btn-lg btn-block font-14 font-weight-bold d-flex justify-content-center"
															style={{
																padding: 'calc(0.5rem + 1px) 1rem',
															}}
															onClick={() =>
																handleDateChange('endDate', new Date(), i)
															}
														>
															<div
																style={{
																	marginRight: 4,
																	transform: 'translate(-2px, -2px)',
																}}
															>
																<svg
																	width="12px"
																	height="12px"
																	viewBox="0 0 14 14"
																>
																	<title>
																		82526130-CDA4-4B9A-B499-66A9C563AB6A
																	</title>
																	<desc>Created with sketchtool.</desc>
																	<g
																		id="Groups-(Admin)"
																		stroke="none"
																		strokeWidth="1"
																		fill="none"
																		fillRule="evenodd"
																	>
																		<g
																			id="03.1_Admin-Dashboard_Groups"
																			transform="translate(-861.000000, -630.000000)"
																			fill="var(--main-color)"
																		>
																			<g
																				id="Group-10"
																				transform="translate(838.000000, 615.000000)"
																			>
																				<g
																					id="add"
																					transform="translate(20.000000, 12.000000)"
																				>
																					<polygon
																						id="Shape"
																						points="17 9 11 9 11 3 9 3 9 9 3 9 3 11 9 11 9 17 11 17 11 11 17 11"
																					></polygon>
																				</g>
																			</g>
																		</g>
																	</g>
																</svg>
															</div>
															Add end date
														</button>
													</>
												)}
											</div>
										</div>
									</div>
								))
							) : (
								<div className="row px-3">
									<div className="col">
										<CustomDatePicker
											label={<span className="form-group">START DATE</span>}
											id="startDate"
											selected={startDates[0]}
											handleDateChange={(date) =>
												handleDateChange('startDate', date)
											}
											placeholderText="Select Date to Start"
											popperPlacement="bottom"
											popperModifiers={popperModifiers}
										/>
									</div>
									<div className="col">
										{endDates[0] ? (
											<CustomDatePicker
												label={<span className="form-group">END DATE</span>}
												id="endDate"
												selected={endDates[0]}
												handleDateChange={(date) =>
													handleDateChange('endDate', date)
												}
												daysHighlighted={[client.startDate]}
												placeholderText="Select Date to End"
												popperPlacement="bottom"
												popperModifiers={popperModifiers}
												isClearable
											/>
										) : (
											<>
												<label className={'form-font'}>END DATE</label>
												<button
													className="btn btn-white bg-white link-color border btn-lg btn-block font-14 font-weight-bold d-flex justify-content-center"
													style={{
														padding: 'calc(0.5rem + 1px) 1rem',
													}}
													onClick={() =>
														handleDateChange('endDate', new Date())
													}
												>
													<div
														style={{
															marginRight: 4,
															transform: 'translate(-2px, -2px)',
														}}
													>
														<svg width="12px" height="12px" viewBox="0 0 14 14">
															<title>
																82526130-CDA4-4B9A-B499-66A9C563AB6A
															</title>
															<desc>Created with sketchtool.</desc>
															<g
																id="Groups-(Admin)"
																stroke="none"
																strokeWidth="1"
																fill="none"
																fillRule="evenodd"
															>
																<g
																	id="03.1_Admin-Dashboard_Groups"
																	transform="translate(-861.000000, -630.000000)"
																	fill="var(--main-color)"
																>
																	<g
																		id="Group-10"
																		transform="translate(838.000000, 615.000000)"
																	>
																		<g
																			id="add"
																			transform="translate(20.000000, 12.000000)"
																		>
																			<polygon
																				id="Shape"
																				points="17 9 11 9 11 3 9 3 9 9 3 9 3 11 9 11 9 17 11 17 11 11 17 11"
																			></polygon>
																		</g>
																	</g>
																</g>
															</g>
														</svg>
													</div>
													Add end date
												</button>
											</>
										)}
									</div>
								</div>
							)}
							{}
							<div className="row justify-content-center align-items-center p-4">
								<button
									onClick={toggle}
									className="btn btn-white link-color border btn-lg text-uppercase font-13 col-4 d-flex justify-content-center"
								>
									<span className="mr-0 w-100">CANCEL</span>
								</button>
								<div className="col-sm-1"></div>
								<button
									onClick={doUpdate}
									className="btn btn-primary btn-lg btn-color text-uppercase font-13 col-4 d-flex justify-content-center"
								>
									<span className="mr-0 w-100">SUBMIT</span>
								</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

UpdateEnrollmentModal.propTypes = {
	changePharmacy: PropTypes.func,
	patientId: PropTypes.any,
	pharmacy: PropTypes.object,
	handleChange: PropTypes.func,
	handleNumberChange: PropTypes.func,
	showPharmacyModal: PropTypes.func,
	modalOpen: PropTypes.bool,
};

export default UpdateEnrollmentModal;
