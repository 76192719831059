import history from 'utils/history';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';

/**
 *
 * OtherRequests
 *
 */
import AttachmentCell from 'atoms/TableCell/AttachmentCell';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import RequestCard from 'cards/RequestCard';
import { headerStyle } from 'containers/App/constants';
import { generateButtons } from 'containers/Requests/helpers';
import { filterByLetter } from 'utils/helpers';

export class OtherRequests extends React.Component {
	approve = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'APPROVED');
	};

	deny = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, 'DENIED');
	};

	activate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, true);
	};

	deactivate = (requestId) => {
		const { updateRequest } = this.props;
		updateRequest(requestId, false);
	};

	delete = (requestId) => {
		const { deleteRequest } = this.props;
		deleteRequest(requestId, 'DENIED');
	};

	goToProfile = (id) => {
		history.push(`profile/${id}`);
	};

	getColumns = () => {
		const { downloadFile, type } = this.props;

		return [
			{
				field: 'name',
				title: 'NAME',
				headerStyle,
				render: (row) => (
					<div className="hand" onClick={() => this.goToProfile(row.userId)}>
						<ImageDetailsCell
							src={row.profilePicture}
							heading={row.name}
							primarySubHeading={row.state}
						/>
					</div>
				),
			},
			{
				field: 'roleName',
				title: 'ROLE',
				headerStyle,
				render: (row) => (
					<div className="mt-2 mx-2 font-13">{row.roleName}</div>
				),
			},
			{
				field: 'docs',
				title: 'DOCUMENTS',
				headerStyle,
				render: (row) => (
					<div onClick={() => downloadFile(row.uuid)}>
						{row.docs?.length > 0 ? (
							<AttachmentCell title={`${row.docs.length} document`} />
						) : (
							<div>-</div>
						)}
					</div>
				),
			},
			{
				field: 'createdAt',
				title: 'REGISTERED ON',
				headerStyle,
				render: (row) => (
					<div className=" mt-2 mx-2 font-13" cursor="pointer">
						{moment(row.createdAt).format('YYYY-MM-DD') || '-'}
					</div>
				),
			},
			{
				field: 'requestState',
				title: 'STATUS',
				headerStyle: { ...headerStyle, width: '11rem' },
				render: (row) =>
					!row.onboardingComplete ? (
						<div
							className="text-muted justify-content-start align-items-center font-12"
							cursor="pointer"
						>
							REGISTRATION INCOMPLETE
						</div>
					) : row.requestState === 'OPEN' ? (
						<div className="d-flex">
							{generateButtons(row, this, type).map((button) => button)}
						</div>
					) : (
						<div
							className="text-muted justify-content-start align-items-center font-12"
							cursor="pointer"
						>
							{row.requestState}
						</div>
					),
			},
		];
	};

	render() {
		const { data, loading, isMobile, userSearch } = this.props;
		const otherReqColumns = this.getColumns();

		let filteredData = [];

		if (data) {
			filteredData = filterByLetter(data, 'name', userSearch);
		}

		const rowStyle = (rowData) => {
			if (!rowData.isActive) {
				return {
					backgroundColor: 'rgb(255 231 231)',
				};
			}
		};

		return (
			<div>
				{isMobile ? (
					<div className="row mx-0">
						{loading ? (
							<SmallLoadingIndicator
								text={'Loading...'}
								color="var(--main-color)"
							/>
						) : (
							filteredData.map((r) => {
								let heading = <div>{r.requestType}</div>;

								return (
									<RequestCard
										heading={heading}
										request={r}
										requestor={{
											heading: r.name,
											subheading: r.requestorState,
										}}
										key={r.requestId}
									/>
								);
							})
						)}
					</div>
				) : (
					<Table
						keyField="otherReq"
						data={filteredData}
						columns={otherReqColumns}
						classes="table-responsive-lg"
						placeholder="No requests to display"
						loading={loading}
						rowStyle={rowStyle}
					/>
				)}
			</div>
		);
	}
}

OtherRequests.propTypes = {
	data: PropTypes.array.isRequired,
	downloadFile: PropTypes.func,
	updateRequest: PropTypes.func,
};

export default OtherRequests;
