const role = {
	admin: 1,
	clinician: 2,
	patient: 3,
};

export const getRole = (type) => role[type];
export const getRoleNameFromId = (id) =>
	Object.keys(role).reduce((final, item) => {
		if (role[item] === id) return item;
		return final;
	}, '');
