import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';

import SelectAutoComplete from 'atoms/SelectAutoComplete';
import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import Table from 'atoms/Table';
import ToggleSwitch from 'atoms/ToggleSwitch';
import LoadingIndicator from 'components/LoadingIndicator';
import SigningModal from 'components/SigningModal';
import { loadClients } from 'containers/Admin/actions';
import { makeSelectClients } from 'containers/Admin/selectors';
import { updateMulti } from 'containers/UserDropDown/actions';
import { makeSelectIsUpdating } from 'containers/UserDropDown/selectors';

import {
	doMagic,
	fetchDocLog,
	testEnrollment,
	testIntakeNew,
	testQuestionnaire,
	testRoi,
	testTelehealth,
} from './actions';
import { logColumns } from './columns';
import { enrollment, intake, roi, telehealth } from './payloads';
import {
	makeSelectDocs,
	makeSelectDocsError,
	makeSelectError,
	makeSelectFetchingDocs,
	makeSelectUrl,
} from './selectors';

const NavigationContainer = styled.div`
	button:last-child {
		border-bottom: none;
	}
`;

const NavigationButton = styled.button`
	padding: 0.5rem;
	width: 100%;
	cursor: pointer;
	text-align: left;

	background: ${(props) => (props.isActive ? 'var(--main-color)' : 'white')};
	color: ${(props) => (props.isActive ? 'white' : 'black')};

	border-bottom: 1px solid gainsboro;

	:hover {
		background: var(--main-color);
		color: white;
	}
`;

const AdminConsole = (props) => {
	const {
		url,
		error,
		fetchDocumentLog,
		fetchingDocs,
		docLog,
		docError,
		clients,
		fetchAllClients,
		doUpdateMulti,
		isUpdating,
		magicTest,
	} = props;

	const [tab, setTab] = useState(0);
	const [selectedEndpoint, setEndpoint] = useState({
		label: 'Please Select an Option',
		value: null,
	});
	const [useSandbox, setSandbox] = useState(false);
	const [useCustomPayload, setCustomPayload] = useState(false);
	const [payload, setPayload] = useState({});
	const [isLoading, setLoading] = useState(false);
	const [showSigningModal, setShowSigningModal] = useState(false);
	const [selectedClients, setSelectedClients] = useState([]);

	const options = [
		'Intake - Adult',
		'Intake - IOP',
		'Telehealth',
		'Release of Information',
		'Enrollment',
		'Questionnaire-Adult',
		'Questionnaire-Child',
	];

	const handleClientSelect = (event) => {
		setSelectedClients(event);
	};

	const selectOption = (option) => {
		setEndpoint(option);
	};

	useEffect(() => {
		if (useCustomPayload) return;

		switch (selectedEndpoint.value) {
			case 'Release of Information':
				setPayload(roi);
				break;
			case 'Telehealth':
				setPayload(telehealth);
				break;
			case 'Intake New - Adult':
				setPayload(intake('adult'));
				break;
			case 'Intake New - IOP':
				setPayload(intake('iopPhp'));
				break;
			case 'Enrollment':
				setPayload(enrollment);
				break;
			default:
				setPayload({});
				break;
		}
	}, [selectedEndpoint]);

	useEffect(() => {
		if (isLoading) {
			if (url) {
				setLoading(false);
				setShowSigningModal(true);
			} else if (error) {
				setLoading(false);
			}
		}
	}, [url, error]);

	useEffect(() => {
		fetchDocumentLog();
		fetchAllClients();
	}, []);

	const clientOptions = () => {
		return Array.isArray(clients)
			? clients
					.filter((c) => c.onboardingComplete)
					.map((c) => ({ label: c.client.name, value: c.patientId }))
			: [];
	};

	const doClientUpdate = () => {
		const data = [];

		for (const client of selectedClients) {
			data.push({
				id: client.value,
				onboardingComplete: false,
			});
		}
		doUpdateMulti(data);
		setSelectedClients([]);
	};

	const magic = () => {
		magicTest();
	};

	const doTest = () => {
		if (!selectedEndpoint.value) return;

		const data = {
			type: selectedEndpoint.value,
			senderUserId: 0,
			state: 'OPEN',
			payload,
			sandbox: useSandbox,
		};

		switch (selectedEndpoint.value) {
			case 'Release of Information':
				props.testRoi(data);
				break;
			case 'Telehealth':
				props.testTelehealth(data);
				break;
			case 'Intake New - Adult':
			case 'Intake New - IOP':
				props.testIntakeNew(data);
				break;
			case 'Enrollment':
				props.testEnrollment(data);
				break;
			case 'Questionnaire-Child':
				data.payload.type = 'child';
				props.testQuestionnaire(data);
				break;
			case 'Questionnaire-Adult':
				data.payload.type = 'adult';
				props.testQuestionnaire(data);
				break;
			default:
				break;
		}

		setLoading(true);
	};

	const toggleCustomPayload = () => {
		setCustomPayload(!useCustomPayload);
	};

	const toggleSandbox = () => {
		setSandbox(!useSandbox);
	};

	const renderTabContent = () => {
		switch (tab) {
			default:
			case 0:
				const toggles = [
					{
						name: 'Use Custom Payload',
						onClick: toggleCustomPayload,
						selected: useCustomPayload,
						extra: useCustomPayload && (
							<textarea
								className="w-100 border"
								style={{ height: 200, resize: 'none' }}
								onChange={(e) => setPayload(JSON.parse(e.target.value))}
							/>
						),
					},
					{
						name: 'Use SignNow Sandbox',
						onClick: toggleSandbox,
						selected: useSandbox,
					},
				];
				return (
					<>
						<h5 className="pb-3">Test Endpoints</h5>
						<div className="row">
							<div className="col-9">
								<SelectAutoComplete
									handleChange={selectOption}
									options={options.map((o) => ({ label: o, value: o }))}
									value={selectedEndpoint}
									placeholder="Select Document to Test"
									indicatorClass="fas fa-sort dropdown-indicator text-primary"
									optionStyles={{ fontSize: '14px' }}
									singleValueStyles={{
										color: 'var(--main-color)',
										fontSize: '14px',
									}}
								/>
							</div>
						</div>
						{toggles.map((t) => (
							<div className="row my-3" key={t.name}>
								<div className="col-3">{t.name}</div>
								<div className="col-1">
									<ToggleSwitch
										className="mt-1"
										onClick={t.onClick}
										selected={t.selected}
									/>
								</div>
								{t.extra}
							</div>
						))}
						<div className="col-2 p-0">
							<button
								disabled={!selectedEndpoint.value}
								className="btn btn-primary btn-color font-15 font-weight-bold"
								onClick={doTest}
								style={{ width: '100%', height: 42 }}
							>
								Test Endpoint
							</button>
						</div>
						<div>
							<button
								onClick={magic}
								className="btn btn-primary btn-color font-15 font-weight-bold"
							>
								Magic Button
							</button>
						</div>
					</>
				);
			case 1:
				return (
					<div>
						<h5 className="pb-3">Document Error Log</h5>
						<Table
							loading={fetchingDocs}
							data={docLog}
							columns={logColumns()}
							keyField="uuid"
							placeholder="No documents to display"
							error={docError}
						/>
					</div>
				);
			case 2:
				return (
					<div>
						<h5 className="pb-3">Reset Intake</h5>
						<div className="row">
							<div className="col-5">
								<SelectAutoComplete
									handleChange={handleClientSelect}
									options={clientOptions()}
									value={selectedClients}
									placeholder="Select Client(s)"
									indicatorClass="fas fa-sort dropdown-indicator text-primary"
									optionStyles={{ fontSize: '14px' }}
									singleValueStyles={{
										color: 'var(--main-color)',
										fontSize: '14px',
									}}
									multiple
								/>
							</div>
							<div className="col-3 mx-0 justify-content-between">
								<button
									disabled={isUpdating || selectedClients.length === 0}
									className="btn btn-primary btn-color font-15 font-weight-bold"
									onClick={doClientUpdate}
									style={{ width: '100%', height: 42 }}
								>
									{isUpdating ? (
										<SmallLoadingIndicator
											style={{
												width: '20px',
												height: '20px',
												marginTop: '0.25rem !important',
											}}
											className="my-0"
										/>
									) : (
										'Reset Intake'
									)}
								</button>
							</div>
						</div>
					</div>
				);
		}
	};

	return (
		<div className="p-4 w-100">
			<div className="row mt-2 p-4">
				<div className="col-2 p-0">
					<NavigationContainer className="bg-white">
						<NavigationButton onClick={() => setTab(0)} isActive={tab === 0}>
							Test Endpoints
						</NavigationButton>
						<NavigationButton onClick={() => setTab(1)} isActive={tab === 1}>
							Document Log
						</NavigationButton>
						<NavigationButton onClick={() => setTab(2)} isActive={tab === 2}>
							Reset Intake
						</NavigationButton>
					</NavigationContainer>
				</div>
				<div className="col-8 bg-white ml-4 p-4">
					{renderTabContent()}
					<div>{error}</div>
				</div>
			</div>
			{isLoading && <LoadingIndicator text="Please wait for SignNow to Load" />}
			{showSigningModal && (
				<SigningModal
					isOpen={showSigningModal}
					siteUrl={url}
					toggle={() => setShowSigningModal(false)}
				/>
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	url: makeSelectUrl(),
	error: makeSelectError(),
	docLog: makeSelectDocs(),
	docError: makeSelectDocsError(),
	fetchingDocs: makeSelectFetchingDocs(),
	clients: makeSelectClients(),
	isUpdating: makeSelectIsUpdating(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		testTelehealth: (data) => dispatch(testTelehealth(data)),
		testRoi: (data) => dispatch(testRoi(data)),
		testEnrollment: (data) => dispatch(testEnrollment(data)),
		testIntakeNew: (data) => dispatch(testIntakeNew(data)),
		testQuestionnaire: (data) => dispatch(testQuestionnaire(data)),
		fetchDocumentLog: () => dispatch(fetchDocLog()),
		fetchAllClients: () => dispatch(loadClients()),
		doUpdateMulti: (data) => dispatch(updateMulti(data)),
		magicTest: (data) => dispatch(doMagic(data)),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminConsole);
