/*
 *
 * BrowseGroups reducer
 *
 */
import { fromJS } from 'immutable';

import {
	CLEAR_URL,
	CREATE_REQUEST,
	CREATE_REQUEST_ERROR,
	CREATE_REQUEST_INDIVIDUAL,
	CREATE_REQUEST_INDIVIDUAL_SUCCESS,
	CREATE_REQUEST_SUCCESS,
	DEFAULT_ACTION,
	LOAD_PROGRAMS,
	LOAD_PROGRAMS_ERROR,
	LOAD_PROGRAMS_SUCCESS,
} from './constants';

export const initialState = fromJS({
	programs: [],
	error: '',
	message: '',
	url: '',
});

function browseGroupsReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case LOAD_PROGRAMS:
			return state.set('programs', []);
		case LOAD_PROGRAMS_SUCCESS:
			return state.set('programs', action.programs);
		case LOAD_PROGRAMS_ERROR:
			return state.set('error', action.error);
		case CREATE_REQUEST:
			return state.set('url', '');
		case CREATE_REQUEST_SUCCESS:
			return state.set('url', action.data.url);
		case CREATE_REQUEST_ERROR:
			return state.set('error', action.err);
		case CREATE_REQUEST_INDIVIDUAL:
			return state.set('url', '');
		case CREATE_REQUEST_INDIVIDUAL_SUCCESS:
			return state.set('message', action.data);
		case CLEAR_URL:
			return state.set('url', '');
		default:
			return state;
	}
}

export default browseGroupsReducer;
