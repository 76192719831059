export const DEFAULT_ACTION = 'src/Notifications/DEFAULT_ACTION';

export const FETCH_NOTIFICATIONS = 'src/Notifications/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS =
	'src/Notifications/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR =
	'src/Notifications/FETCH_NOTIFICATIONS_ERROR';
export const UPDATE_NOTIFICATIONS = 'src/Notifications/UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_SUCCESS =
	'src/Notifications/UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_ERROR =
	'src/Notifications/UPDATE_NOTIFICATIONS_ERROR';
