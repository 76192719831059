/*
 *
 * CliniciansAndClients constants
 *
 */

export const DEFAULT_ACTION = 'src/CliniciansAndClients/DEFAULT_ACTION';

export const LOAD_ALL_CLIENTS = 'src/CliniciansAndClients/LOAD_ALL_CLIENTS';
export const LOAD_ALL_CLIENTS_SUCCESS =
	'src/CliniciansAndClients/LOAD_ALL_CLIENTS_SUCCESS';
export const LOAD_ALL_CLIENTS_ERROR =
	'src/CliniciansAndClients/LOAD_ALL_CLIENTS_ERROR';

export const LOAD_ALL_CLINICIANS =
	'src/CliniciansAndClients/LOAD_ALL_CLINICIANS';
export const LOAD_ALL_CLINICIANS_SUCCESS =
	'src/CliniciansAndClients/LOAD_ALL_CLINICIANS_SUCCESS';
export const LOAD_ALL_CLINICIANS_ERROR =
	'src/CliniciansAndClients/LOAD_ALL_CLINICIANS_ERROR';

export const DELETE_CLINCIAN = 'src/CliniciansAndClients/DELETE_CLINCIAN';
export const DELETE_CLINCIAN_SUCCESS =
	'src/CliniciansAndClients/DELETE_CLINCIAN_SUCCESS';
export const DELETE_CLINCIAN_ERROR =
	'src/CliniciansAndClients/DELETE_CLINCIAN_ERROR';

export const EDIT_CLINCIAN = 'src/CliniciansAndClients/EDIT_CLINCIAN';
export const EDIT_CLINCIAN_SUCCESS =
	'src/CliniciansAndClients/EDIT_CLINCIAN_SUCCESS';
export const EDIT_CLINCIAN_ERROR =
	'src/CliniciansAndClients/EDIT_CLINCIAN_ERROR';

export const CHANGE_CLINCIAN = 'src/CliniciansAndClients/CHANGE_CLINCIAN';
export const CHANGE_CLINCIAN_SUCCESS =
	'src/CliniciansAndClients/CHANGE_CLINCIAN_SUCCESS';
export const CHANGE_CLINCIAN_ERROR =
	'src/CliniciansAndClients/CHANGE_CLINCIAN_ERROR';

export const GET_CLINCIAN = 'src/CliniciansAndClients/GET_CLINCIAN';
export const GET_CLINCIAN_SUCCESS =
	'src/CliniciansAndClients/GET_CLINCIAN_SUCCESS';
export const GET_CLINCIAN_ERROR = 'src/CliniciansAndClients/GET_CLINCIAN_ERROR';

export const ADD_CLIENT = 'src/MyClients/ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'src/MyClients/ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR = 'src/MyClients/ADD_CLIENT_ERROR';

export const CLEAR_NEW_CLIENT = 'src/MyClients/CLEAR_NEW_CLIENT';

export const LOAD_PROGRAMS = 'src/MyClients/LOAD_PROGRAMS';
export const LOAD_PROGRAMS_SUCCESS = 'src/MyClients/LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_ERROR = 'src/MyClients/LOAD_PROGRAMS_ERROR';

export const LOAD_CLIENTS = 'src/MyClients/LOAD_CLIENTS';
export const LOAD_CLIENTS_SUCCESS = 'src/MyClients/LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_ERROR = 'src/MyClients/LOAD_CLIENTS_ERROR';
