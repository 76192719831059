import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import ClinicianHeader from 'components/CommonHeader';
import FileUpload from 'components/FileUpload';
import { clinicianTypes } from 'constants/userTypes';
import { makeSelectAuth } from 'containers/App/selectors';
import { checkNested } from 'utils/helpers';

import {
	clinicianRegistration,
	fetchData,
	fetchStates,
	uploadCredential,
} from './actions';
import {
	CLINICIAN_LICENSED,
	CLINICIAN_NOLICENSED,
	CLINICIAN_PRELICENSED,
} from './constants';
import './index.css';
import makeSelectClinicianRegister from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class ClinicianRegister extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: '1',
			therapistType: '',
			licenseNumber: '',
			licenseState: '',
			licenseExpirationMonth: '',
			licenseExpirationYear: '',
			supervisorName: '',
			licenseNumberSupervisor: '',
			licenseExpirationMonthSupervisor: '',
			therapistTypePreLicensed: '',
			licenseStateSupervisor: '',
			licenseExpirationYearSupervisor: '',
			licenseNumberPreLicensed: '',
			licenseStatePreLicensed: '',
			licenseExpirationMonthPreLicensed: '',
			licenseExpirationYearPreLicensed: '',
			therapistTypeNoLicensed: '',
			credentialTypeNoLicensed: 'License',
			licensingAuthority: '',
			licenseNumberNoLicense: '',
			yearIssuedNoLicense: '',
			files: [],
		};
	}

	yearList() {
		const currentYear = parseInt(moment().format('YYYY'), 10);
		return [...Array(50).keys()].map((a) => (a + currentYear).toString());
	}

	UNSAFE_componentWillMount() {
		const { fetchClinicianData, auth, dispatch } = this.props;
		const { id } = auth.loggedInUser;
		fetchClinicianData({ id });
		dispatch(fetchStates());
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.clinicianRegister &&
			nextProps.clinicianRegister.clinicianType
		) {
			const { clinicianRegister } = nextProps;
			const { clinicianType } = clinicianRegister;
			const { licenseDetails } = clinicianRegister;
			if (clinicianType === CLINICIAN_LICENSED) {
				this.setState({ activeTab: '1' });
				this.setState({ therapistType: clinicianRegister.therapistType });
			} else if (clinicianType === CLINICIAN_PRELICENSED) {
				this.setState({ activeTab: '2' });
				this.setState({
					therapistTypePreLicensed: clinicianRegister.therapistType,
				});
			} else if (clinicianType === CLINICIAN_NOLICENSED) {
				this.setState({ activeTab: '3' });
				this.setState({
					therapistTypeNoLicensed: clinicianRegister.therapistType,
				});
			}
			const keys = Object.getOwnPropertyNames(licenseDetails);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				this.setState({ [key]: licenseDetails[key] });
			}
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	upload = (files) => {
		this.setState({ files });
	};

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	message = () => <span />;

	save = () => {
		const { clinicianRegistrationForm, auth, dispatch } = this.props;
		const { files } = this.state;

		if (files.length > 0) {
			dispatch(uploadCredential(files));
		}

		const data = {};
		data.id = auth.loggedInUser.id;
		if (this.state.activeTab === '1') {
			data.clinicianType = CLINICIAN_LICENSED;
			data.therapistType = this.state.therapistType;
			data.licenseDetails = {
				licenseNumber: this.state.licenseNumber,
				licenseState: this.state.licenseState,
				licenseExpirationMonth: this.state.licenseExpirationMonth,
				licenseExpirationYear: this.state.licenseExpirationYear,
			};
		} else if (this.state.activeTab === '2') {
			data.clinicianType = CLINICIAN_PRELICENSED;
			data.therapistType = this.state.therapistTypePreLicensed;
			data.licenseDetails = {
				supervisorName: this.state.supervisorName,
				licenseNumberSupervisor: this.state.licenseNumberSupervisor,
				licenseExpirationMonthSupervisor:
					this.state.licenseExpirationMonthSupervisor,
				licenseStateSupervisor: this.state.licenseStateSupervisor,
				licenseExpirationYearSupervisor:
					this.state.licenseExpirationYearSupervisor,
				licenseNumberPreLicensed: this.state.licenseNumberPreLicensed,
				licenseStatePreLicensed: this.state.licenseStatePreLicensed,
				licenseExpirationMonthPreLicensed:
					this.state.licenseExpirationMonthPreLicensed,
				licenseExpirationYearPreLicensed:
					this.state.licenseExpirationYearPreLicensed,
			};
		} else if (this.state.activeTab === '3') {
			data.clinicianType = CLINICIAN_NOLICENSED;
			data.therapistType = this.state.therapistTypeNoLicensed;
			data.licenseDetails = {
				credentialTypeNoLicensed: this.state.credentialTypeNoLicensed,
				licensingAuthority: this.state.licensingAuthority,
				licenseNumberNoLicense: this.state.licenseNumberNoLicense,
				yearIssuedNoLicense: this.state.yearIssuedNoLicense,
			};
		}
		clinicianRegistrationForm(data);
	};

	render() {
		const { clinicianRegister } = this.props;
		const { loggedInUser } = this.props.auth;

		return (
			<div>
				<ClinicianHeader
					routeProps={this.props}
					user={loggedInUser}
					name="Clinician Registrations"
				/>
				<div className="container px-5 d-flex flex-column">
					<h2 className="align-self-center mb-4 mt-3">
						<strong>Credentials</strong>
					</h2>
					<div className="p-0 mb-3 d-flex justify-content-center">
						<Nav tabs className="actions col-8 p-0 m-0">
							<NavItem className="col-4 p-0 d-flex justify-content-center">
								<NavLink
									className={`${
										this.state.activeTab === '1' ? 'active' : ''
									} w-100 d-flex justify-content-center align-items-center text-center`}
									onClick={() => {
										this.toggle('1');
									}}
								>
									I&apos;m Licensed
								</NavLink>
							</NavItem>
							<NavItem className="col-4 p-0 d-flex justify-content-center">
								<NavLink
									className={`${
										this.state.activeTab === '2' ? 'active' : ''
									} w-100 d-flex justify-content-center align-items-center text-center`}
									onClick={() => {
										this.toggle('2');
									}}
								>
									I&apos;m Pre-Licensed or Under Supervision
								</NavLink>
							</NavItem>
							<NavItem className="col-4 p-0 d-flex justify-content-center">
								<NavLink
									className={`${
										this.state.activeTab === '3' ? 'active' : ''
									} w-100 d-flex justify-content-center align-items-center text-center`}
									onClick={() => {
										this.toggle('3');
									}}
								>
									I Have No License
								</NavLink>
							</NavItem>
						</Nav>
					</div>
					<TabContent
						activeTab={this.state.activeTab}
						className="d-flex justify-content-center"
					>
						<TabPane tabId="1" className="col-8 p-0">
							<div className="card d-flex flex-column p-4">
								<form>
									<div className="form-group">
										<label htmlFor="therapistType" className="form-font">
											THERAPIST TYPE
										</label>
										<select
											value={this.state.therapistType}
											name="therapistType"
											className="custom-select"
											id="therapistType"
											onChange={this.handleChange}
											required
										>
											<option value="">Select Therapist Type</option>
											{clinicianTypes.map((e) => (
												<option value={e} key={e}>
													{e}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licenseNumber" className="form-font">
											LICENSE NUMBER
										</label>
										<input
											defaultValue={
												this.state.licenseNumber ||
												checkNested(
													clinicianRegister,
													'licenseDetails',
													'licenseNumber',
												) ||
												''
											}
											placeholder="License Number"
											type="text"
											name="licenseNumber"
											className="form-control"
											id="licenseNumber"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="licenseState" className="form-font">
											LICENSE STATE
										</label>
										<select
											value={this.state.licenseState}
											className="custom-select"
											placeholder="Select State"
											id="licenseState"
											name="licenseState"
											onChange={this.handleChange}
											required
										>
											<option value="">Select State</option>
											{clinicianRegister.states.map((e) => (
												<option value={e.code} key={e}>
													{e.name}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licenseExpiration" className="form-font">
											LICENSE EXPIRATION
										</label>
										<div className="d-flex">
											<select
												value={this.state.licenseExpirationMonth}
												className="custom-select"
												placeholder="Select month"
												id="licenseExpirationMonth"
												name="licenseExpirationMonth"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Month</option>
												{moment.monthsShort().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
											<select
												value={this.state.licenseExpirationYear}
												className="custom-select"
												placeholder="Select State"
												id="licenseExpirationYear"
												name="licenseExpirationYear"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Year</option>
												{this.yearList().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
										</div>
									</div>

									<div className="mt-2">
										<button
											type="button"
											className="btn btn-primary btn-color w-100 font-weight-bold"
											onClick={this.save}
										>
											SAVE
										</button>
									</div>
								</form>
							</div>
						</TabPane>
						<TabPane tabId="2" className="col-8 p-0">
							<div className="card d-flex flex-column p-4">
								<form>
									<div className="form-group">
										<label
											htmlFor="therapistTypePreLicensed"
											className="form-font"
										>
											THERAPIST TYPE
										</label>
										<select
											value={this.state.therapistTypePreLicensed}
											className="custom-select"
											id="therapistTypePreLicensed"
											name="therapistTypePreLicensed"
											onChange={this.handleChange}
											required
										>
											<option value="">Select Therapist Type</option>
											{clinicianTypes.map((e) => (
												<option value={e} key={e}>
													{e}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="supervisorName" className="form-font">
											SUPERVISOR&apos;S NAME
										</label>
										<input
											defaultValue={
												this.state.supervisorName ||
												checkNested(
													clinicianRegister,
													'licenseDetails',
													'supervisorName',
												) ||
												''
											}
											placeholder="Supervisor Name"
											type="text"
											name="supervisorName"
											className="form-control"
											id="supervisorName"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label
											htmlFor="licenseNumberSupervisor"
											className="form-font"
										>
											SUPERVISOR&apos;S LICENSE NUMBER
										</label>
										<input
											defaultValue={
												this.state.licenseNumberSupervisor ||
												checkNested(
													clinicianRegister,
													'licenseDetails',
													'licenseNumberSupervisor',
												) ||
												''
											}
											placeholder="License Number"
											type="text"
											name="licenseNumberSupervisor"
											className="form-control"
											id="licenseNumberSupervisor"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label
											htmlFor="licenseStateSupervisor"
											className="form-font"
										>
											SUPERVISOR&apos;S LICENSE STATE
										</label>
										<select
											value={this.state.licenseStateSupervisor}
											className="custom-select"
											placeholder="Select State"
											id="licenseStateSupervisor"
											name="licenseStateSupervisor"
											onChange={this.handleChange}
											required
										>
											<option value="">Select State</option>
											{clinicianRegister.states.map((e) => (
												<option value={e.code} key={e}>
													{e.name}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label
											htmlFor="licenseExpirationSupervisor"
											className="form-font"
										>
											SUPERVISOR&apos;S LICENSE EXPIRATION
										</label>
										<div className="d-flex">
											<select
												value={this.state.licenseExpirationMonthSupervisor}
												className="custom-select"
												placeholder="Select State"
												id="licenseExpirationMonthSupervisor"
												name="licenseExpirationMonthSupervisor"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Month</option>
												{moment.monthsShort().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
											<select
												value={this.state.licenseExpirationYearSupervisor}
												className="custom-select"
												placeholder="Select State"
												id="licenseExpirationYearSupervisor"
												name="licenseExpirationYearSupervisor"
												onChange={this.handleChange}
												required
											>
												<option value="">Select Year</option>
												{this.yearList().map((e) => (
													<option value={e} key={e}>
														{e}
													</option>
												))}
											</select>
										</div>
									</div>
								</form>
							</div>
							<hr className="m-0" />
							<div className="card d-flex flex-column p-4">
								<form>
									<h4>My Pre-License Information</h4>
									<div className="mt-3 pb-5">
										<div className="form-group">
											<label
												htmlFor="licenseNumberPreLicensed"
												className="form-font"
											>
												LICENSE NUMBER
											</label>
											<input
												defaultValue={
													this.state.licenseNumberPreLicensed ||
													checkNested(
														clinicianRegister,
														'licenseDetails',
														'licenseNumberPreLicensed',
													) ||
													''
												}
												placeholder="License Number"
												type="text"
												name="licenseNumberPreLicensed"
												className="form-control"
												id="licenseNumberPreLicensed"
												onChange={this.handleChange}
											/>
										</div>
										<div className="form-group">
											<label
												htmlFor="licenseStatePreLicensed"
												className="form-font"
											>
												LICENSE STATE
											</label>
											<select
												value={this.state.licenseStatePreLicensed}
												className="custom-select"
												placeholder="Select State"
												id="licenseStatePreLicensed"
												name="licenseStatePreLicensed"
												onChange={this.handleChange}
												required
											>
												<option value="">Select State</option>
												{clinicianRegister.states.map((e) => (
													<option value={e.code} key={e.code}>
														{e.name}
													</option>
												))}
											</select>
										</div>
										<div className="form-group">
											<label
												htmlFor="licenseExpirationPreLicensed"
												className="form-font"
											>
												LICENSE EXPIRATION
											</label>
											<div className="d-flex">
												<select
													value={this.state.licenseExpirationMonthPreLicensed}
													className="custom-select"
													placeholder="Select State"
													id="licenseExpirationMonthPreLicensed"
													name="licenseExpirationMonthPreLicensed"
													onChange={this.handleChange}
													required
												>
													<option value="">Select Month</option>
													{moment.monthsShort().map((e) => (
														<option value={e} key={e}>
															{e}
														</option>
													))}
												</select>
												<select
													value={this.state.licenseExpirationYearPreLicensed}
													className="custom-select"
													placeholder="Select State"
													id="licenseExpirationYearPreLicensed"
													name="licenseExpirationYearPreLicensed"
													onChange={this.handleChange}
													required
												>
													<option value="">Select Year</option>
													{this.yearList().map((e) => (
														<option value={e} key={e}>
															{e}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="mt-5">
											<button
												type="button"
												className="btn btn-primary btn-color w-100 font-weight-bold"
												onClick={this.save}
											>
												SAVE
											</button>
										</div>
									</div>
								</form>
							</div>
						</TabPane>
						<TabPane tabId="3" className="col-8 p-0">
							<div className="card d-flex flex-column p-4">
								<form>
									<div className="form-group">
										<label
											htmlFor="therapistTypeNoLicensed"
											className="form-font"
										>
											THERAPIST TYPE
										</label>
										<select
											value={this.state.therapistTypeNoLicensed}
											className="custom-select"
											id="therapistTypeNoLicensed"
											name="therapistTypeNoLicensed"
											onChange={this.handleChange}
											required
										>
											<option value="">Select Therapist Type</option>
											{clinicianTypes.map((e) => (
												<option value={e} key={e}>
													{e}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label
											htmlFor="credentialTypeNoLicensed"
											className="form-font"
										>
											CREDENTIAL TYPE
										</label>
										<p className="form-guideline-font">
											Choose an alternate credential, if you have one
										</p>
										<select
											value={this.state.credentialTypeNoLicensed}
											className="custom-select"
											id="credentialTypeNoLicensed"
											name="credentialTypeNoLicensed"
											onChange={this.handleChange}
											required
										>
											<option value="">License</option>
											<option value="Certificate">Certificate</option>
											<option value="Degree">Degree</option>
											<option value="Diploma">Diploma</option>
											<option value="Membership">Membership</option>
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="licensingAuthority" className="form-font">
											LICENSING AUTHORITY
										</label>
										<input
											defaultValue={
												this.state.licensingAuthority ||
												checkNested(
													clinicianRegister,
													'licenseDetails',
													'licensingAuthority',
												) ||
												''
											}
											placeholder="Licensing Authority"
											type="text"
											name="licensingAuthority"
											className="form-control"
											id="licensingAuthority"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label
											htmlFor="licenseNumberNoLicense"
											className="form-font"
										>
											LICENSE NUMBER
										</label>
										<input
											defaultValue={
												this.state.licenseNumberNoLicense ||
												checkNested(
													clinicianRegister,
													'licenseDetails',
													'licenseNumberNoLicense',
												) ||
												''
											}
											placeholder="License Number"
											type="text"
											name="licenseNumberNoLicense"
											className="form-control"
											id="licenseNumberNoLicense"
											onChange={this.handleChange}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="yearIssuedNoLicense" className="form-font">
											YEAR ISSUED
										</label>
										<select
											value={this.state.yearIssuedNoLicense}
											className="custom-select"
											placeholder="Select State"
											id="yearIssuedNoLicense"
											name="yearIssuedNoLicense"
											onChange={this.handleChange}
											required
										>
											<option value="">Select Year</option>
											{this.yearList().map((e) => (
												<option value={e} key={e}>
													{e}
												</option>
											))}
										</select>
									</div>
									<FileUpload
										upload={this.upload}
										message="Drag and drop your credential here (only pdf format supported), Browse or
                        send your credential to admin@mindtherapy.com."
										files={this.state.files}
									/>
									<div className="mt-2">
										<button
											type="button"
											className="btn btn-primary btn-color w-100 font-weight-bold"
											onClick={this.save}
										>
											SAVE
										</button>
									</div>
								</form>
							</div>
						</TabPane>
					</TabContent>
				</div>
			</div>
		);
	}
}

ClinicianRegister.propTypes = {
	clinicianRegistrationForm: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	fetchClinicianData: PropTypes.func.isRequired,
	clinicianRegister: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	clinicianRegister: makeSelectClinicianRegister(),
	auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		clinicianRegistrationForm: (data) => dispatch(clinicianRegistration(data)),
		fetchClinicianData: (data) => dispatch(fetchData(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicianRegister);
