import history from 'utils/history';

import { findIndex, isEqual } from 'lodash';

import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import SmallLoadingIndicator from 'atoms/SmallLoadingIndicator';
import AdultSubstanceAbuse from 'components/AdultSubstanceAbuse';
import BehavioralGuidelines from 'components/BehavioralGuidelines';
import ChildBasicInfo from 'components/ChildBasicInfo';
import CommonHeader from 'components/CommonHeader';
import DrugAlcoholPolicy from 'components/DrugAlcoholPolicy';
import Footer from 'components/Footer';
import GroupStandards from 'components/GroupStandards';
import GroupTherapyConsentFrom from 'components/GroupTherapyConsentFrom';
import LoadingIndicator from 'components/LoadingIndicator';
import StatusModal from 'components/RequestSentModal';
import SigningModal from 'components/SigningModal';
import {
	fetchCities,
	fetchStates,
	fetchZipcodes,
} from 'containers/App/actions';
import {
	makeSelectAuth,
	makeSelectCities,
	makeSelectIsMobile,
	makeSelectStates,
	makeSelectZipcodes,
} from 'containers/App/selectors';
import makeSelectGeo from 'containers/Geo/selectors';
import makeSelectPatientRegister from 'containers/PatientRegister/selectors';
import AdultMedical from 'pages/intake/adult/Medical';
import AdultMedicalContinued from 'pages/intake/adult/MedicalContinued';
import AdultMedicalHistory from 'pages/intake/adult/MedicalHistory';
import AdultOtherHistory from 'pages/intake/adult/OtherHistory';
import AdultPsychiatricHistory from 'pages/intake/adult/PsychiatricHistory';
import AdultSleepHabits from 'pages/intake/adult/SleepHabits';
import ChildAdhdInfo from 'pages/intake/child/AdhdInfo';
import ChildAppointmentReason from 'pages/intake/child/AppointmentReason';
import ChildOtherIntake from 'pages/intake/child/OtherIntake';
import ChildSleepHabits from 'pages/intake/child/SleepHabits';
import Acknowledgements from 'pages/intake/shared/Acknowledgements';
import AuthorizeToSharePHI from 'pages/intake/shared/AuthorizeToSharePhi';
import FinancialAgreementIntake from 'pages/intake/shared/FinancialAgreement';
import InsuranceIntake from 'pages/intake/shared/Insurance';
import PrimaryCareIntake from 'pages/intake/shared/PrimaryCare';
import PrivacyPracticesIntake from 'pages/intake/shared/PrivacyPractices';
import IntakeQuestionnaire from 'pages/intake/shared/Questionnaire';
import Telehealth from 'pages/intake/shared/Telehealth';
import { isProduction } from 'utils/constants';
import PatientPayment from 'v2/routes/intake/PatientPayment';
import IopIntake from 'v2/routes/intake/PatientPaymentIOP';

import {
	checkOnboading,
	docCreate,
	docSign,
	fetchData,
	patientIntake,
	setPrevPath,
} from './actions';
import {
	STEPS,
	adultSteps,
	childSteps,
	iopPhpSteps,
	lastSigningStep,
	patientFinalStep,
} from './constants';
import './index.css';
import makeSelectPatientIntake, {
	makeSelectCreateDocSuccess,
	makeSelectCreatingDoc,
} from './selectors';
import { stateFields } from './state';

/* eslint-disable react/prefer-stateless-function */
export class Intake extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			siteUrl: '',
			creatingDoc: false,
			createDocSuccess: false,
			waitingOnDoc: false,
			waitingOnDocData: {},
			maxProgress: 15,
			currentProgress: 0,
			primaryCareChecked: false,
			therapistChecked: false,
			insuranceInformationreChecked: false,
			drugAlcoholPolicyChecked: false,
			behavioralGuideLinesChecked: false,
			cancellationPolicyChecked: false,
			authorizeToSharePHIChecked: false,
			groupTherapyConsentFormChecked: false,
			therapyGroupChecked: false,
			returnedCheckChecked: false,
			delinquentAccountsChecked: false,
			limitaionsOfConfidentialityChecked: false,
			mobileMsgFaxEmailChecked: false,
			MessagesChecked: false,
			emergencyAccessChecked: false,
			consentForTreatmentChecked: false,
			electronicRecordChecked: false,
			billingChecked: false,
			infoRelatedTreatmentChecked: false,
			patientAgreementChecked: false,
			openPaymentChecked: false,
			goodFaithEstimateChecked: false,
			financialAgreementChecked: false,
			IOPChecked: false,
			IOPAgreementChecked: false,
			guarantorChecked: false,
			programFee: '',
			patientPaymentDetailsChecked: false,
			patientPaymentAgreementChecked: false,
			sameAsMainAddress: false,
			privacyPracticesChecked: false,
			privacyNoticeNotSignedDescribe: '',
			authorizeToSharePHINotSignedDescribe: '',
			GroupStandardsChecked: false,
			telehealthChecked: false,
			virtualGroupChecked: false,
			loading: false,
			signingError: false,
			useCreditCard: true,
		};
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		const { patientIntakeFetchData, auth } = this.props;
		const { id } = auth.loggedInUser;
		this.setState({ name: auth.loggedInUser.name });
		patientIntakeFetchData({ id });
		this.setLocalState();
		this.props.doFetchStates();

		// Quick fix - the modal is somehow setting the body's class to "modal-open"
		document.body.className = '';
	}

	componentWillUnmount() {
		const { currentProgress } = this.state;
		const { doSetPrevPath } = this.props;

		if (currentProgress === 1) {
			history.push('/patient/registration');
			doSetPrevPath('Intake');
		}
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		// eslint-disable-next-line array-callback-return
		Object.keys(stateFields).map((comp) => {
			if (
				nextProps.patientIntakeFields.questionnaire[comp] &&
				!isEqual(
					nextProps.patientIntakeFields.questionnaire[comp],
					this.state[comp],
				) &&
				isEqual(nextState[comp], stateFields[comp])
			) {
				this.setState({
					[comp]: nextProps.patientIntakeFields.questionnaire[comp],
				});
			}
		});
		return true;
	};

	setLocalState = () => {
		// eslint-disable-next-line array-callback-return
		Object.keys(stateFields).map((comp) => {
			this.setState({
				[comp]: stateFields[comp],
			});
		});
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { maxProgress } = this.state;
		const { patientIntakeFields, createDocSuccess } = nextProps;

		if (this.state.creatingDoc && createDocSuccess) {
			this.setState({ creatingDoc: false });

			const { waitingOnDoc, waitingOnDocData } = this.state;
			if (waitingOnDoc) {
				this.saveData(waitingOnDocData);
				this.setState({ waitingOnDoc: false, waitingOnDocData: null });
			}
		}

		if (patientIntakeFields.registrationType) {
			this.setState({
				maxProgress:
					patientFinalStep[patientIntakeFields.registrationType] || 15,
			});
		}

		if (patientIntakeFields.url) {
			this.setState({
				siteUrl: patientIntakeFields.url,
				modal: true,
				loading: false,
				signingError: false,
			});
		} else if (
			(this.state.loading &&
				patientIntakeFields.response &&
				patientIntakeFields.response.status > 400) ||
			patientIntakeFields.code > 400
		) {
			this.setState({ loading: false, modal: false, signingError: true });
		}

		if (patientIntakeFields.checks) {
			Object.keys(patientIntakeFields.checks).forEach((fieldName) => {
				this.setState({
					[fieldName]: patientIntakeFields.checks[fieldName],
				});
			});
		}

		if (patientIntakeFields.intakeProgress > 0) {
			let { intakeProgress } = patientIntakeFields;
			if (
				intakeProgress <= maxProgress &&
				this.state.currentProgress !== intakeProgress
			) {
				if (intakeProgress > 4 && this.state.currentProgress === 0) {
					intakeProgress = 4;
				}
				this.setState({ currentProgress: intakeProgress });
				this.scrollTo(0);
			}
		}
	}

	toggle() {
		this.setState((prevState) => ({
			modal: !prevState.modal,
		}));
		this.props.checkOnboading();
	}

	nextProgress = (data, component) => (event) => {
		if (event) event.preventDefault();
		// This whole system needs a rework...
		const { currentProgress, maxProgress } = this.state;
		const {
			patientIntakeFields: { registrationType = 'adult' },
		} = this.props;

		let step = 1;
		let newCurrentProgress = currentProgress + step;
		const finalStep = patientFinalStep[registrationType];

		if (component) {
			data.id = this.props.auth.loggedInUser.id;
			data.questionnaire = {
				[component]: this.state[component],
			};
			const keys = Object.getOwnPropertyNames(data);
			data.q = keys.join();
		}

		data.intakeProgress =
			newCurrentProgress > maxProgress ? maxProgress : newCurrentProgress;
		data.currentProgress = newCurrentProgress;

		if (
			currentProgress < maxProgress ||
			!this.state.creatingDoc ||
			this.state.createDocSuccess
		) {
			let cb;
			const { docCreate, docSign } = this.props;

			if (newCurrentProgress === lastSigningStep + 1) {
				cb = docCreate;
			} else if (newCurrentProgress > finalStep) {
				cb = docSign;
			}

			this.saveData(data, cb);

			if (newCurrentProgress > maxProgress) {
				this.setState({ loading: true });
			}
		} else {
			this.setState({ waitingOnDoc: true, waitingOnDocData: data });
		}

		if (newCurrentProgress === lastSigningStep + 1) {
			this.setState({ createDocSuccess: false, creatingDoc: true });
		}

		if (newCurrentProgress <= maxProgress) {
			this.setState({ currentProgress: newCurrentProgress });
			this.scrollTo(0);
		}
	};

	saveData = (data, cb) => {
		const { patientIntakeForm } = this.props;
		patientIntakeForm(data, cb);
	};

	prevProgress = () => {
		const {
			patientIntakeFields: { registrationType = 'adult' },
		} = this.props;
		const { currentProgress } = this.state;

		let step = 1;
		if (registrationType === 'child' && currentProgress === 15) {
			step = 4;
		}

		this.saveData({ q: 'progress', intakeProgress: currentProgress - step });

		if (currentProgress > 0) {
			this.setState({ currentProgress: currentProgress - step });
		} else {
			window.history.back();
		}

		this.scrollTo(0);
	};

	goToStep = (step) => {
		const {
			patientIntakeFields: { maxIntakeProgress },
		} = this.props;
		if (step > maxIntakeProgress) return;

		this.saveData({ q: 'progress', intakeProgress: step });
		this.setState({ currentProgress: step });
	};

	handleChange = (event) => {
		const { target } = event;
		const { name, value } = target;

		this.setState({ [name]: value });
	};

	handleStateChange = (component) => (event) => {
		const { target } = event;
		event.preventDefault();
		const { name, value } = target;
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[component];
		componentState[name] = value;
		this.setState({ [component]: componentState });
	};

	handleDateChange = (component, id) => (date) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentState = this.state[component];
		componentState[id] = moment(date).format('YYYY-MM-DD');
		if (component === 'childBasicInfo' && id === 'dob') {
			if (date) {
				componentState.age = moment().diff(componentState[id], 'years');
			} else componentState.age = '';
		}

		this.setState({ [component]: componentState });
	};

	handleCheck = (component, checkboxGroup, id) => {
		// eslint-disable-next-line react/no-access-state-in-setstate
		const componentData = this.state[component];
		const group = componentData[checkboxGroup];
		const index = findIndex(group, (symptom) => symptom.id === id);
		group[index].isSelected = !group[index].isSelected;
		this.setState({ [component]: componentData });
	};

	handleAcceptance = (key) => {
		const value = this.state[key];

		this.setState({ [key]: !value });
	};

	closeRequestSentModal = () => {
		this.setState({
			signingError: false,
		});
	};

	handleGeoChange = (type, selectedItem) => {
		const { doFetchCities, doFetchZipcodes } = this.props;
		switch (type) {
			case 'state':
				doFetchCities(selectedItem.value, selectedItem.label);
				break;

			case 'city':
				doFetchZipcodes(selectedItem.value);
				break;

			default:
				break;
		}
	};

	getStep(progress) {
		const {
			patientIntakeFields: { registrationType = 'adult' },
			patientIntakeFields,
			geo,
			auth,
			states,
			cities,
			zipcodes,
			isMobile,
			doFetchCities,
			doFetchZipcodes,
		} = this.props;
		const { loggedInUser } = this.props.auth;
		const { authorizeToSharePHIChecked, authorizeToSharePHINotSignedDescribe } =
			this.state;

		const steps = this.getStepListByUserType();
		const currentStepName = steps[progress];

		switch (currentStepName) {
			case STEPS.Primary:
				return (
					<PrimaryCareIntake
						handleAcceptance={this.handleAcceptance}
						patientIntakeFields={patientIntakeFields}
						primaryCareChecked={this.state.primaryCareChecked}
						therapistChecked={this.state.therapistChecked}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						geo={geo}
						auth={auth}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						handleGeoChange={this.handleGeoChange}
						isMobile={isMobile}
					/>
				);
			case STEPS.Insurance:
				return (
					<InsuranceIntake
						handleAcceptance={this.handleAcceptance}
						patientIntakeFields={patientIntakeFields}
						insuranceInformationreChecked={
							this.state.insuranceInformationreChecked
						}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						isMobile={isMobile}
					/>
				);
			case STEPS.Acknowledgement:
				return (
					<Acknowledgements
						handleAcceptance={this.handleAcceptance}
						cancellationPolicyChecked={this.state.cancellationPolicyChecked}
						returnedCheckChecked={this.state.returnedCheckChecked}
						delinquentAccountsChecked={this.state.delinquentAccountsChecked}
						limitaionsOfConfidentialityChecked={
							this.state.limitaionsOfConfidentialityChecked
						}
						mobileMsgFaxEmailChecked={this.state.mobileMsgFaxEmailChecked}
						MessagesChecked={this.state.MessagesChecked}
						emergencyAccessChecked={this.state.emergencyAccessChecked}
						consentForTreatmentChecked={this.state.consentForTreatmentChecked}
						electronicRecordChecked={this.state.electronicRecordChecked}
						billingChecked={this.state.billingChecked}
						infoRelatedTreatmentChecked={this.state.infoRelatedTreatmentChecked}
						patientAgreementChecked={this.state.patientAgreementChecked}
						openPaymentChecked={this.state.openPaymentChecked}
						goodFaithEstimateChecked={this.state.goodFaithEstimateChecked}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						isMobile={isMobile}
					/>
				);
			case STEPS.Financial:
				return (
					<FinancialAgreementIntake
						handleAcceptance={this.handleAcceptance}
						financialAgreementChecked={this.state.financialAgreementChecked}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						isMobile={isMobile}
					/>
				);
			case STEPS.Payment:
				return (
					<PatientPayment
						handleAcceptance={this.handleAcceptance}
						patientIntakeFields={patientIntakeFields}
						patientPaymentDetailsChecked={
							this.state.patientPaymentDetailsChecked
						}
						patientPaymentAgreementChecked={
							this.state.patientPaymentAgreementChecked
						}
						sameAsMainAddress={this.state.sameAsMainAddress}
						useCreditCard={this.state.useCreditCard}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						isMobile={isMobile}
						states={states}
						cities={cities}
						zipcodes={zipcodes}
						fetchCities={doFetchCities}
						fetchZipcodes={doFetchZipcodes}
					/>
				);
			case STEPS.IopIntake:
				return (
					<IopIntake
						handleAcceptance={this.handleAcceptance}
						patientIntakeFields={patientIntakeFields}
						IOPChecked={this.state.IOPChecked}
						IOPAgreementChecked={this.state.IOPAgreementChecked}
						guarantorChecked={this.state.guarantorChecked}
						useCreditCard={this.state.useCreditCard}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						isMobile={isMobile}
					/>
				);
			case STEPS.Privacy:
				return (
					<PrivacyPracticesIntake
						handleAcceptance={this.handleAcceptance}
						handleChange={this.handleChange}
						privacyPracticesChecked={this.state.privacyPracticesChecked}
						privacyNoticeNotSignedDescribe={
							this.state.privacyNoticeNotSignedDescribe
						}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						isMobile={isMobile}
					/>
				);
			case STEPS.Phi:
				return (
					<AuthorizeToSharePHI
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						authorizeToSharePHIChecked={authorizeToSharePHIChecked}
						authorizeToSharePHINotSignedDescribe={
							authorizeToSharePHINotSignedDescribe
						}
						handleAcceptance={this.handleAcceptance}
						handleChange={this.handleChange}
						isMobile={isMobile}
					/>
				);
			case STEPS.Telehealth:
				return (
					<Telehealth
						handleAcceptance={this.handleAcceptance}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						telehealthChecked={this.state.telehealthChecked}
						isMobile={isMobile}
					/>
				);
			case STEPS.Group:
				return (
					<GroupStandards
						handleAcceptance={this.handleAcceptance}
						patientDetail={registrationType}
						nextProgress={this.nextProgress}
						prevProgress={this.prevProgress}
						groupStandardsChecked={this.state.groupStandardsChecked}
						isMobile={isMobile}
					/>
				);
			case STEPS.ChildInfo:
				return (
					<ChildBasicInfo
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						data={this.state.childBasicInfo}
						handleChange={this.handleStateChange}
						handleDateChange={this.handleDateChange}
						isMobile={isMobile}
					/>
				);
			case STEPS.Questionnaire:
				return (
					<IntakeQuestionnaire
						patientIntakeFields={patientIntakeFields}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						isMobile={isMobile}
					/>
				);
			case STEPS.Behavioral:
				return (
					<BehavioralGuidelines
						behavioralGuideLinesChecked={this.state.behavioralGuideLinesChecked}
						handleAcceptance={this.handleAcceptance}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						isMobile={isMobile}
					/>
				);
			case STEPS.ChidlAppointmentReason:
				return (
					<ChildAppointmentReason
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						data={this.state.childAppointmentReason}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultMedical:
				return (
					<AdultMedical
						patientIntakeFields={patientIntakeFields}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						data={this.state.adultMedical}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						handleDateChange={this.handleDateChange}
						isMobile={isMobile}
					/>
				);
			case STEPS.DrugAlcohol:
				return (
					<DrugAlcoholPolicy
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						drugAlcoholPolicyChecked={this.state.drugAlcoholPolicyChecked}
						handleAcceptance={this.handleAcceptance}
						isMobile={isMobile}
					/>
				);
			case STEPS.ChildAdhdInfo:
				return (
					<ChildAdhdInfo
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						data={this.state.childAdhdInfo}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultMedicalContinued:
				return (
					<AdultMedicalContinued
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.adultMedicalContinued}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.ChildSleepHabits:
				return (
					<ChildSleepHabits
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.childSleepHabits}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultSleepHabits:
				return (
					<AdultSleepHabits
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.adultSleepHabits}
						handleChange={this.handleStateChange}
						isMobile={isMobile}
					/>
				);
			case STEPS.Consent:
				return (
					<GroupTherapyConsentFrom
						name={loggedInUser.name}
						handleAcceptance={this.handleAcceptance}
						groupTherapyConsentFormChecked={
							this.state.groupTherapyConsentFormChecked
						}
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						patientDetail={patientIntakeFields.registrationType}
						isMobile={isMobile}
					/>
				);
			case STEPS.ChildOtherIntake:
				return (
					<ChildOtherIntake
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.childOtherIntake}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
						isUploading={this.props.creatingDoc}
					/>
				);
			case STEPS.Substance:
				return (
					<AdultSubstanceAbuse
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.adultSubstanceAbuse}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultPsychiatricHistory:
				return (
					<AdultPsychiatricHistory
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={
							this.state.adultPsychiatricHistory ||
							stateFields.adultPsychiatricHistory
						}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultMedicalHistory:
				return (
					<AdultMedicalHistory
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={
							this.state.adultMedicalHistory || stateFields.adultMedicalHistory
						}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
					/>
				);
			case STEPS.AdultOtherHistory:
				return (
					<AdultOtherHistory
						prevProgress={this.prevProgress}
						nextProgress={this.nextProgress}
						auth={auth}
						data={this.state.adultOtherHistory || stateFields.adultOtherHistory}
						handleChange={this.handleStateChange}
						handleCheck={this.handleCheck}
						isMobile={isMobile}
						isUploading={this.props.creatingDoc}
					/>
				);

			default:
				return <div>Invalid Step</div>;
		}
	}

	getStepListByUserType = () => {
		const {
			patientIntakeFields: { registrationType = 'adult' },
		} = this.props;
		let steps = [];

		switch (registrationType) {
			case 'iopPhp':
				steps = iopPhpSteps;
				break;
			case 'child':
				steps = childSteps;
				break;
			case 'adult':
			default:
				steps = adultSteps;
				break;
		}

		return steps;
	};

	scrollTo = (value) => {
		document.body.scrollTo({ top: value, behavior: 'smooth' });
	};

	render() {
		const { isMobile } = this.props;
		const headerHeight = isMobile ? 60 : 100;
		const { currentProgress, maxProgress } = this.state;
		const { loggedInUser } = this.props.auth;
		// const steps = this.getStepListByUserType();
		/* const quickJump = steps.map((s, i) => (
			<option
				value={i}
				disabled={i > maxIntakeProgress}
				selected={i === currentProgress}
				key={stepNameToLabel[s]}
				onClick={() => this.goToStep(i)}
			>
				{`${i + 1} - ${stepNameToLabel[s]}`}
			</option>
		)); */

		return (
			<div className="position-relative">
				{this.props.creatingDoc && (
					<div
						className="row"
						style={{ position: 'fixed', top: 110, right: 40 }}
					>
						<div className="col-auto px-0 pr-2 link-color">
							Uploading Intake Doc
						</div>
						<div className="col-auto px-0">
							<SmallLoadingIndicator
								color="var(--main-color)"
								className=""
								style={{ width: '20px', height: '20px', marginTop: '4px' }}
							/>
						</div>
					</div>
				)}
				{/*process.env.NODE_ENV !== 'production' && (
          <div
            style={{
              position: 'absolute',
              top: 120,
              left: 20,
            }}
          >
            <select
              className="custom-select"
              placeholder="Select step..."
              id="quickJump"
              name="quickJump"
            >
              {quickJump}
            </select>
          </div>
          )*/}
				{this.state.loading && <LoadingIndicator />}
				{this.state.signingError && (
					<StatusModal
						successStatus={false}
						hide={this.closeRequestSentModal}
						message="Something went wrong. Please login again after some time and complete the
            intake."
					/>
				)}
				<div
					className="w-100 sticky"
					style={{ position: 'sticky', top: 0, zIndex: 1000 }}
				>
					<CommonHeader
						routeProps={this.props}
						user={loggedInUser}
						name="Patient Intake and Consent"
						height={headerHeight}
						isMobile={isMobile}
					/>
					<Progress
						className="prog"
						color="success"
						value={(currentProgress / maxProgress) * 100}
					/>
				</div>
				{/* <div
          className="container"
        >    
          <div 
            className={`${isMobile ? '' : 'pl-5'}`}
            style={{ 
              width: 410,
              margin: isMobile ? '0 auto' : '0'
            }}
          >
            <SelectAutoComplete
              placeholder="Jump to step..."
              options={quickJump}
              value={currentStep}
              handleChange={o => this.goToStep(o.value)}
              controlStyles={{
                fontSize: 18
              }}
              optionStyles={{
                fontSize: 14
              }}
            />
          </div>
        </div> */}
				<div className="content" style={{ width: '100vw' }}>
					{!isProduction && (
						<div
							style={{
								position: 'fixed',
								right: 20,
								zIndex: 100,
							}}
						>
							<button
								className="card"
								style={{ color: 'var(--main-color)' }}
								onClick={() => this.scrollTo(0)}
							>
								&#9650;
							</button>
							<button
								className="card"
								style={{ color: 'var(--main-color)' }}
								onClick={() => this.scrollTo(document.body.scrollHeight)}
							>
								&#9660;
							</button>
						</div>
					)}
					<div
						className={`container ${isMobile && 'px-0'}`}
						style={{ minHeight: window.innerHeight - headerHeight - 64 }}
					>
						{this.getStep(currentProgress)}
					</div>
					<Footer />
				</div>
				<SigningModal
					isOpen={this.state.modal}
					siteUrl={this.state.siteUrl}
					toggle={this.toggle}
				/>
			</div>
		);
	}
}

Intake.propTypes = {
	patientIntakeForm: PropTypes.func.isRequired,
	patientIntakeFields: PropTypes.object.isRequired,
	patientIntakeFetchData: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	checkOnboading: PropTypes.func.isRequired,
	doSetPrevPath: PropTypes.func.isRequired,
	geo: PropTypes.object.isRequired,
	states: PropTypes.array,
	cities: PropTypes.object,
	zipcodes: PropTypes.object,
	doFetchStates: PropTypes.func.isRequired,
	doFetchCities: PropTypes.func.isRequired,
	doFetchZipcodes: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	patientRegister: makeSelectPatientRegister(),
	patientIntakeFields: makeSelectPatientIntake(),
	createDocSuccess: makeSelectCreateDocSuccess(),
	creatingDoc: makeSelectCreatingDoc(),
	states: makeSelectStates(),
	cities: makeSelectCities(),
	zipcodes: makeSelectZipcodes(),
	auth: makeSelectAuth(),
	geo: makeSelectGeo(),
	isMobile: makeSelectIsMobile(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		patientIntakeForm: (data, cb) => dispatch(patientIntake(data, cb)),
		docCreate: () => dispatch(docCreate()),
		docSign: () => dispatch(docSign()),
		patientIntakeFetchData: (data) => dispatch(fetchData(data)),
		checkOnboading: () => dispatch(checkOnboading()),
		doSetPrevPath: (data) => dispatch(setPrevPath(data)),
		doFetchStates: () => dispatch(fetchStates()),
		doFetchCities: (stateCode, stateName) =>
			dispatch(fetchCities(stateCode, stateName)),
		doFetchZipcodes: (cityName) => dispatch(fetchZipcodes(cityName)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Intake);
