import moment from 'moment';

import PropTypes from 'prop-types';

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { BULLET } from 'constants/main';
import { capitalizeWords, getRepeatDaysFromFrequency } from 'utils/helpers';

import './GroupDateModal.css';

/* eslint-disable react/prefer-stateless-function */
class GroupDateModal extends React.Component {
	closeModal = () => {
		const { hide } = this.props;
		hide();
	};

	render() {
		const { cardGroupDetails, addToBucket } = this.props;
		return (
			<div>
				<Modal
					isOpen="true"
					className="modal-dialog-centered border-0"
					backdrop="static"
				>
					<ModalHeader
						toggle={this.closeModal}
						className="border-0 font-30 text-dark cust-datemodal-header"
					>
						{`${cardGroupDetails.group_type} ${BULLET} ${capitalizeWords(
							cardGroupDetails.location,
						)}`}
					</ModalHeader>
					<ModalBody>
						<div className="d-flex flex-column">
							<div className="d-flex align-items-center">
								<ImageDetailsCell
									src={cardGroupDetails.profilePicture}
									heading={cardGroupDetails.clinician_name}
									primarySubHeading={cardGroupDetails.therapist_type}
								/>
								{/* <div className="pr-1">
                  <i className="fas fa-user-circle fa-2x" />
                </div> */}
								{/* <div className="ml-1 align-self-center">
                  <h6 className="text-dark cust-datemodal-clinician-name m-0 font-17">
                    {cardGroupDetails.clinician_name}
                  </h6>
                  <p className="cust-datemodal-clinician-title mx-0 mb-0 mt-1 font-11">
                    {cardGroupDetails.clinician_type}
                  </p>
                </div> */}
							</div>
							<div className="d-flex justify-content-between py-3">
								<div>
									<div className="text-cust-grey font-10 cust-datemodal-label text-uppercase p-1">
										Last Session
									</div>
									<div className="text-dark cust-datemodal-label-value font-13 p-1">
										{moment(cardGroupDetails.lastSession).format(
											'MMM D[,] YYYY',
										)}
									</div>
								</div>
								<div>
									<div className="text-cust-grey font-10 cust-datemodal-label text-uppercase p-1">
										Upcoming Session
									</div>
									<div className="text-dark cust-datemodal-label-value font-13 p-1">
										{moment(cardGroupDetails.upcomingSession).format(
											'MMM D[,] YYYY',
										)}
									</div>
								</div>
								<div>
									<div className="text-cust-grey font-10 cust-datemodal-label text-uppercase p-1">
										Price
									</div>
									<div className="text-dark cust-datemodal-label-value font-13 p-1">
										${cardGroupDetails.price}
										/m
									</div>
								</div>
								<div>
									<div className="text-cust-grey font-10 cust-datemodal-label text-uppercase p-1">
										Enrollees
									</div>
									<div className="text-dark cust-datemodal-label-value font-13 p-1">
										{cardGroupDetails.members ? cardGroupDetails.members : 0}
									</div>
								</div>
							</div>
						</div>
						<div className="border-top">
							<div className="p-3">
								<div className="font-weight-bold text-dark font-17">
									Schedule
								</div>
								<div className="d-flex align-items-center">
									<div className="font-weight-bold text-dark font-17">
										<i className="far fa-clock" />
									</div>
									<div className="ml-2 font-weight-bold text-dark font-17">
										{moment
											.utc(cardGroupDetails.startTime, 'HH:mm A')
											.local()
											.format('hh:mm A')}{' '}
										to{' '}
										{moment
											.utc(cardGroupDetails.endTime, 'HH:mm A')
											.local()
											.format('hh:mm A')}
									</div>
								</div>
								<div className="pl-2 ml-3 text-dark font-17">
									Repeats on{' '}
									{
										getRepeatDaysFromFrequency(
											cardGroupDetails.repeat_frequency,
											cardGroupDetails.repeats_on,
										).repeatDays
									}
								</div>
							</div>
							<div className="d-flex justify-content-end ml-auto">
								<button
									type="button"
									className="btn btn-color-cust px-5 py-2 link-color font-10"
									onClick={() => {
										addToBucket(cardGroupDetails.program_id);
									}}
								>
									Add
								</button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

GroupDateModal.propTypes = {
	hide: PropTypes.func.isRequired,
	cardGroupDetails: PropTypes.object.isRequired,
	addToBucket: PropTypes.func.isRequired,
};

export default GroupDateModal;
