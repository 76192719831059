export const typeToFullName = (type) => {
	let requestType = type;
	const lowerType = type.toLowerCase();

	if (lowerType.includes('leave')) requestType = 'Request for Leave of Absence';
	else if (lowerType.includes('general')) requestType = 'General Request';
	else if (lowerType.includes('rx')) requestType = 'Request for Rx';
	else if (lowerType.includes('discontinuance'))
		requestType = 'Request for Discontinuance';
	else if (lowerType.includes('enrollment')) requestType = 'Enrollment Request';
	else if (lowerType.includes('roi')) requestType = 'Release of Information';
	else if (lowerType.includes('form')) requestType = 'Form';
	else if (lowerType.includes('registration'))
		requestType = 'completed their registration';

	return requestType;
};
