import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import loadIcon from 'images/load-icon.gif';
import {
    getOQAdministrationURL,
    sendOQAdministrationSMS,
} from './actions';

const measuresList = [
    ['1', 'OQ®-45.2 English'],
    ['121', 'CSSRS English'],
    ['9', 'OQ®-ASC English'],
];

type Props = {
    isOpen: boolean;
    toggle: () => void;
    client: ClientData;
}

const AdministrationModal = (props: Props): JSX.Element => {
    const { isOpen, toggle, client } = props;
    const {
        administrationURL,
        isFetchingURL,
        isSendingSMS,
    } = useSelector((state: RootState) => state.get('measures').toJS());
    const dispatch = useDispatch();

    const [tooltipMessage, setTooltipMessage] = useState('Copy to Clipboard');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedMeasure, setSelectedMeasure] = useState('1'); // Default to OQ 45.2

    const formatttedPhoneNumber = formatPhoneNumber(client?.client?.phone);
    const selectedMeasureLabel = measuresList.find(([value]) => value === selectedMeasure)?.[1];

    const handleGetAdministration = () => {
        dispatch(
            getOQAdministrationURL({
                patientId: client.patientId.toString(),
                instrumentIds: selectedMeasure,
            })
        );
    };

    const SendSMSButton = (): JSX.Element => (
        <div>
            <button
                className="position-relative btn btn-primary primary-btn-color px-3 py-2 font-10"
                onClick={() => setShowConfirmationModal(true)}
                disabled={isSendingSMS}
            >
                {isSendingSMS &&
                    <img
                        className="position-absolute m-0 p-0"
                        style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                        src={loadIcon}
                        alt="Loading..."
                        height="20px"
                    />}
                <span style={{ color: isSendingSMS ? 'transparent' : 'inherit' }}>Send Text to Client</span>
            </button>
            {showConfirmationModal && (
                <Modal
                    className="modal-dialog-centered custom-modal"
                    isOpen={showConfirmationModal}
                    toggle={() => setShowConfirmationModal(false)}
                >
                    <ModalHeader toggle={() => setShowConfirmationModal(false)}>Confirmation</ModalHeader>
                    <ModalBody>
                        <span>
                            Are you sure you want to send the <b>{selectedMeasureLabel}</b> link to the client at <b>{formatttedPhoneNumber}</b>?
                        </span>
                        <div className="d-flex flex-row justify-content-between w-100 mt-4">
                            <button
                                className="btn btn-white bg-white btn-lg btn-block border mt-2 font-15 mr-2"
                                onClick={() => setShowConfirmationModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary btn-lg btn-block btn-color font-15"
                                onClick={() => {
                                    handleSendSMS();
                                    setShowConfirmationModal(false);
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );

    const AdministrationURL = (
        { handleCopy }: { handleCopy: (e: React.SyntheticEvent<HTMLButtonElement>) => void }
    ): JSX.Element => (
        <form className="w-100">
            <div className="input-group">
                <input
                    type="text"
                    className="form-control "
                    value={administrationURL}
                    placeholder="Your link"
                    readOnly
                />
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{tooltipMessage}</Tooltip>}
                >
                    <span className="input-group-btn ">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={handleCopy}
                        >
                            Copy
                        </button>
                    </span>
                </OverlayTrigger>
            </div>
        </form>
    );

    const GetAdministrationButton = (): JSX.Element => (
        <button
            className="position-relative btn btn-primary primary-btn-color px-3 py-2 font-10"
            onClick={handleGetAdministration}
            disabled={isFetchingURL}
        >
            {isFetchingURL &&
                <img
                    className="position-absolute m-0 p-0"
                    style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                    src={loadIcon}
                    alt="Loading..."
                    height="20px"
                />}
            <span style={{ color: isFetchingURL ? 'transparent' : 'white' }}>Get Administration URL</span>
        </button>
    );

    const handleSendSMS = () => {
        dispatch(
            sendOQAdministrationSMS({
                patientId: client.patientId.toString(),
                phoneNumber: client.client.phone,
                instrumentIds: selectedMeasure,
            })
        );
    };

    const handleCopy = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            await navigator.clipboard.writeText(administrationURL);
            setTooltipMessage('Copied!');
            setTimeout(() => setTooltipMessage('Copy to Clipboard'), 1000);
        } catch (err) {
            setTooltipMessage('Copy with Ctrl-C');
            setTimeout(() => setTooltipMessage('Copy to Clipboard'), 1000);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className="modal-dialog-centered custom-modal"
            backdrop="static"
            toggle={toggle}
            style={{
                width: '100%',
                maxWidth: 752,
                minHeight: 500,
                margin: '0.5rem auto',
            }}
        >
            <ModalHeader toggle={toggle}>Administration for {client?.client?.name}</ModalHeader>
            <ModalBody>
                <div className="d-flex flex-column justify-content-between align-items-center mb-3">
                    <div className="d-flex w-100 justify-content-between align-items-center mb-4">
                        <div className="align-items-center justify-content-center pt-2">
                            <label className="mr-2">Select measure: </label>
                            <select
                                name="measures"
                                value={selectedMeasure}
                                onChange={(e) => setSelectedMeasure(e.target.value)}
                            >
                                {measuresList.map(([value, label]) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <GetAdministrationButton />
                    </div>
                    <div className="d-flex w-100 mb-4">
                        <AdministrationURL handleCopy={handleCopy} />
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <label>Send link to {formatttedPhoneNumber}: </label>
                        <SendSMSButton />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default AdministrationModal;