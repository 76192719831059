import PropTypes from 'prop-types';

import React from 'react';

import Circle from 'components/LoadingIndicator/Circle';
import Wrapper from 'components/LoadingIndicator/Wrapper';
import 'components/LoadingIndicator/index.css';

const SmallLoadingIndicator = (props) => {
	const {
		text,
		color = 'white',
		fontSize,
		className = 'my-4',
		inline,
		size,
	} = props;
	let { style } = props;

	if (size === 'small') {
		style = { width: '20px', height: '20px', margin: '0 auto', ...style };
	}

	return (
		<div
			className={`${
				inline ? 'row mt-2' : 'd-flex flex-column'
			} justify-content-center w-100`}
		>
			<Wrapper className={className} style={style}>
				<Circle color={color} />
				<Circle color={color} rotate={30} delay={-1.1} />
				<Circle color={color} rotate={60} delay={-1} />
				<Circle color={color} rotate={90} delay={-0.9} />
				<Circle color={color} rotate={120} delay={-0.8} />
				<Circle color={color} rotate={150} delay={-0.7} />
				<Circle color={color} rotate={180} delay={-0.6} />
				<Circle color={color} rotate={210} delay={-0.5} />
				<Circle color={color} rotate={240} delay={-0.4} />
				<Circle color={color} rotate={270} delay={-0.3} />
				<Circle color={color} rotate={300} delay={-0.2} />
				<Circle color={color} rotate={330} delay={-0.1} />
			</Wrapper>
			{text && (
				<h4
					className="d-flex justify-content-center"
					style={{ fontSize, color }}
				>
					{text}
				</h4>
			)}
		</div>
	);
};

SmallLoadingIndicator.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
	fontSize: PropTypes.number,
	className: PropTypes.string,
	inline: PropTypes.bool,
	size: PropTypes.string,
};

export default SmallLoadingIndicator;
