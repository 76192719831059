import api from 'utils/api';

export const intakeTestApi = (data) => {
	return api
		.post(`/patient/${data.senderUserId}/intake-test`, data)
		.then((response) => response.data)
		.catch((err) => err);
};

export const intakeApi = (data) =>
	api
		.post(`/patient/${data.id}/intake`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const signingApi = (data) =>
	api
		.post(`/patient/${data.id}/intake-signing`, data)
		.then((response) => response.data)
		.catch((err) => err);
