/*
 *
 * SignUp constants
 *
 */

export const TOGGLE_VERIFICATION = 'src/SignUp/TOGGLE_VERIFICATION';
export const STORE_DATA = 'src/SignUp/STORE_DATA';
export const CHECK_USER_EXIST = 'src/SignUp/CHECK_USER_EXIST';
export const USER_EXIST = 'src/SignUp/USER_EXIST';
export const USER_DOES_NOT_EXIST = 'src/SignUp/USER_DOES_NOT_EXIST';
export const SEND_OTP = 'src/SignUp/SEND_OTP';
export const PHONE_VALIDATION = 'src/SignUp/PHONE_VALIDATION';
export const VALIDATE_OTP = 'src/SignUp/VALIDATE_OTP';
export const DO_SIGN_UP = 'src/SignUp/PERFORM_SIGN_UP';
export const SUCCESS = 'src/SignUp/SUCCESS';
export const SUCCESS_VERIFIED = 'src/SignUp/SUCCESS_VERIFIED';
export const SHOW_ERROR = 'src/SignUp/SHOW_ERROR';
export const REMOVE_ERROR = 'src/SignUp/REMOVE_ERROR';
export const ERROR = 'src/SignUp/ERROR';
