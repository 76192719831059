import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchNotificationsAPI, updateNotificationsAPI } from 'utils/api';

import {
	fetchNotificationsError,
	fetchNotificationsSuccess,
	updateNotificationsError,
	updateNotificationsSuccess,
} from './actions';
import { FETCH_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from './constants';

export default function* notificationsSaga() {
	yield takeLatest(FETCH_NOTIFICATIONS, fetchNotifications);
	yield takeLatest(UPDATE_NOTIFICATIONS, updateNotifications);
}

export function* fetchNotifications() {
	try {
		const response = yield call(fetchNotificationsAPI);

		if (response.data) {
			yield put(fetchNotificationsSuccess(response.data));
		} else {
			yield put(fetchNotificationsError('Could not load notifications'));
		}
	} catch (err) {
		yield put(fetchNotificationsError('Could not load notifications'));
	}
}

export function* updateNotifications(action) {
	try {
		const { data } = action;
		const response = yield call(updateNotificationsAPI, data);

		if (response.data) {
			yield put(updateNotificationsSuccess(response.data.updatedRecords));
		} else {
			yield put(updateNotificationsError('Could not load notifications'));
		}
	} catch (err) {
		yield put(updateNotificationsError('Could not load notifications'));
	}
}
