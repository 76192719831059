export const clinicianTypes = [
	'Art Therapist',
	'Clinician Social Work/Therapist',
	'Counselor',
	'Drug and Alcohol Counselor',
	'Licensed Professional Counselor',
	'Licensed Psychoanalyst',
	'Limited Licensed Psychologist',
	'LPC Intern',
	'Marriage and Family Therapist',
	'Marriage and Family Therapist Intern',
	'Marriage and Family Therapist Associate',
	'Nutrition Therapist',
	'Occupational Therapist',
	'Pastoral Counselor',
	'Pre-Licensed Professional',
	'Psychiatric Nurse',
	'Psychiatric Nurse Practitioner',
	'Psychiatrist',
	'Psychological Associate',
	'Psychologist',
	'Registered Psychotherapist',
	'Treatment Center',
	'Yoga Therapist',
	'Other',
];
