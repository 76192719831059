/*
 *
 * GroupDetail constants
 *
 */

export const DEFAULT_ACTION = 'src/GroupDetail/DEFAULT_ACTION';

export const GET_ATTENDANCE_SUCCESS = 'src/GroupDetail/GET_ATTENDANCE_SUCCESS';
export const GET_ATTENDANCE_ERROR = 'src/GroupDetail/GET_ATTENDANCE_ERROR';
export const GET_ATTENDANCE = 'src/GroupDetail/GET_ATTENDANCE';

export const SAVE_ATTENDANCE = 'src/GroupDetail/SAVE_ATTENDANCE';
export const SAVE_ATTENDANCE_SUCCESS =
	'src/GroupDetail/SAVE_ATTENDANCE_SUCCESS';
export const SAVE_ATTENDANCE_ERROR = 'src/GroupDetail/SAVE_ATTENDANCE_ERROR';

export const GET_SESSIONS_SUCCESS = 'src/GroupDetail/GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_ERROR = 'src/GroupDetail/GET_SESSIONS_ERROR';
export const GET_SESSIONS = 'src/GroupDetail/GET_SESSIONS';

export const ADD_SESSION = 'src/GroupDetail/ADD_SESSION';
export const ADD_SESSION_SUCCESS = 'src/GroupDetail/ADD_SESSION_SUCCESS';
export const ADD_SESSION_ERROR = 'src/GroupDetail/ADD_SESSION_ERROR';

export const EXTEND_SESSIONS = 'src/GroupDetail/EXTEND_SESSIONS';
export const EXTEND_SESSIONS_SUCCESS =
	'src/GroupDetail/EXTEND_SESSIONS_SUCCESS';
export const EXTEND_SESSIONS_ERROR = 'src/GroupDetail/EXTEND_SESSIONS_ERROR';

export const DELETE_SESSIONS = 'src/GroupDetail/DELETE_SESSIONS';
export const DELETE_SESSIONS_SUCCESS =
	'src/GroupDetail/DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_ERROR = 'src/GroupDetail/DELETE_SESSIONS_ERROR';

export const GET_CLIENTS = 'src/GroupDetail/GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'src/GroupDetail/GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_ERROR = 'src/GroupDetail/GET_CLIENTS_ERROR';

export const FETCH_ALL_CLIENTS = 'src/GroupDetail/FETCH_ALL_CLIENTS';
export const FETCH_ALL_CLIENTS_SUCCESS =
	'src/GroupDetail/FETCH_ALL_CLIENTS_SUCCESS';
export const FETCH_ALL_CLIENTS_ERROR =
	'src/GroupDetail/FETCH_ALL_CLIENTS_ERROR';

export const ADD_CLIENT = 'src/GroupDetail/ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'src/GroupDetail/ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR = 'src/GroupDetail/ADD_CLIENT_ERROR';

export const UNENROLL = 'src/GroupDetail/UNENROLL';
export const UNENROLL_SUCCESS = 'src/GroupDetail/UNENROLL_SUCCESS';
export const UNENROLL_ERROR = 'src/GroupDetail/UNENROLL_ERROR';

export const LOAD_PROGRAM = 'src/GroupDetail/LOAD_PROGRAM';
export const LOAD_PROGRAM_SUCCESS = 'src/GroupDetail/LOAD_PROGRAM_SUCCESS';
export const LOAD_PROGRAM_ERROR = 'src/GroupDetail/LOAD_PROGRAM_ERROR';

export const UPDATE_ENROLLMENT = 'src/GroupDetail/UPDATE_ENROLLMENT';
export const UPDATE_ENROLLMENT_SUCCESS =
	'src/GroupDetail/UPDATE_ENROLLMENT_SUCCESS';
export const UPDATE_ENROLLMENT_ERROR =
	'src/GroupDetail/UPDATE_ENROLLMENT_ERROR';
