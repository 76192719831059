import { createSelector } from 'reselect';

import { returnJS } from 'utils/helpers';

import { initialState } from './reducer';

/**
 * Direct selector to the userForgotPassword state domain
 */

const selectUserForgotPasswordDomain = (state) =>
	state.get('userForgotPassword', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserForgotPassword
 */

const makeSelectUserForgotPassword = () =>
	createSelector(selectUserForgotPasswordDomain, (substate) =>
		returnJS(substate),
	);

export default makeSelectUserForgotPassword;
export { selectUserForgotPasswordDomain };
