/*
 *
 * UserDropDown reducer
 *
 */
import { fromJS } from 'immutable';

import { LOGIN_USER_SUCCESS } from 'containers/SignIn/constants';

import {
	ERROR,
	LIST_USERS,
	LOGOUT,
	PASSWORD_ERROR,
	PASSWORD_VERIFIED,
	REMOVE_PASSWORD_ERROR,
	TOGGLE_EMAIL,
	TOGGLE_NOTIFICATIONS,
	TOGGLE_PASSWORD,
	TOGGLE_PHONE,
	TOGGLE_SETTINGS,
	UPDATE_EMAIL,
	UPDATE_MULTI,
	UPDATE_MULTI_ERROR,
	UPDATE_MULTI_SUCCESS,
	UPDATE_NAME,
	UPDATE_PASSWORD,
	UPDATE_PASSWORD_OTHERS,
	UPDATE_PHONE,
	UPDATE_USERS,
	VERIFY_PASSWORD,
} from './constants';

export const initialState = fromJS({
	loggedInUser: {},
	showNotifications: false,
	showSettings: false,
	showEmailUpdate: false,
	showPassUpdate: false,
	showPhoneUpdate: false,
	passwordVerified: false,
	passwordError: '',
	users: [],
	isUpdating: false,
	multiError: false,
	multiResult: [],
});

function userDropDownReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_USER_SUCCESS:
			return state.mergeDeep({ loggedInUser: action.successData.loggedInUser });
		case TOGGLE_NOTIFICATIONS:
			return state.set('showNotifications', !state.get('showNotifications'));
		case TOGGLE_SETTINGS:
			return state.set('showSettings', !state.get('showSettings'));
		case TOGGLE_EMAIL:
			return state.set('showEmailUpdate', !state.get('showEmailUpdate'));
		case TOGGLE_PASSWORD:
			return state.set('showPassUpdate', !state.get('showPassUpdate'));
		case TOGGLE_PHONE:
			return state.set('showPhoneUpdate', !state.get('showPhoneUpdate'));
		case VERIFY_PASSWORD:
			return state;
		case UPDATE_NAME:
			return state;
		case UPDATE_EMAIL:
			return state;
		case UPDATE_PHONE:
			return state;
		case UPDATE_PASSWORD:
			return state;
		case UPDATE_PASSWORD_OTHERS:
			return state;
		case LIST_USERS:
			return state;
		case UPDATE_USERS:
			return state.set('users', action.users);
		case PASSWORD_VERIFIED:
			return state.set('passwordVerified', !state.get('passwordVerified'));
		case LOGOUT:
			return state;
		case PASSWORD_ERROR:
			return state.set('passwordError', action.errorMsg);
		case REMOVE_PASSWORD_ERROR:
			return state.set('passwordError', action.nullMsg);
		case ERROR:
			return state;
		case UPDATE_MULTI:
			return state.merge({
				isUpdating: true,
				multiError: false,
				multiResult: [],
			});
		case UPDATE_MULTI_SUCCESS:
			return state.merge({
				isUpdating: false,
				multiResult: action.data,
			});
		case UPDATE_MULTI_ERROR:
			return state.merge({
				isUpdating: false,
				multiError: true,
			});
		default:
			return state;
	}
}

export default userDropDownReducer;
