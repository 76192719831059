import history from 'utils/history';

import { startCase } from 'lodash';

import moment from 'moment';

import React, { useEffect, useState } from 'react';

import CircularMenu from 'atoms/CircularMenu';
import DefaultAvatar from 'atoms/DefaultAvatar';
import Table from 'atoms/Table';
import ImageDetailsCell from 'atoms/TableCell/ImageDetailsCell';
import { getAttendanceColor } from 'utils/helpers';

import './style.css';

const AttendanceTable = (props) => {
	const {
		sessionData = [],
		type,
		editing,
		viewAsPatient,
		handleSelectAllSessions,
		handleSelectSession,
		selectedSessions = [],
		handleAttendanceChange,
		loading,
		auth,
		sessions,
	} = props;

	const [allSelected, setAllSelected] = useState(false);
	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);

	const isCircularMenuVisible = type === 'admin' || type === 'clinician';

	const isDateInRange = (session, row) => {
		const { startDate, endDate } = row;
		const startsBefore = moment
			.utc(startDate)
			.local()
			.startOf('day')
			.isBefore(moment.utc(session.startDate).local().endOf('day'));
		const endsAfter =
			!endDate ||
			moment
				.utc(endDate)
				.local()
				.endOf('day')
				.isAfter(moment.utc(session.endDate).local().startOf('day'));

		return startsBefore && endsAfter;
	};

	const generateAttendanceCell = (session, row) => {
		let {
			attendanceData: { attendance },
		} = row;

		if (!Array.isArray(attendance)) {
			attendance = [];
		}

		const sessionAttendance =
			attendance.find((a) => a.sessionId === session.sessionId) || {};
		const { status = '', notes = '', patientId = '' } = sessionAttendance;

		return isCircularMenuVisible ? (
			isDateInRange(session, row) && (
				<CircularMenu
					status={status}
					comment={notes}
					handleChange={handleAttendanceChange(row.patientId, session)}
					mode={type === 'patient' ? 'view' : 'edit'}
					circleStyle={{ margin: '0 auto' }}
				/>
			)
		) : isDateInRange(session, row) ? (
			<DefaultAvatar
				name={status}
				style={{
					backgroundColor: getAttendanceColor(status),
					margin: '0 auto',
				}}
				mode="view"
				comment={notes}
				id={moment(session.startDate).format('ddddMMMMDoYYYY') + patientId}
			/>
		) : (
			<DefaultAvatar
				style={{ backgroundColor: 'white' }}
				mode="view"
				comment={notes}
				id={moment(session.startDate).format('ddddMMMMDoYYYY') + patientId}
			/>
		);
	};

	const generateHeading = (session, i) => (
		<div>
			{editing && (
				<input
					className="hand"
					type="checkbox"
					key={`checkbox-${i}`}
					onClick={() => handleSelectSession(session.sessionId)}
					checked={selectedSessions.includes(session.sessionId)}
					style={{
						width: '15px',
						height: '15px',
					}}
				/>
			)}
			<div>{moment.utc(session.startDate).local().format('D')}</div>
		</div>
	);

	const handleSelectAll = () => {
		handleSelectAllSessions();
		setAllSelected(!allSelected);
	};

	const getColumns = () => {
		const columns = [];

		if (!Array.isArray(sessions)) return [];

		sessions.forEach((session, i) => {
			columns.push({
				field: 'sessions',
				title: (
					<div className="table-header font-10 text-uppercase">
						{generateHeading(session, i)}
					</div>
				),
				render: (row) => {
					return <div>{generateAttendanceCell(session, row)}</div>;
				},
				headerStyle: {
					textAlign: 'center',
					border: 'none',
					outline: 'none',
					width: 88,
					minWidth: 88,
				},
				sorting: false,
				cellStyle: {
					whiteSpace: 'nowrap',
					width: 88,
					minWidth: 88,
				},
			});
		});

		return columns;
	};

	const goToProfile = (id) => {
		history.push({
			pathname: `/${auth.loggedInUser.roleName}/dashboard/profile/${id}`,
		});
	};

	const composeData = () => {
		if (!sessionData[0] || !sessionData[0].sessions) return [];

		const tableData = sessionData.map((row, i) => {
			row.startDate = row.attendanceData.startDate;
			row.endDate = row.attendanceData.endDate;
			row.name = row.attendanceData.name;
			row.city = row.attendanceData.city;
			row.patientId = row.attendanceData.patientId;
			row.uniqueData = `${row.attendanceData.startDate}-${i}`;
			row.patientType = row.attendanceData.patientType;

			return row;
		});

		return tableData;
	};

	useEffect(() => {
		setColumns(getColumns());
	}, [tableData]);

	useEffect(() => {
		setTableData(composeData());
	}, [sessionData]);

	return (
		<div className="attendance-table-container row">
			{type !== 'patient' && !viewAsPatient && (
				<div className="col-auto">
					<div className="table-header font-10 px-3 py-1 mb-1 text-uppercase hand no-select">
						<button
							className="btn btn-white bg-white link-color border font-weight-bold d-flex justify-content-start font-12 mb-2 mt-1"
							onClick={handleSelectAll}
						>
							{allSelected ? 'Deselect' : 'Select'} All Sessions
						</button>
					</div>
					<div>
						{tableData.map((row) => (
							<div
								className="hand"
								key={row.name}
								style={{ padding: '16px' }}
								onClick={() => goToProfile(row.patientId)}
							>
								<ImageDetailsCell
									heading={row.name}
									primarySubHeading={startCase(row.patientType)}
								/>
							</div>
						))}
					</div>
				</div>
			)}
			<div className="col" style={{ overflowX: 'auto' }}>
				{columns.length > 0 && (
					<Table
						data={tableData}
						columns={columns}
						keyField={'uniqueData'}
						hover={false}
						classes="attendance-table"
						pagination={false}
						loading={loading}
						horizontalScroll
						paging={false}
						showWhenEmpty
					/>
				)}
			</div>
		</div>
	);
};

export default AttendanceTable;
