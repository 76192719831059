/*
 *
 * BrowseGroups reducer
 *
 */
import { fromJS } from 'immutable';

import {
	DEFAULT_ACTION,
	LOAD_PROGRAMS_PRIVATE,
	LOAD_PROGRAMS_PRIVATE_ERROR,
	LOAD_PROGRAMS_PRIVATE_SUCCESS,
} from './constants';

export const initialState = fromJS({
	programs: [],
});

function browseGroupsPrivateReducer(state = initialState, action) {
	switch (action.type) {
		case DEFAULT_ACTION:
			return state;
		case LOAD_PROGRAMS_PRIVATE:
			return state.set('programs', []);
		case LOAD_PROGRAMS_PRIVATE_SUCCESS:
			return state.set('programs', action.programs);
		case LOAD_PROGRAMS_PRIVATE_ERROR:
			return state.set('error', action.error);
		default:
			return state;
	}
}

export default browseGroupsPrivateReducer;
