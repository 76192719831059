import api from 'utils/api';

function buildFormData(formData, data, parentKey) {
	if (
		data &&
		typeof data === 'object' &&
		!(data instanceof Date) &&
		!(data instanceof File)
	) {
		Object.keys(data).forEach((key) => {
			buildFormData(
				formData,
				data[key],
				parentKey ? `${parentKey}[${key}]` : key,
			);
		});
	} else {
		const value = data == null ? '' : data;

		formData.append(parentKey, value);
	}
}

export function jsonToFormData(data) {
	const formData = new FormData();
	buildFormData(formData, data);
	return formData;
}

export function filesToFormData(files) {
	const formData = new FormData();
	for (const file of files) {
		formData.append('files', file);
	}
	return formData;
}

export const clinicianRegistrationAPI = (data) =>
	api
		.put(`/clinician/${data.id}`, data)
		.then((response) => response.data)
		.catch((err) => err);

export const fetchClinicianData = (data) =>
	api
		.get(`/clinician/${data.id}`)
		.then((response) => response.data)
		.catch((err) => err);

export const uploadCredentialAPI = (data, file) =>
	api
		.put(
			`/document/upload?userId=${data.id}&documentType=${data.document}`,
			jsonToFormData(file),
		)
		.then((response) => response.data)
		.catch((err) => err);
