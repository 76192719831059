import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
//import { noop } from 'utils/common';

import {
    createOQClientSuccess,
    createOQClientError,
    createOQEmployeeSuccess,
    createOQEmployeeError,
    getOQProfile,
    getOQProfileSuccess,
    getOQProfileError,
    getOQClient,
    getOQClientSuccess,
    getOQClientError,
    getOQAdministrationURLSuccess,
    getOQAdministrationURLError,
    sendOQAdministrationSMSSuccess,
    sendOQAdministrationSMSError,
} from './actions';
import {
    CREATE_OQ_CLIENT,
    CREATE_OQ_EMPLOYEE,
    GET_OQ_ADMINISTRATION_URL,
    GET_OQ_CLIENT,
    GET_OQ_PROFILE,
    SEND_OQ_ADMINISTRATION_SMS,
} from './constants';
import {
    CreateOQClientAction,
    CreateOQEmployeeAction,
    GetOQProfileAction,
    GetOQClientAction,
    GetOQAdministrationURLAction,
    SendOQAdministrationSMSAction,
} from './types';
import {
    createOQClientAPI,
    createOQEmployeeAPI,
    getOQProfileAPI,
    getOQClientAPI,
    getOQAdministrationURLAPI,
    sendOQAdministrationSMSAPI,
} from 'utils/api';

import { getLogger } from 'utils/logger';
const logger = getLogger('v2.measures.saga');

const handleError = (error: any, functionName: string): string => {
    if (error instanceof Error) {
        logger.error(error.message); // only logger.error gets printed in prod
        logger.debug(error.cause); // don't expose stack trace in production
        return error.message;
    }
    const message = 'Unknown error has occurred';
    logger.error(message);
    logger.debug(functionName + ' error: ');
    logger.debug(error); // attempt to log error
    return message;
};

function* createOQClientSaga(action: CreateOQClientAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(createOQClientAPI, data);
        if (response.data) {
            yield put(createOQClientSuccess(response.data));
            yield put(getOQClient({ patientId: data.patientId }));
        } else {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
    } catch (e) {
        const message = handleError(e, 'createOQClientSaga');
        yield put(createOQClientError(message));
    }
}

function* createOQEmployeeSaga(action: CreateOQEmployeeAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(createOQEmployeeAPI, data);
        if (response.data) {
            yield put(createOQEmployeeSuccess(response.data));
            yield put(getOQProfile({ userId: data.employeeId }));
        } else {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
    } catch (e) {
        const message = handleError(e, 'createOQEmployeeSaga');
        yield put(createOQEmployeeError(message));
    }
}

function* getOQProfileSaga(action: GetOQProfileAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(getOQProfileAPI, data);
        if (response.data) {
            yield put(getOQProfileSuccess(response.data));
        } else {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
    } catch (e) {
        const message = handleError(e, 'getOQProfileSaga');
        yield put(getOQProfileError(message));
    }
}

function* getOQClientSaga(action: GetOQClientAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(getOQClientAPI, data);
        if (!response.data) {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
        if (response.data.success) {
            yield put(getOQClientSuccess(response.data.data));
        }
    } catch (e) {
        const message = handleError(e, 'getOQClientSaga');
        yield put(getOQClientError(message));
    }
}

function* getOQAdministrationURLSaga(action: GetOQAdministrationURLAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(getOQAdministrationURLAPI, data);
        if (response.data) {
            yield put(getOQAdministrationURLSuccess(response.data.data.url));
        } else {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
    } catch (e) {
        const message = handleError(e, 'getOQAdministrationURLSaga');
        yield put(getOQAdministrationURLError(message));
    }
}

function* sendOQAdministrationSMSSaga(action: SendOQAdministrationSMSAction): SagaIterator {
    const { data } = action;
    try {
        const response: any = yield call(sendOQAdministrationSMSAPI, data);
        if (response.data) {
            yield put(sendOQAdministrationSMSSuccess(response.data));
        } else {
            const error: APIError = response.response.data;
            throw Error(error.error, { cause: error });
        }
    } catch (e) {
        const message = handleError(e, 'sendOQAdministrationSMSSaga');
        yield put(sendOQAdministrationSMSError(message));
    }
}

// Define the root saga
export default function* measuresSaga(): SagaIterator {
    yield takeLatest(CREATE_OQ_CLIENT, createOQClientSaga);
    yield takeLatest(CREATE_OQ_EMPLOYEE, createOQEmployeeSaga);
    yield takeLatest(GET_OQ_PROFILE, getOQProfileSaga);
    yield takeLatest(GET_OQ_CLIENT, getOQClientSaga);
    yield takeLatest(GET_OQ_ADMINISTRATION_URL, getOQAdministrationURLSaga);
    yield takeLatest(SEND_OQ_ADMINISTRATION_SMS, sendOQAdministrationSMSSaga);
}
