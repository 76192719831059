const filters = (options, type) => {
	if (type === 'clinician') {
		return [
			{
				type: 'Dropdown Input',
				name: 'location',
				label: 'location',
				selectOptions: options.location,
				selectLabel: 'LOCATION',
				defaultSelect: 'All Locations',
			},
			{
				type: 'Single Select',
				name: 'groupSize',
				label: 'size',
				selectOptions: options.groupSize,
				selectLabel: 'SIZE',
				defaultSelect: 'Any Size',
			},
			{
				type: 'Single Select',
				name: 'status',
				label: 'status',
				selectOptions: options.inPerson,
				selectLabel: 'STATUS',
				defaultSelect: 'Any Status',
			},
			{
				type: 'Toggle',
				name: 'showInactive',
				label: 'Show Inactive',
				selectLabel: 'SHOW INACTIVE',
				isSelected: options.showInactive,
			},
		];
	}
	return [
		{
			type: 'Dropdown Input',
			name: 'clinicianName',
			label: 'clinician',
			selectOptions: options.clinicianName,
			selectLabel: 'CLINICIAN',
			defaultSelect: 'All Clinicians',
		},
		{
			type: 'Single Select',
			name: 'groupSize',
			label: 'size',
			selectOptions: options.groupSize,
			selectLabel: 'SIZE',
			defaultSelect: 'Any Size',
		},
		{
			type: 'Dropdown Input',
			name: 'location',
			label: 'location',
			selectOptions: options.location,
			selectLabel: 'LOCATION',
			defaultSelect: 'All Locations',
		},
		{
			type: 'Single Select',
			name: 'status',
			label: 'status',
			selectOptions: options.inPerson,
			selectLabel: 'STATUS',
			defaultSelect: 'Any Status',
		},
		{
			type: 'Toggle',
			name: 'showInactive',
			label: 'Show Inactive',
			selectLabel: 'SHOW INACTIVE',
			isSelected: options.showInactive,
		},
	];
};
export default filters;
