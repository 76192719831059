import history from 'utils/history';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';

import ActionButton from 'atoms/ActionButton';
import { makeSelectAuth } from 'containers/App/selectors';
import useIsMobile from 'hooks/isMobile.ts';
import notificationBellImage from 'svgs/notification';

import { fetchNotifications, updateNotifications } from './actions';
import { generateNotificationMessage } from './helpers';
import './index.css';
import { makeSelectNotifications } from './selectors';

const NotificationItem = styled.button`
	background: ${(props) => (props.hasSeen ? 'white' : '#dbf4ff')};

	&:hover {
		background: #f8f9fa;
	}
`;

const Notifications = (props) => {
	const { notifications, auth, loadNotifications, readNotification } = props;
	const location = useLocation();
	const {
		loggedInUser: { roleName },
	} = auth;

	useEffect(() => {
		loadNotifications();
	}, [loadNotifications, location]);

	const goto = ({ route, state }) => {
		history.push({
			pathname: `/${roleName}/dashboard/${route}`,
			state,
		});
		toggle();
	};
	const [hasUnread, setHasUnread] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useIsMobile();

	useEffect(() => {
		if (!isOpen && !hasUnread) {
			let unread = false;
			if (notifications.findIndex((n) => !n.hasSeen) > -1) unread = true;
			setHasUnread(unread);
		}
	}, [notifications, hasUnread, isOpen]);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const handleReadAll = () => {
		let sendRequest = false;
		const notifIds = notifications
			.map((n) => {
				if (!n.hasSeen) {
					sendRequest = true;
					return n.id;
				}
			})
			.filter((n) => n);

		if (sendRequest) {
			readNotification(notifIds);
		}

		setHasUnread(false);
	};

	const setAsRead = (id) => {
		readNotification([id]);
	};

	return (
		<div>
			<Dropdown
				direction="down"
				isOpen={isOpen}
				toggle={toggle}
				className="w-100"
			>
				<DropdownToggle
					tag="span"
					data-toggle="dropdown"
					aria-expanded={isOpen}
				>
					{hasUnread && (
						<div
							style={{ position: 'absolute', top: 10, right: 10, zIndex: 100 }}
						>
							<div className="notification-bubble" />
						</div>
					)}
					<ActionButton
						image={notificationBellImage}
						fillColor={isOpen ? 'var(--main-color)' : 'var(--main-grey)'}
					/>
				</DropdownToggle>
				<DropdownMenu
					right
					className={`notification-menu ${isMobile && 'mobile'}`}
					flip={false}
					style={{ overflowX: 'hidden' }}
				>
					{notifications.length === 0 ? (
						<DropdownItem className="notification-item py-3 px-4">
							No new notifications
						</DropdownItem>
					) : (
						<div>
							<div
								className="row w-100 font-10 py-1 px-2 mx-0 justify-content-end"
								style={{
									borderBottom: '1px solid gainsboro',
								}}
							>
								{/* <div className="col-auto px-0">
                  <CustomCheckbox />
                  <div>Show read</div>
                </div> */}
								<div
									className="col-auto px-0 font-italic mr-2"
									style={{
										color: hasUnread ? 'var(--main-color)' : 'black',
										cursor: hasUnread ? 'pointer' : 'auto',
									}}
									onClick={handleReadAll}
								>
									{hasUnread ? 'Mark all read.' : 'No unread notifications'}
								</div>
							</div>
							{notifications.map((n) => (
								<NotificationItem
									key={n.id}
									className="notification-item py-3 px-4"
									onClick={() => setAsRead(n.id)}
									hasSeen={!hasUnread || n.hasSeen}
								>
									<div className="row font-14 mb-2">
										<div
											className="col px-1 text-left"
											style={{ wordWrap: 'break-word' }}
										>
											{generateNotificationMessage(n, goto, roleName)}
										</div>
									</div>
								</NotificationItem>
							))}
						</div>
					)}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	auth: makeSelectAuth(),
	notifications: makeSelectNotifications(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
		loadNotifications: () => dispatch(fetchNotifications()),
		readNotification: (data) => dispatch(updateNotifications(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
