/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { connectRouter } from 'connected-react-router/immutable';

import history from 'utils/history';

import { combineReducers } from 'redux-immutable';

import { LOGOUT } from 'containers/UserDropDown/constants';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
	const appReducer = combineReducers({
		...injectedReducers,
	});

	const rootReducer = (state, action) => {
		if (action.type === LOGOUT) {
			// eslint-disable-next-line no-param-reassign
			state = undefined;
		}

		return appReducer(state, action);
	};

	// Wrap the root reducer and return a new root reducer with router state
	const mergeWithRouterState = connectRouter(history);
	return mergeWithRouterState(rootReducer);
}
