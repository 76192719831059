import React from 'react';

const Pill = (props) => {
	const { modifiedClass, onClick, selected, text, ...rest } = props;

	let className = `badge badge-pill text-bold border m-1 px-3 py-2 text-uppercase font-10 hand ${modifiedClass}`;

	selected
		? (className += ' badge-success')
		: (className += ' bg-white badge-color-cust link-color');

	return (
		<div className={className} onClick={onClick} {...rest}>
			{text}
		</div>
	);
};

export default Pill;
