/**
 *
 * SigningModal
 *
 */
import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import CustomButton from 'atoms/CustomButton';
import TextArea from 'atoms/TextArea';
import useIsMobile from 'hooks/isMobile.ts';

const DeclineModal = (props) => {
	const { isOpen, toggle, form = {}, submit, mode } = props;
	const [reason, setReason] = useState(form.declineReason || '');
	const isMobile = useIsMobile();

	const customButtonClass =
		'btn btn-primary btn-color btn-lg btn-block font-15 font-weight-bold d-flex justify-content-center p-3 px-4';

	const handleInput = (e) => {
		setReason(e.target.value);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className={`${isMobile ? 'modal-mobile' : 'modal-dialog-centered'}`}
			style={
				isMobile
					? {
							marginTop: '50%',
					  }
					: {}
			}
			backdrop="static"
		>
			<ModalHeader
				className="border-0 font-30 text-dark cust-datemodal-header pb-0"
				toggle={toggle}
			>
				Decline Form
				<div className="font-15">{form.filename}</div>
			</ModalHeader>
			<ModalBody className="pt-0">
				<TextArea
					placeholder="Please provide a reason for declining"
					handleInput={handleInput}
					value={reason}
					rows={6}
					disabled={mode === 'view'}
				/>
				<div className="row w-100 justify-content-center">
					{mode === 'edit' ? (
						<>
							<CustomButton
								type="secondary"
								title="Cancel"
								onClick={toggle}
								className={`${customButtonClass} mx-3`}
							/>
							<CustomButton
								disabled={reason === ''}
								type="primary"
								title="Submit"
								onClick={() => submit(reason)}
								className={customButtonClass}
							/>
						</>
					) : (
						<CustomButton
							type="primary"
							title="Okay"
							onClick={toggle}
							className={customButtonClass}
						/>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};

DeclineModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
};

export default DeclineModal;
