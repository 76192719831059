import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import RequestSentModal from 'components/RequestSentModal';
import { submitJotform } from 'containers/Forms/actions';

export default function CovidReturn() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = location;
	const [, sid] = search.split('=');
	const { isUpdating, errors } = useSelector((state) =>
		state.get('forms').toJS(),
	);

	useEffect(() => {
		dispatch(submitJotform({ sid, form: 'covid' }));
	}, []);

	let message = 'One moment...';
	if (!isUpdating) {
		if (errors.length) {
			message = errors.join(' ');
		} else {
			message =
				'Thank you for completing the form. You may now check in to your therapies.';
		}
	}

	return (
		<RequestSentModal
			message={message}
			hide={() => (isUpdating ? null : history.push('/patient/dashboard'))}
			successStatus={!errors.length}
		/>
	);
}
