/*   eslint no-case-declarations: 0 */

/*
 *
 * PatientRegister reducer
 *
 */
import { fromJS } from 'immutable';

import _ from 'lodash';

import { ERROR, FETCH_DATA, PATIENT_REGISTRATION, SUCCESS } from './constants';

export const initialState = fromJS({
	id: '',
	details: {},
	address: {},
	otherDetails: {},
	emergencyContact: [],
	registrationProgress: 0,
	intakeProgress: 0,
	fetchSuccess: false,
});

function updatePatientRegistration(action, initial) {
	let state = initial;
	state = state.merge(action.data);
	state = state.set('fetchSuccess', true);
	return state;
}

function updateStateFromSubmittedFields(action, initial) {
	let state = initial;
	if (action.data.patientDetails) {
		const data = {};
		const { emergencyContact } = action.data.patientDetails;
		data.emergencyContact = emergencyContact;
		if (action.data.address) {
			data.address = action.data.address;
			if (action.data.patientDetails.homePhone) {
				data.address.homePhone = action.data.patientDetails.homePhone;
			}
			if (
				action.data.mailingAddress &&
				Object.keys(action.data.mailingAddress).length > 0
			) {
				data.address.mailingAddress = action.data.mailingAddress;
			}
		}
		if (action.data.referredBy) {
			data.otherDetails = _.pick(action.data.patientDetails, [
				'occupation',
				'employer',
				'employerPhone',
				'otherFamilyName',
			]);
			data.otherDetails.referredBy = action.data.referredBy;
		}
		data.details = _.pick(action.data.patientDetails, [
			'id',
			'registrationType',
			'name',
			'childName',
			'maritalStatus',
			'phone',
			'email',
			'dob',
			'sex',
		]);
		data.registrationProgress = action.data.registrationProgress || 0;
		data.intakeProgress = action.data.intakeProgress || 0;
		state = state.mergeDeep(data);
	}
	return state;
}

function patientRegisterReducer(state = initialState, action) {
	switch (action.type) {
		case PATIENT_REGISTRATION:
			return updatePatientRegistration(action, state);
		case SUCCESS:
			return updateStateFromSubmittedFields(action, state);
		case FETCH_DATA:
			return updatePatientRegistration(action, state);
		case ERROR:
			return state;
		default:
			return state;
	}
}

export default patientRegisterReducer;
