import React from 'react';

const Popup = (props) => {
	const {
		isOpen,
		text,
		style = { container: {}, outerTri: {}, innerTri: {} },
		noTri,
		adjust,
	} = props;
	return (
		<div
			className="p-1"
			style={{
				...styles.container(isOpen, adjust),
				...style.container,
			}}
		>
			{!noTri && (
				<div>
					<div
						style={{
							...styles.outerTri,
							...style.outerTri,
						}}
					/>
					<div
						style={{
							...styles.innerTri,
							...style.innerTri,
						}}
					/>
				</div>
			)}
			{text}
		</div>
	);
};

const styles = {
	container: (isOpen, adjust = 0) => ({
		position: 'absolute',
		top: 0,
		right: 0,
		transform: `translate(${44 + adjust}%, -100%) ${
			isOpen ? 'scale(1)' : 'scale(0.8)'
		}`,
		background: 'white',
		border: '1px solid grey',
		borderRadius: '5px',
		color: '#f25555',
		opacity: isOpen ? '100%' : '0%',
		fontSize: '18px',
		transition: '0.25s all',
		zIndex: 100000000,
		pointerEvents: 'none',
		textAlign: 'center',
		whiteSpace: 'pre',
	}),
	outerTri: {
		width: 0,
		height: 0,
		borderLeft: '7px solid transparent',
		borderRight: '7px solid transparent',
		borderTop: '7px solid grey',
		position: 'absolute',
		bottom: 0,
		left: '50%',
		transform: 'translate(-50%, 100%)',
	},
	innerTri: {
		width: 0,
		height: 0,
		borderLeft: '5px solid transparent',
		borderRight: '5px solid transparent',
		borderTop: '5px solid white',
		position: 'absolute',
		bottom: 0,
		left: '50%',
		transform: 'translate(-50%, 90%)',
	},
};

export default Popup;
