/*
 *
 * GroupDetail actions
 *
 */
import {
	ADD_CLIENT,
	ADD_CLIENT_ERROR,
	ADD_CLIENT_SUCCESS,
	ADD_SESSION,
	ADD_SESSION_ERROR,
	ADD_SESSION_SUCCESS,
	DEFAULT_ACTION,
	DELETE_SESSIONS,
	DELETE_SESSIONS_ERROR,
	DELETE_SESSIONS_SUCCESS,
	EXTEND_SESSIONS,
	EXTEND_SESSIONS_ERROR,
	EXTEND_SESSIONS_SUCCESS,
	FETCH_ALL_CLIENTS,
	FETCH_ALL_CLIENTS_ERROR,
	FETCH_ALL_CLIENTS_SUCCESS,
	GET_ATTENDANCE,
	GET_ATTENDANCE_ERROR,
	GET_ATTENDANCE_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_ERROR,
	GET_CLIENTS_SUCCESS,
	GET_SESSIONS,
	GET_SESSIONS_ERROR,
	GET_SESSIONS_SUCCESS,
	LOAD_PROGRAM,
	LOAD_PROGRAM_ERROR,
	LOAD_PROGRAM_SUCCESS,
	SAVE_ATTENDANCE,
	SAVE_ATTENDANCE_ERROR,
	SAVE_ATTENDANCE_SUCCESS,
	UNENROLL,
	UNENROLL_ERROR,
	UNENROLL_SUCCESS,
	UPDATE_ENROLLMENT,
	UPDATE_ENROLLMENT_ERROR,
	UPDATE_ENROLLMENT_SUCCESS,
} from './constants';

export function defaultAction() {
	return {
		type: DEFAULT_ACTION,
	};
}

export function getAttendance(data, cb) {
	return {
		type: GET_ATTENDANCE,
		data,
		cb,
	};
}

export function getAttendanceSuccess(data) {
	return {
		type: GET_ATTENDANCE_SUCCESS,
		data,
	};
}

export function getAttendanceError(error) {
	return {
		type: GET_ATTENDANCE_ERROR,
		error,
	};
}

export function loadProgram(data) {
	return {
		type: LOAD_PROGRAM,
		data,
	};
}

export function loadProgramSuccess(data) {
	return {
		type: LOAD_PROGRAM_SUCCESS,
		data,
	};
}

export function loadProgramError(error) {
	return {
		type: LOAD_PROGRAM_ERROR,
		error,
	};
}

export function saveAttendance(data, res, errCB) {
	return {
		type: SAVE_ATTENDANCE,
		data,
		res,
		errCB,
	};
}

export function saveAttendanceSuccess(data) {
	return {
		type: SAVE_ATTENDANCE_SUCCESS,
		data,
	};
}

export function saveAttendanceError(error) {
	return {
		type: SAVE_ATTENDANCE_ERROR,
		error,
	};
}

export function addSession(data) {
	return {
		type: ADD_SESSION,
		data,
	};
}
export function addSessionSuccess(data) {
	return {
		type: ADD_SESSION_SUCCESS,
		data,
	};
}
export function addSessionError(error) {
	return {
		type: ADD_SESSION_ERROR,
		error,
	};
}

export function extendSessions(data, cb) {
	return {
		type: EXTEND_SESSIONS,
		data,
		cb,
	};
}
export function extendSessionsSuccess(data) {
	return {
		type: EXTEND_SESSIONS_SUCCESS,
		data,
	};
}
export function extendSessionsError(error) {
	return {
		type: EXTEND_SESSIONS_ERROR,
		error,
	};
}

export function deleteSessions(data) {
	return {
		type: DELETE_SESSIONS,
		data,
	};
}

export function deleteSessionsSuccess(ids) {
	return {
		type: DELETE_SESSIONS_SUCCESS,
		ids,
	};
}

export function deleteSessionsError(error) {
	return {
		type: DELETE_SESSIONS_ERROR,
		error,
	};
}

export function getSessions(data) {
	return {
		type: GET_SESSIONS,
		data,
	};
}

export function getSessionsSuccess(data) {
	return {
		type: GET_SESSIONS_SUCCESS,
		data,
	};
}

export function getSessionsError(error) {
	return {
		type: GET_SESSIONS_ERROR,
		error,
	};
}

export function fetchAllClients(role) {
	return {
		type: FETCH_ALL_CLIENTS,
		role,
	};
}
export function fetchAllClientsSuccess(data) {
	return {
		type: FETCH_ALL_CLIENTS_SUCCESS,
		data,
	};
}
export function fetchAllClientsError(error) {
	return {
		type: FETCH_ALL_CLIENTS_ERROR,
		error,
	};
}

export function addClient(data, cb) {
	return {
		type: ADD_CLIENT,
		data,
		cb,
	};
}

export function addClientSuccess(data) {
	return {
		type: ADD_CLIENT_SUCCESS,
		data,
	};
}

export function addClientError(error) {
	return {
		type: ADD_CLIENT_ERROR,
		error,
	};
}

export function getClients(data) {
	return {
		type: GET_CLIENTS,
		data,
	};
}

export function getClientsSuccess(data) {
	return {
		type: GET_CLIENTS_SUCCESS,
		data,
	};
}

export function getClientsError(error) {
	return {
		type: GET_CLIENTS_ERROR,
		error,
	};
}

export function unenroll(data) {
	return {
		type: UNENROLL,
		data,
	};
}
export function unenrollSuccess(data) {
	return {
		type: UNENROLL_SUCCESS,
		data,
	};
}
export function unenrollError(error) {
	return {
		type: UNENROLL_ERROR,
		error,
	};
}

export function updateEnrollment(data) {
	return {
		type: UPDATE_ENROLLMENT,
		data,
	};
}
export function updateEnrollmentSuccess(data) {
	return {
		type: UPDATE_ENROLLMENT_SUCCESS,
		data,
	};
}
export function updateEnrollmentError(error) {
	return {
		type: UPDATE_ENROLLMENT_ERROR,
		error,
	};
}
