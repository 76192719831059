/*
 *
 * UserDropDown reducer
 *
 */
import { fromJS } from 'immutable';

import {
	FETCH_FEEDBACK,
	FETCH_FEEDBACK_ERROR,
	FETCH_FEEDBACK_SUCCESS,
	UPDATE_FEEDBACK,
	UPDATE_FEEDBACK_ERROR,
	UPDATE_FEEDBACK_SUCCESS,
} from './constants';

export const initialState = fromJS({
	error: '',
	success: false,
	feedback: [],
	isFetching: false,
	isUpdating: false,
});

function feedbackReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_FEEDBACK:
			return state.merge({
				error: '',
				isFetching: true,
				success: false,
			});
		case FETCH_FEEDBACK_SUCCESS:
			return state.merge({
				error: false,
				success: true,
				isFetching: false,
				feedback: action.feedback,
			});
		case FETCH_FEEDBACK_ERROR:
			return state.merge({
				error: action.error,
				success: false,
				isFetching: false,
				feedback: [],
			});

		case UPDATE_FEEDBACK:
			return state.merge({
				error: '',
				isUpdating: true,
				success: false,
			});
		case UPDATE_FEEDBACK_SUCCESS:
			const feedback = state.get('feedback').toJS();
			feedback.forEach((f) => {
				if (f.id === action.id) {
					f.completed = true;
				}
			});

			return state.merge({
				error: false,
				success: true,
				isUpdating: false,
				feedback,
			});
		case UPDATE_FEEDBACK_ERROR:
			return state.merge({
				error: action.error,
				success: false,
				isUpdating: false,
				feedback: [],
			});
		default:
			return state;
	}
}

export default feedbackReducer;
