/**
 *
 * Redirect
 *
 */
import history from 'utils/history';

import PropTypes from 'prop-types';

import React from 'react';

/* eslint-disable react/prefer-stateless-function */
class Redirect extends React.Component {
	componentDidMount() {
		history.push(this.props.redirectPath);
	}

	render() {
		return <div />;
	}
}

Redirect.propTypes = {
	redirectPath: PropTypes.string.isRequired,
};
export default Redirect;
