import moment from 'moment';

export function compareWords(a, b, order) {
	if (a === null || typeof a === 'undefined') {
		if (order === 'asc') {
			return -1;
		}

		return 1;
	} else if (b === null || typeof b === 'undefined') {
		if (order === 'asc') {
			return 1;
		}

		return -1;
	}

	if (order === 'asc') {
		return a.localeCompare(b);
	}

	return b.localeCompare(a);
}

export function compareDates(a, b, order) {
	if (a === null || typeof a === 'undefined') {
		if (order === 'asc') {
			return 1;
		}

		return -1;
	} else if (b === null || typeof b === 'undefined') {
		if (order === 'asc') {
			return -1;
		}

		return 1;
	}

	const timeFormat = 'YYYY-MM-DD HH:MM:SS';

	if (order === 'asc') {
		return moment(b, timeFormat).isBefore(moment(a, timeFormat)) ? 1 : -1;
	}

	return moment(a, timeFormat).isBefore(moment(b, timeFormat)) ? 1 : -1;
}

export function compareBools(a, b, order) {
	if (order === 'asc') {
		return b || a ? 1 : -1;
	}

	return a && b ? 1 : -1;
}

export function compareArrays(a, b, order) {
	return compareInts(a.length, b.length, order);
}

export function compareInts(a, b, order) {
	if (order === 'asc') {
		return b < a ? 1 : -1;
	}

	return a < b ? 1 : -1;
}
