import { call, put, takeLatest } from 'redux-saga/effects';

import { changePharmacyAPI } from 'utils/api';
import { noop } from 'utils/common';

import { changePharmacyError, changePharmacySuccess } from './actions';
import { CHANGE_PHARMACY } from './constants';

// Individual exports for testing
export default function* rxRequestsSaga() {
	yield takeLatest(CHANGE_PHARMACY, changePharmacy);
}

export function* changePharmacy(actions) {
	const { data, cb = noop } = actions;
	try {
		const response = yield call(changePharmacyAPI, data);
		if (response.response && response.response.status >= 400) {
			yield put(changePharmacyError());
		} else {
			yield put(changePharmacySuccess(response.data));
			cb();
		}
	} catch (err) {
		yield put(changePharmacyError(err));
	}
}
